import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import InsuranceDraftFlow from '../../../../flows/InsuranceDraftFlow/InsuranceDraftFlow';
import { InsuranceNewFlowState } from '../../../../flows/InsuranceDraftFlow/types';
import { FlowType, useManagedFlow, useScrollTop } from '../../../../flows/utils/flow';
import useInsuranceCompleteHandler from '../../../../flows/utils/useInsuranceCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { getHomeUrl, getLocationCode } from '../../../../utilities/urls';
import { InsuranceApplicationDataFragment } from '../../InsuranceRoute/data.graphql';

export type InsuranceDraftFlowHandlerProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
    consents: ConsentDataFragment[];
    token: string;
};

const getFlowImplementation = (channel: Channel): FlowType<InsuranceNewFlowState, InsuranceDraftFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
            return InsuranceDraftFlow;

        default:
            return null;
    }
};

const InsuranceDraftHandler = ({ insuranceApplication, consents, token }: InsuranceDraftFlowHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();
    const history = useHistory();

    // create the initial state
    const initialState = useMemo(
        (): InsuranceNewFlowState => ({
            channel: insuranceApplication.channel,
            company,
            token,
            zone,
            country,
            dealerId: insuranceApplication.dealerId!,
            insuranceApplication,
            source: ApplicationFlowSource.INSURANCE,
            consents,
            insuranceCompany: insuranceApplication.insuranceCompany,
        }),
        [insuranceApplication, company, token, zone, country, consents]
    );

    const flowImplementation = getFlowImplementation(insuranceApplication.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'insuranceDraftFlow');

    useScrollTop(step.identifier);

    useInsuranceCompleteHandler('insuranceDraftFlow');

    if (insuranceApplication.steps?.submission) {
        const locationCode = getLocationCode(country.code, zone.code);
        const companyRootUrl = getHomeUrl(insuranceApplication.channel)(company.code, locationCode);

        history.push(companyRootUrl, {
            applicationVersionId: insuranceApplication.version.id,
            submitted: true,
            isInsurance: true,
        });

        return null;
    }

    return step.render();
};

export default InsuranceDraftHandler;
