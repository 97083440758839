import { useQuery } from '@apollo/client';
// @ts-ignore
import { Actions, GrayButton, Modal } from '@appvantageasia/afc-ui';
import { isNil, keyBy } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { ApplicationStatus } from '../../../../schema';
import { getApplicationUrl, getInsuranceApplicationUrl } from '../../../../utilities/urls';
import { SubmitSuccess } from '../../../ui/calculator';
import { getApplication } from '../../ApplicationRoute/data.graphql';
import { getInsuranceApplication } from '../../InsuranceRoute/data.graphql';

type LocationState = { submitted?: boolean; applicationVersionId?: string; isInsurance?: boolean };

type ModalMessage = { type: string; title: string; submit: string; confirmation: string; contact: string };
const getModalMessagesKeys = (types: string[]): { [k: string]: ModalMessage } => {
    const messages = types.map(type => ({
        type,
        title: `submitSuccessModal.${type}.title`,
        submit: `submitSuccessModal.${type}.message.submit`,
        confirmation: `submitSuccessModal.${type}.message.confirmation`,
        contact: 'submitSuccessModal.contact',
    }));

    return keyBy(msg => msg.type, messages);
};

const SubmitSuccessModal = () => {
    const { t } = useTranslation();
    const { submitted = false, applicationVersionId, isInsurance } = useLocation<LocationState>().state ?? {};
    const [show, setShow] = useState(submitted);

    const history = useHistory();
    const messagesKeys = useMemo(() => getModalMessagesKeys(['submitted', 'pending', 'drafted']), []);

    const { data } = useQuery(isInsurance ? getInsuranceApplication : getApplication, {
        fetchPolicy: 'cache-first',
        skip: isNil(applicationVersionId),
        variables: { id: applicationVersionId as string },
    });

    const application = useMemo(() => (isInsurance ? data?.insuranceApplication : data?.application), [
        data,
        isInsurance,
    ]);

    const message = useMemo(() => {
        if (application?.status === ApplicationStatus.DRAFT) {
            return messagesKeys.drafted;
        }

        if (application?.proceedWithCustomerDevice) {
            return messagesKeys.pending;
        }

        return messagesKeys.submitted;
    }, [application, messagesKeys]);

    const goTo = useCallback(() => {
        if (isInsurance) {
            // @ts-ignore
            history.pushWithCompanyAndState(getInsuranceApplicationUrl, null, application.version.id);

            return;
        }

        // @ts-ignore
        history.pushWithCompanyAndState(getApplicationUrl, null, application);
    }, [history, isInsurance, application]);

    const sendCustomerConfirmationEmail = useMemo(() => {
        if (isInsurance) {
            return application?.insuranceCompany?.sendEmailToCustomer;
        }

        return application?.bank?.sendCustomerConfirmationEmail;
    }, [isInsurance, application]);

    if (!application || !show) {
        return null;
    }

    return (
        <Modal
            containerClassName="modal-container"
            onClose={() => setShow(false)}
            title={t(message.title)}
            showClose
            showTitle
        >
            <SubmitSuccess>
                <p>
                    <Trans
                        components={{ s: <span /> }}
                        i18nKey={message.submit}
                        values={{ customer: application.customer.name.value }}
                    />
                </p>
                {sendCustomerConfirmationEmail && (
                    <p>
                        <Trans
                            components={{ s: <span /> }}
                            i18nKey={message.confirmation}
                            values={{ customer: application.customer.name.value }}
                        />
                    </p>
                )}
                {!sendCustomerConfirmationEmail && application.status !== ApplicationStatus.DRAFT && (
                    <p>{t(message.contact)}</p>
                )}
            </SubmitSuccess>
            <Actions>
                <GrayButton onClick={goTo}>{t('submitSuccessModal.button.viewApplication')}</GrayButton>
            </Actions>
        </Modal>
    );
};

export default SubmitSuccessModal;
