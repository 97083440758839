import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Document } from '@appvantageasia/afc-ui';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues, ReduxFormContext } from 'redux-form';
import DocumentPreview, { DocumentPreviewProps } from '../../../ApplicationRoute/Document/DocumentPreview';
import {
    deleteAppointmentAttachment,
    DeleteAppointmentAttachmentMutation,
    DeleteAppointmentAttachmentMutationVariables,
} from '../Details.graphql';
import { AppointmentFormValues, useAppointmentFormContext } from '../Form/context';
import DocumentUpload from './DocumentUpload';

const { DocumentContainer, TabContainer, DocumentBg, DocumentGrid } = Document;

const TabDocument = () => {
    // get form values
    const { disabled } = useAppointmentFormContext();
    const { form } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state) as AppointmentFormValues, [form]);
    const { attachments, version } = useSelector(valueSelector);
    const client = useApolloClient();

    const removeAttachmentMutation = useCallback<DocumentPreviewProps['removeMutation']>(
        async ({ referenceId, attachmentId }) => {
            await client.mutate<DeleteAppointmentAttachmentMutation, DeleteAppointmentAttachmentMutationVariables>({
                mutation: deleteAppointmentAttachment,
                variables: {
                    attachmentId,
                    id: referenceId,
                },
            });
        },
        [client]
    );

    const renderDocuments = useCallback(
        document =>
            document && (
                <DocumentPreview
                    key={document.id}
                    document={document}
                    files={attachments}
                    name="attachments"
                    referenceId={version.id}
                    removeMutation={removeAttachmentMutation}
                    showRemove
                />
            ),
        [attachments, removeAttachmentMutation, version.id]
    );

    return (
        <DocumentContainer>
            <TabContainer>
                <DocumentBg>
                    <DocumentGrid>
                        {attachments.map(renderDocuments)}
                        <Field
                            component={DocumentUpload}
                            disabled={disabled}
                            name="attachments"
                            referenceId={version.id}
                        />
                    </DocumentGrid>
                </DocumentBg>
            </TabContainer>
        </DocumentContainer>
    );
};

export default TabDocument;
