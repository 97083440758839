import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change, ReduxFormContext } from 'redux-form';
import styled from 'styled-components';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import { getRuntimeSettings } from '../../../../../selectors';
import radioChecked from '../assets/radio-checked.png';
import radioUnchecked from '../assets/radio-unchecked.png';

const RadioContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 35px;
    align-items: center;
    img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        align-self: center;
    }
    div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            cursor: default;
            font-size: 16px;
        }
    }
`;

export const formatDeliveryPrice = (
    price: number,
    label: string,
    options: { label: string }[],
    formatCurrency: (price: number) => string
) => {
    if (price === 0) {
        if (label === options[1].label) {
            return 'Charges may apply';
        }

        return 'Included';
    }

    return formatCurrency(price);
};

type RadioProps = {
    options: { name: string; label: string; price: number }[];
    input: any;
};

export const RadioInput = ({ options, input }: RadioProps) => {
    const { value } = input;
    const dispatch = useDispatch();
    const { form } = useContext(ReduxFormContext);
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { formatCurrency } = useCompanyFormatting();

    const onClick = useCallback(
        option => {
            options.forEach(
                (item, index) => item.name === option && dispatch(change(form, 'delivery', options[index].name))
            );
        },
        [dispatch, form, options]
    );

    return options.map(item => (
        <RadioContainer key={item.name} onClick={() => onClick(item.name)}>
            {item.name === value ? (
                <img alt="radio checked" src={radioChecked} />
            ) : (
                <img alt="radio unchecked" src={radioUnchecked} />
            )}
            <div>
                <span>{item.label}</span>
                <span>
                    {formatDeliveryPrice(item.price, item.label, ckdConfiguration.delivery.options, formatCurrency)}
                </span>
            </div>
        </RadioContainer>
    ));
};

const RadioField = (props: any) => <Field {...props} component={RadioInput} />;

export default RadioField;
