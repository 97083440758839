import { useApolloClient } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SubmissionError } from 'redux-form';
import { setAuthorization, setCompanyCode, setCountryCode, setDealerId, setUser, setZoneCode } from '../../../actions';
import { LoginType } from '../../../schema';
import { getCompanyIdentifier } from '../../../selectors';
import { handleResponseError } from '../../../utilities/forms';
import { getCompanyRootUrl, getLocationCode } from '../../../utilities/urls';
import MultipleSessionModal from '../../shared/MultipleSessionModal';
import useValidation from '../../utilities/useValidation';
import CredentialForm, { schema as credentialFormSchema } from './CredentialForm';
import { authenticate, UserDataFragment } from './api.graphql';
import getUserWithPermissions from './getUserWithPermissions';
import { getRootUrl } from './shared';

type CredentialStepProps = {
    setContext: React.Dispatch<
        React.SetStateAction<{
            step: string;
            companies?: Pick<UserDataFragment, 'availableCompanies'>;
            username?: string;
            password?: string;
        }>
    >;
};

type StateFromUrl = {
    state: {
        redirect?: string | null;
        dealerId?: string | null;
    };
};

const CredentialStep = ({ setContext }: CredentialStepProps) => {
    const [authenticationResponse, setAuthenticationResponse] = useState(null);
    const { t } = useTranslation();
    const validate = useValidation(credentialFormSchema);
    const client = useApolloClient();

    // we need to be able to dispatch actions to redux
    const dispatch = useDispatch();

    // and also the history object
    const history = useHistory();

    // get current company identifier (there may be none)
    const { companyCode, countryCode, zoneCode, companyId, countryId, zoneId } = useSelector(getCompanyIdentifier);

    // if those values are available, it means we are going for a direct login
    const directLogin = companyId && countryId && zoneId;

    // check if there's a redirect url and dealerId after login
    const { state }: StateFromUrl = useLocation();

    // the callback to execute on submissions
    const onSubmit = useCallback(
        async ({ username, password }) => {
            const variables = { username, password, countryId, zoneId, loginType: LoginType.CI };

            return client
                .mutate({ mutation: authenticate, variables })
                .then(({ data: { authorization } }) => {
                    if (authorization.ok) {
                        const { user } = authorization;

                        // first skip everything if it is super user
                        if (user.isSuperUser) {
                            return { ...authorization, username, password };
                        }
                        const modifiedUser = getUserWithPermissions(user);
                        const availableCompanies = modifiedUser?.availableCompanies || [];
                        const response = { ...authorization, user: modifiedUser, username, password };

                        // if it is direct login, check the user has that country login permission
                        if (directLogin) {
                            if (
                                availableCompanies.some(company =>
                                    company.countries.some(country => country.id === countryId)
                                )
                            ) {
                                return response;
                            }

                            return Promise.reject(
                                new SubmissionError({ _error: t('common.error.invalidUserAndPassword') })
                            );
                        }

                        // if the user has at least one company with login permission
                        if (availableCompanies.length > 0) {
                            return response;
                        }
                    }

                    return Promise.reject(
                        new SubmissionError({
                            _error: authorization.errors.map(({ message }: { message: string }) => message).join(' '),
                        })
                    );
                })
                .catch(handleResponseError);
        },
        [countryId, zoneId, client, directLogin, t]
    );

    const login = useCallback(
        async ({ token, user, username, password }) => {
            const availableCompanies = user?.availableCompanies || [];
            const countries = availableCompanies[0]?.countries || [];
            const dealers = countries[0]?.dealers || [];
            const zones = countries[0]?.zones || [];

            if (state?.redirect) {
                dispatch(setUser(user));
                dispatch(setAuthorization(token));

                if (state.dealerId) {
                    dispatch(setDealerId(state.dealerId));
                } else {
                    const [dealer] = dealers;
                    dispatch(setDealerId(dealer.id));
                }

                history.push(state.redirect);
            }

            // if there is only one company, one country, one dealer and one zone, skip locale selection
            if (availableCompanies.length === 1 && dealers.length === 1 && zones.length === 1) {
                dispatch(setUser(user));
                dispatch(setAuthorization(token));

                const [dealer] = dealers;
                dispatch(setDealerId(dealer.id));

                if (!directLogin) {
                    client
                        .mutate({
                            mutation: authenticate,
                            variables: {
                                username,
                                password,
                                countryId: countries[0].id,
                                zoneId: zones[0].id,
                                dealerId: dealer.id,
                                loginType: LoginType.CI,
                            },
                        })
                        .then(({ data: { authorization: secondAuthorization } }) => {
                            if (secondAuthorization.ok && secondAuthorization.token) {
                                dispatch(setAuthorization(secondAuthorization.token));
                                dispatch(setCompanyCode(availableCompanies[0].code));
                                dispatch(setCountryCode(countries[0].code));
                                dispatch(setZoneCode(zones[0].code));
                                dispatch(setDealerId(dealers[0].id));
                            } else {
                                return Promise.reject(
                                    new SubmissionError({ _error: t('common.error.invalidUserAndPassword') })
                                );
                            }

                            return true;
                        })
                        .then(() => {
                            const url = getRootUrl(user, availableCompanies[0].code, countries[0].code);

                            if (url) {
                                // navigate to available channel
                                history.push(url(availableCompanies[0].code, countries[0].code));
                            } else {
                                // else use default redirect
                                history.push(getCompanyRootUrl(availableCompanies[0].code, countries[0].code));
                            }
                        })
                        .catch(handleResponseError);
                } else {
                    // and finally redirect
                    const locationCode = getLocationCode(countryCode, zoneCode);

                    const url = getRootUrl(user, companyCode, countryCode);

                    if (url) {
                        // navigate to available channel
                        history.push(url(companyCode, locationCode));
                    } else {
                        // else use default redirect
                        history.push(getCompanyRootUrl(companyCode, locationCode));
                    }
                }
            } else {
                // update the context to move onto the next step
                setContext({ step: 'selection', companies: user.availableCompanies, username, password });
            }

            return Promise.resolve();
        },
        [dispatch, directLogin, countryCode, zoneCode, state, client, history, companyCode, setContext, t]
    );

    // the callback to execute on successful submissions
    const onSubmitSuccess = useCallback(
        async ({ hasExistingSession, ...data }) => {
            if (hasExistingSession) {
                setAuthenticationResponse(data);
            } else {
                await login(data);
            }

            return Promise.resolve();
        },
        [login]
    );

    const goToPasswordReset = useCallback(
        event => {
            event.preventDefault();
            setContext({ step: 'requestNewPassword' });
        },
        [setContext]
    );

    const onClose = useCallback(async () => {
        await login(authenticationResponse);

        // then we clear the state
        setAuthenticationResponse(null);
    }, [authenticationResponse, login]);

    return (
        <>
            {!!authenticationResponse && <MultipleSessionModal onClose={onClose} />}
            <CredentialForm
                goToPasswordReset={goToPasswordReset}
                onSubmit={onSubmit}
                onSubmitSuccess={onSubmitSuccess}
                validate={validate}
            />
        </>
    );
};

export default CredentialStep;
