import { useQuery } from '@apollo/client';
// @ts-ignore
import { DarkButton, GrayButton, Actions, Modal } from '@appvantageasia/afc-ui';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import {
    GetSalesPersonQuery,
    GetSalesPersonQueryVariables,
    getSalesPerson,
} from '../../../../../components/routes/ApplicationRoute/Action.graphql';
import SelectField from '../../../../../components/shared/form/SelectField';
import { useCountry } from '../../../../../hookSelectors';
import { getUser as getStateUser } from '../../../../../selectors';

export type DraftModalProps<T> = {
    onClose: () => void;
} & Pick<InjectedFormProps<any, T>, 'handleSubmit' | 'change'>;

const DraftModal = <T extends unknown>({ onClose, change, handleSubmit }: DraftModalProps<T>) => {
    const { t } = useTranslation();
    const user = useSelector(getStateUser);

    if (!user) {
        throw new Error('user missing in state');
    }

    const userName = user.name;
    const userId = user.id;

    useEffect(() => {
        change('draftTo', userId);

        return () => change('draftTo', undefined);
    }, [change, userId]);

    const country = useCountry();
    const { data } = useQuery<GetSalesPersonQuery, GetSalesPersonQueryVariables>(getSalesPerson, {
        fetchPolicy: 'cache-and-network',
        variables: { countryId: country.id },
    });

    const options = useMemo(() => {
        const items = data?.sales?.items || [];

        if (!items.some(item => item.value === userId)) {
            return [...items, { value: userId, label: userName }];
        }

        return items;
    }, [userId, userName, data]);

    return (
        <Modal onClose={onClose} title={t('draftPage.draftModal.title')} showClose showTitle>
            <SelectField
                clearable={false}
                label={t('draftPage.draftModal.fieldLabel')}
                name="draftTo"
                options={options}
            />
            <Actions>
                <GrayButton onClick={onClose}>{t('draftPage.draftModal.button.cancel')}</GrayButton>
                <DarkButton onClick={handleSubmit}>{t('draftPage.draftModal.button.submit')}</DarkButton>
            </Actions>
        </Modal>
    );
};

export default DraftModal;
