import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCountry } from '../../../../hookSelectors';
import LoadingLayer from '../../../common/LoadingLayer';
import { Modal } from '../../../ui/DownloadModal';
import { TextAlignCenter } from '../../../ui/TextAlign';

export type DownloadDocumentsModalProps = {
    onClose: () => void;
    password?: string | null;
    isShow: boolean;
    isDownloading: boolean;
};

const DownloadDocumentsModal = ({ onClose, password, isShow, isDownloading }: DownloadDocumentsModalProps) => {
    const [t] = useTranslation();
    const { code } = useCountry();

    const identifier = useMemo(() => {
        if (code === 'NZ') {
            return t('applicationDetailsPage.downloadDocuments.identifier.dateOfBirth');
        }

        return t('applicationDetailsPage.downloadDocuments.identifier.identity');
    }, [t, code]);

    if (!isShow) {
        return null;
    }

    return (
        <Modal
            containerClassName="modal-container"
            onClose={onClose}
            title={t('applicationDetailsPage.button.downloadDocuments')}
            showClose
            showTitle
        >
            {isDownloading ? (
                <LoadingLayer />
            ) : (
                <TextAlignCenter>
                    <Trans i18nKey="applicationDetailsPage.downloadDocuments.message" values={{ identifier }} />
                    {password && (
                        <>
                            <div style={{ marginTop: '1.25rem' }}>
                                <Trans i18nKey="applicationDetailsPage.downloadDocuments.password" />
                            </div>
                            {password}
                        </>
                    )}
                </TextAlignCenter>
            )}
        </Modal>
    );
};

export default DownloadDocumentsModal;
