// @ts-ignore
import { IconSelectV2 } from '@appvantageasia/afc-ui';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useMedia from 'use-media';
import { loadLanguages } from '../../actions';
import { useCountry } from '../../hookSelectors';
import { getLanguages } from '../../selectors';
import breakpoints from '../../utilities/constants/breakpoints';
import { Container, LanguageOption, DropdownContainer } from '../ui/LanguageSelector';

const useLanguages = () => {
    const { languages } = useCountry();
    const languageOptions = useSelector(getLanguages);
    const mappedLanguageOptions = useMemo(
        () => Object.fromEntries(languageOptions.map(option => [option.code, option.name])),
        [languageOptions]
    );

    return useMemo(() => {
        if (languages?.length) {
            return languages.map(value => ({
                label: mappedLanguageOptions[value],
                value,
            }));
        }

        return [{ value: 'en', label: 'English' }];
    }, [languages, mappedLanguageOptions]);
};

const LanguageSelector = ({ isAtLogin = false }: { isAtLogin?: boolean }) => {
    const { i18n } = useTranslation();
    const languages = useLanguages();

    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const updateLanguage = useCallback(({ value }) => i18n.changeLanguage(value), [i18n]);

    const currentOption = useMemo(() => languages.find(option => option.value === i18n.languages[0]) || null, [
        i18n.languages,
        languages,
    ]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadLanguages());
    }, [dispatch]);

    if (languages.length <= 1) {
        return null;
    }

    if (isMobile) {
        return (
            <DropdownContainer>
                <IconSelectV2
                    isSearchable={false}
                    onChange={updateLanguage}
                    options={languages}
                    value={currentOption}
                />
            </DropdownContainer>
        );
    }

    const languageOptions = languages.map(option => {
        const { value, label } = option;

        return (
            <LanguageOption
                key={value}
                active={value === i18n.languages[0]}
                isAtLogin={isAtLogin}
                onClick={() => updateLanguage(option)}
                type="button"
            >
                {label}
            </LanguageOption>
        );
    });

    return <Container>{languageOptions}</Container>;
};

export default LanguageSelector;
