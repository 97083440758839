import React from 'react';
import { CalculatorValues } from '../../../components/shared/calculator-next/types';
import { FlowStep } from '../../utils/flow';
import { ConfiguratorFlowState } from '../ConfiguratorFlow';
import ConfigPage from '../components/Configurator/ConfigPage';
import CKDMetaTitle from '../components/Configurator/shared/CKDMetaTitle';
import { MiniConfiguratorDetails } from '../components/Summary/Summary';

export type ConfigPageStepValues = MiniConfiguratorDetails & {
    calculatorValues: Partial<CalculatorValues>;
};

class ConfigPageStep extends FlowStep<ConfiguratorFlowState, ConfigPageStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'configPage';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('configuratorPage.label.step');
    }

    public render(): React.ReactElement | null {
        const {
            interiorColor,
            exteriorColor,
            enhancedPackage,
            tequipment,
            timepiece,
            delivery,
            variantId,
            defaultFinanceProduct,
            events,
            promoCode,
            promo,
            dealerId,
        } = this.state;

        const initialValues = {
            interiorColor,
            exteriorColor,
            enhancedPackage,
            tequipment,
            timepiece,
            delivery,
            variantId,
            defaultFinanceProduct,
            events,
            promoCode,
            promo,
            dealerId,
        };

        return (
            <>
                <CKDMetaTitle />
                <ConfigPage initialValues={initialValues} submit={this.submit} />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute(values: ConfigPageStepValues) {
        this.stateStore.update(values);

        return this.nextStep;
    }
}

export default ConfigPageStep;
