import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { DarkButton, GrayButton, Actions, Modal, Footer } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { attachLoading, setNotification } from '../../../actions';
import { ApplicationStatus, Channel, EventExternalSite } from '../../../schema';
import { getCompanyIdentifier, getGlobalPermissions } from '../../../selectors';
import { getCalculatorFromApplication } from '../../../utilities/application';
import { getSnapshotFromApplication } from '../../../utilities/calculator';
import * as urls from '../../../utilities/urls';
import ApplicantStep from '../../shared/form-v2/steps/ApplicantStep';
import FinanceStep from '../../shared/form-v2/steps/FinanceStep';
import Summary from '../../shared/form-v2/steps/Summary';
import TradeInVehicleStep from '../../shared/form-v2/steps/TradeInVehicleStep';
import VehicleStep from '../../shared/form-v2/steps/VehicleStep';
import ModalContent from '../../ui/calculator/ModalContent';
import { ApplicationData } from '../ApplicationRoute';
import ActivityLog from '../ApplicationRoute/ActivityLog';
import { ApplicationFormValues } from '../ApplicationRoute/ApplicationForm';
import { getMutation, getStatusMessages, questions, titles } from '../ApplicationRoute/actions/UpdateStateAction';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

type LeadDetailsProps = {
    application: ApplicationData;
};

export type LeadFormValues = Partial<ApplicationFormValues>;

const LeadDetails = ({
    application,
    initialValues,
}: LeadDetailsProps & InjectedFormProps<LeadFormValues, LeadDetailsProps>) => {
    const { t } = useTranslation();
    const history = useHistory();

    const dispatch = useDispatch();
    const client = useApolloClient();

    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const [isModalShow, setShowModal] = useState(false);

    const { mayManageLead } = useSelector(getGlobalPermissions);

    const url = urls.getLeadsUrl(companyCode, locationCode);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const { hasTradeIn, event, status, id, identifier } = application;

    // Only shared can continue for now
    const hasFinanceReference = application.financeApplication && application.financeApplication?.identifier;
    const canContinue =
        !application.version.isOutdated && application.channel !== Channel.EVENT && !hasFinanceReference;

    const onSubmit = useCallback(() => {
        // generate calculator config from lead
        const calculator = getCalculatorFromApplication(application);

        // get the car model from it
        const { variant } = calculator;

        // finally go to the calculator itself
        // @ts-ignore
        history.pushWithCompanyAndState(
            urls.getNewApplicationCalculatorUrl,
            {
                lead: {
                    // customer(lead) details to prefill in customer details
                    customer: initialValues?.customer,
                    // provide the lead ID
                    leadId: application.id,
                    // push the snapshot for the calculator to get things right
                    snapshot: getSnapshotFromApplication(application),
                    // get calculator values
                    calculator,
                    // provide lead version to give lead version id to booking creation
                    leadVersionId: application.version.id,
                },
            },
            variant,
            'lead'
        );
    }, [history, initialValues, application]);

    const onSubmitModal = useCallback(async () => {
        // wait for the update to be completed
        const nextStatus = ApplicationStatus.AGREEMENTCONCLUDED;

        const promise = getMutation(client, id, nextStatus);
        await dispatch(attachLoading(promise));

        // get notification message
        const message = getStatusMessages(identifier ?? '', nextStatus);

        if (message) {
            dispatch(setNotification(message.title, message.message));
        }

        // then redirect
        history.push(url);
    }, [client, dispatch, history, id, identifier, url]);

    const onCloseModal = () => setShowModal(false);

    let showAgreementConcluded = false;

    const isPorscheFinder = event?.setting?.externalSite === EventExternalSite.PORSCHEFINDER;
    const isIccCheckout = event?.setting?.externalSite === EventExternalSite.ICC;

    if (
        (isPorscheFinder || isIccCheckout) &&
        event?.setting?.reservationPeriod &&
        event?.setting?.reservationPeriod > 0 &&
        status === ApplicationStatus.LEADRECEIVED &&
        mayManageLead
    ) {
        showAgreementConcluded = true;
    }

    return (
        <Tabs>
            <TabList>
                <Tab>Details</Tab>
                <Tab>Activity Log</Tab>
            </TabList>
            <TabPanel>
                <Summary application={application} disabled={!mayManageLead} />
                <ApplicantStep disabled />
                {hasTradeIn && <TradeInVehicleStep application={application} />}
                <VehicleStep application={application} />
                <FinanceStep application={application} disabled />
                <FootContainer className="appActionBtn">
                    <FootBar>
                        <FootBarRow>
                            {onCancel && (
                                <FootBarButton onClick={onCancel}>{t('leadDetailsPage.button.cancel')}</FootBarButton>
                            )}
                            <div style={{ flex: 1 }} />
                            {canContinue && (
                                <FootBarButton disabled={!mayManageLead} onClick={onSubmit}>
                                    {t('leadDetailsPage.button.continueApplication')}
                                </FootBarButton>
                            )}
                            {showAgreementConcluded && (
                                <Footer.FootBarButton onClick={() => setShowModal(true)}>
                                    {t('leadDetailsPage.button.concludeAgreement')}
                                </Footer.FootBarButton>
                            )}
                        </FootBarRow>
                    </FootBar>
                </FootContainer>
                {isModalShow && (
                    <Modal onClose={onCloseModal} title={get(ApplicationStatus.AGREEMENTCONCLUDED, titles)} showTitle>
                        <ModalContent>
                            <p>{get(ApplicationStatus.AGREEMENTCONCLUDED, questions)}</p>
                        </ModalContent>
                        <Actions>
                            <GrayButton onClick={onCloseModal}>cancel</GrayButton>
                            <DarkButton onClick={onSubmitModal}>Ok</DarkButton>
                        </Actions>
                    </Modal>
                )}
            </TabPanel>
            <TabPanel>
                <ActivityLog application={application} />
            </TabPanel>
        </Tabs>
    );
};

export default reduxForm<LeadFormValues, LeadDetailsProps>({ form: 'lead' })(LeadDetails);
