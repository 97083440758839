import React from 'react';
import { useSelector } from 'react-redux';
import { BankPresetOption } from '../../../schema';
import { getGlobalPermissions } from '../../../selectors';
import AFCApplicantStep from '../../shared/form-v2/steps/ApplicantStep';
import FinanceStep from '../../shared/form-v2/steps/FinanceStep';
import GuarantorStep from '../../shared/form-v2/steps/GuarantorStep';
import PMEApplicantStep from '../../shared/form-v2/steps/PME/ApplicantStep';
import PaymentDetails from '../../shared/form-v2/steps/PaymentDetails';
import Summary from '../../shared/form-v2/steps/Summary';
import TradeInVehicleStep from '../../shared/form-v2/steps/TradeInVehicleStep';
import VehicleStep from '../../shared/form-v2/steps/VehicleStep';
import useBankPresetStep from '../../utilities/useBankPresetStep';
import useFormValues from '../../utilities/useFormValues';
import AddGuarantorProvider from '../ApplicationRoute/AddGuarantorProvider';
import RecalculateProvider from '../ApplicationRoute/RecalculateProvider';
import { ApplicationFormValues } from './ApplicationForm';
import { ApplicationData } from './index';

type ApplicationProps = {
    application: ApplicationData;
    disabled?: boolean;
    allowPrimaryInfoChanges?: boolean;
};

type ApplicantStepComponentType = ({
    allowPrimaryInfoChanges,
    disabled,
}: {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
}) => JSX.Element;

const Application = ({ application, disabled, allowPrimaryInfoChanges }: ApplicationProps) => {
    const { mayManageReservation } = useSelector(getGlobalPermissions);

    // get form values
    const formValues = useFormValues<ApplicationFormValues>();

    const { hasTradeIn } = application;

    const hasPayment = !application.isLead && application.reservationDeposit;

    const ApplicantStep = useBankPresetStep<ApplicantStepComponentType>(
        {
            [BankPresetOption.PMESA]: PMEApplicantStep,
            [BankPresetOption.ENBDBANK]: PMEApplicantStep,
            [BankPresetOption.AFFINBANK]: AFCApplicantStep,
            [BankPresetOption.NONE]: AFCApplicantStep,
            [BankPresetOption.TTBBANK]: AFCApplicantStep,
        },
        formValues.financeProduct?.bank?.presetOption
    );

    return (
        <>
            <Summary application={application} disabled={!mayManageReservation} />
            <AddGuarantorProvider application={application}>
                {guarantorProps => (
                    <ApplicantStep
                        allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                        disabled={disabled}
                        {...guarantorProps}
                    />
                )}
            </AddGuarantorProvider>
            {formValues?.guarantor?.id && <GuarantorStep disabled={disabled} />}
            {hasTradeIn && <TradeInVehicleStep application={application} />}
            <VehicleStep application={application} disabled={disabled} />
            {hasPayment && hasPayment !== 0 && <PaymentDetails application={application} />}
            <RecalculateProvider application={application}>
                {openRecalculate => (
                    <FinanceStep application={application} disabled={disabled} openRecalculate={openRecalculate} />
                )}
            </RecalculateProvider>
        </>
    );
};

export default Application;
