import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px 30px;

    @media (max-width: 450px) {
        padding: 10px 15px;
    }
`;

export const Title = styled.div`
    font-size: 1.28rem;
    border-bottom: 1px solid #ced4da;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 39px;
    text-transform: capitalize;
    margin-bottom: 10px;
`;

export const Label = styled.div`
    min-width: 200px;
    font-size: 1.43rem;
    color: #000000;
`;

export const Action = styled.button`
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: right;
    cursor: pointer;

    svg {
        color: #000000;
    }
`;

type PortletProps = {
    children: React.ReactNode;
    closeable: boolean;
    open: boolean;
    title: string;
};

const Portlet = ({ title, open: initOpen, closeable, children }: PortletProps) => {
    const [open, setOpen] = useState(initOpen);

    const toggle = useCallback(() => {
        if (closeable) {
            setOpen(state => !state);
        }
    }, [setOpen, closeable]);

    return (
        <Container>
            <Title>
                <Label>{title}</Label>
                {closeable && (
                    <Action onClick={toggle}>
                        <Icon icon={open ? faAngleUp : faAngleDown} />
                    </Action>
                )}
            </Title>
            {open && <div>{children}</div>}
        </Container>
    );
};

export default Portlet;
