import { pick, getOr } from 'lodash/fp';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useListContextFromState = () => {
    const { listContext: initialListContext } = useLocation().state ?? {};

    const get = useMemo(() => {
        return (key, defaultValue = undefined) => getOr(defaultValue, key, initialListContext);
    }, [initialListContext]);

    return [initialListContext, get];
};

const useListContext = (paging, sorting, search) =>
    useMemo(
        () => ({
            paging: pick(['page', 'pageSize'], paging),
            sorting: pick(['key', 'order'], sorting),
            search,
        }),
        [paging, sorting, search]
    );

export default useListContext;
