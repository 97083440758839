import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Channel } from '../../schema';
import HeaderLogoButton from '../ui/header/HeaderLogoButton';
import HeaderLogoContainer from '../ui/header/HeaderLogoContainer';
import useHomeUrl from '../utilities/useHomeUrl';
import CompanyLogo from './CompanyLogo';

type HeaderLogoProps = {
    channel?: Channel;
};

const HeaderLogo = ({ channel }: HeaderLogoProps) => {
    const history = useHistory();
    const { url, state } = useHomeUrl(channel) || {};

    const clickable = useCallback(() => {
        if (url) {
            // @ts-ignore
            history.pushWithCompanyAndState(url, state);
        }
    }, [history, state, url]);

    return (
        <HeaderLogoContainer>
            <HeaderLogoButton onClick={clickable}>
                <CompanyLogo />
            </HeaderLogoButton>
        </HeaderLogoContainer>
    );
};

HeaderLogo.propTypes = {
    channel: PropTypes.string,
};

export default HeaderLogo;
