import { PrimaryButton } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import * as yup from 'yup';
import { useContentTranslation } from '../../../i18n';
import { getCompanyIdentifier } from '../../../selectors';
import { requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import SelectField from '../../shared/form/SelectField';
import FormActions, { Error } from '../../ui/form/FormActions';
import LoginLogo from '../../ui/login/LoginLogo';
import Title from '../../ui/login/Title';

/* handle company options and side effects */
const useCompanies = (contextInfo, companyCode) => {
    const dispatch = useDispatch();
    const { ct } = useContentTranslation();

    const companies = useMemo(() => {
        if (!contextInfo) {
            // the user is not here yet
            return [];
        }

        return contextInfo.map(company => ({
            value: company.id,
            code: company.code,
            label: ct(company.name),
            countries: company.countries,
        }));
    }, [contextInfo, ct]);

    useEffect(() => {
        // we are going to either select or reset it
        if (companies.length === 1) {
            dispatch(change('loginSelection', 'companyId', companies[0].value));
        } else if (companyCode && companies.length > 1) {
            const selectedCompany = companies.filter(company => company.code === companyCode);
            dispatch(
                change('loginSelection', 'companyId', selectedCompany.length === 1 ? selectedCompany[0].value : null)
            );
        }
    }, [companies, companyCode, dispatch]);

    return companies;
};

/* handle country options and side effects */
const useCountries = (companies, countryCode) => {
    const dispatch = useDispatch();
    const { ct } = useContentTranslation();

    // we need to know the current company which have been selected
    const { companyId } = useSelector(getFormValues('loginSelection'));

    const countries = useMemo(() => {
        if (!companies.length || !companyId) {
            // we do not have yet the companies
            return [];
        }

        // look for the company
        const company = companies.find(item => item.value === companyId);

        // return an empty list if it does not exist
        return company
            ? company.countries.map(country => ({
                  value: country.id,
                  label: country.mlcName ? ct(country.mlcName) : country.name,
                  code: country.code,
                  dealers: country.dealers,
                  zones: country.zones,
              }))
            : [];
    }, [companies, companyId]);

    useEffect(() => {
        // we are going to either select or reset it
        if (countries.length === 1) {
            dispatch(change('loginSelection', 'countryId', countries[0].value));
        } else if (countryCode && countries.length > 1) {
            const selectedCountry = countries.filter(country => country.code === countryCode);
            dispatch(
                change('loginSelection', 'countryId', selectedCountry.length === 1 ? selectedCountry[0].value : null)
            );
        }
    }, [countries, countryCode, dispatch]);

    return countries;
};

/* handle zone options and side effects */
const useZones = (countries, zoneCode) => {
    const dispatch = useDispatch();
    const { ct } = useContentTranslation();

    // we need to know the current company which have been selected
    const { countryId } = useSelector(getFormValues('loginSelection'));

    const zones = useMemo(() => {
        if (!countries.length || !countries) {
            // we do not have yet the companies
            return [];
        }

        // look for the company
        const country = countries.find(item => item.value === countryId);

        // return an empty list if it does not exist
        return country ? country.zones.map(zone => ({ value: zone.id, label: ct(zone.name), code: zone.code })) : [];
    }, [countries, countryId]);

    useEffect(() => {
        // we are going to either select or reset it
        if (zones.length === 1) {
            dispatch(change('loginSelection', 'zoneId', zones[0].value));
        } else if (zoneCode && zones.length > 1) {
            const selectedZone = zones.filter(zone => zone.code === zoneCode);
            dispatch(change('loginSelection', 'zoneId', selectedZone.length === 1 ? selectedZone[0].value : null));
        }
    }, [zones, dispatch, zoneCode]);

    return zones;
};

/* handle dealer options and side effects */
const useDealers = countries => {
    const dispatch = useDispatch();
    const { ct } = useContentTranslation();

    // we need to know the current company which have been selected
    const { countryId } = useSelector(getFormValues('loginSelection'));

    const dealers = useMemo(() => {
        if (!countries.length || !countries) {
            // we do not have yet the companies
            return [];
        }

        // look for the company
        const country = countries.find(item => item.value === countryId);

        // return an empty list if it does not exist
        return country ? country.dealers.map(dealer => ({ value: dealer.id, label: ct(dealer.name) })) : [];
    }, [countries, countryId, ct]);

    useEffect(() => {
        // countries have changed
        const value = dealers.length === 1 ? dealers[0].value : null;
        // update form value
        dispatch(change('loginSelection', 'dealerId', value));
    }, [dealers, dispatch]);

    return dealers;
};

const SelectionForm = ({ handleSubmit, companies: contextInfo }) => {
    const { companyCode, countryCode, zoneCode } = useSelector(getCompanyIdentifier);
    const { t } = useTranslation();
    // get options
    const companies = useCompanies(contextInfo, companyCode);
    const countries = useCountries(companies, countryCode);
    const zones = useZones(countries, zoneCode);
    const dealers = useDealers(countries);

    return (
        <form>
            <LoginLogo />
            <Title withMargin>{t('companyAuthenticationPage.title.localeSelection')}</Title>
            <SelectField.Outline
                disabled={companies.length <= 1}
                label={t('companyAuthenticationPage.label.company')}
                name="companyId"
                options={companies}
            />
            <SelectField.Outline
                disabled={countries.length <= 1}
                label={t('companyAuthenticationPage.label.country')}
                name="countryId"
                options={countries}
            />
            <SelectField.Outline
                disabled={zones.length <= 1}
                label={t('companyAuthenticationPage.label.zone')}
                name="zoneId"
                options={zones}
            />
            <SelectField.Outline
                disabled={dealers.length <= 1}
                label={t('companyAuthenticationPage.label.dealer')}
                name="dealerId"
                options={dealers}
            />
            <FormActions>
                <div>
                    <PrimaryButton onClick={handleSubmit} type="button">
                        {t('companyAuthenticationPage.button.next')}
                    </PrimaryButton>
                </div>
                <FormError form="loginSelection">{error => <Error>{error}</Error>}</FormError>
            </FormActions>
        </form>
    );
};

SelectionForm.propTypes = {
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            countries: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    zones: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.string.isRequired,
                            name: PropTypes.shape({}).isRequired,
                        })
                    ),
                })
            ),
            id: PropTypes.string.isRequired,
            name: PropTypes.shape({}).isRequired,
        })
    ),
    handleSubmit: PropTypes.func.isRequired,
};

export const schema = t =>
    yup.object().shape({
        companyId: requiredString(t('companyAuthenticationPage.error.companyRequired')),
        countryId: requiredString(t('companyAuthenticationPage.error.countryRequired')),
        dealerId: requiredString(t('companyAuthenticationPage.error.dealerRequired')),
        zoneId: requiredString(t('companyAuthenticationPage.error.zoneRequired')),
    });

export default reduxForm({ form: 'loginSelection' })(SelectionForm);
