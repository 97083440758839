import React from 'react';
import {
    createInsuranceApplication,
    CreateInsuranceApplicationMutation,
    CreateInsuranceApplicationMutationVariables,
} from '../../../api/draft.graphql';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { ApplicationFlowSource, Channel } from '../../../schema';
import { prepareForGraphQL } from '../../../utilities/forms';
import { getInsuranceDraftUrl, getLocationCode } from '../../../utilities/urls';
import { ReduxFormFlowStep } from '../../utils/flow';
import { InsuranceNewFlowState } from '../types';
import DraftRoute from './components/DraftPage';

export type InsuranceCalculatorStepValues = {
    driverLicensePassDate: Date;
    claimDiscount: number;
    occupation: string;
    ncd: string;
    existingCarPlate: string;
    displacement: number;
    price: number;
};

export type DraftStepValues = {
    draftTo?: string; // assignee ID to provide when drafting
    customer: ApplicationCustomerDataFragment;
    uploadId?: string;
    insurance?: {
        calculator: InsuranceCalculatorStepValues;
        comment?: string;
    };
};

class DraftStep extends ReduxFormFlowStep<InsuranceNewFlowState, DraftStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return ApplicationFlowSource.DRAFT;
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('draftPage.label.step');
    }

    public get channelSetting() {
        const { channel, country } = this.state;

        switch (channel) {
            case Channel.EXPRESS:
                return country.channelSetting.express;

            case Channel.USED:
                return country.channelSetting.used;

            case Channel.NEW:
                return country.channelSetting.new;

            default:
                throw new Error('Not implemented');
        }
    }

    public render(): React.ReactElement | null {
        const { calculator, zone, channel, customer, dealerId } = this.state;

        return (
            <>
                <HelmetTitle channel={channel} title="Applicant Details" />
                <DraftRoute
                    backStep={this.getBackContext()}
                    channel={channel}
                    customer={customer}
                    dealerId={dealerId}
                    insuranceCalculator={calculator}
                    submit={this.submit}
                    zone={zone}
                    disableFinanceApplication
                />
            </>
        );
    }

    protected async execute(values: DraftStepValues) {
        const { apolloClient } = this;
        const { zone, calculator, channel, application, dealerId } = this.state;

        if (!application) {
            throw new Error('missing application');
        }

        const { customer, proceedWithCustomerDevice, variant } = application;

        const insuranceApplicationPayload = prepareForGraphQL({
            applicationVersionId: application?.version.id,
            // drafting
            assigneeId: values.draftTo,
            draft: !!values.draftTo,
            // add the zone id
            zoneId: zone.id,
            // and dealer id
            dealerId,
            customerId: customer.id,
            uploadId: values.uploadId,
            // add the channel
            channel,
            variantId: variant.id,
            locale: this.i18n.languages[0],
            calculator: {
                ...values?.insurance?.calculator,
                displacement: calculator?.displacement || 0,
                price: calculator?.carPrice || 0,
                coe: calculator?.coe ? { amount: calculator?.coe } : undefined,
            },
            comment: values?.insurance?.comment,
            proceedWithCustomerDevice: !values.draftTo && proceedWithCustomerDevice,
        });

        const draftResponse = await apolloClient.mutate<
            CreateInsuranceApplicationMutation,
            CreateInsuranceApplicationMutationVariables
        >({
            mutation: createInsuranceApplication,
            variables: { data: insuranceApplicationPayload },
        });

        const { insuranceApplication, token } = draftResponse.data?.response || {};

        if (!insuranceApplication) {
            throw new Error('application missing in state');
        }

        // update the state
        this.stateStore.update({ insuranceApplication, token });

        if (insuranceApplicationPayload.draft) {
            // trigger events
            this.flow.dispatchCompleted();

            // no next step from here
            return null;
        }

        if (this.isLastStep) {
            const { company, country, zone } = this.state;

            const locationCode = getLocationCode(country.code, zone.code);
            const url = getInsuranceDraftUrl(company.code, locationCode, insuranceApplication.version.id);

            this.flow.history.push(url, { token });

            // no next step from here
            return null;
        }

        return this.nextStep;
    }
}

export default DraftStep;
