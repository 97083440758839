import qs from 'qs';
import { useEffect } from 'react';
import { ApplicationFlowSource } from '../../../schema';
import { TransactionStatus } from '../../../utilities/constants/transactionStatus';

const localKey = 'porschePaymentCallback';

type Response = {
    applicationId: string;
    status: TransactionStatus;
};

export type PorschePaymentCallbackSession = {
    applicationId: string;
    source: ApplicationFlowSource;
    response?: Response;
    origin: string;
};

export const startPorschePaymentSession = (source: PorschePaymentCallbackSession['source'], applicationId: string) => {
    const data: PorschePaymentCallbackSession = {
        applicationId,
        source,
        origin: window.location.href,
    };

    // persist session data in local storage
    localStorage.setItem(localKey, JSON.stringify(data));
};

export const getPorschePaymentSession = (applicationId: string, source: PorschePaymentCallbackSession['source']) => {
    const rawData = localStorage.getItem(localKey);

    // there's no data in local storage
    if (!rawData) {
        return null;
    }

    const data = JSON.parse(rawData) as PorschePaymentCallbackSession;

    // validate if application and source are the same
    if (data.applicationId === applicationId && data.source === source && data.response) {
        return data;
    }

    return null;
};

export const cleanPorschePaymentSession = () => localStorage.removeItem(localKey);

const PorschePaymentCallback = () => {
    const localState = localStorage.getItem(localKey);

    useEffect(() => {
        if (!localState) {
            window.location.replace(window.location.origin);

            return;
        }

        const session = JSON.parse(localState) as PorschePaymentCallbackSession;
        const response = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as Response;

        if (response.applicationId !== session.applicationId) {
            // stop here
            window.location.replace(window.location.origin);

            return;
        }

        const nextData: PorschePaymentCallbackSession = { ...session, response };

        // update the state
        localStorage.setItem(localKey, JSON.stringify(nextData));

        const url = new URL(session.origin);

        // update search/query
        url.search = window.location.search;

        window.location.replace(url.href);
    }, [localState]);

    return null;
};

export default PorschePaymentCallback;
