import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, WrappedFieldArrayProps, Field, GenericFieldArray } from 'redux-form';
import * as yup from 'yup';
import DateField from '../../../../../components/shared/form-v2/DateField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import { BankPresetOption, CustomerDetailsSource } from '../../../../../schema';
import { onNumKeyPress } from '../../../../../utilities/forms';

type LicenseItemProps = {
    name: string;
    isGrid?: boolean;
};

const LicenseItem = ({ name, isGrid = false }: LicenseItemProps) => {
    const { t } = useTranslation();
    if (isGrid) {
        return (
            <>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={false}
                        label={t('customerDetails.label.drivingLicenseNumber')}
                        name={`${name}.number`}
                        onKeyPress={onNumKeyPress}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField label={t('customerDetails.label.drivingLicenseExpiry')} name={`${name}.expiryDate`} />
                </div>
            </>
        );
    }

    return (
        <>
            <TextField
                disabled={false}
                label={t('customerDetails.label.drivingLicenseNumber')}
                name={`${name}.number`}
                onKeyPress={onNumKeyPress}
            />
            <DateField label={t('customerDetails.label.drivingLicenseExpiry')} name={`${name}.expiryDate`} />
        </>
    );
};

type LicenseArrayPorps = { isGrid?: boolean } & WrappedFieldArrayProps<any>;

const LicenseArray = ({ fields, isGrid }: LicenseArrayPorps) => {
    const items = fields.map((field: string, index: number) => (
        <LicenseItem key={field} isGrid={isGrid} name={field} />
    ));

    useEffect(() => {
        if (fields.length > 0) {
            return;
        }

        fields.push({
            classes: [{}],
            source: CustomerDetailsSource.MANUAL,
        });
    }, []);

    return items;
};

type DrivingLicenseProps = {
    isGrid?: boolean;
};

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const DrivingLicense = ({ ...props }: DrivingLicenseProps) => (
    <FieldArrayCustom component={LicenseArray} name="details.drivingLicense" rerenderOnEveryChange {...props} />
);

type LicenseSchema = {
    expiryDate: yup.StringSchema<string, object>;
    number: yup.StringSchema<string, object>;
};

export const schema = (t: TFunction) => ({
    drivingLicense: yup
        .array()
        .of(
            // @ts-ignore
            yup.lazy((value, options) => {
                const isEnbdBankPreset =
                    get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

                if (isEnbdBankPreset) {
                    return yup.object().shape({
                        expiryDate: yup.string().nullable().notRequired(),
                        number: yup.string().nullable().notRequired(),
                    });
                }

                const licenseSchema: LicenseSchema = {
                    expiryDate: yup.string().required(t('common.error.required')),
                    number: yup
                        .string()
                        .nullable()
                        .required(t('common.error.required'))
                        .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
                };

                return yup.object().shape(licenseSchema);
            })
        )
        .nullable()
        .required(),
});

export default DrivingLicense;
