import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { attachLoading, setNotification } from '../../../../../actions';
import { submitPayment, submitAdditionalDetails } from '../../../../../api/adyen';
import {
    cleanAydenSession,
    getAydenSession,
    startAydenSession,
} from '../../../../../components/routes/wip/AydenCallback';
import { ApplicationFlowSource } from '../../../../../schema';
import PaymentStatus from '../../../../../utilities/constants/paymentStatus';

const usePaymentCallback = (
    applicationId: string,
    source: ApplicationFlowSource,
    token: string,
    setPaymentResult: (resultCode: any) => void
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onResult = useCallback(
        resultCode => {
            switch (resultCode) {
                case PaymentStatus.REFUSED:
                    dispatch(
                        setNotification(
                            t('notification.paymentRefused.title'),
                            t('notification.paymentRefused.message')
                        )
                    );
                    break;

                case PaymentStatus.ERROR:
                    dispatch(
                        setNotification(t('notification.paymentError.title'), t('notification.paymentError.message'))
                    );
                    break;

                default:
                    break;
            }

            setPaymentResult(resultCode);
        },
        [setPaymentResult, dispatch, t]
    );

    const processPaymentResponse = useCallback(
        ({ action, resultCode }, component) => {
            if (action) {
                startAydenSession(source, applicationId);
                component.handleAction(action);

                return;
            }

            if (getAydenSession(applicationId, source)) {
                cleanAydenSession();
            }

            onResult(resultCode);
        },
        [applicationId, source, onResult]
    );

    useEffect(() => {
        const session = getAydenSession(applicationId, source);

        if (session) {
            cleanAydenSession();

            onResult(session.response?.resultCode);
        }
    }, [applicationId, onResult, source]);

    const onSubmit = useCallback(
        async (state, component) => {
            const paymentRequest = { ...state.data };

            // submit payment
            const result = await dispatch(attachLoading(submitPayment(paymentRequest, token)));

            processPaymentResponse(result, component);
        },
        [dispatch, processPaymentResponse, token]
    );

    const onAdditionalDetails = useCallback(
        async (state, component) => {
            const result = await dispatch(attachLoading(submitAdditionalDetails({ ...state.data }, token)));
            processPaymentResponse(result, component);
        },
        [dispatch, processPaymentResponse, token]
    );

    return {
        onAdditionalDetails,
        onSubmit,
    };
};

export default usePaymentCallback;
