import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions';
import { FinanceDataFragment } from '../../../../../components/data/useFinanceProducts.graphql';
import { BankDataFragment } from '../../../../../components/data/useLoadBank.graphql';
import { InsuranceCompanyDataFragment } from '../../../../../components/data/useLoadInsuranceCompany.graphql';
import { PromoDataFragment } from '../../../../../components/data/useLoadPromo.graphql';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { startMyInfoSession } from '../../../../../components/routes/wip/MyInfoCallback';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import { ApplicationFlowSource, EventExternalSite } from '../../../../../schema';
import { CarOfInterestType } from '../../../steps/EventDraftStep';
import {
    MyInfoList,
    MyInfoListWrap,
    MyInfoText,
    SingpassButtonWrap,
    SingpassErrorText,
    SingpassText,
    SingpassWrap,
    Title,
} from '../ui';
import { ReactComponent as SingpassButtonImage } from '../../../../../assets/images/singpass_btn.svg';
import { ReactComponent as SingpassLogo } from '../../../../../assets/images/singpass_logo.svg';

export type MyInfoProps = {
    dealerId?: string;
    myInfo: {
        requestUrl: string;
    };
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    financeProduct?: FinanceDataFragment;
    promo?: PromoDataFragment | null;
    withMyInfoError?: boolean;
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    isCalculatorEnabled?: boolean;
    carOfInterest?: CarOfInterestType;
    bookingId?: string;
    step: string;
    event: EventDataFragment;
    appliedForFinancing?: boolean;
    bank?: BankDataFragment;
    appliedForInsurance?: boolean;
    insuranceCalculator?: Partial<CalculatorInsuranceValues>;
    insuranceCompany?: InsuranceCompanyDataFragment;
};

const MyInfo = ({ myInfo: { requestUrl }, step, event, ...props }: MyInfoProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { calculator, dealerId, variant, financeProduct, withMyInfoError, carOfInterest } = props;
    const isMarketingReconsent = event.setting.externalSite === EventExternalSite.MARKETINGRECONSENT;

    const allowMyInfo = useMemo(() => {
        // if we have calculator, variant, finance product and dealer id
        // user came from porsche finder flow
        if (dealerId && calculator && variant && financeProduct) {
            return true;
        }

        // otherwise it will be normal flow
        // we need to check if there's dealer id and variant id available also variant name
        return dealerId && (!!carOfInterest?.variantId || !!carOfInterest?.variantName);
    }, [calculator, dealerId, carOfInterest, financeProduct, variant]);

    const goToMyInfo = useCallback(() => {
        if (allowMyInfo) {
            // no application created yet
            startMyInfoSession(requestUrl, ApplicationFlowSource.EVENT, '', '', step, props);
        } else {
            dispatch(
                setNotification(t('notification.eventMyInfoError.title'), t('notification.eventMyInfoError.message'))
            );
        }
    }, [allowMyInfo, dispatch, props, requestUrl, t, step]);

    return (
        <div>
            <Title>{t('eventMyInfo.title')}</Title>
            <MyInfoText>
                {t(isMarketingReconsent ? 'eventMyInfo.marketingReconsentDescription' : 'eventMyInfo.description')}
            </MyInfoText>
            <MyInfoListWrap>
                <MyInfoList>
                    <Trans
                        components={{ l: <li /> }}
                        i18nKey={isMarketingReconsent ? 'eventMyInfo.marketingReconsentList' : 'eventMyInfo.list'}
                    />
                </MyInfoList>
            </MyInfoListWrap>
            <SingpassWrap>
                <SingpassLogo />
                <SingpassText>{t('eventMyInfo.singpassDescription')}</SingpassText>
                <SingpassButtonWrap onClick={goToMyInfo}>
                    <SingpassButtonImage />
                </SingpassButtonWrap>
                {withMyInfoError && <SingpassErrorText>{t('common.error.singpass')}</SingpassErrorText>}
            </SingpassWrap>
        </div>
    );
};

export default MyInfo;
