import styled, { css } from 'styled-components';
import SelectOption from './SelectOption';

export const DropdownContainer = styled.div`
    margin: 0 auto;

    max-width: 320px;
`;

export type SelectContainerProps = { isBack?: boolean };

const SelectContainer = styled.ul<SelectContainerProps>`
    min-height: 32px;

    padding-top: 1px;
    padding-left: 1px;

    margin: 0;

    white-space: nowrap;
    font-size: 0.85rem;
    line-height: 30px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ${props =>
        props.isBack &&
        css`
            ${SelectOption}:first-child {
                background-color: #bbbbbb;
            }
        `}
`;

export default SelectContainer;
