import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../../../../hookSelectors';
import { Channel } from '../../../../../schema';
import { MonthlyPaymentOption } from '../useMonthlyPaymentOptions';
import { FilterDefinition } from './index';

const useMonthlyPaymentFilter = (
    monthlyPayment: MonthlyPaymentOption['value'] | null,
    monthlyPayments: MonthlyPaymentOption[],
    setMonthlyPayment: (monthlyPayment: MonthlyPaymentOption['value'] | null) => void,
    channel: Channel
): FilterDefinition<string | MonthlyPaymentOption['value']> | null => {
    const { t } = useTranslation();
    const { channelSetting } = useCountry();
    const filterByMonthlyInstalment = useMemo(() => {
        switch (channel) {
            case Channel.USED:
                return channelSetting.used.filterByMonthlyInstalment;

            case Channel.NEW:
                return channelSetting.new.filterByMonthlyInstalment;

            default:
                return false;
        }
    }, [channel, channelSetting.new.filterByMonthlyInstalment, channelSetting.used.filterByMonthlyInstalment]);

    // callback for changes
    const onChange = useCallback(
        ({ value }) => {
            if (value === 'all') {
                // reset monthly payment
                setMonthlyPayment(null);
            } else {
                // switch monthly payments
                setMonthlyPayment(value);
            }
        },
        [setMonthlyPayment]
    );

    return useMemo(() => {
        if (!filterByMonthlyInstalment) {
            // this filter has been disabled
            return null;
        }

        if (monthlyPayments.length === 0) {
            // no option
            return null;
        }

        const options = [
            // all option
            { id: 'all', value: 'all', label: t('chooserPage.filter.selectAll') },
            // monthly payment options
            ...monthlyPayments,
        ];

        return {
            key: 'monthlyPayment',
            title: t('chooserPage.filter.monthlyPaymentTitle'),
            onChange,
            options,
            value: monthlyPayment ?? 'all',
        };
    }, [filterByMonthlyInstalment, monthlyPayment, monthlyPayments, onChange, t]);
};

export default useMonthlyPaymentFilter;
