import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as apis from '../api';
import { State } from '../reducers';
import { OptionsState } from '../reducers/options';
import * as selectors from '../selectors';
import { attachLoading } from './loading';

export const OPTIONS_SET = '@OPTIONS/SET';

export const OPTIONS_UNSET = '@OPTIONS/UNSET';

export const OPTIONS_UNSET_ALL = '@OPTIONS/UNSET_ALL';

export type SetOptions<T> = {
    type: typeof OPTIONS_SET;
    key: string;
    value: T;
};

export type UnsetOptions = { type: typeof OPTIONS_UNSET; key: string };

export type UnsetAllOptions = { type: typeof OPTIONS_UNSET_ALL };

export const setOptions = <T>(key: string, value: T): SetOptions<T> => ({ type: OPTIONS_SET, key, value });

export const unsetOptions = (key: string) => ({ type: OPTIONS_UNSET, key });

export const unsetAllOptions = () => ({ type: OPTIONS_UNSET_ALL });

export type OptionsActions = SetOptions<any> | UnsetOptions | UnsetAllOptions;

const makeLoadOption = <T>(
    optionKey: string,
    selector: (state: State) => T,
    fetchFunction: (client: ApolloClient<NormalizedCacheObject>) => Promise<T>
) => (): ThunkAction<
    Promise<T | undefined>,
    State,
    { client: ApolloClient<NormalizedCacheObject> },
    AnyAction
> => async (dispatch, getState, { client }) => {
    // get the options at first
    const state = getState();
    const options = selector(state);

    if (!isEmpty(options)) {
        return options;
    }

    const promise = fetchFunction(client);
    const data = await dispatch(attachLoading(promise));
    // then update state
    dispatch(setOptions(optionKey, data));

    return data;
};

export const loadNationalities = makeLoadOption<OptionsState['nationalities']>(
    'nationalities',
    selectors.getNationalities,
    apis.fetchNationalities
);

export const loadOccupations = makeLoadOption<OptionsState['occupations']>(
    'occupations',
    selectors.getOccupations,
    apis.fetchOccupations
);

export const loadDownpaymentTo = makeLoadOption<OptionsState['downpaymentTo']>(
    'downpaymentTo',
    selectors.getDownpaymentTo,
    apis.fetchDownpaymentTo
);

export const loadCountries = makeLoadOption<OptionsState['nationalities']>(
    'countries',
    selectors.getCountries,
    apis.fetchCountries
);

export const loadStatusOptions = makeLoadOption<OptionsState['statusOptions']>(
    'statusOptions',
    selectors.getStatusOptions,
    apis.fetchStatusOptions
);

export const loadDialingCodes = makeLoadOption<OptionsState['dialingCodes']>(
    'dialingCodes',
    selectors.getDialingCodes,
    apis.fetchDialingCodes
);

export const loadCountriesDialingCodes = makeLoadOption<OptionsState['countriesDialingCodes']>(
    'countriesDialingCodes',
    selectors.getCountriesDialingCodes,
    apis.fetchCountriesDialingCodes
);

export const loadGenders = makeLoadOption<OptionsState['genders']>('genders', selectors.getGenders, apis.fetchGenders);

export const loadRaces = makeLoadOption<OptionsState['races']>('races', selectors.getRaces, apis.fetchRaces);

export const loadMaritalStatuses = makeLoadOption<OptionsState['maritalStatuses']>(
    'maritalStatuses',
    selectors.getMaritalStatuses,
    apis.fetchMaritalStatus
);

export const loadResidentialStatuses = makeLoadOption<OptionsState['residentialStatuses']>(
    'residentialStatuses',
    selectors.getResidentialStatuses,
    apis.fetchResidentialStatus
);

export const loadDrivingLicenseValidityCodes = makeLoadOption<OptionsState['drivingLicenseValidityCodes']>(
    'drivingLicenseValidityCodes',
    selectors.getDrivingLicenseValidityCodes,
    apis.fetchDrivingLicenseValidityCodes
);

export const loadDrivingLicenseClasses = makeLoadOption<OptionsState['drivingLicenseClasses']>(
    'drivingLicenseClasses',
    selectors.getDrivingLicenseClasses,
    apis.fetchDrivingLicenseClasses
);

export const loadCities = makeLoadOption<OptionsState['cities']>('cities', selectors.getCities, apis.fetchCities);

export const loadTimezones = makeLoadOption<OptionsState['timezones']>(
    'timezones',
    selectors.getTimezones,
    apis.fetchTimezones
);

export const loadLanguages = makeLoadOption<OptionsState['languages']>(
    'languages',
    selectors.getLanguages,
    apis.fetchLanguages
);

export const loadRelationships = makeLoadOption<OptionsState['relationships']>(
    'relationships',
    selectors.getRelationships,
    apis.fetchRelationships
);

export const loadEducations = makeLoadOption<OptionsState['educations']>(
    'educations',
    selectors.getEducations,
    apis.fetchEducations
);

export const loadResidenceTypes = makeLoadOption<OptionsState['residenceTypes']>(
    'residenceTypes',
    selectors.getResidenceTypes,
    apis.fetchResidenceTypes
);
export const loadAddressTypes = makeLoadOption<OptionsState['addressTypes']>(
    'addressTypes',
    selectors.getAddressTypes,
    apis.fetchAddressTypes
);

export const loadIncomeTypes = makeLoadOption<OptionsState['incomeTypes']>(
    'incomeTypes',
    selectors.getIncomeTypes,
    apis.fetchIncomeTypes
);

export const loadEmirates = makeLoadOption<OptionsState['emirates']>(
    'emirates',
    selectors.getEmirates,
    apis.fetchEmirates
);

export const loadAffinBank = makeLoadOption<OptionsState['affinBank']>(
    'affinBank',
    selectors.getAffinBank,
    apis.fetchAffinBankOptions
);
