// @ts-ignore
import { PrimaryButton } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm, InjectedFormProps } from 'redux-form';
import * as yup from 'yup';
import { requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import TextField from '../../shared/form/TextField';
import FormActions, { Error } from '../../ui/form/FormActions';
import LoginLogo from '../../ui/login/LoginLogo';
import Title from '../../ui/login/Title';

const RequestNewPasswordForm = ({ handleSubmit }: InjectedFormProps) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit}>
            <LoginLogo />
            <Title>{t('companyAuthenticationPage.title.forgotPassword')}</Title>
            <p>{t('companyAuthenticationPage.titleDescription.forgotPassword')}</p>
            <TextField.Outline
                label={t('companyAuthenticationPage.label.username')}
                name="username"
                placeholder={t('companyAuthenticationPage.label.username')}
            />
            <FormActions>
                <div>
                    <PrimaryButton type="submit">{t('companyAuthenticationPage.button.submit')}</PrimaryButton>
                </div>
                <FormError form="requestNewPassword">{error => <Error>{error}</Error>}</FormError>
            </FormActions>
        </form>
    );
};

export const schema = (t: TFunction) =>
    yup.object().shape({
        username: requiredString(t('companyAuthenticationPage.error.usernameRequired')),
    });

export default reduxForm({ form: 'requestNewPassword' })(RequestNewPasswordForm);
