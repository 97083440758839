import * as api from '../api';
import { getCurrentCountry } from '../selectors';
import { attachLoading } from './loading';

export const signingRedirect = (url, id) => {
    sessionStorage.setItem('redirectToSigning', 'true');
    sessionStorage.setItem('applicationTempVersionId', id);
    window.location = url;
};

export const applyUsedVariant = (id, name) => async (dispatch, getState, { client }) => {
    const state = getState();
    const { id: countryId } = getCurrentCountry(state);

    const data = {
        countryId,
        name,
    };

    const promise = api.createUsedVariant(client, { data });

    return dispatch(attachLoading(promise));
};

/* Redux action keys */

export const NEW_APPLICATION_SET = '@NEW_APPLICATION/SET';
export const NEW_APPLICATION_PARTIAL_SET = '@NEW_APPLICATION/PARTIAL_SET';
export const NEW_APPLICATION_RESTORE = '@NEW_APPLICATION/RESTORE';

/* Redux action creators */

export const setNewApplication = (step, data, partial = false) => ({
    type: partial ? NEW_APPLICATION_PARTIAL_SET : NEW_APPLICATION_SET,
    step,
    data,
});

export const restoreNewApplication = state => ({ type: NEW_APPLICATION_RESTORE, state });
