import {
    ContextActions,
    CONTEXT_SET_COMPANY,
    CONTEXT_SET_SCOPE,
    CONTEXT_SET_COMPANY_CODE,
    CONTEXT_SET_COUNTRY_CODE,
    CONTEXT_SET_USER,
    CONTEXT_SET_ZONE_CODE,
    CONTEXT_UNSET,
    CONTEXT_SET_DEALER_ID,
} from '../actions/context';
import { CompanyScopeFragment, CountryScopeFragment, ZoneScopeFragment } from '../components/data/useLoadScope.graphql';
import { UserDataFragment } from '../components/routes/LoginRoute/api.graphql';
import persistState from './persistState';

export type ContextState = {
    companyCode: string | null;
    countryCode: string | null;
    zoneCode: string | null;
    dealerId: string | null;
    company: CompanyScopeFragment | null;
    country: CountryScopeFragment | null;
    zone: ZoneScopeFragment | null;
    user: UserDataFragment | null;
};

const initialState: ContextState = {
    companyCode: null,
    countryCode: null,
    zoneCode: null,
    dealerId: null,
    company: null,
    user: null,
    country: null,
    zone: null,
};

const reducer = (state = initialState, action: ContextActions): ContextState => {
    switch (action.type) {
        case CONTEXT_SET_COMPANY_CODE:
            return { ...state, companyCode: action.companyCode };

        case CONTEXT_SET_COUNTRY_CODE:
            return { ...state, countryCode: action.countryCode };

        case CONTEXT_SET_DEALER_ID:
            return { ...state, dealerId: action.dealerId };

        case CONTEXT_SET_ZONE_CODE:
            return { ...state, zoneCode: action.zoneCode };

        case CONTEXT_SET_COMPANY:
            return { ...state, company: action.company };

        case CONTEXT_SET_SCOPE: {
            const { company = null, country = null, zone = null } = action.scope;

            return {
                ...state,
                // update entities
                company,
                country,
                zone,
                // also update codes
                companyCode: company?.code || null,
                countryCode: country?.code || null,
                zoneCode: zone?.code || null,
            };
        }

        case CONTEXT_SET_USER:
            return { ...state, user: action.user };

        case CONTEXT_UNSET:
            return initialState;

        default:
            return state;
    }
};

export default persistState('context', reducer);
