import { useCallback } from 'react';
import useCompanyFormatting from '../../components/utilities/useCompanyFormatting';
import { Maybe } from '../../schema';
import useOptions from '../constants/useOptions';

const useGetFormattedCurrency = () => {
    const { formatCurrency } = useCompanyFormatting();

    return useCallback((value: number) => formatCurrency(value).split(' ')[1], [formatCurrency]);
};

export type FooterInsuranceData = {
    premium?: number;
    yearOfBirth?: number;
    drivingExperience?: number;
    ncd?: number;
};

const useGetCalculatorFooterText = () => {
    const getFormattedCurrency = useGetFormattedCurrency();
    const { yearsOfDrivingOptions } = useOptions();

    return useCallback(
        (
            text?: string | null,
            coe?: number,
            ppsr?: number,
            establishment?: number,
            luxuryTax?: number,
            selectedDealerEstablishment?: number,
            selectedBankEstablishment?: Maybe<number> | undefined,
            insuranceData?: FooterInsuranceData
        ) => {
            if (!text) {
                return null;
            }

            let result = text;

            if (coe) {
                result = result?.replace('<<COE>>', getFormattedCurrency(coe));
            }

            if (ppsr) {
                result = result?.replace('<<ppsr>>', getFormattedCurrency(ppsr));
            }

            // Refer to: PNZ-9
            // <<establishment>> became dealer establishment in price disclaimer
            if (establishment) {
                result = result?.replace('<<establishment>>', getFormattedCurrency(selectedDealerEstablishment ?? 0));
            }

            if (luxuryTax) {
                result = result?.replace('<<LUXURY_TAX>>', getFormattedCurrency(luxuryTax));
            }

            const yearOfExperience = insuranceData?.drivingExperience
                ? yearsOfDrivingOptions?.find(item => item.value === insuranceData?.drivingExperience)?.label
                : '';

            result = result
                ?.replace(
                    '<<NZfees>>',
                    getFormattedCurrency(
                        (ppsr ?? 0) + (selectedDealerEstablishment ?? 0) + (selectedBankEstablishment ?? 0)
                    )
                )
                // Insurance premium was calculated using age, year of driving, and NCD
                // from default setting
                ?.replace('<<INSURANCE_PREMIUM>>', getFormattedCurrency(insuranceData?.premium ?? 0))
                ?.replace('<<NCD>>', insuranceData?.ncd ? insuranceData?.ncd.toString() : '')
                ?.replace('<<YEAR_OF_DRIVING>>', yearOfExperience ?? '')
                ?.replace('<<AGE>>', insuranceData?.yearOfBirth ? insuranceData?.yearOfBirth?.toString() : '');

            return result;
        },
        [getFormattedCurrency, yearsOfDrivingOptions]
    );
};

export default useGetCalculatorFooterText;
