/* eslint-disable no-underscore-dangle */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { History } from 'history';
import { i18n, TFunction } from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { ConsentDataFragment } from '../../api/consents.graphql';
import { ContentTranslation } from '../../i18n';
import { ApplicationFlowSource, Channel, InsuranceSigningMode } from '../../schema';
import { Application, Company, Country, Zone } from '../DraftFlow';
import { getCountryInsuranceConsents } from '../DraftFlow/utils/consents';
import { InsuranceApplication } from '../InsuranceDraftFlow/types';
import { Flow, FlowStep } from '../utils/flow';
import * as flowSteps from './steps';

export type InsuranceResumeFlowState = {
    // channel
    channel: Channel;
    // company document (mandatory)
    company: Company;
    // Insurance Company document
    insuranceCompany: InsuranceApplication['insuranceCompany'];
    // application document (provided after submitting a draft)
    insuranceApplication: InsuranceApplication;
    // country document (mandatory)
    country: Country;
    // zone document (mandatory)
    zone: Zone;
    // token (provided after draft creation)
    token: string;
    // source flow
    source: ApplicationFlowSource;
    consents?: ConsentDataFragment[];
};

export type InsuranceResumeFlowStep = FlowStep<InsuranceResumeFlowState>;

class InsuranceResumeFlow extends Flow<InsuranceResumeFlowState> {
    private initialized: Application['steps'];

    constructor(
        initialState: InsuranceResumeFlowState,
        pubSubId: string,
        dispatch: ThunkDispatch<any, any, any>,
        apolloClient: ApolloClient<NormalizedCacheObject>,
        history: History,
        t: TFunction,
        i18n: i18n,
        contentTranslation: ContentTranslation
    ) {
        super(initialState, pubSubId, dispatch, apolloClient, history, t, i18n, contentTranslation);
        // because we define initialized as a private property
        // if we don't initialize it in the constructor it will be automatically set back to undefined
        // TS specs says we can use "declare" to only define the that rather than the property itself
        // however CRA has an opened issue on the topic and didn't update the babel configuration of yet
        // therefor we are forced to do this ugly thing...
        this.initialized = initialState.insuranceApplication.steps;
    }

    public get channelSetting() {
        const { channel, country } = this.state;

        switch (channel) {
            case Channel.EXPRESS:
                return country.channelSetting.express;

            case Channel.NEW:
                return country.channelSetting.new;

            default:
                throw new Error('Not implemented');
        }
    }

    protected plannify(): FlowStep<InsuranceResumeFlowState>[] {
        const steps: FlowStep<InsuranceResumeFlowState>[] = [];

        const { insuranceApplication, consents, channel, insuranceCompany } = this.state;

        if (!this.initialized) {
            // define it for the first time
            this.initialized = insuranceApplication.steps;
        }

        const { initialized } = this;
        const initialSubmission = !initialized?.submission;

        const insuranceConsents = getCountryInsuranceConsents(consents, channel);

        // show separate step consent step when there is no kyc
        if (initialSubmission && !initialized?.consentsAndDeclarations && insuranceConsents.length) {
            steps.push(this.createStep(flowSteps.ConsentStep));
        }

        switch (initialSubmission ? insuranceCompany.signing.onCreate : insuranceCompany.signing.onUpdate) {
            case InsuranceSigningMode.NAMIRIAL:
                steps.push(this.createStep(flowSteps.NamirialStep));
                break;

            case InsuranceSigningMode.NONE:
            default:
                // do nothing
                break;
        }

        return [...steps, this.createStep(flowSteps.SubmitStep)];
    }
}

export default InsuranceResumeFlow;
