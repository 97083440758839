export const AUTHORIZATION_SET = '@AUTHORIZATION/SET';

export const AUTHORIZATION_UNSET = '@AUTHORIZATION/UNSET';

export type SetAuthorization = {
    type: typeof AUTHORIZATION_SET;
    authorization: { accessToken: string };
};

export const setAuthorization = (accessToken: string): SetAuthorization => ({
    type: AUTHORIZATION_SET,
    authorization: { accessToken },
});

export type UnsetAuthorization = { type: typeof AUTHORIZATION_UNSET };

export const unsetAuthorization = () => ({ type: AUTHORIZATION_UNSET });

export type AuthorizationActions = SetAuthorization | UnsetAuthorization;
