import styled from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';

export type SelectOptionProps = {
    active?: boolean;
};

const SelectOption = styled.li<SelectOptionProps>`
    padding-left: 5px;
    padding-right: 5px;

    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid #ced4da;

    height: 100%;

    cursor: pointer;

    text-align: center;
    color: ${props => (props.active ? '#fff' : '#000')};
    background-color: ${props => (props.active ? props.theme.themeHighlightColour : '#fff')};

    @media (min-width: ${breakpoints.md}) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export default SelectOption;
