import styled from 'styled-components';

const DrawerContainer = styled.div<{ fixedMenu: boolean; open: boolean }>`
    background-color: #222034;
    color: white;

    height: 100vh;
    width: 250px;

    position: fixed;
    top: 0;
    left: ${props => (props.open ? '0px' : '-250px')};
    z-index: ${props => (props.fixedMenu ? '1000' : '9999')};
    transition: all 0.3s ease;
`;

export default DrawerContainer;
