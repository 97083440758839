import { useApolloClient, useQuery } from '@apollo/client';
import { isObject, pick } from 'lodash/fp';
import React, { useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setNotification } from '../../../actions';
import { getCountryCode, getCurrentZone, getDialingCodes } from '../../../selectors';
import { PROFILE_SAVE_NOTIFICATION } from '../../../utilities/constants/notification';
import { handleResponseError } from '../../../utilities/forms';
import { mapProfileToState } from '../../../utilities/profile';
import { getCarModelsUrl } from '../../../utilities/urls';
import Header from '../../shared/Header';
import HelmetTitle from '../../shared/HelmetTitle';
import { uploadImage, deleteImage, getUser, update, resetPassword } from './Page.graphql';
import ProfileForm from './ProfileForm';

const ProfileRoute = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dialingCodes = useSelector(getDialingCodes) || [];
    const countryCode = useSelector(getCountryCode);
    const { timezone } = useSelector(getCurrentZone);
    const { t } = useTranslation();

    const { data, loading, refetch } = useQuery(getUser, { fetchPolicy: 'network-only' });

    const initialValues = useMemo(
        () =>
            data?.user &&
            dialingCodes &&
            mapProfileToState(data?.user, dialingCodes, countryCode, timezone, t('dateFormats.dateTimeFormat')),
        [data, dialingCodes, countryCode, timezone, t]
    );

    const ref = useRef(null);
    const onCancel = useCallback(() => history.goBack(), [history]);

    const client = useApolloClient();

    const onSave = useCallback(
        async values => {
            const { image, password } = values;

            let promise = Promise.resolve();
            if (password) {
                const variables = { ...pick(['password', 'newPassword'], values) };
                promise = promise.then(() =>
                    client.mutate({ mutation: resetPassword, variables, fetchPolicy: 'no-cache' })
                );
            }

            promise = promise.then(() =>
                client.mutate({
                    mutation: update,
                    variables: {
                        data: {
                            ...pick(['name', 'phone', 'phonePrefix', 'email'], values),
                        },
                    },
                })
            );

            if (image instanceof File) {
                // must upload a new file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({
                        mutation: uploadImage,
                        variables: { id: response.id, file: image },
                    })
                );
            } else if (image === null && isObject(initialValues.image)) {
                // must remove an existing file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({
                        mutation: deleteImage,
                        variables: { id: response.id },
                    })
                );
            }

            // Refetch user info after saving
            promise = promise.then(refetch);

            promise = promise.then(() => {
                const { title, message } = PROFILE_SAVE_NOTIFICATION;
                dispatch(setNotification(title, message));
                history.pushWithCompany(getCarModelsUrl);
            });

            return promise.catch(handleResponseError);
        },
        [client, dispatch, history, initialValues, refetch]
    );

    if (!loading && !initialValues) {
        // profile is still loading
        return null;
    }

    return (
        <>
            <HelmetTitle title="Profile" />
            <Header />
            <ProfileForm ref={ref} initialValues={initialValues} onCancel={onCancel} onSubmit={onSave} />
        </>
    );
};

export default ProfileRoute;
