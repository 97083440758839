import { isEmpty } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../DraftPage/ui';

type TranslationEventReservationInfo = {
    title: string;
    content: string;
};

type ReservationInformationProps = {
    reservationPeriod?: string;
};

const ReservationInformation = ({ reservationPeriod }: ReservationInformationProps) => {
    const { t } = useTranslation();

    const eventReservationInfo: TranslationEventReservationInfo = t('eventReservationInfo', { returnObjects: true });

    if (isEmpty(eventReservationInfo.content) || isEmpty(eventReservationInfo.title)) {
        return null;
    }

    return (
        <div>
            {eventReservationInfo?.title && <Title>{eventReservationInfo.title}</Title>}
            {eventReservationInfo?.content && <p>{t('eventReservationInfo.content', { reservationPeriod })}</p>}
        </div>
    );
};

export default ReservationInformation;
