// @ts-ignore
import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { CommonFieldProps } from 'redux-form/lib/Field';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import ConsentTitle from '../../../../components/ui/ConsentTitle';
import { useContentTranslation } from '../../../../i18n';

type ConsentInputProps = {
    consent: ConsentDataFragment;
};

const ConsentNonInteractiveInput = ({ input, consent }: WrappedFieldProps & ConsentInputProps) => {
    const { onChange } = input;

    const { formatPath } = useContentTranslation();
    const label = get(formatPath('name'), consent);
    const description = get(formatPath('description'), consent);

    useEffect(() => {
        // By doing this, it must trigger `updateConsent` from `ConsentForm` on load
        // Since the consent that only render text won't have interactive element
        // to trigger `onChange`
        onChange(true);
    }, [onChange]);

    return (
        <>
            <ConsentTitle>{label}</ConsentTitle>
            <div>{description}</div>
        </>
    );
};

const ConsentNonInteractiveField = (props: ConsentInputProps & Partial<CommonFieldProps>) => (
    <Field {...props} component={ConsentNonInteractiveInput} />
);

export default ConsentNonInteractiveField;
