// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../i18n';
import { ownerIdOptions, yesNoOptions } from '../../../../utilities/constants/options';
import DateField from '../../../shared/form-v2/DateField';
import NumberField from '../../../shared/form-v2/NumberField';
import SelectField from '../../../shared/form-v2/SelectField';
import TextField from '../../../shared/form-v2/TextField';

type ExpressProps = {
    disabled?: boolean;
};

const Express = ({ disabled = false }: ExpressProps) => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.vehicleOfInterest')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.make')}
                            name={formatPath('variant.model.make.name')}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.model')}
                            name={formatPath('variant.model.name')}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.vehicleNumber')}
                            name="variant.preOwnedCarDetails.plateNumber"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.displacement')}
                            name="calculator.displacement"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={t('applicationDetailsPage.label.originalRegistrationDate')}
                            name="variant.preOwnedCarDetails.originalRegistrationDate"
                            disabled
                        />
                    </div>{' '}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={t('applicationDetailsPage.label.coeExpiryDate')}
                            name="variant.preOwnedCarDetails.coeExpiryDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.qpPaid')}
                            name="variant.preOwnedCarDetails.qpPaid"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.openMarketValue')}
                            name="variant.preOwnedCarDetails.omv"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.ownerIdType')}
                            name="variant.preOwnedCarDetails.ownerIdType"
                            options={ownerIdOptions}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.ownerIdSuffix')}
                            maxLength="4"
                            name="variant.preOwnedCarDetails.ownerIdSuffix"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('applicationDetailsPage.label.inspectedByDealer')}
                            name="variant.preOwnedCarDetails.inspected"
                            options={yesNoOptions}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default Express;
