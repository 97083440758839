import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useCountry } from '../../../../hookSelectors';
import { getGlobalPermissions, getIsAuthenticated } from '../../../../selectors';
import { getCompanyLoginUrl } from '../../../../utilities/urls';
import Error404NotFound from '../../Error404NotFound';
import Error404Suggestion from '../../Error404Suggestion';
import Inner from './Inner';

const CarListRoute = () => {
    const country = useCountry();
    const history = useHistory();
    const { pathname, search } = useLocation();
    const redirect = `${pathname}${search}`;

    const { isActive, allowPrivateAccess } = country.channelSetting.new;
    const { isActive: isUsedCarActive, allowPrivateAccess: allowUsedCarPrivateAcess } = country.channelSetting.used;
    const { isActive: isExpressActive } = country.channelSetting.express;

    const isAuthenticated = useSelector(getIsAuthenticated);
    const { mayViewNewCarChannel, mayViewUsedCarChannel, mayViewExpressChannel } = useSelector(getGlobalPermissions);
    const mayViewOtherChannel = mayViewExpressChannel || (mayViewUsedCarChannel && allowUsedCarPrivateAcess);

    /* Without useEffect component will re-render as infinite loop
    when we force to logout because of session expire or login in second tab */
    useEffect(() => {
        // unauthenticated
        if (!isAuthenticated) {
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
        }
    }, [history, isAuthenticated, redirect]);

    // not active used car channel
    if (!isActive || !(allowPrivateAccess && mayViewNewCarChannel)) {
        // other channel based on user permission
        const otherActive = isUsedCarActive || isExpressActive;

        return mayViewOtherChannel && otherActive ? <Error404Suggestion /> : <Error404NotFound />;
    }

    return <Inner />;
};

export default CarListRoute;
