import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { EventOrValueHandler } from 'redux-form';
import styled, { useTheme } from 'styled-components';
import { Title, Label, Action } from './Portlet';
import { ReactComponent as Checked } from '../../assets/images/boxChecked.svg';
import { ReactComponent as Unchecked } from '../../assets/images/boxUnchecked.svg';

const Container = styled.div`
    padding: 10px 0;

    @media (max-width: 450px) {
        padding: 10px 15px;
    }
`;

const Inner = styled.div`
    padding: 0;
`;

const Checkbox = styled.div`
    display: flex;

    cursor: 'pointer';

    svg {
        width: 25px;
        padding-right: 5px;
    }

    input {
        display: none;
    }
`;

const CalculatorPortletTitle = styled(Title)`
    border: 0;
`;

type CalculatorPortletProps = {
    children: React.ReactNode;
    closeable: boolean;
    checked?: boolean;
    open: boolean;
    displayOption?: boolean;
    name: string;
    onClick?: EventOrValueHandler<ChangeEvent<any>>;
};

const CalculatorPortlet = ({
    name,
    open: initOpen,
    closeable,
    checked = false,
    onClick,
    displayOption,
    children,
}: CalculatorPortletProps) => {
    const [open, setOpen] = useState(initOpen);
    const theme = useTheme();

    const toggle = useCallback(() => {
        if (closeable) {
            setOpen(state => !state);
        }
    }, [setOpen, closeable]);

    useEffect(() => {
        setOpen(checked);
    }, [setOpen, checked]);

    return !displayOption ? (
        <div>{children}</div>
    ) : (
        <Container>
            <CalculatorPortletTitle>
                {displayOption ? (
                    <Checkbox onClick={onClick}>
                        {checked ? <Checked fill={theme.themeHighlightColour} /> : <Unchecked />}
                        <input checked={checked} name={name} readOnly />
                        <Label>{name}</Label>
                    </Checkbox>
                ) : (
                    <Label>{name}</Label>
                )}

                {closeable && (
                    <Action onClick={toggle}>
                        <Icon icon={open ? faAngleDown : faAngleUp} />
                    </Action>
                )}
            </CalculatorPortletTitle>
            {open && <Inner>{children}</Inner>}
        </Container>
    );
};

export default CalculatorPortlet;
