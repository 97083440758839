import { CalculatorContext, ComparisonCalculator } from '@appvantageasia/afc-calculator-ui-next';
import { isNil } from 'lodash/fp';
import { nanoid } from 'nanoid';
import React, { useCallback, Dispatch, SetStateAction, useMemo, memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../components/shared/calculator-next/useCalculatorMeta';
import { Container } from '../../../../components/ui/calculator';
import { CalculatorComparisonContainer } from '../../../../components/ui/calculator/CalculatorComparison';
import { PlusIcon, ShareIcon } from '../../../../components/ui/calculator/Navigation';
import { useCountry } from '../../../../hookSelectors';
import { Channel } from '../../../../schema';
import Navigation from '../../../utils/Navigation';
import { useShareFunction } from '../../../utils/ShareProvider';
import useDealerEstablishment from '../../../utils/useDealerEstablishment';
import ComparisonItem from './ComparisonItem';
import NavBlock from './NavBlock';
import { cloneCalculatorValues } from './Page';

export type ComparisonProps = {
    dealerId: string;
    calculators: Partial<CalculatorValues>[];
    setCalculators: Dispatch<SetStateAction<any[]>>;
    fallbackFinanceProduct?: FinanceDataFragment | null;
};

type ComparisonContext = {
    getFieldContext?: Function;
    values: Partial<CalculatorValues>;
    uid?: string;
    id: string;
};

const Comparison = ({
    dealerId,
    calculators: initialCalculators,
    setCalculators: onCalculatorsChange,
    fallbackFinanceProduct,
    ...props
}: ComparisonProps) => {
    const { t } = useTranslation();
    const { channelSetting } = useCountry();
    const { allowSharing } = channelSetting.new;

    const [calculatorsContext, setCalculatorsContext] = useState<ComparisonContext[]>(
        initialCalculators.map(values => ({
            values,
            // TODO: use calculator context for comparison
            // temp id
            id: nanoid(),
        }))
    );

    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId, Channel.NEW);

    // sharing function
    const share = useShareFunction();

    const onPrev = useCallback(() => {
        // set back to single calculator
        setCalculatorsContext(context => [context[0]]);
    }, [setCalculatorsContext]);

    // calculator context states
    const additionalMeta = useMemo(() => ({ channel: Channel.NEW, selectedDealerEstablishment }), [
        selectedDealerEstablishment,
    ]);
    const meta = useCalculatorMeta(additionalMeta);

    const { onChange, remove } = useMemo(
        () => ({
            onChange: (context: CalculatorContext<CalculatorValues>) => {
                setCalculatorsContext(previous => {
                    const index = previous.findIndex(({ uid }) => isNil(uid) || uid === context.uid);

                    const current = [...previous];

                    // update values
                    current[index] = { ...previous[index], ...context };

                    return current;
                });
            },
            remove: (uid: string) => setCalculatorsContext(contexts => contexts.filter(context => context.uid !== uid)),
        }),
        []
    );

    useEffect(() => {
        onCalculatorsChange(calculatorsContext.map(({ values }) => values));
    }, [calculatorsContext, onCalculatorsChange]);

    const renderComparisonItems = useMemo(() => {
        return calculatorsContext.map(context => {
            return (
                <ComparisonItem
                    key={context.id}
                    dealerId={dealerId}
                    initialValues={context.values}
                    meta={meta}
                    onChange={onChange}
                    remove={remove}
                />
            );
        });
    }, [calculatorsContext, dealerId, meta, onChange, remove]);

    // comparison function
    const compare = useCallback(
        () =>
            setCalculatorsContext(contexts => [
                ...contexts,
                { values: cloneCalculatorValues(contexts[contexts.length - 1].values), id: nanoid() },
            ]),
        []
    );

    return (
        <>
            <Container>
                <Navigation onPrev={() => onPrev()} prevText={t('newCalculatorPage.button.previous')}>
                    {calculatorsContext.length < 3 && (
                        <NavBlock icon={<PlusIcon />} label={t('newCalculatorPage.button.compare')} onClick={compare} />
                    )}
                    {allowSharing && (
                        <NavBlock
                            icon={<ShareIcon />}
                            label={t('newCalculatorPage.button.share')}
                            onClick={() =>
                                share(
                                    calculatorsContext.map(({ values, getFieldContext }) => {
                                        return {
                                            ...values,
                                            getFieldContext,
                                        };
                                    })
                                )
                            }
                        />
                    )}
                </Navigation>
            </Container>
            <CalculatorComparisonContainer>
                <ComparisonCalculator>{renderComparisonItems}</ComparisonCalculator>
            </CalculatorComparisonContainer>
        </>
    );
};

export default memo(Comparison);
