import React from 'react';
import styled, { css } from 'styled-components';
import SubTitle from './SubTitle';

const Tab = styled(SubTitle)<{ isActive: boolean }>`
    width: auto;
    text-align: initial;
    cursor: pointer;
    display: inline-block;
    color: ${props => props.theme.themeHighlightColour};

    ${props =>
        props.isActive &&
        css`
            cursor: initial;
            color: initial;
        `};
`;

type TabsProps = {
    className: string;
    activeTab: number;
    onClick: (index: number) => void;
    tabs: string[];
};

const Tabs = ({ className, activeTab = 0, tabs, onClick }: TabsProps) => (
    <div className={className}>
        {tabs.map((title, index) => (
            <Tab key={index.toString()} isActive={activeTab === index} onClick={() => onClick(index)}>
                {title}
            </Tab>
        ))}
    </div>
);

export default styled(Tabs)`
    width: 100%;
    text-align: center;

    ${Tab} + ${Tab} {
        ::before {
            margin: 0 5px;
            content: '|';
            cursor: initial;
            color: #ced4da;
            font-weight: normal;
        }
    }
`;
