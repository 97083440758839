import { useQuery } from '@apollo/client';
import { head, isEmpty, isNil } from 'lodash/fp';
import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router';
import {
    getApplicationByToken,
    GetApplicationByTokenQuery,
    GetApplicationByTokenQueryVariables,
} from '../../../api/application.graphql';
import { useCountry } from '../../../hookSelectors';
import { getCalculatorFromApplication } from '../../../utilities/application';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import { CalculatorValues } from '../../shared/calculator-next/types';
import { ApplicationDataFragment } from '../ApplicationRoute/data.graphql';
import Error404Suggestion from '../Error404Suggestion';
import { EventDataFragment } from '../EventRoute/EventRoute.graphql';
import { CsvProvider } from './CsvContext';
import { GetDataQuery, GetDataQueryVariables, getData, CsvConfiguratorDataFragment } from './data.graphql';

type RouterParams = { porscheCode: string };

export type CsvBootstrapContext = {
    dealers: GetDataQuery['result']['dealers'];
    application?: ApplicationDataFragment | undefined;
    event: EventDataFragment;
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    dealerId?: string;
    csvConfigurator: CsvConfiguratorDataFragment;
};

export type CsvBootstrapProps = {
    children: (context: CsvBootstrapContext) => ReactElement;
    token?: string;
    bookingId?: string;
};

const CsvBootstrap = ({ children, token }: CsvBootstrapProps) => {
    const { porscheCode } = useParams<RouterParams>();
    const { id: countryId, channelSetting } = useCountry();
    const { isActive } = channelSetting.event;

    // fetch csv setup
    const { data, loading: setupLoading } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables: {
            countryId,
            porscheCode,
        },
        fetchPolicy: 'no-cache',
    });

    // fetch application data
    const { data: applicationData, loading: applicationLoading } = useQuery<
        GetApplicationByTokenQuery,
        GetApplicationByTokenQueryVariables
    >(getApplicationByToken, {
        fetchPolicy: 'network-only',
        variables: { token: token as string },
        skip: isEmpty(token),
    });

    const calculator = useMemo(() => {
        const application = applicationData?.application;

        if (!application) {
            return undefined;
        }

        return getCalculatorFromApplication(application);
    }, [applicationData]);

    const isLoading = setupLoading || applicationLoading;

    if (isLoading) {
        // wait for loading to be over
        return null;
    }

    if (isNil(data) || isNil(data.result)) {
        return <Error404Suggestion header={false} />;
    }

    const { event, variant, dealers, csvConfigurator } = data.result;

    const isEventExpired = event?.period?.end && event.period.end < new Date();
    // events is not active or not valid
    if (!isActive || !event?.isActive || isNil(dealers) || !dealers?.length || isEventExpired || isNil(variant)) {
        return <Error404Suggestion header={false} />;
    }

    const context = {
        dealers,
        event,
        application: applicationData?.application,
        variant,
        calculator,
        csvConfigurator,
        dealerId: dealers.length === 1 ? head(dealers)?.id : undefined,
    };

    return (
        <CsvProvider csvConfigurator={csvConfigurator} dealers={dealers}>
            {children(context)}
        </CsvProvider>
    );
};

export default CsvBootstrap;
