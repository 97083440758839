import React from 'react';
import { FormSection } from 'redux-form';
import ApplicantStep from '../../../../shared/form-v2/steps/ApplicantStep';
import TradeInVehicleStep from '../../../../shared/form-v2/steps/TradeInVehicleStep';
import VehicleStep from '../../../../shared/form-v2/steps/VehicleStep';
import { useAppointmentFormContext } from '../Form/context';
import SectionSummary from './SectionSummary';

const TabDetails = () => {
    const { values, disabled } = useAppointmentFormContext();

    const { latestApplication } = values;
    const { hasTradeIn } = latestApplication;

    return (
        <>
            <SectionSummary channel={latestApplication.channel} dealerId={latestApplication.dealerId} />
            <FormSection name="latestApplication">
                <VehicleStep application={latestApplication} disabled={disabled} />
                <ApplicantStep
                    allowPrimaryInfoChanges={false}
                    application={latestApplication}
                    canAddGuarantor={false}
                    disabled={disabled}
                />
                {hasTradeIn && <TradeInVehicleStep application={latestApplication} />}
            </FormSection>
        </>
    );
};

export default TabDetails;
