import { FinanceDataFragment } from '../../components/data/useFinanceProducts.graphql';
import { EventDataFragment } from '../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorValues } from '../../components/shared/calculator-next/types';
import { Flow, FlowStep } from '../utils/flow';
import { MiniConfiguratorDetails } from './components/Summary/Summary';
import * as flowSteps from './steps';

export type ConfiguratorFlowState = MiniConfiguratorDetails & {
    events: EventDataFragment[];
    calculatorValues?: Partial<CalculatorValues>;
    defaultFinanceProduct: FinanceDataFragment;
    isResumeOrder: boolean;
};

class ConfiguratorFlow extends Flow<ConfiguratorFlowState> {
    protected initialize(): FlowStep<ConfiguratorFlowState> {
        return super.initialize();
    }

    protected plannify(): FlowStep<ConfiguratorFlowState>[] {
        const steps: FlowStep<ConfiguratorFlowState>[] = [];

        const { isResumeOrder } = this.state;

        if (isResumeOrder) {
            steps.push(this.createStep(flowSteps.ResumeOrderStep));
        } else {
            steps.push(this.createStep(flowSteps.ConfigPageStep));
        }

        return [...steps, this.createStep(flowSteps.SummaryStep)];
    }
}

export default ConfiguratorFlow;
