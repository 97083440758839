import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Actions, DarkButton } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import useLoadVariants from '../../../../../components/data/useLoadVariants';
import InsuranceCalculator from '../../../../../components/shared/calculator-next/InsuranceCalculator';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../../components/shared/calculator-next/useCalculatorMeta';
import { CalculatorFooter, Container, VariantImage } from '../../../../../components/ui/calculator';
import { Error as InsurancePremiumError } from '../../../../../components/ui/calculator/CalculatorError';
import { useCountry } from '../../../../../hookSelectors';
import { useContentTranslation } from '../../../../../i18n';
import { Channel } from '../../../../../schema';
import { useGetCalculatorFooterText } from '../../../../../utilities/calculator';
import { useVariant } from '../../../../DraftFlow/components/Calculator/Single';
import useDealerEstablishment from '../../../../utils/useDealerEstablishment';
import noImg from '../../../../../assets/images/no-image.png';

export type PageProps = {
    initialValues: Partial<CalculatorValues>;
    dealerId: string;
    submit: (values: any) => Promise<any>;
    snapshot?: any;
};

export const cloneCalculatorValues = (value: Partial<CalculatorValues>) => {
    if (value?.unitId) {
        const unitId = uuidv4();

        return { ...value, unitId };
    }

    return value;
};

const Page = ({ initialValues, dealerId, submit, snapshot }: PageProps) => {
    if (!initialValues.variant) {
        throw new Error('Car model missing in initial values');
    }

    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const country = useCountry();
    const { formatPath } = useContentTranslation();
    const { variants } = useLoadVariants('cache-and-network', Channel.NEW, dealerId);

    const [context, setContext] = useState<CalculatorContext<CalculatorInsuranceValues>>();
    const [insurancePremiumHasError, setInsurancePremiumHasError] = useState<boolean>(false);
    const { values } = context || {};

    // get the text footer from the country
    const getCalculatorFooterText = useGetCalculatorFooterText();

    const insuranceCalculatorFooterText = getCalculatorFooterText(
        get(formatPath('channelSetting.new.insurancePriceDisclaimer'), country),
        country?.coe?.amount,
        country?.ppsr?.amount,
        country?.establishment?.amount,
        country?.luxuryTax?.amount
    );

    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.NEW);

    // calculator context states
    const additionalMeta = useMemo(
        () => ({
            snapshot,
            channel: Channel.NEW,
            selectedDealerEstablishment,
            ...initialValues,
        }),
        [selectedDealerEstablishment, initialValues, snapshot]
    );

    const meta = useCalculatorMeta(additionalMeta);

    const onSubmit = useCallback(() => {
        if (!context) {
            throw new Error('missing values');
        }

        const { values } = context || {};

        return submit({
            calculator: values,
        });
    }, [submit, context]);

    const handleInsurancePremiumChange = useCallback(
        ({ hasError }) => {
            setInsurancePremiumHasError(hasError);
        },
        [setInsurancePremiumHasError]
    );

    // the variant may eventually change later
    const variant = useVariant(dealerId, values?.variant || initialValues.variant);

    return (
        <>
            <Container>
                <VariantImage alt={ct(variant?.name)} fallbackSrc={noImg} src={variant?.images[0]?.url || noImg} />
            </Container>
            <Container>
                <InsuranceCalculator
                    key={initialValues.variant}
                    channel={Channel.NEW}
                    dealerId={dealerId}
                    initialValues={initialValues}
                    meta={meta}
                    onChange={setContext}
                    onInsurancePremiumChange={handleInsurancePremiumChange}
                    variants={variants}
                >
                    <displayFields.CarModelPriceField
                        fieldKey="carModelAndPrice"
                        isEditable={false}
                        isViewable={() => true}
                        size={2}
                        override
                    />
                </InsuranceCalculator>
                <CalculatorFooter dangerouslySetInnerHTML={{ __html: insuranceCalculatorFooterText || '' }} />
                <Actions>
                    <DarkButton disabled={insurancePremiumHasError} onClick={onSubmit}>
                        {t('newCalculatorPage.button.next')}
                    </DarkButton>
                </Actions>
                {insurancePremiumHasError && (
                    <InsurancePremiumError>{t('calculator.error.insurancePremium')}</InsurancePremiumError>
                )}
            </Container>
        </>
    );
};

export default Page;
