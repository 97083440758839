import styled from 'styled-components';
import { ReactComponent as Compare } from '../../../assets/images/compare.svg';
import { ReactComponent as Share } from '../../../assets/images/share.svg';

export const PlusIcon = styled(Compare)``;

export const ShareIcon = styled(Share)``;

const Block = styled.div`
    button {
        border: none;
        outline: none;
        color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        background-color: transparent;

        &:disabled {
            cursor: not-allowed;
        }

        svg {
            fill: #666666;
            width: 24px;
            height: 24px;
        }

        ${PlusIcon} {
            margin-left: 5px;
            margin-right: 10px;
            width: 22px;
        }

        ${ShareIcon} {
            margin-left: 5px;
            width: 22px;
        }

        span {
            font-size: 0.96rem;
            text-transform: ${props => props.theme.casing};
        }
    }
`;

const Fill = styled.div`
    flex-grow: 2;
`;

type ContainerProps = { fixedMenu: boolean };
const Container = styled.div<ContainerProps>`
    /* style for mobile and devices smaller than ipad */
    display: flex;
    margin-top: 10px;
    padding-left: ${props => (props.fixedMenu ? 250 : 0)}px;
    transition: all 0.5s ease;

    /* style for iPads, laptops and bigger devices */
    @media (min-width: 768px) {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 30px;
        z-index: 999;
    }
`;

export default { Container, Block, Fill };
