import { displayFields, Values } from '@appvantageasia/afc-calculator-ui-next';
import React from 'react';
import Portal from './Portal';

export type RemoteExpectedTradeInAmountFieldProps<
    CalculatorValues extends object = any
> = displayFields.ExpectedTradeInAmountFieldProps<CalculatorValues> & {
    container?: HTMLElement | null;
};

const RemoteExpectedTradeInAmountField = ({ container, ...props }: RemoteExpectedTradeInAmountFieldProps<Values>) => {
    const render = <displayFields.ExpectedTradeInAmountField {...props} />;

    if (!container) {
        return render;
    }

    return <Portal container={container}>{render}</Portal>;
};

export default RemoteExpectedTradeInAmountField;
