import styled, { css } from 'styled-components';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Row } from '../ui';

export const SMALL_GAP = '10px';
export const LARGE_GAP = '21px';

export const SectionWrapper = styled.div`
    padding-top: ${LARGE_GAP};
`;

export type SectionProps = {
    $isOpened?: boolean;
};

export const SectionItem = styled.div<SectionProps>`
    margin-bottom: ${SMALL_GAP};

    &:last-child {
        margin-bottom: ${LARGE_GAP};
    }
`;

export const PriceItalic = styled.div`
    display: inline-block;
    span {
        font-style: italic;
        font-size: 13px;
    }

    & > *:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

export type CheckboxRowProps = {
    $marginTop?: string;
};

export const CheckboxRow = styled(Row)<CheckboxRowProps>`
    grid-gap: 0;
    line-height: 1;
    margin-top: ${props => props.$marginTop ?? '21px'};

    @media (max-width: ${breakpoints.sm}) {
        display: grid;

        ${({ columns }) => `grid-template-columns: repeat(${columns ?? 2}, 1fr);`}
    }
`;

export const CheckboxWrapper = styled.div`
    label {
        margin-bottom: 0;
    }
`;

export const SpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export type PriceProps = {
    promo?: number;
    isPromoValid?: Boolean;
    isCoeEnabled?: Boolean;
    isLast?: Boolean;
};

export const Price = styled.div<PriceProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;

    ${props =>
        props.isLast &&
        props.isCoeEnabled &&
        css`
            border-top: 1px solid #ced4da;
            margin-top: 4px;
            padding-top: 4px;
        `}

    span:first-child {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        ${props =>
            props.theme.display === 'small' &&
            css`
                text-align: left;
            `}
    }

    span:last-child {
        font-weight: 500;
        font-size: 26px;
        margin-left: 17px;
        white-space: nowrap;
        ${props =>
            props.isCoeEnabled &&
            css`
                font-size: 16px;
                font-weight: 400;
            `}
        ${props =>
            props.promo &&
            props.isPromoValid &&
            css`
                color: #44444480;
                text-decoration: line-through;
            `}
    }

    span:last-of-type {
        margin-top: 6px;

        ${props =>
            props.isCoeEnabled &&
            props.isLast &&
            css`
                margin-top: 0;
            `}
    }
`;

export const PriceContainer = styled.div`
    line-height: 10px;
    margin-bottom: 6px;
`;

export const Description = styled.div`
    margin-bottom: 30px;
`;

export const SubHeading = styled.h3`
    font-size: 14px;
    font-weight: normal;
`;

export const CalculatorContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 6px;
`;

export const CalculatorToggle = styled.div`
    display: block;
    text-align: right;
    margin-top: 6px;
    margin-bottom: 17px;
`;

export const SectionItemContent = styled.div<{ $isOpened?: boolean }>`
    ${({ $isOpened }) =>
        $isOpened &&
        css`
            margin-top: ${SMALL_GAP};
            margin-bottom: ${LARGE_GAP};
        `}
`;
