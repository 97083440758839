import { TFunction } from 'i18next';
import { get, omit } from 'lodash/fp';
import React, { useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadAffinBank } from '../../../../../actions';
import CountryField from '../../../../../components/shared/form-v2/CountryField';
import DateField from '../../../../../components/shared/form-v2/DateField';
import NationalityField from '../../../../../components/shared/form-v2/NationalityField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import useCustomerSourceOptions from '../../../../../components/utilities/useCustomerSourceOptions';
import { LicenseType, CustomerDetailsSource, BankPresetOption } from '../../../../../schema';
import { getAffinBankIdTypes, getAffinBankRaces, getAffinBankResidentialStatuses } from '../../../../../selectors';
import useOptions from '../../../../../utilities/constants/useOptions';
import { getMinDateOfBirth } from '../../../../../utilities/dates';
import { yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';
import DrivingLicense from './DrivingLicense';

const watchedFields = [
    'dateOfBirth',
    'details.gender',
    'details.maritalStatus',
    'details.nationality',
    'details.drivingLicense',
    'details.race',
    'details.residentialStatus',
    'details.thirdParty.affinBank.idType',
    'details.thirdParty.affinBank.idIssueCountry',
    'details.thirdParty.affinBank.residentialCountry',
    'details.thirdParty.affinBank.race',
    'details.thirdParty.affinBank.residentialStatus',
];

type ApplicantDetailsFormProps = {
    disabled?: boolean;
    bankPresetOption?: BankPresetOption;
    step: string;
};

const ApplicantDetailsForm = ({ disabled = false, step, bankPresetOption }: ApplicantDetailsFormProps) => {
    const { sectionPrefix } = useContext(ReduxFormContext);
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isAffinBank, isTTBBank] = useMemo(
        () => [bankPresetOption === BankPresetOption.AFFINBANK, bankPresetOption === BankPresetOption.TTBBANK],
        [bankPresetOption]
    );

    useEffect(() => {
        if (isAffinBank) {
            dispatch(loadAffinBank());
        }
    }, [dispatch, isAffinBank]);

    const idTypesAffin = useSelector(getAffinBankIdTypes);
    const racesAffin = useSelector(getAffinBankRaces);
    const residentialStatusesAffin = useSelector(getAffinBankResidentialStatuses);

    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);

    const { genders, residentialStatuses } = useCustomerSourceOptions();
    const { maritalStatuses, races } = useOptions();

    if (isTTBBank) {
        return null;
    }

    return (
        <>
            <Title>
                {step === 'guarantor' ? t('kycPage.guarantorDetailsTitle') : t('kycPage.applicantDetailsTitle')}
            </Title>
            <DateField
                disabled={disabled || fieldState.dateOfBirth}
                label={t('customerDetails.label.dateOfBirth')}
                maxDate={minDateOfBirth}
                name="dateOfBirth.value"
            />
            <NationalityField
                disabled={disabled || fieldState.details.nationality}
                label={t('customerDetails.label.nationality')}
                name="details.nationality.value"
            />
            <SelectField.Outline
                disabled={
                    disabled ||
                    (isAffinBank ? fieldState.details?.thirdParty?.affinBank?.race : fieldState.details.race)
                }
                label={t('customerDetails.label.race')}
                name={isAffinBank ? 'details.thirdParty.affinBank.race.value' : 'details.race.value'}
                options={isAffinBank ? racesAffin : races}
            />
            {isAffinBank && (
                <>
                    <SelectField.Outline
                        disabled={disabled || fieldState.details?.thirdParty?.affinBank?.idType}
                        label={t('customerDetails.label.idType')}
                        name="details.thirdParty.affinBank.idType.value"
                        options={idTypesAffin}
                    />
                    <CountryField
                        disabled={disabled || fieldState.details?.thirdParty?.affinBank?.idIssueCountry}
                        label={t('customerDetails.label.idIssueCountry')}
                        name="details.thirdParty.affinBank.idIssueCountry.value"
                    />
                </>
            )}
            <SelectField.Outline
                disabled={disabled || fieldState.details.gender}
                label={t('customerDetails.label.gender')}
                name="details.gender.value"
                options={genders}
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.maritalStatus}
                label={t('customerDetails.label.maritalStatus')}
                name="details.maritalStatus.value"
                options={maritalStatuses}
                sort={false}
            />
            {isAffinBank && (
                <CountryField
                    disabled={disabled || fieldState.details?.thirdParty?.affinBank?.residentialCountry}
                    label={t('customerDetails.label.residenceCountry')}
                    name="details.thirdParty.affinBank.residentialCountry.value"
                />
            )}
            <SelectField.Outline
                disabled={
                    disabled ||
                    (isAffinBank
                        ? fieldState.details?.thirdParty?.affinBank?.residentialStatus
                        : fieldState.details.residentialStatus)
                }
                label={t('customerDetails.label.residentialStatus')}
                name={
                    isAffinBank
                        ? 'details.thirdParty.affinBank.residentialStatus.value'
                        : 'details.residentialStatus.value'
                }
                options={isAffinBank ? residentialStatusesAffin : residentialStatuses}
                sort={false}
            />
            <DrivingLicense disabled={disabled} />
        </>
    );
};
export type LicenseSchema = {
    expiryDate: yup.StringSchema<string, object>;
    number: yup.StringSchema<string, object>;
    type: yup.StringSchema<string, object>;
};

export const rootSchema = (t: TFunction) => ({
    // @ts-ignore
    dateOfBirth: yup.lazy((values, options) => {
        const shouldValidate = get(['context', 'useCustomerBirthDay'], options);

        if (shouldValidate) {
            return yupExt.customerProperty().shape({
                value: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    }),
});

export const affinBankSchema = (t: TFunction) => ({
    idType: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    idIssueCountry: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    residentialCountry: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    residentialStatus: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    race: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
});

export const detailsSchema = (t: TFunction) => ({
    drivingLicense: yup
        .array()
        .of(
            // @ts-ignore
            yup.lazy((value, { context }) => {
                const countryCode = get('countryCode', context);
                if (get('source', value) === CustomerDetailsSource.MYINFO) {
                    return yup.mixed().notRequired();
                }

                const licenseSchema: any = {
                    expiryDate: yup.string().required(t('common.error.required')),
                    type: yup.string().notRequired(),
                    validity: yup.string().required(t('common.error.required')),
                };

                const type = get('type', value);

                switch (type) {
                    case LicenseType.QUALIFIED:
                        if (countryCode !== 'TH') {
                            licenseSchema.classes = yup.array().of(
                                yup.object().shape({
                                    issueDate: yup.string().required(t('common.error.required')),
                                    licenseClass: yup.string().required(t('common.error.required')),
                                })
                            );
                        }

                        break;

                    case LicenseType.PROVISIONAL:
                        if (countryCode !== 'TH') {
                            licenseSchema.classes = yup.array().of(
                                yup.object().shape({
                                    licenseClass: yup.string().required(t('common.error.required')),
                                })
                            );
                        }
                        break;

                    default:
                        licenseSchema.expiryDate = yup.string().nullable().notRequired();
                        licenseSchema.validity = yup.string().nullable().notRequired();
                        break;
                }

                return yup.object().shape(licenseSchema);
            })
        )
        .notRequired(),
    gender: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    maritalStatus: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    nationality: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    race: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    residentialStatus: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
});

export const getApplicantSchema = (bankPresetOption: BankPresetOption, t: TFunction) => {
    switch (bankPresetOption) {
        case BankPresetOption.AFFINBANK:
            return omit(['race', 'residentialStatus'], affinBankSchema(t));

        case BankPresetOption.TTBBANK:
            return {};

        default:
            return detailsSchema(t);
    }
};

export default ApplicantDetailsForm;
