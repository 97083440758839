import PubSub from 'pubsub-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { NewFlowState } from '../../../../flows/DraftFlow';
import { usePersistedFlow } from '../../../../flows/utils/persistency';
import { checkTokenValidity } from '../../../../flows/utils/tokens';
import { getCompanyIdentifier } from '../../../../selectors';
import { getHomeUrl } from '../../../../utilities/urls';
import Footer from '../../../shared/Footer';
import Header from '../../../shared/Header';
import PublicSessionTimer from '../../../shared/PublicSessionTimer';
import RedirectToCompanyHome from '../../../utilities/RedirectToCompanyHome';
import DraftBootstrap from './DraftBootstrap';
import DraftHandler from './DraftHandler';

type LocationState = { token?: string };

type LocationParams = { applicationVersionId: string };

type PersistedData = { token?: string; step?: string };

export const serialize = (data: NewFlowState, stepIdentifier: string) => {
    const persistedData: PersistedData = { token: data.token, step: stepIdentifier };

    return JSON.stringify(persistedData);
};

export const unserialize = (serializedData: string): PersistedData => {
    const data = JSON.parse(serializedData) as PersistedData;

    if (data?.token && !checkTokenValidity(data.token)) {
        // start with an empty state
        return {};
    }

    return data;
};

export const generateCacheKey = (pubSubChannel: string, state: NewFlowState) =>
    `${pubSubChannel}:${state.application?.version.id}`;

const pubSubChannel = 'draftFlow';

const onIdle = () => PubSub.publishSync(`${pubSubChannel}.timeout`);

const DraftRoute = () => {
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    const { state: { token: tokenFromLocation } = {} } = useLocation<LocationState>();
    const { applicationVersionId } = useParams<LocationParams>();

    const { token = tokenFromLocation } =
        usePersistedFlow(
            `draftFlow:${applicationVersionId}`,
            pubSubChannel,
            serialize,
            unserialize,
            generateCacheKey
        ) || {};

    if (!token) {
        // token is mandatory
        return <RedirectToCompanyHome />;
    }

    return (
        <>
            <DraftBootstrap token={token}>
                {(application, consents) => {
                    if (applicationVersionId !== application.version.id) {
                        // something is not right
                        return <RedirectToCompanyHome />;
                    }

                    const getUrl = getHomeUrl(application.channel);

                    return (
                        <>
                            <PublicSessionTimer onIdle={onIdle} url={getUrl(companyCode, locationCode)} />
                            <Header channel={application.channel} />
                            <DraftHandler application={application} consents={consents} token={token} />
                        </>
                    );
                }}
            </DraftBootstrap>
            <Footer />
        </>
    );
};

export default DraftRoute;
