import { CalculatorContext, defaultPreset } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Actions, DarkButton } from '@appvantageasia/afc-ui';
import React, { useCallback, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedCalculator, {
    ConnectedCalculatorProps,
} from '../../../../components/shared/calculator-next/ConnectedCalculator';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import IconButton from '../../../../components/ui/IconButton';
import { VariantImage } from '../../../../components/ui/calculator';
import {
    VariantImageContainer,
    ComparisonItemContainer,
    CalculatorContainer,
    CalculatorComparisonError,
} from '../../../../components/ui/calculator/CalculatorComparison';
import { Channel } from '../../../../schema';
import { useApplyCalculator } from '../../../utils/ApplyCalculatorProvider';
import { CalculatorStepValues } from '../../steps/CalculatorStep';
import { useVariant } from './Single';
import { ReactComponent as CircleTimes } from '../../../../assets/images/circle-times.svg';
import noImg from '../../../../assets/images/no-image.png';

export type ComparisonItemProps = {
    initialValues: any;
    remove: (uid: string) => void;
    onChange?: (context: CalculatorContext<CalculatorValues>) => void;
} & Omit<ConnectedCalculatorProps, 'channel' | 'initialValues' | 'setVariantId' | 'children'>;

const ComparisonItem = ({ initialValues, meta, dealerId, remove, onChange, ...props }: ComparisonItemProps) => {
    const { t } = useTranslation();

    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const { values, errors, uid } = context || {};

    const enhancedOnChange = useCallback(
        (context: CalculatorContext<CalculatorValues>) => {
            setContext(context);

            if (onChange) {
                onChange(context);
            }
        },
        [onChange]
    );

    // the variant may eventually change later
    const variant = useVariant(dealerId, values?.variant || initialValues.variant);

    // callback to apply with this calculator
    const submit = useApplyCalculator<CalculatorStepValues>();
    const onSubmit = useCallback(() => {
        if (!context) {
            throw new Error('missing values');
        }

        const { values, getFieldContext } = context;

        return submit({
            calculator: values,
            bank: getFieldContext('bank').selectedBank,
            financeProduct: getFieldContext('financeProduct').selectedFinanceProduct,
            variant: getFieldContext('variant').selectedVariant,
            promo: getFieldContext('promoCode').promo,
        });
    }, [submit, context]);

    const removeCalculator = useCallback(() => {
        if (uid) {
            remove(uid);
        }
    }, [uid, remove]);

    return (
        <ComparisonItemContainer>
            <VariantImageContainer>
                <IconButton onClick={removeCalculator}>
                    <CircleTimes />
                </IconButton>
                <VariantImage alt={variant?.name} fallbackSrc={noImg} src={variant?.images[0]?.url || noImg} />
            </VariantImageContainer>
            <CalculatorContainer>
                <ConnectedCalculator
                    channel={Channel.NEW}
                    dealerId={dealerId}
                    initialValues={initialValues}
                    meta={meta}
                    onChange={enhancedOnChange}
                    size={1}
                >
                    {defaultPreset}
                </ConnectedCalculator>
            </CalculatorContainer>
            <CalculatorComparisonError errors={errors} />
            <Actions>
                <DarkButton disabled={false} onClick={onSubmit}>
                    {t('newCalculatorPage.button.next')}
                </DarkButton>
            </Actions>
        </ComparisonItemContainer>
    );
};

export default memo(ComparisonItem);
