import { get } from 'lodash/fp';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FieldArray, FieldArrayFieldsProps, ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import DateField from '../../../../components/shared/form-v2/DateField';
import NumberField from '../../../../components/shared/form-v2/NumberField';
import TextField from '../../../../components/shared/form-v2/TextField';
import CheckboxField from '../../../../components/shared/form/CheckboxField';
import useCustomerSource from '../../../../components/utilities/useCustomerSource';
import { Channel } from '../../../../schema';
import { checkHasTradeIn } from '../DraftPage/Form/TradeInVehicleForm';
import { Header, Title } from '../DraftPage/ui';

type VehicleItemProps = {
    mini?: boolean;
    arrayName: string;
    name: string;
    index: number;
    length: number;
    disabled?: boolean;
};

const VehicleItem = ({
    arrayName,
    name,
    index,
    length,
    disabled: disabledFromProps = false,
    mini = false,
}: VehicleItemProps) => {
    const { t } = useTranslation();

    const watchedFields = useMemo(
        () => [
            {
                path: name,
                properties: [
                    'make',
                    'model',
                    'year',
                    'registrationDate',
                    'roadTaxExpiryDate',
                    'engineCapacity',
                    'color.primary',
                    'color.secondary',
                    'status',
                    'scheme',
                    'coe.category',
                    'coe.expiryDate',
                    'quotaPremium',
                    'openMarketValue',
                    'noOfTransfer',
                    'mileage',
                ],
            },
        ],
        [name]
    );

    // get field state
    const fieldState = useCustomerSource(watchedFields);

    // check if field is disabled
    const disabled = disabledFromProps || get(name, fieldState);

    const { change } = useContext(ReduxFormContext);
    const dispatch = useDispatch();

    const onChange = useCallback(
        value => {
            // disallow trade in for other vehicles
            if (value) {
                for (let i = 0; i < length; i++) {
                    if (i === index) {
                        // we skip if same index with this item
                        continue;
                    }

                    dispatch(change(`${arrayName}[${i}].allowTradeIn`, false));
                }
            }
        },
        [arrayName, change, dispatch, index, length]
    );
    const fields = [
        <TextField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.registrationNo')}
            maxLength={8}
            name={`${name}.registrationNo`}
        />,
        <TextField disabled={disabled} label={t('eventTradeInVehicle.label.make')} name={`${name}.make`} />,
        <TextField disabled={disabled} label={t('eventTradeInVehicle.label.model')} name={`${name}.model`} />,
        <NumberField disabled={disabled} label={t('eventTradeInVehicle.label.year')} name={`${name}.year`} />,
        <DateField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.registrationDate')}
            name={`${name}.registrationDate`}
        />,
        <DateField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.roadTaxExpiryDate')}
            name={`${name}.roadTaxExpiryDate`}
        />,
        <NumberField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.engineCapacity')}
            name={`${name}.engineCapacity`}
        />,
        <TextField disabled={disabled} label={t('eventTradeInVehicle.label.propellant')} name={`${name}.propellant`} />,
        <TextField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.primaryColor')}
            name={`${name}.color.primary`}
        />,
        <TextField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.secondaryColor')}
            name={`${name}.color.secondary`}
        />,
        <TextField disabled={disabled} label={t('eventTradeInVehicle.label.status')} name={`${name}.status`} />,
        <TextField disabled={disabled} label={t('eventTradeInVehicle.label.scheme')} name={`${name}.scheme`} />,
        <TextField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.coeCategory')}
            name={`${name}.coe.category`}
        />,
        <DateField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.coeExpiryDate')}
            name={`${name}.coe.expiryDate`}
        />,
        <NumberField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.quotaPremium')}
            name={`${name}.quotaPremium`}
        />,
        <NumberField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.openMarketValue')}
            name={`${name}.openMarketValue`}
        />,
        <NumberField
            disabled={disabled}
            label={t('eventTradeInVehicle.label.noOfTransfers')}
            name={`${name}.noOfTransfer`}
        />,
        <NumberField
            label={t('eventTradeInVehicle.label.mileage')}
            name={`${name}.mileage`}
            placeholder={t('eventTradeInVehicle.placeholder.mileage')}
        />,
    ];

    const header = (
        <Header>
            <Title>{t('eventTradeInVehicle.titleWithIndex', { index: index + 1 })}</Title>
            <CheckboxField
                channel={Channel.USED}
                label={t('eventTradeInVehicle.label.allowTradeIn')}
                name={`${name}.allowTradeIn`}
                onChange={onChange}
            />
        </Header>
    );

    const padding = <div style={{ padding: '25px 0' }} />;

    return mini ? (
        <>
            <div>
                {header}
                {fields.slice(0, 9)}
            </div>
            <div>
                {padding}
                {fields.slice(9)}
            </div>
        </>
    ) : (
        <>
            <div>
                {header}
                {fields.slice(0, 6)}
            </div>
            <div>
                {padding}
                {fields.slice(6, 12)}
            </div>
            <div>
                {padding}
                {fields.slice(12)}
            </div>
        </>
    );
};

const VehicleArray = ({
    fields,
    disabled,
    shrinkLastItem,
}: VehicleFormProps & { fields: FieldArrayFieldsProps<any> }) => {
    const { name } = fields;

    const children = fields.map((field, index) => (
        <VehicleItem
            key={field}
            arrayName={name}
            disabled={disabled}
            index={index}
            length={fields.length}
            mini={shrinkLastItem && index === fields.length - 1}
            name={field}
        />
    ));

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

type VehicleFormProps = {
    disabled?: boolean;
    shrinkLastItem: boolean;
};

const VehicleForm = (props: VehicleFormProps) => (
    <FieldArray component={VehicleArray} name="details.vehicles" rerenderOnEveryChange {...props} />
);

export default VehicleForm;

// remove error message for required in events
export const vehicleSchema = yup
    .array()
    .of(
        yup.lazy(values => {
            if (checkHasTradeIn(values)) {
                return yup.object().shape({
                    make: yup.string().required(' '),
                    mileage: yup.string().required(' '),
                    model: yup.string().required(' '),
                    registrationNo: yup.string().required(' '),
                });
            }

            return yup.mixed().notRequired();
        })
    )
    .notRequired();
