import { FontAwesomeIcon as Icon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type IconButtonProps = {
    children?: React.ReactNode;
    icon?: FontAwesomeIconProps['icon'];
    size?: FontAwesomeIconProps['size'];
    onClick?: () => void;
    onMouseEnter?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
};

const IconButton = ({ children, icon, size, ...props }: IconButtonProps) => (
    <button type="button" {...props}>
        {children || (icon && <Icon icon={icon} size={size} />)}
    </button>
);

export default styled(IconButton)<{ disabled?: boolean }>`
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
