import { useQuery } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useContentTranslation } from '../../../../i18n';
import { getCompanyIdentifier } from '../../../../selectors';
import { getAppointmentsUrl } from '../../../../utilities/urls';
import HelmetTitle from '../../../shared/HelmetTitle';
import AppointmentDetails from './AppointmentDetails';
import {
    getAppointmentByVersionId,
    GetAppointmentByVersionIdQuery,
    GetAppointmentByVersionIdQueryVariables,
} from './Details.graphql';

const AppointmentDetailsRoute = () => {
    const { language } = useContentTranslation();
    const { versionId } = useParams<{ versionId: string }>();

    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    const { data, loading } = useQuery<GetAppointmentByVersionIdQuery, GetAppointmentByVersionIdQueryVariables>(
        getAppointmentByVersionId,
        {
            variables: { versionId, locale: language },
            fetchPolicy: 'no-cache',
        }
    );

    const appointment = data?.appointment;

    if (loading) {
        return null;
    }

    if (!appointment) {
        return <Redirect to={getAppointmentsUrl(companyCode, locationCode)} />;
    }

    return (
        <>
            <HelmetTitle title="Appointment Details" />
            <AppointmentDetails key={appointment.id} appointment={appointment} />
        </>
    );
};

export default AppointmentDetailsRoute;
