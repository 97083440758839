import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import CheckboxField from '../../../../components/shared/form-v2/CheckboxField';
import DateField from '../../../../components/shared/form-v2/DateField';
import FileField from '../../../../components/shared/form-v2/FileField';
import NumberField from '../../../../components/shared/form-v2/NumberField';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../components/shared/form-v2/TextField';
import validateFile from '../../../../components/shared/partialForms/KYCForm/validateFile';
import { UploadPurpose } from '../../../../schema';
import { getCountryCode } from '../../../../selectors';
import { ownerIdOptions } from '../../../../utilities/constants/options';
import { createFileSizeValidator } from '../../../../utilities/forms';
import { Disclaimer, Emphasis } from './ui';

export type PreOwnedDetailsProps = {
    computationsOnly?: boolean;
};

const PreOwnedDetails = ({ computationsOnly = false }: PreOwnedDetailsProps) => {
    const { t } = useTranslation();
    const today = useMemo(() => new Date(), []);
    const countryCode = useSelector(getCountryCode);

    // show disclaimer label only in SG
    const isSingaporeMarket = countryCode === 'SG';

    return (
        <div>
            {!computationsOnly && (
                <>
                    <TextField label={t('preOwnedDetails.label.make')} name="makeName" disabled />
                    <TextField label={t('preOwnedDetails.label.model')} name="modelName" />
                    <TextField label={t('preOwnedDetails.label.plateNumber')} name="preOwnedCarDetails.plateNumber" />
                </>
            )}
            <DateField
                label={t('preOwnedDetails.label.originalRegistrationDate')}
                maxDate={today}
                name="preOwnedCarDetails.originalRegistrationDate"
            />
            {isSingaporeMarket && (
                <>
                    <DateField
                        label={t('preOwnedDetails.label.coeExpiryDate')}
                        name="preOwnedCarDetails.coeExpiryDate"
                    />
                    <NumberField
                        label={t('preOwnedDetails.label.qpPaid')}
                        name="preOwnedCarDetails.qpPaid"
                        type="currency"
                    />
                    <NumberField label={t('preOwnedDetails.label.omv')} name="preOwnedCarDetails.omv" type="currency" />
                    {!computationsOnly && (
                        <>
                            <SelectField.Outline
                                label={t('preOwnedDetails.label.ownerIdType')}
                                name="preOwnedCarDetails.ownerIdType"
                                options={ownerIdOptions}
                            />
                            <TextField
                                label={t('preOwnedDetails.label.ownerIdSuffix')}
                                maxLength="4"
                                name="preOwnedCarDetails.ownerIdSuffix"
                            />
                            <FileField
                                label={t('preOwnedDetails.label.vehicleLogCard')}
                                name="preOwnedCarDetails.vehicleLogCard"
                                placeholder={t('preOwnedDetails.placeholder.vehicleLogCard')}
                                purpose={UploadPurpose.VEHICLE_LOG_CARD}
                            />
                            <br />
                            <CheckboxField
                                label={t('preOwnedDetails.label.inspected')}
                                name="preOwnedCarDetails.inspected"
                            />
                        </>
                    )}
                    <br />
                    <Disclaimer>
                        <Trans
                            components={{
                                span: (
                                    <Emphasis
                                        href="https://vrl.lta.gov.sg/lta/vrl/action/pubfunc?ID=EnquireRebateBeforeDeReg"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    />
                                ),
                            }}
                            i18nKey="preOwnedDetails.disclaimer"
                        />
                    </Disclaimer>
                </>
            )}
        </div>
    );
};

const preOwnedCarDetailsSingaporeMarketSchema = (t: TFunction) => ({
    coeExpiryDate: yup.string().required(t('common.error.required')),
    inspected: yup.bool().required(t('common.error.required')).oneOf([true], t('common.error.required')),
    omv: yup.string().nullable().required(t('common.error.required')),
    ownerIdSuffix: yup
        .string()
        .required(t('common.error.required'))
        .min(4, t('common.error.exact', { count: 4 }))
        .max(4, t('common.error.exact', { count: 4 })),
    ownerIdType: yup.string().required(t('common.error.required')),
    qpPaid: yup.string().required(t('common.error.required')),
    vehicleLogCard: yup
        .mixed()
        .required(t('common.error.required'))
        // @ts-ignore
        .test('extension-validation', t('common.error.fileExtension'), validateFile)
        // @ts-ignore
        .test('size-validation', t('common.error.fileSize', { size: '2MB' }), createFileSizeValidator(2)),
});

const preOwnedCarDetailsGenericSchema = (t: TFunction) => ({
    originalRegistrationDate: yup.string().required(t('common.error.required')),
    plateNumber: yup.string().required(t('common.error.required')),
});

export const preownedNonSGMarketSchema = (t: TFunction) =>
    yup.object().shape({
        makeName: yup.string().required(t('common.error.required')),
        modelName: yup.string().required(t('common.error.required')),
        preOwnedCarDetails: yup.object().shape({
            ...preOwnedCarDetailsGenericSchema(t),
        }),
    });

export const preownedSGMarketSchema = (t: TFunction) =>
    yup.object().shape({
        makeName: yup.string().required(t('common.error.required')),
        modelName: yup.string().required(t('common.error.required')),
        preOwnedCarDetails: yup.object().shape({
            ...preOwnedCarDetailsGenericSchema(t),
            ...preOwnedCarDetailsSingaporeMarketSchema(t),
        }),
    });

export default PreOwnedDetails;
