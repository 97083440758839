import React from 'react';
import SystemContainer from '../ui/SystemContainer';
import { useFixedMenuController } from './FixedMenuController';

type WrapperProps = {
    children: JSX.Element | React.ReactNode;
};

const Wrapper = ({ children }: WrapperProps) => {
    const { fixedMenu } = useFixedMenuController();

    return <SystemContainer fixedMenu={fixedMenu}>{children}</SystemContainer>;
};
export default Wrapper;
