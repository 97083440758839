import { OutlineWrapper, PureError } from '@appvantageasia/afc-ui';
import React from 'react';
import BoxedFileInput from '../../ui/form/BoxedFileInput';
import PreviewFileInput from '../../ui/form/PreviewFileInput';
import TextField from './TextField';

const FileField = props => <TextField inputComponent={BoxedFileInput} {...props} />;

FileField.Preview = props => (
    <TextField
        errorComponent={PureError}
        inputComponent={PreviewFileInput}
        wrapperComponent={OutlineWrapper}
        {...props}
    />
);

export default FileField;
