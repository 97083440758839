import React from 'react';
import { FormSection } from 'redux-form';
import CustomerStep, { CustomerStepProps } from './CustomerStep';

export type GuarantStepProps = Omit<CustomerStepProps, 'name'>;

const GuarantorStep = ({ ...props }: GuarantStepProps) => {
    return (
        <FormSection name="guarantor">
            <CustomerStep name="Guarantor Details" {...props} />
        </FormSection>
    );
};

export default GuarantorStep;
