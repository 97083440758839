import { identity, isEmpty } from 'lodash/fp';
import React, { useCallback } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/shared';
import { getAuthorization } from '../../selectors';

const LogoutOnInactivity = () => {
    // retrieve the session/authorization from the redux state
    const authorization = useSelector(getAuthorization);
    const { timeout = 3200 } = authorization;

    // whenever we have a session, we need to use idle
    const useIdle = !isEmpty(authorization);

    const dispatch = useDispatch();
    const onIdle = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        useIdle && (
            <IdleTimer
                debounce={250}
                element={document}
                onAction={identity}
                onActive={identity}
                onIdle={onIdle}
                timeout={timeout * 3200}
            />
        )
    );
};

export default LogoutOnInactivity;
