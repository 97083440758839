export type SubmitPaymentResponse = {
    // checksum: string;
    // payRequestId: string;
    url: string;
};

export const submitPayment = async (token: string): Promise<SubmitPaymentResponse> => {
    const response = await fetch('/api/ttb/payment', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};
