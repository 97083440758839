import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSigningCallback } from '../../shared/Signing';

const SigningCallbackAction = props => {
    const { applicationId, referer, isAuthenticated } = props;

    // then callback
    const signingCallback = useSigningCallback(applicationId, isAuthenticated, referer);

    useEffect(() => {
        if (signingCallback) {
            signingCallback();
        }
    }, [applicationId, signingCallback]);

    return null;
};

SigningCallbackAction.propTypes = {
    applicationId: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    referer: PropTypes.string.isRequired,
};

export default SigningCallbackAction;
