import styled from 'styled-components';

type TStyledTitle = {
    customSpace?: string;
};

const Title = styled.h1<TStyledTitle>`
    font-size: 1.43rem;
    font-weight: 400;
    text-transform: ${props => props.theme.casing};
    margin-bottom: 10px;
    width: 100%;
    text-align: center;

    @media (max-width: 768px) {
        margin-top: ${props => props.customSpace || '10px'};
    }
`;

export default Title;
