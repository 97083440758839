import React from 'react';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import { ApplicationFlowSource } from '../../../schema';
import { Application } from '../../DraftFlow';
import { FlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';
import { MyInfoProps } from '../components/DraftPage/Form/MyInfo';
import MyInfoRoute from '../components/MyInfo';

export type MyInfoStepValues = Omit<MyInfoProps, 'myInfo'> & {
    customer: ApplicationCustomerDataFragment;
    hasError: boolean;
};

class MyInfoStep extends FlowStep<EventFlowState, MyInfoStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'myInfo';
    }

    // eslint-disable-next-line class-methods-use-this
    public get ignoreOnBack(): boolean {
        return true;
    }

    public get isCompleted(): boolean {
        const { application, reservation } = this.state;

        if (reservation) {
            // For next step to my info
            if (reservation.customer && reservation.customer.withMyInfo) {
                return true;
            }

            return !!application?.steps?.reservationToFinance;
        }

        return !!application?.id;
    }

    public render(): React.ReactElement | null {
        const { event } = this.state;

        if (!event) {
            throw new Error('Event missing in state');
        }

        return <MyInfoRoute event={event} onSubmit={this.submit} source={ApplicationFlowSource.EVENT} />;
    }

    public async execute({ customer, hasError, ...state }: MyInfoStepValues) {
        const { application = {} as Application } = this.state;

        if (hasError) {
            // if there's error we need to go back to previous step
            this.stateStore.update({
                withMyInfoError: hasError,
                ...state,
            });

            return this.flow.getBackStep(this.identifier);
        }

        this.stateStore.update({
            application: { ...application, customer },
            ...state,
        });

        return this.nextStep;
    }

    public async executeBefore(): Promise<FlowStep<EventFlowState>> {
        // if step is not entered through initialzed
        // we skip this step since application is manual process
        return this.nextStep as FlowStep<EventFlowState>;
    }
}

export default MyInfoStep;
