import { filter, orderBy, get } from 'lodash/fp';
import { ConsentDataFragment } from '../../../api/consents.graphql';
import { Channel, ConsentOrDeclarationOwnerType } from '../../../schema';

type AllInputConsents = ConsentDataFragment[] | null | undefined;

export const getBankConsents = filter<ConsentDataFragment>(
    item => get('owner.type', item) === ConsentOrDeclarationOwnerType.BANK
);

export const getCountryConsents = filter<ConsentDataFragment>(
    item => get('owner.type', item) === ConsentOrDeclarationOwnerType.COUNTRY
);

export const getNewCarChannelConsents = filter<ConsentDataFragment>(
    item => get('owner.type', item) === ConsentOrDeclarationOwnerType.NEWCARCHANNEL
);

export const getPreownedChannelConsents = filter<ConsentDataFragment>(
    item => get('owner.type', item) === ConsentOrDeclarationOwnerType.PREOWNEDCARCHANNEL
);

export const filterInsuranceConsents = filter<ConsentDataFragment>(
    item => get('owner.type', item) === ConsentOrDeclarationOwnerType.INSURANCE
);

const getCountryAndChannelConsents = (all: AllInputConsents, channel: Channel) => [
    ...getCountryConsents(all),
    ...(channel === Channel.EXPRESS ? getPreownedChannelConsents(all) : []),
    ...(channel === Channel.NEW ? getNewCarChannelConsents(all) : []),
];

export const getFinancingConsents = (all: AllInputConsents, channel: Channel) =>
    orderBy('order', 'asc', [...getBankConsents(all), ...getCountryAndChannelConsents(all, channel)]);

export const getNonFinancingConsents = (all: AllInputConsents, channel: Channel) =>
    orderBy('order', 'asc', getCountryAndChannelConsents(all, channel));

export const getInsuranceConsents = (all: AllInputConsents) => orderBy('order', 'asc', filterInsuranceConsents(all));

export const getCountryInsuranceConsents = (all: AllInputConsents, channel: Channel) =>
    orderBy('order', 'asc', [...filterInsuranceConsents(all), ...getCountryAndChannelConsents(all, channel)]);
