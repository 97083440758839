import React, { createContext, useContext, useMemo, useState } from 'react';

type HeaderControllerState = {
    component: React.ComponentType | null;
    properties: any;
};

type HeaderControllerMethods = {
    set?: (component: HeaderControllerState['component'], properties?: HeaderControllerState['properties']) => void;
    unset?: () => void;
};

const initialState: HeaderControllerState = { component: null, properties: null };

type HeaderControllerContext = [HeaderControllerState, HeaderControllerMethods];

export const Context: React.Context<HeaderControllerContext> = createContext([initialState, {}]);

export const useHeaderController = (): HeaderControllerContext => useContext<HeaderControllerContext>(Context);

type HeaderControllerProps = {
    children: React.ReactNode;
};

const HeaderController = ({ children }: HeaderControllerProps) => {
    const [state, setState] = useState<HeaderControllerState>(initialState);

    const { set, unset } = useMemo(
        () => ({
            set: (component: React.ComponentType | null, properties = null) => setState({ component, properties }),
            unset: () => setState(initialState),
        }),
        [setState]
    );

    const context = useMemo((): HeaderControllerContext => [state, { set, unset }], [state, set, unset]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default HeaderController;
