import { ToggleContainer } from '@appvantageasia/afc-ui';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
    faBars,
    faAddressCard,
    faFileAlt,
    faSignOutAlt,
    faCarAlt,
    faCarSide,
    faShippingFast,
    faUserTie,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { logout } from '../../actions/shared';
import { useCountry } from '../../hookSelectors';
import { getGlobalPermissions } from '../../selectors';
import { getPrivateAccess } from '../../utilities/channels';
import { getProfileUrl } from '../../utilities/urls';
import IconButton from '../ui/IconButton';
import { MenuSidebarContainer, StyledNavLink } from '../ui/MenuSidebar';
import Avatar from './Avatar';
import Drawer from './Drawer';
import { useFixedMenuController } from './FixedMenuController';

const MenuSidebar = ({ onClose, ...props }) => {
    const { t } = useTranslation();
    const {
        mayViewLead,
        mayViewApplication,
        mayViewReservation,
        mayViewNewCarChannel,
        mayViewUsedCarChannel,
        mayViewExpressChannel,
        mayViewEventChannel,
        mayViewCustomer,
        mayViewInsuranceApplication,
        mayViewAppointment,
    } = useSelector(getGlobalPermissions);

    const { channelSetting } = useCountry();

    const dispatch = useDispatch();

    const loc = useLocation();
    const [companyCode, locationCode] = loc.pathname.split('/').filter(Boolean);

    const menus = useMemo(() => {
        const prefixPath = path => `/${companyCode}/${locationCode}${path}`;

        return [
            mayViewNewCarChannel &&
                getPrivateAccess(channelSetting.new) && {
                    key: 'newCarChannel',
                    label: t('common.links.newCarChannel'),
                    icon: faCarAlt,
                    link: prefixPath('/new'),
                    onClick: onClose,
                },
            mayViewUsedCarChannel &&
                getPrivateAccess(channelSetting.used) && {
                    key: 'usedCarChannel',
                    label: t('common.links.usedCarChannel'),
                    icon: faCarSide,
                    link: prefixPath('/used'),
                    onClick: onClose,
                },
            mayViewExpressChannel &&
                getPrivateAccess(channelSetting.express) && {
                    key: 'expressChannel',
                    label: t('common.links.expressChannel'),
                    icon: faShippingFast,
                    link: prefixPath('/preowned/calculator'),
                    onClick: onClose,
                },
            mayViewEventChannel &&
                getPrivateAccess(channelSetting.event) && {
                    key: 'eventsChannel',
                    label: t('common.links.eventsChannel'),
                    icon: faCalendar,
                    link: prefixPath('/event'),
                    onClick: onClose,
                },
            mayViewLead && {
                key: 'leads',
                label: t('common.links.leads'),
                icon: faAddressCard,
                link: prefixPath('/leads'),
                onClick: onClose,
            },
            mayViewReservation && {
                key: 'reservation-applications',
                label: t('common.links.reservationApplications'),
                icon: faFileAlt,
                link: prefixPath('/reservations'),
                onClick: onClose,
            },
            mayViewApplication && {
                key: 'finance-applications',
                label: t('common.links.financeApplications'),
                icon: faFileAlt,
                link: prefixPath('/applications'),
                onClick: onClose,
            },
            mayViewInsuranceApplication && {
                key: 'insurance-applications',
                label: t('common.links.insuranceApplications'),
                icon: faFileAlt,
                link: prefixPath('/insuranceApplications'),
            },
            mayViewAppointment && {
                key: 'appointments',
                label: t('common.links.appointments'),
                icon: faCalendar,
                link: prefixPath('/appointments'),
                onClick: onClose,
            },
            mayViewCustomer && {
                key: 'customers',
                label: t('common.links.customers'),
                icon: faUserTie,
                link: prefixPath('/customers'),
                onClick: onClose,
            },
            {
                key: 'signOut',
                label: t('common.links.signOut'),
                icon: faSignOutAlt,
                link: prefixPath('/login'),
                onClick: event => {
                    // prevent the event
                    event.stopPropagation();
                    event.preventDefault();

                    // trigger a logout
                    dispatch(logout('manual'));

                    // close the menu
                    onClose();

                    return false;
                },
            },
        ].filter(Boolean);
    }, [
        mayViewNewCarChannel,
        channelSetting,
        t,
        onClose,
        mayViewUsedCarChannel,
        mayViewExpressChannel,
        mayViewEventChannel,
        mayViewLead,
        mayViewReservation,
        mayViewApplication,
        mayViewInsuranceApplication,
        mayViewAppointment,
        mayViewCustomer,
        companyCode,
        locationCode,
        dispatch,
    ]);

    const isActive = useCallback((match, location, item) => {
        switch (item.key) {
            case 'home':
                // check for home urls
                return (match && match.isExact) || location.pathname.indexOf('/apply') !== -1;

            default:
                return location.pathname.indexOf(item.link) !== -1;
        }
    }, []);

    // redirect to profile
    const history = useHistory();
    const onAvatarClick = useCallback(() => {
        history.pushWithCompany(getProfileUrl);

        onClose();
    }, [history, onClose]);

    const { fixedMenu, onFixedToggle } = useFixedMenuController();

    return (
        <Drawer onClose={onClose} {...props}>
            <MenuSidebarContainer>
                <ToggleContainer onClick={onFixedToggle}>
                    <FontAwesomeIcon icon={fixedMenu ? faTimes : faBars} size="lg" />
                </ToggleContainer>
                <Avatar onClick={onAvatarClick} />
                {menus.map(item => (
                    <StyledNavLink
                        key={item.key}
                        isActive={(match, location) => isActive(match, location, item)}
                        onClick={item.onClick}
                        to={item.link}
                    >
                        <div>{item.label}</div>
                        <IconButton icon={item.icon} size="lg" />
                    </StyledNavLink>
                ))}
            </MenuSidebarContainer>
        </Drawer>
    );
};

MenuSidebar.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default MenuSidebar;
