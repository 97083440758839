import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadDialingCodes } from '../../actions';
import * as selectors from '../../selectors';

const usePhone = () => {
    const dispatch = useDispatch();
    const { code: countryCode } = useSelector(selectors.getCurrentCountry);
    const dialingCodes = useSelector(selectors.getDialingCodes);

    useEffect(() => {
        dispatch(loadDialingCodes());
    }, [dispatch]);

    return useMemo(() => dialingCodes?.find(item => item.countryCode === countryCode)?.phone || {}, [
        dialingCodes,
        countryCode,
    ]);
};

export default usePhone;
