import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../../components/shared/ImgWithFallback';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import noImg from '../../../../../assets/images/no-image.png';
import cross from '../assets/cross.png';
import infoIcon from '../assets/info.png';
import leftArrowGray from '../assets/left-gray.png';
import leftArrowWhite from '../assets/left.png';
import rightArrowGray from '../assets/right-gray.png';
import rightArrowWhite from '../assets/right.png';

const SlideShowContainer = styled.div<{ isHidden: boolean; scrollBarWidth: number }>`
    max-width: 100%;
    height: 40vh;
    display: ${props => (props.isHidden ? 'none' : 'grid')};
    background-color: black;
    grid-template-rows: 60px calc(100% - 120px) 60px;
    grid-template-columns: 60px calc(100% - 120px) 60px;
    grid-template-areas:
        '. . cross'
        'arrowLeft text arrowRight'
        '. . infoIcon';

    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        height: 60vh;
        grid-template-rows: 60px calc(100% - 120px) 60px;
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        width: ${props => `calc(60vw - ${props.scrollBarWidth}px)`};
        height: 90vh;
        grid-template-rows: 60px calc(100% - 120px) 60px;
        grid-template-columns: 60px calc(100% - 120px) 60px;
    }
`;

const ImageContainer = styled.div<{ isInfoHidden: boolean }>`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 40vh;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${props =>
        !props.isInfoHidden &&
        css`
            opacity: 0.4;
        `}

    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        height: 60vh;
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        width: 100%;
        height: 92vh;
        img {
            object-position: 50% 80%;
        }
    }
`;

const TextContainer = styled.div<{ isInfoHidden: boolean }>`
    grid-area: text;
    z-index: ${props => (props.isInfoHidden ? '-1' : '999')};
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    div {
        display: inline-block;
        max-height: 90%;
        overflow-y: auto;
        h4 {
            margin-bottom: 5px;
        }
        p {
            margin: 0;
        }
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 100px;
            h4 {
                margin-bottom: 60px;
            }
            p {
                line-height: 2;
                margin-bottom: 30px;
            }
        }
    }
`;

const CrossContainer = styled.div<{ isInfoHidden: boolean }>`
    grid-area: cross;
    z-index: ${props => (props.isInfoHidden ? '-1' : '999')};
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    img {
        width: 20px !important;
        height: 20px !important;
    }
`;

const ArrowContainerLeft = styled.div`
    max-width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: arrowLeft;
    img {
        width: 20px !important;
        height: 40px !important;
        object-position: center !important;
    }
`;

const ArrowContainerRight = styled(ArrowContainerLeft)`
    grid-area: arrowRight;
`;

const ArrowImageContainer = styled.div`
    cursor: pointer;
`;

const InfoIcon = styled.div<{ isInfoHidden: boolean }>`
    grid-area: infoIcon;
    z-index: ${props => (props.isInfoHidden ? '999' : '-1')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
        width: 20px !important;
        height: 20px !important;
    }
`;

type SlideShowProps = {
    options: { desktopImage: string; mobileImage: string; description: { headline: string; body: string[] } }[];
    isArrowColorGray: boolean;
};

export const SlideShow = ({ options, isArrowColorGray }: SlideShowProps) => {
    const isMobileSource = useMedia({ maxWidth: breakpoints.sm });
    const [selectedItem, setSelected] = useState(options[0].description.headline);
    const [isInfoHidden, setInfoHidden] = useState(true);
    // the width of scroll bar
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

    const onClick = useCallback(() => {
        setInfoHidden(false);
    }, []);
    const onClose = useCallback(() => {
        setInfoHidden(true);
    }, []);
    const changeImage = useCallback(option => {
        setSelected(option);
    }, []);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {options.map((item, index) => (
                <SlideShowContainer
                    key={item.description.headline}
                    isHidden={selectedItem !== item.description.headline}
                    scrollBarWidth={scrollBarWidth}
                >
                    <ImageContainer isInfoHidden={isInfoHidden}>
                        <ImgWithFallback
                            alt={item.description.headline}
                            fallbackSrc={noImg}
                            src={isMobileSource ? item.mobileImage : item.desktopImage}
                        />
                    </ImageContainer>
                    <ArrowContainerLeft>
                        <ArrowImageContainer
                            onClick={() =>
                                changeImage(
                                    index > 0
                                        ? options[index - 1].description.headline
                                        : options[index - 1 + options.length].description.headline
                                )
                            }
                        >
                            <img
                                alt="left arrow"
                                src={isArrowColorGray && isInfoHidden ? leftArrowGray : leftArrowWhite}
                            />
                        </ArrowImageContainer>
                    </ArrowContainerLeft>
                    <CrossContainer isInfoHidden={isInfoHidden} onClick={onClose}>
                        <img alt="cross" src={cross} />
                    </CrossContainer>
                    <TextContainer isInfoHidden={isInfoHidden}>
                        <div>
                            <h4>{item.description.headline}</h4>
                            {item.description.body.map((text, index) => (
                                <p key={index.toString()}>{text}</p>
                            ))}
                        </div>
                    </TextContainer>
                    <ArrowContainerRight>
                        <ArrowImageContainer
                            onClick={() =>
                                changeImage(
                                    index < options.length - 1
                                        ? options[index + 1].description.headline
                                        : options[index + 1 - options.length].description.headline
                                )
                            }
                        >
                            <img
                                alt="right arrow"
                                src={isArrowColorGray && isInfoHidden ? rightArrowGray : rightArrowWhite}
                            />
                        </ArrowImageContainer>
                    </ArrowContainerRight>
                    <InfoIcon isInfoHidden={isInfoHidden} onClick={onClick}>
                        <img alt="info" src={infoIcon} />
                    </InfoIcon>
                </SlideShowContainer>
            ))}
        </>
    );
};

export default SlideShow;
