import React, { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import ImgWithFallback from '../../../../../components/shared/ImgWithFallback';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { TimepieceValues } from '../Timepiece';
import { ModalContainer, ModalWrapper, ContentContainer, CrossContainer } from './PackageModal';
import useTimepieceImage from './useTimepieceImage';
import noImg from '../../../../../assets/images/no-image.png';
import close from '../assets/close.png';

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 50px 50px;
    width: 100%;
    height: 250px;
    padding: 20px;
    background-color: white;
    .textWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h4 {
            margin-bottom: 30px;
        }
        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            span {
                width: 50%;
                text-align: left;
            }
        }
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        width: 50%;
        height: 600px;
        padding: 20px 50px 50px;
    }
`;

type TimepieceModalProps = {
    timepiece: TimepieceValues;
    options: {
        hands: {
            value: string;
            dropdownOptions: {
                value: string;
                label: string;
            }[];
            description: {
                headline: string;
            };
        };
        colouredRing: {
            value: string;
            dropdownOptions: {
                value: string;
                label: string;
            }[];
            description: {
                headline: string;
            };
        };
        engraving: {
            description: {
                headline: string;
            };
        };
    };
    setDisplayTimepiece: Dispatch<SetStateAction<boolean>>;
};

export const TimepieceModal = ({ timepiece, setDisplayTimepiece, options }: TimepieceModalProps) => {
    const timepieceImage = useTimepieceImage(timepiece);
    const onClose = useCallback(() => {
        setDisplayTimepiece(false);
    }, [setDisplayTimepiece]);

    return (
        <ModalContainer>
            <ModalWrapper width="1000px">
                <ContentContainer>
                    <ImgWithFallback alt="timepiece" fallbackSrc={noImg} src={timepieceImage} />
                    <CrossContainer marginLeft="950px" onClick={onClose}>
                        <img alt="cross" src={close} />
                    </CrossContainer>
                    <TextContainer>
                        <div className="textWrapper">
                            <h4>Your Selection</h4>
                            <div>
                                <span>{options.hands.description.headline}</span>
                                <span>
                                    {
                                        options.hands.dropdownOptions.filter(item => item.value === timepiece.hands)[0]
                                            .label
                                    }
                                </span>
                            </div>
                            <div>
                                <span>{options.colouredRing.description.headline}</span>
                                <span>
                                    {
                                        options.colouredRing.dropdownOptions.filter(
                                            item => item.value === timepiece.colouredRing
                                        )[0].label
                                    }
                                </span>
                            </div>
                            {timepiece.engraving && (
                                <div>
                                    <span>{options.engraving.description.headline}</span>
                                    <span>{timepiece.engraving}</span>
                                </div>
                            )}
                        </div>
                    </TextContainer>
                </ContentContainer>
            </ModalWrapper>
        </ModalContainer>
    );
};

export default TimepieceModal;
