import React, { useCallback, Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormSection } from 'redux-form';
import styled from 'styled-components';
import useMedia from 'use-media';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../components/shared/form-v2/TextField';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { onTextOrNumKeyPress } from '../../../../utilities/forms';
import { ConfigFormValues } from './ConfigForm';
import { SelectionContainer } from './Exterior';
import { PriceField } from './Package';
import AddField, { Button } from './shared/AddField';
import SlideShow from './shared/SlideShow';
import { SectionTitle } from './shared/ui';
import lessDetails from './assets/less-details.png';
import moreDetails from './assets/more-details.png';

const TimepieceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .selection {
        width: 80%;
        max-width: 400px;
        min-width: 320px;
        margin: 20px;
        font-size: 16px;
        label {
            font-size: 16px;
            color: #444444;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    /* Desktop and Tablet Screen */
    @media (min-width: ${breakpoints.xl}) {
        margin: 30px 20px;
        .selection {
            align-self: center;
        }
    }
`;

const TextLimit = styled.div`
    display: flex;
    span {
        width: 100%;
        text-align: right;
        font-size: 12px;
        font-style: italic;
        margin: 5px;
    }
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column !important;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start !important;
    cursor: pointer;
    img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 10px;
    }
    span {
        font-size: 12px !important;
        font-style: italic !important;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    .firstColumn {
        width: 30%;
    }
    .secondColumn {
        width: 60%;
    }
`;

export const TermsAndConditions = styled.div`
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    margin: 10px 0;
`;

export type TimepieceValues = {
    hands: string;
    colouredRing: string;
    engraving?: string;
    isAdded?: boolean;
};

type TimepieceProps = {
    setDisplayTimepiece: Dispatch<SetStateAction<boolean>>;
    setDisplayTerms: Dispatch<SetStateAction<boolean>>;
};

const Timepiece = ({ setDisplayTimepiece, setDisplayTerms }: TimepieceProps) => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { options } = ckdConfiguration.timepiece;
    const { formatCurrency } = useCompanyFormatting();
    const [showDetails, setShowDetails] = useState(false);

    // get form values
    const values = useFormValues<ConfigFormValues>();
    const timepiece = values?.timepiece;
    const active = timepiece?.hands && timepiece?.colouredRing;

    const onClick = useCallback(() => {
        setDisplayTimepiece(true);
    }, [setDisplayTimepiece]);

    const handleTermsAndConditions = useCallback(() => {
        setDisplayTerms(true);
    }, [setDisplayTerms]);

    const showSpecifications = useCallback(() => {
        setShowDetails(!showDetails);
    }, [showDetails]);

    return (
        <FormSection name="timepiece">
            <SelectionContainer>
                {isMobile && <SlideShow options={Object.values(options)} isArrowColorGray />}
                <div className="configurator">
                    <TimepieceContainer>
                        <SectionTitle>{ckdConfiguration.timepiece.headline}</SectionTitle>
                        <PriceField>{formatCurrency(ckdConfiguration.timepiece.price)}</PriceField>
                        <div className="selection">
                            <SelectField.Outline
                                disabled={options.hands.dropdownOptions.length <= 1}
                                label={options.hands.description.headline}
                                name={options.hands.label}
                                options={options.hands.dropdownOptions}
                                placeholder="Select"
                            />
                            <SelectField.Outline
                                disabled={options.colouredRing.dropdownOptions.length <= 1}
                                label={options.colouredRing.description.headline}
                                name={options.colouredRing.label}
                                options={options.colouredRing.dropdownOptions}
                                placeholder="Select"
                            />
                            <TextField
                                label={options.engraving.description.headline}
                                maxLength="12"
                                name={options.engraving.label}
                                onKeyPress={onTextOrNumKeyPress}
                                placeholder="Your text here"
                            />
                            <TextLimit>
                                <span>{options.engraving.limit}</span>
                            </TextLimit>
                            <DetailsContainer>
                                <ButtonContainer onClick={showSpecifications}>
                                    {showDetails ? (
                                        <img alt="more details" src={lessDetails} />
                                    ) : (
                                        <img alt="less details" src={moreDetails} />
                                    )}
                                    <span>{ckdConfiguration.timepiece.specifications.label}</span>
                                </ButtonContainer>

                                {showDetails &&
                                    ckdConfiguration.timepiece.specifications.details.map((item: string[]) => (
                                        <TextContainer>
                                            <span className="firstColumn">{item[0]}</span>
                                            <span className="secondColumn">{item[1]}</span>
                                        </TextContainer>
                                    ))}
                            </DetailsContainer>
                            <TermsAndConditions onClick={handleTermsAndConditions}>
                                <span>{ckdConfiguration.timepiece.termsAndConditions.headline}</span>
                            </TermsAndConditions>
                        </div>
                        <div className="buttons">
                            <Button
                                backgroundColor={active ? '#737278' : '#b5b4ba'}
                                disabled={!active}
                                onClick={onClick}
                            >
                                {ckdConfiguration.timepiece.buttons[0]}
                            </Button>
                            <AddField
                                defaultColor={active ? '#737278' : '#b5b4ba'}
                                disabled={!active}
                                fieldName="timepiece.isAdded"
                                name="isAdded"
                            />
                        </div>
                    </TimepieceContainer>
                </div>
            </SelectionContainer>
        </FormSection>
    );
};

export default Timepiece;
