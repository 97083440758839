import { IntlValue } from '@appvantageasia/afc-calculator-ui-next';
import { get, uniqBy } from 'lodash/fp';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import useLoadVariants from '../../../../../components/data/useLoadVariants';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import { ContentTranslator, useContentTranslation } from '../../../../../i18n';
import { AssetCondition, Channel } from '../../../../../schema';
import { CarOfInterestType } from '../../../steps/EventDraftStep';

export const useAssetConditionOptions = () => {
    const { t } = useTranslation();

    return useMemo(
        () => [
            { label: t('eventVehicleOfInterest.assetCondition.new'), value: AssetCondition.NEW },
            { label: t('eventVehicleOfInterest.assetCondition.preOwned'), value: AssetCondition.USED },
        ],
        [t]
    );
};

export const getModelsFromVariants = (variants: VariantDataFragment[]) => {
    const models = variants.map(variant => variant.model);
    const allModels = models.flatMap(model => (model.parent ? [model, { parentId: null, ...model.parent }] : [model]));

    return uniqBy(get('id'), allModels);
};

const mapEntityAsOption = (item: { name: IntlValue<string>; id: string }, ct: ContentTranslator) => ({
    label: ct(item.name),
    value: item.id,
});

type CarOfInterestSelectionProps = {
    disabled: boolean;
    dealerId: string;
};

const CarOfInterestSelection = ({ disabled = false, dealerId }: CarOfInterestSelectionProps) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const formData: { carOfInterest?: CarOfInterestType } = useSelector(getFormValues('customer'));
    const values = formData.carOfInterest;

    const { variants: allVariants } = useLoadVariants('cache-first', Channel.EVENT, dealerId);

    const modelId = values?.modelId;
    const subModelId = values?.subModelId;

    const variants = useMemo(() => allVariants.filter(variant => variant?.assetCondition !== AssetCondition.USED), [
        allVariants,
    ]);

    const noVariants = variants.length === 0;

    // get the model options
    const allModels = useMemo(() => getModelsFromVariants(variants), [variants]);

    // extract models as options
    const modelOptions = useMemo(
        () => allModels.filter(model => !model.parentId).map(item => mapEntityAsOption(item, ct)),
        [allModels, ct]
    );

    // extract sub models
    const subModelOptions = useMemo(() => {
        if (modelId) {
            return allModels.filter(model => model.parentId === modelId).map(item => mapEntityAsOption(item, ct));
        }

        return [];
    }, [modelId, allModels, ct]);

    const finalModelId = subModelOptions.length > 0 ? subModelId : modelId;

    // get variants
    const variantOptions = useMemo(() => {
        if (finalModelId) {
            return variants
                .filter(variant => variant.model.id === finalModelId)
                .map(item => mapEntityAsOption(item, ct));
        }

        return [];
    }, [finalModelId, variants, ct]);

    const assetConditions = useAssetConditionOptions();

    const previousValuesRef = useRef(values);
    const dispatch = useDispatch();

    useEffect(() => {
        let reset = false;

        if (previousValuesRef.current?.modelId !== values?.modelId) {
            reset = true;

            // reset sub model id
            dispatch(
                change(
                    'customer',
                    'carOfInterest.subModelId',
                    subModelOptions.length === 1 ? subModelOptions[0].value : undefined
                )
            );
        }

        if (reset || previousValuesRef.current?.subModelId !== values?.subModelId) {
            // reset variant id
            dispatch(
                change(
                    'customer',
                    'carOfInterest.variantId',
                    variantOptions.length === 1 ? variantOptions[0].value : undefined
                )
            );
        }

        previousValuesRef.current = values;
    }, [values, previousValuesRef, modelOptions, subModelOptions, variantOptions, dispatch]);

    return (
        <>
            <SelectField.OutlineLeft
                disabled={disabled}
                name="assetCondition"
                options={assetConditions}
                placeholder={t('eventVehicleOfInterest.placeholder.assetCondition')}
            />
            {modelOptions.length > 0 && (
                <SelectField.OutlineLeft
                    disabled={disabled}
                    name="modelId"
                    options={modelOptions}
                    placeholder={t('eventVehicleOfInterest.placeholder.model')}
                />
            )}
            {subModelOptions.length > 0 && (
                <SelectField.OutlineLeft
                    disabled={disabled || subModelOptions.length === 1}
                    name="subModelId"
                    options={subModelOptions}
                    placeholder={t('eventVehicleOfInterest.placeholder.subModel')}
                />
            )}
            {variantOptions.length > 0 && (
                <SelectField.OutlineLeft
                    disabled={disabled || variantOptions.length === 1}
                    name="variantId"
                    options={variantOptions}
                    placeholder={t('eventVehicleOfInterest.placeholder.variant')}
                />
            )}
            {noVariants && (
                <TextField
                    disabled={disabled}
                    label={t('eventVehicleOfInterest.placeholder.vehicleModel')}
                    name="variantName"
                />
            )}
        </>
    );
};

export default CarOfInterestSelection;
