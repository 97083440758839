import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { setNotification } from '../../../../actions';
import { cleanTTBPaymentSession, getTTBPaymentSession } from '../../../../components/routes/wip/TTBPaymentCallback';
import { ApplicationFlowSource, PaymentStatus } from '../../../../schema';

const useTTBPayment = (applicationId: string, source: ApplicationFlowSource) => {
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const { t } = useTranslation();
    const [status, setStatus] = useState<PaymentStatus | null>(null);

    const processStatus = useCallback(
        paymentStatus => {
            switch (paymentStatus) {
                case PaymentStatus.FAILED:
                    dispatch(
                        setNotification(
                            t('notification.paymentRefused.title'),
                            t('notification.paymentRefused.message')
                        )
                    );
                    break;
            }

            setStatus(paymentStatus);
        },
        [dispatch, t]
    );

    useEffect(() => {
        const session = getTTBPaymentSession(applicationId, source);

        if (session) {
            cleanTTBPaymentSession();
            processStatus(session.response?.status);
        }
    }, [applicationId, processStatus, source]);

    return useMemo(() => ({ status }), [status]);
};

export default useTTBPayment;
