// @ts-ignore
import { GrayButton, Actions, Modal, ActionsCenter } from '@appvantageasia/afc-ui';
import PubSub from 'pubsub-js';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { EventFlowState } from '../../../flows/EventFlow/EventSubmitFlow';
import { Flow } from '../../../flows/utils/flow';
import createWebSocketClient from '../../../utilities/createWebSocketClient';
import { Info } from '../../ui/Notification';
import { FinderVehicleDataFragment, onFinderVehicleBookingExpiry } from './data.graphql';

type FinderVehicleBookingWatcherProps = {
    finderVehicle: FinderVehicleDataFragment;
    isExpired: boolean;
};

const nullCallback = () => null;

const FinderVehicleBookingWatcher = ({ finderVehicle, isExpired = false }: FinderVehicleBookingWatcherProps) => {
    const { t } = useTranslation();
    const { getState } = useStore();

    const [showModal, setShowModal] = useState(isExpired);
    const [bookingId, setBookingId] = useState<string | undefined>();

    useEffect(() => {
        const token = PubSub.subscribe('eventSubmitFlow', (msg: string, flow: Flow<EventFlowState>) => {
            switch (msg) {
                case 'eventSubmitFlow.initialized':
                case 'eventSubmitFlow.updated':
                    setBookingId(flow.state.bookingId);
                    break;

                default:
                    break;
            }
        });

        return () => {
            PubSub.unsubscribe(token);
        };
    }, []);

    useEffect(() => {
        if (!bookingId || !finderVehicle) {
            return nullCallback;
        }

        // create a websocket client
        const client = createWebSocketClient(getState);

        const variables = { bookingId };

        const observable = client.subscribe({ query: onFinderVehicleBookingExpiry, variables }).subscribe(() => {
            // show modal
            setShowModal(true);
        });

        return () => {
            // unsubscribe
            observable.unsubscribe();
        };
    }, [bookingId, finderVehicle, getState]);

    const onClose = useCallback(() => {
        // clear session
        PubSub.publishSync(`eventSubmitFlow.completed`);
        setShowModal(false);
        window.location.href = finderVehicle.url;
    }, [finderVehicle.url]);

    if (!showModal) {
        return null;
    }

    return (
        <Modal onClose={onClose} title={t('finderVehicleBookingExpiry.title')} showClose showTitle>
            <Info>{t('finderVehicleBookingExpiry.label.message')}</Info>
            <Actions>
                <ActionsCenter>
                    <GrayButton onClick={onClose}>{t('finderVehicleBookingExpiry.button.confirm')}</GrayButton>
                </ActionsCenter>
            </Actions>
        </Modal>
    );
};

export default FinderVehicleBookingWatcher;
