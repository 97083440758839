import { TFunction } from 'i18next';
import { set } from 'lodash/fp';
import { FinanceDataFragment } from '../../components/data/useFinanceProducts.graphql';
import { InventoryUnitsDataFragment } from '../../components/data/useLoadInventoryUnits.graphql';
import { VariantDataFragment } from '../../components/data/useLoadVariants.graphql';
// eslint-disable-next-line max-len
import { calculateMonthlyInstallment } from '../../components/routes/wip/CarListRoute/processVariantWithFormattedPrices';
import { InventoryUnitPrice } from '../../schema';

const overrideVariantDataWithUnit = <
    V extends VariantDataFragment & {
        monthlyPrice?: number;
        priceDescription?: string;
    }
>(
    variant: V,
    unit: InventoryUnitsDataFragment,
    t: TFunction,
    financeProduct?: FinanceDataFragment | null,
    roundNumber?: (value: number) => number,
    formatCurrency?: (value: number, currency?: string) => string,
    currencySymbol?: string
): V => {
    let newVariant = variant;

    const overridePrices = (variant: V, unit: InventoryUnitsDataFragment): Array<InventoryUnitPrice> => {
        // if inventory unit prices are uninitialized ( empty array ), use variant prices
        if (!unit.prices?.length) {
            return variant.prices;
        }

        const prices: Array<InventoryUnitPrice> = [];

        // always override variant price with inventory unit price
        variant.prices.forEach(({ value, zoneId }) => {
            const unitToOverrideVariant = unit.prices.find(price => price.zoneId === zoneId);
            if (!unitToOverrideVariant) {
                // if one of the zones has no inventory unit price, remain with variant price
                prices.push({ value, zoneId });
            } else {
                prices.push(unitToOverrideVariant);
                const unitPrice = unitToOverrideVariant.value;

                if (financeProduct && formatCurrency && roundNumber) {
                    const monthly = financeProduct
                        ? calculateMonthlyInstallment(financeProduct, {
                              id: variant.id,
                              price: unitPrice,
                              versionId: variant.version.id,
                          }) || 0
                        : 0;

                    const formattedMonthlyPrice = formatCurrency(monthly, currencySymbol);
                    const formattedTotalPrice = formatCurrency(unitPrice, currencySymbol);
                    const priceDescription = t('chooserPage.variantPrice', {
                        totalPrice: formattedTotalPrice,
                        monthlyPrice: formattedMonthlyPrice,
                    });
                    const monthlyPrice = roundNumber(monthly);

                    newVariant = set('priceDescription', priceDescription || newVariant.priceDescription, newVariant);
                    newVariant = set('monthlyPrice', monthlyPrice || newVariant.monthlyPrice, newVariant);
                }
            }
        });

        return prices;
    };

    if (unit.images.length) {
        // add images
        newVariant = set('images', [...unit.images, variant.images], newVariant);
    }

    // override details
    newVariant = set('description', unit.details.description || newVariant.description, newVariant);
    newVariant = set('features', unit.details.features || newVariant.features, newVariant);
    newVariant = set('usedCarDetails', { ...newVariant.usedCarDetails, ...unit.details.usedCarDetails }, newVariant);
    newVariant = set('prices', overridePrices(newVariant, unit), newVariant);

    return newVariant;
};

export default overrideVariantDataWithUnit;
