import styled, { css } from 'styled-components';

type CustomStyledProps = {
    background: string;
};

export const ApplicationStatusStyle = styled.div<CustomStyledProps>`
    width: 80px;
    padding: 4px 8px;
    font-size: 0.57rem;
    text-align: center;
    border-radius: 3px;
    background: ${props => props.background || 'inherit'};
    color: white;

    ${props =>
        !props.background &&
        css`
            border: 1px solid #dedede;
            color: black;
        `}
`;

export const JourneySection = styled.div`
    .journey-section {
        color: black;
        margin-bottom: 15px;
        .title {
            padding: 6px 0 9.5px 0;
            font-size: 1.28rem;
            color: #000000;
            height: 39px;
        }
        .journey-item {
            border: 1px solid #dedede;
            padding: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 15px;
            font-size: 0.85rem;
            .quote-info {
                flex: 1;
                display: block;
            }
            .stage-info {
                flex: 5;
                display: flex;
                flex-direction: row;
                .stage-flow-item {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    .stage-icon {
                        width: 30px;
                        height: 30px;
                        min-height: 30px;
                        min-width: 30px;
                        border-radius: 15px;
                        font-size: 1.07rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 5px;
                        &.checked {
                            color: #4fa2ff;
                            border: 1px solid #4fa2ff;
                        }
                        &.in-progress {
                            color: white;
                            background: #4fa2ff;
                            border: 1px solid #4fa2ff;
                        }
                        &.times {
                            color: #c10034;
                            border: 1px solid #c10034;
                        }
                        &.disabled {
                            color: #dedede;
                            border: 1px solid #dedede;
                        }
                    }
                    .info {
                        padding-top: 10px;
                        margin: 0 5px;
                        .info-title {
                            text-transform: uppercase;
                        }
                        .info-item {
                            color: #888;
                            font-size: 0.72rem;
                        }
                    }
                    &.disabled {
                        color: #dedede;
                        cursor: not-allowed;
                        .info {
                            .info-item {
                                color: #dedede;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 860px) {
        .journey-section {
            .journey-item {
                flex-direction: column;
                .quote-info {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px dashed #dedede;
                    display: flex;
                    justify-content: space-between;
                    .status-info {
                        align-self: center;
                    }
                }
                .stage-info {
                    flex-direction: column;
                    flex-wrap: wrap;
                    .stage-flow-item {
                        width: 100%;
                        min-width: 145px;
                        max-width: 100%;
                        min-height: 60px;
                    }
                }
            }
        }
    }
`;
