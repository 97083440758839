import React, { useMemo } from 'react';
import { CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import Page from './Page';

export type CalculatorProps = {
    onSubmit: (values: any) => Promise<void>;
    dealerId: string;
    initialValues?: Partial<CalculatorValues>;
    snapshot?: any;
};

const Calculator = ({ onSubmit, snapshot, initialValues, dealerId }: CalculatorProps) => {
    if (!initialValues) {
        return null;
    }

    return <Page dealerId={dealerId} initialValues={initialValues} snapshot={snapshot} submit={onSubmit} />;
};

export default Calculator;
