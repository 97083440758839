import { LoginType } from '../schema';
import * as graph from './account.graphql';

export const refreshAuthorization = async client => {
    const { data } = await client.mutate({ mutation: graph.refreshCredentials, fetchPolicy: 'no-cache' });

    return data;
};

export const emitRequestNewPassword = async (client, username, countryId, companyId, locale) => {
    const variables = { username, countryId, companyId, locale, purpose: LoginType.CI };
    const { data } = await client.mutate({ mutation: graph.sendResetPassword, variables, fetchPolicy: 'no-cache' });

    return data.success;
};

export const consumeResetPassword = async (client, secret, password) => {
    const variables = { secret, password };
    const { data } = await client.mutate({ mutation: graph.resetPassword, variables, fetchPolicy: 'no-cache' });

    return data.success;
};

export const logout = async client => {
    const { data } = await client.mutate({ mutation: graph.logout, fetchPolicy: 'no-cache' });

    return data;
};
