import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationPhase, ApplicationStatus } from '../../../../schema';
import { DropdownContainer } from '../../../ui/LanguageSelector';
import ActionButtonSelect from '../../../ui/form/ActionButtonSelect';
import useApplyForFinancing from '../../ReservationRoute/actions/useApplyForFinancing';
import { ApplicationDataFragment } from '../data.graphql';

export type ApplicationStatusMobileProps = {
    application: ApplicationDataFragment;
    showApproveDeclineActions?: boolean;
    voidDisabled: boolean;
    completeDisabled: boolean;
    nextStatusProps: Function;
    downloadDisabled: boolean;
    downloadAction: Function;
    showAgreementConcluded: boolean;
};

const ApplicationStatusMobile = ({
    application,
    showApproveDeclineActions,
    voidDisabled,
    completeDisabled,
    downloadDisabled,
    nextStatusProps,
    downloadAction,
    showAgreementConcluded,
}: ApplicationStatusMobileProps) => {
    const [t] = useTranslation();

    const { identifier, phase } = application;

    if (!identifier) {
        throw new Error('identifier missing in state');
    }

    const downloadLabel = t('applicationDetailsPage.button.downloadDocuments');
    const applyForFinancingLabel = t('applicationDetailsPage.button.applyForFinancing');

    const getApplicationStatus = useMemo(() => {
        const options: Array<{ label: string; value: any }> = [];

        if (!downloadDisabled) {
            options.push({
                value: downloadLabel,
                label: downloadLabel,
            });
        }

        if (!voidDisabled) {
            options.push({ value: ApplicationStatus.CANCELLED, label: 'Void' });
        }

        if (phase === ApplicationPhase.RESERVATION && !application.financeApplication?.identifier) {
            options.push({
                value: applyForFinancingLabel,
                label: applyForFinancingLabel,
            });
        }

        if (showAgreementConcluded) {
            options.push({
                value: ApplicationStatus.AGREEMENTCONCLUDED,
                label: t('applicationDetailsPage.button.concludeAgreement'),
            });
        }

        if (!completeDisabled) {
            options.push({ value: ApplicationStatus.COMPLETED, label: ApplicationStatus.COMPLETED });
        }

        if (showApproveDeclineActions) {
            options.push(
                { value: ApplicationStatus.APPROVED, label: ApplicationStatus.APPROVED },
                { value: ApplicationStatus.DECLINED, label: ApplicationStatus.DECLINED }
            );
        }

        return options;
    }, [
        downloadDisabled,
        voidDisabled,
        phase,
        application.financeApplication,
        showAgreementConcluded,
        completeDisabled,
        showApproveDeclineActions,
        downloadLabel,
        applyForFinancingLabel,
        t,
    ]);

    const applyForFinancing = useApplyForFinancing();

    const handleChange = useCallback(
        data => {
            if (data.value === downloadLabel) {
                return downloadAction();
            }

            if (data.value === applyForFinancingLabel) {
                return applyForFinancing(application);
            }

            return nextStatusProps(data.value);
        },
        [application, applyForFinancing, applyForFinancingLabel, downloadAction, downloadLabel, nextStatusProps]
    );

    return (
        <DropdownContainer>
            <ActionButtonSelect
                isSearchable={false}
                menuPlacement="top"
                onChange={handleChange}
                options={getApplicationStatus}
                placeholder="Action"
                value="Action"
                isTextCenter
            />
        </DropdownContainer>
    );
};

export default ApplicationStatusMobile;
