import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change, ReduxFormContext } from 'redux-form';
import styled from 'styled-components';
import { getRuntimeSettings } from '../../../../../selectors';

export const Button = styled.button<{
    backgroundColor: string;
    selected?: boolean;
    disabled?: boolean;
    color?: string;
    withBorder?: boolean;
}>`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    margin: 10px;
    height: 35px;
    border: ${props => (props.withBorder ? '2px solid grey' : 'none')};
    background: ${props => (props.selected ? '#d5001c' : props.backgroundColor)};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 16px;
    font-weight: 550;
    color: ${props => (props.color ? props.color : 'white')};
`;

type AddButtonProps = {
    fieldName: string;
    input: any;
    disabled: boolean;
    defaultColor: string;
};

export const AddButton = ({ input, fieldName, disabled, defaultColor }: AddButtonProps) => {
    const { value } = input;
    const dispatch = useDispatch();
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { enhancedPackage } = ckdConfiguration;

    const { form } = useContext(ReduxFormContext);

    const onClick = useCallback(() => dispatch(change(form, fieldName, !value)), [dispatch, fieldName, form, value]);

    return (
        <Button backgroundColor={defaultColor} disabled={disabled} onClick={onClick} selected={value}>
            {value ? enhancedPackage.buttons.added : enhancedPackage.buttons.add}
        </Button>
    );
};

const AddField = (props: any) => <Field {...props} component={AddButton} />;

export default AddField;
