import { TFunction } from 'i18next';
import { uniq, pick } from 'lodash/fp';
import { useMemo } from 'react';
import overrideVariantDataWithUnit from '../../../../flows/utils/overrideVariantDataWithUnit';
import { FinanceDataFragment } from '../../../data/useFinanceProducts.graphql';
import { InventoryUnitsDataFragment } from '../../../data/useLoadInventoryUnits.graphql';
import { ProcessedVariant } from '../CarListRoute/processVariantWithFormattedPrices';

const useComputed = (
    variants: ProcessedVariant[],
    units: InventoryUnitsDataFragment[],
    financeProduct: FinanceDataFragment | null,
    roundNumber: (value: number) => number,
    formatCurrency: (value: number, currency?: string) => string,
    currencySymbol: string,
    t: TFunction
): { variants: ProcessedVariant[]; units: ProcessedVariant[] } =>
    useMemo(() => {
        // map variant ids
        const entries = variants.map((variant): [string, ProcessedVariant] => [variant.version.id, variant]);
        const mappedVariants = Object.fromEntries(entries);

        // then link variants on units
        const availableUnits = units
            .map(unit => {
                const { variantId } = unit.set.inventory;
                const variant = mappedVariants[variantId];

                if (!variant) {
                    return null;
                }

                return {
                    ...overrideVariantDataWithUnit(
                        variant,
                        unit,
                        t,
                        financeProduct,
                        roundNumber,
                        formatCurrency,
                        currencySymbol
                    ),
                    unit,
                };
            })
            .filter(Boolean) as (ProcessedVariant & { unit: InventoryUnitsDataFragment })[];

        // then list back variants
        const availableUnitIds = uniq(availableUnits.map(unit => unit.version.id));
        const availableVariants = Object.values(pick(availableUnitIds, mappedVariants));

        return { variants: availableVariants, units: availableUnits };
    }, [variants, units, t, financeProduct, roundNumber, formatCurrency, currencySymbol]);

export default useComputed;
