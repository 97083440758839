import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { MakeDataFragment, ModelDataFragment } from '../../../../data/useLoadVariants.graphql';
import { FilterDefinition } from './index';

const useSubModelFilter = (
    maker: string | null,
    makers: MakeDataFragment[],
    model: string | null,
    models: ModelDataFragment[],
    subModel: string | null,
    subModels: ModelDataFragment[],
    setMaker: (makeId: string | null) => void,
    setModel: (modelId: string | null) => void,
    setSubModel: (modelId: string | null) => void
): FilterDefinition<string> | null => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    // get current model to get its name
    const modelBackLabel = useMemo(() => ct(models.find(item => item.id === model)?.name), [ct, model, models]);
    // get current make to get its name
    const makeBackLabel = useMemo(() => ct(makers.find(item => item.id === maker)?.name), [ct, maker, makers]);

    // callback for changes
    const onChange = useCallback(
        ({ value }) => {
            switch (value) {
                case 'makeBack':
                    // reset make model
                    setMaker(null);
                    setModel(null);
                    break;

                case 'modelBack':
                    // reset model
                    setModel(null);
                    break;

                case 'all':
                    // reset sub model
                    setSubModel(null);
                    break;

                default:
                    // update sub model
                    setSubModel(value);
                    break;
            }
        },
        [setMaker, setModel, setSubModel]
    );

    return useMemo(() => {
        if (subModels.length < 2) {
            // we cannot display it because we do not have enough sub models
            return null;
        }

        if (!maker && makers.length > 1) {
            // the user needs first to select the maker
            return null;
        }

        if (!model && models.length > 1) {
            // the user needs first to select the model
            return null;
        }

        const withMakers = makers.length > 1;
        const withModels = models.length > 1;

        const options = [
            // the back option
            withModels && { id: 'modelBack', value: 'modelBack', label: `< ${modelBackLabel}` },
            withMakers && !withModels && { id: 'makeBack', value: 'makeBack', label: `< ${makeBackLabel}` },
            // all option
            { id: 'all', value: 'all', label: t('chooserPage.filter.selectAll') },
            // sub model options
            ...subModels.map(subModelOption => ({
                id: subModelOption.id,
                value: subModelOption.id,
                label: ct(subModelOption.name),
            })),
        ].filter(Boolean) as FilterDefinition<string>['options'];

        return {
            key: 'subModel',
            title: t('chooserPage.filter.carModelTitle'),
            isBack: withMakers || withModels,
            onChange,
            options,
            value: subModel ?? 'all',
        };
    }, [
        subModels,
        maker,
        makers.length,
        model,
        models.length,
        modelBackLabel,
        makeBackLabel,
        t,
        onChange,
        subModel,
        ct,
    ]);
};

export default useSubModelFilter;
