import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { addBusinessDays } from 'date-fns';
import React, { memo, ReactElement, useState, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useFormatDateTime from '../../../../components/shared/useFormatDate';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { FinanceData } from '../../../EventFlow/components/Calculator/blocks/Financing';
import Introduction from '../../../EventFlow/components/Calculator/blocks/Introduction';
import { Page as Layout, Row, Block } from '../../../EventFlow/components/Calculator/ui';
import { EventCalculatorStepValues } from '../../../EventFlow/steps/EventCalculatorStep';
import { CalculatorSnapshot } from '../../../utils/getSnapshotFromApplication';
import Financing from './Financing';

export type SingleProps = {
    onSubmit: (values: EventCalculatorStepValues) => Promise<void>;
    initialCalculatorValues: Partial<CalculatorValues>;
    initialValues: FinanceData;
    snapshot?: CalculatorSnapshot;
    variant: VariantDataFragment;
    event: EventDataFragment;
};

const Page = ({
    initialCalculatorValues,
    initialValues,
    snapshot,
    onSubmit: apply,
    variant,
    event,
}: SingleProps): ReactElement | null => {
    // get the variant
    const { display } = useTheme();
    const columns = display === 'large' ? 3 : 1;

    const variants = useMemo(() => [variant], [variant]);

    const { formatCurrency } = useCompanyFormatting();
    const formatDate = useFormatDateTime();

    // state to keep track of latest values in the calculator
    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const { values, errors } = context || {};

    // submit function
    const onSubmit = useCallback(
        formValues => {
            if (!context) {
                throw new Error('missing values');
            }

            const { values, getFieldContext } = context;

            return apply({
                calculator: values,
                bank: getFieldContext('bank').selectedBank,
                financeProduct: getFieldContext('financeProduct').selectedFinanceProduct,
                variant: getFieldContext('variant').selectedVariant,
                promo: getFieldContext('promoCode').promo,
                ...formValues,
            });
        },
        [apply, context]
    );

    const paymentAmount = event?.setting?.bookingPayment?.amount;

    const reservationPeriod = event.setting.reservationPeriod ?? 0;
    const reservationInstruction = event.setting.reservationInstruction
        ? event.setting.reservationInstruction
              .replaceAll('<<RESERVATION_PERIOD>>', `${reservationPeriod}`)
              .replaceAll('<<RESERVATION_AMOUNT>>', formatCurrency(paymentAmount))
              .replaceAll('<<RESERVATION_EXPIRE>>', formatDate(addBusinessDays(new Date(), reservationPeriod)))
        : null;

    return (
        <Layout>
            <Block>
                <Row columns={columns}>
                    <Introduction reservationInstruction={reservationInstruction} variant={variant} />
                    <Financing
                        calculatorErrors={errors}
                        calculatorValues={values}
                        event={event}
                        initialCalculatorValues={initialCalculatorValues}
                        initialValues={initialValues}
                        onCalculatorChange={setContext}
                        onSubmit={onSubmit}
                        snapshot={snapshot}
                        variant={variant}
                        variants={variants}
                    />
                </Row>
            </Block>
        </Layout>
    );
};

export default memo(Page);
