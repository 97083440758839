// @ts-ignore
import { OutlineSelect } from '@appvantageasia/afc-ui';
import { isEqual } from 'lodash/fp';
import React, { useMemo } from 'react';
import useMedia from 'use-media';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { SelectContainer, SelectOption } from '../../../../ui/car-models';
import { DropdownContainer } from '../../../../ui/car-models/SelectContainer';

export type SelectValue<TValue> = { id: string; label: string; value: TValue };

export type SelectProps<TValue> = {
    options: SelectValue<TValue>[];
    onChange: (option: SelectValue<TValue>) => void;
    value: TValue | null;
} & { [prop: string]: any };

const Select = <TValue extends any>({ options, onChange, value, ...props }: SelectProps<TValue>) => {
    const isMobile = useMedia({ maxWidth: breakpoints.md });
    const currentOption = useMemo(() => options.find(option => option.value === value), [value, options]) || null;

    if (isMobile) {
        return (
            <DropdownContainer>
                <OutlineSelect
                    isSearchable={false}
                    onChange={onChange}
                    options={options}
                    value={currentOption}
                    isTextCenter
                />
            </DropdownContainer>
        );
    }

    return (
        <SelectContainer {...props}>
            {options.map((option, ind) => (
                <SelectOption
                    key={ind.toString()}
                    active={isEqual(value, option.value)}
                    onClick={() => onChange(option)}
                >
                    {option.label}
                </SelectOption>
            ))}
        </SelectContainer>
    );
};

export default Select;
