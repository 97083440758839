import * as api from '../api';
import { SigningStatus } from '../schema';
import { TIMEOUT } from '../utilities/constants/enevelopeStatuses';
import { attachLoading } from './loading';
import * as v2 from './newApplicationv2';

export const { applyUsedVariant } = v2;

export const getEnvelopeStatus = (applicationId, isInsurance, source) => (dispatch, getState, { client }) => {
    const promise = api.fetchEnvelopeStatus(client, applicationId, isInsurance, source);

    return dispatch(attachLoading(promise));
};

const maxRetries = 10;

export const getPollingValidEnvelopeStatus = (applicationId, isInsurance, source) => dispatch => {
    const promise = new Promise(resolve => {
        let retries = 1;

        const getValidEnvelopeStatus = async () => {
            const status = await dispatch(getEnvelopeStatus(applicationId, isInsurance, source));

            // we will keep polling
            // until we get a valid status
            if (status === SigningStatus.REJECTED || status === SigningStatus.COMPLETED) {
                resolve(status);

                return;
            }

            if (retries === maxRetries) {
                resolve(TIMEOUT);

                return;
            }

            retries++;

            setTimeout(getValidEnvelopeStatus, 2000);
        };

        getValidEnvelopeStatus();
    });

    return dispatch(attachLoading(promise));
};

export const getGuaranteedBuybackStatus = (applicationId, source, signer) => (dispatch, getState, { client }) => {
    const promise = api.fetchGuaranteedBuybackStatus(client, applicationId, source, signer);

    return dispatch(attachLoading(promise));
};

export const getPollingGuaranteedBuybackStatus = (applicationId, source, signer) => dispatch => {
    const promise = new Promise(resolve => {
        let retries = 1;

        const getValidResult = async () => {
            const result = await dispatch(getGuaranteedBuybackStatus(applicationId, source, signer));

            // we will keep polling
            // until we get a valid status
            if (result.status === SigningStatus.REJECTED || result.status === SigningStatus.COMPLETED) {
                resolve(result);

                return;
            }

            if (retries === maxRetries) {
                resolve({ status: TIMEOUT });

                return;
            }

            retries++;

            setTimeout(getValidResult, 2000);
        };

        getValidResult();
    });

    return dispatch(attachLoading(promise));
};
