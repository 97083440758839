import styled from 'styled-components';
import ErrorMessage from '../ErrorMessage';

const FormActions = styled.div`
    border-top: 1px solid #ced4da;
    width: 100%;
    display: flex;
    margin: 1.42rem 0;
    padding-top: 1.42rem;
`;

export const Error = styled(ErrorMessage)`
    padding-left: 1rem;
    flex-grow: 2;
`;

export default FormActions;
