import { LOADING_REGISTER, LOADING_UNREGISTER, LoadingActions } from '../actions/loading';

export type LoadingState = { id: string; silent: boolean }[];

const reducer = (state: LoadingState = [], action: LoadingActions): LoadingState => {
    switch (action.type) {
        case LOADING_REGISTER:
            return [...state, action.task];

        case LOADING_UNREGISTER:
            return state.filter(task => task.id !== action.taskId);

        default:
            return state;
    }
};

export default reducer;
