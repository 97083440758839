import qs from 'qs';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import { DraftFlow, NewFlowState } from '../../../../flows/DraftFlow';
import { FlowType, useManagedFlow, useScrollTop } from '../../../../flows/utils/flow';
import getCalculatorFromApplication from '../../../../flows/utils/getCalculatorFromApplication';
import useCompleteHandler from '../../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { getHomeUrl, getLocationCode } from '../../../../utilities/urls';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';

export type DraftFlowHandlerProps = {
    application: ApplicationDataFragment;
    consents: ConsentDataFragment[];
    token: string;
};

const getFlowImplementation = (channel: Channel): FlowType<NewFlowState, DraftFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
        case Channel.USED:
            return DraftFlow;

        default:
            return null;
    }
};

const DraftHandler = ({ application, consents, token }: DraftFlowHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();
    const history = useHistory();

    // create the initial state
    const initialState = useMemo(
        (): NewFlowState => ({
            channel: application.channel,
            skipEarlyStages: true,
            company,
            token,
            zone,
            country,
            dealerId: application.dealerId!,
            application,
            calculator: getCalculatorFromApplication(application),
            financeProduct: application.financeProduct,
            bank: application.financeProduct?.bank,
            promo: application.promoCode,
            source: ApplicationFlowSource.DRAFT,
            consents,
        }),
        [application, company, token, zone, country, consents]
    );

    const flowImplementation = getFlowImplementation(application.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'draftFlow');

    useScrollTop(step.identifier);

    useCompleteHandler('draftFlow');

    if (application.steps?.submission) {
        const locationCode = getLocationCode(country.code, zone.code);
        const companyRootUrl = getHomeUrl(application.channel)(company.code, locationCode);

        history.push(companyRootUrl, { applicationVersionId: application.version.id, submitted: true });

        return null;
    }

    return step.render();
};

export default DraftHandler;
