import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getGlobalPermissions } from '../../../../selectors';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { getCustomersUrl } from '../../../../utilities/urls';
import applySourceChange from '../../../utilities/applySourceChange';
import useValidationContext from '../../../utilities/useValidationContext';
import { ApplicationCustomerDataFragment } from '../../ApplicationRoute/data.graphql';
import { CustomerApplicationJourneyFragment } from './CustomerRoute.graphql';
import { update } from './Details.graphql';
import Form from './Form';

type CustomerDetails = {
    initialValues: { customer: ApplicationCustomerDataFragment };
    journeys: {
        [key: string]: CustomerApplicationJourneyFragment[];
    };
};

const Details = ({ initialValues, journeys }: CustomerDetails) => {
    const history = useHistory();
    const { mayManageCustomer } = useSelector(getGlobalPermissions);
    const client = useApolloClient();

    const onSubmit = useCallback(
        async ({ ...data }) => {
            const customerData = applySourceChange(data.customer, initialValues.customer);
            const { id, ...newObj } = customerData;

            const newData = prepareForGraphQL(omit(['applications', 'type', 'version'], newObj));
            const variables = { data: newData, id };

            return client.mutate({ mutation: update, variables }).catch(handleResponseError);
        },
        [client, initialValues]
    );

    // @ts-ignore
    const onSubmitSuccess = useCallback(() => history.pushWithCompanyAndState(getCustomersUrl), [history]);
    const validation = useValidationContext();

    return (
        <Form
            disabled={!mayManageCustomer}
            initialValues={initialValues}
            journeys={journeys}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            validation={validation}
        />
    );
};

export default Details;
