import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { DraftFlow } from '../../../flows/DraftFlow';
import { useManagedFlow } from '../../../flows/utils/flow';
import useCompleteHandler from '../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../schema';
import { getGlobalPermissions, getIsAuthenticated } from '../../../selectors';
import { getCompanyLoginUrl } from '../../../utilities/urls';
import { CompanyScopeFragment, CountryScopeFragment, ZoneScopeFragment } from '../../data/useLoadScope.graphql';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import Header from '../../shared/Header';
import PendingModal from '../../shared/PendingModal';
import Error404NotFound from '../Error404NotFound';
import Error404Suggestion from '../Error404Suggestion';
import SubmitSuccessModal from './CarListRoute/SubmitSucessModal';

type ExpressStepProps = {
    company: CompanyScopeFragment;
    country: CountryScopeFragment;
    zone: ZoneScopeFragment;
    dealerId: string;
    isAuthenticated: boolean;
};

const ExpressStep = ({ country, company, zone, dealerId, isAuthenticated }: ExpressStepProps) => {
    const { step } = useManagedFlow(
        DraftFlow,
        {
            isAuthenticated,
            channel: Channel.EXPRESS,
            zone,
            country,
            company,
            source: ApplicationFlowSource.DRAFT,
            dealerId,
        },
        'expressSubmitFlow'
    );

    return step.render();
};

const ExpressRoute = () => {
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();
    const isAuthenticated = useSelector(getIsAuthenticated);
    const { key, pathname, search } = useLocation();
    const history = useHistory();
    const redirect = `${pathname}${search}`;
    const dealerId = useMustExistDealerIdFromTenantContext();

    useCompleteHandler('expressSubmitFlow');

    const { isActive } = country.channelSetting.express;
    const { allowPrivateAccess, isActive: isNewCarActive } = country.channelSetting.new;
    const { allowPrivateAccess: allowUsedCarPrivateAccess, isActive: isUsedCarActive } = country.channelSetting.used;

    const { mayViewNewCarChannel, mayViewUsedCarChannel, mayViewExpressChannel } = useSelector(getGlobalPermissions);
    const mayViewOtherChannel =
        (mayViewNewCarChannel && allowPrivateAccess) || (mayViewUsedCarChannel && allowUsedCarPrivateAccess);

    /* Without useEffect component will re-render as infinite loop
    when we force to logout because of session expire or login in second tab */
    useEffect(() => {
        // unauthenticated
        if (!isAuthenticated) {
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
        }
    }, [history, isAuthenticated, redirect]);

    // no permission or express channel not active
    if (!mayViewExpressChannel || !isActive) {
        const otherActive = isNewCarActive || isUsedCarActive;

        return mayViewOtherChannel && otherActive ? <Error404Suggestion /> : <Error404NotFound />;
    }

    // rerender component if it's still same route and application is done
    return (
        <Fragment key={key}>
            <Header channel={Channel.EXPRESS} />
            <ExpressStep
                company={company}
                country={country}
                dealerId={dealerId}
                isAuthenticated={isAuthenticated}
                zone={zone}
            />
            <SubmitSuccessModal />
            <PendingModal />
        </Fragment>
    );
};

export default ExpressRoute;
