import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import * as yup from 'yup';
import { requirements, passwordRegex } from '../../../utilities/constants/passwordRequirements';
import { createFormValidation, requiredString } from '../../../utilities/forms';
import ContactField from '../../shared/form/ContactField';
import FileField from '../../shared/form/FileField';
import FormError from '../../shared/form/FormError';
import TextField from '../../shared/form/TextField';
import Portlet from '../../ui/Portlet';
import { ProfileTitle, FootBar, Grid } from '../../ui/Profile';
import { Error } from '../../ui/form/FormActions';
import PasswordRequirementList from '../../ui/login/PasswordRequirementList';

const validatePassword = (value, t) =>
    requirements(t).map(({ description, regex }) => ({
        description,
        isChecked: regex.test(value),
    }));

const ProfileForm = ({ onCancel, handleSubmit }) => {
    const { t } = useTranslation();
    const { newPassword = '' } = useSelector(getFormValues('profile')) || {};
    const passwordRequirements = useMemo(() => validatePassword(newPassword, t), [newPassword, t]);

    return (
        <form onSubmit={handleSubmit}>
            <ProfileTitle>Profile Details</ProfileTitle>
            <Portlet title="Main Details" closeable open>
                <Grid>
                    <div>
                        <TextField.PureGroup label="User Name" name="username" disabled />
                        <TextField.PureGroup label="Email" name="email" />
                        <TextField.PureGroup label="Last Modified" name="lastModified" disabled />
                    </div>
                    <div>
                        <TextField.PureGroup label="Name" name="name" />
                        <ContactField />
                    </div>
                    <FileField.Preview
                        accept=".jpg,.png,.jpeg"
                        label="Image"
                        name="image"
                        tip="Size 500x500px in jpg or png"
                    />
                </Grid>
            </Portlet>
            <Portlet title="Change Password" closeable open>
                <Grid>
                    <div>
                        <TextField.PureGroup label="Current Password" name="password" type="password" />
                        <TextField.PureGroup label="New Password" name="newPassword" type="password" />
                        <TextField.PureGroup label="Confirm New Password" name="confirmNewPassword" type="password" />
                    </div>
                    <PasswordRequirementList requirements={passwordRequirements} t={t} />
                </Grid>
            </Portlet>
            <FootBar>
                <FootBar.Action onClick={onCancel} type="button">
                    cancel
                </FootBar.Action>
                <FootBar.Action type="submit">save</FootBar.Action>
            </FootBar>
            <FormError form="profile">{error => <Error>{error}</Error>}</FormError>
        </form>
    );
};

ProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

const makePasswordValidation = validation =>
    yup.lazy((value, { parent }) => {
        const { password, newPassword, confirmNewPassword } = parent;
        const isMandatory = password || newPassword || confirmNewPassword;

        if (!isMandatory) {
            return yup.string();
        }

        return validation;
    });

const imageValidation = () =>
    yup.lazy(value => {
        if (value instanceof File) {
            return yup.mixed().test('fileSize', 'File size is large than 5MB', image => image?.size <= 5 * 1024 * 1024);
        }

        return yup.mixed().nullable();
    });

const schema = yup.object().shape({
    confirmNewPassword: makePasswordValidation(
        yup
            .string()
            .required('Comfirm new password is mandatory')
            .test('is-matching', 'Password not match', function testPasswordMatch(value) {
                return value === this.parent.newPassword;
            })
    ),
    email: yup.string().required('Email is mandatory').email('Please enter email in correct format'),
    image: imageValidation(),
    name: requiredString('Name is mandatory'),
    newPassword: makePasswordValidation(
        requiredString('New password is mandatory').matches(passwordRegex, 'Password should follow requirements side')
    ),
    password: makePasswordValidation(requiredString('Current password is mandatory')),
});

export default reduxForm({ form: 'profile', validate: createFormValidation(schema) })(ProfileForm);
