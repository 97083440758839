import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FinanceDataFragment } from '../../../../../components/data/useFinanceProducts.graphql';
import { PromoDataFragment } from '../../../../../components/data/useLoadPromo.graphql';
import { CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import { PromoCodeUnit } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { ConfigFormValues } from '../ConfigForm';
import useComputeConfiguratorPrice from './useComputeConfiguratorPrice';

const useCalculatorValues = (
    financeProduct: FinanceDataFragment,
    values: ConfigFormValues,
    promo?: PromoDataFragment
): Partial<CalculatorValues> => {
    // prepare ckd configuration
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const configuratorPrice = useComputeConfiguratorPrice(values);
    const { price: carPrice } = ckdConfiguration.vehicle;

    // calculate promo code value
    let promoValue = 0;
    if (promo && promo?.value) {
        // check promo value more than starting price
        const isExceededCarPrice = promo.value > carPrice;
        const availablePromoPrice = isExceededCarPrice ? carPrice : promo.value;

        const isPercentage = promo?.unit === PromoCodeUnit.PERCENTAGE;
        promoValue = isPercentage ? (carPrice * promo.value) / 100 : availablePromoPrice;
    }

    return useMemo(() => {
        // total price includes of base car price + car price
        const totalPrice = carPrice + configuratorPrice - promoValue;

        const {
            monthlyInstalment: defaultMonthlyInstallment,
            loan,
            tenure,
            interestRate,
        } = ckdConfiguration.calculator;
        const cappedLoan = loan > totalPrice ? totalPrice : loan;
        const cappedLoanPercentage = totalPrice ? (cappedLoan / totalPrice) * 100 : 0;

        // if total price 0 put monthly installment to 0
        const monthlyInstalments = [
            { amount: totalPrice === 0 ? 0 : (defaultMonthlyInstallment as number), start: 1, end: tenure },
        ];

        // to adjust later
        const query = {
            paymentMode: financeProduct.paymentMode.mode || undefined,
            price: totalPrice,
            variant: ckdConfiguration.vehicle.actualIdentifider,
            term: tenure,
            interestRate,
            downPayment: {
                percentage: 100 - cappedLoanPercentage,
                amount: totalPrice - cappedLoan,
            },
            loan: {
                percentage: cappedLoanPercentage,
                amount: cappedLoan,
            },
            balloon: {
                amount: 0,
                percentage: 0,
            },
        };

        return {
            monthlyInstalments,
            coe: 0,
            variant: ckdConfiguration.vehicle.actualIdentifider,
            carPrice: ckdConfiguration.vehicle.price,
            bank: financeProduct.bank.id,
            financeProduct: financeProduct.id,
            carOptions: [],
            tenure: query.term,
            interestRate: query.interestRate,
            totalPrice: query.price,
            paymentMode: query.paymentMode,
            downPayment: query.downPayment,
            loan: query.loan,
            balloon: query.balloon,
            promoCode: promo?.identifier,
        };
    }, [carPrice, promoValue, ckdConfiguration, financeProduct, promo, configuratorPrice]);
};

export default useCalculatorValues;
