import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    ${props =>
        props.theme.webFontUrl &&
        css`
            @font-face {
                font-family: 'Company Font';
                src: url(${props.theme.webFontUrl});

                // this make first text render a little faster
                // https://web.dev/font-best-practices/
                font-display: swap;
            }
        `}
    
    body {
        padding: 0;
        margin: 0;
        font-style: normal;
        font-weight: 100;
        color: ${props => props.theme.colors.defaultColor};
        background: ${props => props.theme.background};
    }
    

    body, input, textarea, button, p {
        font-family: 'Company Font', 'Roboto', sans-serif;
    }

    input, textarea {
        
        &::placeholder {
            color: #b8beca;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #b8beca;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #b8beca;
        }
    }

    html {
        font-size: 14px;
    }

    @media (max-width: 1199px) {
        html {
            font-size: 13px;
        }
    }

    @media (max-width: 767px) {
        html {
            font-size: 12px;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.4;
    }

    h1 {
        font-size: 1.5rem;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 0.5rem;
        font-weight: 700;
        line-height: 1.2;
    }
    
    .dp-close:hover,
    .dp-close:focus,
    .dp-clear:hover,
    .dp-clear:focus,
    .dp-today:hover,
    .dp-today:focus,
    .dp-next:hover,
    .dp-next:focus,
    .dp-prev:hover,
    .dp-prev:focus,
    .dp-cal-month:focus,
    .dp-cal-month:hover,
    .dp-cal-year:hover,
    .dp-cal-year:focus {
        background: #f0f0f0;
        color: black;
    }

    .dp-day:hover,
    .dp-month:hover,
    .dp-year:hover,
    .dp-current:focus,
    .dp-current,
    .dp-day:focus,
    .dp-month:focus,
    .dp-year:focus {
        outline: none;
        background: #f0f0f0;
        color: black;
    }

    .dp-day-today:after {
        content: '';
        height: 0;
        width: 0;
        border: 7px solid #ced4da;
        border-bottom-color: transparent;
        border-left-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
    }
    
    .dp-cal {
      min-height: auto;
    }

    .dp::before {
        background: #f5f5f5;
        height: 0;
    }

    .dp {
        padding-top: 0;
    }

    .dp-day.dp-day--disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .gallery-modal .thumbnail-button {
        border: 0;
    }
`;

export default GlobalStyle;
