import styled from 'styled-components';

export const OcrImagesContainer = styled.div`
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 140px;
    grid-column-gap: 10px;
`;

export const OcrImageBox = styled.div`
    position: relative;
    color: #999999;
    border: 1px solid #ced4da;
    line-height: 1;
    padding: 10px;

    input {
        display: none;
    }

    label {
        display: flex;
        margin: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 100%;

        svg {
            width: 25px;
            margin: 10px;
        }

        span {
            text-transform: ${props => props.theme.casing};
            text-align: center;
        }
    }

    button {
        position: absolute;
        left: 15px;
        top: 15px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        color: #999999;

        svg {
            width: 25px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
