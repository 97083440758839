import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from './actions';

const useLoading = (loading: boolean) => {
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

    useEffect(() => {
        let mounted = true;
        let resolvePromise: () => void;

        if (loading) {
            const promise = new Promise<void>(resolve => {
                resolvePromise = resolve;

                if (!mounted) {
                    resolve();
                }
            });

            dispatch(attachLoading(promise));
        }

        return () => {
            mounted = false;

            if (resolvePromise) {
                resolvePromise();
            }
        };
    }, [dispatch, loading]);
};

export default useLoading;
