import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Title from './Title';

type ErrorTitleProps = {
    children: string;
    className: string;
};

const ErrorTitle = ({ children, className }: ErrorTitleProps) => (
    <Title className={className}>
        <Icon icon={faExclamationTriangle} /> {children}
    </Title>
);

const StyledErrorTitle = styled(ErrorTitle)`
    svg {
        color: #ee5757;
    }
`;

export default StyledErrorTitle;
