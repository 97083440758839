import React, { useMemo, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { RoundingMode } from '../../schema';
import { getCurrentCountry } from '../../selectors';
import * as helpers from '../../utilities/numberHelper';
import { CountryScopeFragment } from '../data/useLoadScope.graphql';

export type Formats = {
    // provide precision
    percentagePrecision: number;
    currencyPrecision: number;
    // formats
    currencyFormat: string;
    // provide rounding function
    roundNumber: (value: any) => number;
    roundNumberDown: (value: any) => number;
    roundPercentage: (value: any) => number;
    roundPercentageDown: (value: any) => number;
    // provide formatting functions
    formatPercentage: (value: any) => string;
    formatCurrencyValues: (values: any, unit?: string, separator?: string) => string;
    formatCurrency: (value: any, unit?: string) => string;
    formatNumber: (value: any) => string;
    formatCurrencyOnly: (value: any, unit?: string) => string;
    formatCurrencyDown: (value: any, unit?: string) => string;
    parseNumber: (value: any) => number | null | undefined;
    // also provide the symbol
    currencySymbol: string;
    // provide formatting properties
    fixedDecimalScale: boolean;
    thousandSeparator: string;
};

export type WithFormatInjectedProps = {
    formats: Formats;
};

export const withCompanyFormatting = <Props extends WithFormatInjectedProps = WithFormatInjectedProps>(
    WrappedComponent: ComponentType<Props>
) => {
    const Component = (props: Omit<Props, keyof WithFormatInjectedProps>) => {
        const formats = useCompanyFormatting();

        return <WrappedComponent {...(props as Props)} formats={formats} />;
    };

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    Component.displayName = `withCompanyFormatting(${displayName})`;

    return Component;
};

const useCompanyFormatting = (): Formats => {
    const country = useSelector(getCurrentCountry);

    return useMemo(() => {
        const { rounding, currency: currencySymbol } = country || ({} as CountryScopeFragment);

        const { amount, percentage } = rounding;

        const { count: currencyPrecision } = amount;

        const { count: percentagePrecision } = percentage;

        // create our rounding function
        const roundPercentage = helpers.roundNumber(percentagePrecision);
        const roundPercentageDown = helpers.roundNumber(percentagePrecision, RoundingMode.ROUNDINGDOWN);
        const roundNumber = helpers.roundNumber(currencyPrecision);
        const roundNumberDown = helpers.roundNumber(currencyPrecision, RoundingMode.ROUNDINGDOWN);

        // then get format functions
        const formatCurrencyOnly = helpers.formatCurrencyOnly(currencySymbol);
        const formatCurrency = helpers.formatCurrency(currencyPrecision, currencySymbol);
        const formatCurrencyDown = helpers.formatCurrency(currencyPrecision, currencySymbol, RoundingMode.ROUNDINGDOWN);
        const formatPercentage = helpers.formatPercentage(percentagePrecision);

        const formatCurrencyValues = helpers.formatCurrencyValues(currencyPrecision, currencySymbol);

        const formatNumber = helpers.formatNumber(currencyPrecision);

        // we might want the formats too
        const currencyFormat = helpers.getCurrencyFormat(currencyPrecision);

        return {
            // provide precision
            percentagePrecision,
            currencyPrecision,
            // and formats
            currencyFormat,
            // providing rounding function
            roundNumber,
            roundNumberDown,
            roundPercentage,
            roundPercentageDown,
            // provide formatting functions
            formatPercentage,
            formatCurrency,
            formatNumber,
            formatCurrencyOnly,
            formatCurrencyDown,
            formatCurrencyValues,
            // provide parsing functions
            parseNumber: helpers.parseNumber,
            // also provide the symbol
            currencySymbol,
            // provide formatting properties
            fixedDecimalScale: true,
            thousandSeparator: ',',
        };
    }, [country]);
};

export default useCompanyFormatting;
