import React from 'react';
import { VariantDataFragment } from '../../../components/data/useLoadVariants.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../components/shared/calculator-next/types';
import { FlowStep } from '../../utils/flow';
import getDealerIdFromFlowState from '../../utils/getDealerIdFromFlowState';
import { InsuranceNewFlowState } from '../types';
import Calculator from './components/Calculator';

export type CalculatorStepValues = {
    calculator?: Partial<CalculatorInsuranceValues>;
};

class InsuranceCalculatorStep extends FlowStep<InsuranceNewFlowState, CalculatorStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'insuranceCalculator';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('newCalculatorPage.label.step');
    }

    public render(): React.ReactElement | null {
        const { channel, calculator } = this.state;
        const dealerId = getDealerIdFromFlowState(this.state);

        return (
            <>
                <HelmetTitle channel={channel} title="Insurance Calculator" />
                <Calculator dealerId={dealerId} initialValues={calculator} onSubmit={this.submit} />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute({ calculator }: CalculatorStepValues) {
        this.stateStore.update({
            calculator,
        });

        return this.nextStep;
    }
}

export default InsuranceCalculatorStep;
