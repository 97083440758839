export const PROFILE_SAVE_NOTIFICATION = {
    message: 'Your Profile Details has been updated.',
    title: `Profile Updated`,
};

export const APPLICATION_COMPLETE_NOTIFICATION = id => ({
    message: `The Application ${id} has been completed.`,
    title: 'Application Completed',
});

export const APPLICATION_CANCEL_NOTIFICATION = id => ({
    message: `The Application ${id} has been cancelled.`,
    title: 'Application Cancelled',
});

export const APPLICATION_APPROVE_NOTIFICATION = id => ({
    message: `The Application ${id} has been approved.`,
    title: 'Application Approved',
});

export const APPLICATION_DECLINE_NOTIFICATION = id => ({
    message: `The Application ${id} has been declined.`,
    title: 'Application Declined',
});

export const APPLICATION_CONCLUDE_AGREEMENT_NOTIFICATION = id => ({
    message: `The Application ${id} agreements has been concluded.`,
    title: 'Application Agreements Concluded',
});
