import { addDays } from 'date-fns';
import { TFunction } from 'i18next';
import { toNumber, filter, isEmpty, omit, getOr, get } from 'lodash/fp';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadIncomeTypes } from '../../../../../actions';
import DateField from '../../../../../components/shared/form-v2/DateField';
import FileFieldArray from '../../../../../components/shared/form-v2/FileFieldArray';
import NumberField from '../../../../../components/shared/form-v2/NumberField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useBankPresetLabel from '../../../../../components/utilities/useBankPresetLabel';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import validateFile from '../../../../../components/utilities/validateFile';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import { getIncomeTypes } from '../../../../../selectors';
import { yesNoOptions } from '../../../../../utilities/constants/options';
import { onNumKeyPress, yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';

const watchedFields = [
    'details.employment.employer',
    'details.employment.incomeType',
    'details.employment.monthlyIncome',
    'details.employment.dateOfJoining',
    'details.employment.totalWorkExperience',
    'details.employment.designation',
    'details.employment.salaryTransferredToBank',
    'details.employment.bankAccountNumber',
    'details.thirdParty.enbdBank.preferredFirstPaymentDate',
    'details.thirdParty.enbdBank.bankName',
];

type OccupationFormProps = {
    disabled?: boolean;
    bankPresetOption?: BankPresetOption;
};

const preferredFirstPaymentMaxDate = () => addDays(new Date(), 45);

const OccupationForm = ({ disabled = false, bankPresetOption }: OccupationFormProps) => {
    const { t } = useTranslation();
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const formValues = getValues();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadIncomeTypes());
    }, [dispatch]);

    const incomeTypes = useSelector(getIncomeTypes);

    const salaryTransferredToBank = getOr(
        undefined,
        `${sectionPrefix}.details.employment.salaryTransferredToBank.value`,
        formValues
    );

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);

    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    return (
        <>
            <Title>{t('kycPage.occupationDetailsTitle')}</Title>
            <SelectField.Outline
                disabled={disabled || fieldState.details.employment.incomeType}
                label={t('customerDetails.label.incomeType')}
                name="details.employment.incomeType.value"
                options={incomeTypes}
            />
            <NumberField
                disabled={disabled || fieldState.details.employment.monthlyIncome}
                label={t('customerDetails.label.monthlyIncome')}
                name="details.employment.monthlyIncome.value"
                type="currency"
            />
            <TextField
                disabled={disabled || fieldState.details.employment.employer}
                label={t('customerDetails.label.employer')}
                name="details.employment.employer.value"
            />
            <DateField
                disabled={disabled || fieldState.details.employment.dateOfJoining}
                label={t('customerDetails.label.dateOfJoining')}
                name="details.employment.dateOfJoining.value"
            />
            <TextField
                disabled={disabled || fieldState.details.employment.totalWorkExperience}
                label={t('customerDetails.label.totalWorkExperience')}
                name="details.employment.totalWorkExperience.value"
                normalize={toNumber}
                onKeyPress={onNumKeyPress}
            />
            <TextField
                disabled={disabled || fieldState.details.employment.designation}
                label={t('customerDetails.label.designation')}
                name="details.employment.designation.value"
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.employment.salaryTransferredToBank}
                label={t('customerDetails.label.salaryTransferredToBank')}
                name="details.employment.salaryTransferredToBank.value"
                options={yesNoOptions}
            />
            {isEnbdBankPreset && !salaryTransferredToBank && (
                <TextField
                    disabled={disabled || fieldState.details?.thirdParty?.enbdBank?.bankName}
                    label="Bank Name"
                    name="details.thirdParty.enbdBank.bankName.value"
                />
            )}
            <TextField
                disabled={disabled || fieldState.details.employment.bankAccountNumber}
                label={t('customerDetails.label.bankAccountNumber')}
                name="details.employment.bankAccountNumber.value"
                onKeyPress={onNumKeyPress}
            />
            {(!isEnbdBankPreset || (isEnbdBankPreset && !salaryTransferredToBank)) && (
                <FileFieldArray
                    details={`(${t('customerDetails.label.latestThreeMonthsPayslips')})`}
                    disabled={disabled}
                    forPurpose={UploadPurpose.CUSTOMER_ATTACHED}
                    label={t('customerDetails.label.uploadSupportingDocument')}
                    name="occupationFiles"
                    rerenderOnEveryChange
                />
            )}
            {isEnbdBankPreset && (
                <DateField
                    disabled={disabled || fieldState.details?.thirdParty?.enbdBank?.preferredFirstPaymentDate}
                    label={getBankPresetLabel('preferredFirstPaymentDate')}
                    maxDate={preferredFirstPaymentMaxDate()}
                    minDate={new Date()}
                    name="details.thirdParty.enbdBank.preferredFirstPaymentDate.value"
                />
            )}
        </>
    );
};

export default OccupationForm;

export const occupationEnbdSchema = (t: TFunction) => ({
    preferredFirstPaymentDate: yupExt.customerProperty().shape({
        value: yup.date().required(t('common.error.required')),
    }),
    // @ts-ignore
    bankName: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

        const salaryTransferredToBank = get(
            'context.values.customerInfo.details.employment.salaryTransferredToBank.value',
            options
        );

        if (!isEnbdBankPreset || (isEnbdBankPreset && !salaryTransferredToBank)) {
            return yupExt.customerProperty().shape({
                value: yup.string().nullable().required(t('common.error.required')),
            });
        }

        return yupExt.customerProperty().shape({
            value: yup.string().nullable(),
        });
    }),
});

// remove error message for required in events

export const occupationSchema = (t: TFunction) => ({
    employment: yupExt.customerProperty().shape({
        bankAccountNumber: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        }),
        dateOfJoining: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),
        designation: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        }),
        employer: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        }),
        monthlyIncome: yupExt.customerProperty().shape({
            value: yup
                .string()
                .nullable()
                .required(t('common.error.required'))
                .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        }),
        salaryTransferredToBank: yupExt.customerProperty().shape({
            value: yup.boolean().required(t('common.error.required')),
        }),
        totalWorkExperience: yupExt.customerProperty().shape({
            value: yup
                .number()
                .required(t('common.error.required'))
                .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        }),
    }),
});

export const occupationFilesSchema = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((value: any, { parent, context }: any) => {
        const isEnbdBankPreset =
            get(['application', 'financeProduct', 'bank', 'presetOption'], context) === BankPresetOption.ENBDBANK;

        const salaryTransferredToBank = get('details.employment.salaryTransferredToBank.value', parent);

        const validator = yup
            .array()
            .transform((currentValue: any) =>
                filter(item => {
                    if (!item) {
                        return false;
                    }

                    if (
                        item.purpose !== UploadPurpose.CUSTOMER_ATTACHED &&
                        item.purpose !== UploadPurpose.GUARANTOR_ATTACHED
                    ) {
                        // wrong purpose, skip it
                        return false;
                    }

                    // ensure it's not an empty item
                    return item instanceof File || !isEmpty(omit(['purpose'], item));
                }, currentValue)
            )
            .of(
                yup.mixed().test(
                    'extension-validation',
                    t('common.error.fileExtension'),
                    // @ts-ignore
                    validateFile
                )
            );

        if (!isEnbdBankPreset || (isEnbdBankPreset && !salaryTransferredToBank)) {
            return validator.min(1, t('common.error.filesRequired'));
        }

        return validator;
    });
