// @ts-ignore
import { Modal } from '@appvantageasia/afc-ui';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { get, identity, omit, getOr, set } from 'lodash/fp';
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ApplicationCustomerDataFragment } from '../../../../../components/routes/ApplicationRoute/data.graphql';
import * as SearchCustomerUI from '../../../../../components/ui/calculator/SearchCustomer';
import { CustomerDetailsSource } from '../../../../../schema';
import { getNationalities } from '../../../../../selectors';
import { SearchingStateCore } from './index';
import { ReactComponent as RadioChecked } from '../../../../../assets/images/radio_checked.svg';
import { ReactComponent as RadioUnChecked } from '../../../../../assets/images/radio_unchecked.svg';

const { PreInfo, List, Error, NricItem, Label, Value, Grid } = SearchCustomerUI;

type Nationalities = { value: string; label: string }[];

type Field = {
    path: string;
    existingLabel: string;
    newLabel: string;
    renderValue?: (value: any) => string;
};

type Fields = { [key: string]: Field };

const getFields = (nationalities: Nationalities, t: TFunction): [string, Field][] => {
    const omittedFields = ['dateOfBirth', 'nationality'];

    const fields: Fields = {
        name: {
            path: 'name',
            existingLabel: t('draftPage.nricModal.label.existingName'),
            newLabel: t('draftPage.nricModal.label.newName'),
        },
        email: {
            path: 'email',
            existingLabel: t('draftPage.nricModal.label.existingEmail'),
            newLabel: t('draftPage.nricModal.label.newEmail'),
        },
        phone: {
            path: 'phone',
            existingLabel: t('draftPage.nricModal.label.existingMobile'),
            newLabel: t('draftPage.nricModal.label.newMobile'),
        },
        dateOfBirth: {
            path: 'dateOfBirth',
            existingLabel: t('draftPage.nricModal.label.existingDateOfBirth'),
            newLabel: t('draftPage.nricModal.label.newDateOfBirth'),
            renderValue: (value: Date | string) => value && format(new Date(value), t('dateFormats.dateFormat')),
        },
        nationality: {
            path: 'details.nationality',
            existingLabel: t('draftPage.nricModal.label.existingNationality'),
            newLabel: t('draftPage.nricModal.label.newNationality'),
            renderValue: (value: string) =>
                getOr(
                    '',
                    'label',
                    nationalities.find(item => item.value === value)
                ),
        },
    };

    return Object.entries(omit(omittedFields, fields));
};

export type NricCompareModalProps = SearchingStateCore & {
    info: ApplicationCustomerDataFragment;
};

const NricCompareModal = ({ info, onComplete, reset, values: initialValues }: NricCompareModalProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const nric = info.identityNumber?.value;

    // get nationalities
    const nationalities = useSelector(getNationalities);

    // get fields
    const fields = useMemo(() => getFields(nationalities, t), [nationalities, t]);

    // create selection state
    const [selectedCustomer, setSelectedCustomer] = useState<{ [key: string]: 'old' | 'new' }>(() =>
        fields.reduce((acc, [field, { path }]) => {
            const previousState = get([path, 'value'], info);
            const currentState = get([path, 'value'], initialValues);

            if (previousState?.source === CustomerDetailsSource.MYINFO) {
                return { ...acc, [field]: 'old' };
            }

            if (currentState?.source === CustomerDetailsSource.MYINFO) {
                return { ...acc, [field]: 'new' };
            }

            return acc;
        }, {})
    );

    // state for errors
    const [error, setError] = useState<string | null>(null);

    // create a callback to handle selection
    const selectCustomer = useCallback(
        (key: string, from: 'new' | 'old') => {
            // reset error to null
            setError(null);
            // update the selections
            setSelectedCustomer(state => ({ ...state, [key]: from }));
        },
        [setSelectedCustomer, setError]
    );

    const onConfirm = useCallback(() => {
        // check if the user selected one entry for each field
        if (Object.keys(selectedCustomer).length === fields.length) {
            // apply changes
            const customerData = fields.reduce((acc, [key, { path }]) => {
                // get the current value
                const from = selectedCustomer[key];

                if (from === 'new') {
                    return set(path, get(path, initialValues), acc);
                }

                return acc;
            }, info);

            return onComplete(customerData);
        }

        // look for the missing fields
        const noValueLabel = fields.reduce((acc, [key]) => {
            if (!selectedCustomer[key]) {
                if (key === 'dateOfBirth') {
                    return `${acc}date of birth/`;
                }

                return `${acc}${key}/`;
            }

            return acc;
        }, '');

        // update the error state
        setError(`Please select ${noValueLabel.slice(0, -1)}.`);

        return false;
    }, [selectedCustomer, fields, info, onComplete, initialValues]);

    const renderItem = (
        key: string,
        type: 'new' | 'old',
        value: any,
        label: string,
        renderValue: (value: any) => string,
        disabled: boolean
    ) => {
        const currentSelection = get(key, selectedCustomer);
        const isChecked = currentSelection === type;

        return (
            <NricItem onClick={() => selectCustomer(key, type)}>
                {isChecked ? (
                    // @ts-ignore
                    <RadioChecked disabled={disabled} fill={theme.themeHighlightColour} />
                ) : (
                    // @ts-ignore
                    <RadioUnChecked disabled={disabled} />
                )}
                <div>
                    <Label>{label}</Label>
                    <Value>{renderValue(value)}</Value>
                </div>
            </NricItem>
        );
    };

    const renderField = ([key, field]: [string, Field]) => {
        const { existingLabel, newLabel, path, renderValue = identity } = field;
        const previousState = get(path, info);
        const currentState = get(path, initialValues);

        const disabled =
            previousState?.source === CustomerDetailsSource.MYINFO ||
            currentState?.source === CustomerDetailsSource.MYINFO;

        return (
            <Grid key={key}>
                <div>{renderItem(key, 'old', previousState?.value, existingLabel, renderValue, disabled)}</div>
                <div>{renderItem(key, 'new', currentState?.value, newLabel, renderValue, disabled)}</div>
            </Grid>
        );
    };

    return (
        <Modal
            onClose={reset}
            onConfirm={onConfirm}
            title={t('draftPage.nricModal.title')}
            showClose
            showConfirm
            showTitle
        >
            <PreInfo>
                <Trans components={{ s: <span /> }} i18nKey="draftPage.nricModal.label.matched" values={{ nric }} />
            </PreInfo>
            <List>{fields.map(renderField)}</List>
            <Error>{error}</Error>
        </Modal>
    );
};

export default NricCompareModal;
