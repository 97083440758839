import React from 'react';
import InsuranceNamirial from '../../DraftFlow/components/InsuranceNamirial';
import { InsuranceNamirialStep } from '../../DraftFlow/steps';
import { FlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';

class EventInsuranceNamirialStep extends FlowStep<EventFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'insuranceNamirial';
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        return !!application?.insuranceApplication?.steps?.approval;
    }

    public render(): React.ReactElement | null {
        const { token, source } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        return <InsuranceNamirial source={source} token={token} />;
    }
}

export default EventInsuranceNamirialStep;
