import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollTop = () => {
    const location = useLocation();

    // scroll to top whenever location changes
    // regardless of path
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
};

export default useScrollTop;
