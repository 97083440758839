import { useQuery } from '@apollo/client';
import {
    CalculatorContext,
    CalculatorFields,
    GridCalculator,
    GridCalculatorProps,
    useCalculatorContext,
    defaultComputingFields,
    defaultPreset,
} from '@appvantageasia/afc-calculator-ui-next';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import useMedia from 'use-media';
import { useCountry, useCompany } from '../../../hookSelectors';
import { useContentTranslation } from '../../../i18n';
import { Channel } from '../../../schema';
import {
    getHasValidPromoCodesByCountryId,
    GetHasValidPromoCodesByCountryIdQuery,
    GetHasValidPromoCodesByCountryIdQueryVariables,
} from '../../data/useLoadPromo.graphql';
import useCompanyFormatting, { Formats } from '../../utilities/useCompanyFormatting';
import { CalculatorMeta, CalculatorValues } from './types';

export type CompanyCalculatorProps = {
    onChange?: (context: CalculatorContext<CalculatorValues>) => void;
    initialValues: Partial<CalculatorValues>;
    fields?: CalculatorFields<CalculatorValues>;
    meta: Partial<CalculatorMeta>;
    children?: ReactNode;
    channel: Channel;
    preset?: JSX.Element;
} & Omit<GridCalculatorProps<CalculatorValues>, 'context' | keyof Formats | 't' | 'ct' | 'language' | 'children'>;

export const useCompanyCalculatorTheme = (channel: Channel) => {
    // build the theme for the UI
    const defaultTheme = useTheme();
    const singleColumn = useMedia({ maxWidth: 320 });

    const newTheme = useMemo(
        () => ({
            ...defaultTheme,
            calculator: {
                ...defaultTheme.calculator,
                singleColumn,
            },
        }),
        [defaultTheme, singleColumn]
    );

    const usedTheme = useMemo(
        () => ({
            ...newTheme,
            calculator: {
                ...newTheme.calculator,
                titleTransform: 'none',
                labelTransform: 'none',
                titleSize: '11px',
                labelSize: '14px',
                boxSize: '50px',
            },
        }),
        [newTheme]
    );

    return channel === Channel.EVENT || channel === Channel.USED ? usedTheme : newTheme;
};

const CompanyCalculator = ({
    onChange,
    initialValues,
    fields = defaultComputingFields,
    meta,
    children,
    channel,
    preset = defaultPreset,
    ...props
}: CompanyCalculatorProps) => {
    const { id: countryId, code: countryCode } = useCountry();
    const { code: companyCode } = useCompany();

    const { data: hasValidPromo } = useQuery<
        GetHasValidPromoCodesByCountryIdQuery,
        GetHasValidPromoCodesByCountryIdQueryVariables
    >(getHasValidPromoCodesByCountryId, {
        variables: {
            countryId,
        },
        fetchPolicy: 'cache-first',
    });
    const hasValidPromoCodes = hasValidPromo?.hasValidPromoCodes;

    const enhancedMeta = useMemo(() => ({ ...meta, hasValidPromoCodes, companyCode, countryCode }), [
        companyCode,
        countryCode,
        hasValidPromoCodes,
        meta,
    ]);

    const context = useCalculatorContext<CalculatorValues>(initialValues, fields, enhancedMeta);

    const { t } = useTranslation();
    const { ct, language } = useContentTranslation();

    // get formatting
    const formats = useCompanyFormatting();
    const theme = useCompanyCalculatorTheme(channel);

    useEffect(() => {
        if (onChange) {
            onChange(context);
        }
    }, [context, onChange]);

    return (
        <GridCalculator context={context} theme={theme} {...formats} {...props} ct={ct} language={language} t={t}>
            {preset}
            {children}
        </GridCalculator>
    );
};

export default CompanyCalculator;
