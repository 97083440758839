import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';
import { loadDrivingLicenseClasses, loadDrivingLicenseValidityCodes } from '../../../actions';
import { useCountry } from '../../../hookSelectors';
import { CustomerDetailsSource, LicenseType } from '../../../schema';
import { getDrivingLicenseClasses, getDrivingLicenseValidityCodes } from '../../../selectors';
import { getLicenseTypeOptions } from '../../../utilities/constants/options';
import { Action } from '../../ui/form/FileFieldContainer';
import DateField from './DateField';
import SelectField from './SelectField';

const getFieldLabel = (isFromMyInfo, label) => (isFromMyInfo ? `${label} (MYINFO)` : label);

const LicenseClass = ({ licenseType, drivingLicenseClasses, fromMyinfo = false, field }) => {
    const { t } = useTranslation();
    const isQualified = licenseType === LicenseType.QUALIFIED;

    return (
        <Fragment key={field}>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <SelectField.Outline
                    label={getFieldLabel(fromMyinfo, t('customerDetails.label.licenseClass'))}
                    name={`${field}.licenseClass`}
                    options={drivingLicenseClasses}
                    disabled
                />
            </div>
            {isQualified && (
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        label={getFieldLabel(fromMyinfo, t('customerDetails.label.licenseIssueDate'))}
                        name={`${field}.issueDate`}
                        disabled
                    />
                </div>
            )}
        </Fragment>
    );
};

LicenseClass.propTypes = {
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    field: PropTypes.string.isRequired,
    fromMyinfo: PropTypes.bool,
    licenseType: PropTypes.string.isRequired,
};

const LicenseClasses = ({ fields, ...props }) => fields.map(field => <LicenseClass field={field} {...props} />);

LicenseClasses.propTypes = {
    disabled: PropTypes.bool,
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
    fromMyinfo: PropTypes.bool,
    licenseType: PropTypes.string.isRequired,
};

const LicenseItem = ({ name, value, drivingLicenseValidityCodes, drivingLicenseClasses }) => {
    // modify driving license options
    const drivingLicenseClassesOptions = useMemo(
        () => drivingLicenseClasses.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseClasses, value.source]
    );
    const drivingLicenseValidityCodesOptions = useMemo(
        () => drivingLicenseValidityCodes.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseValidityCodes, value.source]
    );

    const { t } = useTranslation();
    // get the license type
    const licenseType = get('type', value);

    // we may have some extra fields depending the type
    const hasExtraFields = [LicenseType.QUALIFIED, LicenseType.PROVISIONAL].includes(licenseType);
    const fromMyinfo = value.source === CustomerDetailsSource.MYINFO;

    const { code } = useCountry();

    return (
        <>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <SelectField.Outline
                    label={getFieldLabel(fromMyinfo, t('customerDetails.label.licenseType'))}
                    name={`${name}.type`}
                    options={getLicenseTypeOptions(t)}
                    sort={false}
                    disabled
                    isFullWidth
                />
            </div>
            {hasExtraFields && (
                <>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getFieldLabel(fromMyinfo, t('customerDetails.label.licenseValidity'))}
                            name={`${name}.validity`}
                            options={drivingLicenseValidityCodesOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={getFieldLabel(
                                fromMyinfo && value.expiryDate,
                                t('customerDetails.label.licenseExpiryDate')
                            )}
                            name={`${name}.expiryDate`}
                            disabled
                        />
                    </div>
                    {code !== 'TH' && (
                        <FieldArray
                            component={LicenseClasses}
                            drivingLicenseClasses={drivingLicenseClassesOptions}
                            fromMyinfo={fromMyinfo}
                            licenseType={licenseType}
                            name={`${name}.classes`}
                            disabled
                        />
                    )}
                </>
            )}
        </>
    );
};

LicenseItem.propTypes = {
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    drivingLicenseValidityCodes: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        expiryDate: PropTypes.instanceOf(Date),
        source: PropTypes.string,
    }).isRequired,
};

const LicenseArray = ({ fields, disabled }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDrivingLicenseClasses());
        dispatch(loadDrivingLicenseValidityCodes());
    }, [dispatch]);

    const drivingLicenseClasses = useSelector(getDrivingLicenseClasses);
    const drivingLicenseValidityCodes = useSelector(getDrivingLicenseValidityCodes);

    const items = fields.map((field, index) => (
        <LicenseItem
            key={field}
            disabled={disabled}
            drivingLicenseClasses={drivingLicenseClasses}
            drivingLicenseValidityCodes={drivingLicenseValidityCodes}
            index={index}
            name={field}
            value={fields.get(index)}
        />
    ));

    const addLicense = useCallback(() => {
        fields.push({
            classes: [{}],
            source: CustomerDetailsSource.MANUAL,
        });
    }, [fields]);

    const hideButton = disabled || items.length > 0;

    return (
        <>
            {items}
            {!hideButton && (
                <>
                    <div />
                    <div>
                        <Action onClick={addLicense}>Add a license</Action>
                    </div>
                </>
            )}
        </>
    );
};

LicenseArray.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

const DrivingLicense = props => (
    <FieldArray component={LicenseArray} name="details.drivingLicense" rerenderOnEveryChange {...props} />
);

export default DrivingLicense;
