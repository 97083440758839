// @ts-ignore
import { Footer } from '@appvantageasia/afc-ui';
import { isEmpty } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { setNotification } from '../../../actions';
import { useCountry } from '../../../hookSelectors';
import { ApplicationStatus, BankSigningMode, Channel } from '../../../schema';
import { BankDataFragment } from '../../data/useLoadBank.graphql';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import { CalculatorValues } from '../../shared/calculator-next/types';
import CheckboxField from '../../shared/form-v2/CheckboxField';
import { Container } from '../../ui/calculator';
import ActivityLog from '../ApplicationRoute/ActivityLog';
import Document from '../ApplicationRoute/Document';
import ModalProvider, { GlobalErrorProvider } from '../ApplicationRoute/Document/ModalProvider';
import useFormChanged from '../ApplicationRoute/actions/useFormChanged';
import Application from './Application';
import Actions from './actions';
import { ApplicationData } from './index';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

export type ApplicationFormProps = {
    allowPrimaryInfoChanges?: boolean;
    application: ApplicationData;
    countryCode: string;
    disabled?: boolean;
    validation: {
        phonePattern: RegExp | null;
    };
    useCustomerNationality: boolean;
    useCustomerBirthDay: boolean;
    onCancel: () => void;
};

export type ApplicationFormValues = Pick<
    ApplicationData,
    | 'assigneeId'
    | 'customer'
    | 'financeProduct'
    | 'remark'
    | 'proceedWithCustomerDevice'
    | 'promoCode'
    | 'attachments'
    | 'options'
    | 'hasTradeIn'
    | 'hasTestDrive'
    | 'tradeIn'
    | 'reservationDeposit'
    | 'payment'
    | 'finderVehicle'
    | 'guarantor'
> & {
    variant: VariantDataFragment;
    calculator: Partial<CalculatorValues>;
    bank?: BankDataFragment;
    // temporary variant field for express
    expressVariant?: VariantDataFragment;
    assetCondition?: string;
    finderVehicleCondition?: string;
};

export type ApplicationFormSubmit = FormSubmitHandler<ApplicationFormValues, ApplicationFormProps>;

export const useProceedWithCustomerDevice = (application: ApplicationData, hasChanges: boolean) => {
    const { channelSetting } = useCountry();

    return useMemo(() => {
        // first check the system allows it
        const allowProceedWithCustomerDevice =
            (application.channel === Channel.USED && channelSetting.used.allowProceedWithCustomerDevice) ||
            (application.channel === Channel.NEW && channelSetting.new.allowProceedWithCustomerDevice) ||
            (application.channel === Channel.EXPRESS && channelSetting.express.allowProceedWithCustomerDevice);

        if (!allowProceedWithCustomerDevice) {
            return false;
        }

        if (!application.steps?.submission) {
            // the application is not yet submitted so we can still ask for the customer to approve
            return true;
        }

        if (application.financeProduct?.bank.signing.onUpdate === BankSigningMode.NONE) {
            // we only need to check if there is signing on resubmission
            return false;
        }

        switch (application.status) {
            case ApplicationStatus.RECEIVED:
                // still being processed by the system
                return false;

            case ApplicationStatus.SIGNING:
            case ApplicationStatus.SIGNINGTIMEOUT:
                // application failed to submit or signing is not complete ye
                return true;

            default:
                // only on changes
                return hasChanges;
        }
    }, [channelSetting, application, hasChanges]);
};

const ApplicationForm = ({
    allowPrimaryInfoChanges = false,
    application,
    disabled = false,
    error,
    submitting,
}: ApplicationFormProps & InjectedFormProps<ApplicationFormValues, ApplicationFormProps>) => {
    const { t } = useTranslation();
    const history = useHistory();
    const onCancel = useCallback(() => history.goBack(), [history]);
    const dispatch = useDispatch();

    const hasChanges = useFormChanged();
    const showProceedWithCustomerDevice = useProceedWithCustomerDevice(application, hasChanges);

    useEffect(() => {
        if (!isEmpty(error) && !submitting) {
            dispatch(setNotification('Error', error));
        }
    }, [submitting, error, dispatch]);

    return (
        <>
            <GlobalErrorProvider>
                <ModalProvider>
                    <Tabs>
                        <TabList>
                            <Tab>{t('applicationDetailsPage.tab.application')}</Tab>
                            <Tab>{t('applicationDetailsPage.tab.document')}</Tab>
                            <Tab>{t('applicationDetailsPage.tab.activityLog')}</Tab>
                        </TabList>
                        <TabPanel>
                            <Application
                                allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                                application={application}
                                disabled={disabled}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Document application={application} />
                        </TabPanel>
                        <TabPanel>
                            <ActivityLog application={application} />
                        </TabPanel>
                    </Tabs>
                </ModalProvider>
            </GlobalErrorProvider>
            <FootContainer className="appActionBtn">
                <FootBar>
                    {!disabled && showProceedWithCustomerDevice && (
                        <FootBarRow>
                            <div style={{ flex: 1 }} />
                            <Container>
                                <CheckboxField
                                    label={t('applicationDetailsPage.label.proceedWithCustomerDevice')}
                                    name="proceedWithCustomerDevice"
                                />
                            </Container>
                        </FootBarRow>
                    )}
                    <FootBarRow>
                        {onCancel && (
                            <FootBarButton onClick={onCancel}>
                                {t('applicationDetailsPage.button.cancel')}
                            </FootBarButton>
                        )}
                        <div style={{ flex: 1 }} />
                        <Actions allowPrimaryInfoChanges={allowPrimaryInfoChanges} application={application} />
                    </FootBarRow>
                </FootBar>
            </FootContainer>
        </>
    );
};

export default reduxForm<ApplicationFormValues, ApplicationFormProps>({ form: 'application' })(ApplicationForm);
