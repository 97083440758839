import { get } from 'lodash/fp';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../../selectors';
import { ConfigFormValues } from '../ConfigForm';

const useComputeConfiguratorPrice = (values?: ConfigFormValues): number => {
    // prepare ckd configuration
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const tequipment = values?.tequipment;

    // get related data from json config file
    const { price: packagePrice } = ckdConfiguration.enhancedPackage;
    const { options: tequipmentOptions } = ckdConfiguration.tequipment;
    const { options: deliveryOptions } = ckdConfiguration.delivery;

    // calculate the tequipment materials
    const tequipmentPrice = useMemo(() => {
        const initialValue = 0;
        if (tequipment) {
            return Object.entries(tequipment).reduce((previousValue, currentValue) => {
                return currentValue[1]
                    ? get(`${currentValue[0]}.price`, tequipmentOptions) + previousValue
                    : previousValue;
            }, initialValue);
        }

        return 0;
    }, [tequipment, tequipmentOptions]);

    // calculate the delivery price
    const deliveryPrice: number = useMemo(() => {
        return deliveryOptions.find((item: { name: string }) => item.name === values?.delivery)?.price || 0;
    }, [deliveryOptions, values]);

    return useMemo(() => (values?.enhancedPackage ? packagePrice : 0) + tequipmentPrice + deliveryPrice, [
        deliveryPrice,
        packagePrice,
        tequipmentPrice,
        values,
    ]);
};

export default useComputeConfiguratorPrice;
