import { Paging, TableList } from '@appvantageasia/afc-ui';
import { flow, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMedia from 'use-media';
import { useContentTranslation } from '../../i18n';
import { ApplicationPhase } from '../../schema';
import breakpoints from '../../utilities/constants/breakpoints';
import useFormatDate from '../shared/useFormatDate';
import useFormatDateTime from '../shared/useFormatDateTime';
import Status from '../ui/ApplicationStatus';
import { renderChannelOption, renderCurrencyDown } from '../ui/Cells';
import { Content } from '../ui/PageContainer';
import useCompanyFormatting from '../utilities/useCompanyFormatting';

// eslint-disable-next-line react/prop-types
const renderStatus = () => ({ statusText: { label, color } }) => <Status color={color}>{label}</Status>;

const useColumns = (hasBank, hasFinanceProduct, hasLoan, applicationPhase) => {
    // get formatting
    const { t } = useTranslation();
    const { formatCurrencyDown } = useCompanyFormatting();
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const columns = [
            {
                key: 'applicationDate',
                sortKey: 'version.createdAt',
                label:
                    applicationPhase === ApplicationPhase.FINANCE
                        ? t('applicationsListPage.list.columns.applicationDate')
                        : t('applicationsListPage.list.columns.reservationDate'),
                getValue: flow([get('version.createdAt'), formatDateTime]),
            },
            {
                key: 'appId',
                sortKey: 'identifier',
                label: t('applicationsListPage.list.columns.appID'),
                getValue: get('identifier'),
            },
            {
                key: 'salesPerson',
                sortKey: 'assignee.name',
                label: t('applicationsListPage.list.columns.assignedTo'),
                getValue: get('assignee.name'),
            },
            {
                key: 'customer',
                sortKey: 'customer.name.value',
                label: t('applicationsListPage.list.columns.customer'),
                getValue: get('customer.name.value'),
            },
            {
                key: 'variant',
                sortKey: formatPath('variant.name'),
                label: t('applicationsListPage.list.columns.variant'),
                getValue: get(formatPath('variant.name')),
            },
            applicationPhase === ApplicationPhase.FINANCE &&
                hasBank && {
                    key: 'bank',
                    sortKey: formatPath('bank.name'),
                    label: t('applicationsListPage.list.columns.bank'),
                    getValue: get(formatPath('bank.name')),
                },
            applicationPhase === ApplicationPhase.FINANCE &&
                hasFinanceProduct && {
                    key: 'financeProduct',
                    sortKey: formatPath('financeProduct.name'),
                    label: t('applicationsListPage.list.columns.financialProduct'),
                    getValue: get(formatPath('financeProduct.name')),
                },
            applicationPhase === ApplicationPhase.FINANCE &&
                hasLoan && {
                    key: 'loan',
                    sortKey: 'calculator.loan.amount',
                    label: t('applicationsListPage.list.columns.loan'),
                    getValue: renderCurrencyDown('calculator.loan.amount', formatCurrencyDown),
                },
            {
                key: 'status',
                sortKey: 'status',
                label: t('applicationsListPage.list.columns.status'),
                getValue: renderStatus(),
            },
            {
                key: 'transactionID',
                sortKey: 'transactionID',
                label: 'Transaction ID',
                getValue: get('transactionID'),
            },
            {
                key: 'lastActivity',
                sortKey: 'version.updatedAt',
                label: t('applicationsListPage.list.columns.lastActivity'),
                getValue: flow([get('version.updatedAt'), formatDateTime]),
            },
            {
                key: 'Channel',
                sortKey: 'channel',
                label: t('applicationsListPage.list.columns.channel'),
                getValue: value => renderChannelOption(value, t),
            },
        ];

        return columns.filter(Boolean);
    }, [formatCurrencyDown, formatDateTime, formatPath, hasBank, hasFinanceProduct, hasLoan, t, applicationPhase]);
};

const useMobileColumns = () => {
    // get formatting
    const { t } = useTranslation();
    const formatDate = useFormatDate();

    return useMemo(() => {
        const columns = [
            {
                key: 'applicationDate',
                sortKey: 'version.createdAt',
                label: t('applicationsListPage.list.columns.applicationDate'),
                getValue: flow([get('version.createdAt'), formatDate]),
            },
            {
                key: 'appId',
                sortKey: 'identifier',
                label: t('applicationsListPage.list.columns.appID'),
                getValue: get('identifier'),
            },
            {
                key: 'customer',
                sortKey: 'customer.name.value',
                label: t('applicationsListPage.list.columns.customer'),
                getValue: get('customer.name.value'),
            },
            {
                key: 'status',
                sortKey: 'status',
                label: t('applicationsListPage.list.columns.status'),
                getValue: renderStatus(),
            },
        ];

        return columns.filter(Boolean);
    }, [formatDate, t]);
};

const ApplicationList = ({ items, onItemClick, sorting, paging, applicationPhase }) => {
    const isMobile = useMedia({ maxWidth: breakpoints.md });
    // check bank and FinanceProduct exist inside application
    const { hasBank, hasFinanceProduct, hasLoan } = useMemo(
        () => ({
            hasFinanceProduct: items.some(application => application.financeProduct),
            hasBank: items.some(application => application.bank.name),
            hasLoan: items.some(application => application.calculator.loan),
        }),
        [items]
    );
    const mobileColumns = useMobileColumns(hasBank, hasFinanceProduct, hasLoan);
    const columns = useColumns(hasBank, hasFinanceProduct, hasLoan, applicationPhase);

    return (
        <>
            <Content>
                {isMobile ? (
                    <TableList columns={mobileColumns} items={items} onItemClick={onItemClick} sorting={sorting} />
                ) : (
                    <TableList columns={columns} items={items} onItemClick={onItemClick} sorting={sorting} />
                )}
            </Content>
            <Paging {...paging} />
        </>
    );
};

ApplicationList.propTypes = {
    applicationPhase: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    onItemClick: PropTypes.func,
    paging: PropTypes.shape({}),
    sorting: PropTypes.shape({}),
};

export default ApplicationList;
