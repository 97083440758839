import { getOr } from 'lodash/fp';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import useOptions from '../../utilities/constants/useOptions';

const useCustomerSourceOptions = () => {
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const customer = getOr(formValues, `${sectionPrefix}`, formValues);
    const { t } = useTranslation();

    const { genders, residentialStatuses } = useOptions();

    return useMemo(
        () => ({
            genders: (genders || [])
                .filter(({ purpose }) => !purpose || purpose.includes(customer?.details?.gender?.source))
                .map(({ label, ...values }) => ({ ...values, label: t(label) })),
            residentialStatuses: (residentialStatuses || [])
                .filter(({ purpose }) => !purpose || purpose.includes(customer?.details?.residentialStatus?.source))
                .map(({ label, ...values }) => ({ ...values, label: t(label) })),
        }),
        [customer, genders, residentialStatuses, t]
    );
};

export default useCustomerSourceOptions;
