import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
    setAuthorization,
    setCompanyCode,
    setCountryCode,
    setZoneCode,
    setUser as setUserAction,
    setDealerId,
} from '../../../actions';
import { LoginType } from '../../../schema';
import { handleResponseError } from '../../../utilities/forms';
import { getCompanyRootUrl, getLocationCode } from '../../../utilities/urls';
import useValidation from '../../utilities/useValidation';
import SelectionForm, { schema as selectionFormSchema } from './SelectionForm';
import { authenticate } from './api.graphql';
import getUserWithPermissions from './getUserWithPermissions';
import { getRootUrl } from './shared';

const SelectionStep = ({ companies, username, password }) => {
    const client = useApolloClient();
    const validate = useValidation(selectionFormSchema);

    // we need to be able to dispatch redux action
    const dispatch = useDispatch();

    // also requires the history object
    const history = useHistory();

    // the callback to execute on submissions
    const onSubmit = useCallback(
        ({ companyId, countryId, zoneId, dealerId }) => {
            const variables = { username, password, countryId, zoneId, dealerId, loginType: LoginType.CI };

            return client
                .mutate({ mutation: authenticate, variables })
                .then(({ data: { authorization } }) => ({
                    ...authorization,
                    // append ids
                    companyId,
                    countryId,
                    zoneId,
                    dealerId,
                }))
                .catch(handleResponseError);
        },
        [client, username, password]
    );

    // the callback to execute on successful submissions
    const onSubmitSuccess = useCallback(
        async authorization => {
            const { token, user, companyId, countryId, zoneId, dealerId } = authorization;
            const modifiedUser = getUserWithPermissions(user);
            // persist authorization tokens
            dispatch(setAuthorization(token));
            // persist user data retrieved earlier
            dispatch(setUserAction(modifiedUser));

            // get entities
            const company = companies.find(i => i.id === companyId);
            const country = company.countries.find(i => i.id === countryId);
            const zone = country.zones.find(i => i.id === zoneId);
            const dealer = country.dealers.find(i => i.id === dealerId);

            // update codes
            dispatch(setCompanyCode(company.code));
            dispatch(setCountryCode(country.code));
            dispatch(setZoneCode(zone.code));
            dispatch(setDealerId(dealer.id));

            // then finally redirect
            const locationCode = getLocationCode(country.code, zone.code);

            const url = getRootUrl(modifiedUser, company.code, country.code);

            if (url) {
                history.push(url(company.code, locationCode));
            } else {
                history.push(getCompanyRootUrl(company.code, locationCode));
            }
        },
        [dispatch, history, companies]
    );

    return (
        <SelectionForm
            companies={companies}
            initialValues={{}}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            validate={validate}
        />
    );
};

SelectionStep.propTypes = {
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            countries: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    zones: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.string.isRequired,
                            name: PropTypes.shape({}).isRequired,
                        })
                    ),
                })
            ),
            id: PropTypes.string.isRequired,
            name: PropTypes.shape({}).isRequired,
        })
    ),
    password: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
};

export default SelectionStep;
