import { FlowStep } from '../../utils/flow';
import { InsuranceNewFlowState } from '../types';

class SubmitStep extends FlowStep<InsuranceNewFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'submit';
    }

    // eslint-disable-next-line class-methods-use-this
    public get isShadowStep(): boolean {
        return true;
    }

    public async executeBefore(): Promise<FlowStep<InsuranceNewFlowState>> {
        // trigger events
        this.flow.dispatchCompleted();

        return this;
    }
}

export default SubmitStep;
