import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNationalities } from '../../../actions';
import { getNationalities } from '../../../selectors';
import SelectField from './SelectField';

const NationalityField = props => {
    const dispatch = useDispatch();
    const nationalities = useSelector(getNationalities);

    useEffect(() => {
        dispatch(loadNationalities());
    }, [dispatch]);

    return <SelectField options={nationalities} {...props} clearable={false} />;
};

export default NationalityField;
