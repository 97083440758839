import styled from 'styled-components';

type LanguageOptionProps = { active: boolean; isAtLogin?: boolean; theme: any };

const getColor = (props: LanguageOptionProps) => {
    if (props.isAtLogin) {
        return '#aaa';
    }

    return props.active ? '#000' : props.theme.themeHighlightColour;
};

const getTextDecoration = (props: LanguageOptionProps) => {
    if (!props.isAtLogin) {
        return 'none';
    }

    return props.active ? 'none' : 'underline';
};

export const LanguageOption = styled.button<LanguageOptionProps>`
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: ${getColor};
    text-decoration: ${getTextDecoration};
`;

export const Container = styled.div`
    display: inline-block;

    & ${LanguageOption}:not(:last-child)::after {
        padding: 0 0.25rem;
        content: '|';
        color: #aaa;
    }
`;

export const DropdownContainer = styled.div`
    flex: 0 1 200px;
`;

export const LoginLanguageContainer = styled.div`
    display: block;
    text-align: center;
`;
