import styled from 'styled-components';

const ChoiceWrap = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-align: left;
    font-size: 16px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 15px;
`;

export default ChoiceWrap;
