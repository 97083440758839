import { displayFields, Values } from '@appvantageasia/afc-calculator-ui-next';
import React from 'react';
import Portal from './Portal';

export type RemoteExpectedCashPaymentFieldProps<
    CalculatorValues extends object = any
> = displayFields.ExpectedCashPaymentFieldProps<CalculatorValues> & {
    container?: HTMLElement | null;
};

const RemoteExpectedCashPaymentField = ({ container, ...props }: RemoteExpectedCashPaymentFieldProps<Values>) => {
    const render = <displayFields.ExpectedCashPaymentField {...props} />;

    if (!container) {
        return render;
    }

    return <Portal container={container}>{render}</Portal>;
};

export default RemoteExpectedCashPaymentField;
