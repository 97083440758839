import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getCompanyIdentifier } from '../../../selectors';
import { getApplicationsUrl } from '../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../shared/HelmetTitle';
import Details from './Details';
import { getApplication, GetApplicationQuery, GetApplicationQueryVariables } from './data.graphql';

export type LocationParams = { id: string };

export type ApplicationData = GetApplicationQuery['application'];

const ApplicationRoute = () => {
    const { language } = useContentTranslation();
    const history = useHistory();
    const { id } = useParams<LocationParams>();
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    const { data, loading } = useQuery<GetApplicationQuery, GetApplicationQueryVariables>(getApplication, {
        variables: { id, locale: language },
        fetchPolicy: 'no-cache',
    });

    const application = data?.application;
    const url = getApplicationsUrl(companyCode, locationCode, application);

    useEffect(() => {
        if (application?.dealerId) {
            if (!isEqual(selectedDealerId, application.dealerId)) {
                history.push(url);
            }
        }
    }, [application, selectedDealerId, history, url]);

    if (loading) {
        // wait for the loading to be over
        return null;
    }

    if (!application) {
        return <Redirect to={url} />;
    }

    // we use lastUpdatedDt as key to control the component
    // there is too much memoized data bellow (and persistent state such as redux form) simply update it
    // so we will unmount/mount on reload
    return (
        <>
            <HelmetTitle title="Application Details" />
            <Details key={application.id} application={application} />
        </>
    );
};

export default ApplicationRoute;
