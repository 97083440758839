// @ts-ignore
import { ActionsV2 as Actions, Footer } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { getGlobalPermissions } from '../../../../selectors';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';
import UpdateStateAction from './UpdateStateAction';

export type RetryActionProps = {
    application: ApplicationDataFragment;
};

const RetryAction = ({ application }: RetryActionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mayManageReservation } = useSelector(getGlobalPermissions);

    const onClick = useCallback(() => {
        // submit the application
        dispatch(submit('application'));
    }, [dispatch]);

    return (
        <>
            <UpdateStateAction application={application} />
            <Actions>
                <Footer.FootBarButton disabled={!mayManageReservation} onClick={onClick}>
                    {t('applicationDetailsPage.button.retry')}
                </Footer.FootBarButton>
            </Actions>
        </>
    );
};

export default RetryAction;
