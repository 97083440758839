// @ts-ignore
import { Footer } from '@appvantageasia/afc-ui';
import { isEmpty } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { setNotification } from '../../../actions';
import { useCountry } from '../../../hookSelectors';
import { Insurance, InsuranceApplicationStatus, Channel, Maybe } from '../../../schema';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import CheckboxField from '../../shared/form-v2/CheckboxField';
import { Container } from '../../ui/calculator';
import { ApplicationData } from '../ApplicationRoute';
import Document from '../ApplicationRoute/Document';
import ModalProvider, { GlobalErrorProvider } from '../ApplicationRoute/Document/ModalProvider';
import useFormChanged from '../ApplicationRoute/actions/useFormChanged';
import ActivityLog from './ActivityLog';
import Application from './Application';
import Actions from './actions';
import { InsuranceApplicationDataFragment } from './data.graphql';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

export type InsuranceCalculatorValues = {
    displacement: number;
    driverLicensePassDate?: Maybe<Date>;
    occupation: string;
    ncd?: Maybe<number>;
    existingCarPlate?: Maybe<string>;
    price: number;
    totalPrice: number;
    originalRegistrationDate: Maybe<Date>;
};

export type ApplicationFormProps = {
    allowPrimaryInfoChanges?: boolean;
    insuranceApplication: InsuranceApplicationDataFragment;
    disabled?: boolean;
    validation: {
        phonePattern: RegExp | null;
    };
    useCustomerNationality: boolean;
};

export type ApplicationFormValues = Pick<
    ApplicationData,
    'assigneeId' | 'customer' | 'proceedWithCustomerDevice' | 'attachments' | 'variant'
> & {
    calculator: Partial<InsuranceCalculatorValues>;
    // temporary variant field for express
    expressVariant?: VariantDataFragment;
    assetCondition?: string;
    insuranceCompany: Partial<Insurance>;
};

export type ApplicationFormSubmit = FormSubmitHandler<ApplicationFormValues, ApplicationFormProps>;

export const useProceedWithCustomerDevice = (application: InsuranceApplicationDataFragment, hasChanges: boolean) => {
    const { channelSetting } = useCountry();

    return useMemo(() => {
        // first check the system allows it
        const allowProceedWithCustomerDevice =
            (application.channel === Channel.USED && channelSetting.used.allowProceedWithCustomerDevice) ||
            (application.channel === Channel.NEW && channelSetting.new.allowProceedWithCustomerDevice) ||
            (application.channel === Channel.EXPRESS && channelSetting.express.allowProceedWithCustomerDevice);

        if (!allowProceedWithCustomerDevice) {
            return false;
        }

        if (!application.steps?.submission) {
            // the application is not yet submitted so we can still ask for the customer to approve
            return true;
        }

        switch (application.status) {
            case InsuranceApplicationStatus.RECEIVED:
                // still being processed by the system
                return false;

            case InsuranceApplicationStatus.SIGNING:
            case InsuranceApplicationStatus.SIGNINGTIMEOUT:
                // application failed to submit or signing is not complete ye
                return true;

            default:
                // only on changes
                return hasChanges;
        }
    }, [channelSetting, application, hasChanges]);
};

const ApplicationForm = ({
    allowPrimaryInfoChanges = false,
    insuranceApplication,
    disabled = false,
    error,
    submitting,
}: ApplicationFormProps & InjectedFormProps<ApplicationFormValues, ApplicationFormProps>) => {
    const { t } = useTranslation();
    const history = useHistory();
    const onCancel = useCallback(() => history.goBack(), [history]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(error) && !submitting) {
            dispatch(setNotification('Error', error));
        }
    }, [submitting, error, dispatch]);

    const hasChanges = useFormChanged();
    const showProceedWithCustomerDevice = useProceedWithCustomerDevice(insuranceApplication, hasChanges);

    return (
        <>
            <GlobalErrorProvider>
                <ModalProvider>
                    <Tabs>
                        <TabList>
                            <Tab>{t('applicationDetailsPage.tab.application')}</Tab>
                            <Tab>{t('applicationDetailsPage.tab.document')}</Tab>
                            <Tab>{t('applicationDetailsPage.tab.activityLog')}</Tab>
                        </TabList>
                        <TabPanel>
                            <Application
                                allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                                disabled={disabled}
                                insuranceApplication={insuranceApplication}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Document application={insuranceApplication} />
                        </TabPanel>
                        <TabPanel>
                            <ActivityLog insuranceApplication={insuranceApplication} />
                        </TabPanel>
                    </Tabs>
                </ModalProvider>
            </GlobalErrorProvider>
            <FootContainer className="appActionBtn">
                <FootBar>
                    {!disabled && showProceedWithCustomerDevice && (
                        <FootBarRow>
                            <div style={{ flex: 1 }} />
                            <Container>
                                <CheckboxField
                                    label={t('applicationDetailsPage.label.proceedWithCustomerDevice')}
                                    name="proceedWithCustomerDevice"
                                />
                            </Container>
                        </FootBarRow>
                    )}
                    <FootBarRow>
                        {onCancel && (
                            <FootBarButton onClick={onCancel}>
                                {t('applicationDetailsPage.button.cancel')}
                            </FootBarButton>
                        )}
                        <div style={{ flex: 1 }} />
                        <Actions application={insuranceApplication} />
                    </FootBarRow>
                </FootBar>
            </FootContainer>
        </>
    );
};

export default reduxForm<ApplicationFormValues, ApplicationFormProps>({ form: 'application' })(ApplicationForm);
