// @ts-ignore
import { PortletButton } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection } from 'redux-form';
import CustomerStep, { CustomerStepProps } from './CustomerStep';

export type ApplicantStepProps = Omit<CustomerStepProps, 'name' | 'action'> & {
    addGuarantor?: () => void;
    canAddGuarantor?: boolean;
};

const ApplicantStep = ({ addGuarantor, canAddGuarantor, ...props }: ApplicantStepProps) => {
    const action = canAddGuarantor ? <PortletButton onClick={addGuarantor}>ADD GUARANTOR</PortletButton> : null;
    const { t } = useTranslation();

    return (
        <FormSection name="customer">
            <CustomerStep action={action} name={t('applicationDetailsPage.subHeading.applicantDetails')} {...props} />
        </FormSection>
    );
};

export default ApplicantStep;
