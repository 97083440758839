// @ts-ignore
import { PrimaryButton } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormActions from '../../ui/form/FormActions';
import Title from '../../ui/login/Title';

type RequestNewPasswordSuccessProps = {
    goBackToLogin: () => void;
};

const RequestNewPasswordSuccess = ({ goBackToLogin }: RequestNewPasswordSuccessProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('companyAuthenticationPage.title.passwordReset')}</Title>
            <p>{t('companyAuthenticationPage.titleDescription.passwordReset')}</p>
            <FormActions>
                <PrimaryButton onClick={goBackToLogin} type="button">
                    {t('companyAuthenticationPage.button.signIn')}
                </PrimaryButton>
            </FormActions>
        </>
    );
};

export default RequestNewPasswordSuccess;
