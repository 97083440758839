import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { unsetContext } from '../../actions';
import { getIsAuthenticated } from '../../selectors';
import Error404NotFound from '../routes/Error404NotFound';
import LoginRoute from '../routes/LoginRoute';
import ResetPasswordRoute from '../routes/ResetPasswordRoute';
import AydenCallback from '../routes/wip/AydenCallback';
import GuaranteedBuybackCallback from '../routes/wip/GuaranteedBuybackCallback';
import MyInfoCallback from '../routes/wip/MyInfoCallback';
import NamirialCallback from '../routes/wip/NamirialCallback';
import PayGatePaymentCallback from '../routes/wip/PayGatePaymentCallback';
import PorschePaymentCallback from '../routes/wip/PorschePaymentCallback';
import TTBPaymentCallback from '../routes/wip/TTBPaymentCallback';
import RedirectToCompanyHome from '../utilities/RedirectToCompanyHome';
import CompanyRouter from './CompanyRouter';
import useScrollTop from './useScrollTop';

const AppRouter = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    const dispatch = useDispatch();
    const history = useHistory();
    const isLoginPage = history?.location?.pathname;

    useEffect(() => {
        if (!isAuthenticated && isLoginPage === '/login') {
            // when accessing app for the first time or manually changing route to '/login'
            // clear context once if not yet authenticated
            dispatch(unsetContext());
        }
    }, [dispatch, isAuthenticated, isLoginPage]);

    useScrollTop();

    return (
        <Switch>
            <Route component={MyInfoCallback} path="/myInfoCallback" exact />
            <Route component={AydenCallback} path="/aydenCallback" exact />
            <Route component={NamirialCallback} path="/namirialCallback" exact />
            <Route component={GuaranteedBuybackCallback} path="/guaranteedBuybackCallback" exact />
            <Route component={PorschePaymentCallback} path="/porschePaymentCallback" exact />
            <Route component={PayGatePaymentCallback} path="/paygatePaymentCallback" exact />
            <Route component={TTBPaymentCallback} path="/ttbPaymentCallback" exact />
            {!isAuthenticated && <Route component={LoginRoute} path="/login" exact />}
            {!isAuthenticated && <Route component={ResetPasswordRoute} path="/login/resetpassword" exact />}
            <Route component={CompanyRouter} path="/:companyCode/:locationCode" />
            {isAuthenticated && <RedirectToCompanyHome />}
            <Redirect path="/" to="/login" exact />
            <Route component={Error404NotFound} />
        </Switch>
    );
};

export default AppRouter;
