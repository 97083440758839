import { get } from 'lodash/fp';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import DrivingLicense from '../../../../../flows/DraftFlow/components/KYC/PME/DrivingLicense';
import { BankPresetOption } from '../../../../../schema';
import { onNumKeyPress, yupExt } from '../../../../../utilities/forms';
import SubTitle from '../../../../ui/SubTitle';
import useBankPresetLabel from '../../../../utilities/useBankPresetLabel';
import DateField from '../../DateField';
import IdentityNumberField from '../../IdentityNumberField';
import TextField from '../../TextField';

export type IdentityDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const IdentityDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: IdentityDetailsProps) => {
    const { t } = useTranslation();

    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const bankPresetOption = get('financeProduct.bank.presetOption', formValues);

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);

    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.identityDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={t('customerDetails.label.passportNumber')}
                        maxLength="200"
                        name="details.passport.number"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        label={t('customerDetails.label.passportExpiry')}
                        name="details.passport.expiry"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <IdentityNumberField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('emiratesIdNumber')}
                        name="identityNumber.value"
                        onKeyPress={onNumKeyPress}
                        placeholder={t('customerDetails.placeholder.identityNumber')}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('emiratesIdExpiry')}
                        name="details.emiratesIdExpiry.value"
                    />
                </div>
                <DrivingLicense isGrid />
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('residentSince')}
                        name="details.uaeResidentSince.value"
                    />
                </div>
                {isEnbdBankPreset && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                disabled={!allowPrimaryInfoChanges && disabled}
                                label={getBankPresetLabel('visaNumber')}
                                name="details.thirdParty.enbdBank.visaNumber.value"
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <DateField
                                disabled={!allowPrimaryInfoChanges && disabled}
                                label={getBankPresetLabel('visaIssueDate')}
                                maxDate={new Date()}
                                name="details.thirdParty.enbdBank.visaIssueDate.value"
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <DateField
                                disabled={!allowPrimaryInfoChanges && disabled}
                                label={getBankPresetLabel('visaExpiryDate')}
                                minDate={new Date()}
                                name="details.thirdParty.enbdBank.visaExpiryDate.value"
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export const identityEnbdSchema = (t: Function) => ({
    visaNumber: yupExt.customerProperty().shape({
        value: yup.string().nullable().notRequired(),
    }),
    visaIssueDate: yupExt.customerProperty().shape({
        value: yup.date().nullable().notRequired(),
    }),
    visaExpiryDate: yupExt.customerProperty().shape({
        value: yup.date().nullable().notRequired(),
    }),
});

export const schema = {
    drivingLicense: yup
        .array()
        .of(
            // @ts-ignore
            yup.lazy((value, options) => {
                const isEnbdBankPreset =
                    get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

                if (isEnbdBankPreset) {
                    return yup.object().shape({
                        expiryDate: yup.string().nullable().notRequired(),
                        number: yup.string().nullable().notRequired(),
                    });
                }
                const licenseSchema = {
                    expiryDate: yup.string().required('Required'),
                    number: yup
                        .string()
                        .required('Required')
                        .max(200, 'Should be less then or equal to 200 characters'),
                };

                return yup.object().shape(licenseSchema);
            })
        )
        .nullable()
        .required(),
    passport: yupExt.customerProperty().shape({
        expiry: yup.string().required('Required'),
        number: yup.string().required('Required').max(200, 'Should be less then or equal to 200 characters'),
    }),
    emiratesIdExpiry: yupExt.customerProperty().shape({
        value: yup.string().required('Required'),
    }),
    uaeResidentSince: yup.string().required('Required'),
};

export default IdentityDetails;
