import { TFunction } from 'i18next';
import React from 'react';
import styled from 'styled-components';
import PasswordRequirement from './PasswordRequirement';

const Title = styled.div`
    color: #9c9c9c;
    padding: 1rem 0;
`;

const Container = styled.div`
    & > * {
        margin-bottom: 0.5rem;
    }
`;

type PasswordRequirementListProps = {
    t: TFunction;
    requirements: Array<{ description: string; isChecked: boolean }>;
};

const PasswordRequirementList = ({ t, requirements }: PasswordRequirementListProps) => (
    <Container>
        <Title>{t('companyAuthenticationPage.label.passwordRequirements')}</Title>
        {requirements.map(requirement => (
            <PasswordRequirement key={requirement.description} {...requirement} />
        ))}
    </Container>
);

export default PasswordRequirementList;
