import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { getIsAuthenticated } from '../../selectors';
import { getCompanyLoginUrl } from '../../utilities/urls';
import MainRouter from './MainRouter';

const authenticatedRoutes = [
    '/applications',
    '/leads',
    '/profile',
    '/insuranceApplications',
    '/reservations',
    '/appointments',
];

const InternalRouter = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    const location = useLocation();
    const history = useHistory();
    const { dealerId } = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (!isAuthenticated) {
        const isLoginRoute = location.pathname.includes('login');
        const redirect = `${location.pathname}${location.search}`;
        const isAuthenticatedRoute = authenticatedRoutes.some(route => redirect.includes(route));

        // need to login first when no public access or authenticated route
        // then redirect back to accessed route
        const isLoginRedirect = !isLoginRoute && isAuthenticatedRoute;

        if (isLoginRedirect) {
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect, dealerId });

            return null;
        }
    }

    const maySeeLogin = !isAuthenticated;

    return <MainRouter isAuthenticated={isAuthenticated} maySeeLogin={maySeeLogin} />;
};

export default InternalRouter;
