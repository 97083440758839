import { useQuery } from '@apollo/client';
import { isEmpty, isNil, orderBy } from 'lodash/fp';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import {
    getFlowConsentsAndDeclarations,
    GetFlowConsentsAndDeclarationsQuery,
    GetFlowConsentsAndDeclarationsQueryVariables,
} from '../../../../api/consents.graphql';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { InsuranceCompanyDataFragment } from '../../../../components/data/useLoadInsuranceCompany.graphql';
import { PromoDataFragment } from '../../../../components/data/useLoadPromo.graphql';
import { ZoneScopeFragment } from '../../../../components/data/useLoadScope.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { ApplicationCustomerDataFragment } from '../../../../components/routes/ApplicationRoute/data.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCustomerNamesSynchronization from '../../../../components/shared/useCustomerNamesSynchronization';
import useFormValues from '../../../../components/utilities/useFormValues';
import { Channel, EventExternalSite, InsuranceApplicationCalculator, RuntimeType } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { CarOfInterestType, TradeInProps } from '../../steps/EventDraftStep';
import { getDraftConsents } from '../../utils/consents';
import AFCCustomerForm, { schema as afcSchema } from './CustomerDetailsForm';
import PMECustomerForm, { schema as pmeSchema } from './PME/CustomerDetailsForm';

export type CustomerFormProps = {
    countryCode: string;
    apply: (values: any, validate: (values: any) => void) => Promise<any>;
    zone: ZoneScopeFragment;
    dealerId?: string;
    useCustomerBirthDay: boolean;
    useCustomerNationality: boolean;
    validation: any;
    channel: Channel;
    event: EventDataFragment;
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    financeProduct?: FinanceDataFragment;
    promo?: PromoDataFragment | null;
    withMyInfoError?: boolean;
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    referenceId: string | undefined;
    setReferenceId: (id: string) => unknown;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    bookingId?: string;
    step: string;
    appliedForFinancing?: boolean;
    appliedForInsurance?: boolean;
    bank?: BankDataFragment;
    isCalculatorEnabled?: boolean;
    insuranceCompany?: InsuranceCompanyDataFragment;
    insuranceCalculator?: Partial<CalculatorInsuranceValues>;
};

export type CustomerFormValues = {
    dealerId: string;
    carOfInterest?: CarOfInterestType;
    tradeIn?: TradeInProps | null;
    customerInfo: Partial<ApplicationCustomerDataFragment>;
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    consentDraft?: { consents: Record<string, boolean> };
    appliedForFinancing?: boolean;
    appliedForInsurance?: boolean;
    isCalculatorEnabled?: boolean;
    insurance?: {
        calculator?: Partial<InsuranceApplicationCalculator>;
    };
};

const getCustomerForm = (type: RuntimeType) => {
    switch (type) {
        case RuntimeType.PME:
            return PMECustomerForm;

        case RuntimeType.AFC:
        default:
            return AFCCustomerForm;
    }
};

const CustomerForm = (props: CustomerFormProps & InjectedFormProps<CustomerFormValues, CustomerFormProps>) => {
    const {
        valid,
        handleSubmit,
        event,
        variant,
        calculator,
        financeProduct,
        promo,
        withMyInfoError,
        hasTestDrive,
        hasTradeIn,
        appliedForFinancing,
        appliedForInsurance,
        isCalculatorEnabled,
        change,
        referenceId,
        setReferenceId,
        miniConfiguratorDetails,
        bookingId,
        dealerId: dealerIdProps,
        step,
        bank,
        insuranceCompany,
        insuranceCalculator,
    } = props;

    const eventSetting = event.setting;

    const { allowTradeIn, allowTestDrive, externalSite } = eventSetting;

    const isPorscheFinderEnabled = externalSite === EventExternalSite.PORSCHEFINDER;
    const isIccCheckoutEnabled = externalSite === EventExternalSite.ICC;
    const isPorscheOrIccCheckoutEnabled = isPorscheFinderEnabled || isIccCheckoutEnabled;

    // get form values
    const {
        carOfInterest,
        hasTestDrive: checkedTestDrive,
        hasTradeIn: checkedTradeIn,
        consentDraft,
        dealerId,
    } = useFormValues<CustomerFormValues>();
    const { consents: consentStatuses } = consentDraft ?? {};

    useEffect(() => {
        if (isPorscheOrIccCheckoutEnabled) {
            change('hasTestDrive', hasTestDrive);
            change('hasTradeIn', hasTradeIn);
            change('appliedForFinancing', appliedForFinancing);
            change('appliedForInsurance', appliedForInsurance);
            change('isCalculatorEnabled', isCalculatorEnabled);
        }
    }, [
        change,
        isPorscheOrIccCheckoutEnabled,
        hasTestDrive,
        hasTradeIn,
        appliedForFinancing,
        isCalculatorEnabled,
        appliedForInsurance,
    ]);

    // update choices for test drive and trade in if checkboxes are in current page
    const nextHasTestDrive = isPorscheOrIccCheckoutEnabled ? hasTestDrive : allowTestDrive && checkedTestDrive;
    const nextHasTradeIn = isPorscheOrIccCheckoutEnabled ? hasTradeIn : allowTradeIn && checkedTradeIn;
    const nextIsCalculatorEnabled = isPorscheOrIccCheckoutEnabled ? isCalculatorEnabled : false;
    const onNormalizeNames = useCustomerNamesSynchronization('customerInfo');

    const { data } = useQuery<GetFlowConsentsAndDeclarationsQuery, GetFlowConsentsAndDeclarationsQueryVariables>(
        getFlowConsentsAndDeclarations,
        {
            variables: {
                consent: {
                    dealerId: dealerId!,
                    eventId: event.id,
                    bankId: appliedForFinancing ? bank?.id : undefined,
                    insuranceId: appliedForInsurance ? insuranceCompany?.id : undefined,
                },
            },
            skip: isNil(dealerId),
        }
    );

    const showDealerSelection = useRef(isNil(dealerIdProps));

    const consents = useMemo(
        () => orderBy('order', 'asc', getDraftConsents(data?.result, appliedForFinancing, appliedForInsurance)),
        [data, appliedForFinancing, appliedForInsurance]
    );
    const hasConsents = consents.length > 0;

    useEffect(() => {
        change('__exclude.consents', consents);
    }, [change, consents]);

    const { type } = useSelector(getRuntimeSettings);
    const CustomerForm = getCustomerForm(type);

    return (
        <CustomerForm
            appliedForFinancing={appliedForFinancing}
            appliedForInsurance={appliedForInsurance}
            bank={bank}
            bookingId={bookingId}
            calculator={calculator}
            carOfInterest={carOfInterest}
            consentStatuses={consentStatuses}
            consents={consents}
            dealerId={dealerId}
            event={event}
            financeProduct={financeProduct}
            handleSubmit={handleSubmit}
            hasConsents={hasConsents}
            insuranceCalculator={insuranceCalculator}
            insuranceCompany={insuranceCompany}
            miniConfiguratorDetails={miniConfiguratorDetails}
            nextHasTestDrive={nextHasTestDrive}
            nextHasTradeIn={nextHasTradeIn}
            nextIsCalculatorEnabled={nextIsCalculatorEnabled}
            onNormalizeNames={onNormalizeNames}
            promo={promo}
            referenceId={referenceId}
            setReferenceId={setReferenceId}
            showDealerSelection={showDealerSelection.current}
            step={step}
            valid={valid}
            variant={variant}
            withMyInfoError={withMyInfoError}
        />
    );
};

// remove error message for required in events
export const schema = (hasCalculator: boolean, type: RuntimeType, isMarketingReconsent: boolean) => {
    switch (type) {
        case RuntimeType.PME:
            return pmeSchema(hasCalculator, isMarketingReconsent);

        case RuntimeType.AFC:
        default:
            return afcSchema(hasCalculator, isMarketingReconsent);
    }
};

export default reduxForm<CustomerFormValues, CustomerFormProps>({
    form: 'customer',
    // @ts-ignore
    onSubmit: (values, dispatch, props) => {
        const { validate, apply, referenceId } = props;

        // @ts-ignore
        return apply({ ...values, referenceId }, nextValues => {
            if (!validate) {
                return;
            }

            const errors = validate(nextValues, props);

            if (!isEmpty(errors)) {
                throw new SubmissionError(errors);
            }
        });
    },
})(CustomerForm);
