import styled from 'styled-components';

export type ChatMessageContainerProps = { isAlt?: boolean };

const ChatMessageContainer = styled.div<ChatMessageContainerProps>`
    text-align: ${props => (props.isAlt ? 'right' : 'left')};
    clear: both;
    margin: 15px 0;

    &:first-of-type {
        margin: 0;
    }
`;

export default ChatMessageContainer;
