import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import { Channel } from '../../../../../schema';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Row } from '../ui';

export type FinancingFormProps = {
    allowTestDrive: boolean;
    allowTradeIn: boolean;
};

const CheckboxRow = styled(Row)`
    grid-gap: 0;
    margin-top: 21px;
    line-height: 1;
    @media (max-width: ${breakpoints.sm}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
`;

const FinancingForm = ({ allowTestDrive, allowTradeIn }: FinancingFormProps) => {
    const { t } = useTranslation();
    const channel = Channel.USED;

    if (!allowTestDrive && !allowTradeIn) {
        return null;
    }

    return (
        <CheckboxRow>
            {allowTestDrive && (
                <CheckboxField
                    channel={channel}
                    label={t('usedCalculatorPage.checkbox.testDrive')}
                    name="hasTestDrive"
                />
            )}
            {allowTradeIn && (
                <CheckboxField channel={channel} label={t('usedCalculatorPage.checkbox.tradeIn')} name="hasTradeIn" />
            )}
        </CheckboxRow>
    );
};

export default FinancingForm;
