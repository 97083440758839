// @ts-ignore
import { DarkButton, Actions } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Title } from '../../../../components/ui/calculator';
import ChoiceWrap from '../../../../components/ui/calculator/ChoiceWrap';
import { Channel } from '../../../../schema';
import { Application } from '../../../DraftFlow';
import PreviewForm from './PreviewForm';

export type PreviewPageProps = {
    onSubmit: (values: any) => Promise<void>;
    application: Application;
    channel: Channel;
};

type PreviewTranslations = { title: string; description: string; button: { [key: string]: string } };

const PreviewPage = ({ onSubmit, application, channel }: PreviewPageProps) => {
    const { t } = useTranslation();
    const { title, description, button }: PreviewTranslations = t('previewPage', { returnObjects: true });

    return (
        <Container>
            <Title>{title}</Title>
            <ChoiceWrap>{description}</ChoiceWrap>
            <PreviewForm application={application} channel={channel} initialValues={application} />
            <Actions>
                <DarkButton onClick={onSubmit} type="button">
                    {button.next}
                </DarkButton>
            </Actions>
        </Container>
    );
};

export default PreviewPage;
