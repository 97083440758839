import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import RedirectToCompanyHome from '../../../../components/utilities/RedirectToCompanyHome';
import { getIsAuthenticated } from '../../../../selectors';
import ApplyCalculatorProvider from '../../../utils/ApplyCalculatorProvider';
import OptionsControlProvider from '../../../utils/OptionsControlProvider';
import { ExpressCalculatorStepValues } from '../../steps/ExpressCalculatorStep';
import { ExpressVariant } from '../../types';
import Inner from './Inner';

export type ExpressCalculatorProps = {
    onSubmit: (values: ExpressCalculatorStepValues) => Promise<void>;
    initialVariant?: ExpressVariant;
    initialValues?: Partial<CalculatorValues>;
    dealerId: string;
    snapshot?: any;
};

const ExpressCalculator = ({
    initialValues,
    dealerId,
    snapshot,
    onSubmit,
    initialVariant,
}: ExpressCalculatorProps): ReactElement | null => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    if (!isAuthenticated) {
        return <RedirectToCompanyHome />;
    }

    return (
        <OptionsControlProvider>
            <ApplyCalculatorProvider handleSubmit={onSubmit}>
                <Inner
                    apply={onSubmit}
                    dealerId={dealerId}
                    initialValues={initialValues}
                    initialVariant={initialVariant}
                    snapshot={snapshot}
                />
            </ApplyCalculatorProvider>
        </OptionsControlProvider>
    );
};

export default ExpressCalculator;
