import React from 'react';
import { completePayment, CompletePaymentMutation, CompletePaymentMutationVariables } from '../../../api/draft.graphql';
import TTBDeposit from '../../DraftFlow/components/TTBDeposit';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';

class TTBDepositStep extends FlowStep<RemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'deposit';
    }

    public async execute() {
        const { apolloClient } = this;
        const { token } = this.state;

        if (!token) {
            throw new Error('Token missing in state');
        }

        const response = await apolloClient.mutate<CompletePaymentMutation, CompletePaymentMutationVariables>({
            mutation: completePayment,
            variables: { token },
        });

        this.stateStore.update({ ...response.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { application, token, source, channel } = this.state;

        return (
            <TTBDeposit
                application={application}
                backStep={this.getBackContext()}
                channel={channel}
                dealerId={application.dealerId}
                onSubmit={this.submit}
                source={source}
                token={token}
            />
        );
    }
}

export default TTBDepositStep;
