import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useUserlike } from '../../../../components/shared/Chatbot/Userlike';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { ConfigFormValues } from './ConfigForm';
import messageIcon from './assets/chat.png';

const FooterWidgetContainer = styled.div`
    position: sticky;
    bottom: 0;
    background-color: #f0f0f0;
    z-index: 1000;

    /* Desktop and laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        margin-left: auto;
        max-width: 45vw;
    }
`;

const WidgetContent = styled.div`
    display: grid;
    grid-template-columns: auto 80px auto;
    grid-template-areas: 'textContainer messageIcon whiteSpace';
    padding: 0 20px 20px;

    #userlikeCustomTab {
        grid-area: messageIcon;
        cursor: pointer;
        border: none;
        padding: 0;

        img {
            width: 80px;
            height: 80px;
        }
    }

    /* Tablet, Desktop and laptop Screen */
    @media (min-width: ${breakpoints.sm}) {
        grid-template-columns: auto 80px 80px;
    }
`;

const TextContainer = styled.div`
    grid-area: textContainer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding-left: 30px;

    span {
        margin: 0;
    }
    .totalPrice {
        font-weight: 600;
        font-size: 17.5px;
    }

    /* Tablet, Desktop and laptop Screen */
    @media (min-width: ${breakpoints.sm}) {
        padding-left: 80px;
    }
`;

const WidgetBar = styled.div`
    position: relative;
    cursor: pointer;
    padding: 7px 0px;

    hr {
        border: none;
        display: block;
        height: 6px;
        width: 120px;
        background: #b5b5b5;
        border-radius: 9px;
        left: 0;
        margin: 0 auto;
    }
`;

const ClickableArea = styled.span`
    grid-area: whiteSpace;
    cursor: pointer;
`;

type FooterWidgetProps = {
    calculatorValues?: Partial<CalculatorValues>;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const FooterWidget = ({ calculatorValues, setShowModal }: FooterWidgetProps) => {
    const { formatCurrency } = useCompanyFormatting();
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const { messenger } = useUserlike(ckdConfiguration.liveChatWidgetKey);

    const openChat = useCallback(() => {
        if (messenger) {
            messenger.maximize();
        }
    }, [messenger]);

    const { price: timepiecePrice } = ckdConfiguration.timepiece;
    const { timepiece } = useFormValues<ConfigFormValues>();

    const totalPrice = useMemo(() => {
        if (!calculatorValues?.totalPrice) {
            return 'XXX,XXX.XX';
        }

        const sum = calculatorValues.totalPrice + (timepiece?.isAdded ? timepiecePrice : 0);

        return formatCurrency(sum);
    }, [calculatorValues, formatCurrency, timepiece, timepiecePrice]);

    const monthlyInstalment = calculatorValues?.monthlyInstalments
        ? formatCurrency(calculatorValues.monthlyInstalments[0].amount)
        : 'XXX,XXX.XX';

    return (
        <FooterWidgetContainer>
            <WidgetBar onClick={() => setShowModal(true)}>
                <hr />
            </WidgetBar>
            <WidgetContent>
                <TextContainer onClick={() => setShowModal(true)}>
                    <span className="totalPrice">Total Price {totalPrice}</span>
                    <span>From {monthlyInstalment} per month</span>
                    <span>{ckdConfiguration.delivery.estimatedDelivery}</span>
                </TextContainer>
                <button id="userlikeCustomTab" onClick={openChat} type="button">
                    <img alt="Live Chat Widget" src={messageIcon} />
                </button>
                <ClickableArea onClick={() => setShowModal(true)} />
            </WidgetContent>
        </FooterWidgetContainer>
    );
};

export default FooterWidget;
