import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Info = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
`;
