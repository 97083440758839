import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../selectors';
import { SubTitle } from '../Configurator/shared/ui';
import { Row, SectionContainer } from './shared/ui';

type InteriorSectionProps = {
    formatCurrency: (price: number) => string;
    interiorColor: string;
};

const InteriorSection = ({ formatCurrency, interiorColor }: InteriorSectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const interior = useMemo(
        () => ckdConfiguration.interior.options.find((item: { name: string }) => item.name === interiorColor),
        [interiorColor, ckdConfiguration.interior.options]
    );

    return (
        <SectionContainer>
            <SubTitle>{ckdConfiguration.summary.interiorColor}</SubTitle>
            <Row>
                <span>{interior.label}</span>
                <span>{ckdConfiguration.interior.price}</span>
            </Row>
        </SectionContainer>
    );
};

export default InteriorSection;
