// @ts-ignore
import { InsuranceValues } from '@appvantageasia/afc-calculator-ui-next';
import { bank } from '@appvantageasia/afc-calculator-ui-next/build/computing/fields';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { ZoneScopeFragment } from '../../../../../components/data/useLoadScope.graphql';
import useMustExistDealerIdFromTenantContext from '../../../../../components/data/useMustExistDealerIdFromTenantContext';
import { ApplicationCustomerDataFragment } from '../../../../../components/routes/ApplicationRoute/data.graphql';
import { pmeSchema } from '../../../../../components/shared/partialForms/CustomerInformationForm';
import { Container, Title } from '../../../../../components/ui/calculator';
import useValidation from '../../../../../components/utilities/useValidation';
import useValidationContext from '../../../../../components/utilities/useValidationContext';
import { useCountry } from '../../../../../hookSelectors';
import { Channel, CustomerDetailsSource, RuntimeType } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { handleResponseError } from '../../../../../utilities/forms';
import { schema } from '../../../../DraftFlow/components/Consent/ConsentForm';
import Navigation from '../../../../utils/Navigation';
import { BackStepContext } from '../../../../utils/flow';
import CustomerForm from './CustomerForm';

export type InnerProps = {
    submit: (values: any) => Promise<any>;
    zone: ZoneScopeFragment;
    backStep?: BackStepContext;
    channel: Channel;
    disableFinanceApplication?: boolean;
    customer?: ApplicationCustomerDataFragment;
    dealerId: string;
    insuranceCalculator?: Partial<InsuranceValues> | undefined;
};

const buildInsuranceCalculatorValues = (insuranceCalculator: Partial<InsuranceValues> | undefined) => {
    if (!insuranceCalculator) {
        return {};
    }

    const { noClaimDiscount, yearsOfDriving, insurancePremium, dateOfRegistration } = insuranceCalculator;

    return {
        insurance: {
            calculator: {
                ncd: noClaimDiscount?.value,
                insurancePremium,
                yearsOfDriving: yearsOfDriving?.value,
                dateOfRegistration,
            },
        },
    };
};

const Inner = ({
    submit,
    zone,
    backStep,
    channel,
    disableFinanceApplication,
    customer,
    dealerId,
    insuranceCalculator,
}: InnerProps) => {
    // get runtime settings
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const { code: countryCode } = useCountry();

    const initialValues = useMemo(() => {
        return {
            application: {
                appliedForInsurance: true,
            },
            customer: {
                withMyInfo: false,
                ...(useCustomerNationality &&
                    countryCode === 'SG' && {
                        details: {
                            nationality: {
                                value: 'SG',
                                source: CustomerDetailsSource.MANUAL,
                            },
                        },
                    }),
                ...customer,
            },
            ...buildInsuranceCalculatorValues(insuranceCalculator),
        };
    }, [customer, useCustomerNationality, countryCode, insuranceCalculator]);

    const validation = useValidationContext();

    // prepare submit
    const onApply = useCallback(
        (values: any, validate: (values: any) => void) => {
            validate(values);

            return submit(values);
        },
        [submit]
    );

    // get translations
    const { t } = useTranslation();

    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    // if current dealer and selected dealer not the same
    // go back to car listing
    useEffect(() => {
        if (!isEqual(selectedDealerId, dealerId)) {
            backStep?.goTo();
        }
    }, [backStep, dealerId, selectedDealerId]);

    return (
        <Container>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <Title>{t('draftPage.title')}</Title>
            <CustomerForm
                apply={onApply}
                channel={channel}
                countryCode={countryCode}
                disableFinanceApplication={disableFinanceApplication}
                initialValues={initialValues}
                insuranceCalculator={insuranceCalculator}
                useCustomerBirthDay={useCustomerBirthDay}
                useCustomerNationality={useCustomerNationality}
                validation={validation}
                zone={zone}
            />
        </Container>
    );
};

export default Inner;
