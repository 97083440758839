import styled from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;

    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export default Grid;
