import styled, { css } from 'styled-components';

const sharedStyle = css<{ withBold: boolean }>`
    word-break: break-all;
    ${props =>
        props.withBold &&
        css`
            font-weight: bold;
        `}
`;

export const PreInfo = styled.div`
    font-size: 1.21rem;
    text-align: center;
    line-height: 25px;

    span {
        font-weight: bold;
    }
`;

export const List = styled.div`
    overflow-y: auto;
    max-height: 500px;
    margin-top: 10px;
`;

export const Item = styled.div`
    cursor: pointer;
    margin-bottom: 5px;
    min-height: 80px;
    border: 1px solid #ced4da;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
    font-size: 1.14rem;
    line-height: 23px;

    svg {
        width: 25px;
        min-width: 25px;
        max-width: 25px;
        margin-right: 30px;
    }

    & > div {
        flex: 1;
    }
`;

export const RowLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LineLeft = styled.div`
    ${sharedStyle}
`;

export const LineRight = styled.div`
    ${sharedStyle}
    text-align: right;
    min-width: 90px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
`;

export const NricItem = styled.div`
    cursor: pointer;
    margin-bottom: 5px;
    height: 80px;
    border: 1px solid #ced4da;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    font-size: 1.14rem;
    line-height: 17px;

    svg {
        min-width: 25px;
        max-width: 25px;
        width: 25px;
        margin-right: 15px;
    }

    & > div {
        flex: 1;
    }
`;

export const Label = styled.div`
    display: block;
    font-size: 0.93rem;
    text-transform: ${props => props.theme.casing};
    color: #bfbfbf;
`;

export const Value = styled.div`
    word-break: break-all;
    display: block;
    color: #000;
`;

export const Error = styled.p`
    color: #ff0000;
    font-size: 0.72rem;
    margin-top: 10px;
    margin-bottom: 0;
`;
