// @ts-ignore
import { Actions } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ChoiceWrap from './ChoiceWrap';
import {
    MyInfoBoxWrap,
    SingpassLogoContainer,
    SingpassButtonContainer,
    MyInfoText,
    MyInfoManualButton,
} from './MyInfoBoxWrap';
import { List, MyInfoListWrap } from './MyInfoListWrap';
import Title from './Title';
import { ReactComponent as SingpassButton } from '../../../assets/images/singpass_button.svg';
import { ReactComponent as SingpassLogo } from '../../../assets/images/singpass_logo.svg';

export type GuarantorMyInfoPageProps = {
    goToMyInfo: () => void;
    goToManual: () => void;
};

const GuarantorMyInfoPage = ({ goToMyInfo, goToManual }: GuarantorMyInfoPageProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('guarantorMyInfoPage.title')}</Title>
            <ChoiceWrap>{t('guarantorMyInfoPage.label.info1')}</ChoiceWrap>
            <MyInfoBoxWrap>
                <SingpassLogoContainer href="https://www.singpass.gov.sg/myinfo/intro" target="_blank">
                    <SingpassLogo />
                </SingpassLogoContainer>
                <MyInfoText>{t('guarantorMyInfoPage.label.info2')}</MyInfoText>
                <SingpassButtonContainer onClick={goToMyInfo}>
                    <SingpassButton />
                </SingpassButtonContainer>
            </MyInfoBoxWrap>
            <MyInfoListWrap>
                <List>
                    <Trans components={{ l: <li /> }} i18nKey="guarantorMyInfoPage.label.myInfoList" />
                </List>
            </MyInfoListWrap>
            <Actions>
                <MyInfoManualButton onClick={goToManual} type="button">
                    {t('guarantorMyInfoPage.button.manual')}
                </MyInfoManualButton>
            </Actions>
            <MyInfoListWrap>
                {t('myInfoPage.label.manualListTitle')}
                <br />
                <List>
                    <Trans components={{ l: <li /> }} i18nKey="guarantorMyInfoPage.label.manualList" />
                </List>
            </MyInfoListWrap>
        </>
    );
};

export default GuarantorMyInfoPage;
