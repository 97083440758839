import { omit } from 'lodash/fp';
import React from 'react';
import { updateCustomer, UpdateCustomerMutationVariables, UpdateCustomerMutation } from '../../../api/customer.graphql';
import {
    completeKyc,
    CompleteKycMutation,
    CompleteKycMutationVariables,
    completeConsents,
    CompleteConsentsMutation,
    CompleteConsentsMutationVariables,
    SaveDraftMutation,
    SaveDraftMutationVariables,
    saveDraft,
} from '../../../api/draft.graphql';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { prepareForGraphQL } from '../../../utilities/forms';
import { ReduxFormFlowStep } from '../../utils/flow';
import { FileInput, uploadFiles } from '../../utils/uploads';
import KYCRoute from '../components/KYC';
import { NewFlowState } from '../types';
import { getFinancingConsents, getInsuranceConsents, getNonFinancingConsents } from '../utils/consents';

export type KYCStepValues = ApplicationCustomerDataFragment & {
    files: FileInput[];
    referenceId: string;
    draftTo?: string;
};

class KYCStep extends ReduxFormFlowStep<NewFlowState, KYCStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'kyc';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('kycPage.label.step');
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.kyc || false;
    }

    protected async execute({ files, referenceId, draftTo, ...customerData }: KYCStepValues) {
        const { apolloClient } = this;
        const { token, application, consents, channel } = this.state;

        if (!token) {
            throw new Error('token is missing in state');
        }

        if (!application) {
            throw new Error('application is missing in state');
        }

        // upload files
        await uploadFiles(
            apolloClient,
            [...files, ...application.attachments],
            application.attachments,
            null,
            application.id,
            token
        );

        // update the customer
        const { id: customerId, type, ...data } = customerData;
        await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
            mutation: updateCustomer,
            variables: { id: customerId, data: prepareForGraphQL(omit(['details.vehicles'], data)), token },
        });

        // complete consents
        const applyForFinancing = application.appliedForFinancing;
        const financingConsents = getFinancingConsents(consents, channel);
        const nonFinancingConsents = getNonFinancingConsents(consents, channel);
        if ((applyForFinancing && financingConsents.length) || (!applyForFinancing && nonFinancingConsents.length)) {
            await apolloClient.mutate<CompleteConsentsMutation, CompleteConsentsMutationVariables>({
                mutation: completeConsents,
                variables: { token, eventId: referenceId },
            });
        }

        // check if application is saved as draft
        if (draftTo) {
            await apolloClient.mutate<SaveDraftMutation, SaveDraftMutationVariables>({
                mutation: saveDraft,
                variables: { token, assigneeId: draftTo },
            });

            // trigger events
            this.flow.dispatchCompleted();

            // no next step from here
            return null;
        }

        // then complete KYC
        const apiResponse = await apolloClient.mutate<CompleteKycMutation, CompleteKycMutationVariables>({
            mutation: completeKyc,
            variables: { token },
        });

        // update state
        this.stateStore.update({ ...apiResponse.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { bank, country, application, withMyInfoError, channel, consents: allConsents } = this.state;

        if (!bank) {
            throw new Error('Bank missing in state');
        }

        if (!application) {
            throw new Error('application missing in state');
        }

        const consents = application?.appliedForFinancing
            ? getFinancingConsents(allConsents, channel)
            : getNonFinancingConsents(allConsents, channel);

        const insuranceConsents = application?.insuranceApplication ? getInsuranceConsents(allConsents) : [];

        const applicationConsents = [...consents, ...insuranceConsents];

        return (
            <>
                <HelmetTitle channel={channel} title="Applicant Details" />
                <KYCRoute
                    application={application}
                    backStep={this.getBackContext()}
                    bank={bank}
                    consents={applicationConsents}
                    country={country}
                    initialValues={{ ...application.customer, files: application.attachments }}
                    isLastStep={this.isLastStep}
                    onSubmit={this.submit}
                    step="customer"
                    withMyInfoError={withMyInfoError}
                />
            </>
        );
    }
}

export default KYCStep;
