import { useApolloClient } from '@apollo/client';
import React, { useState, useCallback, ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { attachLoading } from '../../../../actions';
import {
    getProceedWithCustomerDeviceToken,
    GetProceedWithCustomerDeviceTokenQuery,
    GetProceedWithCustomerDeviceTokenQueryVariables,
} from '../../../../api/application.graphql';
import { RemoteFlow } from '../../../../schema';
import { handleResponseError } from '../../../../utilities/forms';
import PublicSessionTimer from '../../../shared/PublicSessionTimer';

export type ShieldCallback = (values: { code: string }) => Promise<void>;

export type ShieldProps = {
    applicationId: string;
    insuranceCompanyName: string;
    flow: RemoteFlow;
    handlerComponent: ComponentType<{ token: string; submitted: boolean }>;
    validationComponent: ComponentType<{ insuranceCompanyName: string; onSubmit: ShieldCallback }>;
    submitted: boolean;
};

const Shield = ({
    applicationId,
    insuranceCompanyName,
    flow,
    handlerComponent: Handler,
    validationComponent: Validation,
    submitted,
}: ShieldProps) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [token, setToken] = useState<string | null>(null);
    const { pathname } = useLocation();

    // callback to run the validation
    const onNext: ShieldCallback = useCallback(
        async ({ code }) => {
            try {
                const promise = client.query<
                    GetProceedWithCustomerDeviceTokenQuery,
                    GetProceedWithCustomerDeviceTokenQueryVariables
                >({
                    query: getProceedWithCustomerDeviceToken,
                    variables: {
                        id: applicationId,
                        passphrase: code,
                        flow,
                    },
                    fetchPolicy: 'no-cache',
                });

                dispatch(attachLoading(promise));

                const { data } = await promise;
                const token = data?.token;

                if (token) {
                    setToken(token);
                }
            } catch (error) {
                handleResponseError(error as Error);
            }
        },
        [dispatch, applicationId, client, flow]
    );

    if (!token) {
        // we must go through the validation
        return <Validation insuranceCompanyName={insuranceCompanyName} onSubmit={onNext} />;
    }

    // can access the main flow
    return (
        <>
            <PublicSessionTimer url={pathname} />
            <Handler submitted={submitted} token={token} />;
        </>
    );
};

export default Shield;
