import { Dictionary } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { getGlobalPermissions } from '../../selectors';
import ConfiguratorRoute from '../routes/ConfiguratorRoute/ConfiguratorRoute';
import CsvRoute from '../routes/CsvRoute';
import FinderRoute from '../routes/FinderRoute';
import ProfileRoute from '../routes/ProfileRoute';
import TimeoutRoute from '../routes/TimeoutRoute';
import CarListRoute from '../routes/wip/CarListRoute';
import DefaultRoute from '../routes/wip/DefaultRoute';
import DraftRoute from '../routes/wip/DraftRoute';
import ExpressRoute from '../routes/wip/ExpressRoute';
import InsuranceDraftRoute from '../routes/wip/InsuranceDraftRoute';
import NewRoute from '../routes/wip/NewRoute';
import UsedCarListRoute from '../routes/wip/UsedCarListRoute';
import UsedRoute from '../routes/wip/UsedRoute';
import Wrapper from '../shared/Wrapper';
import ApplicationRouter from './ApplicationRouter';
import AppointmentRouter from './AppointmentRouter';
import CompanyLoginRouter from './CompanyLoginRouter';
import CustomerRouter from './CustomerRouter';
import EventRouter from './EventRouter';
import InsuranceRouter from './InsuranceRouter';
import LeadRouter from './LeadRouter';
import ReservationRouter from './ReservationRouter';
import { prefixCompanyPath as prefixPath } from './shared';

type MainRouterProps = {
    maySeeLogin: boolean;
    isAuthenticated: boolean;
};

const redirectTo = (suffix: string) => (props: {
    match: { path?: string; params: { companyCode: string; locationCode: string } };
}) => (
    <Redirect
        from={props.match.path}
        to={`/${props.match.params.companyCode}/${props.match.params.locationCode}/${suffix}`}
    />
);

const renderWithHeader = (permissions: Dictionary<boolean>, isAuthenticated: boolean) => {
    const {
        mayViewLead,
        mayViewApplication,
        mayViewReservation,
        mayViewInsuranceApplication,
        mayViewAppointment,
    } = permissions;

    return () => (
        <Wrapper>
            <Switch>
                {isAuthenticated && <Route component={ProfileRoute} path={prefixPath('/profile')} />}
                <Route component={ExpressRoute} path={prefixPath('/preowned/calculator')} exact />
                <Route path={prefixPath('/preowned/calculator')} render={redirectTo('preowned/calculator')} />
                <Route component={NewRoute} path={prefixPath('/new/calculator/:variantId')} exact />
                <Route component={UsedRoute} path={prefixPath('/used/calculator/:variantId/:unitId')} exact />
                {mayViewLead && <Route component={LeadRouter} path={prefixPath('/leads')} />}
                {mayViewApplication && <Route component={ApplicationRouter} path={prefixPath('/applications')} />}
                {mayViewReservation && <Route component={ReservationRouter} path={prefixPath('/reservations')} />}
                {mayViewInsuranceApplication && (
                    <Route component={InsuranceRouter} path={prefixPath('/insuranceApplications')} />
                )}
                {mayViewAppointment && <Route component={AppointmentRouter} path={prefixPath('/appointments')} />}
                <Route component={ConfiguratorRoute} path={prefixPath('/configurator')} exact />
                <Route component={FinderRoute} path={prefixPath('/finder/:listingId')} exact />
                <Route component={CsvRoute} path={prefixPath('/csv/:porscheCode')} exact />
                <Route component={EventRouter} path={prefixPath('/event')} />
                <Route component={DraftRoute} path={prefixPath('/apply/:applicationVersionId')} exact />
                <Route
                    component={InsuranceDraftRoute}
                    path={prefixPath('/insuranceApply/:applicationVersionId')}
                    exact
                />
                <Route component={CustomerRouter} path={prefixPath('/customers')} />
                <Route component={CarListRoute} path={prefixPath('/new')} exact />
                <Route component={UsedCarListRoute} path={prefixPath('/used')} exact />
                <Route path={prefixPath('/new')} render={redirectTo('new')} />
                <Route path={prefixPath('/used')} render={redirectTo('used')} />
                <Route component={TimeoutRoute} path={prefixPath('/timeout')} />
                <Redirect path={prefixPath('/')} to={prefixPath('/new')} exact />
                <Route component={DefaultRoute} />
            </Switch>
        </Wrapper>
    );
};

const MainRouter = ({ maySeeLogin, isAuthenticated }: MainRouterProps) => {
    const permissions = useSelector(getGlobalPermissions);

    // this router is for either case :
    // * the user has logged in
    // * the zone allows public access
    return (
        <Switch>
            {maySeeLogin && <Route component={CompanyLoginRouter} path={prefixPath('/login')} />}
            <Route render={renderWithHeader(permissions, isAuthenticated)} />
        </Switch>
    );
};

export default MainRouter;
