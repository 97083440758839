import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getIsAuthenticated } from '../../../selectors';
import { PRIVATE_ACCESS } from '../../../utilities/constants/access';
import { getCompanyLoginUrl } from '../../../utilities/urls';
import PaymentCallbackAction from './PaymentCallbackAction';
import SigningCallbackAction from './SigningCallbackAction';

const RedirectRoute = () => {
    // we need both the history object and location
    const location = useLocation();
    const history = useHistory();

    // extract parameters from the URL query
    const queryParameters = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { access, resultCode } = queryParameters;

    // define if we are on private or public access
    const isPrivateAccess = access?.toLowerCase() === PRIVATE_ACCESS;
    // we need to know the current user status
    const isAuthenticated = useSelector(getIsAuthenticated);

    if (isPrivateAccess && !isAuthenticated) {
        // need to authenticate first
        history.pushWithCompanyAndState(getCompanyLoginUrl, {
            // we want the user to come back here after login
            redirect: `${location.pathname}${location.search}`,
        });

        return null;
    }

    if (resultCode) {
        return <PaymentCallbackAction isAuthenticated={isAuthenticated} {...queryParameters} />;
    }

    // pass over all information we got from he query parameters
    return <SigningCallbackAction isAuthenticated={isAuthenticated} {...queryParameters} />;
};

export default RedirectRoute;
