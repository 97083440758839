import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppointmentListRoute, AppointmentDetailsRoute } from '../routes/AppointmentRoute';
import Header from '../shared/Header';
import { prefixAppointmentPath } from './shared';
import useNotPaidBackAction from './useNotPaidBackAction';
import useNotSignedBackAction from './useNotSignedBackAction';

const AppointmentRouter = () => {
    useNotSignedBackAction();
    useNotPaidBackAction();

    return (
        <>
            <Header />
            <Switch>
                <Route component={AppointmentListRoute} path={prefixAppointmentPath('/')} exact />
                <Route component={AppointmentDetailsRoute} path={prefixAppointmentPath('/:versionId')} exact />
                <Redirect to={prefixAppointmentPath('/')} />
            </Switch>
        </>
    );
};

export default AppointmentRouter;
