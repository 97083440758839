import React, { ReactElement, useCallback, useState, useMemo } from 'react';
import ReactBnbGallery from 'react-bnb-gallery';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import useMedia from 'use-media';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Block, SubTitle, Container } from '../ui';
import 'react-bnb-gallery/dist/style.css';

const GalleryGrid = styled.div`
    position: relative;
`;

type GalleryContainerProps = {
    imageCount: number;
};

const GalleryContainer = styled.div<GalleryContainerProps>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: center;
    justify-content: center;

    .thumbnail-button {
        border: 0;
    }

    ${GalleryGrid}.large:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    ${GalleryGrid}.large:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    ${GalleryGrid}.mask > img {
        filter: brightness(0.5);
    }

    @media (max-width: ${breakpoints.sm}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);

        ${GalleryGrid}.large:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        ${props =>
            props.imageCount === 2 &&
            css`
                grid-template-rows: 1fr;
                ${GalleryGrid}.large:nth-child(1) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }
                ${GalleryGrid}.large:nth-child(2) {
                    grid-column-start: 2;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }
            `}
    }
`;

const GalleryImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const HiddenImagesIndicatorContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    left: 0;
    top: 0;
    cursor: pointer;
`;

const HiddenImagesIndicator = styled.span`
    color: #fff;
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

export type GalleryImageType = { url: string; id: any };

export type GalleryProps = {
    images: GalleryImageType[];
};

const Gallery = ({ images }: GalleryProps): ReactElement => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const openGallery = useCallback(
        index => {
            setIsOpen(true);
            setImageIndex(index);
        },
        [setIsOpen, setImageIndex]
    );

    const isDesktop = useMedia({ minWidth: breakpoints.md });
    const imageCount = useMemo(() => images?.length, [images]);
    const desktopLimit = useMemo(() => (imageCount < 5 ? 2 : 5), [imageCount]);
    const shownImages = useMemo(() => (isDesktop ? desktopLimit : 3), [desktopLimit, isDesktop]);

    const galleryImages = useMemo(
        () =>
            images?.map((image: GalleryImageType, index: number) => {
                if (index > shownImages - 1) {
                    return null;
                }

                const maskImage = index === shownImages - 1 && imageCount > index + 1;
                const largeImage = index === 0 || (index === 1 && imageCount > 1 && imageCount < 5 && isDesktop);

                return (
                    <GalleryGrid key={index.toString()} className={`${largeImage && 'large'} ${maskImage && ' mask'}`}>
                        <GalleryImage onClick={() => openGallery(index)} src={image.url} />
                        {maskImage && (
                            <HiddenImagesIndicatorContainer onClick={() => openGallery(index)}>
                                <HiddenImagesIndicator>+{imageCount - shownImages + 1}</HiddenImagesIndicator>
                            </HiddenImagesIndicatorContainer>
                        )}
                    </GalleryGrid>
                );
            }),
        [imageCount, images, isDesktop, openGallery, shownImages]
    );

    const photos = useMemo(() => images.map(image => image.url), [images]);

    return (
        <Block id="section-gallery">
            <Container>
                <SubTitle>{t('usedCalculatorPage.galleryTitle')}</SubTitle>
                <GalleryContainer imageCount={imageCount}>{galleryImages}</GalleryContainer>
                <ReactBnbGallery
                    activePhotoIndex={imageIndex}
                    onClose={() => setIsOpen(false)}
                    photos={photos}
                    show={isOpen}
                />
            </Container>
        </Block>
    );
};

export default Gallery;
