import styled from 'styled-components';

const FootBar = styled.div`
    background: transparent;
    border-top: 1px solid #dedede;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 30px;

    @media (max-width: 450px) {
        margin: 0 15px;
    }
`;

FootBar.Action = styled.button`
    text-transform: ${props => props.theme.casing};
    outline: none;
    height: 35px;
    font-size: 0.72rem;
    border: 1px solid #ced4da;
    color: #000000;
    background: transparent;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
`;

export default FootBar;
