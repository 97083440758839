export const prefixCompanyPath = (path: string) => `/:companyCode/:locationCode${path}`;

export const prefixLoginPath = (path: string) => prefixCompanyPath(`/login${path}`);

export const prefixLeadPath = (path: string) => prefixCompanyPath(`/leads${path}`);

export const prefixReservationPath = (path: string) => prefixCompanyPath(`/reservations${path}`);

export const prefixApplicationPath = (path: string) => prefixCompanyPath(`/applications${path}`);

export const prefixEventPath = (path: string) => prefixCompanyPath(`/event${path}`);

export const prefixCustomerPath = (path: string) => prefixCompanyPath(`/customers${path}`);

export const prefixInsurancePath = (path: string) => prefixCompanyPath(`/insuranceApplications${path}`);

export const prefixAppointmentPath = (path: string) => prefixCompanyPath(`/appointments${path}`);
