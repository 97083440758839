import React from 'react';
import { CalculatorValues } from '../../../components/shared/calculator-next/types';
import { ApplicationFlowSource } from '../../../schema';
import { FlowStep } from '../../utils/flow';
import { ConfiguratorFlowState } from '../ConfiguratorFlow';
import CKDMetaTitle from '../components/Configurator/shared/CKDMetaTitle';
import ResumeOrder from '../components/ResumeOrder';

export type ResumeOrderStepValues = Partial<CalculatorValues>;

class ResumeOrderStep extends FlowStep<ConfiguratorFlowState, ResumeOrderStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return ApplicationFlowSource.RESUME;
    }

    // eslint-disable-next-line class-methods-use-this
    public get ignoreOnBack(): boolean {
        return true;
    }

    public render(): React.ReactElement | null {
        const {
            interiorColor,
            exteriorColor,
            enhancedPackage,
            tequipment,
            timepiece,
            delivery,
            variantId,
            defaultFinanceProduct,
        } = this.state;

        const values = {
            interiorColor,
            exteriorColor,
            enhancedPackage,
            tequipment,
            timepiece,
            delivery,
            variantId,
        };

        return (
            <>
                <CKDMetaTitle />
                <ResumeOrder defaultFinanceProduct={defaultFinanceProduct} onSubmit={this.submit} values={values} />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute(calculatorValues: ResumeOrderStepValues) {
        this.stateStore.update({ calculatorValues });

        return this.nextStep;
    }
}

export default ResumeOrderStep;
