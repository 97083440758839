/* eslint-disable no-underscore-dangle, class-methods-use-this, max-classes-per-file */
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { History } from 'history';
import { TFunction, i18n } from 'i18next';
import PubSub from 'pubsub-js';
import { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../actions';
import { ContentTranslation, useContentTranslation } from '../../i18n';
import { handleResponseError } from '../../utilities/forms';

export class FlowStore<State = any> {
    private storedData: State;

    private onUpdates: () => void;

    constructor(initialState: State, onUpdates: () => void) {
        this.storedData = initialState;
        this.onUpdates = onUpdates;
    }

    public get data(): State {
        return this.storedData;
    }

    public update(nexState: Partial<State>): void {
        this.storedData = { ...this.storedData, ...nexState };
        this.onUpdates();
    }
}

export type BackStepContext = { label: string; goTo: () => void; identifier: string };

export class FlowStep<State = any, ExecutionValues = any> {
    protected readonly stateStore: FlowStore<State>;

    protected readonly dispatch: ThunkDispatch<any, any, any>;

    protected readonly apolloClient: ApolloClient<NormalizedCacheObject>;

    protected readonly flow: Flow<State>;

    protected readonly t: TFunction;

    protected readonly contentTranslation: ContentTranslation;

    protected readonly i18n: i18n;

    protected submitPromise: Promise<void> | null;

    constructor(
        stateStore: FlowStore<State>,
        flow: Flow<State>,
        dispatch: ThunkDispatch<any, any, any>,
        apolloClient: ApolloClient<NormalizedCacheObject>,
        t: TFunction,
        i18n: i18n,
        contentTranslation: ContentTranslation
    ) {
        this.stateStore = stateStore;
        this.dispatch = dispatch;
        this.apolloClient = apolloClient;
        this.flow = flow;
        this.t = t;
        this.i18n = i18n;
        this.contentTranslation = contentTranslation;
        this.submit = this.submit.bind(this);
        this.goTo = this.goTo.bind(this);
        this.submitPromise = null;
    }

    public get state(): State {
        return this.stateStore.data;
    }

    public get label(): string {
        return '';
    }

    public get identifier(): string {
        throw new Error('NotImplemented');
    }

    public get isShadowStep(): boolean {
        return false;
    }

    public get ignoreOnBack(): boolean {
        return this.isShadowStep;
    }

    public get nextStep(): FlowStep<State> | null {
        return this.flow.getNextStep(this.identifier);
    }

    public get isLastStep(): boolean {
        const { nextStep } = this;

        if (!nextStep) {
            return true;
        }

        if (!nextStep.isShadowStep) {
            return false;
        }

        return nextStep.isLastStep;
    }

    public render(): ReactElement | null {
        return null;
    }

    protected async executeBefore(): Promise<FlowStep<State>> {
        // executed when the previous step is completed
        return this;
    }

    protected goTo() {
        this.flow.setActiveStep(this.identifier);
    }

    public getBackContext(): BackStepContext | undefined {
        const step = this.flow.getBackStep(this.identifier);

        if (step) {
            return { label: step.label, goTo: step.goTo, identifier: step.identifier };
        }

        return undefined;
    }

    public get isCompleted() {
        return false;
    }

    protected async execute(values: ExecutionValues): Promise<FlowStep<State> | null> {
        // executed when the step is completed
        return this.nextStep;
    }

    public async submit(values: ExecutionValues): Promise<void> {
        if (this.submitPromise) {
            // already being submitted
            return this.submitPromise;
        }

        const handler = async (): Promise<void> => {
            let nextStep = await this.execute(values);

            while (nextStep) {
                // eslint-disable-next-line no-await-in-loop
                const activeStep = await nextStep.executeBefore();

                if (activeStep === nextStep) {
                    this.flow.setActiveStep(activeStep.identifier);
                    break;
                }

                nextStep = activeStep;
            }
        };

        try {
            this.submitPromise = this.dispatch<Promise<void>>(
                attachLoading(handler().catch(this.onSubmitError.bind(this)))
            );

            await this.submitPromise;
        } finally {
            this.submitPromise = null;
        }

        return Promise.resolve();
    }

    protected async onSubmitError(error: any): Promise<void> {
        console.error(error);
    }
}

export class ReduxFormFlowStep<State = any, ExecutionValues = any> extends FlowStep<State, ExecutionValues> {
    protected async onSubmitError(error: any): Promise<void> {
        console.error(error);

        return handleResponseError(error);
    }
}

export interface FlowStepType<State, EValues> {
    new (
        stateStore: FlowStore<State>,
        flow: Flow<State>,
        reduxStore: ThunkDispatch<any, any, any>,
        apolloClient: ApolloClient<NormalizedCacheObject>,
        t: TFunction,
        i18n: i18n,
        contentTranslation: ContentTranslation
    ): FlowStep<State, EValues>;
}

export class Flow<State = any> {
    protected readonly stateStore: FlowStore<State>;

    protected readonly dispatch: ThunkDispatch<any, any, any>;

    protected steps: FlowStep<State>[];

    protected _currentStep: FlowStep<State>;

    protected readonly pubSubId: string;

    protected readonly apolloClient: ApolloClient<NormalizedCacheObject>;

    public readonly history: History;

    public readonly t: TFunction;

    public readonly contentTranslation: ContentTranslation;

    public readonly i18n: i18n;

    constructor(
        initialState: State,
        pubSubId: string,
        dispatch: ThunkDispatch<any, any, any>,
        apolloClient: ApolloClient<NormalizedCacheObject>,
        history: History,
        t: TFunction,
        i18n: i18n,
        contentTranslation: ContentTranslation
    ) {
        // apollo client and redux dispatch
        this.apolloClient = apolloClient;
        this.dispatch = dispatch;
        this.history = history;
        this.t = t;
        this.i18n = i18n;
        this.contentTranslation = contentTranslation;

        // create state store
        this.stateStore = new FlowStore<State>(initialState, () => {
            this.steps = this.plannify();
            PubSub.publish(`${pubSubId}.updated`, this);
        });

        // initial steps
        this.steps = this.plannify();
        this._currentStep = this.initialize();
        this.pubSubId = pubSubId;

        PubSub.publish(`${pubSubId}.initialized`, this);
    }

    protected initialize(): FlowStep<State> {
        return this.steps[0];
    }

    public updateState(updates: Partial<State>): void {
        this.stateStore.update(updates);
    }

    public updateCacheKey(): void {
        PubSub.publish(`${this.pubSubId}.updateCacheKey`, this);
    }

    public dispatchCompleted(): void {
        PubSub.publish(`${this.pubSubId}.completed`, this);
    }

    protected createStep<EValues = any>(StepClass: FlowStepType<State, EValues>): FlowStep<State, EValues> {
        const step = new StepClass(
            this.stateStore,
            this,
            this.dispatch,
            this.apolloClient,
            this.t,
            this.i18n,
            this.contentTranslation
        );

        if (!this.steps) {
            return step;
        }

        return this.steps.find(existingStep => existingStep.identifier === step.identifier) || step;
    }

    public hasStep(identifier: string): boolean {
        return !!this.steps.find(step => step.identifier === identifier);
    }

    public setActiveStep(identifier?: string): void {
        const nextStep = this.steps.find(step => step.identifier === identifier);

        if (nextStep) {
            // update the step
            this._currentStep = nextStep;
            // publish it
            PubSub.publish(`${this.pubSubId}.updated`, this);
        }
    }

    public get currentStep(): FlowStep<State> {
        return this._currentStep;
    }

    public getStep(identifier: string): FlowStep<State> | undefined {
        return this.steps.find(step => step.identifier === identifier);
    }

    public getBackStep(identifier?: string): FlowStep<State> | null {
        let currentIndex = this.steps.findIndex(
            step => step.identifier === (identifier || this.currentStep.identifier)
        );

        while (currentIndex > 0) {
            // move back in the indexes by one
            currentIndex -= 1;
            // get the step
            const previousStep = this.steps[currentIndex];

            if (!previousStep.ignoreOnBack) {
                // this is it
                return previousStep;
            }
        }

        return null;
    }

    public getNextStep(identifier?: string): FlowStep<State> | null {
        const currentIndex = this.steps.findIndex(
            step => step.identifier === (identifier || this.currentStep.identifier)
        );

        if (currentIndex < 0) {
            return null;
        }

        return this.steps[currentIndex + 1] || null;
    }

    public get state(): State {
        return this.stateStore.data;
    }

    protected plannify(): FlowStep<State>[] {
        return [];
    }
}

export interface FlowType<State, Output> {
    new (
        initialState: State,
        pubSubId: string,
        dispatch: ThunkDispatch<any, any, any>,
        apolloClient: ApolloClient<NormalizedCacheObject>,
        history: History,
        t: TFunction,
        i18n: i18n,
        contentTranslation: ContentTranslation
    ): Output;
}

export const useManagedFlow = <State extends any, Output extends Flow<State>>(
    FlowImplementation: FlowType<State, Output>,
    initialState: State,
    pubSubChannel: string
): { flow: Output; step: FlowStep<State>; revision: number } => {
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const flowRef = useRef<Output | null>(null);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const contentTranslation = useContentTranslation();

    if (flowRef.current === null) {
        flowRef.current = new FlowImplementation(
            initialState,
            pubSubChannel,
            dispatch,
            apolloClient,
            history,
            t,
            i18n,
            contentTranslation
        );
    }

    const { current: flow } = flowRef;

    const [revision, setRevision] = useState(0);

    useEffect(() => {
        const token = PubSub.subscribe(`${pubSubChannel}.updated`, () => {
            setRevision(state => state + 1);
        });

        return () => {
            PubSub.unsubscribe(token);
        };
    }, [flow, pubSubChannel]);

    if (!flow) {
        throw new Error('Flow not created in state');
    }

    return { flow, step: flow.currentStep, revision };
};

export const useScrollTop = (identifier: string) => {
    // scroll to top whenever step changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [identifier]);
};
