import React from 'react';
import { ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';
import ThankYou from '../components/ThankYou/ThankYou';

class EventThankYouStep extends ReduxFormFlowStep<EventFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'thankYou';
    }

    public async executeBefore(): Promise<ReduxFormFlowStep<EventFlowState>> {
        // trigger events
        this.flow.dispatchCompleted();

        return this;
    }

    public render(): React.ReactElement | null {
        const {
            variant,
            event,
            channel,
            calculator,
            carOfInterest,
            miniConfiguratorDetails,
            finderVehicle,
            dealerId,
            csvConfigurator,
            isCalculatorEnabled,
        } = this.state;

        if (!event) {
            throw new Error('Event missing in state');
        }

        if (!dealerId) {
            // we need dealer id when fetching variants
            throw new Error('dealer id missing in state');
        }

        return (
            <ThankYou
                calculator={calculator}
                carOfInterest={carOfInterest}
                channel={channel}
                csvConfigurator={csvConfigurator}
                dealerId={dealerId}
                event={event}
                finderVehicle={finderVehicle}
                isCalculatorEnabled={isCalculatorEnabled}
                miniConfiguratorDetails={miniConfiguratorDetails}
                variant={variant}
            />
        );
    }
}

export default EventThankYouStep;
