import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, Redirect } from 'react-router';
import { logout } from '../../actions/shared';
import { getCompanyIdentifier } from '../../selectors';
import { getMaintenanceUrl } from '../../utilities/urls';
import MaintenanceRoute, { MaintenanceRouteProps } from '../routes/MaintenanceRoute';
import { prefixCompanyPath as prefixPath } from './shared';

type MaintenanceRouterProps = MaintenanceRouteProps;

const MaintenanceRouter = (props: MaintenanceRouterProps) => {
    const dispatch = useDispatch();
    const path = prefixPath('/maintenance');
    const isMatch = useRouteMatch(path);
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    useEffect(() => {
        dispatch(logout(null, true));
    }, [dispatch]);

    if (!isMatch) {
        return <Redirect to={getMaintenanceUrl(companyCode, locationCode)} />;
    }

    return <MaintenanceRoute {...props} />;
};

export default MaintenanceRouter;
