import {
    CalculatorContext,
    DualItem,
    FirstCell,
    Label,
    LabelWithoutEllipsis,
    SecondCell,
    Value,
} from '@appvantageasia/afc-calculator-ui-next';
import { get, isNil } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../i18n';
import useCompanyFormatting from '../../../utilities/useCompanyFormatting';
import { CalculatorInsuranceValues, CalculatorValues } from '../types';
import EditionCarPriceField from './edition/CarPriceField';
import EditionVariantField from './edition/VariantField';

export type CarModelPriceFieldProps = {
    fieldKey: string;
    size: number;
    financeContext: CalculatorContext<CalculatorValues>;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
    disabled?: boolean;
};

const CarModelPriceField = ({
    financeContext,
    insuranceContext,
    size,
    disabled = false,
    ...props
}: CarModelPriceFieldProps) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const { formatCurrency } = useCompanyFormatting();

    const inventories = financeContext.getFieldContext('inventories');
    const stocks = inventories?.remainingStock;
    const { values } = financeContext;

    const [showEdition, setShowEdition] = useState<'carPrice' | 'variant' | null>(null);

    const renderEdition = useCallback(
        close => {
            const editionProps = {
                insuranceContext,
                financeContext,
                close,
            };

            switch (showEdition) {
                case 'variant':
                    return <EditionVariantField {...editionProps} labelTKey="calculator.label.carModel" />;

                case 'carPrice':
                    return <EditionCarPriceField {...editionProps} labelTKey="calculator.label.carPrice" />;

                default:
                    return null;
            }
        },
        [financeContext, insuranceContext, showEdition]
    );

    const { show, close } = useMemo(
        () => ({
            show: (key: 'carPrice' | 'variant') => setShowEdition(key),
            close: () => setShowEdition(null),
        }),
        [setShowEdition]
    );
    const carPrice = get('carPrice', values);
    const variantContext = financeContext.getFieldContext('variant');

    return (
        <>
            <DualItem editable={!disabled}>
                <FirstCell onClick={() => show('variant')}>
                    <Value disabled={disabled}>{ct(variantContext?.selectedVariant?.name) ?? '-'}</Value>
                    {!isNil(stocks) ? <Label>{t('calculator.label.carModelStock', { stocks })}</Label> : null}
                </FirstCell>
                <SecondCell onClick={() => show('carPrice')}>
                    <LabelWithoutEllipsis>{t('calculator.label.carPrice')}</LabelWithoutEllipsis>
                    <Value disabled={disabled}>{formatCurrency(carPrice)}</Value>
                </SecondCell>
            </DualItem>
            {showEdition && !disabled && renderEdition(close)}
        </>
    );
};

export default CarModelPriceField;
