import { TFunction } from 'i18next';
import { isEmpty } from 'lodash/fp';
import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stopSubmit, ReduxFormContext, untouch, touch, getFormSyncErrors } from 'redux-form';
import * as yup from 'yup';
import useFormValues from '../../../../../components/utilities/useFormValues';
import useValidation from '../../../../../components/utilities/useValidation';
import useValidationContext from '../../../../../components/utilities/useValidationContext';
import { getRuntimeSettings } from '../../../../../selectors';
import { flattenKeys } from '../../../../../utilities/fp';

import { Application } from '../../../types';

const useSaveDraftValidation = (schema: (t: TFunction) => yup.Schema<{}>, application?: Application) => {
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const schemaValidate = useValidation(schema);
    const validation = useValidationContext();
    const { form } = useContext(ReduxFormContext);
    const formErrors = useSelector(getFormSyncErrors(form));
    const dispatch = useDispatch();
    const values = useFormValues();

    return useCallback(() => {
        // validate the values
        const errors = schemaValidate(values, { useCustomerBirthDay, useCustomerNationality, validation, application });
        // then we manually untouch the previous errors to hide existing errors
        dispatch(untouch(form, ...Object.keys(flattenKeys(formErrors))));
        // we set new errors based on draft validation
        dispatch(stopSubmit(form, errors));
        // then we touch the fields to show
        dispatch(touch(form, ...Object.keys(flattenKeys(errors))));

        return isEmpty(errors);
    }, [
        schemaValidate,
        values,
        useCustomerBirthDay,
        useCustomerNationality,
        validation,
        application,
        dispatch,
        form,
        formErrors,
    ]);
};

export default useSaveDraftValidation;
