import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Message, MessageTitle } from '../../../components/ui/calculator';

const SubmittedPage = () => {
    const { t } = useTranslation();
    const { title, message }: { title: string; message: string } = t('notification.guarantorDetailsSubmitted', {
        returnObjects: true,
    });

    return (
        <Container>
            <MessageTitle>{title}</MessageTitle>
            <Message>{message}</Message>
        </Container>
    );
};

export default SubmittedPage;
