import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type LoadingLayerProps = {
    className?: string;
};

const LoadingLayer = ({ className }: LoadingLayerProps) => (
    <div className={className}>
        <div className="bg-mask" />
        <div className="bg">
            <div className="spinner__container">
                <FontAwesomeIcon color="black" icon={faSpinner} size="2x" spin />
            </div>
        </div>
    </div>
);

export default styled(LoadingLayer)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    .bg-mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: white;
        opacity: 0.1;
        z-index: 1;
    }

    .bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
    .spinner__container {
        padding: 12px;
        opacity: 0.5;
        background: white;
        border-radius: 5px;
    }
`;
