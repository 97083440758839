import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, fieldInputPropTypes } from 'redux-form';
import { useTheme } from 'styled-components';
import { Channel } from '../../../schema';
import CheckboxContainer from '../../ui/form/Checkbox';
import { ReactComponent as Checked } from '../../../assets/images/boxChecked.svg';
import { ReactComponent as Unchecked } from '../../../assets/images/boxUnchecked.svg';

export const CheckboxInput = ({ input, label, disabled, channel = Channel.NEW, margin }) => {
    const theme = useTheme();
    const { value = false, onChange, name } = input;
    const boolValue = Boolean(value);

    const onClick = useCallback(
        event => {
            event.preventDefault();
            onChange(!boolValue);

            return false;
        },
        [onChange, boolValue]
    );

    const isV2Design = channel === Channel.USED || channel === Channel.EVENT;

    const checkIcon = isV2Design ? <Icon icon={faCheck} /> : <Checked fill={theme.themeHighlightColour} />;

    return (
        <CheckboxContainer
            disabled={disabled}
            htmlFor={name}
            isV2Design={isV2Design}
            margin={margin}
            onClick={!disabled ? onClick : undefined}
        >
            <CheckboxContainer.Checkbox checked={boolValue} isV2Design={isV2Design}>
                {boolValue ? checkIcon : <Unchecked />}
                <input checked={boolValue} name={name} readOnly />
            </CheckboxContainer.Checkbox>
            <CheckboxContainer.Label isV2Design={isV2Design}>{label}</CheckboxContainer.Label>
        </CheckboxContainer>
    );
};

CheckboxInput.propTypes = {
    channel: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    margin: PropTypes.string,
};

const CheckboxField = props => <Field {...props} component={CheckboxInput} />;

export default CheckboxField;
