import { useQuery } from '@apollo/client';
// @ts-ignore
import { Footer, Paging, TableList } from '@appvantageasia/afc-ui';
import { flow, get } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useContentTranslation } from '../../../../i18n';
import { SortOrder } from '../../../../schema';
import { getZoneId } from '../../../../selectors';
import useLoading from '../../../../useLoading';
import { appointmentStatusOptions } from '../../../../utilities/constants/options';
import { getAppointmentUrl } from '../../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../../shared/HelmetTitle';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { renderChannelOption } from '../../../ui/Cells';
import PageContainer, { Title, Content } from '../../../ui/PageContainer';
import { TextAlignCenter } from '../../../ui/TextAlign';
import useListContext, { useListContextFromState } from '../../../utilities/useListContext';
import useSearch from '../../../utilities/useSearch';
import useServerPaging from '../../../utilities/useServerPaging';
import useSorting from '../../../utilities/useSorting';
import { AppointmentDataFragment } from '../Appointment.graphql';
import AppointmentDateFilter from './AppointmentDateFilter';
import AppointmentDownload from './AppointmentDownload';
import { getAppointments, GetAppointmentsQuery, GetAppointmentsQueryVariables } from './AppointmentList.graphql';
import { AppointmentListProvider } from './AppointmentListContext';
import { appointmentReducer } from './reducer';

const { FootContainer, FootBar, FootBarRow, FootBarButton } = Footer;

export const getSortOrderEnum = (sortOrder: string) => {
    switch (sortOrder.toLowerCase()) {
        case 'asc':
            return SortOrder.ASC;

        case 'desc':
            return SortOrder.DESC;

        default:
            return SortOrder.ASC;
    }
};

const AppointmentListRoute = () => {
    const { t } = useTranslation();
    const { language, ct, formatPath } = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    // get initial list context
    const [, getInitial] = useListContextFromState();

    const search = useSearch('appointments', getInitial('search'));

    const [stateReducer, dispatch] = useReducer(appointmentReducer, {});

    const { startAppointmentDate, endAppointmentDate } = stateReducer;

    const [paging] = useServerPaging({
        search,
        filter: { startAppointmentDate, endAppointmentDate },
        initialPage: getInitial('paging.page'),
    });

    const sorting = useSorting({
        initialKey: getInitial('sorting.key', 'version.updatedAt'),
        initialOrder: getInitial('sorting.order'),
    });

    // get applications with apollo
    const zoneId = useSelector(getZoneId);
    const dealerId = useMustExistDealerIdFromTenantContext();

    const { data, loading, error: apiError } = useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(
        getAppointments,
        {
            variables: {
                filter: {
                    dealerIds: [dealerId],
                    zoneId,
                    startAppointmentDate,
                    endAppointmentDate,
                },
                search,
                sorting: [
                    {
                        type: sorting && sorting?.key,
                        order: getSortOrderEnum(sorting?.order),
                    },
                ],
                paging: {
                    limit: paging.pageSize,
                    offset: (paging.page - 1) * paging.pageSize,
                },
                locale: language,
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    const items = data?.appointments?.items || [];
    const isLoading = loading && items.length <= 0;

    useLoading(isLoading);

    useEffect(() => {
        paging.setItemCount(data?.appointments?.count || 0);
    }, [paging, data]);

    const columns = useMemo(() => {
        const result = [
            {
                key: 'date',
                sortKey: 'date',
                label: t('appointmentListPage.columns.date'),
                getValue: flow([get('date'), formatDateTime]),
                filter: <AppointmentDateFilter />,
                styles: {
                    paddingRight: 24,
                },
            },
            {
                key: 'identifier',
                sortKey: 'identifier',
                label: t('appointmentListPage.columns.identifier'),
                getValue: get('identifier'),
            },
            {
                key: 'assignee.name',
                sortKey: 'assignee.name',
                label: t('appointmentListPage.columns.assigneeName'),
                getValue: get('assignee.name'),
            },
            {
                key: 'latestApplication.customer.name.value',
                sortKey: 'customer.name.value',
                label: t('appointmentListPage.columns.customerName'),
                getValue: get('latestApplication.customer.name.value'),
            },
            {
                key: 'latestApplication.variant.name',
                sortKey: formatPath('variant.name'),
                label: t('appointmentListPage.columns.variantName'),
                getValue: (value: AppointmentDataFragment) => ct(value.latestApplication.variant.name),
            },
            {
                key: 'status',
                sortKey: 'status',
                label: t('appointmentListPage.columns.status'),
                getValue: (item: AppointmentDataFragment) =>
                    appointmentStatusOptions.find(option => option.value === item.status)?.label,
            },
            {
                key: 'version.updatedAt',
                sortKey: 'version.updatedAt',
                label: t('appointmentListPage.columns.lastActivity'),
                getValue: flow([get('version.updatedAt'), formatDateTime]),
            },
            {
                key: 'latestApplication.channel',
                sortKey: 'application.channel',
                label: t('appointmentListPage.columns.channel'),
                getValue: (value: AppointmentDataFragment) => renderChannelOption(value.latestApplication, t),
            },
            {
                key: 'latestApplication.event.name',
                sortKey: 'event.name',
                label: t('appointmentListPage.columns.eventName'),
                getValue: get('latestApplication.event.name'),
            },
        ];

        return result;
    }, [ct, formatDateTime, formatPath, t]);

    // make a context to bump/restore those configuration
    const listContext = useListContext(paging, sorting, search);

    // callback on items
    const history = useHistory();
    const onItemClick = useCallback(
        (item: AppointmentDataFragment) =>
            // @ts-ignore
            history.pushWithCompanyAndState(
                getAppointmentUrl,
                { listContext, previous: t('appointmentDetailPage.button.back') },
                item.version.id
            ),
        [history, listContext, t]
    );

    const [downloadModal, setDownloadModal] = useState<React.ReactNode>(null);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () => setDownloadModal(<AppointmentDownload onClose={closeDownloadModal} />),
        [setDownloadModal, closeDownloadModal]
    );

    const renderMessage = () => {
        return !isLoading ? <TextAlignCenter>{t('appointmentListPage.noAppointments')}</TextAlignCenter> : null;
    };

    if (apiError) {
        throw apiError;
    }

    return (
        <PageContainer>
            <HelmetTitle title="Appointment List" />
            <Title>{t('appointmentListPage.title')}</Title>
            <AppointmentListProvider dispatch={dispatch} state={stateReducer}>
                <Content>
                    <TableList
                        columns={columns}
                        items={items}
                        onItemClick={onItemClick}
                        paging={paging}
                        sorting={sorting}
                    />
                </Content>
                <Paging />
            </AppointmentListProvider>
            {!items.length && renderMessage()}
            <div style={{ marginBottom: '80px' }} />
            {!!items.length && (
                <FootContainer>
                    <FootBar>
                        <FootBarRow>
                            <div style={{ flex: 1 }} />
                            <FootBarButton onClick={openDownloadModal}>
                                {t('appointmentListPage.button.download')}
                            </FootBarButton>
                        </FootBarRow>
                    </FootBar>
                </FootContainer>
            )}
            {downloadModal}
        </PageContainer>
    );
};

export default AppointmentListRoute;
