import React from 'react';
import SubmittedPage from '../../RemoteFlow/components/SubmittedPage';
import { FlowStep } from '../../utils/flow';
import { InsuranceRemoteFlowState } from '../InsuranceRemoteFlow';

class SubmitStep extends FlowStep<InsuranceRemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'submit';
    }

    public async executeBefore(): Promise<FlowStep<InsuranceRemoteFlowState>> {
        // trigger events
        this.flow.dispatchCompleted();

        return this;
    }

    render() {
        const { insuranceApplication, insuranceCompany } = this.state;

        return (
            <SubmittedPage
                dealerId={insuranceApplication.dealerId}
                remoteFlowAcknowledgmentInfo={insuranceCompany.remoteFlowAcknowledgmentInfo}
            />
        );
    }
}

export default SubmitStep;
