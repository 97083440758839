import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculatorValues } from '../../components/shared/calculator-next/types';
import { useCountry } from '../../hookSelectors';
import OptionsCheckModal from './OptionsCheckModal';

export type CheckOptionsRemovalValues = { calculator: Partial<CalculatorValues> };

export type CheckOptionsRemoval<TValues extends CheckOptionsRemovalValues> = (values: TValues) => Promise<boolean>;

const CheckOptionsRemovalContext = createContext<CheckOptionsRemoval<CheckOptionsRemovalValues> | null>(null);

export const useCheckOptionsRemoval = <TValues extends CheckOptionsRemovalValues>() => {
    const context = useContext(CheckOptionsRemovalContext);

    if (context === null) {
        throw new Error('Check optionals removal context not provided');
    }

    return context as CheckOptionsRemoval<TValues>;
};

export type OptionsControlProviderProps = { children: ReactElement | ReactNode };

const OptionsControlProvider = ({ children }: OptionsControlProviderProps) => {
    const { t } = useTranslation();
    const { title, message }: { title: string; message: string } = t('optionsCheckModal', {
        returnObjects: true,
    });
    // get the current country to see if we are using options
    const { channelSetting } = useCountry();
    const { allowOptions } = channelSetting.new;

    // we may have a promise ongoing when waiting for using confirmations regarding options
    const [optionsCheckerPromise, setOptionsCheckerPromise] = useState<((value: boolean) => void) | null>(null);

    // callback to apply verification on options
    const checkOptionsRemoval = useCallback(
        async (values: CheckOptionsRemovalValues) => {
            // options have been disabled and yet we have options on the calculator
            if (!allowOptions && (values?.calculator?.carOptions || []).length > 0) {
                // request user confirmation to proceed with the removal of options
                const shouldApply = await new Promise<boolean>(resolve => setOptionsCheckerPromise(() => resolve));

                // reset the promise to null after choice has been made
                setOptionsCheckerPromise(null);

                return !shouldApply;
            }

            // we can proceed
            return false;
        },
        [allowOptions, setOptionsCheckerPromise]
    );

    // callback on the action removal confirmation
    const ok = useCallback(() => optionsCheckerPromise && optionsCheckerPromise(true), [optionsCheckerPromise]);
    const close = useCallback(() => optionsCheckerPromise && optionsCheckerPromise(false), [optionsCheckerPromise]);

    return (
        <CheckOptionsRemovalContext.Provider value={checkOptionsRemoval}>
            {children}
            {optionsCheckerPromise && <OptionsCheckModal close={close} message={message} ok={ok} title={title} />}
        </CheckOptionsRemovalContext.Provider>
    );
};

export default OptionsControlProvider;
