import { pick } from 'lodash/fp';
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PaymentWidgetContainer from '../../../../components/ui/PaymentWidgetContainer';
import { useCountry } from '../../../../hookSelectors';
import useLoading from '../../../../useLoading';
import { formatLocale } from '../Deposit/hooks/useAdyen';
import { PaymentData, PorschePaymentConfig } from './usePorschePayment';

type PaymentWidgetProps = {
    config: PorschePaymentConfig;
    onPaymentUpdate: (data: PaymentData) => void;
};

const countryCodeMapping: { [key: string]: string } = {
    DU: 'AE',
};

const PaymentWidget = ({ config, onPaymentUpdate }: PaymentWidgetProps) => {
    const { i18n } = useTranslation();

    const locale = formatLocale(i18n.languages[0]);

    const [loading, setLoading] = useState(true);
    useLoading(loading);

    const { code: countryCode, currency } = useCountry();

    const widget = useRef<HTMLElement>();

    const paymentMethodSelectedHandler = useCallback(
        event => {
            // publish only when detail has value
            // detail will only a value when all fields are satisfied
            if (event.detail) {
                onPaymentUpdate(pick(['paymentMethod', 'strongCustomerAuthenticationData'], event.detail));
            }
        },
        [onPaymentUpdate]
    );

    const assortments = useMemo(
        () =>
            JSON.stringify([
                {
                    assortment: config.assortment,
                    amount: config.amount.value,
                },
            ]),
        [config.amount, config.assortment]
    );

    useEffect(() => {
        let widgetRefValue: HTMLElement | null = null;

        // observer payment-widget element for changes
        const observer = new MutationObserver(mutationList => {
            const paymentWidget = mutationList.find(({ target }) =>
                (target as HTMLElement).className.includes('payment-widget')
            );
            // check until an html element exists
            if (paymentWidget && (paymentWidget.target as HTMLElement).children.length) {
                setLoading(false);
                observer.disconnect();
            }
        });

        if (widget.current) {
            widget.current.addEventListener('paymentMethodSelectedHandler', paymentMethodSelectedHandler);

            observer.observe(widget.current, { childList: true, subtree: true });

            widgetRefValue = widget.current;
        }

        return () => {
            widgetRefValue &&
                widgetRefValue.removeEventListener('paymentMethodSelectedHandler', paymentMethodSelectedHandler);
            observer.disconnect();
        };
    }, [paymentMethodSelectedHandler]);

    const mappedCountryCode = useMemo(() => {
        const updatedCountryCode = countryCodeMapping[countryCode.toUpperCase()];

        // return original country code if it does not exist
        return updatedCountryCode ?? countryCode;
    }, [countryCode]);

    return (
        <>
            <PaymentWidgetContainer>
                <guest-payment-widget
                    ref={widget}
                    apikey={config.clientKey}
                    assortmentswithamounts={assortments}
                    countrycode={mappedCountryCode}
                    currency={currency}
                    environment={config.environment}
                    locale={locale}
                    redirecturl={config.redirectUrl}
                />
            </PaymentWidgetContainer>
            <Helmet>
                <script src={config.widgetUrl} />
            </Helmet>
        </>
    );
};

export default PaymentWidget;
