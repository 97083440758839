import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { useTheme } from 'styled-components';
import useMedia from 'use-media';
import { useFixedMenuController } from '../../../../components/shared/FixedMenuController';
import { useHeaderController } from '../../../../components/shared/HeaderController';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import LoginAccess from '../../../../components/shared/LoginAccess';
import MenuSidebar from '../../../../components/shared/MenuSidebar';
import IconButton from '../../../../components/ui/IconButton';
import HeaderContainer from '../../../../components/ui/header/HeaderContainer';
import HeaderController from '../../../../components/ui/header/HeaderController';
import HeaderLogoButton from '../../../../components/ui/header/HeaderLogoButton';
import HeaderLogoContainer from '../../../../components/ui/header/HeaderLogoContainer';
import { getIsAuthenticated } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { getConfiguratorUrl } from '../../../../utilities/urls';
import textLogoImage from './assets/text-logo.jpg';
import defaultLogo from '../../../../components/shared/assets/logo_black.png';

const HeaderWrapper = styled.div`
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1000;
`;

const Header = () => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const isAuthenticated = useSelector(getIsAuthenticated);
    const history = useHistory();
    const { logo: iconLogoImage } = useTheme();
    const [isTextLogo, setIsTextLogo] = useState(false);
    const [navBarHeight, setNavBarHeight] = useState('78px');
    const [logoWidth, setLogoWidth] = useState('');

    // @ts-ignore
    const [{ component: Component, properties }] = useHeaderController();
    const { showMenu, fixedMenu, onClose, onToggle } = useFixedMenuController();

    const clickable = useCallback(() => {
        // @ts-ignore
        history.pushWithCompany(getConfiguratorUrl);
    }, [history]);

    // change icon and nav bar size on scroll
    useEffect(() => {
        if (isMobile) {
            window.onscroll = () => {
                if (window.pageYOffset < 100) {
                    setNavBarHeight('78px');
                    setLogoWidth('');
                    setIsTextLogo(false);
                } else if (window.pageYOffset > 150) {
                    setNavBarHeight('50px');
                    setLogoWidth('100px');
                    setIsTextLogo(true);
                }
            };
        } else {
            setNavBarHeight('78px');
            setLogoWidth('');
            setIsTextLogo(false);
        }
    }, [isMobile]);

    const iconLogo = iconLogoImage && (
        <ImgWithFallback
            alt="logo"
            fallbackSrc={defaultLogo}
            src={iconLogoImage}
            style={{ display: isTextLogo ? 'none' : 'inline-block' }}
        />
    );
    const textLogo = textLogoImage && (
        <ImgWithFallback
            alt="logo"
            fallbackSrc={defaultLogo}
            src={textLogoImage}
            style={{ display: isTextLogo ? 'inline-block' : 'none' }}
        />
    );

    return (
        <HeaderWrapper>
            {isAuthenticated && <LoginAccess />}
            <HeaderContainer height={navBarHeight}>
                <div>
                    {isAuthenticated && !fixedMenu && (
                        <IconButton icon={faBars} onClick={onToggle} onMouseEnter={onToggle} size="lg" />
                    )}
                </div>
                <HeaderLogoContainer>
                    <HeaderLogoButton onClick={clickable} width={logoWidth}>
                        {iconLogo}
                        {textLogo}
                    </HeaderLogoButton>
                </HeaderLogoContainer>
                <HeaderController>{Component && <Component {...properties} />}</HeaderController>
            </HeaderContainer>
            {isAuthenticated && <MenuSidebar onClose={onClose} open={showMenu} />}
        </HeaderWrapper>
    );
};

export default Header;
