/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { OcrImageBox } from './OcrImages';
import { ReactComponent as Icon } from '../../../assets/images/ocrCamera.svg';

type OcrImageInputProps = {
    label: string;
    onChange: (event: any) => void;
};

const OcrImageInput = ({ label, onChange }: OcrImageInputProps) => (
    <OcrImageBox>
        <label>
            <input accept=".jpg,.png,.jpeg" onChange={onChange} type="file" />
            <Icon fill="#999999" />
            <span>{label}</span>
        </label>
    </OcrImageBox>
);

export default OcrImageInput;
