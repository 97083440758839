import React from 'react';
import { RemoteFlow } from '../../../../schema';
import RemoteValidation from './RemoteValidation';
import Shield, { ShieldProps } from './Shield';

export type RemoteShieldProps = Pick<ShieldProps, 'applicationId' | 'bankName' | 'handlerComponent' | 'submitted'>;

const RemoteShield = (props: RemoteShieldProps) => (
    <Shield
        {...props}
        flow={RemoteFlow.CUSTOMER}
        // ignore ts validation on this prop for now as it's too strict
        // @ts-ignore
        validationComponent={RemoteValidation}
    />
);

export default RemoteShield;
