import { useMemo } from 'react';
import { BankPresetOption } from '../../schema';
import useOptions from '../../utilities/constants/useOptions';

const useEmirates = (bankPresetOption?: BankPresetOption) => {
    const { cities = [], emirates } = useOptions();

    return useMemo(() => {
        switch (bankPresetOption) {
            case BankPresetOption.PMESA:
                return cities
                    .filter(({ countryCode }) => countryCode === 'SA')
                    .map(({ name }) => ({ label: name, value: name }));

            default:
                return emirates;
        }
    }, [bankPresetOption, emirates, cities]);
};

export default useEmirates;
