import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ConsentText from '../../../../components/ui/calculator/ConsentText';
import { useContentTranslation } from '../../../../i18n';
import useDealerContactInformation from '../../../utils/useDealerContactInformation';
import { Title } from '../DraftPage/ui';

export const Contact = styled.div`
    font-size: 16px;
    color: #000000;
`;

export const ContactStatement = styled.div`
    color: #000;
`;

export type DealerInformationProps = {
    dealerId: string;
};

const DealerInformation = ({ dealerId }: DealerInformationProps) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const { loading, data } = useDealerContactInformation(dealerId);

    const { name, address, telephone, whatsapp, email, additionalInformation } = data?.result ?? {};

    if (loading) {
        return null;
    }

    return (
        <ContactStatement>
            {name && <Title>{ct(name)}</Title>}
            {address && <Contact>{ct(address)}</Contact>}
            {telephone && (
                <Contact>
                    {t('eventThankYouPage.label.phone')} {telephone}
                </Contact>
            )}
            {whatsapp && (
                <Contact>
                    {t('eventThankYouPage.label.whatsApp')} {whatsapp}
                </Contact>
            )}
            {email && (
                <Contact>
                    {t('eventThankYouPage.label.email')} {email}
                </Contact>
            )}
            {additionalInformation && <ConsentText dangerouslySetInnerHTML={{ __html: ct(additionalInformation) }} />}
        </ContactStatement>
    );
};

export default DealerInformation;
