import styled from 'styled-components';

const ConsentTitle = styled.span`
    display: block;
    font-size: 1.21rem;
    margin-top: 20px;
    margin-bottom: 15px;
`;

export default ConsentTitle;
