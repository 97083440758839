import React from 'react';
import SelectField from './SelectField';

const claimDiscountOptions = [
    { value: 0, label: '0%' },
    { value: 10, label: '10%' },
    { value: 20, label: '20%' },
    { value: 30, label: '30%' },
    { value: 40, label: '40%' },
    { value: 50, label: '50%' },
];

export type ClaimDiscountProps = {
    label?: string;
    name: string;
    disabled: boolean;
};

const ClaimDiscount = ({ disabled, label, ...props }: ClaimDiscountProps) => {
    return (
        <SelectField disabled={disabled} label={label} options={claimDiscountOptions} {...props} clearable={false} />
    );
};

export default ClaimDiscount;
