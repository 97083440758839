import { useApolloClient, useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { attachLoading } from '../../../../actions';
import {
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../../api/application.graphql';
import { getCurrentZone, getGlobalPermissions } from '../../../../selectors';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { getAppointmentsUrl } from '../../../../utilities/urls';
import Title from '../../../shared/form-v2/Title';
import FixedMenuFormTabContainer from '../../../ui/form/FixedMenuFormTab';
import applySourceChange from '../../../utilities/applySourceChange';
import Navigation from '../../NewApplicationRoutes/shared/Navigation';
import { ApplicationDetailContainer } from '../../ReservationRoute/Details';
import { AppointmentDataFragment } from '../Appointment.graphql';
import {
    getReservedAppointmentDates,
    GetReservedAppointmentDatesQuery,
    GetReservedAppointmentDatesQueryVariables,
    rearrangeDateAppointment,
    RearrangeDateAppointmentMutation,
    RearrangeDateAppointmentMutationVariables,
} from './Details.graphql';
import AppointmentForm from './Form/AppointmentForm';
import { AppointmentFormValues } from './Form/context';

export type AppointmentDetailsProps = {
    appointment: AppointmentDataFragment;
};

const AppointmentDetails = ({ appointment }: AppointmentDetailsProps) => {
    const { t } = useTranslation();
    const zone = useSelector(getCurrentZone);
    const dispatch = useDispatch();

    const history = useHistory();
    const { previous } = useLocation<{ previous: string }>().state ?? {};

    const { mayManageAppointment, mayManageCustomer } = useSelector(getGlobalPermissions);

    const client = useApolloClient();

    const { data, loading } = useQuery<GetReservedAppointmentDatesQuery, GetReservedAppointmentDatesQueryVariables>(
        getReservedAppointmentDates,
        {
            fetchPolicy: 'network-only',
            variables: {
                filter: {
                    zoneId: appointment.latestApplication.zoneId,
                    dealerIds: [appointment.latestApplication.dealerId],
                    exceptId: appointment.id,
                },
            },
            skip:
                !appointment.latestApplication ||
                !appointment.latestApplication?.zoneId ||
                !appointment.latestApplication?.dealerId,
        }
    );

    const onPrev = useCallback(() => {
        if (previous) {
            history.goBack();
        } else {
            // @ts-ignore
            history.pushWithCompanyAndState(getAppointmentsUrl);
        }
    }, [history, previous]);

    const initialValues = useMemo<AppointmentFormValues>(
        () => ({
            ...omit(['latestApplication'], appointment),
            latestApplication: appointment.latestApplication,
        }),
        [appointment]
    );

    const onSubmitChanges = useCallback(
        async (values: AppointmentFormValues) => {
            try {
                // update customer
                if (mayManageCustomer) {
                    const { customer } = values.latestApplication;
                    const initialCustomer = appointment.latestApplication.customer;
                    const modifiedCustomer = applySourceChange(customer, initialCustomer);
                    const { id: customerId, type, ...customerData } = modifiedCustomer;
                    const customerPromise = client.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                        mutation: updateCustomer,
                        variables: { id: customerId, data: prepareForGraphQL(customerData) },
                    });
                    // attach loading
                    dispatch(attachLoading(customerPromise));
                }

                // update appointment
                const appointmentPromise = client.mutate<
                    RearrangeDateAppointmentMutation,
                    RearrangeDateAppointmentMutationVariables
                >({
                    mutation: rearrangeDateAppointment,
                    variables: {
                        id: values.id,
                        date: values.date,
                    },
                });

                // attach loading
                dispatch(attachLoading(appointmentPromise));

                // go back to appointment list
                // @ts-ignore
                history.pushWithCompanyAndState(getAppointmentsUrl);

                return null;
            } catch (error) {
                return handleResponseError(error as Error);
            }
        },
        [appointment, client, dispatch, history, mayManageCustomer]
    );

    if (loading || !zone || !appointment.date) {
        return null;
    }

    return (
        <ApplicationDetailContainer>
            <FixedMenuFormTabContainer>
                <Navigation onPrev={onPrev} prevText={t('appointmentDetailPage.button.back')} />
                <Title customSpace="20px">
                    {t('appointmentDetailPage.title')} - {appointment.identifier}
                </Title>
                <AppointmentForm
                    disabled={!mayManageAppointment}
                    initialValues={initialValues}
                    onSubmit={onSubmitChanges}
                    reservedAppointments={data?.reserved ?? []}
                />
            </FixedMenuFormTabContainer>
        </ApplicationDetailContainer>
    );
};

export default AppointmentDetails;
