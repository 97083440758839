import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext, InjectedFormProps } from 'redux-form';
import * as yup from 'yup';
import { loadAddressTypes, loadCountries, loadCountriesDialingCodes, loadResidenceTypes } from '../../../../../actions';
import CheckboxField from '../../../../../components/shared/form-v2/CheckboxField';
import CountryField from '../../../../../components/shared/form-v2/CountryField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useBankPresetLabel from '../../../../../components/utilities/useBankPresetLabel';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import useEmirates from '../../../../../components/utilities/useEmirates';
import usePhone from '../../../../../components/utilities/usePhone';
import { BankPresetOption } from '../../../../../schema';
import { getAddressTypes, getCountriesDialingCodes, getResidenceTypes } from '../../../../../selectors';
import { AddressType } from '../../../../../utilities/constants/optionTypes';
import { onTelKeyPress, yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';

const watchedFields = [
    'details.addressType',
    'details.emirate',
    'details.isCountrySameAsResidence',
    {
        path: 'details.homeCountryAddress',
        properties: ['phone', 'fullAddress', 'country'],
    },
    {
        path: 'details.residentialAddress',
        properties: ['type', 'fullAddress', 'poBox'],
    },
    {
        path: 'details.office',
        properties: ['type', 'fullAddress', 'poBox', 'phone', 'city'],
    },
    'details.thirdParty.pmeSA.emirate',
];

type ContactFormProps = {
    disabled?: boolean;
    change: InjectedFormProps['change'];
    bankPresetOption?: BankPresetOption;
};

const getOfficeEmirateName = (bankPresetOption?: BankPresetOption) => {
    switch (bankPresetOption) {
        case BankPresetOption.PMESA:
            return 'details.thirdParty.pmeSA.emirate.value';

        case BankPresetOption.ENBDBANK:
            return 'details.office.city';

        default:
            return 'details.emirate.value';
    }
};

const ContactForm = ({ disabled = false, change, bankPresetOption }: ContactFormProps) => {
    const { t } = useTranslation();
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const values = getValues();

    const isCountrySameAsResidence = get(
        sectionPrefix ? `${sectionPrefix}.details.isCountrySameAsResidence` : 'details.residentialAddress.country',
        values
    );

    const dispatch = useDispatch();

    const addressType = get('customerInfo.details.addressType', values);
    const officeAddress = get('customerInfo.details.office.fullAddress', values);
    const residentialAddress = get('customerInfo.details.residentialAddress.fullAddress', values);
    const homeCountry = get(`${sectionPrefix}.details.homeCountryAddress.country`, values);
    const isCountrySameAsResidenceValue = get('customerInfo.details.isCountrySameAsResidence.value', values);

    const isPMESAPreset = bankPresetOption === BankPresetOption.PMESA;
    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    useEffect(() => {
        dispatch(loadCountries());
        dispatch(loadAddressTypes());
        dispatch(loadResidenceTypes());
        dispatch(loadCountriesDialingCodes());
    }, [dispatch]);

    const addressTypes = useSelector(getAddressTypes);
    const residenceTypes = useSelector(getResidenceTypes);
    const countriesDialingCodes = useSelector(getCountriesDialingCodes);

    const emirates = useEmirates(bankPresetOption);
    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);

    const { code: homeCountryDialingCode } = useMemo(
        () => countriesDialingCodes?.find(item => item.countryCode === homeCountry)?.phone || {},
        [countriesDialingCodes, homeCountry]
    );

    const { code: phoneCountryCode } = usePhone();

    const setHomeCountryAddress = useCallback(
        (value?: string) => {
            if (!addressType) {
                return;
            }

            if (isEnbdBankPreset) {
                change('customerInfo.details.homeCountryAddress.fullAddress', residentialAddress);

                return;
            }

            switch (addressType.value) {
                case AddressType.RESIDENCE:
                    change('customerInfo.details.homeCountryAddress.fullAddress', value || residentialAddress);

                    break;
                case AddressType.OFFICE:
                    change('customerInfo.details.homeCountryAddress.fullAddress', value || officeAddress);

                    break;
                default:
            }
        },
        [addressType, isEnbdBankPreset, change, residentialAddress, officeAddress]
    );

    // Handle checkbox is country same as residence check
    useEffect(() => {
        if (isCountrySameAsResidenceValue) {
            setHomeCountryAddress();
        }
    }, [change, isCountrySameAsResidenceValue, setHomeCountryAddress]);

    // Handle details.residentialAddress.fullAddress change
    useEffect(() => {
        if (isCountrySameAsResidenceValue) {
            setHomeCountryAddress(residentialAddress);
        }
    }, [change, isCountrySameAsResidenceValue, residentialAddress, setHomeCountryAddress]);

    // Handle details.office.fullAddress change
    useEffect(() => {
        if (isCountrySameAsResidenceValue) {
            setHomeCountryAddress(officeAddress);
        }
    }, [change, isCountrySameAsResidenceValue, officeAddress, setHomeCountryAddress]);

    const renderResidenceAddress = useMemo(() => {
        return (
            <>
                <TextField
                    disabled={disabled || fieldState.details.residentialAddress}
                    label={getBankPresetLabel('residenceAddress', 'address')}
                    name="details.residentialAddress.fullAddress"
                />
                <TextField
                    disabled={disabled || fieldState.details.residentialAddress}
                    label={getBankPresetLabel('residencePoBox', 'poBox')}
                    name="details.residentialAddress.poBox"
                />
                <SelectField.Outline
                    disabled={disabled || fieldState.details.emirate}
                    label={getBankPresetLabel('residenceCity', 'emirate')}
                    name={isPMESAPreset ? 'details.thirdParty.pmeSA.emirate.value' : 'details.emirate.value'}
                    options={emirates}
                />
                <SelectField.Outline
                    disabled={disabled || fieldState.details.residentialAddress}
                    label={t('customerDetails.label.residenceType')}
                    name="details.residentialAddress.type"
                    options={residenceTypes}
                />
            </>
        );
    }, [disabled, emirates, fieldState, getBankPresetLabel, isPMESAPreset, residenceTypes, t]);

    const renderOfficeAddress = useMemo(() => {
        return (
            <>
                <TextField
                    disabled={disabled || fieldState.details.office}
                    label={getBankPresetLabel('officeAddress', 'address')}
                    name="details.office.fullAddress"
                />
                <TextField
                    disabled={disabled || fieldState.details.office}
                    label={getBankPresetLabel('officePoBox', 'poBox')}
                    name="details.office.poBox"
                />
                <SelectField.Outline
                    disabled={disabled || fieldState.details.emirate}
                    label={getBankPresetLabel('officeCity', 'emirate')}
                    name={getOfficeEmirateName(bankPresetOption)}
                    options={emirates}
                />
                {!isEnbdBankPreset && (
                    <SelectField.Outline
                        disabled={disabled || fieldState.details.office}
                        label={getBankPresetLabel('officeType', 'residenceType')}
                        name="details.office.type"
                        options={residenceTypes}
                    />
                )}
                <TextField
                    disabled={disabled || fieldState.details.office}
                    label={t('customerDetails.label.officeLandLineNumber')}
                    maxLength="10"
                    name="details.office.phone"
                    onKeyPress={onTelKeyPress}
                    prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                    type="tel"
                />
            </>
        );
    }, [
        bankPresetOption,
        disabled,
        emirates,
        fieldState.details.emirate,
        fieldState.details.office,
        getBankPresetLabel,
        isEnbdBankPreset,
        phoneCountryCode,
        residenceTypes,
        t,
    ]);

    return (
        <>
            <Title>{t('kycPage.contactDetailsTitle')}</Title>{' '}
            <SelectField.Outline
                disabled={disabled || fieldState.details.addressType}
                label={getBankPresetLabel('addressType')}
                name="details.addressType.value"
                options={addressTypes}
            />
            {(isEnbdBankPreset ||
                (!isEnbdBankPreset && (!addressType || addressType.value === AddressType.RESIDENCE))) &&
                renderResidenceAddress}
            {!isEnbdBankPreset && addressType && addressType.value === AddressType.OFFICE && renderOfficeAddress}
            <CheckboxField
                label={t('customerDetails.label.isCountrySameAsResidence')}
                name="details.isCountrySameAsResidence.value"
            />
            <TextField
                disabled={disabled || isCountrySameAsResidence?.value}
                label={t('customerDetails.label.homeCountryAddress')}
                name="details.homeCountryAddress.fullAddress"
            />
            <CountryField
                disabled={disabled}
                label={t('customerDetails.label.homeCountry')}
                name="details.homeCountryAddress.country"
            />
            <TextField
                disabled={disabled}
                label={t('customerDetails.label.homeCountryPhone')}
                maxLength="10"
                name="details.homeCountryAddress.phone"
                onKeyPress={onTelKeyPress}
                prefix={homeCountryDialingCode ? `+${homeCountryDialingCode}` : undefined}
                type="tel"
            />
            {isEnbdBankPreset && renderOfficeAddress}
        </>
    );
};

const officeAddressSchema = (t: TFunction) => ({
    fullAddress: yup.string().required(t('common.error.required')),
    phone: yup
        .string()
        .required(t('common.error.required'))
        .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
    poBox: yup.string().required(t('common.error.required')),
    // @ts-ignore
    type: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

        if (isEnbdBankPreset) {
            return yup.string().nullable();
        }

        return yup.string().required(t('common.error.required'));
    }),
});

const residentialAddressSchema = (t: TFunction) => ({
    fullAddress: yup.string().required(t('common.error.required')),
    poBox: yup.string().required(t('common.error.required')),
    type: yup.string().required(t('common.error.required')),
});

export const contactPMESASchema = (t: TFunction) => ({
    emirate: yupExt.customerProperty().shape({
        value: yup.string().nullable(),
    }),
});

export const contactSchema = (t: TFunction) => ({
    addressType: yupExt.customerProperty().shape({ value: yup.string().required(t('common.error.required')) }),
    // @ts-ignore
    office: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;
        const parent = get('parent', options);
        if (isEnbdBankPreset || (!isEnbdBankPreset && parent.addressType?.value === AddressType.OFFICE)) {
            return yupExt.customerProperty().shape(officeAddressSchema(t));
        }

        return yupExt.object().nullable();
    }),
    // @ts-ignore
    residentialAddress: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;
        const parent = get('parent', options);
        if (isEnbdBankPreset || (!isEnbdBankPreset && parent.addressType?.value === AddressType.RESIDENCE)) {
            return yupExt.customerProperty().shape(residentialAddressSchema(t));
        }

        return yupExt.object().nullable();
    }),
    homeCountryAddress: yupExt.customerProperty().shape({
        country: yup.string().required(t('common.error.required')),
        fullAddress: yup.string().required(t('common.error.required')).nullable(),
        phone: yup
            .string()
            .required(t('common.error.required'))
            .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
    }),
});

export default ContactForm;
