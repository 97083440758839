import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import ReservationDepositField from '../../../../components/shared/calculator/ReservationDepositField';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { Application } from '../../../DraftFlow';
import { SubmittedState } from '../../../DraftFlow/components/PorscheDeposit';
import PaymentWidget from '../../../DraftFlow/components/PorscheDeposit/PaymentWidget';
import usePorschePayment from '../../../DraftFlow/components/PorscheDeposit/usePorschePayment';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { Title } from '../DraftPage/ui';
import PaymentSuccess from './PaymentSuccess';

export type PorschePaymentProps = {
    application: Application;
    channel: Channel;
    consentGiven: boolean;
    depositAmount?: number;
    onSubmit: () => void;
    token: string;
    isLastStep: boolean;
    source: ApplicationFlowSource;
};

const PorschePayment = ({
    onSubmit,
    application,
    channel,
    depositAmount,
    consentGiven,
    isLastStep,
    token,
    source,
}: PorschePaymentProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        config,
        status,
        onSubmit: submitPayment,
        setPaymentData,
        hasPaymentData,
        paymentMethods,
    } = usePorschePayment(application.version.id, channel, source, token);

    const disabled = !config || !consentGiven || !hasPaymentData;

    const isSuccessful = application.steps?.payment || (status && SubmittedState.includes(status));

    // set the paymentStatus for goback
    useEffect(() => {
        dispatch(change('customer', 'paymentStatus', isSuccessful));
    }, [dispatch, isSuccessful]);

    // redirect to next step if payment is successful
    useEffect(() => {
        if (isSuccessful) {
            onSubmit();
        }
    }, [isSuccessful, onSubmit]);

    return (
        <div>
            <Title>{t('eventConsentDepositPage.depositTitle')}</Title>
            <ReservationDepositField amount={depositAmount} channel={channel} />
            <br />
            {config && !isSuccessful && (
                <>
                    <PaymentWidget config={config} onPaymentUpdate={setPaymentData} />
                    <Buttons>
                        <PrimaryButton disabled={disabled} onClick={submitPayment}>
                            <FontAwesomeIcon icon={faAngleRight} /> {t('eventConsentDepositPage.button.pay')}
                        </PrimaryButton>
                    </Buttons>
                </>
            )}
            {isSuccessful && <PaymentSuccess isLastStep={isLastStep} />}
        </div>
    );
};

export default PorschePayment;
