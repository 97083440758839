import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCountry } from '../../../../hookSelectors';
import { getCompanyIdentifier } from '../../../../selectors';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';

const useApplyForInsurance = () => {
    const history = useHistory();
    const { code: countryCode } = useCountry();
    const { companyCode } = useSelector(getCompanyIdentifier);

    return useCallback(
        (application: ApplicationDataFragment) => {
            const url = `/${companyCode}/${countryCode}/applications/${application.version.id}/applyForInsurance`;

            history.push(url);

            return null;
        },
        [history]
    );
};

export default useApplyForInsurance;
