import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from 'styled-components';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../../components/shared/ImgWithFallback';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import noImg from '../../../../../assets/images/no-image.png';
import close from '../assets/close.png';
import cross from '../assets/cross.png';
import leftArrow from '../assets/left.png';
import rightArrow from '../assets/right.png';

export const ModalContainer = styled.div`
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalWrapper = styled.div<{ name?: string; selectedComponent?: string; width?: string }>`
    position: absolute;
    display: flex;
    align-items: center;
    width: 90vw;
    height: 550px;
    max-height: 90vh;
    z-index: ${props => (props.name === props.selectedComponent ? '10000' : '999')};

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        width: ${props => props.width};
        height: 600px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90vh;
    z-index: 10000;
    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        img {
            height: 500px;
        }
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        flex-direction: row;
        img {
            width: 50%;
            height: 600px;
        }
    }
`;

export const CrossContainer = styled.div<{ marginLeft?: string }>`
    position: absolute;
    align-self: flex-end;
    cursor: pointer;

    img {
        margin: 15px 15px 15px 0 !important;
        width: 20px;
        height: 20px;
    }

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        align-self: flex-start;
        margin-left: ${props => props.marginLeft};
    }
`;

export const ArrowContainer = styled.div`
    z-index: 999;
    margin: 10px;
    cursor: pointer;
    img {
        width: 20px;
        height: 40px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 250px;
    padding: 20px;
    background-color: white;
    h4 {
        font-size: 1.4em;
    }
    span {
        font-size: 1.2em;
    }
    p {
        margin-top: 3vw;
        line-height: 1.6;
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        width: 50%;
        height: 600px;
        padding: 20px 50px 50px;
    }
`;

type PackageModalProps = {
    options: { desktopImage: string; name: string; headline: string; subline: string; body: string[] }[];
    setDisplayPackage: Dispatch<SetStateAction<boolean>>;
};

export const PackageModal = ({ options, setDisplayPackage }: PackageModalProps) => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const [selectedComponent, setSelected] = useState(options[0].name);
    const onClick = useCallback(option => {
        setSelected(option);
    }, []);
    const onClose = useCallback(() => {
        setDisplayPackage(false);
    }, [setDisplayPackage]);

    return (
        <ModalContainer>
            {options.map((item, index) => (
                <ModalWrapper key={item.name} name={item.name} selectedComponent={selectedComponent} width="1000px">
                    {options.length > 1 && (
                        <ArrowContainer
                            onClick={() =>
                                onClick(index > 0 ? options[index - 1].name : options[index - 1 + options.length].name)
                            }
                        >
                            <img alt="left arrow" src={leftArrow} />
                        </ArrowContainer>
                    )}
                    <ContentContainer>
                        <ImgWithFallback alt={item.headline} fallbackSrc={noImg} src={item.desktopImage} />
                        <CrossContainer marginLeft="880px" onClick={onClose}>
                            <img alt="cross" src={isMobile ? cross : close} />
                        </CrossContainer>
                        <TextContainer>
                            <h4>{item.headline}</h4>
                            <span>{item.subline}</span>
                            {item.body.map(text => (
                                <p>{text}</p>
                            ))}
                        </TextContainer>
                    </ContentContainer>
                    {options.length > 1 && (
                        <ArrowContainer
                            onClick={() =>
                                onClick(
                                    index < options.length - 1
                                        ? options[index + 1].name
                                        : options[index + 1 - options.length].name
                                )
                            }
                        >
                            <img alt="right arrow" src={rightArrow} />
                        </ArrowContainer>
                    )}
                </ModalWrapper>
            ))}
        </ModalContainer>
    );
};

export default PackageModal;
