import React, { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import { handleResponseError } from '../../utilities/forms';
import { useCheckOptionsRemoval, CheckOptionsRemovalValues } from './OptionsControlProvider';

export type ApplyCalculator<TValues = any> = (values: TValues) => Promise<TValues>;

export type ApplyProviderProps<TValues extends CheckOptionsRemovalValues> = {
    handleSubmit: (values: TValues) => Promise<void>;
    children: ReactElement | ReactNode;
};

const ApplyCalculatorContext = createContext<ApplyCalculator | null>(null);

export const useApplyCalculator = <TValues extends CheckOptionsRemovalValues>() => {
    const context = useContext(ApplyCalculatorContext);

    if (context === null) {
        throw new Error('Apply calculator context not provided');
    }

    return context as ApplyCalculator<TValues>;
};

const ApplyCalculatorProvider = <TValues extends CheckOptionsRemovalValues>({
    children,
    handleSubmit,
}: ApplyProviderProps<TValues>) => {
    // we will need dispatch
    const dispatch = useDispatch();

    // options removal function
    const checkOptionsRemoval = useCheckOptionsRemoval<TValues>();

    // apply action
    const apply = useCallback(
        async (values: TValues) => {
            // first check options for removal
            const doNotProceed = await checkOptionsRemoval(values);

            if (doNotProceed) {
                // do not proceed as per user does not approve
                return null;
            }

            try {
                // submit the calculator
                const promise = handleSubmit(values);

                // wait for its completion
                return await dispatch(actions.attachLoading(promise));
            } catch (error) {
                return handleResponseError(error as Error);
            }
        },
        [dispatch, checkOptionsRemoval, handleSubmit]
    );

    return <ApplyCalculatorContext.Provider value={apply}>{children}</ApplyCalculatorContext.Provider>;
};

export default ApplyCalculatorProvider;
