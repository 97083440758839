// @ts-ignore
import { Actions } from '@appvantageasia/afc-ui';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import { Container } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import { ApplicationFlowSource, Channel, ChannelSetting } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import { PayGateTransactionStatus } from '../../../../utilities/constants/PayGateTransactionStatus';
import DepositConsentForm, { schema } from '../../../EventFlow/components/ConsentDeposit/ConsentForm';
import { PageTitle, Title } from '../../../EventFlow/components/DraftPage/ui';
import { BackStepContext } from '../../../utils/flow';
import { Application } from '../../types';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { PaymentContainer } from '../Deposit';
import usePaymentConsents from '../Deposit/hooks/usePaymentConsents';
import PayGateConfirmationModal from './PayGateConfirmationModal';
import PayGateForm from './PayGateForm';
import usePayGatePayment from './usePayGatePayment';

export type PageProps = {
    application: Application;
    token: string;
    dealerId: string;
    channel?: Channel;
    onSubmit: (values?: any) => void;
    backStep?: BackStepContext;
    source: ApplicationFlowSource;
};

export const SubmittedState = [PayGateTransactionStatus.APPROVED, PayGateTransactionStatus.RECEIVED_BY_PAYGATE];

const Deposit = ({ onSubmit, backStep, application, token, dealerId, channel = Channel.NEW, source }: PageProps) => {
    const { t } = useTranslation();
    const [consentGiven, setConsentGiven] = useState(false);
    const [referenceId, setReferenceId] = useState<string>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const actions = useMemo(
        () => ({
            close: () => setShowModal(false),
            open: () => setShowModal(true),
            confirm: () => {
                setShowModal(false);
                setConfirm(true);
            },
        }),
        []
    );
    const { status } = usePayGatePayment(application.version.id, source);
    const isSuccessful = application.steps?.payment || (status && SubmittedState.includes(status));

    useEffect(() => {
        if (isSuccessful) {
            onSubmit();
        }
    }, [isSuccessful, onSubmit]);

    const country = useSelector(getCurrentCountry);

    const { consents, loading: consentsLoading } = usePaymentConsents(dealerId);

    const validate = useValidation(schema(consents));

    const hasDeposit = country?.channelSetting[channel.toLowerCase() as keyof ChannelSetting];
    const hasConsents = hasDeposit ? !!consents?.length : false;

    const disabled = hasConsents && !consentGiven;

    if (consentsLoading) {
        return null;
    }

    return (
        <PaymentContainer>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <PageTitle>{t('depositPage.title')}</PageTitle>
            <div className="row">
                {hasConsents && (
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Container>
                            <Title>{t('eventConsentDepositPage.paymentConsentTitle')}</Title>
                            <DepositConsentForm
                                consents={consents}
                                referenceId={referenceId}
                                setReferenceId={setReferenceId}
                                setValid={setConsentGiven}
                                showNextButton={!hasDeposit}
                                token={token}
                                validate={validate}
                            />
                            <Buttons>
                                <PrimaryButton disabled={disabled} onClick={actions.open}>
                                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventConsentDepositPage.button.pay')}
                                </PrimaryButton>
                            </Buttons>
                        </Container>
                    </div>
                )}
                {showModal && <PayGateConfirmationModal onClose={actions.close} onConfirm={actions.confirm} />}
                {!isSuccessful && (confirm || !hasConsents) && (
                    <PayGateForm applicationId={application.version.id} source={source} token={token} />
                )}
            </div>
        </PaymentContainer>
    );
};

export default Deposit;
