import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import {
    BannerContainer as BaseBannerContainer,
    BannerLink,
} from '../../../EventFlow/components/Calculator/blocks/Banner';
import { ReactComponent as Times } from '../../../../assets/images/times.svg';

const BannerContainer = styled(BaseBannerContainer)`
    margin-bottom: 0;
    padding-left: 20px;

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding-left: 100px;
    }
`;

type BannerProps = {
    onClose: () => void;
};

const Banner = ({ onClose }: BannerProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const { url, heading, preAnchor, anchor } = ckdConfiguration.banner;

    return (
        <BannerContainer>
            <span>{heading}</span>
            <span>
                {preAnchor}
                <BannerLink href={url}>{anchor}</BannerLink>
            </span>
            <Times fill="#000" onClick={onClose} />
        </BannerContainer>
    );
};

export default Banner;
