import { permissionChannels } from '../../../utilities/permissions';
import { UserDataFragment } from './api.graphql';

// to remove country/company permissions without login permission
const getUserWithPermissions = (user: UserDataFragment) => {
    // no need to check for superuser
    if (user.isSuperUser) {
        return user;
    }

    const availableCompanies = user.availableCompanies || [];
    const companyIds: string[] = [];
    const countryIds: string[] = [];

    // check login permisssion
    const modifiedPermissions = user.permissions
        .map(permission => {
            if (Object.values(permissionChannels).some(hasPermission => hasPermission(user, permission.countryId))) {
                companyIds.push(permission.companyId);
                countryIds.push(permission.countryId);

                return permission;
            }

            return false;
        })
        .filter(Boolean);

    // remove companies and countires that doesn't have login permission
    const modifiedCompanies = availableCompanies
        .map(company => {
            if (companyIds.includes(company.id)) {
                const countries = company.countries.filter(country => countryIds.includes(country.id));
                if (countries.length > 0) {
                    return { ...company, countries };
                }
            }

            return false;
        })
        .filter(Boolean);

    return ({
        ...user,
        permissions: modifiedPermissions,
        availableCompanies: modifiedCompanies,
    } as unknown) as UserDataFragment;
};

export default getUserWithPermissions;
