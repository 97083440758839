import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import {
    getDealerBookingPayment,
    GetDealerBookingPaymentQuery,
    GetDealerBookingPaymentQueryVariables,
} from '../../api/dealers.graphql';

const useDealerBookingPayment = (id: string) =>
    useQuery<GetDealerBookingPaymentQuery, GetDealerBookingPaymentQueryVariables>(getDealerBookingPayment, {
        variables: { dealerId: id },
        skip: isEmpty(id),
        fetchPolicy: 'cache-first',
    });
export default useDealerBookingPayment;
