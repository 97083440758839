import React, { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import ConsentText from '../../../../components/ui/calculator/ConsentText';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { ModalContainer, ModalWrapper, ContentContainer, CrossContainer } from '../Configurator/shared/PackageModal';
import close from '../Configurator/assets/close.png';

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    .textWrapper {
        overflow-y: auto;
        height: 600px;
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 50px 50px;
    }
`;

type TermsAndConditionsModalProps = {
    setDisplayTerms: Dispatch<SetStateAction<boolean>>;
    headline: string;
    body: string[];
};

const TermsAndConditionsModal = ({ setDisplayTerms, headline, body }: TermsAndConditionsModalProps) => {
    const onClose = useCallback(() => {
        setDisplayTerms(false);
    }, [setDisplayTerms]);

    return (
        <ModalContainer>
            <ModalWrapper width="700px">
                <ContentContainer>
                    <CrossContainer marginLeft="650px" onClick={onClose}>
                        <img alt="cross" src={close} />
                    </CrossContainer>
                    <TextContainer>
                        <div className="textWrapper">
                            <h4>{headline}</h4>
                            {body.map((item: string, index: number) => (
                                <ConsentText key={index.toString()} dangerouslySetInnerHTML={{ __html: item }} />
                            ))}
                        </div>
                    </TextContainer>
                </ContentContainer>
            </ModalWrapper>
        </ModalContainer>
    );
};

export default TermsAndConditionsModal;
