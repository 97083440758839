import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { CsvConfiguratorDataFragment } from '../../../../components/routes/CsvRoute/data.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { FinderVehicleDataFragment } from '../../../../components/routes/FinderRoute/data.graphql';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import ReservationDepositField from '../../../../components/shared/calculator/ReservationDepositField';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { useCountry } from '../../../../hookSelectors';
import { Channel, EventExternalSite, RuntimeType } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import { getEventUrl } from '../../../../utilities/urls';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { CarOfInterestType } from '../../steps/EventDraftStep';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { EventGrid, Page, PageTitle, Space } from '../DraftPage/ui';
import DealerInformation from '../shared/DealerInformation';
import Paragraph from '../shared/Paragraph';
import SelectedCar from '../shared/SelectedCar';

const SuccessStatement = styled.div`
    color: #000;
`;

const SuccessEmailStatement = styled.div`
    color: #000;
`;

const Link = styled.a`
    margin-top: 10px;
    button {
        width: 100%;
    }
`;

const ThankYouContainer = styled.div`
    ul {
        list-style-type: square;
        display: table;

        li {
            display: table-row;
            line-height: 18px;

            &::before {
                padding-right: 0.5rem;
                content: '▪︎';
                display: table-cell;
                text-align: right;
            }
        }
    }
    ol {
        list-style-type: decimal;
        margin-left: 1.25rem;
    }
`;

export type ThankYouProps = {
    channel: Channel;
    calculator?: Partial<CalculatorValues>;
    event: EventDataFragment;
    variant?: VariantDataFragment;
    carOfInterest?: CarOfInterestType;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    finderVehicle?: FinderVehicleDataFragment;
    dealerId: string;
    csvConfigurator?: CsvConfiguratorDataFragment;
    isCalculatorEnabled?: boolean;
};

const ThankYou = ({
    channel,
    event,
    calculator,
    variant,
    carOfInterest,
    miniConfiguratorDetails,
    finderVehicle,
    dealerId,
    csvConfigurator,
    isCalculatorEnabled,
}: ThankYouProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { type } = useSelector(getRuntimeSettings);
    const { googleTagManager } = useCountry();
    const { formatCurrency } = useCompanyFormatting();

    const hasDepositPayment = !!event?.setting?.isDepositPaymentMandatory;
    const amount = event?.setting?.bookingPayment?.amount;

    const isFinderFlow = event.setting.externalSite === EventExternalSite.PORSCHEFINDER;
    const isConfigatorFlow = event.setting.externalSite === EventExternalSite.CONFIGURATOR;
    const isPMEInstance = type === RuntimeType.PME;

    const model = finderVehicle?.listing?.vehicle.modelSeries.localize;
    const porscheUrl = `https://www.porsche.com/middle-east/models/${model}/${model}-models/`;

    // Prepare for Google Tag Manager Data Layer Push
    const googleTagManagerId = googleTagManager?.id;

    const isMarketingReconsent = event.setting.externalSite === EventExternalSite.MARKETINGRECONSENT;

    useEffect(() => {
        if (!isConfigatorFlow) {
            return;
        }

        const dealer = event.dealers.find(({ id }) => id === dealerId);

        const tagManagerArgs = {
            dataLayer: {
                event: 'PlaceYourOrderSuccessfully',
                dealer: dealer?.name,
                details: {
                    miniConfiguratorDetails,
                    promoCode: calculator?.promoCode,
                    carPrice: formatCurrency(calculator?.totalPrice),
                    monthlyPayment: calculator?.monthlyInstalments
                        ? formatCurrency(calculator?.monthlyInstalments[0].amount)
                        : undefined,
                    eventName: event?.name,
                },
            },
            dataLayerName: 'PageDataLayer',
        };

        if (googleTagManagerId) {
            TagManager.dataLayer(tagManagerArgs);
        }
    }, [isConfigatorFlow, event, miniConfiguratorDetails, calculator, formatCurrency, googleTagManagerId, dealerId]);

    const navigate = useCallback(() => {
        if (finderVehicle?.url) {
            window.location.href = finderVehicle.url;
        } else if (csvConfigurator?.url) {
            window.location.href = csvConfigurator.url;
        } else {
            // @ts-ignore
            history.pushWithCompany(getEventUrl, event.id);
        }
    }, [csvConfigurator, event.id, finderVehicle, history]);

    const buttonLabel = useMemo(() => {
        if (finderVehicle?.url) {
            return t('eventThankYouPage.button.porscheFinderDone');
        }

        if (csvConfigurator?.url) {
            return t('eventThankYouPage.button.csvConfiguratorDone');
        }

        return t('eventThankYouPage.button.done');
    }, [csvConfigurator, finderVehicle, t]);

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Thank You" />
            <PageTitle>{t('eventThankYouPage.title')}</PageTitle>
            <EventGrid>
                <ThankYouContainer>
                    <Space>
                        {hasDepositPayment && (
                            <SuccessStatement>
                                <Paragraph as="div">
                                    <Trans i18nKey="eventThankYouPage.description" />
                                </Paragraph>
                            </SuccessStatement>
                        )}
                        {!isMarketingReconsent && (
                            <SuccessEmailStatement>
                                <Paragraph as="div">
                                    <Trans i18nKey="eventThankYouPage.emailDescription" />
                                </Paragraph>
                            </SuccessEmailStatement>
                        )}
                        <SelectedCar
                            calculator={calculator}
                            carOfInterest={carOfInterest}
                            dealerId={dealerId}
                            event={event}
                            isCalculatorEnabled={isCalculatorEnabled}
                            miniConfiguratorDetails={miniConfiguratorDetails}
                            variant={variant}
                        />
                        {hasDepositPayment && <ReservationDepositField amount={amount} channel={channel} />}
                    </Space>

                    {isMarketingReconsent && (
                        <SuccessEmailStatement>
                            <Paragraph as="div">
                                <Trans i18nKey="eventThankYouPage.descriptionMarketingReconsent" />
                            </Paragraph>
                        </SuccessEmailStatement>
                    )}
                </ThankYouContainer>
                <ThankYouContainer>
                    <Paragraph as="div">
                        <Trans i18nKey="eventThankYouPage.label.contactUs" />
                    </Paragraph>
                    <DealerInformation dealerId={dealerId} />
                    <Buttons>
                        <PrimaryButton onClick={navigate}>
                            <FontAwesomeIcon icon={faAngleRight} /> {buttonLabel}
                        </PrimaryButton>
                        {isPMEInstance && isFinderFlow && model && (
                            <Link href={porscheUrl} rel="noopener noreferrer" target="_blank">
                                <PrimaryButton>
                                    {' '}
                                    <FontAwesomeIcon icon={faAngleRight} />{' '}
                                    {t('eventThankYouPage.button.visitModelPage')}
                                </PrimaryButton>
                            </Link>
                        )}
                    </Buttons>
                </ThankYouContainer>
            </EventGrid>
        </Page>
    );
};

export default ThankYou;
