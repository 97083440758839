/* eslint-disable react/prop-types */
import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import { differenceInYears, subYears } from 'date-fns';
import { isNil } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { FinderVehicleDataFragment } from '../../../../../components/routes/FinderRoute/data.graphql';
import InsuranceCalculator, {
    InsuranceCalculatorErrors,
} from '../../../../../components/shared/calculator-next/InsuranceCalculator';
import {
    CalculatorInsuranceValues,
    CalculatorMeta,
    CalculatorValues,
} from '../../../../../components/shared/calculator-next/types';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import useInsurancePremium from '../../../../../components/utilities/useInsurancePremium';
import { Channel } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { FooterInsuranceData } from '../../../../../utilities/calculator/useGetCalculatorFooterText';
import useDealerEstablishment from '../../../../utils/useDealerEstablishment';
import { toDate } from '../../AppointmentFlowPage/AppointmentFlowForm';
import { DisclaimerEventItem, DisclaimerWrapper } from './Disclaimer';
import { CheckboxWrapper, PriceItalic, SectionItem, SectionItemContent, SpaceBetween } from './styles';

export type SectionInsuranceProps = {
    appliedForInsurance: boolean;
    dealerId: string;
    initialCalculatorValues: Partial<CalculatorValues>;
    meta: Partial<CalculatorMeta>;
    finderVehicle?: FinderVehicleDataFragment | null;
    onInsuranceCalculatorChange: (context: CalculatorContext<CalculatorInsuranceValues>) => void;
    onInsurancePremiumChange: ({ errors }: { errors: InsuranceCalculatorErrors }) => void;
    variants: VariantDataFragment[];
    insuranceDisclaimers?: string[] | null;
    selectedBankEstablishment?: number | null;
    carPrice?: number;
    setIgnoreInsuranceCalculator?: (isInsuranceByPassed: boolean) => void;
    ignoreInsuranceCalculator: boolean;
};

const SectionInsurance = ({
    appliedForInsurance,
    initialCalculatorValues,
    finderVehicle,
    dealerId,
    meta,
    onInsuranceCalculatorChange,
    onInsurancePremiumChange,
    variants,
    insuranceDisclaimers,
    selectedBankEstablishment = 0,
    carPrice,
    setIgnoreInsuranceCalculator,
    ignoreInsuranceCalculator,
}: SectionInsuranceProps) => {
    const { t } = useTranslation();

    const { formatCurrency } = useCompanyFormatting();
    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.EVENT);

    const changeRef = useRef<CalculatorContext<CalculatorInsuranceValues>['change']>();

    const { insurance: insuranceSetting } = useSelector(getRuntimeSettings);

    const { registrationDate, isFinderNew } = useMemo(() => {
        const isFinderNew = finderVehicle?.listing?.vehicle?.condition?.value?.toLowerCase() === 'new';
        const registrationDate = isFinderNew
            ? new Date()
            : finderVehicle?.listing?.vehicle?.firstRegistrationDate?.value;

        return {
            registrationDate,
            isFinderNew,
        };
    }, [finderVehicle]);

    const initialInsuranceCalculatorValues = useMemo(
        () => ({
            dateOfBirth: subYears(new Date(), insuranceSetting.age),
            dateOfRegistration: registrationDate,
            yearsOfDriving: {
                value: insuranceSetting.yearsOfDriving,
            },
            noClaimDiscount: {
                value: insuranceSetting.ncd,
            },
            carPrice,
        }),
        [insuranceSetting, registrationDate, carPrice]
    );

    const insuranceSupportData = useMemo(
        () => ({
            finderVehicle,
        }),
        [finderVehicle]
    );

    // Get disclaimer value
    const { insurancePremiumAmount: disclaimerInsurancePremium } = useInsurancePremium(
        Channel.EVENT,
        dealerId,
        (initialInsuranceCalculatorValues as unknown) as CalculatorValues,
        insuranceSupportData
    );

    // When using default combination to get disclaimer insurance value is not available,
    // we will skip showing the insurance calculator
    useEffect(() => {
        if (!disclaimerInsurancePremium) {
            setIgnoreInsuranceCalculator?.(true);
        } else {
            setIgnoreInsuranceCalculator?.(false);
        }
    }, [disclaimerInsurancePremium, setIgnoreInsuranceCalculator]);

    const insuranceData = useMemo<FooterInsuranceData>(
        () => ({
            premium: disclaimerInsurancePremium,
            ncd: initialInsuranceCalculatorValues.noClaimDiscount.value,
            drivingExperience: initialInsuranceCalculatorValues.yearsOfDriving.value,
            yearOfBirth: differenceInYears(new Date(), new Date(initialInsuranceCalculatorValues.dateOfBirth)),
        }),
        [initialInsuranceCalculatorValues, disclaimerInsurancePremium]
    );

    const onEnhancedInsuranceCalculatorChange = useCallback<SectionInsuranceProps['onInsuranceCalculatorChange']>(
        context => {
            if (!changeRef.current) {
                changeRef.current = context.change;
            }

            if (onInsuranceCalculatorChange) {
                onInsuranceCalculatorChange(context);
            }
        },
        [onInsuranceCalculatorChange]
    );

    useEffect(() => {
        if (changeRef.current && !isNil(registrationDate)) {
            changeRef.current('dateOfRegistration', toDate(registrationDate)?.toISOString());
        }
    }, [registrationDate]);

    useEffect(() => {
        if (changeRef.current) {
            changeRef.current('disclaimerInsurancePremium', disclaimerInsurancePremium);
        }
    }, [disclaimerInsurancePremium]);

    const shownInsuranceCalculator = appliedForInsurance && !ignoreInsuranceCalculator;

    return (
        <SectionItem>
            <SpaceBetween>
                <CheckboxWrapper>
                    <CheckboxField
                        channel={Channel.EVENT}
                        label={t('eventCalculatorPage.checkbox.applyForInsurance')}
                        name="appliedForInsurance"
                    />
                </CheckboxWrapper>
                {!ignoreInsuranceCalculator && (
                    <PriceItalic>
                        <span>
                            <Trans
                                components={{ s: <span /> }}
                                i18nKey="eventCalculatorPage.label.quickQuote"
                                values={{ value: formatCurrency(disclaimerInsurancePremium) }}
                            />
                        </span>
                    </PriceItalic>
                )}
            </SpaceBetween>
            <SectionItemContent $isOpened={shownInsuranceCalculator}>
                <InsuranceCalculator
                    channel={Channel.EVENT}
                    dealerId={dealerId}
                    finderVehicle={finderVehicle}
                    initialValues={initialCalculatorValues}
                    meta={meta}
                    onChange={onEnhancedInsuranceCalculatorChange}
                    onInsurancePremiumChange={onInsurancePremiumChange}
                    variants={variants}
                >
                    <displayFields.COEField
                        fieldKey="coe"
                        isViewable={() => false}
                        labelTKey="calculator.label.coe"
                        override
                    />
                    <displayFields.TotalPriceField
                        fieldKey="totalPrice"
                        isViewable={() => false}
                        labelTKey="calculator.label.totalPrice"
                        override
                    />
                    <displayFields.DateOfBirthField
                        fieldKey="dateOfBirth"
                        isViewable={() => shownInsuranceCalculator}
                        labelTKey="calculator.label.dateOfBirth"
                        override
                    />
                    <displayFields.YearsOfDrivingField
                        fieldKey="yearsOfDriving"
                        isViewable={() => shownInsuranceCalculator}
                        labelTKey="calculator.label.yearsOfDriving"
                        override
                    />
                    <displayFields.NoClaimDiscountField
                        fieldKey="noClaimDiscount"
                        isViewable={() => shownInsuranceCalculator}
                        labelTKey="calculator.label.noClaimDiscount"
                        override
                    />
                    <displayFields.DateOfRegistrationField
                        fieldKey="dateOfRegistration"
                        isEditable={() => isFinderNew}
                        isViewable={() => shownInsuranceCalculator}
                        labelTKey="calculator.label.dateOfRegistration"
                        override
                    />
                    <displayFields.InsurancePremiumField
                        fieldKey="insurancePremium"
                        isViewable={() => shownInsuranceCalculator}
                        labelTKey="calculator.label.insurancePremium"
                        size={2}
                        override
                    />
                </InsuranceCalculator>
                {!!insuranceDisclaimers?.length && shownInsuranceCalculator && (
                    <DisclaimerWrapper>
                        <DisclaimerEventItem
                            insuranceData={insuranceData}
                            priceDisclaimers={insuranceDisclaimers}
                            selectedBankEstablishment={selectedBankEstablishment}
                            selectedDealerEstablishment={selectedDealerEstablishment}
                        />
                    </DisclaimerWrapper>
                )}
            </SectionItemContent>
        </SectionItem>
    );
};

export default SectionInsurance;
