import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { Channel } from '../../../../schema';
import ShareProvider from '../../../utils/ShareProvider';
import { UsedCalculatorStepValues } from '../../steps/UsedCalculatorStep';
import Page from './Page';
import UnitProvider from './UnitProvider';

type RouterParams = { variantId: string; unitId: string };

export type UsedCalculatorProps = {
    onSubmit: (values: UsedCalculatorStepValues) => Promise<void>;
    initialValues?: Partial<CalculatorValues>;
    dealerId: string;
    snapshot?: any;
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
};

const UsedCalculator = ({
    onSubmit,
    snapshot,
    initialValues: initialValuesFromProps,
    dealerId,
    hasTestDrive,
    hasTradeIn,
}: UsedCalculatorProps) => {
    // get the initial variant id from react router
    const { variantId: initialVariantId, unitId } = useParams<RouterParams>();

    // get initial calculator values
    const initialCalculatorValues = useMemo(
        (): Partial<CalculatorValues> => initialValuesFromProps || { variant: initialVariantId },
        [initialValuesFromProps, initialVariantId]
    );

    const initialValues = useMemo(
        () => ({
            hasTradeIn,
            hasTestDrive,
        }),
        [hasTestDrive, hasTradeIn]
    );

    return (
        <ShareProvider channel={Channel.USED} dealerId={dealerId}>
            <UnitProvider unitId={unitId}>
                {unit => (
                    <Page
                        dealerId={dealerId}
                        initialCalculatorValues={initialCalculatorValues}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        snapshot={snapshot}
                        unit={unit}
                    />
                )}
            </UnitProvider>
        </ShareProvider>
    );
};

export default UsedCalculator;
