import React, { createContext, ReactNode, useMemo } from 'react';
import { CsvConfiguratorDataFragment, GetDataQuery } from './data.graphql';

export type CsvContextData = {
    csvConfigurator: CsvConfiguratorDataFragment;
    dealers: GetDataQuery['result']['dealers'];
};

export const CsvContext = createContext<CsvContextData | null>(null);

export type CsvProviderProps = CsvContextData & {
    children?: ReactNode;
};

export const CsvProvider = ({ csvConfigurator, dealers, children }: CsvProviderProps) => {
    const memoizedCsvConfigurator = useMemo(() => ({ csvConfigurator, dealers }), [csvConfigurator, dealers]);

    return <CsvContext.Provider value={memoizedCsvConfigurator}>{children}</CsvContext.Provider>;
};

export const useCsvContext = () => {
    const context = React.useContext(CsvContext);
    if (!context) {
        throw new Error('useCsvContext must be used within a CsvProvider');
    }

    return context;
};
