import { useMemo, useCallback, useState } from 'react';

export type SortType = 'asc' | 'desc';

type UseSortingProps = {
    initialKey: string;
    initialOrder?: SortType;
};

export const revertSort = (sort: SortType) => (sort === 'asc' ? 'desc' : 'asc');

const useSorting = ({ initialKey, initialOrder = 'desc' }: UseSortingProps) => {
    const [state, setState] = useState({ key: initialKey, order: initialOrder });

    const reset = useCallback(() => setState({ key: initialKey, order: initialOrder }), [
        initialOrder,
        initialKey,
        setState,
    ]);

    const update = useCallback(
        key =>
            setState(currentState => ({
                key,
                order: key !== currentState.key ? initialOrder : revertSort(currentState.order),
            })),
        [initialOrder, setState]
    );

    return useMemo(
        () => ({
            ...state,
            update,
            reset,
        }),
        [state, update, reset]
    );
};

export default useSorting;
