import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash/fp';
import { useMemo } from 'react';
import {
    GetFlowConsentsAndDeclarationsQueryVariables,
    GetFlowConsentsAndDeclarationsQuery,
    getFlowConsentsAndDeclarations,
} from '../../../../../api/consents.graphql';
import { getPaymentConsents } from '../../../../EventFlow/utils/consents';

const usePaymentConsents = (dealerId: string) => {
    const { data: flowConsentsData, loading } = useQuery<
        GetFlowConsentsAndDeclarationsQuery,
        GetFlowConsentsAndDeclarationsQueryVariables
    >(getFlowConsentsAndDeclarations, {
        variables: {
            consent: { dealerId },
        },
    });
    const consents = useMemo(() => orderBy('order', 'asc', getPaymentConsents(flowConsentsData?.result)), [
        flowConsentsData,
    ]);

    return { consents, loading };
};

export default usePaymentConsents;
