import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues, ReduxFormContext } from 'redux-form';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import { useContentTranslation } from '../../../../../i18n';
import { CarOfInterestType } from '../../../steps/EventDraftStep';

const useDealerOptionsSelect = ({
    event,
    showDealerSelection,
}: {
    event: EventDataFragment;
    showDealerSelection: boolean;
}) => {
    const { form } = useContext(ReduxFormContext);

    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const dealerOptions = useMemo(() => event.dealers.map(({ id, name }) => ({ value: id, label: ct(name) })), [
        ct,
        event.dealers,
    ]);

    const formData: { carOfInterest?: CarOfInterestType } = useSelector(getFormValues('customer'));
    const values = formData.carOfInterest;

    const dispatch = useDispatch();

    useEffect(() => {
        if (dealerOptions.length === 1) {
            dispatch(change(form, 'carOfInterest.dealerId', dealerOptions[0].value));
        }
    }, [dealerOptions, dispatch, form]);

    useEffect(() => {
        if (values?.dealerId) {
            dispatch(change(form, 'dealerId', values.dealerId));
        }
    }, [dispatch, form, values]);

    return useCallback(
        ({ disabled }: { disabled: boolean }): JSX.Element => {
            if (showDealerSelection && dealerOptions.length > 1) {
                return (
                    <SelectField.OutlineLeft
                        disabled={disabled}
                        name="dealerId"
                        options={dealerOptions}
                        placeholder={t('eventVehicleOfInterest.placeholder.preferredDealership')}
                    />
                );
            }

            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        },
        [dealerOptions, showDealerSelection, t]
    );
};

export default useDealerOptionsSelect;
