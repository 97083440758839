// @ts-ignore
import { Modal, Actions, DarkButton, ActionsCenter } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '../../../../components/ui/Notification';

export type TTBConfirmationModalProps = {
    onClose: () => void;
    onConfirm: () => void;
};

const TTBConfirmationModal = ({ onClose, onConfirm }: TTBConfirmationModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose} title={t('ttbConfirmationModal.title')} showClose showTitle>
            <Info>{t('ttbConfirmationModal.message')}</Info>
            <Actions>
                <ActionsCenter>
                    <DarkButton onClick={onConfirm}>{t('ttbConfirmationModal.button.proceed')}</DarkButton>
                </ActionsCenter>
            </Actions>
        </Modal>
    );
};

export default TTBConfirmationModal;
