import React, { memo } from 'react';
import { Channel } from '../../../../../schema';
import { MakeDataFragment, ModelDataFragment } from '../../../../data/useLoadVariants.graphql';
import { CarModelHeader, CarModelTitle } from '../../../../ui/car-models';
import { MonthlyPaymentOption } from '../useMonthlyPaymentOptions';
import Select, { SelectValue } from './Select';
import useMakerFilter from './useMakerFilter';
import useModelFilter from './useModelFilter';
import useMonthlyPaymentFilter from './useMonthlyPaymentFilter';
import useSubModelFilter from './useSubModelFilter';

export type FilterDefinition<TValue> = {
    key: string;
    title: string;
    onChange: (option: SelectValue<TValue>) => void;
    options: SelectValue<TValue>[];
    isBack?: boolean;
    value: TValue;
};

export type FilterProps = {
    maker: string | null;
    makers: MakeDataFragment[];
    setMaker: (makerId: string | null) => void;

    model: string | null;
    models: ModelDataFragment[];
    setModel: (modelId: string | null) => void;

    subModel: string | null;
    subModels: ModelDataFragment[];
    setSubModel: (modelId: string | null) => void;

    monthlyPayment: MonthlyPaymentOption['value'] | null;
    monthlyPayments: MonthlyPaymentOption[];
    setMonthlyPayment: (monthlyPayment: MonthlyPaymentOption['value'] | null) => void;

    channel: Channel;
};

const Filter = ({
    // maker properties
    maker,
    makers,
    setMaker,

    // model properties
    model,
    models,
    setModel,

    // sub model properties
    subModel,
    subModels,
    setSubModel,

    // monthly payment properties
    monthlyPayment,
    monthlyPayments,
    setMonthlyPayment,

    // channel
    channel,
}: FilterProps) => {
    // list Index
    const filters = [
        useMakerFilter(maker, makers, model, models, subModels, setMaker),
        useModelFilter(maker, makers, model, models, subModels, setMaker, setModel),
        useSubModelFilter(maker, makers, model, models, subModel, subModels, setMaker, setModel, setSubModel),
        useMonthlyPaymentFilter(monthlyPayment, monthlyPayments, setMonthlyPayment, channel),
    ].filter(Boolean) as FilterDefinition<any>[];

    const isSingleFilter = filters.length === 1;

    const elements = filters.map((filter, index) => {
        const { key, title, ...props } = filter;

        return (
            <div key={key}>
                <CarModelTitle align={isSingleFilter ? 'center' : undefined}>
                    {isSingleFilter ? title : `${index + 1}. ${title}`}
                </CarModelTitle>
                <Select {...props} />
            </div>
        );
    });

    return <CarModelHeader>{elements}</CarModelHeader>;
};

export default memo(Filter);
