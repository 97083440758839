import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { loadRelationships } from '../../../../../actions';
import { BankPresetOption } from '../../../../../schema';
import { getRelationships } from '../../../../../selectors';
import { fromContextValidation, onTelKeyPress, onTextKeyPress, yupExt } from '../../../../../utilities/forms';
import SubTitle from '../../../../ui/SubTitle';
import usePhone from '../../../../utilities/usePhone';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

export type ReferenceDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const ReferenceDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: ReferenceDetailsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRelationships);
    }, [dispatch]);

    const relationships = useSelector(getRelationships);

    const { code: phoneCountryCode } = usePhone();

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.referenceDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        autoComplete={false}
                        label={t('customerDetails.label.referenceName')}
                        name="details.reference.name"
                        onKeyPress={onTextKeyPress}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.relationship')}
                        name="details.reference.relationship"
                        options={relationships}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.contactNo')}
                        name="details.reference.phone"
                        onKeyPress={onTelKeyPress}
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export const schema = (t: TFunction) => ({
    // @ts-ignore
    reference: yup.lazy((value, { context }) => {
        const isEnbdBankPreset =
            get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

        const baseString = yup.string();

        if (isEnbdBankPreset) {
            return yupExt.customerProperty().shape({
                name: baseString.max(200, t('common.error.lessThanOrEqual', { max: 200 })).nullable(),
                phone: fromContextValidation(
                    baseString.max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                    'phonePattern',
                    t('common.error.mobile'),
                    true
                ),
            });
        }

        return yupExt.customerProperty().shape({
            name: baseString
                .required(t('common.error.required'))
                .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
            phone: fromContextValidation(
                baseString.required(t('common.error.required')).max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                'phonePattern',
                t('common.error.mobile')
            ),
            relationship: baseString.required(t('common.error.required')),
        });
    }),
});

export default ReferenceDetails;
