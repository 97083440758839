import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router';
import { useCountry } from '../../hookSelectors';
import { Channel } from '../../schema';
import { getGlobalPermissions, getIsAuthenticated } from '../../selectors';
import { getCompanyLoginUrl } from '../../utilities/urls';
import Error404NotFound from '../routes/Error404NotFound';
import Error404Suggestion from '../routes/Error404Suggestion';
import EventRoute from '../routes/EventRoute';
import EventDetailsRoute from '../routes/EventRoute/EventDetailsRoute';
import EventsRoute from '../routes/EventRoute/list/EventsRoute';
import Header from '../shared/Header';
import { prefixEventPath as prefixPath } from './shared';

const PrivateRoute = ({ isAuthenticated, component, ...props }: any) => {
    const { pathname, search } = useLocation();
    const redirect = `${pathname}${search}`;
    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated) {
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
        }
    }, [history, isAuthenticated, redirect]);

    return <Route {...props} component={component} />;
};

const EventRouter = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const country = useCountry();
    const { isActive } = country.channelSetting.event;
    const { isActive: isNewCarActive, allowPrivateAccess } = country.channelSetting.new;
    const { isActive: isUsedCarActive, allowPrivateAccess: allowUsedCarPrivateAccess } = country.channelSetting.used;
    const { isActive: isExpressActive } = country.channelSetting.express;

    const { mayViewNewCarChannel, mayViewUsedCarChannel, mayViewExpressChannel, mayViewEventChannel } = useSelector(
        getGlobalPermissions
    );
    const mayViewOtherChannel =
        mayViewExpressChannel ||
        (mayViewNewCarChannel && allowPrivateAccess) ||
        (mayViewUsedCarChannel && allowUsedCarPrivateAccess);

    if (isAuthenticated) {
        const otherActive = isNewCarActive || isUsedCarActive || isExpressActive;

        // no permission or event channel not active
        if (!mayViewEventChannel || !isActive) {
            return mayViewOtherChannel && otherActive ? <Error404Suggestion /> : <Error404NotFound />;
        }
    }

    return (
        <>
            <Header channel={Channel.EVENT} />
            <Switch>
                <PrivateRoute component={EventsRoute} isAuthenticated={isAuthenticated} path={prefixPath('/')} exact />
                <PrivateRoute
                    component={EventDetailsRoute}
                    isAuthenticated={isAuthenticated}
                    path={prefixPath('/details/:id')}
                    exact
                />

                <Route component={EventRoute} path={prefixPath('/:eventId')} exact />
                <Redirect to={prefixPath('/')} />
            </Switch>
        </>
    );
};

export default EventRouter;
