import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { fetchRuntimeSettings } from '../api';
import { RuntimeSettingsDataFragment } from '../api/miscellaneous.graphql';
import { State } from '../reducers';

export const RUNTIME_SETTINGS_SET = '@RUNTIME_SETTINGS/SET';

export type SetRuntimeSettings = {
    type: typeof RUNTIME_SETTINGS_SET;
    settings: RuntimeSettingsDataFragment;
};

export const setRuntimeSettings = (settings: RuntimeSettingsDataFragment): SetRuntimeSettings => ({
    type: RUNTIME_SETTINGS_SET,
    settings,
});

export const initializeRuntimeSettings: ThunkAction<
    Promise<SetRuntimeSettings>,
    State,
    { client: ApolloClient<NormalizedCacheObject> },
    AnyAction
> = async (dispatch, state, { client }) => dispatch(setRuntimeSettings(await fetchRuntimeSettings(client)));

export type RuntimeSettingsActions = SetRuntimeSettings;
