import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReservationLocationState } from '../../../../flows/EventFlow/EventSubmitFlow';
import { EventExternalSite } from '../../../../schema';
import { getCompanyIdentifier } from '../../../../selectors';
import { handleResponseError } from '../../../../utilities/forms';
import { getCsvUrl, getPorscheFinderUrl } from '../../../../utilities/urls';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';

const useApplyForFinancing = () => {
    const history = useHistory();

    const { getState } = useStore();

    return useCallback(
        async (reservationApplication: ApplicationDataFragment) => {
            try {
                // get company and location code
                const { companyCode, locationCode } = getCompanyIdentifier(getState());

                switch (reservationApplication.event?.setting?.externalSite) {
                    case EventExternalSite.PORSCHEFINDER: {
                        const state: ReservationLocationState = {
                            reservation: reservationApplication,
                        };

                        // @ts-ignore
                        history.pushWithCompanyAndState(
                            getPorscheFinderUrl,
                            state,
                            reservationApplication.finderVehicle?.listing?.id
                        );
                        break;
                    }

                    case EventExternalSite.ICC: {
                        const url = getCsvUrl(
                            companyCode,
                            locationCode,
                            reservationApplication.csvConfigurator?.porscheCode
                        );
                        history.push(url, { reservationVersionId: reservationApplication.version.id });
                        break;
                    }

                    default:
                        throw new Error('Unsupported external site');
                }

                return null;
            } catch (error) {
                // process the error
                return handleResponseError(error as Error);
            }
        },
        [history, getState]
    );
};

export default useApplyForFinancing;
