import { ApolloClient } from '@apollo/client';
import { filter, omit, isEmpty } from 'lodash/fp';
import {
    AttachmentDataFragment,
    removeUnattached,
    remove,
    create,
    copy,
    update,
    UpdateMutation,
    UpdateMutationVariables,
    RemoveMutation,
    RemoveMutationVariables,
    RemoveUnattachedMutation,
    RemoveUnattachedMutationVariables,
    CopyMutation,
    CopyMutationVariables,
    CreateMutation,
    CreateMutationVariables,
} from '../../api/attachment.graphql';
import { ApplicationEventSource, UploadPurpose } from '../../schema';

export type ExistingFile = AttachmentDataFragment & { toCopy?: boolean };

export type NewFile = File & { id: string; toCopy?: boolean; purpose: UploadPurpose };

export type FileInput = ExistingFile | NewFile;

export const uploadFiles = async (
    client: ApolloClient<any>,
    files: FileInput[],
    initialFiles: FileInput[] = [],
    initialUploadId: string | null = null,
    applicationId: string | null = null,
    token: string | null = null,
    source?: ApplicationEventSource
): Promise<string | null> => {
    // split previous and new files
    // first the files we already had and we are keeping
    let existingFiles = filter(
        file => !(file instanceof File) && !isEmpty(omit(['purpose'], file)),
        files
    ) as ExistingFile[];

    // then the files we need to upload
    const newFiles = filter(file => file instanceof File, files) as NewFile[];

    let uploadId = initialUploadId;

    if (initialFiles.length !== existingFiles.length) {
        // we have files to delete
        // first check for those
        // to do so, we first list ID of files we are going to keep
        const currentIds = existingFiles.map(file => file.id);

        // look for the files for which ID we do not previously listed
        const filesToDelete = filter(
            file => !currentIds.includes(file.id) && !file.toCopy,
            initialFiles
        ) as ExistingFile[];

        // we ay now delete them
        await Promise.all(
            filesToDelete.map(file => {
                if (applicationId) {
                    return client.mutate<RemoveMutation, RemoveMutationVariables>({
                        mutation: remove,
                        variables: {
                            applicationId,
                            attachmentId: file.id,
                            token,
                        },
                    });
                }

                if (!uploadId) {
                    throw new Error('upload ID missing to remove file');
                }

                return client.mutate<RemoveUnattachedMutation, RemoveUnattachedMutationVariables>({
                    mutation: removeUnattached,
                    variables: {
                        attachmentId: file.id,
                        uploadId,
                    },
                });
            })
        );
    }

    const copyExistingFile = async ({ id }: ExistingFile) => {
        const response = (
            await client.mutate<CopyMutation, CopyMutationVariables>({
                mutation: copy,
                variables: {
                    attachmentId: id,
                    uploadId,
                },
            })
        ).data?.attachment;

        if (!response) {
            throw new Error('Failed to copy attachment');
        }

        return { ...response.attachment, uploadId: response.uploadId };
    };

    const filesToCopy = filter(file => file.toCopy, existingFiles) as ExistingFile[];

    if (filesToCopy.length) {
        // first remove those files from existing
        existingFiles = filter(file => !file.toCopy, existingFiles);

        // call api to copy
        // and replace existing files by responses
        if (!uploadId) {
            // we need to get our first upload it
            // get our first file
            const file = filesToCopy.pop();

            if (!file) {
                throw new Error('file is undefined');
            }

            // now upload it
            const { uploadId: newUploadId, ...result } = await copyExistingFile(file);
            // get the upload id
            uploadId = newUploadId;
            // add it to existing files
            existingFiles = [...existingFiles, result];
        }

        // copy other documents (if any)
        existingFiles = [...existingFiles, ...(await Promise.all(filesToCopy.map(file => copyExistingFile(file))))];
    }

    const uploadNewFile = async (file: NewFile): Promise<AttachmentDataFragment & { uploadId?: string }> => {
        if (applicationId) {
            const response = (
                await client.mutate<UpdateMutation, UpdateMutationVariables>({
                    mutation: update,
                    variables: {
                        file,
                        purpose: file.purpose,
                        id: applicationId,
                        token,
                        source,
                    },
                })
            ).data?.attachment;

            if (!response) {
                throw new Error('Failed to upload attachment');
            }

            return response;
        }

        const response = (
            await client.mutate<CreateMutation, CreateMutationVariables>({
                mutation: create,
                variables: {
                    uploadId,
                    file,
                    purpose: file.purpose,
                },
            })
        ).data?.attachment;

        if (!response) {
            throw new Error('Failed to upload attachment');
        }

        // turn it into an object
        return { ...response.attachment, uploadId: response.uploadId };
    };

    if (!uploadId && newFiles.length) {
        // we have files to upload but no upload ID yet
        // so let's get our first file and process it alone
        const file = newFiles.pop();

        if (!file) {
            throw new Error('file is undefined');
        }

        // upload the file and get its upload it
        // which we will reuse for next files to be uploaded
        const { uploadId: newUploadId, ...result } = await uploadNewFile(file);

        // add it to our list
        existingFiles = [...existingFiles, result];

        if (newUploadId) {
            // and reuse its upload it
            uploadId = newUploadId;
        }
    }

    if (newFiles.length) {
        // upload all remaining files
        existingFiles = [...existingFiles, ...(await Promise.all(newFiles.map(uploadNewFile)))];
    }

    return uploadId;
};
