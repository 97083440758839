import { load } from '@porsche-design-system/components-js';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading, setNotification } from '../../../../actions';
import { getConfig, submitPayment, getPaymentMethods } from '../../../../api/porsche';
import {
    cleanPorschePaymentSession,
    getPorschePaymentSession,
    startPorschePaymentSession,
} from '../../../../components/routes/wip/PorschePaymentCallback';
import { useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { TransactionStatus } from '../../../../utilities/constants/transactionStatus';

export type PorschePaymentConfig = {
    assortment: string;
    environment: string;
    clientKey: string;
    amount: { value: number; currency: string };
    merchantAccountCountryCode: string;
    widgetUrl: string;
    redirectUrl: string;
};

type PaymentMethods = {
    method: string;
    types: { type: string; displayName: string }[];
};

export type PorschePaymentMethods = {
    paymentMethods: PaymentMethods[];
};

type PaymentMethod = {
    typeDisplayName: string;
    encryptedCardNumber: string;
    encryptedExpiryMonth: string;
    encryptedExpiryYear: string;
    encryptedSecurityCode: string;
};

type StrongCustomerAuthenticationData = {
    browserInfo: {
        acceptHeader: string;
        colorDepth: number;
        javaEnabled: boolean;
        language: string;
        screenHeight: number;
        screenWidth: number;
        timeZoneOffset: number;
        userAgent: string;
    };
};

export type PaymentData = {
    paymentMethod: PaymentMethod;
    strongCustomerAuthenticationData: StrongCustomerAuthenticationData;
};

const usePorschePayment = (applicationId: string, channel: Channel, source: ApplicationFlowSource, token: string) => {
    const { id: zoneId } = useZone();
    const { t } = useTranslation();
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

    // manage config state
    const [config, setConfig] = useState<PorschePaymentConfig | null>(null);
    // manage status
    const [status, setStatus] = useState<TransactionStatus | null>(null);
    // manage payment data
    const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
    // manage payment methods
    const [paymentMethods, setPaymentMethods] = useState<PorschePaymentMethods | null>(null);

    // prepare fetch config
    const fetchConfig = useCallback(async () => {
        const result = await dispatch<Promise<PorschePaymentConfig>>(attachLoading(getConfig(channel, zoneId, token)));
        setConfig(result);
    }, [channel, dispatch, token, zoneId]);

    // prepare fetch config
    const fetchPaymentMethods = useCallback(async () => {
        const result = await dispatch<Promise<PorschePaymentMethods>>(attachLoading(getPaymentMethods(token)));
        setPaymentMethods(result);
    }, [dispatch, token]);

    const processStatus = useCallback(
        status => {
            switch (status) {
                case TransactionStatus.FAILED:
                    dispatch(
                        setNotification(
                            t('notification.paymentRefused.title'),
                            t('notification.paymentRefused.message')
                        )
                    );
                    break;

                case TransactionStatus.ERROR:
                    dispatch(
                        setNotification(t('notification.paymentError.title'), t('notification.paymentError.message'))
                    );
                    break;
            }

            setStatus(status);
        },
        [dispatch, t]
    );

    const onSubmit = useCallback(async () => {
        const result = await dispatch<Promise<{ id: string; status: TransactionStatus; redirectUrl: string | null }>>(
            attachLoading(submitPayment(paymentData, token))
        );

        const { status, redirectUrl } = result;

        if (redirectUrl) {
            startPorschePaymentSession(source, applicationId);
            window.location.replace(redirectUrl);
        }

        if (getPorschePaymentSession(applicationId, source)) {
            cleanPorschePaymentSession();
        }

        processStatus(status);
        setStatus(status);
    }, [applicationId, dispatch, paymentData, processStatus, source, token]);

    useEffect(() => {
        const session = getPorschePaymentSession(applicationId, source);

        if (session) {
            cleanPorschePaymentSession();
            processStatus(session.response?.status);
        }
    }, [applicationId, processStatus, source]);

    // load porsche design systems
    useEffect(() => load(), []);

    // fetch porsche payment config
    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    // fetch payment methods
    useEffect(() => {
        fetchPaymentMethods();
    }, [fetchPaymentMethods]);

    return useMemo(
        () => ({
            config,
            status,
            hasPaymentData: !!paymentData,
            onSubmit,
            setPaymentData,
            paymentMethods,
        }),
        [config, onSubmit, paymentData, status, paymentMethods]
    );
};

export default usePorschePayment;
