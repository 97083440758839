import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { setNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { getZoneId } from '../../../../selectors';
import useLoading from '../../../../useLoading';
import { searchOnKeys } from '../../../../utilities/fp';
import { getCustomerUrl } from '../../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../../shared/HelmetTitle';
import PageContainer, { Title } from '../../../ui/PageContainer';
import useListContext, { useListContextFromState } from '../../../utilities/useListContext';
import usePaging from '../../../utilities/usePaging';
import useSearch from '../../../utilities/useSearch';
import useSorting from '../../../utilities/useSorting';
import CustomerLists from './CustomerLists';
import { getCustomers } from './CustomersRoute.graphql';

const searchFields = [
    'name.value',
    'zone.code',
    'latestApplication.assignee.name',
    'latestApplication.identifier',
    'latestApplication.status',
];

const CustomersRoute = () => {
    const { t } = useTranslation();
    const { language } = useContentTranslation();
    const dispatch = useDispatch();
    // get initial list context
    const [, getInitial] = useListContextFromState();

    // get customers with apollo
    const zoneId = useSelector(getZoneId);
    const dealerId = useMustExistDealerIdFromTenantContext();
    const variables = { zoneId, dealerId, locale: language };
    const { data, loading, error: apiError } = useQuery(getCustomers, { variables, fetchPolicy: 'cache-and-network' });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    useLoading(isLoading);

    // searching comes first
    const search = useSearch('customers', getInitial('search'));
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [searchMethod, search]);

    // then sort
    const sorting = useSorting({
        initialKey: getInitial('sorting.key', 'id'),
        initialOrder: getInitial('sorting.order'),
    });
    const sortedItems = useMemo(() => orderBy(sorting.key, sorting.order, matchedItems), [matchedItems, sorting]);

    // and finally paging
    const [pagedItems, paging] = usePaging(sortedItems, { search, initialPage: getInitial('paging.page') });

    // make a context to bump/restore those configuration
    const listContext = useListContext(paging, sorting, search);

    // callback on items
    const history = useHistory();
    const onItemClick = useCallback(item => history.pushWithCompanyAndState(getCustomerUrl, { listContext }, item.id), [
        history,
        listContext,
    ]);

    const { error = null } = useLocation().state ?? {};
    useEffect(() => {
        if (error) {
            dispatch(setNotification('Error', error));
        }
    }, [dispatch, error]);

    if (apiError) {
        throw apiError;
    }

    return (
        <PageContainer>
            <HelmetTitle title="Customers" />
            <Title>{t('customersListPage.title')}</Title>
            <CustomerLists
                items={pagedItems}
                loading={isLoading}
                onItemClick={onItemClick}
                paging={paging}
                sorting={sorting}
            />
        </PageContainer>
    );
};

export default CustomersRoute;
