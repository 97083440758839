import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { getLeadsUrl } from '../../../utilities/urls';
import Title from '../../shared/form-v2/Title';
import FixedMenuFormTabContainer from '../../ui/form/FixedMenuFormTab';
import { useListContextFromState } from '../../utilities/useListContext';
import { ApplicationData } from '../ApplicationRoute';
import { ApplicationDetailContainer } from '../ApplicationRoute/Details';
import Navigation from '../NewApplicationRoutes/shared/Navigation';
import LeadDetails, { LeadFormValues } from './LeadDetails';

type DetailsProps = {
    application: ApplicationData;
    initialValues: LeadFormValues;
};

const Details = ({ application, initialValues }: DetailsProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { previous } = useLocation<{ previous: string }>().state || {};
    const prevText = useMemo(() => previous || t('leadDetailsPage.button.previous'), [previous, t]);

    const [listContext] = useListContextFromState();

    const onPrev = useCallback(() => {
        // if there is previous source
        // navigate back
        if (previous) {
            history.goBack();
        } else {
            // else go back to listing
            // @ts-ignore
            history.pushWithCompanyAndState(getLeadsUrl, { listContext });
        }
    }, [history, listContext, previous]);

    const title = useMemo(
        () => (application ? `${t('leadDetailsPage.title')} - ${application.identifier}` : t('leadDetailsPage.title')),
        [application, t]
    );

    return (
        <ApplicationDetailContainer>
            <FixedMenuFormTabContainer className="appContainer">
                <Navigation onPrev={onPrev} prevText={prevText} />
                <Title customSpace="20px">{title}</Title>
                <LeadDetails application={application} initialValues={initialValues} />
            </FixedMenuFormTabContainer>
        </ApplicationDetailContainer>
    );
};

export default Details;
