import styled from 'styled-components';
import ImgWithFallback from '../../shared/ImgWithFallback';

const VariantImage = styled(ImgWithFallback)`
    width: 100%;
    max-width: 450px;
    margin: 10px 0;
`;

export default VariantImage;
