// @ts-ignore
import { Modal, Actions, GrayButton, ActionsCenter } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { unsetNotification } from '../../actions';
import { getNotification } from '../../selectors';
import { Info } from '../ui/Notification';

const Notification = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notification = useSelector(getNotification);

    const onClose = useCallback(() => dispatch(unsetNotification()), [dispatch]);

    if (notification) {
        return (
            <Modal onClose={onClose} title={notification.title} showClose showTitle>
                {notification.message && <Info>{notification.message}</Info>}
                <Actions>
                    <ActionsCenter>
                        <GrayButton onClick={onClose}>{t('notification.button.done')}</GrayButton>
                    </ActionsCenter>
                </Actions>
            </Modal>
        );
    }

    return null;
};

export default Notification;
