import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';

const DisclaimerContainer = styled.div`
    background-color: #000000;
    padding: 10px 20px;
    margin: 0;
    color: #ffffff;
    display: flex;
    algin-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p {
        margin: 0;
        a {
            text-decoration: underline;
            color: #ffffff;
        }
    }

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 15px 100px;
    }
`;

const Disclaimer = () => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const currentYear = new Date().getFullYear();

    return (
        <DisclaimerContainer>
            <p>
                <span>© {currentYear}</span>
                <span>&nbsp;</span>
                <span>{ckdConfiguration.disclaimer.companyName}</span>
                <span>&nbsp;</span>
                <a href={ckdConfiguration.disclaimer.legalNotice.url} rel="noopener noreferrer" target="_blank">
                    {ckdConfiguration.disclaimer.legalNotice.text}
                </a>
                <span>&nbsp;</span>
                <a href={ckdConfiguration.disclaimer.privacyPolicy.url} rel="noopener noreferrer" target="_blank">
                    {ckdConfiguration.disclaimer.privacyPolicy.text}
                </a>
                <span>&nbsp;</span>
                <a href={ckdConfiguration.disclaimer.cookies.url} rel="noopener noreferrer" target="_blank">
                    {ckdConfiguration.disclaimer.cookies.text}
                </a>
                <span>&nbsp;</span>
                <span>{ckdConfiguration.disclaimer.paragraph}</span>
            </p>
        </DisclaimerContainer>
    );
};

export default Disclaimer;
