import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useZone } from '../../hookSelectors';
import {
    getInventoryUnitsByZoneId,
    GetInventoryUnitsByZoneIdQuery,
    GetInventoryUnitsByZoneIdQueryVariables,
    InventoryUnitsDataFragment,
} from './useLoadInventoryUnits.graphql';

export type InventoryUnit = InventoryUnitsDataFragment;

const useLoadInventoryUnits = (
    dealerId: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): InventoryUnit[] => {
    // get zone id
    const { id } = useZone();

    // get data using apollo
    const variables = { id, dealerId };
    const { data } = useQuery<GetInventoryUnitsByZoneIdQuery, GetInventoryUnitsByZoneIdQueryVariables>(
        getInventoryUnitsByZoneId,
        { variables, fetchPolicy }
    );

    return data?.inventoryUnits || [];
};

export default useLoadInventoryUnits;
