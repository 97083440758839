import { useApolloClient } from '@apollo/client';
import { omit, get } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { attachLoading } from '../../../../actions';
import {
    updateApplication,
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables,
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../../api/application.graphql';
import {
    CreateUsedVariantMutationVariables,
    CreateUsedVariantMutation,
    createUsedVariant,
} from '../../../../api/miscellaneous.graphql';
import { useContentTranslation } from '../../../../i18n';
import { ApplicationStatus } from '../../../../schema';
import { getCompanyIdentifier, getCountryId, getGlobalPermissions } from '../../../../selectors';
import { getCalculatorPayload } from '../../../../utilities/calculator';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { getApplicationResumeUrl, getApplicationsUrl } from '../../../../utilities/urls';
import { VariantDataFragment, VariantPreOwnedCarDataFragment } from '../../../data/useLoadVariants.graphql';
import applySourceChange from '../../../utilities/applySourceChange';
import { SubmitProvider } from '../ApplicationDetails';
import { ApplicationFormValues } from '../ApplicationForm';

const isUsedCarChanged = (
    current: VariantDataFragment,
    initial: VariantDataFragment,
    formatPath: (path: string) => string
) => {
    if (get(formatPath('model.make.name'), initial) !== get(formatPath('model.make.name'), current)) {
        return true;
    }

    if (get(formatPath('model.name'), initial) !== get(formatPath('model.name'), current)) {
        return true;
    }

    if (!current.preOwnedCarDetails || !initial.preOwnedCarDetails) {
        return false;
    }

    return Object.keys(current.preOwnedCarDetails).find(key => {
        const getWithKey = get(`preOwnedCarDetails.${key}`);

        return getWithKey(initial) !== getWithKey(current);
    });
};

const ReSubmitProvider: SubmitProvider = ({ children, application }) => {
    const { formatPath } = useContentTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useApolloClient();
    const { getState } = useStore();

    const globalPermissions = useSelector(getGlobalPermissions);
    const { mayManageCustomer } = globalPermissions;

    const applicationId = application.id;

    const isUsedCar = useMemo(() => application?.variant?.channels.express, [application]);

    const { mapIntlValue, ct, language: defaultLanguage } = useContentTranslation();

    const onSubmit = useCallback(
        async (values: ApplicationFormValues) => {
            try {
                let variantId = values.variant.id;

                if (isUsedCar && isUsedCarChanged(values.variant, application.variant, formatPath)) {
                    const countryId = getCountryId(getState()) as string;
                    const variantPromise = client.mutate<CreateUsedVariantMutation, CreateUsedVariantMutationVariables>(
                        {
                            mutation: createUsedVariant,
                            variables: {
                                data: {
                                    countryId,
                                    // for express use model name as variant name
                                    name: mapIntlValue({ [defaultLanguage]: ct(values.variant.model.name) }),
                                    makeName: mapIntlValue({ [defaultLanguage]: ct(values.variant.model.make.name) }),
                                    modelName: mapIntlValue({ [defaultLanguage]: ct(values.variant.model.name) }),
                                    preOwnedCarDetails: omit(
                                        ['vehicleLogCard', '__typename'],
                                        values.variant.preOwnedCarDetails
                                    ) as VariantPreOwnedCarDataFragment,
                                },
                            },
                        }
                    );

                    // attach loading
                    dispatch(attachLoading(variantPromise));

                    const expressVariant = (await variantPromise).data?.variant;

                    if (expressVariant?.id) {
                        variantId = expressVariant.id;
                    }
                }

                // update customer
                if (mayManageCustomer) {
                    const { customer, guarantor } = values;
                    const initialCustomer = application.customer;
                    const modifiedCustomer = applySourceChange(customer, initialCustomer);
                    const { id: customerId, type, ...customerData } = modifiedCustomer;
                    const customerPromise = client.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                        mutation: updateCustomer,
                        variables: { id: customerId, data: prepareForGraphQL(customerData) },
                    });
                    // attach loading
                    dispatch(attachLoading(customerPromise));

                    // update guarantor
                    if (guarantor) {
                        const { id: guarantorId, type, ...guarantorData } = guarantor;
                        const guarantorPromise = client.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
                            {
                                mutation: updateCustomer,
                                variables: { id: guarantorId, data: prepareForGraphQL(guarantorData) },
                            }
                        );

                        // attach loading
                        dispatch(attachLoading(guarantorPromise));
                    }
                }

                // create payload for update
                const payload: UpdateApplicationMutationVariables = {
                    id: applicationId,
                    data: {
                        // the remark
                        remark: values.remark,
                        // set proceedWithCustomerDevice policy
                        proceedWithCustomerDevice: values.proceedWithCustomerDevice,
                        // build options
                        optionIds: values.calculator.carOptions || [],
                        // finance product id and variant id comes from the calculator
                        financeProductId: values.financeProduct?.id,
                        variantId,
                        // then calculator payload
                        calculator: getCalculatorPayload(values.calculator),
                        promoCodeId: values.promoCode?.id,
                    },
                    locale: defaultLanguage,
                };

                // execute the mutation
                const promise = client.mutate<UpdateApplicationMutation, UpdateApplicationMutationVariables>({
                    mutation: updateApplication,
                    variables: prepareForGraphQL(payload),
                });

                // attach the promise with load
                dispatch(attachLoading(promise));

                // wait for it to be done
                const { data } = await promise;
                const response = data?.context;

                if (!response) {
                    // that shouldn't happen
                    throw new Error('Context missing in response');
                }

                // get the token and application
                const { token, application: newApplication } = response;

                // get company and location code
                const { companyCode, locationCode } = getCompanyIdentifier(getState());

                if (
                    !newApplication.proceedWithCustomerDevice &&
                    ![ApplicationStatus.SUBMITTED, ApplicationStatus.RECEIVED].includes(newApplication.status)
                ) {
                    // move to resume flow
                    const url = getApplicationResumeUrl(companyCode, locationCode, newApplication);
                    history.push(url, { token });

                    return null;
                }

                // go back to application list
                const url = getApplicationsUrl(companyCode, locationCode, newApplication);
                history.push(url, { submitted: true, application: newApplication });

                return null;
            } catch (error) {
                // process the error
                return handleResponseError(error as Error);
            }
        },
        [
            isUsedCar,
            application.variant,
            application.customer,
            formatPath,
            client,
            dispatch,
            applicationId,
            getState,
            history,
            mapIntlValue,
            defaultLanguage,
            ct,
            mayManageCustomer,
        ]
    );

    return children(onSubmit);
};

export default ReSubmitProvider;
