import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReservationDepositField from '../../../../components/shared/calculator/ReservationDepositField';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import PaymentStatus from '../../../../utilities/constants/paymentStatus';
import { Application } from '../../../DraftFlow';
import usePaymentCallback from '../../../DraftFlow/components/Deposit/hooks/usePaymentCallback';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { Title } from '../DraftPage/ui';
import AdyenPayment from './AdyenPayment';
import PaymentSuccess from './PaymentSuccess';

export type DepositPaymentProps = {
    application: Application;
    channel: Channel;
    depositAmount?: number;
    paymentResult?: any;
    onSubmit: () => void;
    setPaymentResult: (resultCode: any) => void;
    token: string;
    consentGiven: boolean;
    isLastStep: boolean;
    source: ApplicationFlowSource;
};

const DepositPayment = ({
    application,
    channel, // n
    depositAmount,
    token,
    setPaymentResult,
    paymentResult,
    onSubmit,
    consentGiven,
    isLastStep,
    source,
}: DepositPaymentProps) => {
    const { t } = useTranslation();
    const adyenConfig = usePaymentCallback(application.version.id, source, token, setPaymentResult);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [dropin, setDropin] = useState<any>();

    const disabled = !dropin || !consentGiven || submitDisabled;

    const submit = useCallback(() => {
        if (dropin) {
            dropin.submit();
        }
    }, [dropin]);

    useEffect(() => {
        if (paymentResult === PaymentStatus.AUTHORISED) {
            onSubmit();
        }
    }, [paymentResult, onSubmit]);

    return (
        <div>
            <Title>{t('eventConsentDepositPage.depositTitle')}</Title>
            <ReservationDepositField amount={depositAmount} channel={channel} />
            <br />
            {paymentResult !== PaymentStatus.AUTHORISED ? (
                <>
                    <AdyenPayment
                        channel={channel}
                        {...adyenConfig}
                        applePayTotalPriceLabel={t('eventConsentDepositPage.label.applePayTotalPrice')}
                        setDropin={setDropin}
                        setSubmitDisabled={setSubmitDisabled}
                        token={token}
                    />
                    <Buttons>
                        <PrimaryButton disabled={disabled} onClick={submit}>
                            <FontAwesomeIcon icon={faAngleRight} /> {t('eventConsentDepositPage.button.pay')}
                        </PrimaryButton>
                    </Buttons>
                </>
            ) : (
                <PaymentSuccess isLastStep={isLastStep} />
            )}
        </div>
    );
};

export default DepositPayment;
