import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useContentTranslation } from '../../../../i18n';
import { ApplicationPhase, Channel } from '../../../../schema';
import { getCompanyIdentifier } from '../../../../selectors';
import { getSourceLabel } from '../../../../utilities/application';
import { getLastModified, handleResponseError } from '../../../../utilities/forms';
import { getApplicationUrl, getAppointmentUrl, getInsuranceApplicationUrl } from '../../../../utilities/urls';
import {
    updateAssignee,
    UpdateAssigneeMutation,
    UpdateAssigneeMutationVariables,
} from '../../../routes/ApplicationRoute/Action.graphql';
import { ApplicationDataFragment } from '../../../routes/ApplicationRoute/data.graphql';
import LinkField from '../../../routes/AppointmentRoute/LinkField';
import useFormatDateTime from '../../useFormatDateTime';
import TextAreaField from '../TextAreaField';
import TextField from '../TextField';
import UserField from '../UserField';

export type SummaryProps = {
    disabled?: boolean;
    application: ApplicationDataFragment;
};

export const formatApplicationStatus = ({ text, extra }: { text: string; extra?: string | null }) => {
    if (extra) {
        return `${text} \r\n${extra}`;
    }

    return text;
};

const Summary = ({ application, disabled = false }: SummaryProps) => {
    const { t } = useTranslation();
    const { formatPath, ct } = useContentTranslation();
    const applicationId = application.id;
    const client = useApolloClient();
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    const status = formatApplicationStatus(application.statusText);

    const onAssigneeChange = useCallback(
        value => {
            client
                .mutate<UpdateAssigneeMutation, UpdateAssigneeMutationVariables>({
                    mutation: updateAssignee,
                    variables: { applicationId, assigneeId: value },
                })
                .catch(handleResponseError);
        },
        [client, applicationId]
    );

    const dealerIds = useMemo(() => [application.dealerId], [application]);

    // Reservation labels
    const labels = useMemo(() => {
        switch (application.phase) {
            case ApplicationPhase.RESERVATION:
                return {
                    title: t('applicationDetailsPage.subHeading.reservationDetails'),
                    id: t('applicationDetailsPage.label.reservationID'),
                    reference: t('applicationDetailsPage.label.reservationReference'),
                };

            case ApplicationPhase.FINANCE:
                return {
                    title: t('applicationDetailsPage.subHeading.applicationDetails'),
                    id: t('applicationDetailsPage.label.applicationID'),
                    reference: t('applicationDetailsPage.label.financeReference'),
                };

            default:
                return {
                    title: t('leadDetailsPage.subHeading.leadDetails'),
                    id: t('leadDetailsPage.label.leadID'),
                    reference: t('leadDetailsPage.label.leadReference'),
                };
        }
    }, [application, t]);

    const references = useMemo(() => {
        const separator = <span>/ </span>;
        const leadReference = application.lead?.version?.id && (
            <Link
                to={getApplicationUrl(companyCode, locationCode, {
                    ...application.lead,
                    phase: ApplicationPhase.LEAD,
                })}
            >
                {application.lead?.identifier}
            </Link>
        );

        const financeReference = application.financeApplication?.version?.id && (
            <Link
                to={getApplicationUrl(companyCode, locationCode, {
                    ...application.financeApplication,
                    phase: ApplicationPhase.FINANCE,
                })}
            >
                {application.financeApplication?.identifier}
            </Link>
        );

        const insuranceReference = application.insuranceApplication?.version?.id && (
            <Link
                to={getInsuranceApplicationUrl(
                    companyCode,
                    locationCode,
                    application.insuranceApplication?.version?.id
                )}
            >
                {application.insuranceApplication?.identifier}
            </Link>
        );

        const appointmentReference = application.appointment?.identifier && (
            <Link to={getAppointmentUrl(companyCode, locationCode, application.appointment.version.id)}>
                {application.appointment?.identifier}
            </Link>
        );

        const links = [leadReference, financeReference, insuranceReference, appointmentReference].filter(Boolean);

        const mappedLinks = links.map((referenceLink, index) => (
            <Fragment key={index.toString()}>
                {referenceLink}
                {index < links.length - 1 ? separator : null}
            </Fragment>
        ));

        return <div className="link-control">{mappedLinks}</div>;
    }, [application, companyCode, locationCode]);

    // format datetimes
    const formatDateTime = useFormatDateTime();
    const createdDate = formatDateTime(application.version?.createdAt);
    const lastModified = getLastModified(application.version, formatDateTime);
    const source = getSourceLabel(application.channel, application.access, t);

    return (
        <Portlet name={labels.title} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={application.identifier}
                            label={labels.id}
                            name="__exclude.applicationId"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={createdDate}
                            label={t('applicationDetailsPage.label.dateCreated')}
                            name="__exclude.createdDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={ct(application.dealer?.name)}
                            label={t('applicationDetailsPage.label.dealer')}
                            name={formatPath('__exclude.dealer.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={application.version.createdBy?.name}
                            label={t('applicationDetailsPage.label.createdBy')}
                            name="__exclude.createdBy"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserField
                            dealerIds={dealerIds}
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.assignedTo')}
                            name="assigneeId"
                            onChangeEvent={onAssigneeChange}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={source}
                            label={t('applicationDetailsPage.label.channel')}
                            name="__exclude.source"
                            disabled
                        />
                    </div>
                    {application.channel === Channel.EVENT && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                fixedValue={application.event?.name}
                                label={t('applicationDetailsPage.label.eventName')}
                                name="__exclude.event"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={lastModified}
                            label={t('applicationDetailsPage.label.lastModified')}
                            name="__exclude.lastModified"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <LinkField>
                            <div className="label">{labels.reference}</div>
                            {references}
                        </LinkField>
                    </div>
                    {application.phase === ApplicationPhase.RESERVATION && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextAreaField
                                fixedValue={status}
                                label={t('applicationDetailsPage.label.reservationStatus')}
                                name="applicationStatus"
                                disabled
                            />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default Summary;
