import React, { useMemo, createContext, useEffect, useContext, useState, ReactNode, Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as Provider, DefaultTheme } from 'styled-components';
import useMedia from 'use-media';
import { getCompany, getRuntimeSettings } from '../../selectors';
import defaultTheme from '../../utilities/constants/defaultTheme';

const ThemeOverride = createContext<Dispatch<Partial<DefaultTheme> | null>>(() => {});

export const useBankTheme = (bank: any): void => {
    const setOverride = useContext(ThemeOverride);

    useEffect(() => {
        const override: Partial<DefaultTheme> = {};

        if (bank?.color) {
            // override the color
            override.themeHighlightColour = bank.color;
            override.colors = {
                ...defaultTheme('').colors,
                activeColor: bank.color,
            };
        }

        if (bank?.logo?.url) {
            // override the logo
            override.logo = bank.logo?.url;
        }

        if (bank?.font?.url) {
            // override the font
            override.webFontUrl = bank.font?.url;
        }

        // update the override with the bank
        setOverride(override);

        return () => {
            // reset the override
            setOverride(null);
        };
    }, [bank, setOverride]);
};

const getCompanyTheme = ({ color, font, logo }: any, casing: string): DefaultTheme => ({
    ...defaultTheme(casing),

    logo: logo?.url || null,
    themeHighlightColour: color,
    webFontUrl: font?.url,

    calculator: {
        ...defaultTheme(casing).calculator,
        valueColor: color,
    },

    colors: {
        ...defaultTheme(casing).colors,
        activeColor: color,
    },
});

export type ThemeProviderProps = { children: ReactNode };

const ThemeProvider = ({ children }: ThemeProviderProps): ReactNode => {
    // get the company from redux
    const company = useSelector(getCompany);

    // get some data from runtime settings
    const { theme: themeVersion, casing } = useSelector(getRuntimeSettings);

    const [override, setOverride] = useState<Partial<DefaultTheme> | null>(null);

    const smallDisplay = useMedia({ maxWidth: 380 });

    const theme = useMemo((): DefaultTheme => {
        const nativeTheme = !company
            ? // use the default theme
              // we do not have any company with which we can override the default theme
              defaultTheme(casing)
            : // override the default theme
              getCompanyTheme(company, casing);

        return {
            ...nativeTheme,
            ...override,
            display: smallDisplay ? 'small' : 'large',
            version: themeVersion,
        };
    }, [company, casing, themeVersion, override, smallDisplay]);

    return (
        <Provider theme={theme}>
            <ThemeOverride.Provider value={setOverride}>{children}</ThemeOverride.Provider>
        </Provider>
    );
};

export default ThemeProvider;
