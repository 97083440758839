import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay, startOfMonth, startOfWeek, endOfDay, endOfMonth, endOfWeek } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Ignoring react-select types because can't install the correct types
// @ts-ignore
import Select, { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { useAppointmentListContext } from './AppointmentListContext';
import { FilterDateRange } from './reducer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    right: 16px;
    background: transparent;
    opacity: 1;
`;

const DateRangeHeaderList = (props: any) => {
    const { t } = useTranslation();

    const { children } = props;

    return (
        <components.MenuList {...props}>
            <div
                style={{
                    textAlign: 'left',
                    padding: '4px 15px',
                    fontSize: 12,
                    fontWeight: 'normal',
                    color: 'rgba(68, 68, 68, 0.5)',
                }}
            >
                {t('appointmentListPage.filter.date.header')}
            </div>
            {children}
        </components.MenuList>
    );
};

const DateRangeDropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={faFilter} />
        </components.DropdownIndicator>
    );
};

const DateRangeSelect = (props: any) => {
    const theme = useTheme();

    const { disabled = false } = props;

    const styles = useMemo(
        () => ({
            control: (provided: any) => ({
                ...provided,
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                minHeight: 'auto',
                height: '18px',
            }),
            menu: (provided: any) => ({
                ...provided,
                top: '20px',
                left: '-16px',
                right: '-16px',
                marginTop: 0,
                marginBottom: 0,
                border: '1px solid #ced4da',
                borderTop: 0,
                borderRadius: 0,
                boxShadow: 'none',
                width: 200,
            }),
            menuList: (provided: any) => ({
                ...provided,
                padding: 0,
                width: 200,
            }),
            option: (provided: any, { isSelected }: { isSelected: boolean }) => ({
                ...provided,
                fontWeight: 400,
                cursor: 'pointer',
                minHeight: '38px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                backgroundColor: 'transparent',
                color: isSelected ? theme?.themeHighlightColour : '#000000',
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#FFFFFF',
                },
                ':active': {
                    ...provided[':active'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#000000',
                },
            }),
            indicatorsContainer: () => ({
                color: 'currentColor',
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: (provided: any, { isDisabled }: { isDisabled: boolean }) => ({
                padding: '0',
                display: isDisabled ? 'none' : 'flex',
            }),
            input: (provided: any) => ({
                ...provided,
                margin: 0,
                height: '18px',
                padding: 0,
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                padding: '0',
                height: '18px',
                justifyContent: 'flex-end',
            }),
            singleValue: (provided: any, { isDisabled }: { isDisabled: boolean }) => ({
                ...provided,
                position: 'relative',
                maxWidth: 'none',
                marginLeft: 0,
                marginRight: 0,
                top: 'unset',
                transform: 'none',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                color: isDisabled ? '#444444' : theme?.themeHighlightColour,
            }),
        }),
        [theme]
    );

    return (
        <Select
            {...props}
            components={{ MenuList: DateRangeHeaderList, DropdownIndicator: DateRangeDropdownIndicator }}
            isDisabled={disabled}
            styles={styles}
        />
    );
};

const AppointmentDateFilter = () => {
    const { state, dispatch } = useAppointmentListContext();

    const { t } = useTranslation();

    const options = useMemo(() => {
        return [
            { value: FilterDateRange.TODAY, label: t('appointmentListPage.filter.date.label.today') },
            { value: FilterDateRange.WEEK, label: t('appointmentListPage.filter.date.label.thisWeek') },
            { value: FilterDateRange.MONTH, label: t('appointmentListPage.filter.date.label.thisMonth') },
        ];
    }, [t]);

    const onWrapperClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(ev => {
        // Blocking table header click event, which is sort action
        ev.preventDefault();
        ev.stopPropagation();
    }, []);

    const onSelect = useCallback(
        ({ value: optionId }: { value: FilterDateRange }) => {
            if (optionId === state.dateRangeId) {
                dispatch({
                    type: 'SET_DATE_RANGE',
                    payload: [undefined, undefined, FilterDateRange.NONE],
                });

                return;
            }

            switch (optionId) {
                case FilterDateRange.TODAY:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [startOfDay(new Date()), endOfDay(new Date()), optionId],
                    });
                    break;

                case FilterDateRange.WEEK:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [startOfWeek(startOfDay(new Date())), endOfWeek(endOfWeek(new Date())), optionId],
                    });
                    break;

                case FilterDateRange.MONTH:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [startOfMonth(startOfDay(new Date())), endOfMonth(endOfDay(new Date())), optionId],
                    });
                    break;

                default:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [undefined, undefined, optionId],
                    });
                    break;
            }
        },
        [dispatch, state.dateRangeId]
    );

    return (
        <Wrapper onClick={onWrapperClick}>
            <DateRangeSelect
                controlShouldRenderValue={false}
                isClearable={false}
                isMulti={false}
                isSearchable={false}
                onBlur={onWrapperClick}
                onChange={onSelect}
                onFocus={onWrapperClick}
                options={options}
                value={{ value: state.dateRangeId }}
            />
        </Wrapper>
    );
};

export default AppointmentDateFilter;
