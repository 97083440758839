import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { CalculatorSnapshot } from '../../../utils/getSnapshotFromApplication';
import { getDownPaymentSetting, getLoanSetting, getTermSetting } from './useRefineFinanceProducts';

const useRefineCalculatorSnapshot = (
    refinements: Partial<FinanceDataFragment> | undefined,
    snapshot?: CalculatorSnapshot | undefined,
    totalPrice?: number
): CalculatorSnapshot | undefined => {
    const noRefinement = isNil(refinements);
    const downPaymentSetting = refinements?.downPaymentSetting;
    const loanSetting = refinements?.loanSetting;
    const termSetting = refinements?.termSetting;

    return useMemo(() => {
        if (noRefinement) {
            return snapshot;
        }

        // check if no snapshot to override
        if (!snapshot) {
            return undefined;
        }

        const { financeProduct } = snapshot;

        // check if no finance product to override
        if (!financeProduct) {
            return undefined;
        }

        const selectedFinanceProduct = {
            ...financeProduct,
            downPaymentSetting: getDownPaymentSetting(
                financeProduct.downPaymentSetting,
                downPaymentSetting,
                totalPrice
            ),
            loanSetting: getLoanSetting(financeProduct.loanSetting, loanSetting, totalPrice),
            termSetting: getTermSetting(financeProduct.termSetting, termSetting),
        };

        return {
            ...snapshot,
            selectedFinanceProduct,
        };
    }, [downPaymentSetting, loanSetting, termSetting, snapshot, totalPrice, noRefinement]);
};

export default useRefineCalculatorSnapshot;
