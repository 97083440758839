import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import AvatarContainer from './AvatarContainer';
import IconButton from './IconButton';

export const MenuSidebarContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > :first-child {
        margin-left: auto;
        padding: 20px;

        color: white;
    }

    ${AvatarContainer} {
        margin: 0 20px 20px 20px;
        padding: 40px;

        border-bottom: 1px solid #fff;
    }
`;

export const StyledNavLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 45px;

    padding: 0 20px;

    text-decoration: none;
    color: #b8beca;
    font-size: 0.93rem;
    cursor: pointer;

    & ${IconButton} {
        color: #74708d;
    }

    &.active {
        border-left: 1px solid ${props => props.theme.themeHighlightColour};
        background: #0e0b20;

        ${IconButton} {
            color: white;
        }
    }
`;
