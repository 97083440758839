import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OcrImageBox } from './OcrImages';

type OcrImagePreviewProps = {
    onClick: () => void;
    src: string;
};

const OcrImagePreview = ({ onClick, src }: OcrImagePreviewProps) => (
    <OcrImageBox>
        <button onClick={onClick} type="button">
            <Icon className="time-circle" icon={faTimesCircle} />
        </button>
        <img alt="" src={src} />
    </OcrImageBox>
);

export default OcrImagePreview;
