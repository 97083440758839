import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingLayer from '../../../common/LoadingLayer';
import { Modal } from '../../../ui/DownloadModal';
import { TextAlignCenter } from '../../../ui/TextAlign';

type DownloadModalProps = {
    onClose: () => void;
    password: string;
};

const DownloadModal = ({ onClose, password }: DownloadModalProps) => {
    const { t } = useTranslation();
    if (password) {
        return (
            <Modal onClose={onClose} title={t('downloadModal.label.password')} showClose showTitle>
                <TextAlignCenter>{password}</TextAlignCenter>
            </Modal>
        );
    }

    return <LoadingLayer />;
};

export default DownloadModal;
