import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ChannelSettingDataFragment } from '../../../../components/data/useLoadScope.graphql';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import { Container } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import PaymentStatus from '../../../../utilities/constants/paymentStatus';
import DepositConsentForm, { schema } from '../../../EventFlow/components/ConsentDeposit/ConsentForm';
import { PageTitle, Title } from '../../../EventFlow/components/DraftPage/ui';
import { BackStepContext } from '../../../utils/flow';
import { Application } from '../../types';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import AdyenPayment from './AdyenPayment';
import PaymentAmount from './PaymentAmount';
import PaymentSuccess from './PaymentSuccess';
import usePaymentCallback from './hooks/usePaymentCallback';
import usePaymentConsents from './hooks/usePaymentConsents';

export const PaymentContainer = styled.div`
    margin: auto;
    max-width: 100%;

    @media (min-width: 1025px) {
        max-width: 80%;
        padding: 0 30px;
    }
`;

export type PageProps = {
    application: Application;
    token: string;
    dealerId: string;
    channel?: Channel;
    onSubmit: (values?: any) => Promise<void>;
    paymentResult?: any;
    setPaymentResult: (resultCode: any) => void;
    backStep?: BackStepContext;
    isLastStep: boolean;
    source: ApplicationFlowSource;
};

const Deposit = ({
    onSubmit,
    paymentResult,
    setPaymentResult,
    backStep,
    isLastStep,
    application,
    token,
    source,
    dealerId,
    channel = Channel.NEW,
}: PageProps) => {
    const { t } = useTranslation();
    const adyenConfig = usePaymentCallback(application.version.id, source, token, setPaymentResult);
    const [consentGiven, setConsentGiven] = useState(false);
    const [referenceId, setReferenceId] = useState<string>();

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [dropin, setDropin] = useState<any>();

    const submit = useCallback(() => {
        if (dropin) {
            dropin.submit();
        }
    }, [dropin]);

    const country = useSelector(getCurrentCountry);
    useEffect(() => {
        if (paymentResult === PaymentStatus.AUTHORISED) {
            onSubmit();
        }
    }, [paymentResult, onSubmit]);

    const { consents, loading: flowConsentsLoading } = usePaymentConsents(dealerId);

    const validate = useValidation(schema(consents));

    const hasDeposit = country?.channelSetting[channel.toLowerCase() as keyof ChannelSettingDataFragment];
    const hasConsents = hasDeposit ? !!consents?.length : false;

    if (flowConsentsLoading) {
        // can't do yet
        return null;
    }

    return (
        <div>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <PageTitle>{t('depositPage.title')}</PageTitle>
            <PaymentContainer>
                <div className="row">
                    {hasConsents && (
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <Container>
                                <Title>{t('eventConsentDepositPage.paymentConsentTitle')}</Title>
                                <DepositConsentForm
                                    consents={consents}
                                    referenceId={referenceId}
                                    setReferenceId={setReferenceId}
                                    setValid={setConsentGiven}
                                    showNextButton={!hasDeposit}
                                    token={token}
                                    validate={validate}
                                />
                            </Container>
                        </div>
                    )}
                    <div className={hasConsents ? 'col-md-6 col-sm-12 col-xs-12' : 'col-md-12 col-sm-12 col-xs-12'}>
                        <Container>
                            <Title>{t('eventConsentDepositPage.depositTitle')}</Title>
                            <PaymentAmount channel={application.channel} />
                            {paymentResult !== PaymentStatus.AUTHORISED && (
                                <>
                                    <AdyenPayment
                                        disabled={hasConsents && !consentGiven}
                                        {...adyenConfig}
                                        applePayTotalPriceLabel={t('depositPage.label.applePayTotalPrice')}
                                        channel={application.channel}
                                        setDropin={setDropin}
                                        setSubmitDisabled={setSubmitDisabled}
                                        token={token}
                                    />
                                    <Buttons>
                                        <PrimaryButton disabled={submitDisabled} onClick={submit}>
                                            <FontAwesomeIcon icon={faAngleRight} />{' '}
                                            {t('eventConsentDepositPage.button.pay')}
                                        </PrimaryButton>
                                    </Buttons>
                                </>
                            )}

                            {paymentResult === PaymentStatus.AUTHORISED && <PaymentSuccess isLastStep={isLastStep} />}
                        </Container>
                    </div>
                </div>
            </PaymentContainer>
        </div>
    );
};

export default Deposit;
