// @ts-ignore
import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { DarkButton, Actions } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import { get, toPairs } from 'lodash/fp';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import * as yup from 'yup';
import { attachLoading } from '../../../../actions';
import {
    updateConsent,
    UpdateConsentMutation,
    UpdateConsentMutationVariables,
} from '../../../../api/application.graphql';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import { AdhocEventType } from '../../../../schema';
import { ConsentStepValues } from '../../steps/ConsentStep';
import ConsentField from './ConsentField';
import ConsentNonInteractiveField from './ConsentNonInteractiveField';

export type ConsentFormValues = Partial<ConsentStepValues>;

export const renderConsent = (consent: ConsentDataFragment) => {
    const { hasCheckbox, id } = consent;

    if (hasCheckbox) {
        return <ConsentField key={id} consent={consent} name={`consents.${id}`} />;
    }

    return <ConsentNonInteractiveField key={id} consent={consent} name={`consents.${id}`} />;
};

const useConsentChangeEffect = (change: InjectedFormProps['change']) => {
    const { t } = useTranslation();
    const { consents, referenceId }: ConsentFormValues = useSelector(getFormValues('consent')) || {};
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const client = useApolloClient();
    const previousConsents = useRef(consents);

    useEffect(() => {
        toPairs(consents || []).forEach(async ([uniqueId, value]) => {
            if (get(uniqueId, previousConsents.current) !== value) {
                // prepare variables
                const variables = {
                    data: {
                        type: value ? AdhocEventType.CHECK : AdhocEventType.UNCHECK,
                        referenceId,
                        detail: { id: uniqueId },
                    },
                };

                // call the API
                const promise = client
                    .mutate<UpdateConsentMutation, UpdateConsentMutationVariables>({
                        mutation: updateConsent,
                        variables,
                    })
                    .then(apiResponse => apiResponse?.data?.consents);

                // attach the promise and wait
                const response = await dispatch<typeof promise>(attachLoading(promise));

                if (!response) {
                    throw new Error('Failed to sign consent');
                }

                // eventId is for consent referenceId
                change('referenceId', response.referenceId);
            }
        });

        previousConsents.current = consents;
    }, [consents, dispatch, referenceId, client, change, t]);
};

export type ConsentFormProps = {
    consents: ConsentDataFragment[];
    isLastStep: boolean;
};

const ConsentForm = ({
    consents,
    valid,
    handleSubmit,
    change,
    isLastStep,
}: ConsentFormProps & InjectedFormProps<ConsentFormValues, ConsentFormProps>) => {
    const { t } = useTranslation();
    useConsentChangeEffect(change);

    return (
        <>
            {consents.map(renderConsent)}
            <Actions>
                <DarkButton disabled={!valid} onClick={handleSubmit} type="button" value="submit">
                    {isLastStep ? t('consentPage.button.submit') : t('consentPage.button.next')}
                </DarkButton>
            </Actions>
        </>
    );
};

export const consentValidation = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((values: any, options: any) => {
        const consents = get('context.consents', options) as ConsentDataFragment[];

        if (!consents) {
            throw new Error('Consent missing in state/props');
        }

        const mandatoryConsents: { [id: string]: yup.BooleanSchema } = {};

        consents.forEach(consent => {
            if (consent.isMandatory && consent.hasCheckbox) {
                mandatoryConsents[consent.id] = yup
                    .boolean()
                    .default(false)
                    .oneOf([true], t('consentPage.error.consents'));
            }
        });

        if (!Object.keys(mandatoryConsents).length) {
            // no mandatory consents
            return yup.mixed().default(undefined);
        }

        return yup.object().default({}).shape(mandatoryConsents);
    });

export const schema = (t: TFunction) => yup.object().shape({ consents: consentValidation(t) });

export default reduxForm<ConsentFormValues, ConsentFormProps>({
    form: 'consent',
})(ConsentForm);
