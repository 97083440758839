import { useQuery } from '@apollo/client';
import { flatMap, flow, get, map, uniq } from 'lodash/fp';
import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';
import useMedia from 'use-media';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { PromoDataFragment } from '../../../../components/data/useLoadPromo.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { BackStepContext } from '../../../utils/flow';
import { PageContainer } from '../Configurator/ConfigForm';
import Header from '../Configurator/Header';
import { TequipmentMaterials } from '../Configurator/Tequipment';
import { TimepieceValues } from '../Configurator/Timepiece';
import useExteriorImage from '../Configurator/shared/useExteriorImage';
import EmailPopup from './EmailPopup';
import GoBackButton from './GoBackButton';
import OrderDetails from './OrderDetails';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import { getData, GetDataQuery, GetDataQueryVariables } from './data/data.graphql';
import noImg from '../../../../assets/images/no-image.png';

export const SummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        flex-direction: row;
        .summary {
            display: flex !important;
            flex-direction: column !important;
            justify-content: flex-start;
            max-width: 35vw;
            width: 100%;
        }
    }
`;

const ImageContainer = styled.div`
    width: 100vw;
    img {
        object-fit: cover;
        margin-bottom: 30px;
        width: 100vw;
        height: 35vh;
    }

    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        img {
            height: 60vh;
        }
    }

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        width: 70vw;
        height: 90vh;
        position: sticky;
        bottom: 0;
        top: 10vh;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: 0;
        }
    }
`;

export type MiniConfiguratorDetails = {
    exteriorColor: string;
    interiorColor: string;
    enhancedPackage: boolean;
    tequipment?: TequipmentMaterials;
    timepiece?: TimepieceValues;
    variantId?: string;
    delivery: string;
    dealerId?: string;
    promoCode?: string;
    promo?: PromoDataFragment;
};

export type SummaryFormProps = {
    initialData: MiniConfiguratorDetails;
    backStep?: BackStepContext;
    events: EventDataFragment[];
    defaultFinanceProduct: FinanceDataFragment;
    showSaveOrder?: boolean;
    updatePromoCode: (promo?: PromoDataFragment, promoCode?: string) => void;
    updateDealerSelection: (dealerId: string) => void;
};

export type SummaryFormValues = {
    dealerId: string;
    email: string;
    promoCode: string;
    promo?: PromoDataFragment;
};

const Summary = ({
    initialData,
    backStep,
    form,
    events,
    defaultFinanceProduct,
    updatePromoCode,
    updateDealerSelection,
}: SummaryFormProps & InjectedFormProps<SummaryFormValues, SummaryFormProps>) => {
    const { id: countryId } = useCountry();
    const { ct } = useContentTranslation();
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const [displayEmailPopup, setDisplayEmailPopup] = useState(false);
    const [displayTerms, setDisplayTerms] = useState(false);
    const summaryImage = useExteriorImage(initialData.exteriorColor, initialData.interiorColor);

    // to scroll up when coming from bottom of configurator page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // fetch dealer data
    const { data } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables: { countryId },
    });

    // then map as options
    const dealerOptions = useMemo(() => {
        if (!data?.dealers) {
            return [];
        }

        const eventDealerIds = flow(flatMap(get('dealers')), map(get('id')), uniq)(events);

        return (
            data.dealers.items
                .filter(({ id }) => eventDealerIds.includes(id))
                .map(({ id, name }) => ({ value: id, label: ct(name) })) || []
        );
    }, [data, events, ct]);

    return (
        <PageContainer isBlur={displayEmailPopup || displayTerms}>
            <div className="pageWrapper">
                <Header />
                <SummaryWrapper>
                    <ImageContainer>
                        <ImgWithFallback alt="Selected Car" fallbackSrc={noImg} src={summaryImage} />
                    </ImageContainer>
                    {backStep && isMobile && <GoBackButton backStep={backStep} />}
                    <div className="summary">
                        {backStep && !isMobile && <GoBackButton backStep={backStep} />}
                        <OrderDetails
                            defaultFinanceProduct={defaultFinanceProduct}
                            events={events}
                            form={form}
                            initialData={initialData}
                            setDisplayEmailPopup={setDisplayEmailPopup}
                            setDisplayTerms={setDisplayTerms}
                            updateDealerSelection={updateDealerSelection}
                            updatePromoCode={updatePromoCode}
                        />
                    </div>
                </SummaryWrapper>
            </div>
            {displayEmailPopup && (
                <EmailPopup
                    data={initialData}
                    dealerOptions={dealerOptions}
                    defaultFinanceProduct={defaultFinanceProduct}
                    events={events}
                    setButton={setDisplayEmailPopup}
                />
            )}
            {displayTerms && (
                <TermsAndConditionsModal
                    body={ckdConfiguration.summary.termsAndConditions.body}
                    headline={ckdConfiguration.summary.termsAndConditions.headline}
                    setDisplayTerms={setDisplayTerms}
                />
            )}
        </PageContainer>
    );
};

export default reduxForm<SummaryFormValues, SummaryFormProps>({
    form: 'summary',
})(Summary);
