import { Calculator, coerce, FinanceProductDocument, MonthlyInstalment } from '@appvantageasia/afc-calculator-next';
import { TFunction } from 'i18next';
import { ContentTranslator } from '../../../../i18n';
import { FinanceDataFragment } from '../../../data/useFinanceProducts.graphql';
import { VariantDataFragment } from '../../../data/useLoadVariants.graphql';
import { getBalloon, getCompoundValues, getInterestRate, getLoan } from './utils';

export const calculateMonthlyInstallment = (
    financeProduct: FinanceDataFragment,
    variant: { id: string; price: number; versionId: string }
) => {
    if (financeProduct === null) {
        return 0;
    }

    const query = generateQuery(financeProduct, {
        id: variant.id,
        price: variant.price,
        versionId: variant.versionId,
    });

    try {
        const coercedFinanceProduct: FinanceProductDocument = coerce(financeProduct);
        const calculator = new Calculator(coercedFinanceProduct);
        calculator.calculate(query);

        const monthlyInstalment = calculator.root.seek(MonthlyInstalment);
        const monthlyInstalmentValue = Array.isArray(monthlyInstalment.value)
            ? monthlyInstalment.value[0]
            : monthlyInstalment.value;

        return (monthlyInstalmentValue as number) || 0;
    } catch (error) {
        console.error(error);

        return 0;
    }
};

export const generateQuery = (
    financeProduct: FinanceDataFragment,
    variantDetails: { price: number; id: string; versionId: string }
): any => {
    const { paymentMode, averageMileageSetting, termSetting, downPaymentSetting } = financeProduct;
    const { price: variantPrice, id: varaintId, versionId: varaintVersionId } = variantDetails;

    const downPayment = getCompoundValues(
        variantDetails.price,
        downPaymentSetting.default,
        downPaymentSetting.defaultUnit
    );
    const loan = getLoan(variantDetails.price, downPayment);

    return {
        paymentMode: paymentMode?.mode,
        variant: varaintId,
        price: variantPrice,
        mileage: averageMileageSetting ? averageMileageSetting.default : 1000,
        term: termSetting.default,
        downPayment,
        loan,
        balloon: getBalloon(financeProduct, {
            totalPrice: variantPrice,
            loan,
            paymentTerm: termSetting.default,
            variantId: varaintVersionId,
        }),
        interestRate: getInterestRate(financeProduct, termSetting.default, downPayment),
    };
};

export type ProcessedVariant = VariantDataFragment & {
    monthlyPrice: number;
    priceDescription: string;
    unit?: any;
};

const processVariantWithFormattedPrices = (
    financeProduct: FinanceDataFragment | null,
    roundNumber: (value: number) => number,
    formatCurrency: (value: number, currency?: string) => string,
    currencySymbol: string,
    zoneId: string,
    t: TFunction,
    ct: ContentTranslator
) => (variant: VariantDataFragment): ProcessedVariant => {
    const variantPrice = variant.prices.find(i => i.zoneId === zoneId)?.value || 0;

    // get the monthly instalment for this variant (based on its price)
    const monthly = financeProduct
        ? calculateMonthlyInstallment(financeProduct, {
              id: variant.id,
              price: variantPrice,
              versionId: variant.version.id,
          })
        : 0;

    // format the whole thing
    const formattedMonthlyPrice = formatCurrency(monthly, currencySymbol);
    const formattedTotalPrice = formatCurrency(variantPrice, currencySymbol);

    return {
        ...variant,
        name: ct(variant.name),
        monthlyPrice: roundNumber(monthly),
        priceDescription: t('chooserPage.variantPrice', {
            totalPrice: formattedTotalPrice,
            monthlyPrice: formattedMonthlyPrice,
        }),
    };
};

export default processVariantWithFormattedPrices;
