import { addDays } from 'date-fns';
import { TFunction } from 'i18next';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import DateField from '../../../../../components/shared/form-v2/DateField';
import FileFieldArray from '../../../../../components/shared/form-v2/FileFieldArray';
import IdentityNumberField from '../../../../../components/shared/form-v2/IdentityNumberField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useBankPresetLabel from '../../../../../components/utilities/useBankPresetLabel';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import { yupExt, onNumKeyPress } from '../../../../../utilities/forms';
import { Title } from '../ui';
import DrivingLicense, { schema as drivingLicenseSchema } from './DrivingLicense';

const watchedFields = [
    'identityNumber',
    'details.passport',
    'details.drivingLicense',
    'details.emiratesIdExpiry',
    'details.uaeResidentSince',
    {
        path: 'details.passport',
        properties: ['number', 'expiry'],
    },
    'details.thirdParty.enbdBank.visaNumber',
    'details.thirdParty.enbdBank.visaIssueDate',
    'details.thirdParty.enbdBank.visaExpiryDate',
];

type CustomerInformationFormProps = {
    disabled?: boolean;
    bankPresetOption?: BankPresetOption;
};

const IdentityDetails = ({ disabled = false, bankPresetOption }: CustomerInformationFormProps) => {
    const { t } = useTranslation();
    const { sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);
    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    return (
        <>
            <Title>{t('kycPage.identityDetailsTitle')}</Title>
            <TextField
                disabled={disabled || fieldState.details.passport}
                label={t('customerDetails.label.passportNumber')}
                name="details.passport.number"
            />
            <DateField
                disabled={disabled || fieldState.details.passport}
                label={t('customerDetails.label.passportExpiry')}
                name="details.passport.expiry"
            />
            <IdentityNumberField
                disabled={disabled || fieldState.identityNumber}
                label={getBankPresetLabel('emiratesIdNumber')}
                name="identityNumber.value"
                onKeyPress={onNumKeyPress}
                placeholder={t('customerDetails.placeholder.identityNumber')}
            />
            <DateField
                disabled={disabled || fieldState.details.emiratesIdExpiry}
                label={getBankPresetLabel('emiratesIdExpiry')}
                name="details.emiratesIdExpiry.value"
            />
            <DrivingLicense />
            <DateField
                disabled={disabled || fieldState.details.uaeResidenceSince}
                label={getBankPresetLabel('residentSince')}
                name="details.uaeResidentSince.value"
            />
            {isEnbdBankPreset && (
                <>
                    <TextField
                        disabled={disabled || fieldState.details?.thirdParty?.enbdBank?.visaNumber}
                        label={getBankPresetLabel('visaNumber')}
                        name="details.thirdParty.enbdBank.visaNumber.value"
                    />
                    <DateField
                        disabled={disabled || fieldState.details?.thirdParty?.enbdBank?.visaIssueDate}
                        label={getBankPresetLabel('visaIssueDate')}
                        maxDate={new Date()}
                        name="details.thirdParty.enbdBank.visaIssueDate.value"
                    />
                    <DateField
                        disabled={disabled || fieldState.details?.thirdParty?.enbdBank?.visaExpiryDate}
                        label={getBankPresetLabel('visaExpiryDate')}
                        minDate={new Date()}
                        name="details.thirdParty.enbdBank.visaExpiryDate.value"
                    />
                </>
            )}
            <FileFieldArray
                details={`(${getBankPresetLabel('frontAndBackOfDocuments')})`}
                disabled={disabled}
                forPurpose={UploadPurpose.CUSTOMER_IDENTITY}
                label={t('customerDetails.label.uploadSupportingDocument')}
                name="identityFiles"
                rerenderOnEveryChange
            />
        </>
    );
};

export default IdentityDetails;

export type LicenseSchema = {
    expiryDate: yup.StringSchema<string, object>;
    number: yup.StringSchema<string, object>;
    type: yup.StringSchema<string, object>;
};

export const identityEnbdSchema = (t: Function) => ({
    visaNumber: yupExt.customerProperty().shape({
        value: yup.string().nullable().notRequired(),
    }),
    visaIssueDate: yupExt.customerProperty().shape({
        value: yup.date().nullable().notRequired(),
    }),
    visaExpiryDate: yupExt.customerProperty().shape({
        value: yup.date().nullable().notRequired(),
    }),
});

export const detailsSchema = (t: TFunction) => ({
    ...drivingLicenseSchema(t),
    passport: yupExt.customerProperty().shape({
        expiry: yup.string().required(t('common.error.required')),
        number: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
    }),
    emiratesIdExpiry: yupExt.customerProperty().shape({
        value: yup.string().required(t('common.error.required')),
    }),
    uaeResidentSince: yup.string().required(t('common.error.required')),
});
