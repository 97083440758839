import { useQuery } from '@apollo/client';
import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import React, { ComponentType, memo, useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useCountry } from '../../../hookSelectors';
import useLoading from '../../../useLoading';
import { getPromo, GetPromoQuery, GetPromoQueryVariables } from '../../data/useLoadPromo.graphql';
import CompanyCalculator, { CompanyCalculatorProps } from './CompanyCalculator';
import promoComputingFields from './promoComputingFields';
import { CalculatorValues } from './types';

export type PromoCodeCalculatorProps = CompanyCalculatorProps & {
    calculatorComponent?: ComponentType<CompanyCalculatorProps>;
    dealerId: string;
};

const PromoCodeCalculator = ({
    dealerId,
    calculatorComponent: Calculator = CompanyCalculator,
    fields = promoComputingFields,
    initialValues,
    onChange,
    meta,
    ...props
}: PromoCodeCalculatorProps) => {
    const { promoCode: initPromoCode } = initialValues;
    const { id: countryId } = useCountry();

    const changeRef = useRef<CalculatorContext<CalculatorValues>['change']>();

    const [promoCode, setPromoCode] = useState(initPromoCode);
    const { data, loading } = useQuery<GetPromoQuery, GetPromoQueryVariables>(getPromo, {
        skip: !promoCode,
        variables: {
            countryId,
            identifier: promoCode as string,
            dealerIds: [dealerId],
        },
        fetchPolicy: 'cache-first',
    });

    useLoading(loading);

    // callback to changes on the calculator
    const onChangeEnhanced = useCallback(
        async (context: CalculatorContext<CalculatorValues>) => {
            if (promoCode !== context.values.promoCode) {
                setPromoCode(context.values.promoCode);
            }

            if (!changeRef.current) {
                changeRef.current = context.change;
            }

            if (onChange) {
                onChange(context);
            }
        },
        [promoCode, onChange]
    );

    useEffect(() => {
        // the promoCode is already updated in the calculator
        // this change don't trigger an update
        // so whenever we receive new promo data
        // we need to recalculate again
        if (changeRef.current) {
            changeRef.current('totalPrice', undefined);
        }
    }, [data]);

    const enhancedMeta = useMemo(() => {
        const promo = data?.promo;

        return { ...meta, promo };
    }, [data, meta]);

    return (
        <Calculator
            {...props}
            fields={fields}
            initialValues={initialValues}
            meta={enhancedMeta}
            onChange={onChangeEnhanced}
        />
    );
};

export default memo(PromoCodeCalculator);
