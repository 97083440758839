import * as exportApi from '../api/export';
import { getAccessToken, getContext } from '../selectors';
import { withLoading } from './loading';

export const exportFactory = exportMethod => (...args) => (dispatch, getState) => {
    // get data from state
    const state = getState();
    const token = getAccessToken(state);
    const context = getContext(state);

    // build http headers
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // get the promise
    const promise = exportMethod(context, headers, ...args);

    return dispatch(withLoading(promise));
};

export const exportApplications = exportFactory(exportApi.exportApplications);

export const exportInsuranceApplications = exportFactory(exportApi.exportInsuranceApplications);

export const exportAppointments = exportFactory(exportApi.exportAppointments);

export const exportEventsApplication = exportFactory(exportApi.exportEventsApplication);

export const exportApplicationDocuments = exportFactory(exportApi.exportApplicationDocuments);
