import styled from 'styled-components';

const ChatTitle = styled.div`
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 18px;
`;

export default ChatTitle;
