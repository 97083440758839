import PropTypes from 'prop-types';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import ConsentTitle from '../ConsentTitle';
import { ReactComponent as Checked } from '../../../assets/images/boxChecked.svg';
import { ReactComponent as Unchecked } from '../../../assets/images/boxUnchecked.svg';

const Checkbox = styled.div`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    svg {
        width: 25px;
    }

    input {
        display: none;
    }
`;

const LabelWrapper = styled.label`
    display: flex;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 2;
    padding-left: 10px;
    text-align: left;
    color: #000;
`;

const PrettyCheckbox = ({ name, checked = false, label, children, disabled = false, onClick }) => {
    const theme = useTheme();

    return (
        <div>
            {label ? <ConsentTitle>{label}</ConsentTitle> : null}
            <LabelWrapper>
                <Checkbox disabled={disabled} htmlFor={name} onClick={onClick && !disabled ? onClick : undefined}>
                    {checked ? <Checked fill={theme.themeHighlightColour} /> : <Unchecked />}
                    <input checked={checked} name={name} readOnly />
                </Checkbox>
                <Description>{children}</Description>
            </LabelWrapper>
        </div>
    );
};

PrettyCheckbox.propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default PrettyCheckbox;
