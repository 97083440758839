import {
    CalculatorContext,
    CalculatorFields,
    GridCalculator,
    GridCalculatorProps,
    useCalculatorContext,
    defaultInsuranceComputingFields,
    defaultInsurancePreset,
    InsuranceValues,
} from '@appvantageasia/afc-calculator-ui-next';
import { isEqual } from 'lodash/fp';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../i18n';
import { Channel } from '../../../schema';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import { FinderVehicleDataFragment } from '../../routes/FinderRoute/data.graphql';
import useCompanyFormatting, { Formats } from '../../utilities/useCompanyFormatting';
import useInsurancePremium, { isInsuranceValidDateOfBirth } from '../../utilities/useInsurancePremium';
import { useCompanyCalculatorTheme } from './CompanyCalculator';
import { CalculatorInsuranceValues, CalculatorMeta, CalculatorValues } from './types';

export type CompanyCalculatorProps = {
    channel: Channel;
    dealerId: string;
    onChange?: (context: CalculatorContext<CalculatorInsuranceValues>) => void;
    onInsurancePremiumChange: (value: { insurancePremiumAmount: number; errors: InsuranceCalculatorErrors }) => void;
    initialValues: Partial<CalculatorValues>;
    fields?: CalculatorFields<CalculatorInsuranceValues>;
    meta: Partial<CalculatorMeta>;
    variants: VariantDataFragment[];
    children?: ReactNode;
    preset?: JSX.Element;
    finderVehicle?: FinderVehicleDataFragment | null;
} & Omit<
    GridCalculatorProps<CalculatorInsuranceValues>,
    'context' | keyof Formats | 't' | 'ct' | 'language' | 'children'
>;

export type InsuranceCalculatorErrors = Partial<Record<keyof InsuranceValues, string>>;

const InsuranceCalculator = ({
    channel,
    dealerId,
    onChange,
    onInsurancePremiumChange,
    initialValues,
    fields = defaultInsuranceComputingFields,
    meta,
    children,
    variants,
    preset = defaultInsurancePreset,
    finderVehicle,
    ...props
}: CompanyCalculatorProps) => {
    const { t } = useTranslation();
    const { ct, language } = useContentTranslation();
    // get formatting
    const formats = useCompanyFormatting();
    const theme = useCompanyCalculatorTheme(channel);

    // Change it to change ref
    const changeRef = useRef<CalculatorContext<CalculatorInsuranceValues>['change']>();

    const enhancedMeta = useMemo(
        () => ({
            variants,
            ...meta,
        }),
        [meta, variants]
    );

    const context = useCalculatorContext<CalculatorInsuranceValues>(initialValues, fields, enhancedMeta);
    const [values, setValues] = useState<Partial<InsuranceValues> | null>(null);

    const { insurancePremiumAmount, loading } = useInsurancePremium(channel, dealerId, values, {
        finderVehicle,
    });

    useEffect(() => {
        const { values: newValues } = context;

        if (!isEqual(values, newValues)) {
            setValues(newValues);
        }

        if (onChange) {
            onChange(context);
        }

        if (!changeRef.current) {
            changeRef.current = context.change;
        }
    }, [context, onChange, values]);
    const insuranceDateOfBirth = useMemo(() => values?.dateOfBirth, [values]);

    useEffect(() => {
        if (!loading && changeRef.current) {
            changeRef.current?.('insurancePremium', insurancePremiumAmount);

            const errors: InsuranceCalculatorErrors = {};
            if (!isInsuranceValidDateOfBirth(insuranceDateOfBirth)) {
                errors.dateOfBirth = t('calculator.error.insuranceMinAge');
            } else if (!insurancePremiumAmount) {
                errors.insurancePremium = t('calculator.error.insurancePremium');
            }

            onInsurancePremiumChange({
                insurancePremiumAmount,
                errors,
            });
        }
    }, [loading, insurancePremiumAmount, onInsurancePremiumChange, t, insuranceDateOfBirth]);

    return (
        <GridCalculator context={context} theme={theme} {...formats} {...props} ct={ct} language={language} t={t}>
            {preset}
            {children}
        </GridCalculator>
    );
};

export default InsuranceCalculator;
