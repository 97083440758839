// permission factory for global permission
import { mapValues } from 'lodash/fp';
import { Channel, PermissionCode, PermissionLevel } from '../schema';

const checkGlobalPermission = (...expectedPermissions) => (user, countryId) => {
    if (!user) {
        // unauthenticated user
        return false;
    }

    const { permissions: permissionList, isSuperUser } = user;

    if (isSuperUser) {
        // super users have all permissions anyway
        return true;
    }

    // get permission based on current country
    if (countryId) {
        const userPermission = permissionList.find(permission => permission.countryId === countryId);
        const { role } = userPermission;

        const { permissions } = role;

        const mapPermissions = permissions.map(i => `${i.code}_${i.level}`);

        for (const permission of expectedPermissions) {
            if (mapPermissions.includes(permission)) {
                return true;
            }
        }
    }

    return false;
};

export const mayViewReservation = checkGlobalPermission(
    `${PermissionCode.CI_RESERVATIONS}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_RESERVATIONS}_${PermissionLevel.MANAGE}`
);

export const mayManageReservation = checkGlobalPermission(
    `${PermissionCode.CI_RESERVATIONS}_${PermissionLevel.MANAGE}`
);

export const mayViewApplication = checkGlobalPermission(
    `${PermissionCode.CI_APPLICATIONS}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_APPLICATIONS}_${PermissionLevel.MANAGE}`
);

export const mayManageApplication = checkGlobalPermission(
    `${PermissionCode.CI_APPLICATIONS}_${PermissionLevel.MANAGE}`
);

export const mayViewLead = checkGlobalPermission(
    `${PermissionCode.CI_LEADS}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_LEADS}_${PermissionLevel.MANAGE}`
);

export const mayManageLead = checkGlobalPermission(`${PermissionCode.CI_LEADS}_${PermissionLevel.MANAGE}`);

/* may the user view new car channels */
export const mayViewNewCarChannel = checkGlobalPermission(
    `${PermissionCode.CI_NEW_CAR_CHANNEL}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_NEW_CAR_CHANNEL}_${PermissionLevel.MANAGE}`
);
/* may the user manage new car channels */
export const mayManageNewCarChannel = checkGlobalPermission(
    `${PermissionCode.CI_NEW_CAR_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user view used car channel */
export const mayViewUsedCarChannel = checkGlobalPermission(
    `${PermissionCode.CI_USED_CAR_CHANNEL}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_USED_CAR_CHANNEL}_${PermissionLevel.MANAGE}`
);
/* may the user manage used car channels */
export const mayManageUsedCarChannel = checkGlobalPermission(
    `${PermissionCode.CI_USED_CAR_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user view express channel */
export const mayViewExpressChannel = checkGlobalPermission(
    `${PermissionCode.CI_EXPRESS_CHANNEL}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_EXPRESS_CHANNEL}_${PermissionLevel.MANAGE}`
);
/* may the user manage express channels */
export const mayManageExpressChannel = checkGlobalPermission(
    `${PermissionCode.CI_EXPRESS_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user view used car channel */
export const mayViewEventChannel = checkGlobalPermission(
    `${PermissionCode.CI_EVENT_CHANNEL}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_EVENT_CHANNEL}_${PermissionLevel.MANAGE}`
);
/* may the user manage maintenance */
export const mayManageEventChannel = checkGlobalPermission(
    `${PermissionCode.CI_EVENT_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may view customer */
export const mayViewCustomer = checkGlobalPermission(
    `${PermissionCode.CI_CUSTOMERS}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_CUSTOMERS}_${PermissionLevel.MANAGE}`
);
/* may manage customer */
export const mayManageCustomer = checkGlobalPermission(`${PermissionCode.CI_CUSTOMERS}_${PermissionLevel.MANAGE}`);

/* may view recalculation */
export const mayViewRecalculate = checkGlobalPermission(
    `${PermissionCode.CI_RECALCULATE}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_RECALCULATE}_${PermissionLevel.MANAGE}`
);

/* may manage recalculation */
export const mayManageRecalculate = checkGlobalPermission(`${PermissionCode.CI_RECALCULATE}_${PermissionLevel.MANAGE}`);

export const mayManageRecalculateInterestRate = checkGlobalPermission(
    `${PermissionCode.CI_RECALCULATE_INTEREST_RATE}_${PermissionLevel.MANAGE}`
);

export const mayManageRecalculateResidualValue = checkGlobalPermission(
    `${PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE}_${PermissionLevel.MANAGE}`
);

export const mayManageInsuranceApplication = checkGlobalPermission(
    `${PermissionCode.CI_INSURANCE_APPLICATIONS}_${PermissionLevel.MANAGE}`
);

export const mayViewInsuranceApplication = checkGlobalPermission(
    `${PermissionCode.CI_INSURANCE_APPLICATIONS}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_INSURANCE_APPLICATIONS}_${PermissionLevel.MANAGE}`
);

export const mayViewAppointment = checkGlobalPermission(
    `${PermissionCode.CI_APPOINTMENT}_${PermissionLevel.VIEW}`,
    `${PermissionCode.CI_APPOINTMENT}_${PermissionLevel.MANAGE}`
);

export const mayManageAppointment = checkGlobalPermission(`${PermissionCode.CI_APPOINTMENT}_${PermissionLevel.MANAGE}`);

const globalPermissions = {
    mayViewReservation,
    mayManageReservation,
    mayViewApplication,
    mayManageApplication,
    mayViewLead,
    mayManageLead,
    mayViewNewCarChannel,
    mayManageNewCarChannel,
    mayViewUsedCarChannel,
    mayManageUsedCarChannel,
    mayViewExpressChannel,
    mayManageExpressChannel,
    mayViewEventChannel,
    mayManageEventChannel,
    mayViewCustomer,
    mayManageCustomer,
    mayViewRecalculate,
    mayManageRecalculate,
    mayManageRecalculateInterestRate,
    mayManageRecalculateResidualValue,
    mayManageInsuranceApplication,
    mayViewInsuranceApplication,
    mayViewAppointment,
    mayManageAppointment,
};

/* return the global permission map */
export const getGlobalPermissions = (user, countryId) => mapValues(fn => fn(user, countryId), globalPermissions);

export const permissionChannels = {
    [Channel.NEW]: mayViewNewCarChannel,
    [Channel.USED]: mayViewUsedCarChannel,
    [Channel.EXPRESS]: mayViewExpressChannel,
    [Channel.EVENT]: mayViewEventChannel,
};
