import { differenceBy, omit, orderBy } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ConsentDataFragment } from '../../../../../api/consents.graphql';
import { BankDataFragment } from '../../../../../components/data/useLoadBank.graphql';
import { CountryScopeFragment } from '../../../../../components/data/useLoadScope.graphql';
import HelmetTitle from '../../../../../components/shared/HelmetTitle';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import useValidation from '../../../../../components/utilities/useValidation';
import useValidationContext from '../../../../../components/utilities/useValidationContext';
import { Channel, UploadPurpose } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { BackStepContext } from '../../../../utils/flow';
import { Application } from '../../../types';
import { Page } from '../ui';
import CustomerForm, { CustomerFormValues, schema as getSchema } from './CustomerForm';

export type PageProps = {
    onSubmit: (values: any) => Promise<void>;
    bank?: BankDataFragment;
    step?: string;
    initialValues: any;
    country: CountryScopeFragment;
    backStep?: BackStepContext;
    isLastStep: boolean;
    application: Application;
    consents?: ConsentDataFragment[];
};
const normalizeValues = (values: CustomerFormValues) => {
    const { customerInfo } = values;
    const { occupationFiles = [], identityFiles = [], files = [] } = customerInfo;

    return {
        ...omit(['occupationFiles', 'identityFiles'], customerInfo),
        files: [...files, ...occupationFiles, ...identityFiles],
    };
};

const KYCPage = ({
    onSubmit,
    backStep,
    initialValues: initialValuesProps,
    application,
    consents,
    ...props
}: PageProps) => {
    const schema = useMemo(() => getSchema(), []);
    const validate = useValidation(schema);

    // get runtime settings
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const validation = useValidationContext();
    const sortedConsents = useMemo(() => orderBy('order', 'asc', consents), [consents]);

    // get company formats, used for validation
    const companyFormats = useCompanyFormatting();

    const initialValues = useMemo(() => {
        if (!application) {
            return null;
        }

        const identityFiles = application.attachments.filter(
            ({ purpose }) => purpose === UploadPurpose.CUSTOMER_IDENTITY
        );

        const occupationFiles = application.attachments.filter(
            ({ purpose }) => purpose === UploadPurpose.CUSTOMER_ATTACHED
        );

        const files = differenceBy('id', application.attachments, [...identityFiles, ...occupationFiles]);

        return {
            customerInfo: { ...application.customer, identityFiles, occupationFiles, files },
            consentDraft: { consents: {}, referenceId: undefined },
        };
    }, [application]);

    // prepare submit
    const onApply = useCallback(
        (values: any, validate: (values: any) => void) => {
            // first validate values
            validate(values);

            // then proceed with apply function
            return onSubmit({ ...normalizeValues(values), referenceId: values.consentDraft.referenceId });
        },
        [onSubmit]
    );

    const onSaveDraft = useCallback(
        values =>
            onSubmit({
                ...normalizeValues(values),
                draftTo: values.draftTo,
                referenceId: values.consentDraft.referenceId,
            }),
        [onSubmit]
    );

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Customer Details" />
            <CustomerForm
                apply={onApply}
                consents={sortedConsents}
                {...props}
                application={application}
                companyFormats={companyFormats}
                initialValues={initialValues}
                onSaveDraft={onSaveDraft}
                useCustomerBirthDay={useCustomerBirthDay}
                useCustomerNationality={useCustomerNationality}
                validate={validate}
                validation={validation}
            />
        </Page>
    );
};

export default KYCPage;
