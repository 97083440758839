import styled from 'styled-components';

const HeaderLogoButton = styled.button<{ width?: string }>`
    border: none;
    outline: none;
    color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    img {
        max-height: 60px;
        max-width: ${props => (props.width && props.width !== '' ? props.width : '260px')};
    }
`;

export default HeaderLogoButton;
