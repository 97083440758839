import React, { createContext, useCallback, useContext, useState } from 'react';
import { CalculatorValues } from '../../components/shared/calculator-next/types';
import { baseSchema as schema } from '../../components/shared/partialForms/CustomerInformationForm';
import useValidation from '../../components/utilities/useValidation';
import { Channel } from '../../schema';
import ShareForm from './ShareForm';

type SharingState = {
    active: boolean;
    calculators: any[];
};

export type ShareProviderProps = {
    children: JSX.Element;
    channel: Channel;
    dealerId: string;
};

export type ShareFunction = (calculators: Partial<CalculatorValues>[]) => void;

const ShareContext = createContext<ShareFunction | null>(null);

export const useShareFunction = () => {
    const context = useContext(ShareContext);

    if (context === null) {
        throw new Error('Sharing context not provided');
    }

    return context;
};

const ShareProvider = ({ children, channel, dealerId }: ShareProviderProps) => {
    const validate = useValidation(schema);
    const [state, setState] = useState<SharingState>({ active: false, calculators: [] });

    const share = useCallback(
        (calculators: any[]) => {
            setState({ active: true, calculators });
        },
        [setState]
    );

    const stopSharing = useCallback(() => setState({ active: false, calculators: [] }), [setState]);

    return (
        <ShareContext.Provider value={share}>
            {children}
            {state.active && (
                <ShareForm
                    calculators={state.calculators}
                    channel={channel}
                    dealerId={dealerId}
                    onClose={stopSharing}
                    validate={validate}
                />
            )}
        </ShareContext.Provider>
    );
};

export default ShareProvider;
