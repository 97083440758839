import { useQuery } from '@apollo/client';
import { trim, isEmpty } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    processCountries,
    processDialingCodes,
    processEmirates,
    processMarkets,
    processNationalities,
} from '../../api';
import {
    QueryAppOptionsQuery,
    QueryAppOptionsQueryVariables,
    queryAppOptions,
    CountryPhoneAppDataFragment,
} from '../../api/miscellaneous.graphql';
import { useCountry } from '../../hookSelectors';
import { Option, OptionsState } from '../../reducers/options';
import { Title } from '../../schema';

type Options = OptionsState & {
    title: Array<{ label: string; value: string }>;
    countries: Array<{ label: string; value: string; phone: CountryPhoneAppDataFragment }>;
    dealerVatOptions: Array<{ label: string; value: boolean }>;
};

// TODO: to move options here in hook
const useOptions = (): Options => {
    const { t } = useTranslation();
    const { data } = useQuery<QueryAppOptionsQuery, QueryAppOptionsQueryVariables>(queryAppOptions, {
        fetchPolicy: 'cache-first',
    });

    const { code } = useCountry();

    return useMemo(() => {
        const title = [
            {
                label: t('common.options.title.mr'),
                value: Title.MR,
            },
            {
                label: t('common.options.title.ms'),
                value: Title.MS,
            },
        ];

        if (code === 'TH') {
            title.push({
                label: t('common.options.title.mrs'),
                value: Title.MRS,
            });
        }

        const claimDiscountOptions = [
            { value: 0, label: '0%' },
            { value: 10, label: '10%' },
            { value: 20, label: '20%' },
            { value: 30, label: '30%' },
            { value: 40, label: '40%' },
            { value: 50, label: '50%' },
        ];

        const yearsOfDrivingOptions = [
            { value: 4, label: '3-4' },
            { value: 8, label: '5-8' },
            { value: 10, label: '9-10' },
            { value: 11, label: '>10' },
        ];

        const dealerVatOptions = [
            { label: t('common.options.dealerOptionVat.include'), value: true },
            { label: t('common.options.dealerOptionVat.exclude'), value: false },
        ];

        if (!data) {
            return {
                genders: [],
                maritalStatuses: [],
                countries: [],
                drivingLicenseValidities: [],
                drivingLicenseClasses: [],
                drivingLicenseValidityCodes: [],
                addressTypes: [],
                cities: [],
                educations: [],
                emirates: [],
                incomeTypes: [],
                markets: [],
                languages: [],
                nationalities: [],
                races: [],
                statusOptions: [],
                relationships: [],
                residenceTypes: [],
                residentialStatuses: [],
                timezones: [],
                dialingCodes: [],
                countriesDialingCodes: [],
                occupations: [],
                downpaymentTo: [],
                affinBank: {
                    races: [],
                    addressTypes: [],
                    idTypes: [],
                    residentialStatuses: [],
                },
                title,
                claimDiscountOptions,
                yearsOfDrivingOptions,
                dealerVatOptions,
            };
        }

        const translationMapper = <T>({ label, ...values }: Option<T>) => ({ ...values, label: t(label) });

        const genders = data.options.genders.map(translationMapper);

        const maritalStatuses = data.options.maritalStatuses.map(translationMapper);

        const downpaymentTo = data.options.downpaymentTo.map(translationMapper);

        const residentialStatuses = data.options.residentialStatuses.map(translationMapper);

        const cities = data.options.cities.map(({ name, countryCode }) => {
            const translatedName = t(`common.options.city.${countryCode}.${trim(name)}`);

            return {
                name: !isEmpty(translatedName) ? translatedName : name,
                countryCode,
            };
        });

        const countries = processCountries(data.options.countries);

        const nationalities = processNationalities(data.options.nationalities);

        const markets = processMarkets(data.options.markets);

        const emirates = processEmirates(data.options.emirates);

        const countriesDialingCodes = processDialingCodes(data.options.countries);

        const dialingCodes = processDialingCodes(data.options.markets);

        return {
            ...data.options,
            title,
            genders,
            maritalStatuses,
            residentialStatuses,
            cities,
            countries,
            nationalities,
            markets,
            emirates,
            countriesDialingCodes,
            dialingCodes,
            claimDiscountOptions,
            downpaymentTo,
            yearsOfDrivingOptions,
            dealerVatOptions,
        };
    }, [t, data, code]);
};

export default useOptions;
