import React from 'react';
import { useTheme } from 'styled-components';
import ImgWithFallback from './ImgWithFallback';
import defaultLogo from './assets/logo_black.png';

const CompanyLogo = props => {
    const { logo } = useTheme();

    if (logo) {
        return <ImgWithFallback alt="logo" {...props} fallbackSrc={defaultLogo} src={logo} />;
    }

    return <img alt="logo" {...props} src={defaultLogo} />;
};

export default CompanyLogo;
