import { TFunction } from 'i18next';
import { identity, some, omit, get, flow, camelCase, startCase } from 'lodash/fp';
import React, { ComponentType, ReactElement, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useCountry } from '../../../hookSelectors';
import { ApplicationEventType, ApplicationStatus, RuntimeType } from '../../../schema';
import { getRuntimeSettings } from '../../../selectors';
import { getCalculatorFromApplication } from '../../../utilities/application';
import {
    schema as customerMainAFCSchema,
    customerPrimaryInfoSchema as customerPrimaryInfoAFCSchema,
    onlyCustomerPrimaryInfoSchema as onlyCustomerPrimaryInfoAFCSchema,
} from '../../shared/form-v2/steps/CustomerStep';
import {
    schema as customerMainPMESchema,
    customerPrimaryInfoSchema as customerPrimaryInfoPMESchema,
    onlyCustomerPrimaryInfoSchema as onlyCustomerPrimaryInfoPMESchema,
} from '../../shared/form-v2/steps/PME/ApplicantStep';
import { useDataMask } from '../../shared/partialForms/CustomerInformationForm';
import useValidation from '../../utilities/useValidation';
import useValidationContext from '../../utilities/useValidationContext';
import ApplicationForm, { ApplicationFormSubmit, ApplicationFormValues } from './ApplicationForm';
import ContinueProvider from './actions/ContinueProvider';
import FailedSubmitProvider from './actions/FailedSubmitProvider';
import ReSubmitProvider from './actions/ReSubmitProvider';
import RetrySubmitProvider from './actions/RetrySubmitProvider';
import SaveChangesProvider from './actions/SaveChangesProvider';
import { useCustomerChanged } from './actions/useFormChanged';
import { ApplicationData } from './index';

export type ApplicationDetailsProps = {
    application: ApplicationData;
    onCancel: () => void;
};

export type SubmitProvider = ComponentType<{
    children: (submit: ApplicationFormSubmit) => ReactElement;
    application: ApplicationData;
    initialValues: ApplicationFormValues;
}>;

export const getConditionText = (finderVehicle: ApplicationData['finderVehicle']): string => {
    const listing = finderVehicle?.listing;

    if (!listing) {
        return '';
    }

    switch (listing.vehicle.condition.value) {
        case 'preowned':
            if (listing.warranty?.porscheApproved) {
                return 'Porsche Approved';
            }

            return 'Preowned';

        default:
            return flow([camelCase, startCase])(listing.vehicle.condition.value);
    }
};

const NoSubmit: SubmitProvider = ({ children }) => children(identity);

const hiddenFields = [
    'bank.name',
    'financeProduct.name',
    'calculator.paymentMode',
    'calculator.interestRate',
    'calculator.tenure',
    'calculator.downPayment.amount',
    'calculator.loan.percentage',
    'calculator.loan.amount',
    'calculator.coe',
    'calculator.ppsr',
    'calculator.establishment',
    'calculator.luxuryTax',
    'calculator.deposit.amount',
    'calculator.monthlyInstalments',
    'calculator.dealerOptions',
    'calculator.totalPrice',
    'calculator.residualValue.amount',
    'calculator.estimatedSurplus',
    'calculator.totalInterestPayable',
    'calculator.licensePlateFee',
    'calculator.commission',
    'calculator.fixedInterestRate',
    'calculator.licenseAndFuelTax',
    'calculator.displacement',
    'calculator.insuranceFee.amount',
    'calculator.taxLoss',
    'calculator.expectedTradeInAmount',
    'calculator.expectedCashPayment',
    'calculator.totalAmountPayable',
    'calculator.nzFee',
    'financeProduct.balloonGFVSetting',
];

export const calculatorDisplay = <T extends Object>(values: T, application: ApplicationData): T => {
    if (application.finderVehicle?.id) {
        if (application.isCalculatorEnabled || application.appliedForFinancing) {
            return values;
        }

        return (omit(hiddenFields, values) as unknown) as T;
    }

    return values;
};

export const getInitialValues = (application: ApplicationData): ApplicationFormValues =>
    calculatorDisplay<ApplicationFormValues>(
        {
            payment: application.payment,
            variant: application.variant,
            bank: application.financeProduct?.bank,
            financeProduct: application.financeProduct,
            assigneeId: application.assigneeId,
            calculator: getCalculatorFromApplication(application),
            customer: application.customer,
            guarantor: application.guarantor,
            remark: application.remark,
            proceedWithCustomerDevice: application.proceedWithCustomerDevice,
            promoCode: application.promoCode,
            attachments: application.attachments,
            options: application.options,
            tradeIn: application?.tradeIn,
            hasTradeIn: !!application?.hasTradeIn,
            hasTestDrive: !!application?.hasTestDrive,
            reservationDeposit: application.reservationDeposit,
            assetCondition: application.assetCondition || application.variant.assetCondition,
            finderVehicleCondition: getConditionText(application.finderVehicle),
            finderVehicle: application.finderVehicle,
        },
        application
    );

const continuableStatuses = [
    ApplicationStatus.SIGNING,
    ApplicationStatus.SIGNINGREJECTED,
    ApplicationStatus.SIGNINGTIMEOUT,
    ApplicationStatus.UNABLETOCONNECT,
    ApplicationStatus.CONNECTIONFAILED,
    ApplicationStatus.PENDINGOTP,
    ApplicationStatus.PAYING,
    ApplicationStatus.PAID,
    ApplicationStatus.UNABLETOPAY,
    ApplicationStatus.PAYMENTTIMEOUT,
    ApplicationStatus.PAYMENTFAILED,
    ApplicationStatus.DRAFT,
    ApplicationStatus.PENDINGCUSTOMERCONFIRMATION,
    ApplicationStatus.PENDINGGUARANTORCONFIRMATION,
    ApplicationStatus.CUSTOMERINFORECEIVED,
    ApplicationStatus.GUARANTEEDBUYBACKINITIALIZED,
    ApplicationStatus.GUARANTEEDBUYBACKREJECTED,
    ApplicationStatus.GUARANTEEDBUYBACKTIMEOUT,
];

const ApplicationDetails = ({ application, onCancel }: ApplicationDetailsProps) => {
    const { status, financeProduct, withGuarantor } = application;
    const bank = financeProduct?.bank;
    const initialValues = useMemo(() => getInitialValues(application), [application]);
    const { type } = useSelector(getRuntimeSettings);
    const { code } = useCountry();

    const received = useMemo(() => some(event => event.type === ApplicationEventType.RECEIVE, application.events), [
        application,
    ]);

    const submitted = useMemo(() => some(event => event.type === ApplicationEventType.SUBMIT, application.events), [
        application,
    ]);

    // allows the user to update some personal info
    // only whe application type is payment
    const allowPrimaryInfoChanges = submitted && !application.appliedForFinancing && !!application.reservationDeposit;

    const [disabled, SubmitProvider]: [boolean, SubmitProvider] = useMemo(() => {
        if (allowPrimaryInfoChanges) {
            return [true, SaveChangesProvider];
        }

        switch (status) {
            // when apply using myinfo for first submission, application status could be `CUSTOMERINFORECEIVED`
            case ApplicationStatus.CUSTOMERINFORECEIVED:
                return [false, received ? ReSubmitProvider : ContinueProvider];

            case ApplicationStatus.RECEIVED:
            case ApplicationStatus.APPROVED:
            case ApplicationStatus.AGREEMENTCONCLUDED:
                return [false, ReSubmitProvider];

            case ApplicationStatus.PAYING:
            case ApplicationStatus.PAID:
            case ApplicationStatus.PAYMENTTIMEOUT:
            case ApplicationStatus.UNABLETOPAY:
            case ApplicationStatus.PAYMENTFAILED:
            case ApplicationStatus.PENDINGCUSTOMERCONFIRMATION:
            case ApplicationStatus.PENDINGGUARANTORCONFIRMATION:
            case ApplicationStatus.SIGNING:
            case ApplicationStatus.PENDINGOTP:
            case ApplicationStatus.SIGNINGREJECTED:
            case ApplicationStatus.SIGNINGTIMEOUT:
            case ApplicationStatus.UNABLETOCONNECT:
            case ApplicationStatus.CONNECTIONFAILED:
            case ApplicationStatus.DRAFT:
            case ApplicationStatus.GUARANTEEDBUYBACKINITIALIZED:
            case ApplicationStatus.GUARANTEEDBUYBACKREJECTED:
            case ApplicationStatus.GUARANTEEDBUYBACKTIMEOUT:
                return [false, ContinueProvider];

            case ApplicationStatus.SUBMISSIONFAILED:
                return [false, FailedSubmitProvider];

            case ApplicationStatus.UNABLETOSUBMIT:
                return [true, RetrySubmitProvider];

            default:
                return [true, NoSubmit];
        }
    }, [allowPrimaryInfoChanges, status, received]);

    // get some context information
    const validation = useValidationContext();
    const customerState = useCustomerChanged();
    const beingMask = useDataMask();

    const { customerMainSchema, customerPrimaryInfoSchema, onlyCustomerPrimaryInfoSchema } = useMemo(() => {
        switch (type) {
            case RuntimeType.PME:
                return {
                    customerMainSchema: customerMainPMESchema,
                    customerPrimaryInfoSchema: customerPrimaryInfoPMESchema,
                    onlyCustomerPrimaryInfoSchema: onlyCustomerPrimaryInfoPMESchema,
                };
            case RuntimeType.AFC:
            default:
                return {
                    customerMainSchema: customerMainAFCSchema,
                    customerPrimaryInfoSchema: customerPrimaryInfoAFCSchema,
                    onlyCustomerPrimaryInfoSchema: onlyCustomerPrimaryInfoAFCSchema,
                };
        }
    }, [type]);

    const applicationFormSchema = useCallback(
        (t: TFunction) => {
            return yup.lazy<ApplicationFormValues>(formValues => {
                const maskedFields = beingMask
                    ? Object.keys(customerState).filter(field => !get(field, customerState))
                    : [];

                let customerSchema: yup.ObjectSchemaDefinition<any, unknown> = customerMainSchema(t);

                if (allowPrimaryInfoChanges) {
                    customerSchema = customerPrimaryInfoSchema(t);
                }
                if (
                    ApplicationStatus.DRAFT === application.status ||
                    !bank?.isKYCMandatory ||
                    (continuableStatuses.indexOf(application.status) > -1 &&
                        bank?.isKYCMandatory &&
                        formValues?.proceedWithCustomerDevice)
                ) {
                    customerSchema = onlyCustomerPrimaryInfoSchema(t);
                }

                // make the yup schema
                return yup.object().shape({
                    customer: yup.object().shape(omit(maskedFields, customerSchema)),
                    ...(withGuarantor && { guarantor: yup.object().shape(omit(maskedFields, customerSchema)) }),
                }) as yup.ObjectSchema<ApplicationFormValues>;
            });
        },
        [
            allowPrimaryInfoChanges,
            beingMask,
            customerState,
            bank,
            application,
            customerMainSchema,
            customerPrimaryInfoSchema,
            onlyCustomerPrimaryInfoSchema,
            withGuarantor,
        ]
    );

    const validate = useValidation(applicationFormSchema);

    // get runtime settings
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);

    return (
        <SubmitProvider application={application} initialValues={initialValues}>
            {onSubmit => (
                <ApplicationForm
                    allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                    application={application}
                    countryCode={code}
                    disabled={disabled}
                    initialValues={initialValues}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    useCustomerBirthDay={useCustomerBirthDay}
                    useCustomerNationality={useCustomerNationality}
                    validate={validate}
                    validation={validation}
                />
            )}
        </SubmitProvider>
    );
};

export default ApplicationDetails;
