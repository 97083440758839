import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { MakeDataFragment, ModelDataFragment } from '../../../../data/useLoadVariants.graphql';
import { FilterDefinition } from './index';

const useMakerFilter = (
    maker: string | null,
    makers: MakeDataFragment[],
    model: string | null,
    models: ModelDataFragment[],
    subModels: ModelDataFragment[],
    setMaker: (makeId: string | null) => void
): FilterDefinition<string> | null => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    // callback for changes
    const onChange = useCallback(
        ({ value }) => {
            switch (value) {
                case 'all':
                    // reset maker
                    setMaker(null);
                    break;

                default:
                    // set maker
                    setMaker(value);
                    break;
            }
        },
        [setMaker]
    );

    return useMemo(() => {
        if (makers.length < 2) {
            // we do not have multiple makers
            return null;
        }

        if (maker && (models.length > 1 || subModels.length > 1)) {
            // the user now needs to select either the model or sub model
            return null;
        }

        const options = [
            // all option
            { id: 'all', value: 'all', label: t('chooserPage.filter.selectAll') },
            // maker options
            ...makers.map(makerOption => ({
                id: makerOption.id,
                value: makerOption.id,
                label: ct(makerOption.name),
            })),
        ];

        return {
            key: 'make',
            title: t('chooserPage.filter.carModelTitle'),
            onChange,
            options,
            value: maker ?? 'all',
        };
    }, [maker, makers, models.length, onChange, subModels.length, t, ct]);
};

export default useMakerFilter;
