import React from 'react';
import { useSelector } from 'react-redux';
import { useCountry } from '../../../../hookSelectors';
import { getGlobalPermissions, getIsAuthenticated } from '../../../../selectors';
import Error404NotFound from '../../Error404NotFound';
import Error404Suggestion from '../../Error404Suggestion';

const DefaultRoute = () => {
    const country = useCountry();
    const { allowPublicAccess: allowNewCarPublicAccess, allowPrivateAccess } = country.channelSetting.new;
    const {
        allowPublicAccess: allowUsedCarPublicAccess,
        allowPrivateAccess: allowUsedCarPrivateAccess,
    } = country.channelSetting.used;
    const isAuthenticated = useSelector(getIsAuthenticated);

    const { mayViewNewCarChannel, mayViewUsedCarChannel, mayViewExpressChannel } = useSelector(getGlobalPermissions);

    // no auth, no public access
    const allowPublicAccess = allowUsedCarPublicAccess || allowNewCarPublicAccess;

    if (!isAuthenticated) {
        return allowPublicAccess ? <Error404Suggestion /> : <Error404NotFound />;
    }

    // auth, button based on permission
    const mayViewOtherChannel =
        mayViewExpressChannel ||
        (mayViewNewCarChannel && allowPrivateAccess) ||
        (mayViewUsedCarChannel && allowUsedCarPrivateAccess);

    return mayViewOtherChannel ? <Error404Suggestion /> : <Error404NotFound />;
};

export default DefaultRoute;
