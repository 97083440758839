import {
    flow,
    get,
    isEmpty,
    map,
    set,
    toLower,
    toString,
    trim,
    omit,
    isArray,
    isObject,
    isNil,
    negate,
    mergeWith,
    unset,
} from 'lodash/fp';

export const pickAndRename = mapper => values =>
    Object.entries(mapper).reduce((acc, [key, sourceKey]) => set(key, get(sourceKey, values), acc), {});

export const rename = mapper => values =>
    Object.entries(mapper).reduce(
        (acc, [key, sourceKey]) => flow([set(key, get(sourceKey, values)), unset(sourceKey)])(acc),
        values
    );

export const reverseObject = object => Object.fromEntries(Object.entries(object).map(([key, value]) => [value, key]));

export const toRegex = string => {
    const result = string.match(/\/(?<exp>.*)\/(?<mode>.*)/);

    if (result) {
        const { groups } = result;
        const { exp } = groups;

        return new RegExp(exp);
    }

    return null;
};

export const searchOnKeys = (keys, items) => {
    const entries = flow([
        map(item => ({
            item,
            data: keys
                .map(key => get(key, item))
                .filter(Boolean)
                .map(flow([toString, toLower])),
        })),
    ])(items);

    return hash => {
        if (isEmpty(trim(hash))) {
            return items;
        }

        const cleanedHash = toLower(hash);

        return entries.filter(entry => entry.data.some(part => part.includes(cleanedHash))).map(entry => entry.item);
    };
};

export function deepOmit(keys, object) {
    const omitKeys = omit(keys);
    const omitFunction = item => {
        if (!isObject(item)) {
            // nothing to omit
            return item;
        }

        if (isArray(item)) {
            return item.map(value => omitFunction(value));
        }

        const newItem = {};
        for (const [key, value] of Object.entries(omitKeys(item))) {
            newItem[key] = omitFunction(value);
        }

        return newItem;
    };

    if (arguments.length === 1) {
        return omitFunction;
    }

    return omitFunction(object);
}

export const deepRemoveNil = object => {
    if (Array.isArray(object)) {
        return object.map(item => deepRemoveNil(item)).filter(negate(isNil));
    }

    if (isObject(object)) {
        const entries = Object.entries(object)
            .map(([key, value]) => {
                const cleanedValue = deepRemoveNil(value);

                if (value === null) {
                    return null;
                }

                return [key, cleanedValue];
            })
            .filter(negate(isNil));

        const newObject = Object.fromEntries(entries);

        return isEmpty(newObject) ? null : newObject;
    }

    return object;
};

export const deepCustomerMerge = (customer, patch) => {
    const customizer = (root, source) => {
        if (Array.isArray(source)) {
            // replace arrays
            return source;
        }

        if (isObject(source)) {
            return mergeWith(customizer, root, source);
        }

        return source;
    };

    return mergeWith(customizer, customer, patch);
};

export const padString = (pad, length, string) => (new Array(length + 1).join(pad) + string).slice(-length);

export const flattenKeys = input => {
    const flattenObject = {};

    const traverse = (root, prefix = '') => {
        if (isArray(root)) {
            for (let i = 0; i < root.length; i++) {
                traverse(root[i], `${prefix}.[${i}]`);
            }
        } else if (isObject(root)) {
            Object.entries(root).forEach(([key, value]) => {
                const target = prefix === '' ? key : `${prefix}.${key}`;

                traverse(value, target);
            });
        } else {
            flattenObject[prefix] = root;
        }
    };

    traverse(input);

    return flattenObject;
};
