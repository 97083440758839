import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useContentTranslation } from '../../../../../i18n';
import { getCompany, getCurrentCountry, getRuntimeSettings } from '../../../../../selectors';

const CKDMetaTitle = () => {
    const company = useSelector(getCompany);
    const country = useSelector(getCurrentCountry);
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { ct } = useContentTranslation();

    const companyText = company ? `${ct(company.name)} ` : '';
    const countryText = country ? `${ct(country.mlcName)} - ` : '';
    const helmetTitle = companyText + countryText + ckdConfiguration.metaTitle;

    return (
        <Helmet>
            <title>{helmetTitle}</title>
        </Helmet>
    );
};

export default CKDMetaTitle;
