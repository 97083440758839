export const NOTIFICATION_SET = '@NOTIFICATION/SET';

export const NOTIFICATION_UNSET = '@NOTIFICATION/UNSET';

export type SetNotification = {
    type: typeof NOTIFICATION_SET;
    notification: { title: string; message: string };
};

export const setNotification = (title: string, message: string): SetNotification => ({
    type: NOTIFICATION_SET,
    notification: { title, message },
});

export type UnsetNotification = { type: typeof NOTIFICATION_UNSET };

export const unsetNotification = (): UnsetNotification => ({ type: NOTIFICATION_UNSET });

export type NotificationActions = SetNotification | UnsetNotification;
