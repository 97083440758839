// @ts-ignore
import { DarkButton, GrayButton, Actions, Modal } from '@appvantageasia/afc-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OcrImageInput from '../../../../components/ui/calculator/OcrImageInput';
import OcrImagePreview from '../../../../components/ui/calculator/OcrImagePreview';
import { OcrImagesContainer } from '../../../../components/ui/calculator/OcrImages';
import { Files } from './OcrManagement';

type FileState = {
    label: string;
    file: File | null;
    preview: string | ArrayBuffer | null;
    onChange: (event: any) => void;
    onClear: () => void;
};

const useFile = (label: string): FileState => {
    const [{ file = null, preview = null }, setFile] = useState<{ file?: File; preview?: string | ArrayBuffer | null }>(
        {}
    );

    const onChange = useCallback(
        (event: any) => {
            const input = event.target;

            if (input && input.files[0]) {
                const newFile = input.files[0];
                const reader = new FileReader();

                reader.onload = ({ target }) => {
                    setFile({ file: newFile, preview: target?.result });
                };

                reader.readAsDataURL(newFile);
            }
        },
        [setFile]
    );

    const onClear = useCallback(() => {
        setFile({});
    }, [setFile]);

    return { label, file, preview, onChange, onClear };
};

export type OcrModalProps = {
    onClose: () => void;
    onValidation: (files: Files) => Promise<void>;
};

const OcrModal = ({ onValidation, onClose }: OcrModalProps) => {
    const { t } = useTranslation();
    const frontIdentityCard = useFile(t('draftPage.ocrModal.label.identityCardFront'));
    const backIdentityCard = useFile(t('draftPage.ocrModal.label.identityCardBack'));
    const nameCard = useFile(t('draftPage.ocrModal.label.nameCard'));

    const isCompleted = frontIdentityCard.file && backIdentityCard.file;

    const renderFile = (state: FileState) => {
        if (state.file) {
            // @ts-ignore
            return <OcrImagePreview onClick={state.onClear} src={state.preview} />;
        }

        return <OcrImageInput label={state.label} onChange={state.onChange} />;
    };

    const submit = useCallback(() => {
        // call the validation
        onValidation({
            back: backIdentityCard.file,
            front: frontIdentityCard.file,
            card: nameCard.file,
        });
    }, [onValidation, frontIdentityCard.file, backIdentityCard.file, nameCard.file]);

    return (
        <Modal onClose={onClose} title={t('draftPage.ocrModal.title')} showClose showTitle>
            <OcrImagesContainer>
                {renderFile(frontIdentityCard)}
                {renderFile(backIdentityCard)}
                {renderFile(nameCard)}
            </OcrImagesContainer>
            <Actions>
                <GrayButton onClick={onClose}>{t('draftPage.ocrModal.button.cancel')}</GrayButton>
                <DarkButton disabled={!isCompleted} onClick={submit}>
                    {t('draftPage.ocrModal.button.submit')}
                </DarkButton>
            </Actions>
        </Modal>
    );
};

export default OcrModal;
