import { useQuery } from '@apollo/client';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { get, isNil, orderBy } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection, InjectedFormProps, reduxForm } from 'redux-form';
import * as yup from 'yup';
import {
    getFlowConsentsAndDeclarations,
    GetFlowConsentsAndDeclarationsQuery,
    GetFlowConsentsAndDeclarationsQueryVariables,
} from '../../../../api/consents.graphql';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { InsuranceCompanyDataFragment } from '../../../../components/data/useLoadInsuranceCompany.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { CalculatorInsuranceValues } from '../../../../components/shared/calculator-next/types';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../components/shared/form-v2/TextField';
import useCustomerNamesSynchronization from '../../../../components/shared/useCustomerNamesSynchronization';
import useFormValues from '../../../../components/utilities/useFormValues';
import useOptions from '../../../../utilities/constants/useOptions';
import { validateNirc, yupExt } from '../../../../utilities/forms';
import { getDraftConsents } from '../../utils/consents';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { ConsentForm, schema as consentFormSchema } from '../ConsentDeposit/ConsentForm';
import ContactForm, { addressSchema, contactSchema } from '../DraftPage/Form/ContactForm';
import CustomerInformationForm, { baseSchema, detailsSchema } from '../DraftPage/Form/CustomerInformationForm';
import { EventGrid, Title } from '../DraftPage/ui';
import VehicleForm from './ConsentVehicleForm';
import DrivingLicenseForm from './DrivingLicenceForm';
import { Actions } from './ui';

type ApplicantFormProps = {
    hasTradeIn?: boolean;
    hasTestDrive?: boolean;
    appliedForFinancing?: boolean;
    appliedForInsurance?: boolean;
    dealerId: string;
    referenceId: string;
    setReferenceId: (id: string) => void;
    event: EventDataFragment;
    bank?: BankDataFragment;
    insuranceCompany?: InsuranceCompanyDataFragment;
    insuranceCalculator?: Partial<CalculatorInsuranceValues>;
};

type ApplicantFormValues = { consentDraft?: { consents?: Record<string, boolean> }; withMyInfo: boolean };

const ApplicantForm = ({
    handleSubmit,
    valid,
    initialValues,
    change,
    hasTestDrive,
    hasTradeIn = false,
    appliedForFinancing,
    appliedForInsurance,
    dealerId,
    event,
    referenceId,
    setReferenceId,
    bank,
    insuranceCompany,
    insuranceCalculator,
}: ApplicantFormProps & InjectedFormProps) => {
    const { t } = useTranslation();

    const { yearsOfDrivingOptions, claimDiscountOptions } = useOptions();

    const submit = useMemo(
        () => (
            <PrimaryButton disabled={!valid} onClick={handleSubmit} type="button" value="submit">
                <FontAwesomeIcon icon={faAngleRight} /> {t('eventApplicantPage.button.next')}
            </PrimaryButton>
        ),
        [handleSubmit, t, valid]
    );

    const onNormalizeNames = useCustomerNamesSynchronization();

    const { consentDraft = {} } = useFormValues<ApplicantFormValues>();
    const { consents: consentStatuses } = consentDraft;

    const showTradeInVehicle = hasTradeIn && !!get('details.vehicles.length', initialValues);
    const withMyInfo = get('withMyInfo', initialValues);
    const hasMyInfoDrivingLicense = !!(withMyInfo && get('details.drivingLicense.length', initialValues));
    const showManualDrivingLicenseField = hasTestDrive && !hasMyInfoDrivingLicense;
    const showMyInfoDrivingLicenseForm = hasTestDrive && hasMyInfoDrivingLicense;

    const { data } = useQuery<GetFlowConsentsAndDeclarationsQuery, GetFlowConsentsAndDeclarationsQueryVariables>(
        getFlowConsentsAndDeclarations,
        {
            variables: {
                consent: {
                    dealerId,
                    eventId: event.id,
                    bankId: appliedForFinancing ? bank?.id : undefined,
                    insuranceId: appliedForInsurance ? insuranceCompany?.id : undefined,
                },
            },
            skip: isNil(dealerId),
        }
    );

    const consents = useMemo(
        () => orderBy('order', 'asc', getDraftConsents(data?.result, appliedForFinancing, appliedForInsurance)),
        [appliedForFinancing, appliedForInsurance, data]
    );
    const showConsents = consents.length > 0;
    useEffect(() => {
        change('__exclude.consents', consents);
    }, [change, consents]);

    const customerInformationForm = (
        <>
            <CustomerInformationForm
                appliedForFinancing={appliedForFinancing}
                appliedForInsurance={appliedForInsurance}
                hasTestDrive={showManualDrivingLicenseField}
                onNormalizeNames={onNormalizeNames}
                isMyInfo
            />
            {appliedForInsurance && (
                <>
                    {insuranceCalculator?.insurancePremium === 0 && (
                        <SelectField.Outline
                            label={t('eventDrivingLicense.label.yearsOfDriving')}
                            name="insurance.calculator.yearsOfDriving"
                            options={yearsOfDrivingOptions}
                            sort={false}
                            isFullWidth
                        />
                    )}
                    <SelectField.Outline
                        label={t('eventDrivingLicense.label.noClaimDiscount')}
                        name="insurance.calculator.ncd"
                        options={claimDiscountOptions}
                        sort={false}
                        isFullWidth
                    />
                    <TextField
                        label={t('eventDrivingLicense.label.existingCarPlate')}
                        name="insurance.calculator.existingCarPlate"
                    />
                </>
            )}
        </>
    );
    const consentForm = (
        <>
            <Title>{t('eventConsentDepositPage.consentTitle')}</Title>
            <FormSection name="consentDraft">
                <ConsentForm
                    consentStatuses={consentStatuses}
                    consents={consents!}
                    referenceId={referenceId}
                    setReferenceId={setReferenceId}
                />
            </FormSection>
        </>
    );

    if (!showTradeInVehicle) {
        return (
            <EventGrid childrenLength={3}>
                {!showConsents ? (
                    <>
                        <div>{customerInformationForm}</div>
                        {showMyInfoDrivingLicenseForm ? (
                            <>
                                <div>
                                    <DrivingLicenseForm />
                                </div>
                                <div>
                                    <ContactForm submit={submit} />
                                </div>
                            </>
                        ) : (
                            <ContactForm submit={submit} split />
                        )}
                    </>
                ) : null}
                {showConsents ? (
                    <>
                        <div>
                            {customerInformationForm}
                            {showMyInfoDrivingLicenseForm ? <DrivingLicenseForm /> : null}
                        </div>
                        <div>
                            <ContactForm />
                        </div>
                        <div>
                            {consentForm}
                            <Buttons>{submit}</Buttons>
                        </div>
                    </>
                ) : null}
            </EventGrid>
        );
    }

    return (
        <>
            <EventGrid childrenLength={3}>
                <div>{customerInformationForm}</div>
                {showMyInfoDrivingLicenseForm ? (
                    <>
                        <div>
                            <DrivingLicenseForm />
                        </div>
                        <div>
                            <ContactForm />
                        </div>
                    </>
                ) : (
                    <ContactForm split />
                )}
            </EventGrid>
            <br />
            <br />
            {!showConsents ? (
                <>
                    <EventGrid childrenLength={6}>
                        <VehicleForm shrinkLastItem={false} />
                    </EventGrid>
                    <Actions>{submit}</Actions>
                </>
            ) : null}
            {showConsents ? (
                <EventGrid childrenLength={6}>
                    <VehicleForm shrinkLastItem />
                    <div>
                        {consentForm}
                        <Buttons>{submit}</Buttons>
                    </div>
                </EventGrid>
            ) : null}
        </>
    );
};

export const schema = (t: TFunction) =>
    yup.object().shape({
        ...baseSchema,
        ...contactSchema(t),
        consentDraft: consentFormSchema()(t),
        details: yupExt.customerProperty().shape({
            ...detailsSchema,
            residentialAddress: yupExt.customerProperty().shape(addressSchema(t)),
        }),
        // somehow it's missing the identity number
        // @ts-ignore
        identityNumber: yup.lazy((values: any, options: any) => {
            const { context, parent } = options;

            if (context?.appliedForFinancing || context?.appliedForInsurance) {
                const identityValidator = validateNirc(parent?.details?.nationality?.value || context.countryCode);

                return yupExt.customerProperty().shape({
                    value: yup
                        .string()
                        .required(t('common.error.required'))
                        .test(
                            'invalid-identity',
                            t('common.error.identityNumber'),
                            (value: any) => !value || identityValidator(value, parent?.dateOfBirth?.value)
                        ),
                });
            }

            return yup.mixed().notRequired();
        }),
    });

export default reduxForm<any, any>({
    form: 'applicant',
})(ApplicantForm);
