import React from 'react';
import styled from 'styled-components';
import { useContentTranslation } from '../../i18n';
import { MaintenanceDataFragment } from '../data/useLoadScope.graphql';
import LanguageSelector from '../shared/LanguageSelector';
import { MaintenanceTitle, MaintenanceContent } from '../ui/Maintenance';

export type MaintenanceRouteProps = MaintenanceDataFragment;

const MaintenanceLanguageContainer = styled.div`
    display: flex;
    height: 68px;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 20px;
`;

const MaintenanceRoute = ({ title, description, image }: MaintenanceRouteProps) => {
    const { ct } = useContentTranslation();

    return (
        <>
            <MaintenanceLanguageContainer>
                <LanguageSelector />
            </MaintenanceLanguageContainer>
            <MaintenanceTitle>
                {image?.url && <img alt="Maintenance" src={image.url} />}
                {ct(title)}
            </MaintenanceTitle>
            {description ? <MaintenanceContent dangerouslySetInnerHTML={{ __html: ct(description) }} /> : null}
        </>
    );
};

export default MaintenanceRoute;
