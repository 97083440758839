import React from 'react';
import { Channel } from '../../../../../schema';
import { ApplicationData } from '../../../../routes/ApplicationRoute';
import Event from './Event';
import Express from './Express';
import New from './New';
import Used from './Used';

export type VehicleStepProps = {
    application: ApplicationData;
    disabled?: boolean;
};

const VehicleStep = ({ application, disabled }: VehicleStepProps) => {
    const { channel } = application;

    switch (channel) {
        case Channel.NEW:
            return <New application={application} />;
        case Channel.USED:
            return <Used application={application} />;
        case Channel.EXPRESS:
            return <Express disabled={disabled} />;
        case Channel.EVENT:
            return <Event application={application} />;
        default:
            return null;
    }
};

export default VehicleStep;
