import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../selectors';
import { getSalesPersonByDealerId } from '../../routes/ApplicationRoute/Action.graphql';
import SelectField from './SelectField';

const UserField = ({ dealerIds, ...props }) => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const variables = { countryId, dealerIds };
    const { data } = useQuery(getSalesPersonByDealerId, { fetchPolicy: 'cache-and-network', variables });

    return <SelectField.Outline {...props} options={data?.sales ?? []} />;
};

UserField.propTypes = {
    dealerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserField;
