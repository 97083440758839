// @ts-ignore
import { useQuery } from '@apollo/client';
// @ts-ignore
import { Paging, TableList, Footer } from '@appvantageasia/afc-ui';
import { flow, get } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useContentTranslation } from '../../i18n';
import { getZoneId } from '../../selectors';
import useLoading from '../../useLoading';
import { getApplicationUrl } from '../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../shared/HelmetTitle';
import SubmitSuccessModalWithNoSession from '../shared/SubmitSuccessModalWithNoSession';
import useFormatDateTime from '../shared/useFormatDateTime';
import Status from '../ui/ApplicationStatus';
import { renderChannelOption } from '../ui/Cells';
import PageContainer, { Title, Content } from '../ui/PageContainer';
import { TextAlignCenter } from '../ui/TextAlign';
import useListContext, { useListContextFromState } from '../utilities/useListContext';
import useSearch from '../utilities/useSearch';
import useServerPaging from '../utilities/useServerPaging';
import useSorting from '../utilities/useSorting';
import {
    getApplications,
    GetApplicationsQuery,
    InsuranceApplicationsDataFragment,
} from './InsuranceApplicationsRoute.graphql';
import DownloadModal from './InsuranceDownloadModal';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

const pageSetting = {
    titleKey: 'applicationsListPage.insuranceTitle',
    previousKey: 'applicationDetailsPage.button.insurancePrevious',
};

type StatusProps = {
    statusText: {
        label: string;
        color: string;
    };
};

const renderStatus = () => ({ statusText: { label, color } }: StatusProps) => <Status color={color}>{label}</Status>;

const useColumns = () => {
    const { t } = useTranslation();
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const columns = [
            {
                key: 'applicationDate',
                sortKey: 'version.createdAt',
                label: t('applicationsListPage.list.columns.insuranceApplicationDate'),
                getValue: flow([get('version.createdAt'), formatDateTime]),
            },
            {
                label: t('applicationsListPage.list.columns.insuranceApplicationId'),
                key: 'identifier',
                getValue: get('identifier'),
                sortKey: 'identifier',
            },
            {
                label: t('applicationsListPage.list.columns.assignedTo'),
                key: 'assignee.name',
                getValue: get('assignee.name'),
                sortKey: 'assignee.name',
            },
            {
                label: t('applicationsListPage.list.columns.customer'),
                key: 'customer.name.value',
                getValue: get('customer.name.value'),
                sortKey: 'customer.name.value',
            },
            {
                label: t('applicationsListPage.list.columns.variant'),
                key: formatPath('variant.name'),
                getValue: get(formatPath('variant.name')),
                sortKey: formatPath('variant.name'),
            },
            {
                label: t('applicationsListPage.list.columns.insuranceCompany'),
                key: formatPath('insuranceCompany.name'),
                getValue: get(formatPath('insuranceCompany.name')),
                sortKey: formatPath('insuranceCompany.name'),
            },
            {
                label: t('applicationsListPage.list.columns.insurancePremium'),
                key: 'calculator.insurancePremium',
                getValue: get('calculator.insurancePremium'),
                sortKey: 'calculator.insurancePremium',
            },
            {
                key: 'status',
                sortKey: 'status',
                label: t('applicationsListPage.list.columns.status'),
                getValue: renderStatus(),
            },
            {
                label: t('applicationsListPage.list.columns.lastActivity'),
                key: 'version.updatedAt',
                getValue: flow([get('version.updatedAt'), formatDateTime]),
                sortKey: 'version.updatedAt',
            },
            {
                label: t('applicationsListPage.list.columns.channel'),
                key: 'application.channel',
                getValue: (item: InsuranceApplicationsDataFragment) => renderChannelOption(item, t),
                sortKey: 'application.channel',
            },
        ];

        return columns.filter(Boolean);
    }, [t, formatPath, formatDateTime]);
};

const useSearchFields = () => {
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            'identifier',
            'user.name',
            'zone.code',
            'customer.name.value',
            formatPath('variant.name'),
            formatPath('insuranceCompany.name'),
            'channel',
        ],
        [formatPath]
    );
};

const InsurancesRoute = () => {
    const { t } = useTranslation();
    const { language } = useContentTranslation();
    const searchFields = useSearchFields();
    const columns = useColumns();

    // get initial list context
    const [, getInitial] = useListContextFromState();

    const search = useSearch('applications', getInitial('search'));

    const [paging] = useServerPaging({ search, initialPage: getInitial('paging.page') });

    const sorting = useSorting({
        initialKey: getInitial('sorting.key', 'version.updatedAt'),
        initialOrder: getInitial('sorting.order'),
    });

    const { titleKey, previousKey } = pageSetting;

    // get applications with apollo
    const zoneId = useSelector(getZoneId);
    const dealerId = useMustExistDealerIdFromTenantContext();
    const variables = {
        id: zoneId,
        dealerIds: [dealerId],
        locale: language,
        sorting: [
            {
                type: sorting && sorting?.key,
                order: sorting && sorting?.order?.toUpperCase(),
            },
        ],
        paging: {
            limit: paging.pageSize,
            offset: (paging.page - 1) * paging.pageSize,
        },
        search: search
            ? searchFields.map(field => {
                  return { identifier: field, value: search };
              })
            : null,
    };

    const { data, loading, error: apiError } = useQuery<GetApplicationsQuery>(getApplications, {
        variables,
        fetchPolicy: 'cache-and-network',
    });

    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    useLoading(isLoading);

    useEffect(() => {
        paging.setItemCount(data?.results?.count || 0);
    }, [paging, data]);

    // make a context to bump/restore those configuration
    const listContext = useListContext(paging, sorting, search);

    // callback on items
    const history = useHistory();
    const onItemClick = useCallback(
        // @ts-ignore
        item => history.pushWithCompanyAndState(getApplicationUrl, { listContext, previous: t(previousKey) }, item),
        [history, listContext, previousKey, t]
    );

    const renderMessage = useMemo(
        () => () =>
            !isLoading ? <TextAlignCenter>{t('applicationsListPage.list.noApplications')}</TextAlignCenter> : null,
        [isLoading]
    );

    const [downloadModal, setDownloadModal] = useState<boolean | null | React.ReactElement>(false);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () => setDownloadModal(<DownloadModal onClose={closeDownloadModal} type="Insurance" />),
        [setDownloadModal, closeDownloadModal]
    );

    const { error = null, submitted = false, application = null, updated = false } =
        useLocation<{
            error?: string;
            submitted?: boolean;
            application?: InsuranceApplicationsDataFragment;
            updated?: boolean;
        }>().state ?? {};
    // display special submit modal
    const [showModal, setShowModal] = useState(submitted || updated);
    const onClose = useCallback(() => setShowModal(state => !state), [setShowModal]);

    if (apiError) {
        throw apiError;
    }

    return (
        <PageContainer>
            <HelmetTitle title="Insurance Application List" />
            <Title>{t(titleKey)}</Title>
            {items.length ? (
                <>
                    <Content>
                        <TableList columns={columns} items={items} onItemClick={onItemClick} sorting={sorting} />
                    </Content>
                    <Paging {...paging} />
                </>
            ) : (
                renderMessage()
            )}

            <div style={{ marginBottom: '80px' }} />

            <div style={{ marginBottom: '80px' }} />
            {!!items.length && (
                <FootContainer>
                    <FootBar>
                        <FootBarRow>
                            <div style={{ flex: 1 }} />
                            <FootBarButton onClick={openDownloadModal}>
                                {t('applicationsListPage.button.download')}
                            </FootBarButton>
                        </FootBarRow>
                    </FootBar>
                </FootContainer>
            )}
            {showModal && application && (
                <SubmitSuccessModalWithNoSession
                    application={application}
                    hasSendCustomerConfirmationEmail={application.insuranceCompany.sendEmailToCustomer}
                    onClose={onClose}
                    updated={updated}
                    isInsurance
                />
            )}
            {downloadModal}
        </PageContainer>
    );
};

export default InsurancesRoute;
