import styled, { StyledComponent } from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';
import Title from '../Title';

export type CarModelTitleProps = {
    align?: string;
};

const CarModelTitle = styled(Title as StyledComponent<'h1', any, CarModelTitleProps, never>)`
    color: #000;
    font-size: 1.43rem;
    text-transform: ${props => props.theme.casing};
    text-align: ${props => props.align || 'center'};

    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}) {
        text-align: ${props => props.align || 'left'};
    }
`;

export default CarModelTitle;
