import { getOr } from 'lodash/fp';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import SubTitle from '../../../../ui/SubTitle';
import NumberField from '../../NumberField';

const GuaranteedBuybackDetails = () => {
    const { t } = useTranslation();

    const { getValues } = useContext(ReduxFormContext);

    const formValues = getValues();
    const bankPresetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);
    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    if (!isEnbdBankPreset) {
        return null;
    }

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.guaranteedBuybackDetails')} </SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={t('customerDetails.label.maxMileage')}
                        name="details.guaranteedBuyback.maxMileage.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={t('customerDetails.label.excessMileageCharge')}
                        name="details.guaranteedBuyback.excessMileageCharge.value"
                        type="currency"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={t('customerDetails.label.vehicleModelYear')}
                        name="details.guaranteedBuyback.vehicleModelYear.value"
                        type="year"
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export default GuaranteedBuybackDetails;
