import { isEmpty } from 'lodash/fp';
import { Reducer, Action } from 'redux';

const persistState = <S, A extends Action>(storageKey: string, reducer: Reducer<S, A>): Reducer<S, A> => {
    let previousState: S;

    const reloadState = (action: A) => {
        // redux is initializing the store
        // retrieve previous state from session storage
        const persistedState = sessionStorage.getItem(storageKey);
        // if we got one, parse it
        const initialState = persistedState ? (JSON.parse(persistedState) as S) : undefined;

        // then finally call the reducer
        previousState = reducer(initialState, action);

        return previousState;
    };

    const updateState = (state: S, action: A) => {
        // call the reducer
        const newState = reducer(state, action);

        // we use shallow comparison to look for changes
        // when state is undefined it means we are going to running on some actions ahead of @@INIT
        // so we are going to ignore those
        if (newState !== previousState && state !== undefined) {
            if (!isEmpty(newState)) {
                // we got data to update
                sessionStorage.setItem(storageKey, JSON.stringify(newState));
            } else {
                // can be removed
                sessionStorage.removeItem(storageKey);
            }

            previousState = newState;
        }

        return newState;
    };

    return (state, action) => {
        if (state === undefined) {
            return reloadState(action);
        }

        return updateState(state, action);
    };
};

export default persistState;
