import { useEffect, useMemo, useRef, useState } from 'react';

const usePaging = (items, { search = null, pageSize = 10, initialPage = 1 } = {}) => {
    const previousSearch = useRef(search);
    const [page, setPage] = useState(initialPage);

    // reset the page to zero whenever the search changed
    useEffect(() => {
        if (previousSearch.current !== search) {
            setPage(1);
            previousSearch.current = search;
        }
    }, [previousSearch, search, setPage]);

    const state = useMemo(
        () => ({
            page,
            pageSize,
            setPage,
            pageCount: Math.ceil(items.length / pageSize),
            itemCount: items.length,
        }),
        [setPage, page, pageSize, items]
    );

    const pagedItems = useMemo(() => {
        return items.slice((page - 1) * pageSize, page * pageSize);
    }, [items, page, pageSize]);

    return [pagedItems, state];
};

export default usePaging;
