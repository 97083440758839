import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { EventFlowState } from '../../../flows/EventFlow/EventSubmitFlow';
import { usePersistedFlow } from '../../../flows/utils/persistency';
import { checkTokenValidity } from '../../../flows/utils/tokens';
import { Channel } from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import { getCompanyLoginUrl } from '../../../utilities/urls';
import Chatbot from '../../shared/Chatbot';
import Footer from '../../shared/Footer';
import Header from '../../shared/Header';
import PublicSessionTimer from '../../shared/PublicSessionTimer';
import EventHandler from '../EventRoute/EventHandler';
import CsvBootstrap from './CsvBootstrap';

type PersistedData = { token?: string; step?: string };

export const serialize = (data: EventFlowState, stepIdentifier: string) => {
    const persistedData: PersistedData = {
        token: data.token,
        step: stepIdentifier,
    };

    return JSON.stringify(persistedData);
};

export const unserialize = (serializedData: string): PersistedData => {
    const data = JSON.parse(serializedData) as PersistedData;

    if (data?.token && !checkTokenValidity(data?.token)) {
        // start with an empty state
        return {};
    }

    return data;
};

export const generateCacheKey = (pubSubChannel: string, state: EventFlowState) =>
    `${pubSubChannel}:${state.application?.version.id}`;

const Inner = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const location = useLocation();
    const { pathname, search } = location;
    const history = useHistory();
    const redirect = `${pathname}${search}`;

    // extract parameters from the URL query
    const { applicationId } = qs.parse(search, { ignoreQueryPrefix: true });

    const { token } =
        usePersistedFlow(
            ['eventSubmitFlow', applicationId].filter(Boolean).join(':'),
            'eventSubmitFlow',
            serialize,
            unserialize,
            generateCacheKey
        ) || {};

    return (
        <>
            <Header channel={Channel.EVENT} />
            <CsvBootstrap token={token}>
                {context => {
                    const { event } = context;
                    const { allowPublicAccess } = event.setting;

                    if (!isAuthenticated && !allowPublicAccess) {
                        // @ts-ignore
                        history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
                    }

                    return (
                        <>
                            <Chatbot channel={Channel.EVENT} event={event} />
                            <PublicSessionTimer url={redirect} />
                            <EventHandler
                                application={context.application}
                                calculator={context.calculator}
                                csvConfigurator={context.csvConfigurator}
                                dealerId={context.dealerId}
                                event={event}
                                token={token}
                                variant={context.variant}
                            />
                        </>
                    );
                }}
            </CsvBootstrap>
            <Footer />
        </>
    );
};

export default Inner;
