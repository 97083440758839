import { getOr } from 'lodash/fp';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import useOptions from '../../../../../utilities/constants/useOptions';
import SubTitle from '../../../../ui/SubTitle';
import NumberField from '../../NumberField';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

const QuotationDetails = () => {
    const { t } = useTranslation();

    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const { downpaymentTo, dealerVatOptions } = useOptions();
    const formValues = getValues();
    const bankPresetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);
    const carOptions = getOr([], `${sectionPrefix}.details.quotation.options`, formValues);
    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    if (!isEnbdBankPreset) {
        return null;
    }

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.quotationDetails')} </SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.commissionNo')}
                        name="details.quotation.commissionNo.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.engineNo')}
                        name="details.quotation.engineNo.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.chassisNo')}
                        name="details.quotation.chassisNo.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.exteriorColor')}
                        name="details.quotation.exteriorColor.value"
                        disabled
                    />
                </div>
                {carOptions?.map((_: any, index: number) => (
                    <Fragment key={index.toString()}>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={t('customerDetails.label.optionDescription', { no: index + 1 })}
                                name={`details.quotation.options[${index}].description.value`}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={t('customerDetails.label.optionVat', { no: index + 1 })}
                                name={`details.quotation.options[${index}].isVatIncluded`}
                                options={dealerVatOptions}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('customerDetails.label.optionPrice', { no: index + 1 })}
                                name={`details.quotation.options[${index}].price`}
                                overrideFormats={{
                                    currencyPrecision: 2,
                                }}
                                type="currency"
                                disabled
                            />
                        </div>
                    </Fragment>
                ))}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.downpaymentTo')}
                        name="details.quotation.downpaymentTo.value"
                        options={downpaymentTo}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.companyName')}
                        name="details.quotation.companyName.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.financeInsuranceManager')}
                        name="details.quotation.financeInsuranceManager.value"
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export default QuotationDetails;
