import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import getSnapshotFromApplication, { SnapshotSourceValues } from '../../../flows/utils/getSnapshotFromApplication';
import useDealerEstablishment from '../../../flows/utils/useDealerEstablishment';
import { useCountry } from '../../../hookSelectors';
import { Channel } from '../../../schema';
import { getGlobalPermissions } from '../../../selectors';
import { getCalculatorFromApplication } from '../../../utilities/application';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import ConnectedCalculator from '../../shared/calculator-next/ConnectedCalculator';
import { CalculatorValues } from '../../shared/calculator-next/types';
import useCalculatorMeta from '../../shared/calculator-next/useCalculatorMeta';
import ExpressCalculator from './ExpressCalculator';
import UsedCalculator from './UsedCalculator';

export type CalculatorProps = {
    application: SnapshotSourceValues & {
        // temporary variant for preowned form in express
        expressVariant?: SnapshotSourceValues['variant'];
    };
    onChange: (context: CalculatorContext<CalculatorValues>) => void;
    channel: Channel;
    dealerId: string;
    variants?: VariantDataFragment[];
    eventId?: string;
};

const Calculator = ({ application, onChange, channel, dealerId, variants, eventId }: CalculatorProps) => {
    const dispatch = useDispatch();

    const { mayManageRecalculate, mayManageRecalculateInterestRate, mayManageRecalculateResidualValue } = useSelector(
        getGlobalPermissions
    );

    const { code } = useCountry();

    // get initial values from the application
    const initialValues = useMemo(() => getCalculatorFromApplication(application), [application]);
    // also get a snapshot
    const snapshot = useMemo(() => getSnapshotFromApplication(application), [application]);

    // get dealer for PNZ purposes
    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, channel);

    // initialize pre owned details for express calculator
    useEffect(() => {
        if (channel === Channel.EXPRESS && !application.expressVariant) {
            // create temporary variant for express calculator
            dispatch(change('application', 'expressVariant', application.variant));
        }
    }, [application, channel, dispatch]);

    useEffect(() => {
        return () => {
            if (channel === Channel.EXPRESS) {
                // remove created field on unmount
                dispatch(change('application', 'expressVariant', undefined));
            }
        };
    }, [channel, dispatch]);

    const recalculate = useMemo(
        () => ({
            isActive: true,
            mayManageRecalculate,
            mayManageRecalculateInterestRate,
            mayManageRecalculateResidualValue,
        }),
        [mayManageRecalculate, mayManageRecalculateInterestRate, mayManageRecalculateResidualValue]
    );

    const allowTradeInAmountInput = useMemo(() => application.event?.setting?.allowTradeInAmountInput, [
        application.event,
    ]);

    // common meta
    const additionalMeta = useMemo(
        () => ({
            channel,
            snapshot,
            selectedDealerEstablishment,
            allowOutdated: true,
            recalculate,
            ...(variants ? { variants } : {}),
            allowTradeInAmountInput: channel === Channel.EVENT && allowTradeInAmountInput,
        }),
        [allowTradeInAmountInput, channel, recalculate, selectedDealerEstablishment, snapshot, variants]
    );
    const meta = useCalculatorMeta(additionalMeta);

    // gather common properties for calculators
    const commonProps = {
        // dealer id tagged to the application
        dealerId,
        // computed initial values
        initialValues,
        // change listener
        onChange,
        // the computed snapshot based on initial values
        snapshot,
        // common meta
        meta,
        // we must recalculate with the same bank only
        disabledBank: true,
        // is calculator in view mode
        disabled: !mayManageRecalculate,
    };

    // function to render the calculator element
    const renderCalculator = () => {
        switch (channel) {
            case Channel.NEW:
                return (
                    <ConnectedCalculator {...commonProps} channel={channel}>
                        <displayFields.CarModelPriceField
                            fieldKey="carModelAndPrice"
                            isViewable={() => true}
                            size={2}
                            override
                        />
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => false}
                            labelTKey="calculator.label.carModel"
                            override
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                    </ConnectedCalculator>
                );

            case Channel.USED:
                return <UsedCalculator {...commonProps} />;

            case Channel.EXPRESS:
                if (!application.expressVariant) {
                    // temporary variant is initializing
                    return null;
                }

                if (!application.expressVariant.preOwnedCarDetails) {
                    throw new Error('Missing pre owned car details on the variant');
                }

                return (
                    <ExpressCalculator
                        {...commonProps}
                        channel={Channel.EXPRESS}
                        countryCode={code}
                        preOwnedCarDetails={application.expressVariant.preOwnedCarDetails}
                        snapshot={snapshot}
                        variant={application.expressVariant}
                    />
                );

            default:
                return (
                    <ConnectedCalculator {...commonProps} channel={channel} eventId={eventId}>
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => true}
                            labelTKey="calculator.label.carModel"
                            size={2}
                            override
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                    </ConnectedCalculator>
                );
        }
    };

    return renderCalculator();
};

export default Calculator;
