import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams, useHistory } from 'react-router';
import { Channel } from '../../../../schema';
import { getCompanyIdentifier } from '../../../../selectors';
import { getEventsUrl } from '../../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../../data/useDealerIdFromTenantContext';
import HelmetTitle from '../../../shared/HelmetTitle';
import { LocationParams } from '../../ApplicationRoute';
import Details from './Details';
import { Context } from './context';
import { getData, GetDataQuery, GetDataQueryVariables, EventDetailsDataFragment } from './data.graphql';

export type EventData = EventDetailsDataFragment;

const EventDetailsRoute = () => {
    const history = useHistory();

    const { id } = useParams<LocationParams>();
    const { companyCode, countryId, locationCode } = useSelector(getCompanyIdentifier);

    const { data, loading } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables: { countryId: countryId!, eventId: id },
        fetchPolicy: 'no-cache',
    });

    const event = data?.event;
    const availableDealers = data?.availableDealers?.items;

    const selectedDealerId = useMustExistDealerIdFromTenantContext();
    const url = getEventsUrl(companyCode, locationCode);

    useEffect(() => {
        const isAvailableDealer = event?.dealerIds
            ? event.dealerIds.some(dealerId => isEqual(dealerId, selectedDealerId))
            : true;

        if (!isAvailableDealer) {
            history.push(url);
        }
    }, [event, history, selectedDealerId, url]);

    if (loading) {
        // wait for the loading to be over
        return null;
    }

    if (!event) {
        return <Redirect to={url} />;
    }

    return (
        <>
            <HelmetTitle channel={Channel.EVENT} title="Details" />
            <Context.Provider value={{ availableDealers }}>
                <Details key={event.id} event={event} />
            </Context.Provider>
        </>
    );
};

export default EventDetailsRoute;
