import { faCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, flow } from 'lodash/fp';
import React from 'react';
import styled, { css } from 'styled-components';
import { applicationChannelOptions } from '../../utilities/constants/options';

const inactiveColor = '#919099';
const activeColor = '#78e139';

const Cell = styled.div`
    text-align: center;
`;

const Highlight = styled.span`
    ${props =>
        props.expired &&
        css`
            color: #d5001c;
        `}
`;

export const renderActive = key => item => (
    <Cell>
        <FontAwesomeIcon color={get(key, item) ? activeColor : inactiveColor} icon={faCircle} />
    </Cell>
);

export const renderCalendar = isExternalLinkClickable => item => {
    const clickable = isExternalLinkClickable(item);

    return (
        <Cell>
            <FontAwesomeIcon
                color={clickable ? 'initial' : inactiveColor}
                cursor={clickable ? 'pointer' : 'not-allowed'}
                icon={faExternalLinkAlt}
            />
        </Cell>
    );
};

export const renderHighlightedDate = (key, isExpired, formatDateTime) => item => (
    <Highlight expired={isExpired(item)}>{flow([get(key), formatDateTime])(item)}</Highlight>
);

export const renderCurrencyDown = (key, formatCurrencyDown) => item => {
    const value = get(key, item);

    if (!value) {
        return null;
    }

    return <span style={{ whiteSpace: 'nowrap' }}>{formatCurrencyDown(value)}</span>;
};

export const renderNameOrDescription = (nameKey, descriptionKey, ct) => item => {
    const name = get(nameKey, item);
    const description = get(descriptionKey, item);

    return ct(name) || ct(description);
};

export const renderChannelOption = (item, t) => {
    const keyMapped = `${item?.channel}:${item?.access}`;

    const channelOptionsMap = Object.fromEntries(
        applicationChannelOptions(t).map(option => [`${option.channel}:${option.access}`, option.label])
    );

    return get(keyMapped, channelOptionsMap);
};
