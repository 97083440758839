import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderLogo from '../shared/HeaderLogo';
import { Container, Title } from '../ui/GenericPage';

const Error404NotFound = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <HeaderLogo />
            <Title>{t('error404Page.pageNotFound')}</Title>
        </Container>
    );
};

export default Error404NotFound;
