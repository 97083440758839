import { subYears } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';

// eslint-disable-next-line import/prefer-default-export
export const formatDateTimeWithOffset = (timeZone, formatStr) => date =>
    date ? format(utcToZonedTime(date, timeZone), formatStr, { timeZone }) : '';

export const getMinDateOfBirth = () => subYears(new Date(), 18);

export const formatDateOnly = (timeZone, formatStr) => date =>
    date ? format(utcToZonedTime(date, timeZone), formatStr, { timeZone }) : '';

export const formatDateTimeOnly = (timeZone, formatStr) => date =>
    date ? format(utcToZonedTime(date, timeZone), formatStr, { timeZone }) : '';
