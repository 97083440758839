import { TequipmentMaterials } from '../../Configurator/Tequipment';

type getCleanCKDValuesProps = {
    ckdConfiguration: any;
    exterior: string;
    interior: string;
    isEnhancedPackageSeleted: boolean;
    tEquipment?: TequipmentMaterials;
    isTimepieceSelected: boolean;
    deliveryMethod: string;
};

const getCleanCKDValues = ({
    ckdConfiguration,
    exterior,
    interior,
    isEnhancedPackageSeleted,
    tEquipment,
    isTimepieceSelected,
    deliveryMethod,
}: getCleanCKDValuesProps) => {
    const exteriorValue = ckdConfiguration.exterior.options.find(
        (item: { name: string; label: string }) => item.name === exterior
    );
    const interiorValue = ckdConfiguration.interior.options.find(
        (item: { name: string; label: string }) => item.name === interior
    );
    const delivery = ckdConfiguration.delivery.options.find(
        (item: { name: string; label: string }) => deliveryMethod === item.name
    );

    return {
        exteriorColor: exteriorValue?.label,
        interiorColor: interiorValue?.label,
        isEnhancedPackageSeleted,
        tEquipment,
        isTimepieceSelected,
        delivery: delivery?.label,
    };
};

export default getCleanCKDValues;
