import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadTimezones } from '../../actions';
import { QueryAppOptionsQuery } from '../../api/miscellaneous.graphql';
import { getTimezones } from '../../selectors';

type TimeZoneLabel = 'abbreviation' | 'name';

type TimeZoneOption = { label: string; value: string };

const useTimeZones = (labelField: TimeZoneLabel = 'name'): TimeZoneOption[] => {
    const timezones: QueryAppOptionsQuery['options']['timezones'] = useSelector(getTimezones) || [];
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadTimezones());
    }, [dispatch]);

    return useMemo(() => {
        return timezones.map(timezone => ({ value: timezone.name, label: timezone[labelField] }));
    }, [timezones, labelField]);
};

export default useTimeZones;
