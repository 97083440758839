import { identity, isEmpty } from 'lodash/fp';
import React, { useCallback } from 'react';
import IdleTimer from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCountry } from '../../hookSelectors';
import { getAuthorization } from '../../selectors';
import { getTimeoutUrl } from '../../utilities/urls';

type PublicSessionTimerProps<T extends {}> = {
    url: string;
    state?: T;
    onIdle?: () => void;
};

const PublicSessionTimer = <T extends {}>({ onIdle: idle, ...props }: PublicSessionTimerProps<T>) => {
    // retrieve the session/authorization from the redux state
    const authorization = useSelector(getAuthorization);
    // ensure that it is public access when using this timer
    const useIdle = isEmpty(authorization);
    const country = useCountry();
    const history = useHistory();

    const onIdle = useCallback(() => {
        if (idle) {
            idle();
        }

        // @ts-ignore
        history.pushWithCompanyAndState(getTimeoutUrl, props);
    }, [history, idle, props]);

    if (!useIdle) {
        return null;
    }

    return (
        <IdleTimer
            debounce={250}
            element={document}
            onAction={identity}
            onActive={identity}
            onIdle={onIdle}
            timeout={country.sessionTimeout * 60000}
        />
    );
};

export default PublicSessionTimer;
