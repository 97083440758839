import React from 'react';
import RemoteFlowBootstrap from '../RemoteRoute/RemoteFlowBootstrap';
import GuarantorFlowHandler from './GuarantorFlowHandler';

export type GuarantorHandlerProps = { token: string; submitted: boolean };

const GuarantorHandler = ({ token, submitted }: GuarantorHandlerProps) => (
    <RemoteFlowBootstrap token={token}>
        {(application, consents) => (
            <GuarantorFlowHandler application={application} consents={consents} submitted={submitted} token={token} />
        )}
    </RemoteFlowBootstrap>
);

export default GuarantorHandler;
