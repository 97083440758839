import React from 'react';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { FlowStep } from '../../utils/flow';
import getDealerIdFromFlowState from '../../utils/getDealerIdFromFlowState';
import Calculator from '../components/UsedCalculator';
import { NewFlowState } from '../types';
import { CalculatorStepValues } from './CalculatorStep';

export type UsedCalculatorStepValues = CalculatorStepValues & {
    unitId: string;
    hasTradeIn?: boolean;
    hasTestDrive?: boolean;
};

class UsedCalculatorStep extends FlowStep<NewFlowState, UsedCalculatorStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'calculator';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('usedCalculatorPage.label.step');
    }

    public render(): React.ReactElement | null {
        const { calculator, snapshot, channel, hasTestDrive, hasTradeIn } = this.state;
        const dealerId = getDealerIdFromFlowState(this.state);

        return (
            <>
                <HelmetTitle channel={channel} title="Calculator" />
                <Calculator
                    dealerId={dealerId}
                    hasTestDrive={hasTestDrive}
                    hasTradeIn={hasTradeIn}
                    initialValues={calculator}
                    onSubmit={this.submit}
                    snapshot={snapshot}
                />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute({
        calculator,
        bank,
        financeProduct,
        unitId,
        promo,
        variant,
        hasTestDrive,
        hasTradeIn,
    }: UsedCalculatorStepValues) {
        // persist the bank and calculator, as well as the finance product
        this.stateStore.update({
            calculator,
            bank,
            financeProduct,
            unitId,
            snapshot: undefined,
            promo,
            disableFinanceApplication: !financeProduct?.variants.some(
                assignedVariant => assignedVariant === variant.version.id
            ),
            hasTestDrive,
            hasTradeIn,
        });

        return this.nextStep;
    }
}

export default UsedCalculatorStep;
