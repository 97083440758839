import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect, createRef } from 'react';
import { Field } from 'redux-form';
import { Container, OTPBox, OTPInput } from '../../ui/form/OTPInput';

const patternAplhaNumeric = /^[a-zA-Z0-9]/;
const CodeInput = ({ input, length }) => {
    const { onChange: setCode } = input;
    const [codeEle, setCodeEle] = useState([]);
    const [codes, setCodes] = useState([]);
    useEffect(() => {
        setCodeEle(elRefs =>
            Array(length)
                .fill()
                .map((_, i) => elRefs[i] || createRef())
        );

        setCodes(state =>
            Array(length)
                .fill()
                .map((_, i) => state[i] || '')
        );
    }, [setCodeEle, setCodes, length]);

    const onChange = useCallback(
        (event, index) => {
            let { data: value } = event.nativeEvent;
            const { inputType } = event.nativeEvent;

            if (inputType === 'deleteContentBackward') {
                const newState = codes.map((_, i) => (i === index ? '' : _));
                setCodes(newState);

                if (index > 0) {
                    codeEle[index - 1].current.focus();
                }
                setCode(newState.join(''));
            }

            if (value) {
                if (!patternAplhaNumeric.test(value)) {
                    value = '';
                }
            }

            if (value) {
                // if value is valid, should move to next input
                const newState = codes.map((_, i) => (i === index ? value.toUpperCase() : _));
                setCodes(newState);

                codeEle[index].current.blur();

                if (index < length - 1) {
                    codeEle[index + 1].current.focus();
                }

                setCode(newState.join(''));
            }
        },
        [setCodes, codeEle, setCode, codes, length]
    );

    const onPaste = useCallback(
        event => {
            const values = event.clipboardData.getData('text/plain');
            setCodes(values.split(''));
            setCode(values);
        },
        [setCode]
    );

    return (
        <Container>
            {codeEle.map((item, index) => (
                <OTPBox key={index.toString()}>
                    <OTPInput
                        ref={item}
                        onChange={event => onChange(event, index)}
                        onPaste={onPaste}
                        value={codes[index]}
                    />
                </OTPBox>
            ))}
        </Container>
    );
};

CodeInput.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    length: PropTypes.number.isRequired,
};

const OTPField = props => <Field component={CodeInput} {...props} />;

export default OTPField;
