import { useMemo } from 'react';
import usePhone from './usePhone';

const useValidationContext = () => {
    const { pattern: phonePattern } = usePhone();

    return useMemo(
        () => ({
            phonePattern: phonePattern ? new RegExp(phonePattern) : null,
        }),
        [phonePattern]
    );
};

export default useValidationContext;
