import { isNil } from 'lodash/fp';
import React from 'react';
import {
    completeConsents,
    CompleteConsentsMutation,
    CompleteConsentsMutationVariables,
} from '../../../api/draft.graphql';
import Consent from '../../DraftFlow/components/Consent';
import { ConsentStepValues } from '../../DraftFlow/steps/ConsentStep';
import { getFinancingConsents, getNonFinancingConsents } from '../../DraftFlow/utils/consents';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';

class ConsentStep extends FlowStep<RemoteFlowState, ConsentStepValues> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'consent';
    }

    public async execute({ referenceId }: ConsentStepValues) {
        const { apolloClient } = this;
        const { token } = this.state;

        const variables = { token, eventId: referenceId };

        const { data } = await apolloClient.mutate<CompleteConsentsMutation, CompleteConsentsMutationVariables>({
            mutation: completeConsents,
            variables,
        });

        this.stateStore.update({ ...data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { consents: all, application, channel } = this.state;

        const consents = application.appliedForFinancing
            ? getFinancingConsents(all, channel)
            : getNonFinancingConsents(all, channel);

        if (isNil(consents)) {
            throw new Error('Consents missing in state');
        }

        return (
            <Consent
                backStep={this.getBackContext()}
                consents={consents}
                isLastStep={this.isLastStep}
                onSubmit={this.submit}
            />
        );
    }
}

export default ConsentStep;
