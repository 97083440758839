import { CalculatorContext, useGridCalculatorContext, CarOptions } from '@appvantageasia/afc-calculator-ui-next';
import React, { memo, useState, useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';

export type CarOptionsFieldProps = {
    containerId: string;
    fieldKey: string;
    isViewable: (context: CalculatorContext<CalculatorValues>) => boolean;
    size?: number;
};

const CarOptionsField = ({ containerId }: CarOptionsFieldProps) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);
    const { getFieldContext, change, values } = useGridCalculatorContext();
    const selectedOptionIds = values?.carOptions || [];
    const value = getFieldContext('carOptions');
    const { groupedOptions: groups, selectedOptions: selected } = value;

    // we need to retrieve the DOM node as soon as it is available
    useLayoutEffect(() => {
        // provide our node
        setContainer(document.getElementById(containerId));
    }, [containerId, setContainer]);

    // prepare on change callbacks
    const onChanges = useMemo(() => {
        // factory to build our callbacks
        const makeOnChange = (groupId: string) => (option: any) => {
            const optionIds = [...selectedOptionIds];

            if (selected[groupId].id !== option.id) {
                const index = selectedOptionIds.indexOf(selected[groupId].id);
                optionIds.splice(index, 1);
                optionIds.push(option.id);

                change('carOptions', optionIds);
            }
        };

        // build our callbacks
        const callbacks = groups.map((group: { id: string }) => [group.id, makeOnChange(group.id)]);

        return Object.fromEntries(callbacks);
    }, [change, groups, selected, selectedOptionIds]);
    const fields = groups.map(({ id, name, options }: any, index: any) => (
        <CarOptions key={id} activeOption={selected[id]} onChange={onChanges[id]} options={options} title={name} />
    ));

    const children = <div style={{ textAlign: 'center' }}>{fields}</div>;

    return container && createPortal(children, container);
};

export default memo(CarOptionsField);
