import { omit } from 'lodash/fp';
import { OPTIONS_SET, OPTIONS_UNSET, OPTIONS_UNSET_ALL, OptionsActions } from '../actions/options';
import { QueryAppOptionsQuery, CountryPhoneAppDataFragment } from '../api/miscellaneous.graphql';
import persistState from './persistState';

export type AppOptions = QueryAppOptionsQuery['options'];

export type DialingCodesOption = {
    value: string;
    label: string;
    countryCode: string;
    phone: CountryPhoneAppDataFragment;
};

export type Option<T> = {
    value: T;
    label: string;
};

export type OptionsState = {
    nationalities?: Option<string>[];
    statusOptions?: AppOptions['statusOptions'];
    countriesDialingCodes?: DialingCodesOption[];
    dialingCodes?: DialingCodesOption[];
    genders?: AppOptions['genders'];
    races?: AppOptions['races'];
    maritalStatuses?: AppOptions['maritalStatuses'];
    residentialStatuses?: AppOptions['residentialStatuses'];
    drivingLicenseValidityCodes?: AppOptions['drivingLicenseValidities'];
    drivingLicenseClasses?: AppOptions['drivingLicenseClasses'];
    cities?: AppOptions['cities'];
    timezones?: AppOptions['timezones'];
    languages?: AppOptions['languages'];
    relationships?: AppOptions['relationships'];
    educations?: AppOptions['educations'];
    addressTypes?: AppOptions['addressTypes'];
    incomeTypes?: AppOptions['incomeTypes'];
    residenceTypes?: AppOptions['residenceTypes'];
    emirates?: Option<string>[];
    affinBank?: AppOptions['affinBank'];
    occupations?: AppOptions['occupations'];
    downpaymentTo?: AppOptions['downpaymentTo'];
    claimDiscountOptions?: Array<{ label: string; value: number }>;
    yearsOfDrivingOptions?: Array<{ label: string; value: number }>;
};

const reducer = (state: OptionsState = {}, action: OptionsActions): OptionsState => {
    switch (action.type) {
        case OPTIONS_SET:
            return { ...state, [action.key]: action.value };

        case OPTIONS_UNSET:
            return omit([action.key], state);

        case OPTIONS_UNSET_ALL:
            return {};

        default:
            return state;
    }
};

export default persistState('options', reducer);
