import styled from 'styled-components';

export const MaintenanceTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px auto;
    margin: 20px 0;
    font-size: 1.71rem;

    img {
        margin: 10% auto 20px;
        max-width: 300px;
        max-height: 100px;
    }

    @media (max-width: 450px) {
        padding: 0 15px;
    }
`;

export const MaintenanceContent = styled.div`
    display: block;
    text-align: center;
    margin: 20px auto;
    font-size: 1.14rem;

    @media (max-width: 450px) {
        padding: 0 15px;
    }
`;

export const MaintenanceNotification = styled.div`
    z-index: 999;
    display: block;
    position: fixed;
    bottom: 0px;
    height: 30px;
    line-height: 30px;
    background: #00000095;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    color: #fff;
`;
