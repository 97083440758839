import { faBars } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Channel } from '../../schema';
import { getIsAuthenticated } from '../../selectors';
import IconButton from '../ui/IconButton';
import HeaderContainer from '../ui/header/HeaderContainer';
import HeaderController from '../ui/header/HeaderController';
import { useFixedMenuController } from './FixedMenuController';
import { useHeaderController } from './HeaderController';
import HeaderLogo from './HeaderLogo';
import LanguageSelector from './LanguageSelector';
import LoginAccess from './LoginAccess';
import MenuSidebar from './MenuSidebar';

type HeaderProps = {
    channel?: Channel;
};

const Header = ({ channel }: HeaderProps) => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const [{ component: Component, properties }] = useHeaderController();
    const { showMenu, onToggle, onClose } = useFixedMenuController();

    return (
        <>
            {isAuthenticated && <LoginAccess />}
            <HeaderContainer>
                <div>
                    {isAuthenticated && !showMenu && (
                        <IconButton icon={faBars} onClick={onToggle} onMouseEnter={onToggle} size="lg" />
                    )}
                </div>
                <HeaderLogo channel={channel} />
                <HeaderController>
                    <LanguageSelector />
                    {Component && <Component {...properties} />}
                </HeaderController>
            </HeaderContainer>
            {isAuthenticated && <MenuSidebar onClose={onClose} open={showMenu} />}
        </>
    );
};

export default Header;
