import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ReservationRoute from '../routes/ReservationRoute';
import ReservationsRoute from '../routes/ReservationsRoute';
import ResumeRoute from '../routes/wip/ResumeRoute';
import Header from '../shared/Header';
import { prefixReservationPath } from './shared';
import useNotPaidBackAction from './useNotPaidBackAction';
import useNotSignedBackAction from './useNotSignedBackAction';

const ReservationRouter = () => {
    useNotSignedBackAction();
    useNotPaidBackAction();

    return (
        <>
            <Header />
            <Switch>
                <Route component={ReservationsRoute} path={prefixReservationPath('/')} exact />
                <Route component={ReservationRoute} path={prefixReservationPath('/:id')} exact />
                <Route component={ResumeRoute} path={prefixReservationPath('/:id/resume')} exact />
                <Redirect to={prefixReservationPath('/')} />
            </Switch>
        </>
    );
};

export default ReservationRouter;
