import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useMedia from 'use-media';
import { getRuntimeSettings } from '../../../../../selectors';
import breakpoints from '../../../../../utilities/constants/breakpoints';

const useDeliveryImage = (dealiveryMethod?: string) => {
    const isMobileSource = useMedia({ maxWidth: breakpoints.sm });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    return useMemo(() => {
        if (dealiveryMethod === ckdConfiguration.delivery.options[0].name) {
            return isMobileSource
                ? ckdConfiguration.delivery.options[0].mobileImage
                : ckdConfiguration.delivery.options[0].desktopImage;
        }
        if (dealiveryMethod === ckdConfiguration.delivery.options[1].name) {
            return isMobileSource
                ? ckdConfiguration.delivery.options[1].mobileImage
                : ckdConfiguration.delivery.options[1].desktopImage;
        }

        return isMobileSource
            ? ckdConfiguration.delivery.options[0].mobileImage
            : ckdConfiguration.delivery.options[0].desktopImage;
    }, [dealiveryMethod, isMobileSource, ckdConfiguration.delivery.options]);
};

export default useDeliveryImage;
