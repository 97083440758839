import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useCountry } from '../../hookSelectors';
import {
    getInsuranceCompany,
    GetInsuranceCompanyQuery,
    GetInsuranceCompanyQueryVariables,
} from '../data/useLoadInsuranceCompany.graphql';

const useInsuranceCompany = () => {
    const { id: countryId } = useCountry();

    const { data } = useQuery<GetInsuranceCompanyQuery, GetInsuranceCompanyQueryVariables>(getInsuranceCompany, {
        variables: {
            countryId,
        },
    });

    return useMemo(() => data?.insuranceCompany, [data]);
};

export default useInsuranceCompany;
