import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, fieldInputPropTypes } from 'redux-form';
import styled, { useTheme, css } from 'styled-components';
import { Channel } from '../../../schema';
import { ReactComponent as Checked } from '../../../assets/images/boxChecked.svg';
import { ReactComponent as Unchecked } from '../../../assets/images/boxUnchecked.svg';

const CheckboxContainer = styled.label`
    display: flex;
    margin: 5px 0px 10px 0px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

CheckboxContainer.Checkbox = styled.div`
    background-color: ${props => (props.isUsedChannel && props.checked ? '#313539' : '#fff')};
    width: 20px;
    height: 20px;

    ${props =>
        props.isUsedChannel &&
        props.checked &&
        css`
            color: #fff;
            text-align: center;
            font-size: 12px;
            line-height: 22px;
        `}

    svg {
        width: 20px;
    }

    input {
        display: none;
    }
`;

CheckboxContainer.Label = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 3px;
    text-align: left;
    font-size: 1rem;
    color: #000;
`;

const CheckboxInput = ({ input, label, disabled, channel = Channel.NEW }) => {
    const theme = useTheme();
    const { value = false, onChange, name } = input;
    const boolValue = Boolean(value);

    const onClick = useCallback(
        event => {
            event.preventDefault();
            onChange(!boolValue);

            return false;
        },
        [onChange, boolValue]
    );

    const isUsedChannel = channel === Channel.USED;

    const checkIcon = isUsedChannel ? <Icon icon={faCheck} /> : <Checked fill={theme.themeHighlightColour} />;

    return (
        <CheckboxContainer
            disabled={disabled}
            htmlFor={name}
            isUsedChannel={isUsedChannel}
            onClick={!disabled ? onClick : undefined}
        >
            <CheckboxContainer.Checkbox checked={boolValue} isUsedChannel={isUsedChannel}>
                {boolValue ? checkIcon : <Unchecked />}
                <input checked={boolValue} name={name} readOnly />
            </CheckboxContainer.Checkbox>
            <CheckboxContainer.Label isUsedChannel={isUsedChannel}>{label}</CheckboxContainer.Label>
        </CheckboxContainer>
    );
};

CheckboxInput.propTypes = {
    channel: Channel,
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    label: PropTypes.string.isRequired,
};

const CheckboxField = props => <Field {...props} component={CheckboxInput} />;

export default CheckboxField;
