import { some } from 'lodash/fp';
import React, { memo, useMemo } from 'react';
import { ApplicationEventType, ApplicationStatus } from '../../../../schema';
import useFormChanged from '../../ApplicationRoute/actions/useFormChanged';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';
import ContinueAction from './ContinueAction';
import ReSubmitAction from './ReSubmitAction';
import RetryAction from './RetryAction';
import SaveChangesAction from './SaveChangesAction';
import UpdateStateAction from './UpdateStateAction';

export type ActionsProps = {
    allowPrimaryInfoChanges?: boolean;
    application: ApplicationDataFragment;
};

const Actions = ({ allowPrimaryInfoChanges = false, application }: ActionsProps) => {
    const hasChanged = useFormChanged();

    const received = useMemo(() => {
        return some(event => event.type === ApplicationEventType.RECEIVE, application.events);
    }, [application.events]);

    const { appliedForFinancing } = application;

    if (allowPrimaryInfoChanges) {
        return <SaveChangesAction application={application} />;
    }

    switch (application.status) {
        case ApplicationStatus.CUSTOMERINFORECEIVED:
            return received || hasChanged ? (
                <ReSubmitAction application={application} approveDisabled declineDisabled />
            ) : (
                <ContinueAction application={application} approveDisabled declineDisabled />
            );

        case ApplicationStatus.RECEIVED:
        case ApplicationStatus.AGREEMENTCONCLUDED:
            if (!appliedForFinancing) {
                return <UpdateStateAction application={application} />;
            }

            return <ReSubmitAction application={application} />;

        case ApplicationStatus.APPROVED:
            return <ReSubmitAction application={application} />;

        case ApplicationStatus.PAYING:
        case ApplicationStatus.PAID:
        case ApplicationStatus.PAYMENTTIMEOUT:
        case ApplicationStatus.UNABLETOPAY:
        case ApplicationStatus.PAYMENTFAILED:
        case ApplicationStatus.PENDINGCUSTOMERCONFIRMATION:
        case ApplicationStatus.PENDINGGUARANTORCONFIRMATION:
        case ApplicationStatus.SIGNING:
        case ApplicationStatus.PENDINGOTP:
        case ApplicationStatus.SIGNINGREJECTED:
        case ApplicationStatus.SIGNINGTIMEOUT:
        case ApplicationStatus.UNABLETOCONNECT:
        case ApplicationStatus.CONNECTIONFAILED:
        case ApplicationStatus.DRAFT:
            if (hasChanged) {
                return <ReSubmitAction application={application} />;
            }

            return <ContinueAction application={application} />;

        case ApplicationStatus.SUBMISSIONFAILED:
        case ApplicationStatus.UNABLETOSUBMIT:
            if (hasChanged) {
                return <ReSubmitAction application={application} />;
            }

            return <RetryAction application={application} />;

        default:
            return <UpdateStateAction application={application} />;
    }
};

export default memo(Actions);
