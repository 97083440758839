// @ts-ignore
import { PrimaryButton } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { FormErrors, reduxForm, InjectedFormProps } from 'redux-form';
import * as yup from 'yup';
import { requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import PasswordField from '../../shared/form/PasswordField';
import TextField from '../../shared/form/TextField';
import { TextAlignRight } from '../../ui/TextAlign';
import FormActions, { Error } from '../../ui/form/FormActions';
import LoginLogo from '../../ui/login/LoginLogo';
import ResetPasswordButton from '../../ui/login/ResetPasswordButton';

type CredentialFormProps = {
    goToPasswordReset: (e: any) => void;
};

type StateFromUrl = {
    state: {
        from: string | null;
    };
};

const useNotificationMessage = () => {
    const { t } = useTranslation();
    const { state }: StateFromUrl = useLocation();

    if (!state) {
        return null;
    }

    switch (state.from) {
        case 'reset':
            return t('common.error.resetPasswordSuccess');

        default:
            return null;
    }
};

const useErrorMessage = () => {
    const { state }: StateFromUrl = useLocation();
    const { t } = useTranslation();

    if (!state) {
        return null;
    }

    switch (state.from) {
        case 'expired':
            return t('common.error.sessionExpired');

        case 'override':
            return t('common.error.signInOverride');

        default:
            return null;
    }
};

const CredentialForm = ({
    goToPasswordReset,
    handleSubmit,
}: CredentialFormProps & InjectedFormProps<any, CredentialFormProps>) => {
    const { t } = useTranslation();
    const notification = useNotificationMessage();
    const externalError = useErrorMessage();

    return (
        <form autoComplete="new-password" onSubmit={handleSubmit}>
            <LoginLogo />
            <TextField.Outline
                autoComplete="off"
                label={t('companyAuthenticationPage.label.username')}
                name="username"
                placeholder={t('companyAuthenticationPage.label.username')}
                autoFocus
            />
            <PasswordField.Outline
                autoComplete="off"
                label={t('companyAuthenticationPage.label.password')}
                name="password"
                placeholder="Password"
            />
            <TextAlignRight>
                <ResetPasswordButton onClick={goToPasswordReset} type="button">
                    {t('companyAuthenticationPage.button.forgotPassword')}
                </ResetPasswordButton>
            </TextAlignRight>
            {notification && <p>{notification}</p>}
            <FormActions>
                <div>
                    <PrimaryButton type="submit">{t('companyAuthenticationPage.button.signIn')}</PrimaryButton>
                </div>
                <FormError externalError={externalError} form="loginCredentials">
                    {(error: FormErrors<{}, string>) => <Error>{error}</Error>}
                </FormError>
            </FormActions>
        </form>
    );
};

export const schema = (t: TFunction) =>
    yup.object().shape({
        password: requiredString(t('companyAuthenticationPage.error.passwordRequired')).matches(
            /^(?=.{8,40}$).*/,
            t('companyAuthenticationPage.error.passwordRange', { min: 8, max: 40 })
        ),
        username: requiredString(t('companyAuthenticationPage.error.usernameRequired')).matches(
            /^(?=.{3,40}$).*/,
            t('companyAuthenticationPage.error.usernameRange', { min: 3, max: 40 })
        ),
    });

export default reduxForm<any, CredentialFormProps>({ form: 'loginCredentials' })(CredentialForm);
