import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

const TooltipPortal = styled.div`
    background-color: #fff;
    color: #000;
    border-radius: 0px;
    border: 1px solid #ccc;
    padding: 0;
    white-space: pre-wrap;
    z-index: 99999;
    font-size: 1rem;
    max-width: 300px;
    position: fixed;
    text-align: center;
`;

const TooltipContainer = styled.div`
    padding: 12px;
`;

type TooltipArrowPosition = 'top' | 'bottom';

const tooltipTopCss = css`
    top: -15px;
    left: 50%;
    border-color: transparent transparent #ccc transparent;

    &::before {
        top: -6px;
        border-color: transparent transparent #fff transparent;
    }
`;

const tooltipBottomCss = css`
    bottom: -15px;
    left: 50%;
    border-color: #ccc transparent transparent transparent;

    &::before {
        bottom: -6px;
        border-color: #fff transparent transparent transparent;
    }
`;

const TooltipArrow = styled.div<{ $position: TooltipArrowPosition }>`
    width: 0;
    height: 0;
    position: absolute;
    border: inset 7px;
    margin-left: -7px;
    border-style: solid;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        margin-left: -7px;
        border: inset 7px;
        border-style: solid;
    }
    ${props => (props.$position === 'top' ? tooltipTopCss : tooltipBottomCss)}
`;

type NextTooltipProps = {
    children: React.ReactNode;
    tooltip: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
};

export const Portal = ({ children }: { children: React.ReactNode }) => {
    return createPortal(children, document.body);
};

const delay = 100;
const spacing = 10;

const NextTooltip = ({ children, tooltip, style, className }: NextTooltipProps) => {
    const [isShow, setShow] = useState(false);
    const [position, setPosition] = useState({ top: -1, left: 0 });
    const [tooltipArrowPosition, setTooltipArrowPosition] = useState<TooltipArrowPosition>('top');

    const targetRef = useRef<HTMLSpanElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const timeoutRef = useRef<number | null>(null);

    const onMouseEnter = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        setShow(true);

        if (!targetRef.current) {
            return;
        }

        // Check if bounding box can be retrieved
        // If not, it means that the browser not supported
        const targetBox = targetRef.current?.getBoundingClientRect();
        if (!targetBox) {
            return;
        }

        let resultTop = targetBox.bottom + spacing;
        let resultLeft = targetBox.left;

        // get the rect of the tooltip
        let tooltipBox: DOMRect | null = null;
        if (tooltipRef.current) {
            tooltipBox = tooltipRef.current?.getBoundingClientRect();

            if (tooltipBox) {
                resultLeft = targetBox.left - tooltipBox.width / 2 + targetRef.current.offsetWidth / 2;
            }
        }

        // reposition the tooltip if it's out of the viewport
        const { innerHeight } = window;
        if (targetRef.current && tooltipBox !== null && resultTop + tooltipBox.height > innerHeight) {
            resultTop = targetBox.top - tooltipBox.height - spacing;
            setTooltipArrowPosition('bottom');
        } else {
            setTooltipArrowPosition('top');
        }

        setPosition({ top: resultTop, left: resultLeft });
    }, []);

    const onMouseLeave = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        timeoutRef.current = window.setTimeout(() => {
            setShow(false);
        }, delay);
    }, []);

    const onMouseEnterTooltip = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    return (
        <>
            <span
                ref={targetRef}
                className={className}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={style}
            >
                {children}
            </span>
            <Portal>
                <TooltipPortal
                    ref={tooltipRef}
                    onMouseEnter={onMouseEnterTooltip}
                    onMouseLeave={onMouseLeave}
                    style={{
                        top: position.top,
                        left: position.left,
                        visibility: isShow ? 'visible' : 'hidden',
                    }}
                >
                    <TooltipContainer>{tooltip}</TooltipContainer>
                    <TooltipArrow $position={tooltipArrowPosition} />
                </TooltipPortal>
            </Portal>
        </>
    );
};

export default NextTooltip;
