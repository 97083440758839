import { CalculatorContext, CurrencyField } from '@appvantageasia/afc-calculator-ui-next';
import { get } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { CalculatorInsuranceValues, CalculatorValues } from '../../types';
import EditionModal from '../EditionModal';

type COEFieldProps = {
    labelTKey: string;
    financeContext: CalculatorContext<CalculatorValues>;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
    close: () => void;
    onChange?: (value: number | undefined) => void;
};

const valueKey = 'coe';

const COEField = (props: COEFieldProps) => {
    const { financeContext } = props;
    const { values } = financeContext;

    const initialValue = (get(valueKey, values) as unknown) as number | undefined;

    const [value, setValue] = useState(initialValue);

    const onChange = useCallback(
        value => {
            setValue(value);
        },
        [setValue]
    );

    return (
        <EditionModal {...props} value={value} valueKey={valueKey}>
            <CurrencyField handleChange={onChange} initialValue={initialValue} />
        </EditionModal>
    );
};

export default COEField;
