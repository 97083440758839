import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDealerId } from '../../actions';
import { getDealerId } from '../../selectors';

export type PersistedTenantContext = {
    dealerId?: string;
};

export type TenantManagementContextValues = {
    current: PersistedTenantContext;
    update: (input?: PersistedTenantContext) => unknown;
};

export const useTenantManagementContext = () => {
    const dealerId = useSelector(getDealerId) as string | undefined;
    const dispatch = useDispatch();
    const update = useCallback(
        (input?: PersistedTenantContext) => {
            const { dealerId } = input ?? {};

            if (dealerId) {
                dispatch(setDealerId(dealerId));
            }
        },
        [dispatch]
    );

    return {
        current: { dealerId },
        update,
    };
};
