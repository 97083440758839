import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { ApplicationData } from '../../../ApplicationRoute';
import { AppointmentDataFragment } from '../../Appointment.graphql';
import { GetReservedAppointmentDatesQuery } from '../Details.graphql';

export type AppointmentDatas = Omit<AppointmentDataFragment, 'latestApplication'>;

export type AppointmentFormValues = AppointmentDatas & {
    latestApplication: ApplicationData;
};

export type AppointmentFormProps = {
    disabled?: boolean;
    reservedAppointments: GetReservedAppointmentDatesQuery['reserved'];
};

export type AppointmentFormContextData = AppointmentFormProps &
    InjectedFormProps<AppointmentFormValues, AppointmentFormProps> & {
        values: AppointmentFormValues;
    };

export const AppointmentFormContext = createContext<AppointmentFormContextData>(null!);

export const useAppointmentFormContext = () => {
    const context = useContext(AppointmentFormContext);

    if (!context) {
        throw new Error('AppointmentFormContext is not provided');
    }

    return context;
};
