import { useApolloClient } from '@apollo/client';
import { pick, isEqual } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { searchCustomer, SearchCustomerQuery, SearchCustomerQueryVariables } from '../../../../../api/customer.graphql';
import { ApplicationCustomerDataFragment } from '../../../../../components/routes/ApplicationRoute/data.graphql';
import { useCountry, useZone } from '../../../../../hookSelectors';
import { getIsAuthenticated } from '../../../../../selectors';
import { prepareForGraphQL } from '../../../../../utilities/forms';
import ExistingCustomerModal from './ExistingCustomerModal';
import NricCompareModal from './NricCompareModal';

export type SearchingStateCore = {
    values: Partial<ApplicationCustomerDataFragment>;
    onComplete: (nextValues: Partial<ApplicationCustomerDataFragment>) => void;
    reset: () => void;
};

export type SearchingIdentityState = SearchingStateCore & {
    type: 'identity';
    payload: ApplicationCustomerDataFragment;
};

export type SearchingPartialState = SearchingStateCore & {
    type: 'partial';
    payload: ApplicationCustomerDataFragment[];
};

export type SearchingState = SearchingIdentityState | SearchingPartialState;

export type SearchFunction = <TValues extends { customer: Partial<ApplicationCustomerDataFragment> }>(
    values: TValues,
    onComplete: (values: TValues) => Promise<TValues>
) => Promise<TValues>;

export const useSearchCustomer = (
    isFromLead: boolean,
    initialCustomerValues?: Partial<ApplicationCustomerDataFragment>
): [SearchFunction, SearchingState | null] => {
    const client = useApolloClient();
    const [state, setState] = useState<SearchingState | null>(null);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const { id: zoneId } = useZone();
    const { matchExistingCustomer } = useCountry();

    const search = useCallback<SearchFunction>(
        async (values, onComplete) => {
            if (!isAuthenticated || isFromLead || !matchExistingCustomer) {
                // nothing else to do
                return onComplete(values);
            }

            // we now should check if we need to check an existing customer
            // list of fields we are working with
            const fieldArray = ['name', 'email', 'phone', 'identityNumber'];
            // get current values from the form
            const currentCustomerValues = pick(fieldArray, values.customer);
            // and initial values
            const lastCustomerValues = pick(fieldArray, initialCustomerValues);
            // we may now get the information we are looking for
            const needSearchExistingCustomer = !isEqual(currentCustomerValues, lastCustomerValues);

            if (!needSearchExistingCustomer) {
                // nothing else to do
                return onComplete(values);
            }

            const variables = {
                zoneId,
                data: prepareForGraphQL({
                    name: values.customer.name?.value,
                    email: values.customer.email?.value,
                    phone: values.customer.phone?.value,
                    identityNumber: values.customer.identityNumber?.value,
                }),
            };

            // look for this customer
            const apiResponse = await client.query<SearchCustomerQuery, SearchCustomerQueryVariables>({
                query: searchCustomer,
                variables,
                fetchPolicy: 'network-only',
            });

            const existingCustomers = apiResponse?.data?.customers || [];

            if (!existingCustomers?.length) {
                // nothing else to do
                return onComplete(values);
            }

            return new Promise(resolve => {
                const identityMatch = existingCustomers.find(
                    // look for matches with the identity number
                    // identityNumber maybe null
                    match => match.identityNumber?.value === currentCustomerValues.identityNumber?.value
                );

                const core: SearchingStateCore = {
                    // also provide the values we are dealing with
                    values: values.customer,

                    // and also provide an onComplete callback
                    onComplete: (nextValues: Partial<ApplicationCustomerDataFragment>) => {
                        // empty the state
                        setState(null);

                        // searching has been designed to be used with redux form
                        // meaning we need to treat the whole flow as only one big promise
                        // so we need to wrap a promise inside our main
                        resolve(onComplete({ ...values, customer: nextValues }));
                    },

                    // provide a very simple reset function by following the same logic
                    reset: () => {
                        // empty the state
                        setState(null);

                        // skip the onComplete callback to let redux form continue the submit handling
                        resolve(values);
                    },
                };

                // update the state
                setState(
                    identityMatch
                        ? {
                              type: 'identity',
                              payload: identityMatch,
                              ...core,
                          }
                        : {
                              type: 'partial',
                              payload: existingCustomers,
                              ...core,
                          }
                );
            });
        },
        [isAuthenticated, isFromLead, matchExistingCustomer, initialCustomerValues, zoneId, client]
    );

    return [search, state];
};

export type SearchCustomerProps = { searching: SearchingState | null };

const SearchCustomer = ({ searching }: SearchCustomerProps) => {
    if (!searching) {
        return null;
    }

    const props = {
        onComplete: searching.onComplete,
        reset: searching.reset,
        values: searching.values,
    };

    switch (searching.type) {
        case 'identity':
            return <NricCompareModal info={searching.payload} {...props} />;

        case 'partial':
            return <ExistingCustomerModal list={searching.payload} {...props} />;

        default:
            return null;
    }
};

export default SearchCustomer;
