import { NumericInputV2 as NumericInput } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { parseNumber } from '../../../utilities/numberHelper';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';
import { CurrencyInput } from './CurrencyField';
import { TextInput } from './TextField';

const useTypeFormats = (type, overrideFormats) => {
    // get formatting
    const formats = useCompanyFormatting();

    switch (type) {
        case 'percentage':
            return {
                precision: overrideFormats?.percentagePrecision ?? formats.percentagePrecision,
                fixedDecimalScale: formats.fixedDecimalScale,
                thousandSeparator: formats.thousandSeparator,
                suffix: '%',
            };

        case 'currency':
            return {
                precision: overrideFormats?.currencyPrecision ?? formats.currencyPrecision,
                fixedDecimalScale: formats.fixedDecimalScale,
                thousandSeparator: formats.thousandSeparator,
                prefix: formats.currencySymbol,
            };

        case 'year':
            return {
                precision: 0,
            };

        case 'number':
        default:
            return {
                precision: overrideFormats?.currencyPrecision ?? formats.currencyPrecision,
                fixedDecimalScale: formats.fixedDecimalScale,
                thousandSeparator: formats.thousandSeparator,
            };
    }
};

const NumberInput = ({ type = 'number', overrideFormats, ...props }) => {
    const typeProps = useTypeFormats(type, overrideFormats);

    switch (type) {
        case 'currency':
            return <CurrencyInput {...typeProps} {...props} />;
        default:
            return <TextInput inputComponent={NumericInput} {...typeProps} {...props} />;
    }
};

NumberInput.propTypes = {
    overrideFormats: PropTypes.shape({
        currencyPrecision: PropTypes.number,
        percentagePrecision: PropTypes.number,
    }),
    type: PropTypes.string.isRequired,
};

const NumberField = props => <Field component={NumberInput} normalize={parseNumber} {...props} />;

export default NumberField;
