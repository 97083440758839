import React from 'react';
import FadeInDown from '../animations/FadeInDown';
import ErrorCard from '../ui/ErrorCard';
import ErrorTitle from '../ui/ErrorTitle';
import NotFoundImage from '../ui/NotFoundImage';
import RootStyle from '../ui/RootStyle';

const NotFoundRoute = () => (
    <>
        <RootStyle />
        <FadeInDown>
            <ErrorCard>
                <NotFoundImage />
                <div>
                    <ErrorTitle>Page Not Found</ErrorTitle>
                    <p>Sorry we couldn&apos;t find your car..</p>
                </div>
            </ErrorCard>
        </FadeInDown>
    </>
);

export default NotFoundRoute;
