// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import { pick } from 'lodash/fp';
import React, { ReactNode } from 'react';
import { yupExt } from '../../../../utilities/forms';
import { ApplicationData } from '../../../routes/ApplicationRoute';
import AddressDetails, { schema as addressSchema } from './AddressDetails';
import CustomerDetails, { schema as customerDetailsSchema, detailsSchema } from './CustomerDetails';
import DrivingLicenseDetails, { schema as drivingLicenseSchema } from './DrivingLicenseDetails';
import OccupationDetails, { schema as occupationSchema } from './OccupationDetails';

export type CustomerStepProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
    name: string;
    action?: ReactNode;
    application?: ApplicationData;
};

const CustomerStep = ({ allowPrimaryInfoChanges, disabled, action, name, application }: CustomerStepProps) => {
    return (
        <Portlet action={action} name={name} closable open>
            <div className="container-fluid">
                <CustomerDetails
                    allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                    application={application}
                    disabled={disabled}
                />
                <AddressDetails />
                <DrivingLicenseDetails disabled={disabled} />
                <OccupationDetails />
            </div>
        </Portlet>
    );
};

export const schema = (t: TFunction) => ({
    ...customerDetailsSchema(t),
    details: yupExt.customerProperty().shape({
        ...detailsSchema(t),
        ...addressSchema(t),
        ...drivingLicenseSchema(t),
        ...occupationSchema(t),
    }),
});

export const customerPrimaryInfoSchema = (t: TFunction) => ({
    ...pick(['email', 'emailBis', 'phoneBis'], customerDetailsSchema(t)),
    details: yupExt.customerProperty().shape(pick(['maritalStatus'], detailsSchema(t))),
});

export const onlyCustomerPrimaryInfoSchema = (t: TFunction) => ({
    ...pick(['email', 'emailBis', 'phoneBis'], customerDetailsSchema(t)),
});

export default CustomerStep;
