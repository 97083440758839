import { getApplication } from '../components/routes/ApplicationRoute/data.graphql';
import { getInsuranceApplication } from '../components/routes/InsuranceRoute/data.graphql';
import { ApplicationStatus } from '../schema';
import * as application from './application.graphql';
import * as customer from './customer.graphql';
import * as lead from './lead.graphql';

export const updateApplication = async (client, applicationId, applicationData, locale) => {
    const variables = { id: applicationId, data: applicationData, locale };
    const { data } = await client.mutate({
        mutation: application.updateApplication,
        variables,
    });

    return data.context;
};

export const updateApplicationStatus = async (client, id, status) => {
    let mutation = null;
    switch (status) {
        case ApplicationStatus.CANCELLED:
            mutation = application.cancelApplication;
            break;
        case ApplicationStatus.COMPLETED:
            mutation = application.completeApplication;
            break;
        default:
            mutation = null;
    }
    const { data } = await client.mutate({
        mutation,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });

    return data.success;
};

export const retryApplication = async (client, applicationId) => {
    const { data } = await client.mutate({
        mutation: application.retryBankSubmission,
        variables: { applicationId },
    });

    return data.success;
};

export const fetchRemoteFlowContext = async (client, queryInfo) => {
    const { data } = await client.query({
        query: application.getProceedWithCustomerDeviceContext,
        variables: { ...queryInfo },
        fetchPolicy: 'no-cache',
    });

    return data.ProceedWithCustomerDeviceContext;
};

export const fetchRemoteToken = async (client, id, passphrase) => {
    const { data } = await client.query({
        query: application.getProceedWithCustomerDeviceToken,
        variables: {
            id,
            passphrase,
        },
        fetchPolicy: 'no-cache',
    });

    return data.token;
};

export const fetchRemoteApplication = async (client, token) => {
    const { data } = await client.query({
        query: application.getApplicationByToken,
        variables: { token },
        fetchPolicy: 'no-cache',
    });

    return data.application;
};

export const saveOccupation = async (client, customerId, customerData, token) => {
    const variables = { id: customerId, data: customerData, token };
    const { data } = await client.mutate({ mutation: application.updateCustomer, variables });

    return data.customer;
};

export const shareCalculators = async (client, variables) => {
    const { data } = await client.mutate({
        mutation: application.shareFinanceContexts,
        variables,
        fetchPolicy: 'no-cache',
    });

    return data.success;
};

export const saveCustomer = async (client, variables, isUpdated) => {
    const { data } = await client.mutate({
        mutation: isUpdated ? customer.updateCustomer : customer.createCustomer,
        variables,
        fetchPolicy: 'no-cache',
    });

    return data.customer;
};

export const fetchCustomer = async (client, variables) => {
    const { data } = await client.query({ query: customer.searchCustomer, variables, fetchPolicy: 'network-only' });

    return data.customers;
};

export const fetchLeads = async (client, zoneId) => {
    const variables = {
        zoneId,
    };
    const { data } = await client.query({
        query: lead.getLeads,
        variables,
        fetchPolicy: 'network-only',
    });

    return data.results;
};

export const fetchApplications = async (client, zoneId) => {
    const variables = {
        zoneId,
    };
    const { data } = await client.query({
        query: application.getApplications,
        variables,
        fetchPolicy: 'network-only',
    });

    return data.results;
};

export const fetchApplication = async (client, applicationVersionId) => {
    const { data } = await client.query({
        query: getApplication,
        variables: { id: applicationVersionId },
        fetchPolicy: 'network-only',
    });

    return data.application;
};

export const fetchInsuranceApplication = async (client, applicationVersionId) => {
    const { data } = await client.query({
        query: getInsuranceApplication,
        variables: { id: applicationVersionId },
        fetchPolicy: 'network-only',
    });

    return data.insuranceApplication;
};

export const fetchProceedApplicationToken = async (client, entry) => {
    const { data } = await client.query({
        query: application.getProceedApplicationToken,
        variables: { entry },
        fetchPolicy: 'network-only',
    });

    return data.token;
};

export const fetchShareCalculator = async (client, id) => {
    const { data } = await client.query({
        query: application.getFinanceContextByLeadId,
        variables: { id },
        fetchPolicy: 'network-only',
    });

    return data.shared;
};

export const fetchEnvelopeStatus = async (client, applicationId, isInsurance, source) => {
    const { data } = await client.query({
        query: application.getSigningStatusByApplicationVersionId,
        variables: { versionId: applicationId, isInsurance, source },
        fetchPolicy: 'network-only',
    });

    return data.signingStatus;
};

export const fetchGuaranteedBuybackStatus = async (client, applicationId, source, signer) => {
    const { data } = await client.query({
        query: application.getGuaranteedBuybackStatusByApplicationVersionId,
        variables: { versionId: applicationId, source, signer },
        fetchPolicy: 'network-only',
    });

    return data.result;
};

export const generateOTP = async (client, variables) => {
    const { data } = await client.mutate({
        mutation: application.generateOTP,
        variables,
    });

    return data.result;
};
