import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Document } from '@appvantageasia/afc-ui';
import cs from 'classnames';
import React, { useState, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, getFormValues, ReduxFormContext } from 'redux-form';
import { remove, RemoveMutation, RemoveMutationVariables } from '../../../../api/attachment.graphql';
import { UploadPurpose } from '../../../../schema';
import { InsuranceApplicationDataFragment } from '../../InsuranceRoute/data.graphql';

import { ApplicationFormValues } from '../ApplicationForm';
import { ApplicationData } from '../index';
import DocumentPreview, { DocumentPreviewProps } from './DocumentPreview';
import DocumentUpload from './DocumentUpload';

const { DocumentContainer, TabContainer, Tabs, TabItem, DocumentBg, DocumentGrid } = Document;

export type Category = {
    label: string;
    id: number;
    source: (string | UploadPurpose)[];
    purpose?: UploadPurpose;
};

export type DocumentComponentProps = {
    application: ApplicationData | InsuranceApplicationDataFragment;
    disabled?: boolean;
};

const DocumentComponent = ({ application, disabled }: DocumentComponentProps) => {
    // get form values
    const { t } = useTranslation();
    const { form } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state) as ApplicationFormValues, [form]);
    const { attachments: files } = useSelector(valueSelector);
    const client = useApolloClient();

    const categories: Category[] = useMemo(() => {
        return [
            {
                label: t('applicationDetailsPage.tab.agreement'),
                id: 1,
                source: ['AGREEMENT', UploadPurpose.GUARANTEED_BUYBACK],
            },
            {
                label: t('applicationDetailsPage.tab.identity'),
                id: 3,
                source: [
                    UploadPurpose.NRIC_FRONT,
                    UploadPurpose.NRIC_BACK,
                    UploadPurpose.CUSTOMER_IDENTITY,
                    UploadPurpose.NRIC_NAMED_CARD,
                    UploadPurpose.GUARANTOR_IDENTITY,
                ],
                purpose: UploadPurpose.CUSTOMER_IDENTITY,
            },
            {
                label: t('applicationDetailsPage.tab.attachment'),
                id: 2,
                source: [
                    UploadPurpose.CUSTOMER_ATTACHED,
                    UploadPurpose.VEHICLE_LOG_CARD,
                    UploadPurpose.GUARANTOR_ATTACHED,
                    UploadPurpose.VSO_UPLOAD,
                ],
                purpose: UploadPurpose.CUSTOMER_ATTACHED,
            },
        ];
    }, [t]);

    const [activeCategory, setActiveCategory] = useState(categories[0]);

    const selectCategory = useCallback(category => setActiveCategory(category), [setActiveCategory]);

    const renderCategoryMenu = useCallback(
        (category: Category) => {
            const { id, source, label } = category;
            const counter = files.filter(item => source.find(file => file === item.purpose)).length;

            return (
                <TabItem
                    key={id}
                    className={cs('tab-item', { active: category === activeCategory })}
                    onClick={() => selectCategory(category)}
                >
                    {label}
                    {counter > 0 && <span>({counter})</span>}
                </TabItem>
            );
        },
        [activeCategory, files, selectCategory]
    );

    const activeDocuments = useMemo(
        () => files.filter(item => activeCategory.source.find(category => category === item.purpose)),
        [activeCategory.source, files]
    );

    const removeAttachmentMutation = useCallback<DocumentPreviewProps['removeMutation']>(
        async ({ referenceId, attachmentId }) => {
            await client.mutate<RemoveMutation, RemoveMutationVariables>({
                mutation: remove,
                variables: {
                    applicationId: referenceId,
                    attachmentId,
                },
            });
        },
        [client]
    );

    const renderDocuments = useCallback(
        document =>
            document && (
                <DocumentPreview
                    key={document.id}
                    document={document}
                    files={application.attachments}
                    name="attachments"
                    referenceId={application.id}
                    removeMutation={removeAttachmentMutation}
                    showRemove={activeCategory?.id !== 1}
                />
            ),
        [activeCategory, application, removeAttachmentMutation]
    );

    return (
        <DocumentContainer>
            <TabContainer>
                <Tabs>{categories.map(renderCategoryMenu)}</Tabs>
                <DocumentBg>
                    <DocumentGrid>
                        {activeDocuments.map(renderDocuments)}
                        {activeCategory.id !== 1 && (
                            <Field
                                activeCategory={activeCategory}
                                applicationId={application.id}
                                component={DocumentUpload}
                                disabled={disabled}
                                name="attachments"
                            />
                        )}
                    </DocumentGrid>
                </DocumentBg>
            </TabContainer>
        </DocumentContainer>
    );
};

export default DocumentComponent;
