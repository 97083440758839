import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import IconButton from '../IconButton';

const Container = styled.form`
    width: 200px;
    height: 30px;
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;

    ${({ theme }) => {
        switch (theme.version) {
            case 'v2': {
                const isLarge = theme.display === 'large';
                const padding = isLarge ? 40 : 20;
                const widthPadding = padding * 2;

                return css`
                    @media (max-width: 768px) {
                        position: absolute;
                        top: 53px;
                        background: #fff;
                        width: calc(100% - ${widthPadding}px);
                        right: ${padding}px;
                    }

                    @media (max-width: 450px) {
                        position: absolute;
                        top: 53px;
                        background: #fff;
                        width: calc(100% - ${widthPadding}px);
                        right: ${padding}px;
                    }
                `;
            }

            case 'v1':
            default:
                return css`
                    @media (max-width: 768px) {
                        position: absolute;
                        top: 53px;
                        background: #fff;
                        width: calc(100% - 60px);
                        right: 30px;
                    }

                    @media (max-width: 450px) {
                        width: calc(100% - 30px);
                        right: 15px;
                    }
                `;
        }
    }}

    & > ${IconButton} {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            color: #999;
            font-size: 1.28rem;
        }
    }
`;

const Input = styled.input`
    color: #000;
    flex-grow: 2;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 1rem;
    min-width: 0;
`;

type SearchInputProps = {
    onSubmit: () => void;
    inputRef: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
    onInputChange: () => void;
    value: string;
    toggle: () => void;
};

const SearchInput = ({ onSubmit, inputRef, onInputChange, value, toggle }: SearchInputProps) => (
    <Container onSubmit={onSubmit}>
        <IconButton icon={faSearch} type="submit" />
        <Input ref={inputRef} onChange={onInputChange} placeholder="Search..." type="text" value={value} autoFocus />
        <IconButton icon={faTimes} onClick={toggle} />
    </Container>
);

SearchInput.propTypes = {
    inputRef: PropTypes.shape({}).isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default SearchInput;
