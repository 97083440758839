import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect, createRef } from 'react';
import { Field } from 'redux-form';
import { useCountry } from '../../../hookSelectors';
import { Container, InputBox, Input } from '../../ui/form/CodeInput';

const patternNumber = /^[0-9]/;
const patternLetter = /^[a-zA-Z]/;

const CodeInput = ({ input, length }) => {
    const { onChange: setCode } = input;
    const [codeEle, setCodeEle] = useState([]);
    const [codes, setCodes] = useState([]);
    const { code: countryCode } = useCountry();

    useEffect(() => {
        setCodeEle(elRefs =>
            Array(length)
                .fill()
                .map((_, i) => elRefs[i] || createRef())
        );

        setCodes(state =>
            Array(length)
                .fill()
                .map((_, i) => state[i] || '')
        );
    }, [setCodeEle, setCodes, length]);

    const onChange = useCallback(
        (event, index) => {
            let value = event.nativeEvent.data;

            if (value) {
                if (countryCode === 'SG') {
                    if (index === length - 1 && !patternLetter.test(value)) {
                        value = '';

                        return;
                    }

                    if (index < length - 1 && !patternNumber.test(value)) {
                        value = '';

                        return;
                    }
                }
            }

            if (value) {
                // if value is valid, should move to next input
                const newState = codes.map((_, i) => (i === index ? value.toUpperCase() : _));
                setCodes(newState);

                codeEle[index].current.blur();

                if (index < length - 1) {
                    codeEle[index + 1].current.focus();
                }

                setCode(newState.join(''));
            } else {
                const newState = codes.map((_, i) => (i === index ? '' : _));
                setCodes(newState);
                setCode(newState.join(''));
            }
        },
        [setCodes, codeEle, setCode, codes, length]
    );

    const onKeyUpPress = useCallback(
        (event, index) => {
            const value = event.nativeEvent.data;
            if (!value && event.key === 'Backspace') {
                if (index > 0) {
                    codeEle[index - 1].current.focus();
                }
            }
        },
        [codeEle]
    );

    return (
        <Container>
            {codeEle.map((item, index) => (
                <InputBox key={index.toString()}>
                    <Input
                        ref={item}
                        onChange={event => onChange(event, index)}
                        onKeyUp={event => onKeyUpPress(event, index)}
                        type={countryCode !== 'SG' || index >= 3 ? 'text' : 'number'}
                        value={codes[index]}
                    />
                </InputBox>
            ))}
        </Container>
    );
};

CodeInput.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    length: PropTypes.number.isRequired,
};

const CodeField = props => <Field component={CodeInput} {...props} />;

export default CodeField;
