import AdyenCheckout from '@adyen/adyen-web';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../../actions';
import { getConfig, getPaymentMethods } from '../../../../../api/adyen';
import { Channel } from '../../../../../schema';

export type PaymentMethods = any;

export type AydenConfig = {
    environment: string;
    clientKey: string;
    originKey: string;
    amount: { value: number; currency: string };
    merchantAccountCountryCode: string;
};

export const formatLocale = (locale: string) => {
    const [languageCode, countryCode] = locale.split('-');

    if (!countryCode) {
        return [languageCode, languageCode.toUpperCase()].join('-');
    }

    return locale;
};

const useAdyen = (
    channel: Channel,
    zoneId: any,
    token: string,
    config: {
        locale?: string;
        onSubmit?: any;
        onAdditionalDetails?: any;
        applePayTotalPriceLabel: string;
        onChange?: any;
        translations: { [key: string]: { [key: string]: string } };
    },
    showPayButton: boolean = true
) => {
    const [checkout, setCheckout] = useState<AdyenCheckout>();
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const [adyenConfig, setAdyenConfig] = useState<AydenConfig | null>(null);
    const [paymentMethodsResponse, setPaymentMethodsResponse] = useState<PaymentMethods | null>(null);

    const fetchConfig = useCallback(async () => {
        const result = await dispatch<Promise<AydenConfig>>(attachLoading(getConfig(channel, zoneId, token)));
        setAdyenConfig(result);
    }, [channel, token, zoneId, dispatch]);

    const fetchPaymentMethods = useCallback(async () => {
        const result = await dispatch<Promise<PaymentMethods>>(
            attachLoading(getPaymentMethods(channel, zoneId, token))
        );
        setPaymentMethodsResponse(result);
    }, [channel, token, zoneId, dispatch]);

    useEffect(() => {
        // fetch Ayden configuration
        fetchConfig();
        // get payment methods
        fetchPaymentMethods();
    }, [fetchConfig, fetchPaymentMethods]);

    useEffect(() => {
        if (!adyenConfig || !paymentMethodsResponse) {
            return;
        }

        const {
            amount: { currency, value },
            merchantAccountCountryCode,
        } = adyenConfig!;

        const { locale, applePayTotalPriceLabel, onSubmit, onAdditionalDetails, onChange, translations } = config;

        // config should have
        // onSubmit and onAdditionalDetails
        const checkoutConfig = {
            clientKey: adyenConfig.clientKey,
            environment: adyenConfig.environment,
            originKey: adyenConfig.originKey,
            paymentMethodsResponse,
            locale,
            onSubmit,
            onChange,
            onAdditionalDetails,
            paymentMethodsConfiguration: {
                card: {
                    showPayButton,
                    hasHolderName: true,
                    holderNameRequired: true,
                    amount: { value, currency },
                },
                applepay: {
                    countryCode: merchantAccountCountryCode,
                    amount: { value, currency },
                    totalPriceLabel: applePayTotalPriceLabel,
                },
            },
            translations,
        };

        if (!checkout) {
            setCheckout(new AdyenCheckout(checkoutConfig));
        } else {
            checkout.update(checkoutConfig);
        }
    }, [adyenConfig, paymentMethodsResponse, config, showPayButton, checkout]);

    return checkout;
};

export default useAdyen;
