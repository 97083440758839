import { createMessenger, Unsubscribable, v1 } from '@userlike/messenger';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChatButton } from '../../ui/chat';
import { ReactComponent as ChatIcon } from '../../../assets/images/chat.svg';

type UserlikeProps = {
    widgetKey: string;
};

const createUserlikeAPI = async (widgetKey: string): Promise<v1.Api | null> => {
    const result = await createMessenger({
        version: 1,
        widgetKey,
    });

    if (result.kind === 'error') {
        console.error(result.error);

        return null;
    }

    const { api } = result.value;

    if (api === null) {
        console.error('api reached end-of-life, please check documentation and upgrade.');
    }

    return api;
};

export const useUserlike = (widgetKey: string) => {
    const [messenger, setMessenger] = useState<v1.Api>();
    const [state, setState] = useState<v1.State>();

    useEffect(() => {
        let subscription: Unsubscribable;

        if (!messenger && widgetKey) {
            // instantiate userlike
            createUserlikeAPI(widgetKey).then(messenger => {
                if (messenger) {
                    setMessenger(messenger);

                    messenger.mount().then(() =>
                        messenger.setVisibility({
                            main: true,
                            button: false,
                            notifications: false,
                        })
                    );

                    subscription = messenger?.state$.subscribe(value => setState(value));
                }
            });
        }

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }

            if (messenger) {
                messenger.unmount();
            }
        };
    }, [messenger, widgetKey]);

    return useMemo(() => ({ messenger, state }), [messenger, state]);
};

const Userlike = ({ widgetKey }: UserlikeProps) => {
    const { messenger, state } = useUserlike(widgetKey);

    const openChat = useCallback(() => {
        if (messenger) {
            messenger.maximize();
        }
    }, [messenger]);

    // do not show the chatbox
    if (!messenger || state?.state === v1.MessengerState.Maximized) {
        return null;
    }

    return (
        <ChatButton onClick={openChat}>
            <ChatIcon />
        </ChatButton>
    );
};

export default Userlike;
