import { faTimesCircle, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Label = styled.label`
    position: relative;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 0;
    color: white;
    background: #f0f0f0;
    min-height: 130px;
    cursor: pointer;
`;

const Preview = styled.img`
    height: auto;
    max-height: 145px;
    max-width: 100%;
    object-fit: contain;
`;

const Close = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    svg {
        color: #d8d8d8;
        font-size: 1.43rem;
    }
`;

const Placeholder = styled.div`
    svg {
        font-size: 3.21rem;
        color: #aaa;
    }
`;

const Tip = styled.div`
    padding-top: 5px;
    color: #9c9c9c;
    font-size: 0.85rem;
`;

const Input = styled.input`
    display: none;
`;

const PreviewFileInput = ({ value, onChange: change, disabled, tip = 'Size 1000x500px in jpg or png', ...props }) => {
    const previousFileRef = useRef(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const remove = useCallback(
        event => {
            event.preventDefault();
            change(null);
            setPreviewUrl(null);

            return false;
        },
        [change, setPreviewUrl]
    );

    const onChange = useCallback(
        event => {
            const file = event.target.files[0];
            if (file instanceof File) {
                const objectURL = URL.createObjectURL(file);

                change(file);
                setPreviewUrl(objectURL);
            }
        },
        [change, setPreviewUrl]
    );

    useEffect(() => {
        if (previousFileRef.current === value) {
            return;
        }

        const url = get('url', value);

        if (value instanceof File) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setPreviewUrl(reader.result), false);
            reader.readAsDataURL(value);
        } else if (url) {
            setPreviewUrl(url);
        }
    }, [value]);

    return (
        <Container>
            <Label disabled={disabled}>
                {previewUrl ? (
                    <Preview src={previewUrl} />
                ) : (
                    <Placeholder>
                        <Icon icon={faImage} size="lg" />
                    </Placeholder>
                )}
                <Input disabled={disabled} onChange={onChange} type="file" {...props} />
                {previewUrl && !disabled && (
                    <Close>
                        <Icon icon={faTimesCircle} onClick={remove} size="lg" />
                    </Close>
                )}
            </Label>
            <Tip>{tip}</Tip>
        </Container>
    );
};

PreviewFileInput.displayName = 'PreviewFileInput';

PreviewFileInput.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    tip: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.shape({
            filename: PropTypes.string,
        }),
        PropTypes.instanceOf(File),
    ]).isRequired,
};

export default PreviewFileInput;
