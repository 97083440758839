import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { useCountry } from '../../../hookSelectors';
import * as selectors from '../../../selectors';
import { getMinDateOfBirth } from '../../../utilities/dates';
import { fromContextValidation, onTelKeyPress, validateNirc, yupExt } from '../../../utilities/forms';
import useCustomerSource from '../../utilities/useCustomerSource';
import useFormValues from '../../utilities/useFormValues';
import usePhone from '../../utilities/usePhone';
import DateField from '../form/DateField';
import NationalityField from '../form/NationalityField';
import TextField from '../form/TextField';

export const useDataMask = () => {
    const { mask } = useSelector(selectors.getCurrentCountry);

    return useMemo(() => mask && mask.count, [mask]);
};

const customerFields = [
    'name',
    'firstName',
    'lastName',
    'email',
    'phone',
    'dateOfBirth',
    'details.nationality',
    'identityNumber',
    'details.maritalStatus',
    'details.employment.occupation',
    'details.gender',
];

const CustomerInformationForm = ({ disabled = false, children = null, onNormalizeNames: { onNormalizeFullName } }) => {
    const { code: phoneCountryCode, minDigits: minPhoneDigit, maxDigits: maxPhoneDigit } = usePhone();
    const { t } = useTranslation();
    const values = useFormValues();
    const { code } = useCountry();
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(selectors.getRuntimeSettings);

    const beingMask = useDataMask();
    const { applicationId: newFlowApplicationId } = useSelector(selectors.getNewApplicationStep('meta')) ?? {};

    const withFocusClear = newFlowApplicationId && beingMask;

    const { sectionPrefix } = useContext(ReduxFormContext);
    const fieldState = useCustomerSource(customerFields, sectionPrefix);

    const appliedForInsurance = get('application.appliedForInsurance', values);

    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);

    return (
        <>
            {children}
            <TextField.FullWidth
                disabled={disabled || fieldState.name}
                label={t('customerDetails.label.name')}
                name="name.value"
                normalize={onNormalizeFullName}
                withFocusClear={withFocusClear}
            />
            <TextField.FullWidth
                disabled={disabled || fieldState.email}
                label={t('customerDetails.label.email')}
                name="email.value"
                type="email"
                withFocusClear={withFocusClear}
            />
            <TextField
                disabled={disabled || fieldState.phone}
                label={t('customerDetails.label.mobile')}
                maxLength={maxPhoneDigit}
                minLength={minPhoneDigit}
                name="phone.value"
                onKeyPress={onTelKeyPress}
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                type="tel"
            />
            {code !== 'NZ' && (
                <TextField
                    disabled={disabled || fieldState.identityNumber}
                    label={t('customerDetails.label.identityNumber')}
                    name="identityNumber.value"
                />
            )}

            {(code === 'NZ' || appliedForInsurance || useCustomerBirthDay) && (
                <DateField
                    disabled={appliedForInsurance}
                    label={t('customerDetails.label.dateOfBirth')}
                    maxDate={code === 'NZ' ? minDateOfBirth : new Date()}
                    name="dateOfBirth.value"
                />
            )}
            {useCustomerNationality && (
                <NationalityField
                    disabled={disabled || fieldState.details.nationality}
                    label={t('customerDetails.label.nationality')}
                    name="details.nationality.value"
                />
            )}
        </>
    );
};

CustomerInformationForm.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onNormalizeNames: PropTypes.shape({
        onNormalizeFirstName: PropTypes.func,
        onNormalizeFullName: PropTypes.func,
        onNormalizeLastName: PropTypes.func,
    }),
};

export default CustomerInformationForm;

export const baseSchema = t =>
    yup.object().shape({
        email: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')).email(t('common.error.email')),
        }),
        name: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),
    });

export const pmeSchema = t =>
    yup.object().shape({
        customer: baseSchema(t).shape({
            dateOfBirth: yupExt.customerProperty().shape({
                value: yup.string().nullable().notRequired(),
            }),
            details: yupExt.customerProperty().shape({
                nationality: yup.object().shape({
                    value: yup.string().nullable().notRequired(),
                }),
            }),
            identityNumber: yup.lazy((values, options) => {
                const { context, parent } = options;

                const nationality = get('details.nationality.value', parent) || context.countryCode;
                const identityValidator = validateNirc(nationality);

                const proceedWithCustomerDevice = get('context.values.application.proceedWithCustomerDevice', options);

                if (proceedWithCustomerDevice) {
                    return yupExt.customerProperty().shape({
                        value: yup
                            .string()
                            .required(t('common.error.required'))
                            .test(
                                'invalid-identity',
                                t('common.error.identityNumber'),
                                value => !value || identityValidator(value, parent?.dateOfBirth?.value)
                            ),
                    });
                }

                return yupExt.customerProperty().shape({
                    value: yup.string().nullable().notRequired(),
                });
            }),
            phone: yupExt.customerProperty().shape({
                value: fromContextValidation(
                    yup.string().required(t('common.error.required')),
                    'phonePattern',
                    t('common.error.mobile')
                ),
            }),
        }),
    });

export const schema = t =>
    yup.object().shape({
        customer: baseSchema(t).shape({
            dateOfBirth: yup.lazy((values, options) => {
                const appliedForInsurance = get(['context', 'values', 'application', 'appliedForInsurance'], options);
                const useCustomerBirthDay = get(['context', 'useCustomerBirthDay'], options);
                const countryCode = get(['context', 'countryCode'], options);

                const shouldValidate = countryCode === 'NZ' || (!appliedForInsurance && useCustomerBirthDay);

                if (shouldValidate) {
                    return yupExt.customerProperty().shape({
                        value: yup.string().required(t('common.error.required')),
                    });
                }

                return yup.mixed().notRequired();
            }),
            details: yupExt.customerProperty().shape({
                nationality: yup.lazy((values, options) => {
                    const shouldValidate = get(['context', 'useCustomerNationality'], options);
                    if (shouldValidate) {
                        return yup.object().shape({
                            value: yup.string().required(t('common.error.required')),
                        });
                    }

                    return yup.mixed().notRequired();
                }),
            }),
            identityNumber: yup.lazy((values, options) => {
                const { context, parent } = options;

                const nationality = get('details.nationality.value', parent) || context.countryCode;
                const identityValidator = validateNirc(nationality);

                if (context.countryCode === 'NZ') {
                    return yupExt.customerProperty().shape({
                        value: yup.string().nullable().notRequired(),
                    });
                }

                return yupExt.customerProperty().shape({
                    value: yup
                        .string()
                        .required(t('common.error.required'))
                        .test(
                            'invalid-identity',
                            t('common.error.identityNumber'),
                            value => !value || identityValidator(value, parent?.dateOfBirth?.value)
                        ),
                });
            }),
            phone: yupExt.customerProperty().shape({
                value: fromContextValidation(
                    yup.string().required(t('common.error.required')),
                    'phonePattern',
                    t('common.error.mobile')
                ),
            }),
        }),
    });
