import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getApplication,
    getInsuranceApplication,
    getPollingGuaranteedBuybackStatus,
    getPollingValidEnvelopeStatus,
    setNotification,
} from '../../../actions';
import { getScopeAccess, GetScopeAccessQuery, GetScopeAccessQueryVariables } from '../../../api/scope.graphql';
import {
    ApplicationFlowSource,
    ApplicationPhase,
    Channel,
    GuaranteedBuybackSigner,
    SigningStatus,
} from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import { PRIVATE_ACCESS } from '../../../utilities/constants/access';
import {
    getApplicationsUrl,
    getCompanyLoginUrl,
    getHomeUrl,
    getInsuranceApplicationsUrl,
    getLocationCode,
    parseCompanyPathname,
} from '../../../utilities/urls';

const localKey = 'namirialCallback';

type Response = SigningStatus;

export type NamirialCallbackSession = {
    envelopeId: string;
    applicationId: string;
    applicationVersionId: string;
    applicationPhase?: ApplicationPhase | null;
    source: ApplicationFlowSource;
    response?: Response;
    origin: string;
    channel: Channel;
    companyCode: string;
    countryCode: string;
    zoneCode: string;
    isBankSigning: boolean;
    originApplicationVersionId: string;
    withInsuranceApplication?: boolean;
    isGuaranteedBuybackSessionEnabled?: boolean;
    guaranteedBuybackSigner?: GuaranteedBuybackSigner;
};

export type StartNamirialCallbackSessionParams = Omit<NamirialCallbackSession, 'origin'> & {
    redirectionUrl: string;
};

export const startNamirialSession = (params: StartNamirialCallbackSessionParams) => {
    const data: NamirialCallbackSession = {
        ...params,
        origin: window.location.href,
    };

    localStorage.setItem(localKey, JSON.stringify(data));

    window.location.replace(params.redirectionUrl);
};

export const cleanNamirialSession = () => localStorage.removeItem(localKey);

export const getNotification = (response: Response): string => {
    switch (response) {
        case SigningStatus.COMPLETED:
            return 'notification.applicationSubmitted';

        case SigningStatus.REJECTED:
            return 'notification.signingCancelled';

        case SigningStatus.TIMEOUT:
            return 'notification.signingTimeOut';

        default:
            return '';
    }
};

const NamirialCallback = () => {
    const { t } = useTranslation();
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector(getIsAuthenticated);

    const callback = useCallback(async () => {
        const localState = localStorage.getItem(localKey);
        // do nothing when there is no state
        if (!localState) {
            window.location.replace(window.location.origin);

            return;
        }

        const {
            applicationVersionId,
            applicationPhase,
            companyCode,
            countryCode,
            zoneCode,
            source,
            origin,
            channel,
            isBankSigning,
            originApplicationVersionId,
            isGuaranteedBuybackSessionEnabled,
            guaranteedBuybackSigner,
        } = JSON.parse(localState) as NamirialCallbackSession;

        // extract parameters from the URL query
        const { access, applicationId }: { access?: string; applicationId?: string } = qs.parse(
            window.location.search,
            {
                ignoreQueryPrefix: true,
            }
        );

        if (applicationId !== applicationVersionId) {
            // ids are different
            // stop here
            window.location.replace(window.location.origin);

            return;
        }

        // define if we are on private or public access
        const isPrivateAccess = (access?.toLowerCase() as string) === PRIVATE_ACCESS;

        // check whether authentication is needed
        const isAuthenticationRequired = isPrivateAccess && !isAuthenticated;

        if (isAuthenticationRequired) {
            // need to authenticate first
            history.push(getCompanyLoginUrl(companyCode, getLocationCode(countryCode, zoneCode)), {
                // we want the user to come back here after login
                redirect: window.location.href,
            });
        }

        // get envelope status
        const namirialEnvelopeResponse = ((await dispatch(
            getPollingValidEnvelopeStatus(applicationVersionId, !isBankSigning, source)
        )) as unknown) as Response;

        let response: SigningStatus = namirialEnvelopeResponse;

        // If guaranteed buyback session enabled,
        // Wait for guaranteed buyback status too
        let guaranteedBuybackStatusResponse: { status: SigningStatus; newRedirectionUrl?: string };
        if (isGuaranteedBuybackSessionEnabled && guaranteedBuybackSigner) {
            guaranteedBuybackStatusResponse = ((await dispatch(
                getPollingGuaranteedBuybackStatus(applicationVersionId, source, guaranteedBuybackSigner)
            )) as unknown) as { status: SigningStatus; newRedirectionUrl?: string };

            response = guaranteedBuybackStatusResponse.status;
        }

        // get scope access
        const scopeResponse = await client.query<GetScopeAccessQuery, GetScopeAccessQueryVariables>({
            query: getScopeAccess,
            variables: { companyCode, countryCode, zoneCode },
            fetchPolicy: 'network-only',
        });

        const channelSetting = scopeResponse.data.scope.country?.channelSetting;

        const getPublicAccess = () => {
            switch (channel) {
                case Channel.USED:
                    return channelSetting?.used?.allowPublicAccess;

                case Channel.NEW:
                    return channelSetting?.new?.allowPublicAccess;

                default:
                    return false;
            }
        };

        // prepare data
        const allowPublicAccess = getPublicAccess();
        const locationCode = getLocationCode(countryCode, zoneCode);
        const companyRootUrl = getHomeUrl(channel)(companyCode, locationCode);

        const applicationsUrl = getApplicationsUrl(companyCode, locationCode, {
            phase: applicationPhase,
            isBankSigning,
        });

        const insuranceApplicationsUrl = getInsuranceApplicationsUrl(companyCode, countryCode);

        const { title, message } = (t(getNotification(response), { returnObjects: true }) || {}) as {
            message: string;
            title: string;
        };

        const draftFlow = async () => {
            switch (response) {
                case SigningStatus.COMPLETED: {
                    const refererPathName = parseCompanyPathname(origin, companyCode, locationCode);

                    if (isAuthenticated) {
                        const newLocationState = {
                            applicationVersionId:
                                source === ApplicationFlowSource.INSURANCE
                                    ? applicationVersionId
                                    : originApplicationVersionId,
                            isInsurance: source === ApplicationFlowSource.INSURANCE,
                        };

                        // Detect if guaranteed buyback enabled and is lease purchase
                        // If enabled, continue the journey instead of redirecting back to home page
                        if (isGuaranteedBuybackSessionEnabled && guaranteedBuybackStatusResponse.newRedirectionUrl) {
                            // Continue journey
                            history.push(refererPathName, newLocationState);
                        } else {
                            // move back to home page
                            history.push(companyRootUrl, { ...newLocationState, submitted: true });
                        }
                    } else {
                        // display notification for submitted application
                        dispatch(setNotification(title, message));
                        history.push(companyRootUrl);
                    }

                    break;
                }

                case SigningStatus.REJECTED:
                case SigningStatus.TIMEOUT:
                    // redirect back to home page with sign not completed state
                    history.push(companyRootUrl, {
                        applicationVersionId,
                        signNotCompleted: {
                            title,
                            message,
                        },
                    });
                    break;

                default:
                    break;
            }
        };

        const remoteFlow = async () => {
            // redirect to origin remote route
            const redirectToOrigin = (isCompleted: boolean) => {
                const refererPathName = parseCompanyPathname(origin, companyCode, locationCode);
                history.push(refererPathName, {
                    isApplicationSubmitted: isCompleted,
                    signingRedirectionMessage: {
                        title,
                        message,
                    },
                });
            };

            switch (response) {
                case SigningStatus.COMPLETED:
                    // Detect if guaranteed buyback enabled and is lease purchase
                    // If enabled, continue the journey instead of redirecting back to home page
                    if (isGuaranteedBuybackSessionEnabled && guaranteedBuybackStatusResponse.newRedirectionUrl) {
                        // Continue journey
                        history.push(parseCompanyPathname(origin, companyCode, locationCode), {
                            applicationVersionId: originApplicationVersionId,
                        });
                    } else if (allowPublicAccess) {
                        // redirect to home page with notification
                        dispatch(setNotification(title, message));
                        history.push(companyRootUrl);
                    } else {
                        redirectToOrigin(true);
                    }
                    break;

                case SigningStatus.REJECTED:
                case SigningStatus.TIMEOUT:
                    if (allowPublicAccess) {
                        // redirect to home page with notification
                        dispatch(setNotification(title, message));
                        history.push(companyRootUrl);
                    } else {
                        redirectToOrigin(false);
                    }
                    break;

                default:
                    break;
            }
        };

        const resumeFlow = async () => {
            switch (response) {
                case SigningStatus.COMPLETED: {
                    if (source === ApplicationFlowSource.RESUMEINSURANCE) {
                        const application = await dispatch(getInsuranceApplication(applicationId));
                        history.push(insuranceApplicationsUrl, {
                            submitted: true,
                            application,
                        });
                    } else if (isGuaranteedBuybackSessionEnabled && guaranteedBuybackStatusResponse.newRedirectionUrl) {
                        // Continue journey
                        history.push(parseCompanyPathname(origin, companyCode, locationCode), {
                            applicationVersionId: originApplicationVersionId,
                        });
                    } else {
                        // we need to get application
                        // as applications url expects an application instead of id
                        const application = await dispatch(getApplication(applicationId));
                        history.push(applicationsUrl, {
                            submitted: true,
                            application,
                        });
                    }
                    break;
                }

                case SigningStatus.REJECTED:
                case SigningStatus.TIMEOUT:
                    dispatch(setNotification(title, message));
                    history.push(applicationsUrl);
                    break;

                default:
                    break;
            }
        };

        const eventFlow = async () => {
            const appId =
                source === ApplicationFlowSource.INSURANCE ? applicationVersionId : originApplicationVersionId;

            const refererPathName = parseCompanyPathname(origin, companyCode, locationCode);
            const urlToRedirect = `${refererPathName}?applicationId=${appId}`;

            switch (response) {
                case SigningStatus.COMPLETED: {
                    history.push(urlToRedirect);
                    break;
                }

                case SigningStatus.REJECTED:
                case SigningStatus.TIMEOUT:
                    dispatch(setNotification(title, message));
                    history.push(urlToRedirect);
                    break;

                default:
                    break;
            }
        };

        const defaultFlow = () => {
            // redirect back to home page
            history.push(companyRootUrl);
        };

        switch (source) {
            case ApplicationFlowSource.DRAFT:
            case ApplicationFlowSource.INSURANCE:
                await draftFlow();
                break;

            case ApplicationFlowSource.REMOTE:
            case ApplicationFlowSource.REMOTEINSURANCE:
                remoteFlow();
                break;

            case ApplicationFlowSource.RESUME:
            case ApplicationFlowSource.RESUMEINSURANCE:
                await resumeFlow();
                break;

            case ApplicationFlowSource.EVENT:
                await eventFlow();
                break;

            default:
                defaultFlow();
                break;
        }
    }, [client, dispatch, history, isAuthenticated, t]);

    useEffect(() => {
        callback();

        return cleanNamirialSession;
    }, [callback]);

    return null;
};

export default NamirialCallback;
