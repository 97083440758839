import { useQuery } from '@apollo/client';
import { TFunction } from 'i18next';
import { find, groupBy, isEqual } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { ContentTranslator, useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { getCompanyIdentifier } from '../../../../selectors';
import { applicationChannelOptions } from '../../../../utilities/constants/options';
import { getCustomersUrl, getCustomerUrl } from '../../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../../shared/HelmetTitle';
import { LocationParams } from '../../ApplicationRoute';
import { getData } from './CustomerRoute.graphql';
import Details from './Details';

const getSourceLabel = (
    channel: Channel,
    access: string,
    dealerName: { [language: string]: string },
    t: TFunction,
    ct: ContentTranslator
) => {
    const data = find(option => option.channel === channel && option.access === access, applicationChannelOptions(t))
        ?.label;

    return `${data}: [${ct(dealerName)}]`;
};

const CustomerRoute = () => {
    const { t } = useTranslation();
    const { ct, language } = useContentTranslation();
    const history = useHistory();
    const { id } = useParams<LocationParams>();
    const variables = { id, locale: language || 'en' };
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    const { data, loading } = useQuery(getData, { variables, fetchPolicy: 'no-cache' });
    const customer = data;

    const journeys = useMemo(() => {
        const values = data?.customer;

        if (!values) {
            return {};
        }

        return groupBy(x => getSourceLabel(x.channel, x.access, x.dealer.name, t, ct), values.applications);
    }, [data, t, ct]);

    const selectedDealerId = useMustExistDealerIdFromTenantContext();
    const [currentDealerId] = useState(selectedDealerId);

    useEffect(() => {
        if (!isEqual(currentDealerId, selectedDealerId)) {
            history.push(getCustomersUrl(companyCode, locationCode));
        }
    }, [companyCode, currentDealerId, history, locationCode, selectedDealerId]);

    if (!loading && !customer) {
        const url = getCustomerUrl(companyCode, locationCode);

        return <Redirect to={url} />;
    }

    if (loading) {
        // wait for the loading to be over
        return null;
    }

    return (
        <>
            <HelmetTitle title="Customer Details" />
            <Details initialValues={customer} journeys={journeys} />
        </>
    );
};

export default CustomerRoute;
