import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { ConfigFormValues } from './ConfigForm';
import OptionBoxField from './shared/OptionBoxField';
import { SectionTitle } from './shared/ui';
import useExteriorImage from './shared/useExteriorImage';
import noImg from '../../../../assets/images/no-image.png';

export const SelectionContainer = styled.div`
    display: flex;
    flex-direction: column;

    /* Desktop and Tablet Screen */
    @media (min-width: ${breakpoints.xl}) {
        img {
            width: 60vw;
            height: 88vh;
        }
        flex-direction: row;
        .configurator {
            display: flex !important;
            flex-direction: column !important;
            justify-content: center;
            width: 40vw;
            height: 600px;
        }
        .configurator > h5 {
            align-self: center;
        }
    }
`;

export const ImageContainer = styled.div`
    width: 100vw;
    height: 35vh;
    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        height: 60vh;
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        img,
        video {
            width: 65vw;
            height: 90vh;
        }
    }
`;

export const OptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
    span {
        font-size: 16px;
    }

    /* Desktop and Table Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 30px 20px;
    }
`;

const Exterior = () => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const exteriorOptions = ckdConfiguration.exterior.options;

    // get form values
    const values = useFormValues<ConfigFormValues>();
    const exteriorImage = useExteriorImage(values?.exteriorColor, values?.interiorColor);

    return (
        <SelectionContainer>
            {isMobile && (
                <ImageContainer>
                    <ImgWithFallback alt={ckdConfiguration.exterior.headline} fallbackSrc={noImg} src={exteriorImage} />
                </ImageContainer>
            )}
            <div className="configurator">
                <OptionContainer>
                    <SectionTitle>{ckdConfiguration.exterior.headline}</SectionTitle>
                    <div>
                        <OptionBoxField fieldName="exteriorColor" name="exteriorColor" options={exteriorOptions} />
                    </div>
                    <span>
                        {exteriorOptions.find((item: { name: string }) => item.name === values.exteriorColor)?.label}
                    </span>
                </OptionContainer>
            </div>
        </SelectionContainer>
    );
};

export default Exterior;
