import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { omit, flow, set } from 'lodash/fp';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../actions';
import {
    ApplicationCustomerDataFragment,
    CustomerDetailsFragment,
} from '../../../../components/routes/ApplicationRoute/data.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { getMyInfoData, getMyInfoSession } from '../../../../components/routes/wip/MyInfoCallback';
import { ApplicationFlowSource, MyInfoExcludableSection } from '../../../../schema';
import { rename } from '../../../../utilities/fp';
import { MyInfoProps } from '../DraftPage/Form/MyInfo';

const buildAddress = (address: CustomerDetailsFragment['residentialAddress']) => {
    // there is no address to manipulate
    if (!address) {
        return null;
    }

    const street = [[address.block, address.street].filter(Boolean).join(' '), address.building]
        .filter(Boolean)
        .join(',');

    return {
        ...omit(['building', 'block'], address),
        street,
    };
};

const useMyInfoInitialization = (
    event: EventDataFragment,
    onSubmit: (values: any) => Promise<void>,
    source: ApplicationFlowSource
) => {
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

    useEffect(() => {
        const infoSession = getMyInfoSession('', source);
        const state = infoSession?.state as Omit<MyInfoProps, 'myInfo'>;

        if (!state) {
            throw new Error('state is missing in session');
        }

        const { hasTestDrive, hasTradeIn, calculator, appliedForFinancing } = state;
        const exclude = [
            !hasTestDrive && MyInfoExcludableSection.DRIVERLICENSE,
            !hasTradeIn && MyInfoExcludableSection.VEHICLE,
        ].filter(Boolean);

        // @ts-ignore
        const myInfoPromise = getMyInfoData(
            client,
            '',
            source,
            { eventId: event.id, bankId: appliedForFinancing ? calculator?.bank : undefined },
            {} as ApplicationCustomerDataFragment,
            exclude as MyInfoExcludableSection[]
        );

        if (myInfoPromise) {
            const promise = myInfoPromise.then(({ error, customer }) =>
                onSubmit({
                    customer: {
                        ...flow([
                            rename({ email: 'emailBis', phone: 'phoneBis' }),
                            value =>
                                set(
                                    'details.residentialAddress',
                                    buildAddress(value.details?.residentialAddress),
                                    value
                                ),
                        ])(customer),
                    },
                    hasError: error,
                    ...state,
                })
            );
            dispatch(attachLoading(promise));
        }
    }, [client, dispatch, onSubmit, source, event.id]);
};

export type MyInfoRouteProps = {
    event: EventDataFragment;
    onSubmit: (values: any) => Promise<void>;
    source: ApplicationFlowSource;
};

const MyInfoRoute = ({ event, onSubmit, source }: MyInfoRouteProps) => {
    // handle my info state
    useMyInfoInitialization(event, onSubmit, source);

    return null;
};

export default MyInfoRoute;
