import React from 'react';
import { RemoteFlow } from '../../../../schema';
import Shield, { ShieldProps } from '../RemoteRoute/Shield';
import GuarantorValidation from './GuarantorValidation';

export type GuarantorShieldProps = Pick<ShieldProps, 'applicationId' | 'bankName' | 'handlerComponent'>;

const GuarantorShield = (props: GuarantorShieldProps) => (
    <Shield
        {...props}
        flow={RemoteFlow.GUARANTOR}
        // ignore ts validation on this prop for now as it's too strict
        // @ts-ignore
        validationComponent={GuarantorValidation}
    />
);

export default GuarantorShield;
