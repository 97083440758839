import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { isNil, isArray, isEmpty } from 'lodash/fp';
import { useMemo } from 'react';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { useCountry } from '../../../../hookSelectors';

export const isInvalidCalculatorValues = (values: Partial<CalculatorValues>) =>
    isNil(values.coe) || (isArray(values.monthlyInstalments) && !values.monthlyInstalments[0]);

export type SubmitButtonStates = { disabled: boolean; hidden: boolean };

const useApplyDisabled = (
    values?: Partial<CalculatorValues>,
    fallbackFinanceProduct?: FinanceDataFragment | null,
    variant?: VariantDataFragment | null,
    availableFinanceProducts?: FinanceDataFragment[] | null,
    errors?: CalculatorContext<CalculatorValues>['errors']
): SubmitButtonStates => {
    const { channelSetting } = useCountry();
    const { isDepositPaymentMandatory, allowFinanceApplication, isFinanceApplicationMandatory } = channelSetting.new;

    const allowFallbackProduct =
        (isFinanceApplicationMandatory || allowFinanceApplication) && isDepositPaymentMandatory;

    return useMemo((): SubmitButtonStates => {
        if (!isEmpty(errors) || !values || isInvalidCalculatorValues(values)) {
            return { disabled: true, hidden: false };
        }

        // if there is finance product, we allow apply
        if (availableFinanceProducts?.length || !fallbackFinanceProduct) {
            return { disabled: false, hidden: false };
        }

        const isFallbackActive = !fallbackFinanceProduct.variants.some(
            assignedVariant => assignedVariant === variant?.version?.id
        );

        if (!isFallbackActive) {
            return { disabled: false, hidden: false };
        }

        const isInvalid = !allowFallbackProduct;

        return { disabled: isInvalid, hidden: isInvalid };
    }, [values, availableFinanceProducts, fallbackFinanceProduct, errors, allowFallbackProduct, variant]);
};

export default useApplyDisabled;
