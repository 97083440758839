import React from 'react';
import { animated, useSpring } from 'react-spring';

export const spring = {
    from: { opacity: 0, transform: 'translate3d(0, -100%, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
};

type FadeInDownProps = {
    children: React.ReactNode;
};

const FadeInDown = ({ children }: FadeInDownProps) => {
    const animation = useSpring(spring);

    return <animated.div style={animation}>{children}</animated.div>;
};

export default FadeInDown;
