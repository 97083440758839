import { get, getOr } from 'lodash/fp';
import { Channel, NewChannel, UsedChannel, ExpressChannel, EventChannel, Country } from '../schema';

export const getLabelFromChannel = (channel: Channel) => {
    switch (channel) {
        case Channel.NEW:
            return 'New';
        case Channel.USED:
            return 'Preowned';
        case Channel.EXPRESS:
            return 'Express';
        case Channel.EVENT:
            return 'Event';
        default:
            return null;
    }
};

export const getChannelSetting = (country: Country, channel: Channel) => {
    switch (channel) {
        case Channel.EXPRESS:
            return country.channelSetting.express;

        case Channel.USED:
            return country.channelSetting.used;

        case Channel.NEW:
            return country.channelSetting.new;

        case Channel.EVENT:
            return country.channelSetting.event;

        default:
            throw new Error('Not implemented');
    }
};

export const getAccess = (access: string) => (setting: NewChannel | UsedChannel | ExpressChannel | EventChannel) =>
    get('isActive', setting) && getOr(true, access, setting);

// defaults to true for express/event for private access
export const getPrivateAccess = getAccess('allowPrivateAccess');

// default to false for express/event for public access
export const getPublicAccess = getAccess('allowPublicAccess');
