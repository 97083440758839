import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadDialingCodes } from '../../../actions';
import { getDialingCodes } from '../../../selectors';
import SelectField from './SelectField';

const formatOptionLabel = (item, { context }) => {
    if (context === 'menu') {
        return `${item.label} (${item.value})`;
    }

    return item?.value;
};

const DialingCodeField = props => {
    const dispatch = useDispatch();
    const nationalities = useSelector(getDialingCodes);

    useEffect(() => {
        dispatch(loadDialingCodes());
    }, [dispatch]);

    return (
        <SelectField.Pure formatOptionLabel={formatOptionLabel} options={nationalities} {...props} clearable={false} />
    );
};

export default DialingCodeField;
