import React, { useEffect, useMemo } from 'react';
import '@adyen/adyen-web/dist/adyen.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useZone } from '../../../../hookSelectors';
import { Channel } from '../../../../schema';
import useAdyen, { formatLocale } from './hooks/useAdyen';

export const AdyenContainer = styled.div<{ disabled?: boolean }>`
    .adyen-checkout__payment-method {
        /* Payment method container */
        transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s ease-out;
        margin: 8px 0;
        border-radius: 12px;
        cursor: default;
        background: #ebebeb;
        border: 1px solid #e6e9eb;
        margin-top: 25px;
    }
`;
type AdyenPaymentProps = {
    onAdditionalDetails: any;
    onSubmit: any;
    applePayTotalPriceLabel: string;
    channel: Channel;
    token: string;
    disabled: boolean;
    setSubmitDisabled: (value: boolean) => void;
    setDropin: (value: any) => void;
};

const AdyenPayment = ({
    onAdditionalDetails,
    onSubmit,
    applePayTotalPriceLabel,
    channel,
    token,
    setSubmitDisabled,
    setDropin,
    disabled,
}: AdyenPaymentProps) => {
    const { id: zoneId } = useZone();
    const { t, i18n } = useTranslation();
    const { translations, locale } = useMemo(() => {
        const formattedLocale = formatLocale(i18n.languages[0]);

        const translations: { [key: string]: { [key: string]: string } } = {
            [formattedLocale]: t('adyen', { returnObjects: true, ignoreJSONStructure: false }),
        };

        return {
            translations,
            locale: formattedLocale,
        };
    }, [i18n.languages, t]);

    const configuration = useMemo(
        () => ({
            locale,
            onSubmit,
            onAdditionalDetails,
            applePayTotalPriceLabel,
            onChange: (state: any) => (state.isValid ? setSubmitDisabled(false) : setSubmitDisabled(true)),
            translations,
        }),
        [locale, onSubmit, onAdditionalDetails, applePayTotalPriceLabel, translations, setSubmitDisabled]
    );

    const checkout = useAdyen(channel, zoneId, token, configuration, false);

    useEffect(() => {
        if (checkout) {
            // @ts-ignore
            setDropin(checkout.create('dropin').mount('#dropin-container'));
        }
    }, [checkout, setDropin]);

    return <AdyenContainer disabled={disabled} id="dropin-container" />;
};

export default AdyenPayment;
