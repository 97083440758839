import { useQuery } from '@apollo/client';
import { InsuranceValues } from '@appvantageasia/afc-calculator-ui-next';
import { differenceInYears } from 'date-fns';
import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { Channel } from '../../schema';
import {
    getInsurancePremiumAmount,
    GetInsurancePremiumAmountQuery,
    GetInsurancePremiumAmountQueryVariables,
} from '../data/useLoadInsurancePremium.graphql';
import useLoadVariants from '../data/useLoadVariants';
import { FinderVehicleDataFragment } from '../routes/FinderRoute/data.graphql';
import useInsuranceCompany from './useInsuranceCompany';

export type SupportedVehicleData = {
    finderVehicle?: FinderVehicleDataFragment | null;
};

export const isInsuranceValidDateOfBirth = (dateOfBirth?: string) => {
    if (!dateOfBirth) {
        return false;
    }

    const nowYear = new Date();
    const dateOfBirthDate = new Date(dateOfBirth);

    const age = differenceInYears(nowYear, dateOfBirthDate);
    if (age < 24) {
        return false;
    }

    return true;
};

const useInsurancePremium = (
    channel: Channel,
    dealerId: string,
    values: Partial<InsuranceValues> | null,
    support?: SupportedVehicleData
) => {
    const insuranceCompany = useInsuranceCompany();
    const { variants = [] } = useLoadVariants('cache-and-network', channel, dealerId);

    const finderVehicle = support?.finderVehicle ?? null;

    const variantValues = values?.variant;

    // Ability to handle when channel event is selected and finderVehicle is there
    const selectedVariant = useMemo(() => {
        if (!isNil(finderVehicle)) {
            const variantIdentifier = finderVehicle?.listing?.vehicle?.orderTypeCode || '';

            // For porsche finder, just pas the orderTypeCode_name
            // regardless it is new or else condition
            return {
                name: variantIdentifier,
            };
        }

        if (variantValues) {
            const variant = variants.find(variant => variant.id === variantValues);

            const variantIdentifier = variant?.identifier || '';

            return {
                name: variantIdentifier,
            };
        }

        return null;
    }, [finderVehicle, variantValues, variants]);

    const premiumPayload = useMemo<GetInsurancePremiumAmountQueryVariables | {}>(() => {
        if (
            isNil(values) ||
            isNil(values?.dateOfBirth) ||
            isNil(values?.yearsOfDriving) ||
            isNil(insuranceCompany) ||
            isNil(selectedVariant) ||
            !isInsuranceValidDateOfBirth(values?.dateOfBirth)
        ) {
            return {};
        }

        return {
            insuranceCompanyId: insuranceCompany?.id || '',
            data: {
                modelName: selectedVariant.name,
                vehiclePrice: values?.carPrice || 0,
                dateOfBirth: values?.dateOfBirth || new Date(),
                originalRegistrationDate: values?.dateOfRegistration || new Date(),
                drivingExperience: values?.yearsOfDriving?.value || 0,
                ncd: values?.noClaimDiscount?.value || 0,
            },
        };
    }, [selectedVariant, values, insuranceCompany]);

    const { data, loading } = useQuery<GetInsurancePremiumAmountQuery, GetInsurancePremiumAmountQueryVariables>(
        getInsurancePremiumAmount,
        {
            skip:
                isNil(values) ||
                isNil(values?.dateOfBirth) ||
                isNil(values?.yearsOfDriving) ||
                isNil(insuranceCompany) ||
                isNil(selectedVariant) ||
                !isInsuranceValidDateOfBirth(values?.dateOfBirth),
            variables: premiumPayload as GetInsurancePremiumAmountQueryVariables,
        }
    );

    return useMemo(
        () => ({
            loading,
            insurancePremiumAmount: data?.insurancePremiumAmount || 0,
        }),
        [data, loading]
    );
};

export default useInsurancePremium;
