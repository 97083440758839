/* eslint-disable react/prop-types */
import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PromoCodeCalculator from '../../../../../components/shared/calculator-next/PromoCodeCalculator';
import { CalculatorMeta, CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import { Channel } from '../../../../../schema';
import useDealerEstablishment from '../../../../utils/useDealerEstablishment';
import RemoteExpectedCashPaymentField from '../RemoteExpectedCashPaymentField';
import RemoteExpectedTradeInAmountField from '../RemoteExpectedTradeInAmountField';
import RemotePromoCodeField from '../RemotePromoCodeField';
import { DisclaimerEventItem, DisclaimerWrapper } from './Disclaimer';
import { CheckboxWrapper, PriceItalic, SectionItem, SectionItemContent, SpaceBetween } from './styles';
import usePrevious from './usePrevious';

export type SectionFinanceProps = {
    hasFinancingCheckbox?: boolean;
    hasCalculator?: boolean;
    isInvalidCalculatorSetup?: boolean;
    formattedMonthlyPayment?: string;
    hasTradeInInput?: boolean;
    promoContainerId: string;
    tradeInContainerNode: HTMLElement | null;
    dealerId: string;
    initialCalculatorValues: Partial<CalculatorValues>;
    meta: Partial<CalculatorMeta>;
    onCalculatorChanged: (context: CalculatorContext<CalculatorValues>) => void;
    appliedForFinancing?: boolean;
    financeDisclaimers?: string[] | null;
    selectedBankEstablishment?: number | null;
    showMonthlyPayment: boolean;
};

const SectionFinance = ({
    hasFinancingCheckbox = false,
    hasCalculator = false,
    formattedMonthlyPayment,
    isInvalidCalculatorSetup = false,
    hasTradeInInput = false,
    promoContainerId,
    tradeInContainerNode,
    dealerId,
    initialCalculatorValues,
    meta,
    onCalculatorChanged,
    appliedForFinancing,
    financeDisclaimers,
    selectedBankEstablishment = 0,
    showMonthlyPayment,
}: SectionFinanceProps) => {
    const { t } = useTranslation();

    const previousShowTradeInAmountInput = usePrevious(hasTradeInInput);
    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.EVENT);

    return (
        <SectionItem>
            <SpaceBetween>
                {hasFinancingCheckbox && (
                    <CheckboxWrapper>
                        <CheckboxField
                            channel={Channel.EVENT}
                            label={t('eventCalculatorPage.checkbox.applyForFinancing')}
                            name="appliedForFinancing"
                        />
                    </CheckboxWrapper>
                )}
                {!isInvalidCalculatorSetup && showMonthlyPayment && (
                    <div style={{ marginLeft: 'auto' }}>
                        <PriceItalic>
                            <span>
                                <Trans
                                    components={{ s: <span /> }}
                                    i18nKey="eventCalculatorPage.label.monthlyPayment"
                                    values={{ monthlyPayment: formattedMonthlyPayment }}
                                />
                            </span>
                        </PriceItalic>
                    </div>
                )}
            </SpaceBetween>
            <SectionItemContent $isOpened={hasCalculator}>
                {!isInvalidCalculatorSetup && (
                    <PromoCodeCalculator
                        channel={Channel.EVENT}
                        dealerId={dealerId}
                        initialValues={initialCalculatorValues}
                        meta={meta}
                        onChange={onCalculatorChanged}
                    >
                        <displayFields.CarModelPriceField
                            fieldKey="carModelAndPrice"
                            isViewable={() => false}
                            size={2}
                            override
                        />
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => false}
                            labelTKey="calculator.label.carModel"
                            override
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                        <displayFields.TotalInterestPayableField
                            fieldKey="totalInterestPayable"
                            isViewable={context =>
                                hasCalculator && displayFields.isTotalInterestPayableViewable(context)
                            }
                            labelTKey="calculator.label.totalInterestPayable"
                            override
                        />
                        <displayFields.MonthlyInstalmentField
                            fieldKey="monthlyInstalment"
                            isViewable={context => hasCalculator && displayFields.isMonthlyInstalmentViewable(context)}
                            labelTKey="calculator.label.monthlyInstalment"
                            override
                        />
                        <displayFields.MultipleMonthlyInstalmentsField
                            fieldKey="monthlyInstalments"
                            isViewable={context =>
                                hasCalculator && displayFields.isMultipleMonthlyInstalmentsViewable(context)
                            }
                            labelTKey="calculator.label.monthlyInstalment"
                            override
                        />
                        <displayFields.TotalPriceField
                            fieldKey="totalPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.totalPrice"
                            override
                        />
                        <displayFields.BankField
                            fieldKey="bank"
                            isViewable={context => hasCalculator && displayFields.isBankViewable(context)}
                            labelTKey="calculator.label.bank"
                            override
                        />
                        <displayFields.DownPaymentField
                            fieldKey="downPayment"
                            isViewable={context => hasCalculator && displayFields.isDownPaymentViewable(context)}
                            labelTKey="calculator.label.downPayment"
                            override
                        />
                        <displayFields.PaymentTermField
                            fieldKey="tenure"
                            isViewable={context => hasCalculator && displayFields.isPaymentTermViewable(context)}
                            labelTKey="calculator.label.paymentTerm"
                            override
                        />
                        <displayFields.LoanField
                            fieldKey="loan"
                            isViewable={context => hasCalculator && displayFields.isLoanViewable(context)}
                            labelTKey="calculator.label.loanAmount"
                            override
                        />
                        <displayFields.MarginOfFinanceField
                            fieldKey="loan.percentage"
                            isViewable={context => hasCalculator && displayFields.isMarginOfFinanceViewable(context)}
                            labelTKey="calculator.label.marginOfFinance"
                        />
                        <displayFields.HPAmountField
                            fieldKey="loan.amount"
                            isViewable={context => hasCalculator && displayFields.isHPAmountViewable(context)}
                            labelTKey="calculator.label.hpAmount"
                        />
                        <displayFields.InterestRateField
                            fieldKey="interestRate"
                            isViewable={context => hasCalculator && displayFields.isInterestRateViewable(context)}
                            labelTKey="calculator.label.interestRate"
                            override
                        />
                        <displayFields.FinanceProductField
                            fieldKey="financeProduct"
                            isViewable={() => hasCalculator}
                            labelTKey="calculator.label.financeProduct"
                            override
                        />
                        <displayFields.BalloonPaymentField
                            fieldKey="balloon"
                            isViewable={context => hasCalculator && displayFields.isBalloonPaymentViewable(context)}
                            labelTKey="calculator.label.balloonPayment"
                            override
                        />
                        <displayFields.DepositField
                            fieldKey="deposit"
                            isViewable={context => hasCalculator && displayFields.isDepositViewable(context)}
                            labelTKey="calculator.label.deposit"
                            override
                        />
                        <displayFields.PaymentModeField
                            fieldKey="paymentMode"
                            isViewable={context => hasCalculator && displayFields.isPaymentModeViewable(context)}
                            labelTKey="calculator.label.paymentMode"
                            override
                        />
                        <displayFields.COEField
                            fieldKey="coe"
                            isViewable={context => false}
                            labelTKey="calculator.label.coe"
                            override
                        />
                        <displayFields.MileageField
                            fieldKey="mileage"
                            isViewable={context => hasCalculator && displayFields.isMileageViewable(context)}
                            labelTKey="calculator.label.mileage"
                            override
                        />
                        <displayFields.ResidualValueField
                            fieldKey="residualValue"
                            isViewable={context => hasCalculator && displayFields.isResidualValueViewable(context)}
                            labelTKey="calculator.label.residualValueField"
                            override
                        />
                        <displayFields.AssuredResaleValueField
                            fieldKey="residualValue.amount"
                            isViewable={context => hasCalculator && displayFields.isAssuredResaleValueViewable(context)}
                            labelTKey="calculator.label.assuredResaleValueField"
                            override
                        />
                        <displayFields.EstimatedSurplusField
                            fieldKey="estimatedSurplus"
                            isViewable={context => hasCalculator && displayFields.isEstimatedSurplusViewable(context)}
                            labelTKey="calculator.label.estimatedSurplus"
                            override
                        />
                        <displayFields.TradeInEquityField
                            fieldKey="tradeInEquity"
                            isViewable={context => hasCalculator && displayFields.isTradeInViewable(context)}
                            labelTKey="calculator.label.tradeInEquity"
                            override
                        />
                        <displayFields.CashPaymentField
                            fieldKey="cashPayment"
                            isViewable={context => hasCalculator && displayFields.isCashPaymentViewable(context)}
                            labelTKey="calculator.label.cashPayment"
                            override
                        />
                        <RemoteExpectedTradeInAmountField
                            container={tradeInContainerNode}
                            fieldKey="expectedTradeInAmount"
                            initialShowEdition={!previousShowTradeInAmountInput}
                            isViewable={context =>
                                hasTradeInInput && displayFields.isExpectedTradeInAmountViewable(context)
                            }
                            labelTKey="calculator.label.expectedTradeInAmount"
                        />
                        <RemoteExpectedCashPaymentField
                            container={tradeInContainerNode}
                            fieldKey="expectedCashPayment"
                            isViewable={context =>
                                hasTradeInInput && displayFields.isExpectedCashPaymentViewable(context)
                            }
                            labelTKey="calculator.label.expectedCashPayment"
                        />
                        <RemotePromoCodeField
                            containerId={promoContainerId}
                            fieldKey="promoCode"
                            isViewable={displayFields.isPromoCodeViewable}
                            labelTKey="calculator.label.promoCode"
                            size={2}
                        />
                    </PromoCodeCalculator>
                )}
                {appliedForFinancing && !!financeDisclaimers?.length && (
                    <DisclaimerWrapper>
                        <DisclaimerEventItem
                            priceDisclaimers={financeDisclaimers}
                            selectedBankEstablishment={selectedBankEstablishment}
                            selectedDealerEstablishment={selectedDealerEstablishment}
                        />
                    </DisclaimerWrapper>
                )}
            </SectionItemContent>
        </SectionItem>
    );
};
export default SectionFinance;
