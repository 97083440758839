import { useApolloClient } from '@apollo/client';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import React, { useMemo, useState, useCallback, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import {
    startChatSession,
    SendChatMessageMutation,
    SendChatMessageMutationVariables,
    StartChatSessionMutation,
    StartChatSessionMutationVariables,
    sendChatMessage,
} from '../../../api/chat.graphql';
import { useZone } from '../../../hookSelectors';
import { Channel } from '../../../schema';
import { handleResponseError } from '../../../utilities/forms';
import { EventDataFragment } from '../../routes/EventRoute/EventRoute.graphql';
import IconButton from '../../ui/IconButton';
import {
    ChatAvatar,
    ChatAvatarContainer,
    ChatButton,
    ChatContentContainer,
    ChatContainer,
    ChatHeader,
    ChatHistory,
    ChatInput,
    ChatInputContainer,
    ChatMessage,
    ChatMessageContainer,
    ChatTitle,
    ChatUser,
    ChatReply,
    ChatReplyContainer,
    ChatTyping,
} from '../../ui/chat';
import { ReactComponent as ChatIcon } from '../../../assets/images/chat.svg';
import { ReactComponent as Times } from '../../../assets/images/times.svg';

type Message = {
    id: string;
    isAlt: boolean;
    url: string;
    user: 'Bot' | 'Me';
    message: string;
};

export type ChatProps = { channel: Channel; event?: EventDataFragment };

const Dialogflow = ({ channel, event }: ChatProps) => {
    const client = useApolloClient();
    const [showChat, setShowChat] = useState(false);
    const [inputValue, setInput] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [chatHistory, setChatHistory] = useState<Message[]>([]);
    const [replies, setReplies] = useState<string[]>([]);
    const [isTyping, setTyping] = useState(false);
    const { id: zoneId } = useZone();

    const addMessage = useCallback(
        (isBot: boolean, message: string) => {
            setChatHistory(history => [
                ...history,
                {
                    id: `${new Date().getTime()}-${isBot ? 'bot' : 'self'}`,
                    isAlt: !isBot,
                    url: '',
                    user: isBot ? 'Bot' : 'Me',
                    message,
                },
            ]);
        },
        [setChatHistory]
    );

    const initializeChat = useCallback(async () => {
        setTyping(true);

        try {
            const { data } = await client.mutate<StartChatSessionMutation, StartChatSessionMutationVariables>({
                mutation: startChatSession,
                variables: { zoneId, channel, eventId: event?.id },
            });

            const result = data?.result;

            if (!result) {
                throw new Error('failed to initialized the chat');
            }

            setTyping(false);
            setSessionId(result.sessionId);
            addMessage(true, result.reply.text);
            setReplies(result.reply.quickReplies);

            return result;
        } catch (error) {
            setTyping(false);

            return handleResponseError;
        }
    }, [addMessage, zoneId, setTyping, setSessionId, setReplies, event, client, channel]);

    useEffect(() => {
        if (chatHistory.length === 0 && showChat) {
            initializeChat();
        }
    }, [chatHistory, showChat, initializeChat]);

    const onGetDialogFlowMessage = useCallback(
        async message => {
            setTyping(true);

            try {
                // add the message
                addMessage(false, message);

                const { data } = await client.mutate<SendChatMessageMutation, SendChatMessageMutationVariables>({
                    mutation: sendChatMessage,
                    variables: { zoneId, sessionId, message, channel, eventId: event?.id },
                });

                const result = data?.result;

                if (!result) {
                    throw new Error('failed to send the reply');
                }

                setTyping(false);
                addMessage(true, result.text);
                setReplies(result.quickReplies);

                return result;
            } catch (error) {
                setTyping(false);

                return handleResponseError;
            }
        },
        [client, sessionId, addMessage, zoneId, event, setTyping, setReplies, channel]
    );

    const scrollChatToBottom = useCallback(() => {
        if (chatHistory.length > 1) {
            const lastMessageId = chatHistory[chatHistory.length - 1].id;
            document.getElementById(lastMessageId)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    useEffect(() => {
        if (chatHistory.length && showChat) {
            scrollChatToBottom();
        }
    }, [chatHistory, scrollChatToBottom, showChat]);

    const { onClose, onToggle, onChange, onSendMessage, onEnter, onReply, onFocus } = useMemo(
        () => ({
            // close the chat
            onClose: () => setShowChat(false),
            // toggle the chart
            onToggle: () => setShowChat(state => !state),
            // listen on input changes
            onChange: (event: ChangeEvent<HTMLInputElement>) => setInput(event.target.value),
            // send a new message
            onSendMessage: () => {
                if (inputValue) {
                    // send the message
                    onGetDialogFlowMessage(inputValue);
                    // reset the inputs & replies
                    setInput('');
                    setReplies([]);
                }
            },
            // on keyboard events
            onEnter: (event: KeyboardEvent<HTMLInputElement>) => {
                if (event.which === 13) {
                    onSendMessage();
                }
            },
            // onr reply selection
            onReply: (reply: string) => {
                onGetDialogFlowMessage(reply);
            },
            // on focus events
            onFocus: () => {
                scrollChatToBottom();
            },
        }),
        [inputValue, onGetDialogFlowMessage, setReplies, setInput, setShowChat, scrollChatToBottom]
    );

    const chatMessages = useMemo(
        () =>
            chatHistory.map(chatMessage => (
                <ChatMessageContainer key={chatMessage.id} id={chatMessage.id} isAlt={chatMessage.isAlt}>
                    <ChatAvatarContainer isAlt={chatMessage.isAlt}>
                        {chatMessage.url ? (
                            <ChatAvatar alt="Chat Avatar" src={chatMessage.url} />
                        ) : (
                            chatMessage.user.charAt(0)
                        )}
                    </ChatAvatarContainer>
                    <ChatContentContainer isAlt={chatMessage.isAlt}>
                        <ChatUser>{chatMessage.user}</ChatUser>
                        <ChatMessage>{chatMessage.message}</ChatMessage>
                    </ChatContentContainer>
                </ChatMessageContainer>
            )),
        [chatHistory]
    );

    const chatReplies = useMemo(
        () =>
            replies.map((reply, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ChatReply key={index} onClick={() => onReply(reply)}>
                    {reply}
                </ChatReply>
            )),
        [onReply, replies]
    );

    return showChat ? (
        <ChatContainer>
            <ChatHeader>
                <Times fill="#fff" onClick={onClose} />
                <ChatTitle>CHAT</ChatTitle>
            </ChatHeader>
            <ChatHistory>{chatMessages}</ChatHistory>
            <ChatInputContainer>
                <ChatInput
                    disabled={!chatHistory.length}
                    onChange={onChange}
                    onFocus={onFocus}
                    onKeyPress={onEnter}
                    placeholder="Send a message"
                    type="text"
                    value={inputValue}
                />
                <IconButton icon={faPaperPlane} onClick={onSendMessage} size="lg" />
            </ChatInputContainer>
            <ChatReplyContainer>{chatReplies}</ChatReplyContainer>
            {isTyping && <ChatTyping>typing. . .</ChatTyping>}
        </ChatContainer>
    ) : (
        <ChatButton onClick={onToggle}>
            <ChatIcon />
        </ChatButton>
    );
};

export default Dialogflow;
