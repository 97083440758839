import styled from 'styled-components';
import Card from './Card';

const ErrorCard = styled(Card)`
    display: flex;
    margin: 50px;

    h1 {
        color: #161616;
        margin-bottom: 20px;

        svg {
            color: #ee5757;
        }
    }

    & > *:first-child {
        margin-right: 30px;
        align-self: end;
        opacity: 0.5;
    }

    & > *:last-child {
        flex-grow: 2;

        & > *:last-child {
            margin-top: 20px;
            text-align: right;
        }
    }

    @media (max-width: 450px) {
        margin: 15px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;

        & > *:first-child {
            margin: 0 auto;
            margin-bottom: 20px;
            opacity: 0.5;
        }

        & > *:last-child {
            text-align: center;

            & > *:last-child {
                text-align: center;
            }
        }
    }
`;

export default ErrorCard;
