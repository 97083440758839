import { get, sortBy, find, isNil } from 'lodash/fp';
import { BalloonBase, BalloonMode, InterestRateMode, Unit } from '../../../../schema';
import { FinanceDataFragment } from '../../../data/useFinanceProducts.graphql';

export type CompoundValue = {
    amount: number;
    percentage: number;
};

export type TableValues = {
    term?: number;
    value?: number;
    downPayment?: number;
    mileage?: number;
    variantId?: string;
};

export const getValueFromTable = (
    table: TableValues[],
    conditions: ((item: TableValues) => boolean)[],
    sortKey: string,
    valueKey = 'value',
    defaultValue = 0
): number => {
    // filter first
    const filteredTables = conditions.reduce((acc, condition) => acc.filter(condition), table);
    // then sort
    const sortedTable = sortBy([sortKey], filteredTables);
    // and get values
    const values = sortedTable.map(get(valueKey));

    // get the first non-null values, otherwise get the default value
    return find(Boolean, values) || defaultValue;
};

export const getDefaultValueByMinAndMax = (value: number, min: number, max: number) => {
    if (value <= min) {
        return min;
    }
    if (value >= max) {
        return max;
    }

    return value;
};

export const getAccurate = (value: number) => (100 * 1000 - value * 1000) / 1000;

export const getCompoundValues = (baseValue: number, value: number, unit: Unit) => {
    if (baseValue === undefined || value === undefined) {
        // we do not have enough for it
        return undefined;
    }

    const normalizedValue =
        unit === Unit.PERCENTAGE
            ? getDefaultValueByMinAndMax(value, 0, 100)
            : getDefaultValueByMinAndMax(value, 0, baseValue);

    switch (unit) {
        case Unit.PERCENTAGE:
            return {
                amount: (baseValue * normalizedValue) / 100,
                percentage: normalizedValue,
            };

        case Unit.CURRENCY:
            return {
                amount: normalizedValue,
                percentage: (normalizedValue * 100) / baseValue,
            };

        default:
            // meaning we didn't retrieve what we needed
            return undefined;
    }
};

export const getInterestRate = (
    financeProduct: FinanceDataFragment,
    paymentTerm: number | undefined,
    downPayment: CompoundValue | undefined
) => {
    const config = financeProduct.interestRateSetting;
    const { mode, default: defaultValue } = config;

    switch (mode) {
        case InterestRateMode.FLAT:
        case InterestRateMode.RANGE:
            return defaultValue;

        case InterestRateMode.TABLE: {
            const { table } = config;

            if (paymentTerm === undefined || downPayment === undefined) {
                // we are still missing data to compute it
                return undefined;
            }

            if (!table) {
                return 0;
            }

            // look into the table
            // if there not enough data we can safely return 0 instead
            return getValueFromTable(
                table,
                [
                    item => item.downPayment === downPayment.percentage,
                    item => (isNil(item) || isNil(item?.term) ? false : item?.term >= paymentTerm),
                ],
                'term'
            );
        }

        default:
            // unknown base
            return 0;
    }
};

export const getLoan = (
    totalPrice: number | undefined,
    downPayment: CompoundValue | undefined
): CompoundValue | undefined => {
    if (downPayment === undefined || totalPrice === undefined) {
        return undefined;
    }

    return {
        percentage: getAccurate(downPayment.percentage),
        amount: totalPrice - downPayment.amount,
    };
};

const getBalloonBaseValue = (basedOn: BalloonBase, totalPrice: number, loanAmount: number) => {
    switch (basedOn) {
        case BalloonBase.CARPRICE:
            return totalPrice;

        case BalloonBase.LOAN:
            return loanAmount;

        default:
            return 0;
    }
};

const getBalloonCompoundValue = (financeProduct: FinanceDataFragment, base: number, variantId: string) => {
    const {
        balloonSetting,
        termSetting: { default: paymentTerm },
    } = financeProduct;

    if (isNil(balloonSetting)) {
        return undefined;
    }

    const { default: defaultValue, defaultUnit, mode, table } = balloonSetting;

    switch (mode) {
        case BalloonMode.RANGE:
            return getCompoundValues(base, defaultValue, defaultUnit);

        case BalloonMode.TABLE: {
            if (!table || variantId === undefined) {
                return undefined;
            }

            const value = getValueFromTable(
                table,
                [
                    item => item?.variantId === variantId,
                    item => (isNil(item) || isNil(item?.term) ? false : item?.term >= paymentTerm),
                ],
                'term'
            );

            return getCompoundValues(base, value, defaultUnit);
        }
        default:
            return null;
    }
};

export const getBalloon = (
    financeProduct: FinanceDataFragment,
    values: { totalPrice: number; loan: CompoundValue | undefined; paymentTerm: number; variantId: string }
) => {
    const config = financeProduct?.balloonSetting;
    const { totalPrice, loan, paymentTerm, variantId } = values;

    if (config === undefined || config === null) {
        return undefined;
    }

    const { basedOn, mode } = config;

    if (mode === undefined || totalPrice === undefined || paymentTerm === undefined || loan === undefined) {
        // we are still missing data to compute it
        return undefined;
    }

    const baseValue = getBalloonBaseValue(basedOn, totalPrice, loan.amount);
    const compoundValue = getBalloonCompoundValue(financeProduct, baseValue, variantId);

    if (compoundValue) {
        const { amount } = compoundValue;

        // round the initial amount down
        return { ...compoundValue, amount };
    }

    return compoundValue;
};
