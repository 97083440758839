// @ts-ignore
import { DarkButton } from '@appvantageasia/afc-ui';
import styled from 'styled-components';

const OcrButton = styled(DarkButton)`
    background-color: #bfbfbf;
    grid-column: 1/ 3;

    font-size: 1.4rem;
    line-height: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 45px;

    svg {
        height: 23px;
        margin-right: 10px;
    }

    span {
        position: relative;
        top: 2px;
    }
`;

export default OcrButton;
