import { map } from 'lodash/fp';
import * as api from '../api';
import { getFinanceProductById } from '../components/data/useFinanceProducts';
import { getPromoFromIdentifier } from '../components/data/useLoadPromo';
import { getCurrentCountry } from '../selectors';
import { getCalculatorPayload } from '../utilities/calculator';
import { prepareForGraphQL } from '../utilities/forms';
import { attachLoading } from './loading';
import { applyUsedVariant } from './newApplicationv2';
import { uploadFilesFromOccupation } from './upload';

export const getApplication = id => async (dispatch, getState, { client }) =>
    dispatch(attachLoading(api.fetchApplication(client, id)));

export const getInsuranceApplication = id => async (dispatch, getState, { client }) =>
    dispatch(attachLoading(api.fetchInsuranceApplication(client, id)));

// eslint-disable-next-line import/prefer-default-export
export const submitApplicationChanges = (values, initialValues) => async (dispatch, getState, { client }) => {
    const state = getState();

    const { calculator, customer, meta, proceedWithCustomerDevice, dealerId, addGuarantor } = values;

    // get the zone
    const country = getCurrentCountry(state);
    const { channelSetting, id: countryId } = country;
    const { allowOptions } = channelSetting.new;

    // first upload the files
    await dispatch(
        uploadFilesFromOccupation(
            values.customer.files,
            initialValues.customer.files ?? [],
            initialValues.meta.applicationId
        )
    );

    // get selected finance product
    const financeProduct = await getFinanceProductById(client, calculator.financeProduct);
    // get the bank from it
    const { termSetting } = financeProduct;
    const { interestOnly } = termSetting;

    // promo code may change
    let promoCodeId;
    if (calculator.promoCode) {
        // get promo code information
        const promo = await getPromoFromIdentifier(client, countryId, calculator.promoCode, [dealerId]);
        if (promo) {
            promoCodeId = promo.id;
        }
    }

    // used car may need generate new variant id
    const { expressVariant } = meta;

    let newVariant;

    if (expressVariant) {
        newVariant = await dispatch(applyUsedVariant(expressVariant?.id, expressVariant?.name));
    }

    // build application payload
    const applicationPayload = prepareForGraphQL({
        // the remark
        remark: customer.remark,
        // set proceedWithCustomerDevice policy
        proceedWithCustomerDevice,
        // Add a Guarantor
        addGuarantor,
        // build options
        optionIds: allowOptions ? map(option => option.id, calculator.carOptions) : [],
        // finance product id and variant id comes from the calculator
        financeProductId: calculator.financeProduct,
        variantId: newVariant ? newVariant.id : calculator.carModel,
        // then calculator payload
        calculator: getCalculatorPayload(calculator, interestOnly),
        promoCodeId,
    });

    // then submit changes
    return dispatch(attachLoading(api.updateApplication(client, meta.applicationId, applicationPayload)));
};
