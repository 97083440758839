import styled from 'styled-components';

const ChatTyping = styled.div`
    position: absolute;
    bottom: 55px;
    left: 50%;
    margin-left: -50px;
    height: 15px;
    line-height: 0;
    text-align: center;
    border-radius: 15px;
    display: inline-block;
    width: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #ddd;
    font-style: italic;
`;

export default ChatTyping;
