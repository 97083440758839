import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import noProfileImg from '../../assets/images/no-profile-image.jpg';
import { getCountryId, getUser } from '../../selectors';
import AvatarContainer from '../ui/AvatarContainer';
import AvatarImage from '../ui/AvatarImage';
import AvatarWrapper from '../ui/AvatarWrapper';
import { TextAlignCenter } from '../ui/TextAlign';

type AvatarProps = {
    onClick: () => void;
};

const Avatar = (props: AvatarProps) => {
    const user = useSelector(getUser);
    const countryId = useSelector(getCountryId) || [];
    const roleName = useMemo(
        () => user?.permissions.filter(permission => permission.countryId === countryId)[0]?.role?.name,
        [countryId, user]
    );

    return (
        <AvatarContainer>
            <AvatarWrapper {...props}>
                <AvatarImage alt="user" fallbackSrc={noProfileImg} src={user?.image?.url || noProfileImg} />
            </AvatarWrapper>
            <TextAlignCenter>{user?.name}</TextAlignCenter>
            <TextAlignCenter>{roleName}</TextAlignCenter>
        </AvatarContainer>
    );
};

export default Avatar;
