import React from 'react';
import InsuranceRemoteFlowBootstrap from './InsuranceRemoteFlowBootstrap';
import InsuranceRemoteFlowHandler from './InsuranceRemoteFlowHandler';

export type RemoteHandlerProps = { token: string; submitted: boolean };

const RemoteHandler = ({ token, submitted }: RemoteHandlerProps) => (
    <InsuranceRemoteFlowBootstrap token={token}>
        {(application, consents) => (
            <InsuranceRemoteFlowHandler
                consents={consents}
                insuranceApplication={application}
                submitted={submitted}
                token={token}
            />
        )}
    </InsuranceRemoteFlowBootstrap>
);

export default RemoteHandler;
