// @ts-ignore:next-line
import { FormTabContainer } from '@appvantageasia/afc-ui';
import React from 'react';
import styled from 'styled-components';
import { useFixedMenuController } from '../../shared/FixedMenuController';

const FixedMenuFormTab = styled(FormTabContainer)<{ fixedMenu: boolean }>`
    position: ${props => (props.fixedMenu ? 'static' : 'relative')};
`;

type FixedMenuFormTabContainerProps = {
    children: JSX.Element | React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
};

const FixedMenuFormTabContainer = ({ children, className, style }: FixedMenuFormTabContainerProps) => {
    const { fixedMenu } = useFixedMenuController();

    return (
        <FixedMenuFormTab className={className} fixedMenu={fixedMenu} style={style}>
            {children}
        </FixedMenuFormTab>
    );
};

export default FixedMenuFormTabContainer;
