import { CalculatorContext, GridCalculator, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import { cloneDeep } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../i18n';
import { Channel } from '../../../schema';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';
import { useCompanyCalculatorTheme } from './CompanyCalculator';
import CarModelPriceField from './baseCalculatorFields/CarModelPriceField';
import CoeField from './baseCalculatorFields/CoeField';
import { CalculatorInsuranceValues, CalculatorValues } from './types';

export type BaseCalculatorProps = {
    channel: Channel;
    financeContext: CalculatorContext<CalculatorValues> | undefined;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
};

const BaseCalculator = ({ channel, financeContext, insuranceContext, ...props }: BaseCalculatorProps) => {
    const { t } = useTranslation();
    const { ct, language } = useContentTranslation();

    // get formatting
    const formats = useCompanyFormatting();
    const theme = useCompanyCalculatorTheme(channel);

    if (!financeContext) {
        return null;
    }

    return (
        <GridCalculator
            context={cloneDeep(financeContext)}
            theme={theme}
            {...formats}
            {...props}
            ct={ct}
            language={language}
            t={t}
        >
            <CarModelPriceField
                fieldKey="carModelAndPrice"
                financeContext={financeContext}
                insuranceContext={insuranceContext}
                size={2}
            />
            <CoeField
                fieldKey="coe"
                financeContext={financeContext}
                insuranceContext={insuranceContext}
                isViewable={displayFields.isCOEViewable}
                labelTKey="calculator.label.coe"
            />

            <displayFields.TotalPriceField
                fieldKey="totalPrice"
                isViewable={displayFields.isTotalPriceViewable}
                labelTKey="calculator.label.totalPrice"
            />
        </GridCalculator>
    );
};

export default BaseCalculator;
