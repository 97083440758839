import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useContentTranslation } from '../../i18n';
import { getCompany } from '../../selectors';
import FooterContainer from '../ui/FooterContainer';

const currentYear = new Date().getFullYear();

const defaultCopyright = 'Appvantage. All Rights Reserved.';

export const useCopyright = () => {
    const { t } = useTranslation();
    const { copyright = { en: defaultCopyright } } = useSelector(getCompany) || {};
    const { ct } = useContentTranslation();

    return t('common.copyright', { currentYear, copyright: ct(copyright), version: process.env.REACT_APP_VERSION });
};

const Footer = () => {
    const copyright = useCopyright();

    return <FooterContainer>{copyright}</FooterContainer>;
};

export default Footer;
