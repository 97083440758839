import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { Channel, Unit } from '../../../../schema';
import { getNewApplicationCalculatorUrl } from '../../../../utilities/urls';
import { FinanceDataFragment } from '../../../data/useFinanceProducts.graphql';
import useFirstFinanceProduct from '../../../data/useFirstFinanceProduct';
import useMustExistDealerIdFromTenantContext from '../../../data/useMustExistDealerIdFromTenantContext';
import Chatbot from '../../../shared/Chatbot';
import Footer from '../../../shared/Footer';
import Header from '../../../shared/Header';
import HelmetTitle from '../../../shared/HelmetTitle';
import PendingModal from '../../../shared/PendingModal';
import { CarList, CarModelFooter } from '../../../ui/car-models';
import useCompanyFormatting from '../../../utilities/useCompanyFormatting';
import Filter from './Filter';
import SubmitSuccessModal from './SubmitSucessModal';
import useMakers from './useMakers';
import useModels from './useModels';
import useMonthlyPayment from './useMonthlyPayment';
import useSubModels from './useSubModels';
import useVariants from './useVariants';

export const useFormatDownPayment = (
    financeProduct: FinanceDataFragment | null,
    formatPercentage: (value: number) => string,
    currencySymbol: string
) => {
    return useMemo(() => {
        if (financeProduct === null) {
            return '';
        }
        const {
            downPaymentSetting: { defaultUnit, default: defaultValue },
        } = financeProduct;

        return defaultUnit === Unit.PERCENTAGE ? formatPercentage(defaultValue) : `${defaultValue} ${currencySymbol}`;
    }, [financeProduct, formatPercentage, currencySymbol]);
};

const Inner = () => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const {
        roundNumber,
        formatCurrency,
        formatCurrencyValues,
        formatPercentage,
        currencySymbol,
    } = useCompanyFormatting();

    const dealerId = useMustExistDealerIdFromTenantContext();

    // get the default finance product
    const financeProduct = useFirstFinanceProduct(Channel.NEW, dealerId);

    const country = useCountry();
    const { allowFinanceApplication, isFinanceApplicationMandatory } = country.channelSetting.new;

    // then get our variants (all of them)
    const { variants, loading } = useVariants(
        financeProduct,
        roundNumber,
        formatCurrency,
        currencySymbol,
        Channel.NEW,
        dealerId
    );

    // list makers
    const makers = useMakers(variants);
    // then models
    const models = useModels(makers.filteredVariants);
    // then sub models
    const subModels = useSubModels(models.filteredVariants, models.current);

    // and finally monthly payments
    const monthlyPayments = useMonthlyPayment(
        subModels.filteredVariants,
        formatCurrencyValues,
        roundNumber,
        currencySymbol
    );

    // history & callback to navigate on click
    const history = useHistory();
    const onClick = useCallback(
        variant => {
            // @ts-ignore
            history.pushWithCompany(getNewApplicationCalculatorUrl, variant.id, 'new');
        },
        [history]
    );

    // finally get the down payment for the current financial product
    const formatedDownPayment = useFormatDownPayment(financeProduct, formatPercentage, currencySymbol);

    const hasMandatoryFinancing = allowFinanceApplication && isFinanceApplicationMandatory;

    const finalVariants = useMemo(() => {
        if (!hasMandatoryFinancing) {
            // we don't need to do anything on this list
            return monthlyPayments.filteredVariants;
        }

        return monthlyPayments.filteredVariants.map(variant => ({
            ...variant,
            // disable if there is no finance product for this variant
            disabled: !financeProduct,
        }));
    }, [monthlyPayments.filteredVariants, hasMandatoryFinancing, financeProduct]);

    return (
        <>
            <HelmetTitle channel={Channel.NEW} title="Chooser" />
            <Header channel={Channel.NEW} />
            <Filter
                channel={Channel.NEW}
                maker={makers.current}
                makers={makers.list}
                model={models.current}
                models={models.list}
                monthlyPayment={monthlyPayments.current}
                monthlyPayments={monthlyPayments.list}
                setMaker={makers.set}
                setModel={models.set}
                setMonthlyPayment={monthlyPayments.set}
                setSubModel={subModels.set}
                subModel={subModels.current}
                subModels={subModels.list}
            />
            {!loading && <CarList cars={finalVariants} onClick={onClick} withMake={makers.list.length > 1} />}
            {financeProduct && (
                <CarModelFooter>
                    {t('chooserPage.disclaimer', {
                        financeProduct: ct(financeProduct.name),
                        downPayment: formatedDownPayment,
                        term: financeProduct.termSetting.default,
                    })}
                </CarModelFooter>
            )}
            <SubmitSuccessModal />
            <PendingModal />
            <Footer />
            <Chatbot channel={Channel.NEW} />
        </>
    );
};

export default Inner;
