import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { ActionsV2 as Actions, Footer } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { submit, InjectedFormProps, reduxForm } from 'redux-form';
import { attachLoading } from '../../../../../actions';
import { AppointmentStatus } from '../../../../../schema';
import { getCompanyIdentifier, getGlobalPermissions } from '../../../../../selectors';
import { handleResponseError } from '../../../../../utilities/forms';
import { getAppointmentsUrl } from '../../../../../utilities/urls';
import useFormValues from '../../../../utilities/useFormValues';
import ModalProvider, { GlobalErrorProvider } from '../../../ApplicationRoute/Document/ModalProvider';
import { TabActivityLog } from '../ActivityLog';
import { TabDetails } from '../Details';
import {
    updateStatusAppointment,
    UpdateStatusAppointmentMutation,
    UpdateStatusAppointmentMutationVariables,
} from '../Details.graphql';
import { TabDocument } from '../Document';
import { AppointmentFormContext, AppointmentFormProps, AppointmentFormValues } from './context';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

const completeOrVoidCheck = [AppointmentStatus.COMPLETED, AppointmentStatus.VOID];
const checkInCheck = [AppointmentStatus.COMPLETED, AppointmentStatus.VOID, AppointmentStatus.CHECKIN];
const contactedCheck = [
    AppointmentStatus.CONTACTED,
    AppointmentStatus.COMPLETED,
    AppointmentStatus.VOID,
    AppointmentStatus.CHECKIN,
];

const AppointmentActions = ({
    isDirty,
    appointmentId,
    currentStatus,
}: {
    isDirty: boolean;
    appointmentId: string;
    currentStatus: AppointmentStatus;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const client = useApolloClient();
    const history = useHistory();

    const { getState } = useStore();

    const { mayManageAppointment } = useSelector(getGlobalPermissions);

    const onSubmitClick = useCallback(() => {
        dispatch(submit('appointment'));
    }, [dispatch]);

    const onChangeStatus = useCallback(
        async (newStatus: AppointmentStatus) => {
            try {
                const promise = client.mutate<
                    UpdateStatusAppointmentMutation,
                    UpdateStatusAppointmentMutationVariables
                >({
                    mutation: updateStatusAppointment,
                    variables: {
                        id: appointmentId,
                        status: newStatus,
                    },
                });

                dispatch(attachLoading(promise));

                // get company and location code
                const { companyCode, locationCode } = getCompanyIdentifier(getState());

                history.push(getAppointmentsUrl(companyCode, locationCode));

                return null;
            } catch (error) {
                return handleResponseError(error as Error);
            }
        },
        [appointmentId, client, dispatch, getState, history]
    );

    if (!mayManageAppointment) {
        return null;
    }

    return (
        <Actions>
            {!contactedCheck.includes(currentStatus) && (
                <Footer.FootBarButton onClick={() => onChangeStatus(AppointmentStatus.CONTACTED)}>
                    {t('appointmentDetailPage.button.contacted')}
                </Footer.FootBarButton>
            )}
            {!checkInCheck.includes(currentStatus) && (
                <Footer.FootBarButton onClick={() => onChangeStatus(AppointmentStatus.CHECKIN)}>
                    {t('appointmentDetailPage.button.checkin')}
                </Footer.FootBarButton>
            )}
            {!completeOrVoidCheck.includes(currentStatus) && (
                <>
                    <Footer.FootBarButton onClick={() => onChangeStatus(AppointmentStatus.VOID)}>
                        {t('appointmentDetailPage.button.void')}
                    </Footer.FootBarButton>
                    <Footer.FootBarButton onClick={() => onChangeStatus(AppointmentStatus.COMPLETED)}>
                        {t('appointmentDetailPage.button.completed')}
                    </Footer.FootBarButton>
                </>
            )}
            <Footer.FootBarButton disabled={!isDirty} onClick={onSubmitClick}>
                {t('appointmentDetailPage.button.submitChanges')}
            </Footer.FootBarButton>
        </Actions>
    );
};

const AppointmentForm = (
    props: AppointmentFormProps & InjectedFormProps<AppointmentFormValues, AppointmentFormProps>
) => {
    const { t } = useTranslation();

    const history = useHistory();

    const onCancel = useCallback(() => history.goBack(), [history]);

    const { dirty } = props;

    const values = useFormValues<AppointmentFormValues>();
    const context = { ...props, values };

    return (
        <AppointmentFormContext.Provider value={context}>
            <GlobalErrorProvider>
                <ModalProvider>
                    <Tabs>
                        <TabList>
                            <Tab>{t('appointmentDetailPage.tab.appointment')}</Tab>
                            <Tab>{t('appointmentDetailPage.tab.document')}</Tab>
                            <Tab>{t('appointmentDetailPage.tab.activityLog')}</Tab>
                        </TabList>
                        <TabPanel>
                            <TabDetails />
                        </TabPanel>
                        <TabPanel>
                            <TabDocument />
                        </TabPanel>
                        <TabPanel>
                            <TabActivityLog />
                        </TabPanel>
                    </Tabs>
                </ModalProvider>
            </GlobalErrorProvider>
            <FootContainer className="appActionBtn">
                <FootBar>
                    <FootBarRow>
                        <FootBarButton onClick={onCancel}>{t('appointmentDetailPage.button.cancel')}</FootBarButton>
                        <div style={{ flex: 1 }} />
                        <AppointmentActions appointmentId={values.id} currentStatus={values.status} isDirty={dirty} />
                    </FootBarRow>
                </FootBar>
            </FootContainer>
        </AppointmentFormContext.Provider>
    );
};

export default reduxForm<AppointmentFormValues, AppointmentFormProps>({
    form: 'appointment',
})(AppointmentForm);
