export const CONTEXT_SET_COMPANY_CODE = '@CONTEXT/SET_COMPANY_CODE';

export const CONTEXT_SET_COUNTRY_CODE = '@CONTEXT/SET_COUNTRY_CODE';

export const CONTEXT_SET_ZONE_CODE = '@CONTEXT/SET_ZONE_CODE';

export const CONTEXT_SET_DEALER_ID = '@CONTEXT/SET_DEALER_ID';

export const CONTEXT_SET_COMPANY = '@CONTEXT/SET_COMPANY';

export const CONTEXT_SET_SCOPE = '@CONTEXT/SET_SCOPE';

export const CONTEXT_SET_USER = '@CONTEXT/SET_USER';

export const CONTEXT_UNSET = '@CONTEXT/UNSET';

export type SetCompanyCode = { type: typeof CONTEXT_SET_COMPANY_CODE; companyCode: string };

export const setCompanyCode = (companyCode: string): SetCompanyCode => ({
    type: CONTEXT_SET_COMPANY_CODE,
    companyCode,
});

export type SetCountryCode = { type: typeof CONTEXT_SET_COUNTRY_CODE; countryCode: string };

export const setCountryCode = (countryCode: string): SetCountryCode => ({
    type: CONTEXT_SET_COUNTRY_CODE,
    countryCode,
});

export type SetDealerId = { type: typeof CONTEXT_SET_DEALER_ID; dealerId: string };

export const setDealerId = (dealerId: string): SetDealerId => ({
    type: CONTEXT_SET_DEALER_ID,
    dealerId,
});

export type SetZoneCode = { type: typeof CONTEXT_SET_ZONE_CODE; zoneCode: string };

export const setZoneCode = (zoneCode: string): SetZoneCode => ({ type: CONTEXT_SET_ZONE_CODE, zoneCode });

export type SetCompany = { type: typeof CONTEXT_SET_COMPANY; company: any | null };

export const setCompany = (company: any | null): SetCompany => ({ type: CONTEXT_SET_COMPANY, company });

export type Scope = {
    company?: any | null;
    country?: any | null;
    zone?: any | null;
};

export type SetScope = {
    type: typeof CONTEXT_SET_SCOPE;
    scope: Scope;
};

export const setScope = (scope: Scope): SetScope => ({ type: CONTEXT_SET_SCOPE, scope });

export type SetUser = { type: typeof CONTEXT_SET_USER; user: any };

export const setUser = (user: any): SetUser => ({ type: CONTEXT_SET_USER, user });

export type UnsetContext = { type: typeof CONTEXT_UNSET };

export const unsetContext = () => ({ type: CONTEXT_UNSET });

export type ContextActions =
    | SetCompanyCode
    | SetCountryCode
    | SetDealerId
    | SetZoneCode
    | SetCompany
    | SetScope
    | SetUser
    | UnsetContext;
