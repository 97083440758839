import { getPrivateHomeUrl } from '../../../utilities/urls';
import { UserDataFragment } from './api.graphql';

// eslint-disable-next-line import/prefer-default-export
export const getRootUrl = (user: UserDataFragment, companyCode?: string, countryCode?: string) => {
    const { availableCompanies } = user;

    // get user country
    const country = availableCompanies
        ?.find(company => company.code === companyCode)
        ?.countries.find(item => item.code === countryCode);

    // country does not exist
    if (!country) {
        return null;
    }

    return getPrivateHomeUrl(user, country);
};
