import { ApolloClient, FetchPolicy, NormalizedCacheObject, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { attachLoading } from '../../actions/loading';
import { Maybe } from '../../schema';
import { getPromo, GetPromoQuery, PromoDataFragment } from './useLoadPromo.graphql';

export type Promo = PromoDataFragment;

export const getPromoFromIdentifier = async (
    client: ApolloClient<NormalizedCacheObject>,
    countryId: string,
    identifier: string,
    dealerIds: string[],
    fetchPolicy: FetchPolicy = 'cache-first'
): Promise<Maybe<Promo>> => {
    const variables = { countryId, identifier, dealerIds };
    const { data } = await client.query({ query: getPromo, variables, fetchPolicy });

    return data.promo || null;
};

const useLoadPromo = (
    countryId: string,
    dealerId?: string | null,
    identifier: string | null = '',
    fetchPolicy: FetchPolicy = 'cache-first'
): Promo | null | undefined => {
    const variables = useMemo(() => ({ countryId, identifier, dealerIds: [dealerId] }), [
        countryId,
        dealerId,
        identifier,
    ]);
    const { refetch, data, loading } = useQuery<GetPromoQuery>(getPromo, {
        variables,
        fetchPolicy,
        skip: isEmpty(countryId) || isEmpty(dealerId),
    });

    const dispatch = useDispatch();

    const previousVariables = useRef(variables);
    useEffect(() => {
        if (previousVariables.current !== variables) {
            const promise = refetch(variables);
            dispatch(attachLoading(promise));
            previousVariables.current = variables;
        }
    }, [refetch, previousVariables, variables, dispatch]);

    return loading ? undefined : data?.promo || null;
};

export default useLoadPromo;
