// @ts-ignore
import { CalculatorComparison as Component } from '@appvantageasia/afc-calculator-ui';
// @ts-ignore
import { Actions } from '@appvantageasia/afc-ui';
import styled from 'styled-components';
import CalculatorError from './CalculatorError';
import VariantImage from './VariantImage';

export const VariantImageContainer = styled.div`
    position: relative;
    margin-left: 5px;
    min-width: 240px;
    height: 130px;
    width: 100%;
    display: flex;

    ${VariantImage} {
        max-width: 240px;
        max-height: 120px;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
    }

    button {
        position: absolute;

        svg {
            fill: #888888;
            width: 24px;
            height: 24px;
        }
    }
`;

export const CalculatorComparisonContainer = styled.div`
    width: 100%;
    overflow: auto;
    margin-top: 50px;

    @media (max-width: 450px) {
        margin-top: 10px;
    }
`;

export const CalculatorContainer = styled.div`
    margin-bottom: 10px;
`;

export const ComparisonItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CalculatorComparisonError = styled(CalculatorError)`
    margin-left: 5px;
    margin-top: 0;
`;

const CalculatorComparison = styled(Component)`
    padding: 0 30px;

    @media (max-width: 450px) {
        padding: 0 15px;
    }

    ${Actions} {
        width: 240px;
        min-width: 240px;
        margin: auto 0 0 5px;

        & > * {
            margin: 0;
        }
    }
`;

export default CalculatorComparison;
