import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues, InjectedFormProps, reduxForm, ReduxFormContext } from 'redux-form';
import useFirstFinanceProduct from '../../../../components/data/useFirstFinanceProduct';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import Footer from '../../../../components/shared/Footer';
import ExpressCalculator, {
    ExpressCalculatorProps,
} from '../../../../components/shared/calculator-next/ExpressCalculator';
import InsuranceCalculator from '../../../../components/shared/calculator-next/InsuranceCalculator';
import CalculatorPortlet from '../../../../components/ui/CalculatorPortlet';
import { CalculatorError, CalculatorFooter } from '../../../../components/ui/calculator';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import { useGetCalculatorFooterText } from '../../../../utilities/calculator';
import useDealerEstablishment from '../../../utils/useDealerEstablishment';
import { CalculatorErrors } from '../../types';
import { Buttons, PrimaryButton } from '../UsedCalculator/ui';
import PreOwnedDetails from './PreOwnedDetails';
import { ExpressGrid } from './ui';

export type PreownedFormProps = Pick<ExpressCalculatorProps, 'snapshot'> & {
    applyDisabled: boolean;
    calculatorErrors: CalculatorErrors;
    initialCalculatorValues: ExpressCalculatorProps['initialValues'];
    onCalculatorChange: ExpressCalculatorProps['onChange'];
    initialValues: VariantDataFragment;
    dealerId: string;
    isFinanceChecked: boolean;
    isInsuranceChecked: boolean;
    onFinanceCheckChange: () => void;
    onInsuranceCheckChange: () => void;
};

const PreownedForm = ({
    invalid,
    initialCalculatorValues,
    onCalculatorChange,
    applyDisabled,
    handleSubmit,
    snapshot,
    calculatorErrors,
    initialValues,
    dealerId,
    isFinanceChecked,
    isInsuranceChecked,
    onFinanceCheckChange,
    onInsuranceCheckChange,
}: PreownedFormProps & InjectedFormProps<any, PreownedFormProps>) => {
    const { t } = useTranslation();
    const { form } = useContext(ReduxFormContext);
    const dispatch = useDispatch();
    const { formatPath } = useContentTranslation();

    // @ts-ignore
    const { preOwnedCarDetails } = useSelector(getFormValues(form)) || {};

    const country = useSelector(getCurrentCountry);

    const { channelSetting } = useCountry();

    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.EXPRESS);

    const fallbackFinanceProduct = useFirstFinanceProduct(Channel.EXPRESS, dealerId);

    const selectedBankEstablishment = fallbackFinanceProduct?.bank.establishment;

    // get the text footer from the country
    const getCalculatorFooterText = useGetCalculatorFooterText();
    const calculatorFooterText = getCalculatorFooterText(
        get(formatPath('channelSetting.express.priceDisclaimer'), country),
        country?.coe?.amount,
        country?.ppsr?.amount,
        country?.establishment?.amount,
        country?.luxuryTax?.amount,
        selectedDealerEstablishment,
        selectedBankEstablishment
    );

    // reset default value after dealer selection
    useEffect(() => {
        if (initialValues) {
            const { makeName, preOwnedCarDetails } = initialValues;

            dispatch(change(form, 'makeName', makeName));
            dispatch(change(form, 'preOwnedCarDetails.ownerIdType', preOwnedCarDetails?.ownerIdType));
        }
    }, [dispatch, form, initialValues]);

    const { isFinanceApplicationMandatory, allowFinanceApplication, isInsuranceEnabled } = channelSetting.express;

    const shouldDisplayFinanceOption = isInsuranceEnabled && !isFinanceApplicationMandatory && allowFinanceApplication;
    const shouldDisplayInsuranceOption = isInsuranceEnabled;

    return (
        <>
            <ExpressGrid>
                <PreOwnedDetails />
                <div>
                    <CalculatorPortlet
                        checked={isFinanceChecked}
                        displayOption={shouldDisplayFinanceOption}
                        name="Finance"
                        onClick={onFinanceCheckChange}
                        closeable
                        open
                    >
                        <ExpressCalculator
                            channel={Channel.EXPRESS}
                            dealerId={dealerId}
                            initialValues={initialCalculatorValues}
                            onChange={onCalculatorChange}
                            preOwnedCarDetails={preOwnedCarDetails}
                            snapshot={snapshot}
                            variant={initialValues}
                        />
                    </CalculatorPortlet>
                    <CalculatorPortlet
                        checked={isInsuranceChecked}
                        displayOption={shouldDisplayInsuranceOption}
                        name="Insurance"
                        onClick={onInsuranceCheckChange}
                        closeable
                        open
                    >
                        {/* <InsuranceCalculator initialValues={initialCalculatorValues} onChange={onCalculatorChange} /> */}
                    </CalculatorPortlet>
                    <Buttons>
                        <PrimaryButton disabled={invalid || applyDisabled} onClick={handleSubmit}>
                            <FontAwesomeIcon icon={faAngleRight} /> {t('expressCalculatorPage.button.next')}
                        </PrimaryButton>
                    </Buttons>
                    <CalculatorError errors={calculatorErrors} />
                    <CalculatorFooter
                        dangerouslySetInnerHTML={{ __html: calculatorFooterText || '' }}
                        maxWidth="500px"
                    />
                </div>
            </ExpressGrid>
            <Footer />
        </>
    );
};

export default reduxForm<any, any>({
    form: 'preowned',
})(PreownedForm);
