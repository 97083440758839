import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { isNil } from 'lodash/fp';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    getApplicationByToken,
    GetApplicationByTokenQuery,
    GetApplicationByTokenQueryVariables,
} from '../../../api/application.graphql';
import { InsuranceApplicationDataFragment } from '../../../components/routes/InsuranceRoute/data.graphql';
import { startNamirialSession } from '../../../components/routes/wip/NamirialCallback';
import { useModal } from '../../../components/shared/modal/ModalConsumer';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ApplicationFlowSource } from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import useLoading from '../../../useLoading';
import { getApplicationsUrl, getCompanyRootUrl } from '../../../utilities/urls';

const maxRetries = 40;
const retryGapInMilliseconds = 3000;

const useSigningInfo = (
    token: string,
    source: ApplicationFlowSource,
    existingSigning?: InsuranceApplicationDataFragment['signing']
) => {
    const { t } = useTranslation();
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const zone = useZone();
    const company = useCompany();
    const country = useCountry();
    const history = useHistory();

    // initial path where the request is initiated
    const path = history.location.pathname;

    useEffect(() => {
        let tries = 0;
        let timeoutId: NodeJS.Timeout;

        const handler = async () => {
            if (tries > maxRetries) {
                clearInterval(timeoutId);
                throw new Error('fail to create signing');
            }

            const response = await client.query<GetApplicationByTokenQuery, GetApplicationByTokenQueryVariables>({
                query: getApplicationByToken,
                variables: { token },
                fetchPolicy: 'network-only',
            });

            // when path becomes different
            // prevent starting namirial session
            if (path !== history.location.pathname) {
                return;
            }

            const { application } = response.data;

            if (isNil(application.insuranceApplication)) {
                return;
            }

            const { signing, version: insuranceVersion } = application.insuranceApplication;

            const isBankSigning = false;

            // check signing validity
            // ensure that we will use updated signing redirection url
            if (
                signing &&
                signing?.id &&
                signing?.redirectionUrl &&
                signing?.redirectionUrl !== existingSigning?.redirectionUrl
            ) {
                startNamirialSession({
                    source,
                    redirectionUrl: signing.redirectionUrl,
                    applicationId: application.id,
                    applicationVersionId: insuranceVersion.id,
                    envelopeId: signing.id,
                    channel: application.channel,
                    companyCode: company.code,
                    countryCode: country.code,
                    zoneCode: zone.code,
                    // when callback from insurance signing,
                    // need this param to redirect to finance application detai page
                    // since we dont have insurance application detail page yet
                    originApplicationVersionId: application.version.id,
                    isBankSigning, // indicate this is bank signing
                    applicationPhase: application.phase,
                });
            } else {
                tries += 1;
                timeoutId = setTimeout(handler, retryGapInMilliseconds);
            }
        };

        timeoutId = setTimeout(handler, retryGapInMilliseconds);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [
        client,
        company.code,
        country.code,
        history.location.pathname,
        path,
        source,
        zone.code,
        token,
        existingSigning,
        t,
    ]);
};

const useBlock = () => {
    const { t } = useTranslation();
    const modal = useModal();
    const history = useHistory();
    const isAuthenticated = useSelector(getIsAuthenticated);

    useEffect(() => {
        const unblock = history.block(() => {
            const {
                title,
                message: content,
                button,
            }: { title: string; message: string; button: { confirm: string; decline: string } } = t(
                'saveChangesModal',
                {
                    returnObjects: true,
                }
            );
            // show confirmation modal to verify
            // if user wants to leave
            modal.show({
                title,
                content,
                confirm: {
                    label: button.confirm,
                    onClick: () => {
                        unblock();
                        const url = isAuthenticated ? getApplicationsUrl : getCompanyRootUrl;

                        // @ts-ignore
                        history.pushWithCompany(url);
                    },
                },
                decline: {
                    label: button.decline,
                },
            });

            return false;
        });

        return () => {
            // remove block when component dismounts
            unblock();
        };
    }, [history, isAuthenticated, modal, t]);
};

export type InsuranceNamirialProps = {
    token: string;
    source: ApplicationFlowSource;
    signing?: InsuranceApplicationDataFragment['signing'];
};

const InsuranceNamirial = ({ token, source, signing }: InsuranceNamirialProps) => {
    useBlock();
    useSigningInfo(token, source, signing);

    useLoading(true);

    return null;
};

export default InsuranceNamirial;
