import { useMemo } from 'react';
import { ProceedApplicationDataFragment } from '../../../api/draft.graphql';
import InsuranceDraftFlow from '../../../flows/InsuranceDraftFlow/InsuranceDraftFlow';
import { InsuranceNewFlowState } from '../../../flows/InsuranceDraftFlow/types';
import { FlowType, useManagedFlow, useScrollTop } from '../../../flows/utils/flow';
import useInsuranceCompleteHandler from '../../../flows/utils/useInsuranceCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../schema';
import { getCalculatorFromApplication } from '../../../utilities/application';
import { InsuranceCompanyDataFragment } from '../../data/useLoadInsuranceCompany.graphql';

export type InsuranceDraftFlowHandlerProps = {
    application: ProceedApplicationDataFragment['application'];
    insuranceCompany: InsuranceCompanyDataFragment;
};

const getFlowImplementation = (channel: Channel): FlowType<InsuranceNewFlowState, InsuranceDraftFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
            return InsuranceDraftFlow;

        default:
            return null;
    }
};

const ApplyForInsuranceHandler = ({ application, insuranceCompany }: InsuranceDraftFlowHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();

    // create the initial state
    const initialState = useMemo(
        (): InsuranceNewFlowState => ({
            insuranceCompany,
            applyForInsurance: true,
            channel: application.channel,
            company,
            zone,
            country,
            dealerId: application.dealerId!,
            application,
            calculator: getCalculatorFromApplication(application),
            source: ApplicationFlowSource.INSURANCE,
            customer: application.customer,
        }),
        [insuranceCompany, application, company, zone, country]
    );

    const flowImplementation = getFlowImplementation(application.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'insuranceDraftFlow');

    useScrollTop(step.identifier);

    useInsuranceCompleteHandler('insuranceDraftFlow');

    return step.render();
};

export default ApplyForInsuranceHandler;
