import styled, { css } from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';
import { TextAlignCenter } from '../TextAlign';
import { CarCardContainer } from './CarCard';

export type CarListContainerProps = {
    lessThanTwo?: boolean;
};

const CarListContainer = styled.div<CarListContainerProps>`
    max-width: 1024px;
    width: 100%;
    margin: auto;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    ${props =>
        props.lessThanTwo &&
        css`
            justify-content: center;
        `}

    @media (min-width: ${breakpoints.md}) {
        flex-direction: row;
        ${CarCardContainer} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        ${CarCardContainer} {
            flex: 0 0 33.3%;
            max-width: 33.3%;
        }
    }

    ${TextAlignCenter} {
        width: 100%;
    }
`;

export default CarListContainer;
