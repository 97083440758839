import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import InsuranceRoute from '../routes/InsuranceRoute';
import InsurancesRoute from '../routes/InsurancesRoute';
import InsuranceResumeRoute from '../routes/wip/InsuranceResumeRoute';
import Header from '../shared/Header';
import { prefixInsurancePath } from './shared';
import useNotPaidBackAction from './useNotPaidBackAction';
import useNotSignedBackAction from './useNotSignedBackAction';

const InsuranceRouter = () => {
    useNotSignedBackAction();
    useNotPaidBackAction();

    return (
        <>
            <Header />
            <Switch>
                <Route component={InsurancesRoute} path={prefixInsurancePath('/')} exact />
                <Route component={InsuranceRoute} path={prefixInsurancePath('/:id')} exact />
                <Route component={InsuranceResumeRoute} path={prefixInsurancePath('/:id/resume')} exact />
                <Redirect to={prefixInsurancePath('/')} />
            </Switch>
        </>
    );
};

export default InsuranceRouter;
