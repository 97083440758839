import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import InfoTooltip from '../../../../../components/ui/tooltip/InfoTooltip';
import { Channel } from '../../../../../schema';
import { PortalContainer } from '../Portal';
import { CheckboxWrapper, SectionItem, SectionItemContent } from './styles';

export type TradeInContent = {
    title?: string;
    content?: string;
};

export type TradeInLabelProps = {
    page: 'eventCalculatorPage' | 'eventDraftPage';
};

export const TradeInLabel = ({ page }: TradeInLabelProps) => {
    const { t } = useTranslation();

    const tradeInTooltip: TradeInContent = t('eventCalculatorPage.tradeIn', { returnObjects: true });

    const hasTooltip = tradeInTooltip?.title && tradeInTooltip?.content;

    return (
        <span style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {t(`${page}.checkbox.tradeIn`)}
            {hasTooltip && <InfoTooltip content={tradeInTooltip.content} title={tradeInTooltip.title} />}
        </span>
    );
};

export type SectionTradeInProps = {
    hasTradeIn?: boolean;
};

// eslint-disable-next-line react/prop-types
const SectionTradeIn = forwardRef<HTMLDivElement, SectionTradeInProps>(({ hasTradeIn }, ref) => {
    return (
        <SectionItem>
            <CheckboxWrapper>
                <CheckboxField
                    channel={Channel.EVENT}
                    label={<TradeInLabel page="eventCalculatorPage" />}
                    name="hasTradeIn"
                />
            </CheckboxWrapper>
            <SectionItemContent $isOpened={hasTradeIn}>
                <PortalContainer ref={ref} />
            </SectionItemContent>
        </SectionItem>
    );
});

export default SectionTradeIn;
