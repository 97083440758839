import { useQuery } from '@apollo/client';
import { Paging, TableList, Footer } from '@appvantageasia/afc-ui';
import { get, flow } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useMedia from 'use-media';
import { setNotification } from '../../actions';
import { useContentTranslation } from '../../i18n';
import { EventExternalSite } from '../../schema';
import { getZoneId } from '../../selectors';
import useLoading from '../../useLoading';
import breakpoints from '../../utilities/constants/breakpoints';
import { getLeadUrl } from '../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../shared/HelmetTitle';
import useFormatDate from '../shared/useFormatDate';
import useFormatDateTime from '../shared/useFormatDateTime';
import Status from '../ui/ApplicationStatus';
import { renderChannelOption } from '../ui/Cells';
import PageContainer, { Title, Content } from '../ui/PageContainer';
import { TextAlignCenter } from '../ui/TextAlign';
import useListContext, { useListContextFromState } from '../utilities/useListContext';
import useSearch from '../utilities/useSearch';
import useServerPaging from '../utilities/useServerPaging';
import useSorting from '../utilities/useSorting';
import DownloadModal from './DownloadModal';
import { getLeads } from './LeadsRoute.graphql';

// eslint-disable-next-line react/prop-types
const renderStatus = () => ({ statusText: { label, color } }) => <Status color={color}>{label}</Status>;

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

const useMobileColumns = (hasFinanceProduct, hasBank) => {
    const { formatPath } = useContentTranslation();

    // get formatting
    const formatDate = useFormatDate();

    return useMemo(() => {
        const columns = [
            {
                key: 'leadDate',
                sortKey: 'version.createdAt',
                label: 'Lead Date',
                getValue: flow([get('version.createdAt'), formatDate]),
            },
            {
                key: 'appId',
                sortKey: 'identifier',
                label: 'Lead ID',
                getValue: get('identifier'),
            },
            {
                key: 'customer',
                sortKey: 'customer.name.value',
                label: 'Customer',
                getValue: get('customer.name.value'),
            },
            {
                key: 'status',
                sortKey: 'status',
                label: 'Status',
                getValue: renderStatus(),
            },
        ];

        return columns.filter(Boolean);
    }, [formatDate, formatPath, hasBank, hasFinanceProduct]);
};

const useColumns = (hasFinanceProduct, hasBank) => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();

    // get formatting
    const formatDateTime = useFormatDateTime();

    return useMemo(() => {
        const columns = [
            {
                key: 'leadDate',
                sortKey: 'version.createdAt',
                label: t('leadsListPage.list.columns.leadDate'),
                getValue: flow([get('version.createdAt'), formatDateTime]),
            },
            {
                key: 'appId',
                sortKey: 'identifier',
                label: t('leadsListPage.list.columns.leadID'),
                getValue: get('identifier'),
            },
            {
                key: 'salesPerson',
                sortKey: 'assignee.username',
                label: t('leadsListPage.list.columns.assignedTo'),
                getValue: get('assignee.username'),
            },
            {
                key: 'customer',
                sortKey: 'customer.name.value',
                label: t('leadsListPage.list.columns.customer'),
                getValue: get('customer.name.value'),
            },
            {
                key: 'variant',
                sortKey: formatPath('variant.name'),
                label: t('leadsListPage.list.columns.variant'),
                getValue: get(formatPath('variant.name')),
            },
            hasBank && {
                key: 'bank',
                sortKey: formatPath('bank.name'),
                label: t('leadsListPage.list.columns.bank'),
                getValue: get(formatPath('bank.name')),
            },
            hasFinanceProduct && {
                key: 'financeProduct',
                sortKey: formatPath('financeProduct.name'),
                label: t('leadsListPage.list.columns.financialProduct'),
                getValue: get(formatPath('financeProduct.name')),
            },
            {
                key: 'lastUpdatedDt',
                sortKey: 'version.updatedAt',
                label: t('leadsListPage.list.columns.lastActivity'),
                getValue: flow([get('version.updatedAt'), formatDateTime]),
            },
            {
                key: 'channel',
                sortKey: 'channel',
                label: t('leadsListPage.list.columns.channel'),
                getValue: value => renderChannelOption(value, t),
            },
            {
                key: 'status',
                sortKey: 'status',
                label: 'Status',
                getValue: renderStatus(),
            },
        ];

        return columns.filter(Boolean);
    }, [formatDateTime, formatPath, hasBank, hasFinanceProduct, t]);
};

const searchFields = ['identifier', 'customer.name.value', 'variant.name', 'financeProduct.name', 'status'];

const renderList = (isMobile, columns, mobileColumns, items, onItemClick, sorting, t, loading) => {
    if (items.length && isMobile) {
        return <TableList columns={mobileColumns} items={items} onItemClick={onItemClick} sorting={sorting} />;
    }
    if (items.length && !isMobile) {
        return <TableList columns={columns} items={items} onItemClick={onItemClick} sorting={sorting} />;
    }

    return !loading ? <TextAlignCenter>{t('leadsListPage.list.noLeads')}</TextAlignCenter> : null;
};

const LeadsRoute = () => {
    const isMobile = useMedia({ maxWidth: breakpoints.md });
    const { t } = useTranslation();
    const { language } = useContentTranslation();
    const dispatch = useDispatch();

    // get initial list context
    const [, getInitial] = useListContextFromState();

    // searching comes first
    const search = useSearch('applications', getInitial('search'));

    const [paging] = useServerPaging({ search, initialPage: getInitial('paging.page') });

    const sorting = useSorting({
        initialKey: getInitial('sorting.key', 'version.updatedAt'),
        initialOrder: getInitial('sorting.order'),
    });

    // get leads with apollo
    const zoneId = useSelector(getZoneId);
    const dealerId = useMustExistDealerIdFromTenantContext();
    const variables = {
        zoneId,
        dealerId,
        locale: language,
        paging: {
            limit: paging.pageSize,
            offset: (paging.page - 1) * paging.pageSize,
        },
        sorting: [
            {
                type: sorting && sorting?.key,
                order: sorting && sorting?.order?.toUpperCase(),
            },
        ],
        search: searchFields.map(identifier => ({
            identifier,
            value: search || '',
        })),
    };
    const { data, loading, error: apiError } = useQuery(getLeads, { variables, fetchPolicy: 'cache-and-network' });

    useEffect(() => {
        paging.setItemCount(data?.results?.count || 0);
    }, [paging, data]);

    const items = useMemo(
        () =>
            (data?.results?.items || []).map(lead => ({
                ...lead,
                variant:
                    lead.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT ? null : lead.variant,
            })),
        [data]
    );

    const isLoading = loading && items.length <= 0;
    useLoading(isLoading);

    const { hasBank, hasFinanceProduct } = useMemo(
        () => ({
            hasFinanceProduct: items.some(lead => lead.financeProduct),
            hasBank: items.some(lead => lead.bank.name),
        }),
        [items]
    );

    const columns = useColumns(hasBank, hasFinanceProduct);
    const mobileColumns = useMobileColumns(hasBank, hasFinanceProduct);

    // make a context to bump/restore those configuration
    const listContext = useListContext(paging, sorting, search);

    // callback on items
    const history = useHistory();
    const onItemClick = useCallback(
        item => {
            history.pushWithCompanyAndState(getLeadUrl, { listContext }, item.version.id);
        },
        [history, listContext]
    );

    const { error = null } = useLocation().state ?? {};
    useEffect(() => {
        if (error) {
            dispatch(setNotification('Error', error));
        }
    }, [dispatch, error]);

    const [downloadModal, setDownloadModal] = useState(false);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () => setDownloadModal(<DownloadModal onClose={closeDownloadModal} type="Leads" />),
        [setDownloadModal, closeDownloadModal]
    );

    if (apiError) {
        throw apiError;
    }

    return (
        <PageContainer>
            <HelmetTitle title="Lead List" />
            <Title>{t('leadsListPage.title')}</Title>
            <Content>{renderList(isMobile, columns, mobileColumns, items, onItemClick, sorting, t, isLoading)}</Content>
            <Paging {...paging} />
            <div style={{ marginBottom: '80px' }} />
            {!!items.length && (
                <FootContainer>
                    <FootBar>
                        <FootBarRow>
                            <div style={{ flex: 1 }} />
                            <FootBarButton onClick={openDownloadModal}>
                                {t('leadsListPage.button.download')}
                            </FootBarButton>
                        </FootBarRow>
                    </FootBar>
                </FootContainer>
            )}
            {downloadModal}
        </PageContainer>
    );
};

export default LeadsRoute;
