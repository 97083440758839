import { getAydenSession } from '../../components/routes/wip/AydenCallback';
import { getMyInfoSession } from '../../components/routes/wip/MyInfoCallback';
import { getPayGatePaymentSession } from '../../components/routes/wip/PayGatePaymentCallback';
import { getPorschePaymentSession } from '../../components/routes/wip/PorschePaymentCallback';
import { getTTBPaymentSession } from '../../components/routes/wip/TTBPaymentCallback';
import {
    ApplicationFlowSource,
    BankSigningMode,
    Channel,
    FinanceProductSubType,
    InsuranceSigningMode,
    PaymentProviderType,
} from '../../schema';
import { Flow, FlowStep } from '../utils/flow';
import * as flowSteps from './steps';
import { NewFlowState } from './types';
import { getCountryInsuranceConsents, getFinancingConsents, getNonFinancingConsents } from './utils/consents';

export type NewFlowStep = FlowStep<NewFlowState>;

class DraftFlow extends Flow<NewFlowState> {
    public get channelSetting() {
        const { channel, country } = this.state;

        switch (channel) {
            case Channel.EXPRESS:
                return country.channelSetting.express;

            case Channel.USED:
                return country.channelSetting.used;

            case Channel.NEW:
                return country.channelSetting.new;

            default:
                throw new Error('not implemented');
        }
    }

    public get applyForFinancing() {
        const { application } = this.state;
        const { channelSetting } = this;

        return Boolean(
            application?.appliedForFinancing ||
                (channelSetting.allowFinanceApplication && channelSetting.isFinanceApplicationMandatory)
        );
    }

    protected initialize(): FlowStep<NewFlowState> {
        const { application } = this.state;

        if (application) {
            const myInfo = getMyInfoSession(application.id, ApplicationFlowSource.DRAFT);

            if (myInfo) {
                const step = this.getStep(myInfo.step);

                if (step) {
                    return step;
                }
            }

            if (this.getPaymentSession(application.version.id)) {
                const step = this.getStep('deposit');

                if (step) {
                    return step;
                }
            }

            for (const step of this.steps) {
                if (!step.isShadowStep && !step.isCompleted) {
                    return step;
                }
            }
        }

        return super.initialize();
    }

    private getCalculatorStep() {
        const { channel } = this.state;

        switch (channel) {
            case Channel.EXPRESS:
                return flowSteps.ExpressCalculatorStep;

            case Channel.NEW:
                return flowSteps.CalculatorStep;

            case Channel.USED:
                return flowSteps.UsedCalculatorStep;

            default:
                throw new Error('Channel not supported');
        }
    }

    private getPaymentStep() {
        const { channelSetting } = this;

        switch (channelSetting.bookingPayment?.provider.type) {
            case PaymentProviderType.ADYEN:
                return flowSteps.DepositStep;

            case PaymentProviderType.PORSCHE:
                return flowSteps.PorscheDepositStep;

            case PaymentProviderType.PAYGATE:
                return flowSteps.PayGateDepositStep;

            case PaymentProviderType.TTB:
                return flowSteps.TTBDepositStep;

            default:
                throw new Error('Payment Provider Type not supported');
        }
    }

    private getPaymentSession(id: string) {
        const { channelSetting } = this;

        switch (channelSetting.bookingPayment?.provider.type) {
            case PaymentProviderType.ADYEN:
                return getAydenSession(id, ApplicationFlowSource.DRAFT);

            case PaymentProviderType.PORSCHE:
                return getPorschePaymentSession(id, ApplicationFlowSource.DRAFT);

            case PaymentProviderType.PAYGATE:
                return getPayGatePaymentSession(id, ApplicationFlowSource.DRAFT);

            case PaymentProviderType.TTB:
                return getTTBPaymentSession(id, ApplicationFlowSource.DRAFT);

            default:
                return null;
        }
    }

    protected plannify(): FlowStep<NewFlowState>[] {
        // there's always the calculator and draft step
        const steps: FlowStep<NewFlowState>[] = [];

        const { application, bank, skipEarlyStages = false, consents, channel, insuranceApplication } = this.state;

        const { applyForFinancing, channelSetting } = this;

        if (!application || !skipEarlyStages) {
            steps.push(this.createStep(this.getCalculatorStep()), this.createStep(flowSteps.DraftStep));
        }

        if (!application || !bank) {
            // might be insurance flow instead
            if (insuranceApplication) {
                if (insuranceApplication.proceedWithCustomerDevice) {
                    // the application need to be submitted
                    return [...steps, this.createStep(flowSteps.SubmitStep)];
                }

                const insuranceConsents = getCountryInsuranceConsents(consents, channel);

                if (insuranceConsents.length) {
                    steps.push(this.createStep(flowSteps.ConsentStep));
                }

                if (insuranceApplication.insuranceCompany.signing.onCreate === InsuranceSigningMode.NAMIRIAL) {
                    steps.push(this.createStep(flowSteps.InsuranceNamirialStep));
                }
            }

            // nothing else yet
            return steps;
        }

        if (application.proceedWithCustomerDevice) {
            // the application need to be submitted
            return [...steps, this.createStep(flowSteps.SubmitStep)];
        }

        if (applyForFinancing) {
            if (bank.isKYCMandatory) {
                if (bank.myInfo) {
                    // there's my info integration
                    steps.push(this.createStep(flowSteps.MyInfoStep));
                }

                // we need to collect KYC fields
                steps.push(this.createStep(flowSteps.KYCStep));
            }

            if (application.guarantor && bank.isKYCMandatory) {
                if (bank.myInfo) {
                    // there's my info integration
                    steps.push(this.createStep(flowSteps.GuanratorMyInfoStep));
                }

                // we need to collect KYC fields for the guarantor
                steps.push(this.createStep(flowSteps.GuarantorStep));
            }
        }

        const financingConsents = getFinancingConsents(consents, channel);
        const nonFinancingConsents = getNonFinancingConsents(consents, channel);

        // show separate consent step when there is no KYC
        if (
            (applyForFinancing && financingConsents.length && !bank.isKYCMandatory) ||
            (!applyForFinancing && nonFinancingConsents.length)
        ) {
            steps.push(this.createStep(flowSteps.ConsentStep));
        }

        if (channelSetting.isDepositPaymentMandatory) {
            steps.push(this.createStep(this.getPaymentStep()));
        }

        let pushedNamirialStep = false;
        if (applyForFinancing) {
            switch (bank.signing.onCreate) {
                case BankSigningMode.NAMIRIAL:
                    pushedNamirialStep = true;
                    steps.push(this.createStep(flowSteps.NamirialStep));
                    break;

                case BankSigningMode.OTP:
                    steps.push(this.createStep(flowSteps.OTPStep));
                    break;

                case BankSigningMode.NONE:
                default:
                    // do nothing
                    break;
            }
        }

        if (application.insuranceApplication) {
            const { insuranceApplication } = application;
            if (
                !pushedNamirialStep &&
                insuranceApplication.insuranceCompany.signing.onCreate === InsuranceSigningMode.NAMIRIAL
            ) {
                steps.push(this.createStep(flowSteps.InsuranceNamirialStep));
            }
        }

        if (
            application.bank?.isGuaranteedBuybackEnabled &&
            application.financeProduct?.subType === FinanceProductSubType.LEASEPURCHASE
        ) {
            steps.push(this.createStep(flowSteps.GuaranteedBuybackStep));
        }

        return [...steps, this.createStep(flowSteps.SubmitStep)];
    }
}

export default DraftFlow;
