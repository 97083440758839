import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

export type ESCEventDetectorProps = {
    onEvent: () => void;
    ignoreCheck?: () => boolean;
};

const ESCEventDetector = ({ onEvent, ignoreCheck }: ESCEventDetectorProps) => {
    useEffect(() => {
        const catchEscape = (event: KeyboardEvent) => {
            if (ignoreCheck && ignoreCheck()) {
                return;
            }

            if (event.keyCode === 27) {
                onEvent();
            }
        };

        document.addEventListener('keydown', catchEscape, false);

        return () => {
            document.removeEventListener('keydown', catchEscape, false);
        };
    }, [ignoreCheck, onEvent]);

    return null;
};

// @ts-ignore
const ignoreCheck = () => document.modalCount !== 1;

const ConfirmWindow = styled(ReactModal)`
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
`;

export type ConfirmOption = { label: string; action: () => any };

export type ConfirmModalProps = {
    className?: string;
    profile: string;
    options: ConfirmOption[];
    onCloseClicked: () => void;
    onOptionSelected: (option: ConfirmOption) => void;
    isOpen?: boolean;
    alertTitle: string;
    alertContent: string;
};

const ConfirmModal = ({
    className,
    profile,
    options,
    onOptionSelected,
    onCloseClicked,
    isOpen,
    alertTitle,
    alertContent,
}: ConfirmModalProps) => {
    let overlay: object = {
        zIndex: 9999,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
    };

    if (profile === 'admin') {
        overlay = {
            ...overlay,
            backgroundColor: 'rgba(27, 26, 41, 0.56)',
            top: 0,
        };
    }

    useLayoutEffect(() => {
        if (!isOpen) {
            return () => undefined;
        }

        // @ts-ignore
        document.modalCount += 1;

        return () => {
            // @ts-ignore
            document.modalCount -= 1;
        };
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <ConfirmWindow className={className} style={{ overlay }} isOpen>
            <ESCEventDetector ignoreCheck={ignoreCheck} onEvent={onCloseClicked} />
            <div className={`alert__model__container ${profile === 'admin' ? 'admin' : 'ci'}`}>
                <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="alert__model__close__icon" href="#" onClick={onCloseClicked}>
                        <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                    </a>
                    <p className="alert__model__title">{alertTitle}</p>
                    <p className="alert__model__content">{alertContent}</p>

                    <div className="confirm__model__actions">
                        {options &&
                            options.map((option, index) => (
                                <button key={index.toString()} onClick={() => onOptionSelected(option)} type="button">
                                    {option.label}
                                </button>
                            ))}
                    </div>
                </div>
            </div>
        </ConfirmWindow>
    );
};

ConfirmModal.propTypes = {
    alertContent: PropTypes.string,
    alertTitle: PropTypes.string,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onCloseClicked: PropTypes.func.isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    profile: PropTypes.string,
};

export default styled(ConfirmModal)`
    .alert__model__container {
        width: 512px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background-color: ${props => (props.profile === 'ci' ? '#ffffff' : 'rgba(14, 15, 25, 0.58)')};
        border: ${props => (props.profile === 'ci' ? '1px #999999 solid' : '0.5px rgba(255, 255, 255, 0.56) solid')};
        color: ${props => (props.profile === 'ci' ? '#000' : '#fff')};

        top: 50%;
        left: 50%;
        position: absolute;
        margin-left: -256px;
        margin-top: -100px;

        &.admin {
            height: 200px;
            margin-top: -100px;
            padding: 26px;

            .alert__model__title {
            }

            .alert__model__content {
                color: #cccccc;
            }

            .alert__model__close__icon {
                width: 15px;
                height: 15px;
                right: 15px;
                top: 8px;
                cursor: pointer;
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                }

                svg {
                    font-size: 15px;
                    position: absolute;
                    top: -0.5px;
                    right: 1.5px;
                    color: rgba(255, 255, 255, 0.31);
                }
            }
        }

        &.ci {
            height: 200px;
            margin-top: -100px;
            padding: 26px;

            .alert__model__close__icon {
                display: block;
                width: 15px;
                height: 15px;
                right: 15px;
                top: 8px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }

                svg {
                    font-size: 15px;
                    position: absolute;
                    top: -0.5px;
                    right: 1.5px;
                }
            }
        }
    }

    .alert__model__title {
        font-size: 16px;
        text-align: center;
    }

    .alert__model__content {
        font-size: 13px;
        text-align: center;
        font-weight: 200;
    }

    .alert__model__close__icon {
        position: absolute;
        right: 14px;
        top: 14px;
        color: gray;
    }

    .confirm__model__actions {
        text-align: center;

        & > button {
            margin: 0 5px -5px 5px;
            background-color: transparent;
            border: 1px ${p => (p.profile === 'ci' ? '#999999' : 'rgba(255, 255, 255, 0.56)')} solid;
            color: ${props => (props.profile === 'ci' ? '#000' : '#fff')};
            cursor: pointer;
            text-transform: capitalize;
        }
    }
`;
