import { get } from 'lodash/fp';
import MarkdownToMdast from 'mdast-util-from-markdown';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useMedia from 'use-media';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { useContentTranslation } from '../../../../../i18n';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Block, Container, getSizingStyle, SubTitle } from '../ui';
import { useColumns } from './Details';

const List = styled.ul<{ columns: number }>`
    padding-left: 0;
    display: flex;

    & > div {
        width: ${props => `calc(100%/${props.columns})`};

        li {
            ${getSizingStyle('list')}
            padding-right: 20px;

            &:before {
                position: absolute;
                margin-left: -20px;
                content: '\\2022';
                color: ${props => props.theme.colors.activeColor};
                width: 20px;
                text-align: center;
                display: inline-block;
            }
        }
    }
`;

export type FeaturesProps = {
    variant: VariantDataFragment;
};

const Features = ({ variant }: FeaturesProps): ReactElement | null => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();

    const features = useMemo(() => {
        if (!variant.features) {
            return [];
        }

        const mdast = MarkdownToMdast(get(formatPath('features'), variant));
        const list = mdast.children;

        if (list[0].type !== 'list') {
            return [];
        }

        return list[0].children.map(get('children[0].children[0].value')).filter(Boolean) as string[];
    }, [formatPath, variant]);

    // then predict how many columns we want
    const isMobile = useMedia({ maxWidth: breakpoints.sm });
    const isDesktop = useMedia({ minWidth: breakpoints.lg });

    const layout = useMemo(() => {
        if (isMobile) {
            return 1;
        }
        if (isDesktop) {
            return 4;
        }

        return 2;
    }, [isDesktop, isMobile]);

    const columns = useColumns(features, layout);

    if (!features.length) {
        return null;
    }

    return (
        <Block id="section-features">
            <Container>
                <SubTitle>{t('usedCalculatorPage.featuresTitle')}</SubTitle>
                <List columns={layout}>
                    {columns.map(column => {
                        if (!column.length) {
                            return null;
                        }
                        // this way of rendering since masonry grid isn't widely supported yet

                        return (
                            <div>
                                {column.map((item, index) => (
                                    <li key={index.toString()}>{item}</li>
                                ))}
                            </div>
                        );
                    })}
                </List>
            </Container>
        </Block>
    );
};

export default Features;
