import React, { useMemo, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '@adyen/adyen-web/dist/adyen.css';
import { Channel } from '../../../../schema';
import { getCurrentZone } from '../../../../selectors';
import useAdyen, { formatLocale } from '../../../DraftFlow/components/Deposit/hooks/useAdyen';

type AdyenPaymentProps = {
    onAdditionalDetails: any;
    onSubmit: any;
    applePayTotalPriceLabel: string;
    channel: Channel;
    token: string;
    setSubmitDisabled: (value: boolean) => void;
    setDropin: (value: any) => void;
};

const AdyenPayment = ({
    onAdditionalDetails,
    onSubmit,
    applePayTotalPriceLabel,
    channel,
    token,
    setSubmitDisabled,
    setDropin,
}: AdyenPaymentProps) => {
    const { id: zoneId } = useSelector(getCurrentZone) || {};
    const { t, i18n } = useTranslation();
    const { translations, locale } = useMemo(() => {
        const formattedLocale = formatLocale(i18n.languages[0]);

        const translations: { [key: string]: { [key: string]: string } } = {
            [formattedLocale]: t('adyen', { returnObjects: true, ignoreJSONStructure: false }),
        };

        return {
            translations,
            locale: formattedLocale,
        };
    }, [i18n.languages, t]);

    const configuration = useMemo(
        () => ({
            locale,
            onSubmit,
            onAdditionalDetails,
            applePayTotalPriceLabel,
            onChange: (state: any) => (state.isValid ? setSubmitDisabled(false) : setSubmitDisabled(true)),
            translations,
        }),
        [locale, onSubmit, onAdditionalDetails, applePayTotalPriceLabel, translations, setSubmitDisabled]
    );

    const checkout = useAdyen(channel, zoneId, token, configuration, false);

    useEffect(() => {
        if (checkout) {
            // @ts-ignore
            setDropin(checkout.create('dropin').mount('#dropin-container'));
        }
    }, [checkout, setDropin]);

    return <div id="dropin-container" />;
};

export default memo(AdyenPayment);
