import React from 'react';
import useCustomerSourceOptions from '../../utilities/useCustomerSourceOptions';
import SelectField from './SelectField';

export type GenderFieldProps = {
    label?: string;
    name: string;
};

const GenderField = ({ label, ...props }: GenderFieldProps) => {
    const { genders } = useCustomerSourceOptions();

    return <SelectField label={label} options={genders} {...props} clearable={false} />;
};

export default GenderField;
