import { isNil } from 'lodash/fp';
import React from 'react';
import { EventExternalSite } from '../../../schema';
import CsvCalculator from '../../CsvFlow/components/Calculator';
import { CalculatorStepValues } from '../../DraftFlow/steps/CalculatorStep';
import { FlowStep } from '../../utils/flow';
import getDealerIdFromFlowState from '../../utils/getDealerIdFromFlowState';
import { EventFlowState } from '../EventSubmitFlow';
import Calculator from '../components/Calculator';
import {
    bookFinderVehicle,
    BookFinderVehicleMutation,
    BookFinderVehicleMutationVariables,
} from '../components/Calculator/Calculator.graphql';

export type EventCalculatorStepValues = CalculatorStepValues & {
    hasTradeIn?: boolean;
    hasTestDrive?: boolean;
    isCalculatorEnabled?: boolean;
    appliedForFinancing?: boolean;
    appliedForInsurance?: boolean;
};

class EventCalculatorStep extends FlowStep<EventFlowState, EventCalculatorStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'calculator';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('eventCalculatorPage.label.step');
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        // only used to get thank you page, after signing with namirial
        // since kyc checking bank.isKycMandatory (to match BootstrapStep in backend)
        return !!application?.id;
    }

    public render(): React.ReactElement | null {
        const {
            calculator,
            snapshot,
            event,
            finderVehicle,
            hasTradeIn,
            hasTestDrive,
            isCalculatorEnabled,
            appliedForFinancing,
            csvConfigurator,
            variant,
            application,
        } = this.state;
        const dealerId = getDealerIdFromFlowState(this.state);

        if (!event) {
            throw new Error('Event missing in state');
        }

        if (event.setting.externalSite === EventExternalSite.ICC) {
            if (!csvConfigurator) {
                throw new Error('CSV Configurator missing in state');
            }

            if (!variant) {
                throw new Error('Variant missing in state');
            }

            return (
                <CsvCalculator
                    appliedForFinancing={application?.appliedForFinancing ?? appliedForFinancing}
                    csvConfigurator={csvConfigurator}
                    dealerId={dealerId}
                    event={event}
                    hasTestDrive={application?.hasTestDrive ?? hasTestDrive}
                    hasTradeIn={application?.hasTradeIn ?? hasTradeIn}
                    initialValues={calculator}
                    isCalculatorEnabled={isCalculatorEnabled}
                    onSubmit={this.submit}
                    snapshot={snapshot}
                    variant={variant}
                />
            );
        }

        if (!finderVehicle) {
            throw new Error('Finder Vehicle missing in state');
        }

        if (isNil(dealerId)) {
            throw new Error('dealer id missing from state');
        }

        return (
            <Calculator
                appliedForFinancing={
                    application?.appliedForFinancing || appliedForFinancing || event.setting.applyForFinancing
                }
                appliedForInsurance={!!application?.insuranceApplication || event.setting.isInsuranceEnabled}
                dealerId={dealerId}
                event={event}
                finderVehicle={finderVehicle}
                hasTestDrive={application?.hasTestDrive ?? hasTestDrive}
                hasTradeIn={!!application?.hasTradeIn ?? hasTradeIn}
                initialValues={calculator}
                isCalculatorEnabled={isCalculatorEnabled}
                onSubmit={this.submit}
                snapshot={snapshot}
            />
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute({
        calculator,
        insuranceCalculator,
        bank,
        financeProduct,
        promo,
        hasTestDrive,
        hasTradeIn,
        isCalculatorEnabled,
        appliedForFinancing,
        appliedForInsurance,
        variant,
    }: EventCalculatorStepValues) {
        const { apolloClient } = this;
        const { finderVehicle, country, csvConfigurator, event } = this.state;

        if (event?.setting.externalSite === EventExternalSite.ICC) {
            if (!csvConfigurator) {
                throw new Error('CSV Configurator missing in state');
            }

            // persist the bank and calculator, finance product, and variant
            this.stateStore.update({
                calculator,
                bank,
                financeProduct,
                snapshot: undefined,
                promo,
                hasTestDrive,
                hasTradeIn,
                variant,
                isCalculatorEnabled,
                appliedForFinancing,
            });

            return this.nextStep;
        }

        let { bookingId } = this.state;

        if (!finderVehicle) {
            throw new Error('Finder Vehicle missing in state');
        }

        if (!bookingId) {
            const { data: bookingData } = await apolloClient.mutate<
                BookFinderVehicleMutation,
                BookFinderVehicleMutationVariables
            >({
                mutation: bookFinderVehicle,
                variables: { id: finderVehicle.remoteId, countryId: country.id },
            });
            bookingId = bookingData?.result;
        }

        // persist the bank and calculator, finance product, and variant, booking id
        this.stateStore.update({
            calculator,
            insuranceCalculator,
            bank,
            financeProduct,
            snapshot: undefined,
            promo,
            hasTestDrive,
            hasTradeIn,
            variant,
            bookingId,
            isCalculatorEnabled,
            appliedForFinancing,
            appliedForInsurance,
        });

        return this.nextStep;
    }
}

export default EventCalculatorStep;
