import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import React, { useCallback } from 'react';
import { CalculatorInsuranceValues, CalculatorValues } from '../types';
import EditionField from './edition/CoeField';

export type CoeFieldProps = {
    fieldKey: string;
    labelTKey: string;
    isViewable: (context: CalculatorContext<CalculatorValues>) => boolean;
    financeContext: CalculatorContext<CalculatorValues>;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
};

const CoeField = ({ financeContext, insuranceContext, fieldKey, isViewable, labelTKey, ...props }: CoeFieldProps) => {
    const renderEdition = useCallback(
        (context, close) => (
            <EditionField
                close={close}
                financeContext={financeContext}
                insuranceContext={insuranceContext}
                labelTKey={labelTKey}
            />
        ),
        [financeContext, insuranceContext, labelTKey]
    );

    return (
        <displayFields.COEField {...props} fieldKey={fieldKey} labelTKey={labelTKey} renderEdition={renderEdition} />
    );
};

export default CoeField;
