import React from 'react';
import styled from 'styled-components';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { useContentTranslation } from '../../../../../i18n';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Block, VariantImage } from '../ui';
import noImg from '../../../../../assets/images/no-image.png';

export type IntroductionProps = {
    variant: VariantDataFragment;
    reservationInstruction?: EventDataFragment['setting']['reservationInstruction'];
};

const ImageContainer = styled(Block)`
    @media (max-width: ${breakpoints.sm}) {
        margin-bottom: 20px;
    }
`;

const IntroductionContainer = styled.div`
    grid-column: auto / span 2;
`;

const ReservationInstruction = styled.div`
    color: black;
    padding: 20px;
`;

const Introduction = ({ variant, reservationInstruction }: IntroductionProps) => {
    const { ct } = useContentTranslation();

    const imageElement = (
        <VariantImage alt={ct(variant?.name)} fallbackSrc={noImg} src={variant?.images[0]?.url || noImg} />
    );

    return (
        <IntroductionContainer>
            {/* eslint-disable-next-line react/no-danger */}
            {reservationInstruction && (
                <ReservationInstruction dangerouslySetInnerHTML={{ __html: reservationInstruction }} />
            )}
            <ImageContainer>{imageElement}</ImageContainer>
        </IntroductionContainer>
    );
};

export default Introduction;
