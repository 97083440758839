import { TFunction } from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BankPresetOption } from '../../schema';

const getTranslation = (t: TFunction, key: string, allowUndefined: boolean = true) => {
    const translation = t(key);
    if (translation !== key) {
        return translation;
    }

    return allowUndefined ? undefined : translation;
};

const useBankPresetLabel = (bankPresetOption?: BankPresetOption) => {
    const { t } = useTranslation();

    return useCallback(
        // use fallback key if translationKey is different from preset
        (translationKey: string, fallbackKey?: string) => {
            // Get from bank presetOption
            if (bankPresetOption) {
                const optionLowercased = bankPresetOption.toLocaleLowerCase();

                const usedTranslationKey = `bankPreset.${optionLowercased}.${translationKey}`;
                const translation = getTranslation(t, usedTranslationKey);
                if (translation) {
                    return translation;
                }
            }

            const defaultKey = `customerDetails.label.${fallbackKey ?? translationKey}`;

            return getTranslation(t, defaultKey, false);
        },
        [bankPresetOption, t]
    );
};

export default useBankPresetLabel;
