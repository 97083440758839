// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import { eventLeadOriginOptions, eventMediumOptions } from '../../../../utilities/constants/options';
import DateField from '../../../shared/form-v2/DateField';
import SelectField from '../../../shared/form-v2/SelectField';
import TextField from '../../../shared/form-v2/TextField';
import UserField from '../../../shared/form-v2/UserField';
import useTimeZones from '../../../utilities/useTimeZones';
import { EventFormData } from './EventForm';

const MainDetails = () => {
    const { t } = useTranslation();
    const timeZones = useTimeZones();

    const { form } = useContext(ReduxFormContext);
    const { setting, dealerIds }: Partial<EventFormData> = useSelector(getFormValues(form)) || {};

    return (
        <Portlet name={t('eventDetailsPage.subHeading.mainDetails')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label={t('eventDetailsPage.label.eventID')} name="identifier" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label={t('eventDetailsPage.label.eventName')} name="name" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label={t('eventDetailsPage.label.eventStatus')} name="status" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField label={t('eventDetailsPage.label.startDate')} name="period.start" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.startDateTimeZone')}
                            name="period.startTimeZone"
                            options={timeZones}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField label={t('eventDetailsPage.label.endDate')} name="period.end" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.endDateTimeZone')}
                            name="period.endTimeZone"
                            options={timeZones}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.leadOrigin')}
                            name="leadOrigin"
                            options={eventLeadOriginOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.medium')}
                            name="medium"
                            options={eventMediumOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label={t('eventDetailsPage.label.campaignID')} name="campaignId" disabled />
                    </div>
                    {setting?.allowPublicAccess && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <UserField
                                dealerIds={dealerIds}
                                label={t('eventDetailsPage.label.responsibleSalesPerson')}
                                name="defaultSalesPersonId"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label={t('eventDetailsPage.label.lastModified')} name="lastModified" disabled />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default MainDetails;
