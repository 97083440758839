// @ts-ignore
import { Modal, Actions, GrayButton, ActionsCenter } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '../ui/Notification';

type MultipleSessionModalProps = {
    onClose: () => void;
};

const MultipleSessionModal = ({ onClose }: MultipleSessionModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose} title={t('multipleSessionModal.title')} showTitle>
            <Info>{t('multipleSessionModal.message')}</Info>
            <Actions>
                <ActionsCenter>
                    <GrayButton onClick={onClose}>{t('multipleSessionModal.button.proceed')}</GrayButton>
                </ActionsCenter>
            </Actions>
        </Modal>
    );
};

export default MultipleSessionModal;
