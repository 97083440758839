import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../actions';
import { submitPayment, SubmitPaymentResponse } from '../../../../api/payGate';
import { startPayGatePaymentSession } from '../../../../components/routes/wip/PayGatePaymentCallback';
import { ApplicationFlowSource } from '../../../../schema';

export type PayGateFormProps = {
    token: string;
    applicationId: string;
    source: ApplicationFlowSource;
};

const PayGateForm = ({ token, applicationId, source }: PayGateFormProps) => {
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const [result, setResult] = useState<SubmitPaymentResponse>();
    const ref = useRef<HTMLButtonElement | null>(null);

    const submit = useCallback(async () => {
        if (token) {
            const result = await dispatch<Promise<SubmitPaymentResponse | undefined>>(
                attachLoading(submitPayment(token))
            );
            setResult(result);
        }
    }, [dispatch, token]);

    useEffect(() => {
        submit();
    }, [submit]);

    useEffect(() => {
        if (result && ref.current) {
            if (ref.current) {
                startPayGatePaymentSession(source, applicationId);
                ref.current?.click();
            }
        }
    }, [applicationId, result, source]);

    return (
        <form action={result?.url} method="POST">
            <input name="PAY_REQUEST_ID" type="hidden" value={result?.payRequestId} />
            <input name="CHECKSUM" type="hidden" value={result?.checksum} />
            <button ref={ref} style={{ display: 'none' }} type="submit">
                submit
            </button>
        </form>
    );
};

export default PayGateForm;
