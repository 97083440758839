import styled from 'styled-components';

export type ChatContentContainerProps = { isAlt?: boolean };

const ChatContentContainer = styled.div<ChatContentContainerProps>`
    float: ${props => (props.isAlt ? 'right' : 'left')};
    margin: 0 15px 15px;
    max-width: calc(100% - 125px);
    overflow-wrap: break-word;
`;

export default ChatContentContainer;
