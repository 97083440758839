import { BoxedDatePicker, BoxedWrapper } from '@appvantageasia/afc-ui';
import { parseISO, format, isSameDay } from 'date-fns';
import { pick, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createRef, Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Field, fieldInputPropTypes } from 'redux-form';
import TinyDatePicker from 'tiny-date-picker-customized';
import { TextInput } from './TextField';
import 'tiny-date-picker-customized/tiny-date-picker.css';

const cleanInputProps = pick(['name', 'value']);
const cleanProps = omit(['minDate', 'maxDate', 'input']);

class DateInput extends Component {
    static getDerivedStateFromProps({ input, t }) {
        const { value } = input;
        const dateFormat = t('dateFormats.dateFormat');
        const parsedValue = parseISO(value);

        return {
            value: parsedValue,
            input: { ...cleanInputProps(input), value: value && format(parsedValue, dateFormat), readOnly: true },
        };
    }

    constructor(props) {
        super(props);

        // we need a reference on the input
        this.inputRef = createRef();

        this.state = { value: null, input: null };
    }

    componentDidMount() {
        const {
            minDate,
            maxDate,
            input: { value: initialValue },
            t,
        } = this.props;

        const dateFormat = t('dateFormats.standardDateFormat');
        const ISOFormat = t('dateFormats.standardISOFormat');

        // create the picker instance
        this.picker = TinyDatePicker(this.inputRef.current, {
            format: value => format(value, dateFormat),
            mode: 'dp-below',
            max: maxDate,
            min: minDate,
        });

        // set its initial value
        if (initialValue) {
            this.picker.setState({ selectedDate: initialValue });
        }

        // listener on changes
        this.picker.on('select', (_, picker) => {
            const newValue = picker.state.selectedDate;
            const { value } = this.state;

            const {
                input: { onChange },
            } = this.props;

            if (!value || !isSameDay(value, newValue)) {
                // update redux value (ISO)
                onChange(!newValue ? null : format(newValue, ISOFormat));

                // update local value
                this.setState({ value: newValue });
            }
        });
    }

    componentDidUpdate() {
        const { value: nextValue } = this.state;
        const previousValue = this.picker.state.selectedDate;

        if (!previousValue || !nextValue || !isSameDay(previousValue, nextValue)) {
            this.picker.setState({ selectedDate: nextValue });
        }
    }

    componentWillUnmount() {
        this.picker.destroy();
    }

    render() {
        const { input } = this.state;

        return (
            <TextInput
                {...cleanProps(this.props)}
                input={input}
                inputComponent={BoxedDatePicker}
                inputRef={this.inputRef}
            />
        );
    }
}

DateInput.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    name: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

const DateField = props => <Field component={DateInput} {...props} />;

DateField.FullWidth = props => <Field component={DateInput} wrapperComponent={BoxedWrapper.FullWidth} {...props} />;

export default withTranslation()(DateField);
