import { useEffect, useState } from 'react';
import { useHeaderController } from '../shared/HeaderController';
import SearchHeader from '../shared/SearchHeader';

const useSearch = (key, initialValue = null) => {
    const [, { set, unset }] = useHeaderController();
    const [search, setSearch] = useState(initialValue);

    useEffect(() => {
        set(SearchHeader, { onChange: setSearch, key, initialValue });

        // unset the header on unmount
        return unset;
    }, [setSearch, set, unset, key, initialValue]);

    return search;
};

export default useSearch;
