import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getRuntimeSettings } from '../../../../selectors';
import { ButtonContainer, DetailsContainer } from '../Configurator/Timepiece';
import { SubTitle } from '../Configurator/shared/ui';
import { Row, SectionContainer } from './shared/ui';
import lessDetails from '../Configurator/assets/less-details.png';
import moreDetails from '../Configurator/assets/more-details.png';

const DetailsHeader = styled.span`
    text-decoration: underline;
    margin-top: 20px;
`;

type PackageSectionProps = {
    formatCurrency: (price: number) => string;
};

const PackageSection = ({ formatCurrency }: PackageSectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const [showDetails, setShowDetails] = useState(false);
    const handleShowDetails = useCallback(() => {
        setShowDetails(!showDetails);
    }, [showDetails]);

    return (
        <SectionContainer>
            <Row>
                <SubTitle>{ckdConfiguration.enhancedPackage.headline}</SubTitle>
                <span>{formatCurrency(ckdConfiguration.enhancedPackage.price)}</span>
            </Row>
            <DetailsContainer>
                <ButtonContainer onClick={handleShowDetails}>
                    {showDetails ? (
                        <>
                            <img alt="less details" src={lessDetails} />
                            <span>{ckdConfiguration.summary.hideDetails}</span>
                        </>
                    ) : (
                        <>
                            <img alt="more details" src={moreDetails} />
                            <span>{ckdConfiguration.summary.showDetails}</span>
                        </>
                    )}
                </ButtonContainer>
                {showDetails && (
                    <>
                        <DetailsHeader>{ckdConfiguration.summary.exterior}</DetailsHeader>
                        {ckdConfiguration.enhancedPackage.list.slice(0, 3).map((item: string[]) => (
                            <Row>
                                <span>{item}</span>
                                <span>{ckdConfiguration.summary.included}</span>
                            </Row>
                        ))}
                        <DetailsHeader>{ckdConfiguration.summary.interior}</DetailsHeader>
                        <Row>
                            <span>{ckdConfiguration.enhancedPackage.list[4]}</span>
                            <span>{ckdConfiguration.summary.included}</span>
                        </Row>
                        <DetailsHeader>{ckdConfiguration.summary.accessory}</DetailsHeader>
                        <Row>
                            <span>{ckdConfiguration.enhancedPackage.list[5]}</span>
                            <span>{ckdConfiguration.summary.included}</span>
                        </Row>
                    </>
                )}
            </DetailsContainer>
        </SectionContainer>
    );
};

export default PackageSection;
