import { TFunction } from 'i18next';
import { get, getOr, toNumber, filter, isEmpty, omit } from 'lodash/fp';
import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext, getFormValues } from 'redux-form';
import * as yup from 'yup';
import FileFieldArray from '../../../../../components/shared/form-v2/FileFieldArray';
import NumberField from '../../../../../components/shared/form-v2/NumberField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import CPFContributions from '../../../../../components/shared/partialForms/CPFContributions';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import validateFile from '../../../../../components/utilities/validateFile';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import { yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';

const watchedFields = [
    'details.employment.employer',
    'details.employment.occupation',
    'details.employment.assessableIncome',
    'details.employment.assessmentYear',
];

type OccupationFormProps = {
    disabled?: boolean;
    withFiles?: boolean;
    step: string;
};

const OccupationForm = ({ disabled, withFiles = false, step }: OccupationFormProps) => {
    const { sectionPrefix, values }: any = useContext(ReduxFormContext);
    const fieldState = useCustomerSource(watchedFields, sectionPrefix).details;
    const { t } = useTranslation();

    // get form values
    const { form } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);
    const formValues = useSelector(valueSelector);

    // get the employment section
    const cpfContributions = get(
        [sectionPrefix, 'details', 'employment', 'cpfContributions'].filter(Boolean),
        formValues
    );

    // check myInfo
    const withMyInfo = getOr(false, sectionPrefix ? `${sectionPrefix}.withMyInfo` : 'withMyInfo', values);

    return (
        <>
            <Title>
                {step === 'guarantor' ? t('kycPage.guarantorOccupationTitle') : t('kycPage.occupationDetailsTitle')}
            </Title>
            <TextField
                disabled={disabled || fieldState.employment.employer}
                label={t('customerDetails.label.employer')}
                name="details.employment.employer.value"
            />
            <TextField
                disabled={disabled || fieldState.employment.occupation}
                label={t('customerDetails.label.occupation')}
                name="details.employment.occupation.value"
            />
            <NumberField
                disabled={disabled || fieldState.employment.assessableIncome}
                label={t('customerDetails.label.assessableIncome')}
                name="details.employment.assessableIncome.value"
                type="currency"
            />
            <NumberField
                disabled={disabled || fieldState.employment.assessmentYear}
                label={t('customerDetails.label.assessmentYear')}
                maxLength="4"
                name="details.employment.assessmentYear.value"
                normalize={toNumber}
                type="year"
            />
            {!withMyInfo && withFiles && (
                <FileFieldArray
                    disabled={disabled}
                    forPurpose={
                        step === 'guarantor' ? UploadPurpose.GUARANTOR_ATTACHED : UploadPurpose.CUSTOMER_ATTACHED
                    }
                    label={t('customerDetails.label.occupationFiles')}
                    name="occupationFiles"
                    rerenderOnEveryChange
                />
            )}
            <CPFContributions history={cpfContributions} />
        </>
    );
};
export default OccupationForm;

// remove error message for required in events

export const detailsSchema = (t: TFunction) => ({
    employment: yupExt.customerProperty().shape({
        assessableIncome: yupExt
            .customerProperty()
            .shape({ value: yup.string().required(t('common.error.required')).nullable() }),
        assessmentYear: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .matches(/^[0-9]+$/, t('common.error.digitsOnly'))
                .min(4, t('common.error.exactDigits', { count: 4 }))
                .max(4, t('common.error.exactDigits', { count: 4 })),
        }),
        employer: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .max(124, t('common.error.lessThanOrEqual', { max: 124 })),
        }),
        occupation: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .max(100, t('common.error.lessThanOrEqual', { max: 100 })),
        }),
    }),
});

export const occupationFilesSchema = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((value: any, { parent, context }: any) => {
        const { withMyInfo } = parent;

        const isTTBBank = get('application.financeProduct.bank.presetOption', context) === BankPresetOption.TTBBANK;

        const validator = yup
            .array()
            .transform((currentValue: any) =>
                filter(item => {
                    if (!item) {
                        return false;
                    }

                    if (
                        item.purpose !== UploadPurpose.CUSTOMER_ATTACHED &&
                        item.purpose !== UploadPurpose.GUARANTOR_ATTACHED
                    ) {
                        // wrong purpose, skip it
                        return false;
                    }

                    // ensure it's not an empty item
                    return item instanceof File || !isEmpty(omit(['purpose'], item));
                }, currentValue)
            )
            .of(
                yup.mixed().test(
                    'extension-validation',
                    t('common.error.fileExtension'),
                    // @ts-ignore
                    validateFile
                )
            );

        if (withMyInfo || isTTBBank) {
            // it's not mandatory
            return validator;
        }

        return validator.min(1, t('common.error.filesRequired'));
    });
