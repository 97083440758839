import React, { useMemo } from 'react';
import { useCountry } from '../../../hookSelectors';
import { Channel, ChatbotType } from '../../../schema';
import { EventDataFragment } from '../../routes/EventRoute/EventRoute.graphql';
import Dialogflow from './Dialogflow';
import Hero from './Hero';
import Userlike from './Userlike';

type ChatbotProps = { channel: Channel; event?: EventDataFragment };

const Chatbot = ({ channel, event }: ChatbotProps) => {
    const { channelSetting } = useCountry();

    // get chatbot configuration
    const chatbotConfiguration = useMemo(() => {
        switch (channel) {
            case Channel.USED:
                return channelSetting.used.chatbot;

            case Channel.NEW:
                return channelSetting.new.chatbot;

            case Channel.EVENT:
                return event?.setting.isChatbotEnabled ? channelSetting.event.chatbot : null;

            default:
                return null;
        }
    }, [channelSetting, channel, event]);

    // nothing to show as there's no configuration
    if (!chatbotConfiguration) {
        return null;
    }

    // return chatbot based on configuration
    switch (chatbotConfiguration.type) {
        case ChatbotType.DIALOGFLOW:
            return <Dialogflow channel={channel} event={event} />;

        case ChatbotType.HERO:
            return <Hero applicationId={chatbotConfiguration.credentials} />;

        case ChatbotType.USERLIKE:
            return <Userlike widgetKey={chatbotConfiguration.credentials} />;

        default:
            return null;
    }
};

export default Chatbot;
