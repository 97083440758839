import { Channel } from '../schema';

export const getConfig = async (channel: Channel, zoneId: string, token: string) => {
    const response = await fetch(`/api/porsche/configuration?zoneId=${zoneId}&channel=${channel}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};

export const submitPayment = async (data: any, token: string) => {
    const response = await fetch('/api/porsche/payment', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};

export const getPaymentMethods = async (token: string) => {
    const response = await fetch('/api/porsche/paymentMethods', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};
