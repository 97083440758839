import React, { ReactElement, ReactNode, Children, useMemo } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../../utilities/constants/breakpoints';

export const Grid = styled.div<{ size: number; mediumSize: number; smallSize: number }>`
    display: grid;
    grid-template-columns: ${props => `repeat(${props.size}, 335px)`};
    grid-auto-flow: row;
    grid-gap: 47px;
    justify-content: center;

    @media (max-width: ${breakpoints.xl}) {
        grid-template-columns: ${props => `repeat(${props.mediumSize}, 1fr)`};
        grid-auto-flow: row;
    }

    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: ${props => `repeat(${props.smallSize}, 1fr)`};
        grid-auto-flow: row;
    }
`;

type KYCGridProps = {
    children: ReactElement | ReactNode;
    maxColumn?: number;
    mediumMaxColumn?: number;
    childrenLength?: number;
};
export const KYCGrid = ({
    children,
    maxColumn = 3,
    mediumMaxColumn = 2,
    childrenLength: childrenLengthGiven,
}: KYCGridProps) => {
    const grids = useMemo(() => {
        const childrenLength = childrenLengthGiven ?? Children.toArray(children).length;
        const size = childrenLength > maxColumn ? maxColumn : childrenLength;
        const mediumSize = childrenLength > mediumMaxColumn ? mediumMaxColumn : childrenLength;

        return {
            size,
            mediumSize,
            smallSize: 1,
        };
    }, [children, childrenLengthGiven, maxColumn, mediumMaxColumn]);

    return <Grid {...grids}>{children}</Grid>;
};

export const Page = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 22px auto 47px;
    padding: 0 15px;

    @media (max-width: ${breakpoints.sm}) {
        margin-top: 0;
    }

    @media (min-width: ${breakpoints.sm}) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (min-width: ${breakpoints.xl}) {
        padding: 0;
    }
`;

export const Title = styled.h1`
    font-size: 17.5px;
    margin-bottom: 10px;
    color: #000;
    margin-top: 10px;

    &:first-of-type {
        margin-top: 0;
    }
`;

export const SubTitle = styled(Title)`
    font-size: 14px;
`;

export const PageTitle = styled.h1`
    color: #444444;
    font-size: 32px;
    font-weight: bold;
    margin-top: 23px;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 18px;

    & ${Title} {
        margin-bottom: 0;
    }

    & > * {
        margin-right: 1rem;
    }
`;

export const MyInfoListWrap = styled.div`
    color: #000000;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    font-size: 16px;
`;

export const MyInfoList = styled.div`
    list-style-type: square;
    display: table;

    li {
        display: table-row;
        line-height: 18px;

        &::before {
            padding-right: 0.5rem;
            content: '▪︎';
            display: table-cell;
            text-align: right;
        }
    }
`;

export const MyInfoText = styled.div`
    font-size: 16px;
    color: #000000;
`;

export const SingpassWrap = styled.div`
    border: 1px solid #dddddd;
    padding: 20px;
    margin-top: 18px;

    svg {
        max-width: 115px;
    }
`;

export const SingpassButtonWrap = styled.div`
    display: inline-block;
    cursor: pointer;

    svg {
        max-width: none;
    }
`;

export const SingpassText = styled.div`
    font-size: 16px;
    color: #000000;
    margin: 10px 0;
`;

export const SingpassErrorText = styled.div`
    font-size: 16px;
    color: #f4333d;
    margin-top: 10px;
`;

export const ContactHint = styled.div`
    color: #000000;
    margin: 10px 0;
`;

export const CarOfInterestContainer = styled.div`
    border: 1px solid #dddddd;
    display: flex;
    height: fit-content;
    margin-bottom: 30px;

    div {
        width: 50%;
        height: auto;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            line-height: 28px;
            margin: 0;
            padding: 0 10px;
        }
    }

    .variantInfo {
        margin: auto;
    }
`;

export const CarOfInterestVariantName = styled.p`
    font-size: 18px;
    font-weight: bold;
`;

export const CarOfInterestVariantPrice = styled.p`
    font-size: 18px;
`;

export const MyInfoContainer = styled.div`
    margin-top: 30px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    div {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        hr {
            width: 100%;
            border-top-color: #ced4da;
        }
    }
`;
