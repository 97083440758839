import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext, getFormValues } from 'redux-form';
import * as yup from 'yup';
import { CustomerDetailsSource, LicenseType } from '../../../../schema';
import SubTitle from '../../../ui/SubTitle';
import DrivingLicense from '../DrivingLicense';

export type DrivingLicenseDetailsProps = {
    disabled?: boolean;
};

const DrivingLicenseDetails = ({ disabled }: DrivingLicenseDetailsProps) => {
    const { t } = useTranslation();
    const { sectionPrefix } = useContext(ReduxFormContext);
    const { form } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);

    const formValues = useSelector(valueSelector) || {};

    // @ts-ignore
    if (!get([sectionPrefix, 'details', 'drivingLicense', 'length'].filter(Boolean), formValues)) {
        return null;
    }

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.drivingLicense')}</SubTitle>
            <div className="row">
                <DrivingLicense disabled={disabled} />
            </div>
        </>
    );
};

export default DrivingLicenseDetails;

export const schema = (t: TFunction) => ({
    drivingLicense: yup
        .array()
        .of(
            // @ts-ignore
            yup.lazy((value, { context }) => {
                const countryCode = get('countryCode', context);
                if (get('source', value) === CustomerDetailsSource.MYINFO) {
                    return yup.mixed().notRequired();
                }

                const licenseSchema: any = {
                    type: yup.string().notRequired().nullable(),
                };

                const type = get('type', value);

                switch (type) {
                    case LicenseType.QUALIFIED:
                        licenseSchema.validity = yup.string().required(t('common.error.required'));
                        licenseSchema.expiryDate = yup.string().required(t('common.error.required'));

                        if (countryCode !== 'TH') {
                            licenseSchema.classes = yup.array().of(
                                yup.object().shape({
                                    issueDate: yup.string().required(t('common.error.required')),
                                    licenseClass: yup.string().required(t('common.error.required')),
                                })
                            );
                        }
                        break;

                    case LicenseType.PROVISIONAL:
                        licenseSchema.validity = yup.string().required(t('common.error.required'));
                        licenseSchema.expiryDate = yup.string().required(t('common.error.required'));

                        if (countryCode !== 'TH') {
                            licenseSchema.classes = yup.array().of(
                                yup.object().shape({
                                    licenseClass: yup.string().required(t('common.error.required')),
                                })
                            );
                        }
                        break;

                    default:
                        break;
                }

                return yup.object().shape(licenseSchema);
            })
        )
        .nullable()
        .notRequired(),
});
