import React from 'react';
import { updateCustomer, UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../api/customer.graphql';
import {
    completeGuarantor,
    completeGuarantorConsents,
    CompleteGuarantorConsentsMutation,
    CompleteGuarantorConsentsMutationVariables,
    CompleteGuarantorMutation,
    CompleteGuarantorMutationVariables,
} from '../../../api/draft.graphql';
import { ApplicationEventSource } from '../../../schema';
import { prepareForGraphQL } from '../../../utilities/forms';
import GuarantorPage from '../../DraftFlow/components/KYC/index';
import { KYCStepValues } from '../../DraftFlow/steps/KYCStep';
import { getFinancingConsents, getNonFinancingConsents } from '../../DraftFlow/utils/consents';
import { FlowStep } from '../../utils/flow';
import { uploadFiles } from '../../utils/uploads';
import { GuarantorFlowState } from '../GuarantorFlow';

class KYCStep extends FlowStep<GuarantorFlowState, KYCStepValues> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'kyc';
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.guarantorConfirmation || false;
    }

    protected async execute({ files, ...customerData }: KYCStepValues): Promise<FlowStep<GuarantorFlowState> | null> {
        const { apolloClient } = this;
        const { token, application, consents, channel } = this.state;
        // upload files
        await uploadFiles(
            apolloClient,
            [...files, ...application.attachments],
            application.attachments,
            null,
            application.id,
            token,
            ApplicationEventSource.GUARANTOR
        );

        // update the customer
        const { id: customerId, type, referenceId, ...data } = customerData;
        await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
            mutation: updateCustomer,
            variables: { id: customerId, data: prepareForGraphQL(data), token },
        });

        // complete consents, using the same consents with kyc
        const applyForFinancing = application.appliedForFinancing;
        const financingConsents = getFinancingConsents(consents, channel);
        const nonFinancingConsents = getNonFinancingConsents(consents, channel);
        if ((applyForFinancing && financingConsents.length) || (!applyForFinancing && nonFinancingConsents.length)) {
            await apolloClient.mutate<CompleteGuarantorConsentsMutation, CompleteGuarantorConsentsMutationVariables>({
                mutation: completeGuarantorConsents,
                variables: { token, eventId: referenceId },
            });
        }

        // then complete KYC
        const apiResponse = await apolloClient.mutate<CompleteGuarantorMutation, CompleteGuarantorMutationVariables>({
            mutation: completeGuarantor,
            variables: { token },
        });

        // update state
        this.stateStore.update({ ...apiResponse.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { bank, country, application, withMyInfoError, consents: allConsents, channel } = this.state;

        const consents = application?.appliedForFinancing
            ? getFinancingConsents(allConsents, channel)
            : getNonFinancingConsents(allConsents, channel);

        return (
            <GuarantorPage
                application={application}
                backStep={this.getBackContext()}
                bank={bank}
                consents={consents}
                country={country}
                initialValues={{ ...application?.guarantor, files: application.attachments }}
                isLastStep={this.isLastStep}
                onSubmit={this.submit}
                step="guarantor"
                withMyInfoError={withMyInfoError}
            />
        );
    }
}

export default KYCStep;
