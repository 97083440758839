import { isObject, entries, set, get, isEmpty } from 'lodash/fp';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getFormInitialValues, getFormValues } from 'redux-form';
import { ApplicationFormValues } from '../ApplicationForm';

const deepIsEqual = (source: any, otherSource: any) => {
    const values = entries(source);

    if (values.length !== entries(otherSource).length) {
        // we do not have the same number of entries so something is different
        return false;
    }

    for (const [key, value] of values) {
        // get the other value by using the key
        const otherValue = otherSource[key];

        if (isObject(value) || Array.isArray(value)) {
            // we need to do deeper comparison
            if (!deepIsEqual(value, otherValue)) {
                return false;
            }
        } else if (value !== otherValue) {
            return false;
        }
    }

    return true;
};

export const maskedFields = ['email', 'emailBis', 'phone', 'phoneBis', 'identityNumber'];

export const useCustomerChanged = (fields: string[] = maskedFields) => {
    // get both initial values and current values
    const initialValues = useSelector(getFormInitialValues('application')) as ApplicationFormValues;
    const values = useSelector(getFormValues('application')) as ApplicationFormValues;

    return useMemo(
        () =>
            fields.reduce(
                (curr, field) =>
                    set(
                        field,
                        get(`customer.${field}.value`, values) !== get(`customer.${field}.value`, initialValues),
                        curr
                    ),
                {}
            ),
        [fields, values, initialValues]
    );
};

const useFormChanged = () => {
    const hasChangedRef = useRef(false);

    // get both initial values and current values
    const initialValues = useSelector(getFormInitialValues('application')) as ApplicationFormValues;
    const values = useSelector(getFormValues('application')) as ApplicationFormValues;

    return useMemo(() => {
        // if it changed once, we don't need to test again
        if (!hasChangedRef.current) {
            //  because file fields is going to add an empty object
            // it will break our comparison
            // so we need to patch things up here
            const files = get('attachments', values);
            const cleanedFiles = files?.filter(file => file instanceof File || !isEmpty(file)) || null;
            const cleanedValues = set('attachments', cleanedFiles, values);

            // run the comparison
            hasChangedRef.current = !deepIsEqual(cleanedValues, initialValues);
        }

        return hasChangedRef.current;
    }, [values, initialValues]);
};

export default useFormChanged;
