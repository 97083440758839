// @ts-ignore
import { Document } from '@appvantageasia/afc-ui';
import { faImage, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
// @ts-ignore
import * as PDFJS from 'pdfjs-dist/webpack';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import { ApplicationData } from '..';
import { attachLoading } from '../../../../actions';
import { AttachmentDataFragment } from '../../../../api/attachment.graphql';
import { getAccessToken } from '../../../../selectors';
import { handleResponseError } from '../../../../utilities/forms';
import { InsuranceApplicationDataFragment } from '../../InsuranceRoute/data.graphql';
import { ApplicationFormValues } from '../ApplicationForm';
import { withModal, WithModalInjectedProps } from './ModalConsumers';
import { Category } from '.';

const { PreviewContainer, SVGBox, PreviewItem, Clear } = Document;

type Attachment = ApplicationFormValues['attachments'][0];

type Data = {
    loading?: boolean;
    img?: string;
    blob?: Blob;
};

const Store: {
    [source: string]: {
        [filename: string]: Data;
    };
} = {};

const getExtension = (filename: string) => {
    const index = filename.lastIndexOf('.');

    if (index !== -1) {
        return filename.slice(index + 1);
    }

    return filename;
};

const mapTypes: { [extension: string]: string } = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf',
};

const loadImage = (source: string, filename: string, reader: ReadableStreamDefaultReader<Uint8Array>) => {
    const buffer: Uint8Array[] = [];

    const readData = async (): Promise<Data> => {
        const { done, value } = await reader.read();

        if (value) {
            // push the data in the buffer
            buffer.push(value);
        }

        if (!done) {
            // read for more
            return readData();
        }

        // get its type
        const type = mapTypes[getExtension(filename)] || 'application/octet-stream';

        if (!Store[source]) {
            Store[source] = {};
        }

        if (!Store[source][filename]) {
            Store[source][filename] = {};
        }

        Store[source][filename] = {
            loading: false,
            blob: new Blob(buffer, { type }),
            img: URL.createObjectURL(new Blob(buffer, { type })),
        };

        return Store[source][filename];
    };

    return readData();
};

const useUrl = (file: Attachment, setLoading: (loading: boolean) => void) => {
    const {
        url,
        metaData: { filename },
        purpose,
    } = file;

    if (!purpose) {
        throw new Error('Purpose is missing in attachment state');
    }

    const [newUrl, setNewUrl] = useState<string | null>(null);

    const getPDFFirstPage = useCallback(
        async reader => {
            try {
                PDFJS.disableWorker = true; // due to CORS

                // get the page
                const pdf = await PDFJS.getDocument(reader.result).promise;
                const page = await pdf.getPage(1);

                // create the viewport and canvas
                const viewport = page.getViewport({ scale: 1.5 });
                const canvas = document.createElement('canvas');
                const canvasContext = canvas.getContext('2d');
                const renderContext = { canvasContext, viewport };

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // render it
                await page.render(renderContext).promise;

                // update the img url
                const pdfUrl = canvas.toDataURL();
                Store[purpose][filename].img = pdfUrl;

                setNewUrl(pdfUrl);
                setLoading(false);
            } catch (error) {
                console.error(error);
                // silently fail
                setNewUrl('');
                setLoading(false);
            }
        },
        [filename, purpose, setLoading]
    );

    const token = useSelector(getAccessToken);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!url) {
                    throw new Error('url missing in attachment state');
                }

                const headers = { Authorization: `Bearer ${token}` };
                const response = await fetch(url, { headers });

                if (!response.body) {
                    throw new Error('invalid response');
                }

                const reader = response.body.getReader();
                const image = await loadImage(purpose, filename, reader);

                if (getExtension(filename) === 'pdf' && image.blob) {
                    // we are going to read it again as a file
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(image.blob);
                    fileReader.onloadend = () => getPDFFirstPage(fileReader);

                    return;
                }

                if (image.img) {
                    setNewUrl(image.img);
                }

                setLoading(false);
            } catch (error) {
                console.error(error);
                // let's make it silent
                setNewUrl('');
                setLoading(false);
            }
        };

        fetchData();
    }, [filename, getPDFFirstPage, purpose, setLoading, url, token]);

    if (Store?.[purpose]?.[filename]) {
        return Store?.[purpose]?.[filename]?.img;
    }

    return newUrl;
};

export type DocumentPreviewProps = {
    document: Attachment;
    disabled?: boolean;
    // applicationId or appointment version id
    referenceId: string;
    files: AttachmentDataFragment[];
    name: string;
    removeMutation: (args: { referenceId: string; attachmentId: string }) => Promise<void>;
    showRemove: boolean;
};

const DocumentPreview = ({
    document,
    modal,
    disabled,
    referenceId,
    files,
    name,
    removeMutation,
    showRemove,
}: DocumentPreviewProps & WithModalInjectedProps) => {
    const { change } = useContext(ReduxFormContext);
    const dispatch = useDispatch();

    const {
        id: attachmentId,
        url,
        metaData: { filename },
        purpose,
    } = document;

    if (!purpose) {
        throw new Error('purpose is missing in attachment state');
    }

    const [loading, setLoading] = useState<boolean>(!!url);
    const newUrl = useUrl(document, setLoading);

    const download = useCallback(() => {
        const { blob } = Store[purpose][filename];

        if (blob) {
            FileSaver.saveAs(blob, filename);
        }
    }, [filename, purpose]);

    const remove = useCallback(() => {
        const promise = removeMutation({ referenceId, attachmentId })
            .then(() => {
                dispatch(
                    change(
                        name,
                        files.filter(item => item.id !== attachmentId)
                    )
                );
            })
            .catch(handleResponseError);

        dispatch(attachLoading(promise));
    }, [attachmentId, change, dispatch, files, name, referenceId, removeMutation]);

    return (
        <PreviewItem>
            <PreviewContainer>
                {loading && (
                    <div className="svg-box">
                        <FontAwesomeIcon className="loading" icon={faSpinner} size="2x" spin />
                    </div>
                )}
                {/* eslint-disable-next-line  */}
                {!loading && newUrl && <img alt={filename} onClick={download} src={newUrl} title={filename} />}
                {!loading && !newUrl && (
                    <SVGBox onClick={download}>
                        <FontAwesomeIcon className="default-image" icon={faImage} />
                    </SVGBox>
                )}
            </PreviewContainer>
            {!disabled && showRemove && newUrl && (
                <Clear
                    onClick={() =>
                        modal.confirm({
                            title: '',
                            content: 'Do you want to remove this?',
                            options: [
                                { label: 'No', action: () => null },
                                { label: 'Yes', action: remove },
                            ],
                        })
                    }
                >
                    <div className="svg-box">
                        <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                    </div>
                </Clear>
            )}
        </PreviewItem>
    );
};

export default withModal(DocumentPreview);
