import { FinanceDataFragment } from '../../components/data/useFinanceProducts.graphql';
import { InsuranceCompanyDataFragment } from '../../components/data/useLoadInsuranceCompany.graphql';
import { PromoDataFragment } from '../../components/data/useLoadPromo.graphql';
import { VariantDataFragment } from '../../components/data/useLoadVariants.graphql';
import { ApplicationDataFragment } from '../../components/routes/ApplicationRoute/data.graphql';
import { CsvConfiguratorDataFragment } from '../../components/routes/CsvRoute/data.graphql';
import { EventDataFragment } from '../../components/routes/EventRoute/EventRoute.graphql';
import { FinderVehicleDataFragment } from '../../components/routes/FinderRoute/data.graphql';
import { getAydenSession } from '../../components/routes/wip/AydenCallback';
import { getMyInfoSession } from '../../components/routes/wip/MyInfoCallback';
import { getPayGatePaymentSession } from '../../components/routes/wip/PayGatePaymentCallback';
import { getPorschePaymentSession } from '../../components/routes/wip/PorschePaymentCallback';
import { getTTBPaymentSession } from '../../components/routes/wip/TTBPaymentCallback';
import { CalculatorInsuranceValues, CalculatorValues } from '../../components/shared/calculator-next/types';
import {
    ApplicationFlowSource,
    BankSigningMode,
    Channel,
    EventExternalSite,
    InsuranceSigningMode,
    PaymentProviderType,
} from '../../schema';
import { MiniConfiguratorDetails } from '../ConfiguratorFlow/components/Summary/Summary';
import { Application, Bank, Company, Country, Zone } from '../DraftFlow';
import { Flow, FlowStep } from '../utils/flow';
import * as flowSteps from './steps';
import { CarOfInterestType } from './steps/EventDraftStep';

export type ReservationLocationState = {
    reservation: ApplicationDataFragment;
};

export type EventFlowState = {
    // channel
    channel: Channel;
    // dealer id
    dealerId?: string;
    // should we skip the early stage
    skipEarlyStages?: boolean;
    // company document (mandatory)
    company: Company;
    // bank document (provided after submitting the calculator)
    bank?: Bank;
    // finance product document (provided after submitting the calculator)
    financeProduct?: FinanceDataFragment;
    // application document (provided after submitting a draft)
    application?: Application;
    // promotion code document (optional)
    promo?: PromoDataFragment | null;
    // country document (mandatory)
    country: Country;
    // zone document (mandatory)
    zone: Zone;
    // calculator settings (provided after submitting the calculator)
    calculator?: Partial<CalculatorValues>;
    // we may have a snapshot if it comes from a lead
    snapshot?: any;
    // lead ID (optional if coming from a lead)
    leadId?: string;
    // token (provided after draft creation)
    token?: string;
    // payment result (provided once payment is done)
    paymentResult?: any;
    // proceeding with my info errors (if MyInfo step failed)
    withMyInfoError?: boolean;
    // source flow
    source: ApplicationFlowSource;
    // event
    event?: EventDataFragment;
    variant?: VariantDataFragment;
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    carOfInterest?: CarOfInterestType;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    finderVehicle?: FinderVehicleDataFragment;
    bookingId?: string;
    isCalculatorEnabled?: boolean;
    appliedForFinancing?: boolean;

    // csv configurator
    csvConfigurator?: CsvConfiguratorDataFragment;

    // reservation reference
    reservation?: ApplicationDataFragment;

    // apply for insurance
    appliedForInsurance?: boolean;

    // insurance calculator
    insuranceCalculator?: Partial<CalculatorInsuranceValues>;

    // insurance company
    insuranceCompany?: InsuranceCompanyDataFragment;
};

export type Event = EventDataFragment;
export type EventFlowStep = FlowStep<EventFlowState>;

class EventFlow extends Flow<EventFlowState> {
    protected initialize(): FlowStep<EventFlowState> {
        const { application } = this.state;

        // there's no application yet when retrieving myInfo
        if (getMyInfoSession('', ApplicationFlowSource.EVENT)) {
            const step = this.getStep('myInfo');

            if (step) {
                return step;
            }
        }

        if (application) {
            if (this.getPaymentSession(application.version.id)) {
                const step = this.getStep('consentDeposit');

                if (step) {
                    return step;
                }
            }

            for (const step of this.steps) {
                if (!step.isShadowStep && !step.isCompleted) {
                    return step;
                }
            }
        }

        return super.initialize();
    }

    private getPaymentSession(id: string) {
        const { event } = this.state;

        switch (event?.setting.bookingPayment?.provider.type) {
            case PaymentProviderType.ADYEN:
                return getAydenSession(id, ApplicationFlowSource.EVENT);

            case PaymentProviderType.PORSCHE:
                return getPorschePaymentSession(id, ApplicationFlowSource.EVENT);

            case PaymentProviderType.PAYGATE:
                return getPayGatePaymentSession(id, ApplicationFlowSource.EVENT);

            case PaymentProviderType.TTB:
                return getTTBPaymentSession(id, ApplicationFlowSource.EVENT);

            default:
                return null;
        }
    }

    protected plannify(): FlowStep<EventFlowState>[] {
        // there's always the calculator and draft step
        const steps: FlowStep<EventFlowState>[] = [];

        const {
            application,
            skipEarlyStages = false,
            event,
            finderVehicle,
            appliedForFinancing,
            bank,
            csvConfigurator,
            reservation,
        } = this.state;

        const eventSetting = event?.setting;
        const hasDeposit = !!eventSetting?.isDepositPaymentMandatory;

        if (!application || !skipEarlyStages) {
            if (
                (finderVehicle && eventSetting?.externalSite === EventExternalSite.PORSCHEFINDER) ||
                (csvConfigurator && eventSetting?.externalSite === EventExternalSite.ICC)
            ) {
                steps.push(this.createStep(flowSteps.EventCalculatorStep));
            }

            steps.push(this.createStep(flowSteps.EventDraftStep));

            if (eventSetting?.myInfo) {
                // there's my info integration
                steps.push(this.createStep(flowSteps.EventMyInfoStep));
                steps.push(this.createStep(flowSteps.EventApplicantStep));
            }
        }

        if (!application) {
            // nothing else yet
            return steps;
        }

        if (!reservation) {
            if (hasDeposit) {
                steps.push(this.createStep(flowSteps.EventConsentDepositStep));
            }

            if (application.hasTestDrive && eventSetting?.isAppointmentEnabled) {
                steps.push(this.createStep(flowSteps.EventAppointmentStep));
            }
        }

        let pushedNamirialStep = false;
        if (appliedForFinancing) {
            switch (bank?.signing.onCreate) {
                case BankSigningMode.NAMIRIAL:
                    pushedNamirialStep = true;
                    steps.push(this.createStep(flowSteps.EventNamirialStep));
                    break;

                case BankSigningMode.OTP:
                    steps.push(this.createStep(flowSteps.EventOTPStep));
                    break;

                case BankSigningMode.NONE:
                default:
                    // do nothing
                    break;
            }
        }

        if (application.insuranceApplication) {
            const { insuranceApplication } = application;
            if (
                !pushedNamirialStep &&
                insuranceApplication.insuranceCompany.signing.onCreate === InsuranceSigningMode.NAMIRIAL
            ) {
                steps.push(this.createStep(flowSteps.EventInsuranceNamirialStep));
            }
        }

        const allSteps = [...steps, this.createStep(flowSteps.EventThankYouStep)];

        return allSteps;
    }
}

export default EventFlow;
