import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React, { createContext, useCallback, useMemo, useContext, useState, ReactNode } from 'react';
import styled from 'styled-components';
import ConfirmModal, { ConfirmOption } from './ConfirmModal';

export const ErrorContext = createContext<[string | null, (error: string | null) => void] | null>(null);

export const useErrorContext = () => {
    const context = useContext(ErrorContext);

    if (!context) {
        throw new Error('Error context not provided');
    }

    return context;
};

export type GlobalErrorProviderProps = {
    children: JSX.Element | ReactNode;
};

export const GlobalErrorProvider = ({ children }: GlobalErrorProviderProps) => {
    const state = useState<string | null>(null);

    return <ErrorContext.Provider value={state}>{children}</ErrorContext.Provider>;
};

const ErrorBlock = styled.div`
    color: red;
    padding: 0 30px 10px 30px;
    background-color: black;
    flex: initial;
    height: 25px;
`;

export const GlobalError = () => {
    const [error] = useErrorContext();

    return error && <ErrorBlock>{error}</ErrorBlock>;
};

export type State = {
    content: string;
    title: string;
    options: ConfirmOption[];
};

export type ModalContextData = {
    confirm: (state: State) => void;
};

export const ModalContext = createContext<null | ModalContextData>(null);

export type ModalProviderProps = {
    profile?: string;
    children: JSX.Element | ReactNode;
};

const ModalProvider = ({ children, profile = 'admin' }: ModalProviderProps) => {
    const [, setError] = useErrorContext();

    const [confirmModal, setConfirmModal] = useState<State | null>(null);

    const closeConfirm = useCallback(() => {
        setConfirmModal(null);
    }, [setConfirmModal]);

    const onConfirmAction = useCallback(
        option => {
            setConfirmModal(null);

            const response = option.action();

            if (response instanceof Promise) {
                response.catch(error => {
                    if (error instanceof ApolloError) {
                        // display a modal for it
                        setConfirmModal({
                            title: 'Error',
                            content: error.graphQLErrors[0].message,
                            options: [{ label: 'ok', action: () => null }],
                        });

                        // and hide it in the head menu
                        setError(null);

                        return null;
                    }

                    // simply print it
                    console.error(error);

                    // then capture it
                    Sentry.captureException(error);

                    return null;
                });
            }
        },
        [setConfirmModal, setError]
    );

    const context = useMemo(() => ({ confirm: setConfirmModal }), [setConfirmModal]);

    return (
        <ModalContext.Provider value={context}>
            {confirmModal && (
                <ConfirmModal
                    alertContent={confirmModal.content}
                    alertTitle={confirmModal.title}
                    onCloseClicked={closeConfirm}
                    onOptionSelected={onConfirmAction}
                    options={confirmModal.options}
                    profile={profile}
                    isOpen
                />
            )}
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
