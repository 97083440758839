import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import DrawerContainer from '../ui/DrawerContainer';
import DrawerOverlay from '../ui/DrawerOverlay';
import { useFixedMenuController } from './FixedMenuController';

const Drawer = ({ children, open = false, onClose }) => {
    const containerRef = useRef(null);
    const { fixedMenu } = useFixedMenuController();

    if (containerRef.current === null) {
        containerRef.current = document.createElement('div');
    }

    useEffect(() => {
        document.body.appendChild(containerRef.current);

        return () => {
            document.body.removeChild(containerRef.current);
        };
    }, []);

    const drawer = createPortal(
        <>
            {!fixedMenu && <DrawerOverlay onClick={onClose} open={open} />}
            <DrawerContainer fixedMenu={fixedMenu} onMouseLeave={onClose} open={open}>
                {children}
            </DrawerContainer>
        </>,
        containerRef.current
    );

    return drawer;
};

Drawer.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

export default Drawer;
