import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getCompanyIdentifier } from '../../selectors';
import { getCompanyRootUrl } from '../../utilities/urls';

const RedirectToCompanyHome = () => {
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    return <Redirect to={getCompanyRootUrl(companyCode, locationCode)} />;
};

export default RedirectToCompanyHome;
