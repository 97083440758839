import { get } from 'lodash/fp';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useFirstFinanceProduct from '../../../../components/data/useFirstFinanceProduct';
import useMustExistDealerIdFromTenantContext from '../../../../components/data/useMustExistDealerIdFromTenantContext';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { CalculatorFooter } from '../../../../components/ui/calculator';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { useGetCalculatorFooterText } from '../../../../utilities/calculator';
import useDealerEstablishment from '../../../utils/useDealerEstablishment';
import Comparison from './Comparison';
import Single from './Single';

export type PageProps = {
    initialValues: Partial<CalculatorValues>;
    dealerId: string;
    snapshot?: any;
};

export const cloneCalculatorValues = (value: Partial<CalculatorValues | CalculatorInsuranceValues>) => {
    if (value?.unitId) {
        const unitId = uuidv4();

        return { ...value, unitId };
    }

    return value;
};

const Page = ({ initialValues, dealerId, snapshot }: PageProps) => {
    if (!initialValues.variant) {
        throw new Error('Car model missing in initial values');
    }
    const country = useCountry();
    const { formatPath } = useContentTranslation();

    const [calculators, setCalculators] = useState<Partial<CalculatorValues>[]>([initialValues]);

    const fallbackFinanceProduct = useFirstFinanceProduct(Channel.NEW, dealerId);

    const selectedBankEstablishment = fallbackFinanceProduct?.bank.establishment;

    const { selectedDealerEstablishment, loading } = useDealerEstablishment(dealerId as string, Channel.NEW);

    const getCalculatorFooterText = useGetCalculatorFooterText();

    const calculatorFooterText = getCalculatorFooterText(
        get(formatPath('channelSetting.new.priceDisclaimer'), country),
        country?.coe?.amount,
        country?.ppsr?.amount,
        country?.establishment?.amount,
        country?.luxuryTax?.amount,
        selectedDealerEstablishment,
        selectedBankEstablishment
    );

    const renderCalculator = () => {
        if (calculators.length > 1) {
            // we are going to run comparison
            return (
                <>
                    <Comparison
                        calculators={calculators}
                        dealerId={dealerId}
                        fallbackFinanceProduct={fallbackFinanceProduct}
                        setCalculators={setCalculators}
                    />
                    <CalculatorFooter dangerouslySetInnerHTML={{ __html: calculatorFooterText || '' }} />
                </>
            );
        }

        // we render a single calculator display
        return (
            <Single
                dealerId={dealerId}
                fallbackFinanceProduct={fallbackFinanceProduct}
                initialValues={calculators[0]}
                selectedDealerEstablishment={selectedDealerEstablishment}
                setCalculators={setCalculators}
                snapshot={snapshot}
            />
        );
    };

    if (loading) {
        return null;
    }

    return renderCalculator();
};

export default Page;
