import styled from 'styled-components';

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > * {
        margin-bottom: 1rem;
    }
`;

export const StatusIcon = styled.img`
    height: 100px;
    display: block;
`;

export const Message = styled.label`
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
`;

const AdyenContainer = styled.div<{ disabled?: boolean }>`
    .adyen-checkout__payment-method {
        /* Payment method container */
        background: transparent;
        border: 0;
    }

    .adyen-checkout__payment-method--selected {
        /* Payment method that has been selected */
    }
    .adyen-checkout__payment-method__header {
        /* Payment method icon and name */
        width: 482px;
        max-width: 100%;
        margin-left: -16px;
    }

    @media (max-width: 450px) {
        /* Payment method icon and name mobile size */
        .adyen-checkout__payment-method__header {
            width: 377px;
            margin-left: -16px;
        }
    }

    .adyen-checkout__payment-method__radio {
        /* Radio button in payment method header */
    }
    .adyen-checkout__payment-method__radio--selected {
        /* Selected radio button in payment method header */
        background: ${props => props.theme.themeHighlightColour};
    }

    .adyen-checkout__payment-method__details {
        padding: 0;
    }

    .adyen-checkout__payment-method__name {
        /* Payment method name in the payment method header */
    }
    .adyen-checkout__spinner__wrapper {
        /* Spinning icon */
    }
    .adyen-checkout__button {
        /* Buttons */
    }
    .adyen-checkout__button--pay {
        /* Pay button */
        background: black;
        border-radius: 0;
        height: 60px;
        font-size: 1.35rem;
        text-transform: ${props => props.theme.casing};
        pointer-events: ${props => (props.disabled ? 'none' : 'all')};
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
        opacity: ${props => (props.disabled ? '45%' : '100%')};
        color: ${props => (props.disabled ? '#7e7e7e' : 'white')};
    }

    .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
        margin-right: 2.5px;
    }

    .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
        margin-left: 2.5px;
    }

    .adyen-checkout__field {
        /* Form field container */
        border: 1px solid #ced4da;
        min-height: 60px;
        box-sizing: border-box;
        margin-bottom: 5px;
    }
    .adyen-checkout__label {
        /* Form label container */
    }

    .adyen-checkout__label__text {
        /* Text element inside the form label container */
        color: #666666;
        font-size: 0.78rem;
        padding: 10px 15px 0;
        text-transform: ${props => props.theme.casing};
    }

    .adyen-checkout__input {
        /* Input fields */
        border-radius: 0;
        border: 0;
        box-shadow: none;
        height: 22px;
        padding: 0 15px 0;
        font-size: 1.21rem;
    }

    .adyen-checkout__label--focused {
        box-shadow: none;

        .adyen-checkout__input {
            box-shadow: none;
            border: 0;
        }
    }

    .adyen-checkout__input--error {
        /* Error state for the input fields */
    }

    .adyen-checkout__error-text {
        /* Error message text */
        padding: 0 15px 10px;
    }
    .adyen-checkout__card__cardNumber__input {
        /* Input field for the card number */
    }
    .adyen-checkout__field--expiryDate {
        /* Input field for the expiry date */
    }
    .adyen-checkout__field__cvc {
        /* Input field for the CVC security code */
    }
    .adyen-checkout__card__holderName {
        /* Input field for card holder name */
        margin-top: 5px;
    }
    .adyen-checkout__checkbox__input {
        /* Checkboxes */
    }
    .adyen-checkout__checkbox__label {
        /* Checkbox labels */
    }
    .adyen-checkout__radio_group__input {
        /* Radio buttons */
    }
    .adyen-checkout__dropdown__button {
        /* Dropdown button showing list of options */
    }
    .adyen-checkout__dropdown__list {
        /* Dropdown list */
    }
    .adyen-checkout__dropdown__element {
        /* Elements in the dropdown list */
    }
    .adyen-checkout__link {
        /* Links */
    }
`;

export default AdyenContainer;
