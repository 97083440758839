import { GLOBAL_ERROR_SET, GLOBAL_ERROR_UNSET, GlobalErrorActions } from '../actions/globalErrors';

export type GlobalErrorState = Error | null;

const reducer = (state: GlobalErrorState = null, action: GlobalErrorActions): GlobalErrorState => {
    switch (action.type) {
        case GLOBAL_ERROR_SET:
            return action.error;

        case GLOBAL_ERROR_UNSET:
            return null;

        default:
            return state;
    }
};

export default reducer;
