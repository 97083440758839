import styled from 'styled-components';

const ChatReply = styled.div`
    cursor: pointer;
    background-color: #fff;
    border-radius: 15px;
    display: inline-block;
    margin: 0 5px;
    padding: 10px;
    border: 1px solid #ddd;

    &:hover,
    &:active,
    &:focus {
        background-color: #ddd;
    }
`;

export default ChatReply;
