// @ts-ignore
import { Actions as BaseActions } from '@appvantageasia/afc-ui';
import styled from 'styled-components';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { PrimaryButton } from '../Calculator/ui';

// eslint-disable-next-line import/prefer-default-export
export const Actions = styled(BaseActions)`
    margin: 0;

    & > * {
        margin: 0;

        @media (min-width: ${breakpoints.md}) {
            max-width: calc(50% - 47px / 2);
        }

        @media (min-width: ${breakpoints.xl}) {
            margin-left: auto;
            max-width: 335px;
        }
    }

    ${PrimaryButton} {
        height: 48px;
        font-size: 1rem;
    }
`;
