import React from 'react';
import useBankPresetStep from '../../../../components/utilities/useBankPresetStep';
import { BankPresetOption } from '../../../../schema';
import KYCForAFC, { PageProps as AFCPageProps } from './AFC';
import KYCForPME, { PageProps as PMEPageProps } from './PME';

type KYCPageType = (props: AFCPageProps | PMEPageProps) => JSX.Element;

const KYCPage = ({ application, ...props }: AFCPageProps | PMEPageProps) => {
    const Page = useBankPresetStep<KYCPageType>(
        {
            [BankPresetOption.ENBDBANK]: KYCForPME,
            [BankPresetOption.PMESA]: KYCForPME,
            [BankPresetOption.AFFINBANK]: KYCForAFC,
            [BankPresetOption.NONE]: KYCForAFC,
            [BankPresetOption.TTBBANK]: KYCForAFC,
        },
        application?.financeProduct?.bank?.presetOption
    );

    return <Page application={application} {...props} />;
};

export default KYCPage;
