// @ts-ignore
import { ActivityLogContainer, HeadTitle } from '@appvantageasia/afc-ui';
import { map, orderBy, flow, filter } from 'lodash/fp';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useContentTranslation } from '../../../i18n';
import {
    ApplicationEventDocumentDelete,
    ApplicationEventDocumentUpload,
    ApplicationEventSource,
    ApplicationEventType,
    ConsentDeclarationEvent,
} from '../../../schema';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';
import { BaseActivityLog } from '../ApplicationRoute/ActivityLog';
import { InsuranceApplicationDataFragment } from './data.graphql';
import { InsuranceApplicationData } from './index';

type Event = InsuranceApplicationData['events'][0];

type ComputedEvent = Event & { label: string | JSX.Element };

const withBy = (activity: string, author?: string | null) => (author ? `${activity} by ${author}` : activity);

const withComment = (activity: string, comment?: string | null) => (comment ? `${activity} - ${comment}` : activity);

const formatSource = (item: Event, insuranceCompany: string | undefined) => {
    switch (item.source) {
        case ApplicationEventSource.USER:
            return item.by?.name;

        case ApplicationEventSource.CUSTOMER:
            return 'Customer';

        case ApplicationEventSource.SYSTEM:
            return 'System';

        case ApplicationEventSource.INSURANCE:
            return insuranceCompany || '';

        default:
            return null;
    }
};

const HeadBar = styled.div`
    min-height: 64.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LinkButton = styled.button`
    text-decoration: underline;
    margin-left: 3px;
    background: none;
    border: none;
    color: ${props => (props.disabled ? '#9c9c9c' : '#069')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const Title = styled(HeadTitle)`
    padding-left: 0;
`;

const formatActivity = (
    item: Event,
    insuranceCompanyName: string | undefined,
    firstSubmit: boolean,
    formatCurrencyDown: (value: any, unit?: string) => string
): string | JSX.Element | null => {
    const author = formatSource(item, insuranceCompanyName);

    switch (item.type) {
        case ApplicationEventType.SHARE:
            return withBy('Shared', author);

        case ApplicationEventType.DRAFT:
            return withBy('Draft is saved', author);

        case ApplicationEventType.CHECK: {
            const { identifier } = item.detail as ConsentDeclarationEvent;

            return withBy(`Consent ${identifier} Checked`, author);
        }

        case ApplicationEventType.SIGNING:
            return withBy(`Signing Initiated`, author);

        case ApplicationEventType.SIGN:
            return withBy(`Signing Completed`, author);

        case ApplicationEventType.SUBMIT:
            if (!firstSubmit) {
                return withBy(`Re-submitted`, author);
            }

            return withBy(`Submitted`, author);

        case ApplicationEventType.RECEIVE:
            return withBy(`Received`, author);

        case ApplicationEventType.APPROVE: {
            const activity = withBy(`Approved`, author);
            // @ts-ignore
            const approvedAmount: number | undefined = item.detail?.approvedAmount;

            if (!approvedAmount) {
                return activity;
            }

            return `${activity} - ${formatCurrencyDown(approvedAmount)}`;
        }

        case ApplicationEventType.DECLINE:
            return withBy(`Declined`, author);

        case ApplicationEventType.CANCEL:
            return withBy(`Cancelled`, author);

        case ApplicationEventType.SUBMISSIONFAIL:
            return withComment(`Submission Failed to Insurance`, item.comment);

        case ApplicationEventType.UNABLETOSUBMIT: {
            return withComment(`Unable to Submit to Insurance`, item.comment);
        }

        case ApplicationEventType.ACTIVATE:
            return withBy(`Activated`, author);

        case ApplicationEventType.COMPLETE:
            return withBy(`Completed`, author);

        case ApplicationEventType.SIGNREJECT:
            return withBy(`Signing Rejected`, author);

        case ApplicationEventType.DOCUMENTUPLOAD: {
            const { filename } = item.detail as ApplicationEventDocumentUpload;

            return withBy(`Document ${filename} uploaded`, author);
        }

        case ApplicationEventType.DOCUMENTDELETE: {
            const { filename } = item.detail as ApplicationEventDocumentDelete;

            return withBy(`Document ${filename} deleted`, author);
        }

        case ApplicationEventType.SIGNTIMEOUT:
            return withComment(`Signing Timeout`);

        case ApplicationEventType.UNABLETOCONNECT:
            return withComment(`Unable to Connect`);

        case ApplicationEventType.CONNECTIONFAIL:
            return withComment(`Connection Failed`);

        case ApplicationEventType.EMAIL: {
            // @ts-ignore
            const { kind, receiver } = item.detail;

            if (!kind || !receiver) {
                return null;
            }

            return withBy(`Email sent to ${kind}: ${receiver}`, author);
        }

        case ApplicationEventType.ASSIGN: {
            // @ts-ignore
            const { kind, from, to } = item.detail;

            if (!kind || !to) {
                return null;
            }

            const assignedTo = `${kind} is assigned to ${to}`;

            if (from) {
                return `${assignedTo} from ${from}`;
            }

            return assignedTo;
        }

        case ApplicationEventType.COMMENT: {
            // @ts-ignore
            const { comment } = item.detail;

            const information = withBy(`Comment added by`, author);

            return (
                <div style={{ margin: '10px 0' }}>
                    {information}: <br />
                    {comment}
                </div>
            );
        }

        default:
            // skip it
            return null;
    }
};

export type ActivityLogProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
};

const ActivityLog = ({ insuranceApplication }: ActivityLogProps) => {
    const { formatCurrencyDown } = useCompanyFormatting();
    const { ct } = useContentTranslation();
    const { events, insuranceCompany } = insuranceApplication;

    const activities: ComputedEvent[] = useMemo(() => {
        let firstSubmit = true;

        return flow([
            map((item: Event): ComputedEvent | null => {
                const entry = formatActivity(item, ct(insuranceCompany.name), firstSubmit, formatCurrencyDown);

                if (entry === null) {
                    // we skip it
                    return null;
                }

                if (item.type === ApplicationEventType.SUBMIT) {
                    firstSubmit = false;
                }

                return { ...item, label: entry };
            }),
            filter(Boolean),
            orderBy('at', 'desc'),
        ])(events);
    }, [events, formatCurrencyDown]);

    if (!activities.length) {
        return null;
    }

    return <BaseActivityLog activities={activities} id={insuranceApplication.id} />;
};

export default ActivityLog;
