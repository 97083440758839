import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../../../components/shared/form/CheckboxField';
import InfoTooltip from '../../../../components/ui/tooltip/InfoTooltip';
import { Channel } from '../../../../schema';
import { CheckboxRow } from '../../../EventFlow/components/Calculator/blocks/styles';

type CalculatorTranslationContent = {
    title: string;
    content: string;
};

export const FinanceCalculatorCheckboxLabel = () => {
    const { t } = useTranslation();

    const financeCalculatorTooltip: CalculatorTranslationContent = t('eventCalculatorPage.financeCalculator', {
        returnObjects: true,
    });

    const hasTooltip = financeCalculatorTooltip?.title && financeCalculatorTooltip?.content;

    return (
        <span style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {t('eventCalculatorPage.checkbox.financeCalculator')}
            {hasTooltip && (
                <InfoTooltip content={financeCalculatorTooltip.content} title={financeCalculatorTooltip.title} />
            )}
        </span>
    );
};

export type FinancingFormProps = {
    allowTradeIn?: boolean;
    allowTestDrive?: boolean;
    page?: 'eventCalculatorPage' | 'eventDraftPage';
};

type TradeInContent = {
    title?: string;
    content?: string;
};

const TradeInLabel = ({ page }: FinancingFormProps) => {
    const { t } = useTranslation();

    const tradeInTooltip: TradeInContent = t('eventCalculatorPage.tradeIn', { returnObjects: true });

    const hasTooltip = tradeInTooltip?.title && tradeInTooltip?.content;

    return (
        <span style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {t(`${page}.checkbox.tradeIn`)}
            {hasTooltip && <InfoTooltip content={tradeInTooltip.content} title={tradeInTooltip.title} />}
        </span>
    );
};

const FinancingForm = ({ allowTradeIn, allowTestDrive, page = 'eventCalculatorPage' }: FinancingFormProps) => {
    const { t } = useTranslation();
    const channel = Channel.EVENT;

    if (!allowTradeIn && !allowTestDrive) {
        return null;
    }

    return (
        <>
            {allowTradeIn && (
                <CheckboxRow columns={1}>
                    <CheckboxField channel={channel} label={<TradeInLabel page={page} />} name="hasTradeIn" />
                </CheckboxRow>
            )}
            {allowTestDrive && (
                <CheckboxRow $marginTop={!allowTradeIn ? '21px' : '6px'} columns={1}>
                    <CheckboxField channel={channel} label={t(`${page}.checkbox.testDrive`)} name="hasTestDrive" />
                </CheckboxRow>
            )}
        </>
    );
};

export default FinancingForm;
