import styled from 'styled-components';
import ImgWithFallback from '../shared/ImgWithFallback';

const AvatarImage = styled(ImgWithFallback)`
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
`;

export default AvatarImage;
