import { toNumber } from 'lodash';
import { pick, some, isEmpty, flow, negate } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import TextField from '../../../../../components/shared/form-v2/TextField';
import { onNumKeyPress } from '../../../../../utilities/forms';
import { Title } from '../ui';

type TradeInVehicleFormProps = {
    disabled?: boolean;
};

const TradeInVehicleForm = ({ disabled = false }: TradeInVehicleFormProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('eventTradeInVehicle.title')}</Title>
            <TextField disabled={disabled} label={`${t('eventTradeInVehicle.label.make')}*`} name="tradeIn.make" />
            <TextField disabled={disabled} label={`${t('eventTradeInVehicle.label.model')}*`} name="tradeIn.model" />
            <TextField
                disabled={disabled}
                label={`${t('eventTradeInVehicle.label.registrationNo')}*`}
                name="tradeIn.registrationNo"
            />
            <TextField
                disabled={disabled}
                label={t('eventTradeInVehicle.label.mileage')}
                name="tradeIn.mileage"
                normalize={toNumber}
                onKeyPress={onNumKeyPress}
                placeholder={`${t('eventTradeInVehicle.placeholder.mileage')}*`}
            />
        </>
    );
};

export default TradeInVehicleForm;

export const checkHasTradeIn = flow([pick(['make', 'mileage', 'model', 'registrationNo']), some(negate(isEmpty))]);

// remove error message for required in events
// @ts-ignore
export const vehicleSchema = yup.lazy((values: any, options: any) => {
    const { context } = options;

    if (context.event?.setting.allowTradeIn && checkHasTradeIn(values)) {
        return yup.object().shape({
            make: yup.string().required(' '),
            mileage: yup.number().required(' '),
            model: yup.string().required(' '),
            registrationNo: yup.string().required(' '),
        });
    }

    return yup.mixed().notRequired();
});
