import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';
import CompanyCalculator from '../../../../components/shared/calculator-next/CompanyCalculator';
import useCalculatorMeta from '../../../../components/shared/calculator-next/useCalculatorMeta';
import TextareaField from '../../../../components/shared/form/TextareaField';
import BoxedGrid from '../../../../components/ui/form/BoxedGrid';
import { Channel } from '../../../../schema';
import { getCalculatorFromApplication } from '../../../../utilities/application';
import { getSnapshotFromApplication } from '../../../../utilities/calculator';
import { Application } from '../../../DraftFlow';
import useDealerEstablishment from '../../../utils/useDealerEstablishment';

export type PreviewFormProps = {
    application: Application;
    channel: Channel;
};

const PreviewForm = ({ application, channel }: PreviewFormProps & InjectedFormProps<any, PreviewFormProps>) => {
    const { t } = useTranslation();
    // get initial values from the application
    const initialValues = useMemo(() => getCalculatorFromApplication(application), [application]);

    // get a snapshot
    const snapshot = useMemo(() => getSnapshotFromApplication(application), [application]);

    // to not trigger an invalid state provide fake settings based on our outdated data
    const financeProducts = useMemo(() => [application.financeProduct], [application]);
    const variants = useMemo(() => [application.variant], [application]);
    const remarks = t('previewPage.label.remarks');

    const { selectedDealerEstablishment } = useDealerEstablishment(application.dealerId, channel);

    const promo = useMemo<Application['promoCode']>(() => {
        if (application.promoCode) {
            return {
                ...application.promoCode,
                remainingQuantity: application.promoCode.activeApplicationIds?.includes(application.id)
                    ? application.promoCode.remainingQuantity + 1
                    : application.promoCode.remainingQuantity,
            };
        }

        return (initialValues.promoCode as unknown) as Application['promoCode'];
    }, [application.id, application.promoCode, initialValues.promoCode]);

    const additionalMeta = useMemo(() => {
        return {
            snapshot,
            channel,
            promo,
            financeProducts,
            variants,
            allowOutdated: true,
            selectedDealerEstablishment,
        };
    }, [channel, financeProducts, promo, selectedDealerEstablishment, snapshot, variants]);
    const meta = useCalculatorMeta(additionalMeta);

    return (
        <>
            <CompanyCalculator channel={channel} initialValues={initialValues} meta={meta} disabled>
                mock
            </CompanyCalculator>
            <BoxedGrid>
                <TextareaField label={remarks} modalLabel={remarks} name="remark" disabled />
            </BoxedGrid>
        </>
    );
};

export default reduxForm<any, PreviewFormProps>({ form: 'preview' })(PreviewForm);
