// @ts-ignore
import { BoxedWrapper } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormSection } from 'redux-form';
import ClaimDiscount from '../../../../../components/shared/form/ClaimDiscount';
import DateField from '../../../../../components/shared/form/DateField';
import GenderField from '../../../../../components/shared/form/GenderField';
import MartialStatus from '../../../../../components/shared/form/MartialStatus';
import TextField from '../../../../../components/shared/form/TextField';
import TextareaField from '../../../../../components/shared/form/TextareaField';
import CustomerInformationForm from '../../../../../components/shared/partialForms/CustomerInformationForm';
import useCustomerNamesSynchronization from '../../../../../components/shared/useCustomerNamesSynchronization';
import BoxedGrid from '../../../../../components/ui/form/BoxedGrid';
import * as selectors from '../../../../../selectors';

type CustomerDetailsFormProps = {
    disabled?: boolean;
};

const CustomerDetailsForm = ({ disabled }: CustomerDetailsFormProps) => {
    const onNormalizeNames = useCustomerNamesSynchronization('customer');

    const { t } = useTranslation();
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(selectors.getRuntimeSettings);

    return (
        <BoxedGrid>
            <FormSection name="customer">
                <CustomerInformationForm disabled={disabled} onNormalizeNames={onNormalizeNames} />
            </FormSection>
            <GenderField label={t('customerDetails.label.gender')} name="customer.details.gender.value" />
            <MartialStatus
                label={t('customerDetails.label.maritalStatus')}
                name="customer.details.maritalStatus.value"
                purpose="INSURANCE"
            />
            <DateField
                label={t('customerDetails.label.driverLicensePassDate')}
                name="insurance.calculator.driverLicensePassDate"
            />
            <ClaimDiscount
                label={t('customerDetails.label.noClaimDiscount')}
                name="insurance.calculator.ncd"
                disabled
            />
            <TextField
                label={t('customerDetails.label.existingCarPlate')}
                name="insurance.calculator.existingCarPlate"
                wrapperComponent={useCustomerNationality && useCustomerBirthDay ? BoxedWrapper.FullWidth : BoxedWrapper}
            />
            <TextareaField.FullWidth
                label={t('customerDetails.label.claimHistory')}
                name="insurance.comment"
                placeholder={t('customerDetails.placeholder.claimHistory')}
                style={{ border: 'none' }}
            />
        </BoxedGrid>
    );
};

export default CustomerDetailsForm;
