import { TableList, Label } from '@appvantageasia/afc-ui';
import { parseISO, format } from 'date-fns';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';

const Container = styled.div`
    margin-top: 16px;
    grid-column: 1 / 3;

    table {
        margin-top: 5px;
        margin-bottom: 5px;
        table-layout: fixed;
        th:last-child {
            text-align: left !important;
        }
        td:last-child {
            text-align: left !important;
        }
    }
`;

const useColumns = t => {
    const { formatCurrencyOnly } = useCompanyFormatting();

    return useMemo(
        () => [
            {
                key: 'month',
                label: t('customerDetails.label.cpfMonth'),
                getValue: item => format(parseISO(get('month', item)), t('dateFormats.monthYearFormat')),
            },
            {
                key: 'paidAt',
                label: t('customerDetails.label.cpfPaidOn'),
                getValue: item => format(parseISO(get('paidAt', item)), t('dateFormats.dateTimeFormat')),
            },
            {
                key: 'amount',
                label: t('customerDetails.label.cpfAmount'),
                getValue: item => (
                    <span style={{ whiteSpace: 'nowrap' }}>{formatCurrencyOnly(get('amount', item), 'SGD')}</span>
                ),
            },
            {
                key: 'employer',
                label: t('customerDetails.label.cpfEmployer'),
                getValue: get('employer'),
            },
        ],
        [formatCurrencyOnly, t]
    );
};

const CPFContributions = ({ history }) => {
    const { t } = useTranslation();
    const columns = useColumns(t);

    if (!history) {
        return null;
    }

    return (
        <Container>
            <Label>{t('customerDetails.label.cpf')}</Label>
            <TableList columns={columns} items={history} />
        </Container>
    );
};

CPFContributions.propTypes = {
    history: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

export default CPFContributions;
