// @ts-ignore
import { Actions, DarkButton, Modal } from '@appvantageasia/afc-ui';
import { pick } from 'lodash/fp';
import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import * as yup from 'yup';
import AddGuarantorForm from '../../../flows/DraftFlow/components/AddGuarantor/AddGuarantorForm';
import { createFormValidation } from '../../../utilities/forms';
import { schema as customerSchema } from '../../shared/form-v2/steps/CustomerStep';
import { ApplicationData } from './index';

export type AddGuarantorFormModalProps = {
    onClose: () => void;
    validation: {
        phonePattern: RegExp | null;
    };
};

export type AddGuarantorFormModalValues = Pick<
    ApplicationData['customer'],
    'name' | 'email' | 'phone' | 'identityNumber'
>;

const AddGuarantorFormModal = ({
    onClose,
    handleSubmit,
}: AddGuarantorFormModalProps & InjectedFormProps<AddGuarantorFormModalValues, AddGuarantorFormModalProps>) => (
    <Modal onClose={onClose} title="Add Guarantor" showClose showTitle>
        <AddGuarantorForm />
        <Actions>
            <DarkButton onClick={handleSubmit}>Send</DarkButton>
        </Actions>
    </Modal>
);

const schema = yup.object().shape(pick(['name', 'email', 'identityNumber', 'phone'], customerSchema));

export default reduxForm<AddGuarantorFormModalValues, AddGuarantorFormModalProps>({
    form: 'guarantor',
    validate: createFormValidation(schema),
})(AddGuarantorFormModal);
