import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getCompanyRootUrl } from '../../utilities/urls';

const useNotSignedBackAction = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (sessionStorage.getItem('redirectToSigning')) {
            // when user tap browser, will return our site,
            // after that, we need to clear new applicaiton state
            // and clear redirectToSigning flag
            // and go to home page
            const { message, title } = t('pendingModal.signingNotCompleted', { returnObjects: true }) as {
                message: string;
                title: string;
            };
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyRootUrl, {
                signNotCompleted: {
                    title,
                    message,
                },
            });
            sessionStorage.removeItem('redirectToSigning');
        }
    }, [dispatch, history, t]);
};

export default useNotSignedBackAction;
