import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import { getCompany, getRuntimeSettings } from '../../selectors';

const MetaData = () => {
    const { cdnLink } = useSelector(getRuntimeSettings);
    const company = useSelector(getCompany);
    const companyCode = company?.code || 'DEFAULT';
    const companyName = company?.name;

    const title = companyName ? `${companyName} AFC` : 'AFC';

    const renderFavIcon = (size: number) =>
        !!cdnLink && (
            <link
                href={urlJoin(
                    cdnLink,
                    `/company/favicon/resized/${companyCode.toUpperCase()}_${company?.favIcon?.id}_${size}_${size}.png`
                )}
                rel="icon"
                type="image/png"
            />
        );

    return (
        <Helmet>
            <title>{title}</title>
            {renderFavIcon(16)}
            {renderFavIcon(32)}
            {renderFavIcon(96)}
            {renderFavIcon(128)}
        </Helmet>
    );
};

export default MetaData;
