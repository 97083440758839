import { InsuranceSigningMode } from '../../schema';
import { getCountryInsuranceConsents } from '../DraftFlow/utils/consents';
import { Flow, FlowStep } from '../utils/flow';
import * as flowSteps from './steps';
import { InsuranceNewFlowState } from './types';

class InsuranceDraftFlow extends Flow<InsuranceNewFlowState> {
    protected plannify(): FlowStep<InsuranceNewFlowState>[] {
        const { insuranceCompany, consents, channel, applyForInsurance } = this.state;

        const steps: FlowStep<InsuranceNewFlowState>[] = [];

        const insuranceConsents = getCountryInsuranceConsents(consents, channel);

        if (applyForInsurance) {
            steps.push(this.createStep(flowSteps.InsuranceCalculatorStep), this.createStep(flowSteps.DraftStep));
        }

        if (insuranceConsents.length) {
            steps.push(this.createStep(flowSteps.ConsentStep));
        }

        if (!applyForInsurance) {
            switch (insuranceCompany.signing.onCreate) {
                case InsuranceSigningMode.NAMIRIAL:
                    steps.push(this.createStep(flowSteps.NamirialStep));
                    break;

                case InsuranceSigningMode.NONE:
                default:
                    // do nothing
                    break;
            }
        }

        return [...steps, this.createStep(flowSteps.SubmitStep)];
    }
}

export default InsuranceDraftFlow;
