import React from 'react';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../selectors';
import { SubTitle } from '../Configurator/shared/ui';
import { Row, SectionContainer } from './shared/ui';

type TimepieceSectionProps = {
    formatCurrency: (price: number) => string;
};

const TimepieceSection = ({ formatCurrency }: TimepieceSectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    return (
        <SectionContainer>
            <SubTitle>{ckdConfiguration.summary.porscheDesign}</SubTitle>
            <Row>
                <span>{ckdConfiguration.timepiece.label}</span>
                <span>{formatCurrency(ckdConfiguration.timepiece.price)}</span>
            </Row>
        </SectionContainer>
    );
};

export default TimepieceSection;
