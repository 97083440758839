import { pick, cloneDeep, map, find } from 'lodash/fp';
import getCalculatorFromApplication from '../flows/utils/getCalculatorFromApplication';
import { Channel } from '../schema';
import { getCalculatorPayload } from './calculator';
import { applicationChannelOptions } from './constants/options';
import { deepOmit, pickAndRename } from './fp';

/*
 * Wording references:
 *    - Application object is the raw data incoming from the API
 *    - Application state is the data stored in redux and used by the application forms and validations
 *    - Payload is the data sent as json payload when submitting an application over the API
 * */

/* map application object into an application meta state */
const mapApplicationToMetaState = pickAndRename({
    applicationId: 'id',
    applicationVersionId: 'version.id',
    applicationNumber: 'identifier',
    createdDate: 'version.createdAt',
    customerId: 'customer.id',
    zoneId: 'zoneId',
    uploadId: 'uploadId',
    assigneeId: 'assigneeId',
    financeProduct: 'financeProduct',
    bank: 'financeProduct.bank',
    promoCodeId: 'promoCode.id',
    version: 'version',
});

// proxy it
export { getCalculatorFromApplication };

export const getCustomerFromApplication = application => ({
    ...deepOmit(['__typename'], application.customer),
    ...pick(['proceedWithCustomerDevice', 'appliedForFinancing', 'withGuarantor'], application),
    remark: application.remark,
    files: application.attachments,
    events: application.events,
});

export const getSourceLabel = (channel, access, t) =>
    find(option => option.channel === channel && option.access === access, applicationChannelOptions(t))?.label;

/* map an application object into an application state */
export const mapApplicationToState = (application, t) => {
    // customer data at first

    return {
        ...pick(['proceedWithCustomerDevice', 'withGuarantor'], application),

        // attach customer data
        customer: getCustomerFromApplication(application),

        // calculator data
        calculator: getCalculatorFromApplication(application),

        // meta data
        meta: {
            // global meta data
            ...mapApplicationToMetaState(application),
            // provide initial promo code
            initialPromoCode: application.promoCode?.identifier,
            // provide initial customer data too
            initialCustomerData: cloneDeep(application.customer),
            // provide source label in meta
            source: getSourceLabel(application.channel, application.access, t),
            channel: application.channel,
            variant: {
                // variant
                model: application.variant.model.parent?.name || application.variant.model.name,
                subModel: application.variant.model.parentId ? application.variant.model.name : null,
                // variant stuff coming from snapshot
                ...pickAndRename({
                    make: 'model.make.name',
                    variant: 'name',
                    variantCode: 'identifier',
                    yearOfManufacture: 'year',
                    assetCondition: 'assetCondition',
                })(application.variant),
                coeAmount: application.calculator.coe.amount,
                ppsrAmount: application.calculator.ppsr.amount,
                establishmentAmount: application.calculator.establishment.amount,
                luxuryTaxAmount: application.calculator?.luxuryTax?.amount,
                vin: application.booking?.unit?.details?.vin,

                // some stuff are not implemented yet
                registrationDate: null,
                age: null,
                fuelType: null,
                price: application.calculator.price,
            },
        },
    };
};

const getCommonPayload = (calculator, meta, allowOptions, customerId) => ({
    customerId: meta.customerId || customerId,
    ...pick(['zoneId', 'leadId', 'uploadId', 'promoCodeId', 'bookingId', 'eventId'], meta),
    ...pickAndRename({ financeProductId: 'financeProduct', variantId: 'carModel' })(calculator),
    optionIds: allowOptions ? map(option => option.id, calculator.carOptions) : [],
    bank: { id: calculator.bank },
});

/* map an lead state into a payload ready for share */
export const mapLeadStateToPayload = (applicationState, allowOptions, customerId, zoneId) => {
    const { calculator, meta } = applicationState;

    const data = {
        ...getCommonPayload(calculator, meta, allowOptions, customerId),
        zoneId,
        calculator: getCalculatorPayload(calculator, meta?.interestOnly),
        channel: Channel.NEW,
    };

    return { data };
};
