// @ts-ignore
import { ActionsV2 as Actions, Footer } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { ApplicationStatus, ApplicationEventType } from '../../../../schema';
import { getGlobalPermissions } from '../../../../selectors';
import useFormDirty from '../../shared/useFormDirty';
import { ApplicationDataFragment } from '../data.graphql';
import UpdateStateAction from './UpdateStateAction';
import useFormChanged from './useFormChanged';

const allowDirectlySubmitStatus = [
    ApplicationStatus.SIGNING,
    ApplicationStatus.PENDINGOTP,
    ApplicationStatus.SIGNINGREJECTED,
    ApplicationStatus.SIGNINGTIMEOUT,
    ApplicationStatus.UNABLETOCONNECT,
    ApplicationStatus.CONNECTIONFAILED,
];

export type ReSubmitActionProps = {
    application: ApplicationDataFragment;
    showApproveDeclineActions?: boolean;
    approveDisabled?: boolean;
    declineDisabled?: boolean;
};

const ReSubmitAction = ({
    application,
    showApproveDeclineActions,
    approveDisabled,
    declineDisabled,
}: ReSubmitActionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mayManageApplication } = useSelector(getGlobalPermissions);

    const onClick = useCallback(async () => {
        // submit the application
        return dispatch(submit('application'));
    }, [dispatch]);

    const hasChanged = useFormChanged();
    const hasErrors = useFormDirty('application');

    const { events = [], status } = application;

    const canSubmitDirectly = allowDirectlySubmitStatus.includes(status);

    const disabled = hasErrors || !mayManageApplication || (!canSubmitDirectly && !hasChanged);

    const hasSigned = !!events.find(item => item.type === ApplicationEventType.RECEIVE);

    return (
        <>
            <UpdateStateAction
                application={application}
                approveDisabled={approveDisabled}
                declineDisabled={declineDisabled}
                showApproveDeclineActions={showApproveDeclineActions}
            />
            <Actions>
                <Footer.FootBarButton disabled={disabled} onClick={onClick}>
                    {hasSigned
                        ? t('applicationDetailsPage.button.submitChanges')
                        : t('applicationDetailsPage.button.submit')}
                </Footer.FootBarButton>
            </Actions>
        </>
    );
};

export default ReSubmitAction;
