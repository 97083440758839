// @ts-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusContainer, StatusIcon, Message } from '../../../../components/ui/Adyen';
import successImg from '../../../../assets/images/paysuccess.svg';

export type PaymentSuccessProps = {
    isLastStep: boolean;
};

const PaymentSuccess = ({ isLastStep }: PaymentSuccessProps) => {
    const { t } = useTranslation();

    return (
        <StatusContainer>
            <StatusIcon src={successImg} />
            <Message>{t('depositPage.label.successMessage')}</Message>
        </StatusContainer>
    );
};

export default PaymentSuccess;
