import React, { useEffect, useMemo, useState } from 'react';
import { ApplicationFlowSource } from '../../../../schema';
import { Application } from '../../../DraftFlow';
import { SubmittedState } from '../../../DraftFlow/components/PayGateDeposit';
import PayGateConfirmationModal from '../../../DraftFlow/components/PayGateDeposit/PayGateConfirmationModal';
import PayGateForm from '../../../DraftFlow/components/PayGateDeposit/PayGateForm';
import usePayGatePayment from '../../../DraftFlow/components/PayGateDeposit/usePayGatePayment';

type PayGatePaymentProps = {
    application: Application;
    source: ApplicationFlowSource;
    onSubmit: () => void;
    token: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    hasConsents: boolean;
};

const PayGatePayment = ({
    application,
    onSubmit,
    source,
    token,
    showModal,
    setShowModal,
    hasConsents,
}: PayGatePaymentProps) => {
    const { status } = usePayGatePayment(application.version.id, source);
    const isSuccessful = application.steps?.payment || (status && SubmittedState.includes(status));

    const [confirm, setConfirm] = useState<boolean>(false);
    const actions = useMemo(
        () => ({
            close: () => setShowModal(false),
            confirm: () => {
                setShowModal(false);
                setConfirm(true);
            },
        }),
        [setShowModal]
    );

    useEffect(() => {
        if (isSuccessful) {
            onSubmit();
        }
    }, [isSuccessful, onSubmit]);

    return (
        <>
            {showModal && <PayGateConfirmationModal onClose={actions.close} onConfirm={actions.confirm} />}
            {!isSuccessful && (confirm || !hasConsents) && (
                <PayGateForm applicationId={application.version.id} source={source} token={token} />
            )}
        </>
    );
};

export default PayGatePayment;
