import { getOr } from 'lodash/fp';
import { getCurrentZone } from './context';

export const getNewApplication = state => state.newApplication;

export const getNewApplicationStep = step => state => getOr(null, step, getNewApplication(state));

export const getHasApplyForFinancing = state => {
    // then get the zone settings
    const zone = getCurrentZone(state) || {};
    const { allowFinanceApplication, isFinanceApplicationMandatory } = zone;

    // when  zone setting has allowFinanceApplication and isFinanceApplicationMandatory,
    // and can go occupation or can sign,
    // then will ask use to select if she/he want to sign or fill occupation
    return allowFinanceApplication && !isFinanceApplicationMandatory;
};
