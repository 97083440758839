// @ts-ignore
import { useApolloClient } from '@apollo/client';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { get, getOr, toPairs } from 'lodash/fp';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import * as yup from 'yup';
import { attachLoading } from '../../../../actions';
import {
    updateApplicationEvent,
    UpdateApplicationEventMutation,
    UpdateApplicationEventMutationVariables,
    updateConsent,
    UpdateConsentMutation,
    UpdateConsentMutationVariables,
} from '../../../../api/application.graphql';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import { AdhocEventType, ApplicationEventType } from '../../../../schema';
import ConsentField from '../../../DraftFlow/components/Consent/ConsentField';
import ConsentNonInteractiveField from '../../../DraftFlow/components/Consent/ConsentNonInteractiveField';
import { ConsentDepositStepValues } from '../../steps/EventConsentDepositStep';
import { Buttons, PrimaryButton } from '../Calculator/ui';

export const renderConsent = (consent: ConsentDataFragment) => {
    const { hasCheckbox, id } = consent;

    if (hasCheckbox) {
        return <ConsentField key={id} consent={consent} name={`consents.${id}`} />;
    }

    return <ConsentNonInteractiveField key={id} consent={consent} name={`consents.${id}`} />;
};

const useConsentChangeEffect = (
    consentStatuses: Record<string, boolean>,
    setReferenceId?: (referenceId: string) => unknown,
    referenceId?: string,
    token?: string
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const client = useApolloClient();
    const previousConsents = useRef(consentStatuses);

    useEffect(() => {
        toPairs(consentStatuses).forEach(async ([uniqueId, value]) => {
            if (get(uniqueId, previousConsents.current) !== value) {
                if (token) {
                    const variables = {
                        token,
                        data: {
                            type: value ? ApplicationEventType.CHECK : ApplicationEventType.UNCHECK,
                            detail: { id: uniqueId },
                        },
                    };

                    const promise = client
                        .mutate<UpdateApplicationEventMutation, UpdateApplicationEventMutationVariables>({
                            mutation: updateApplicationEvent,
                            variables,
                        })
                        .then(apiResponse => apiResponse?.data?.success);

                    const success = await dispatch<typeof promise>(attachLoading(promise));

                    if (!success) {
                        throw new Error('Failed to sign consent');
                    }

                    return;
                }

                // prepare variables
                const variables = {
                    data: {
                        type: value ? AdhocEventType.CHECK : AdhocEventType.UNCHECK,
                        referenceId,
                        detail: { id: uniqueId },
                    },
                };

                // call the API
                const promise = client
                    .mutate<UpdateConsentMutation, UpdateConsentMutationVariables>({
                        mutation: updateConsent,
                        variables,
                    })
                    .then(apiResponse => apiResponse?.data?.consents);

                // attach the promise and wait
                const response = await dispatch<typeof promise>(attachLoading(promise));

                if (!response) {
                    throw new Error('Failed to sign consent');
                }

                // eventId is for consent referenceId
                setReferenceId && setReferenceId(response.referenceId);
            }
        });

        previousConsents.current = consentStatuses;
    }, [consentStatuses, dispatch, referenceId, client, setReferenceId, t, token]);
};

export type ConsentFormProps = {
    consents: ConsentDataFragment[];
    consentStatuses?: Record<string, boolean>;
    setReferenceId?: (referenceId: string) => unknown;
    referenceId?: string;
    token?: string;
};

export const ConsentForm = ({
    consents,
    consentStatuses = {},
    setReferenceId,
    referenceId,
    token,
}: ConsentFormProps) => {
    useConsentChangeEffect(consentStatuses, setReferenceId, referenceId, token);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{consents.map(renderConsent)}</>;
};

export type ConsentFormToConnectProps = {
    token: string;
    consents: ConsentDataFragment[];
    showNextButton: boolean;
    setValid: (valid: boolean) => unknown;
    setReferenceId: (referenceId: string) => unknown;
    referenceId?: string;
};
export type ConsentFormToConnectValues = Partial<ConsentDepositStepValues>;

const ConsentFormToConnect = ({
    token,
    consents,
    valid,
    handleSubmit,
    setReferenceId,
    showNextButton,
    setValid,
    referenceId,
}: ConsentFormToConnectProps & InjectedFormProps<ConsentFormToConnectValues, ConsentFormToConnectProps>) => {
    const { t } = useTranslation();
    const { consents: consentStatuses }: ConsentFormToConnectValues =
        useSelector(getFormValues('consentDeposit')) || {};
    useConsentChangeEffect(consentStatuses, setReferenceId, referenceId, token);

    useEffect(() => {
        setValid && setValid(valid);
    }, [setValid, valid]);

    return (
        <>
            {consents.map(renderConsent)}
            {showNextButton && (
                <Buttons>
                    <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                        <FontAwesomeIcon icon={faAngleRight} /> {t('eventConsentDepositPage.button.next')}
                    </PrimaryButton>
                </Buttons>
            )}
        </>
    );
};

export const consentValidation = (t: TFunction, consents?: ConsentDataFragment[]) =>
    // @ts-ignore
    yup.lazy((values: any, options: any) => {
        const contextConsents = getOr([], 'context.values.__exclude.consents', options) as ConsentDataFragment[];
        const consentsToCheck = consents || contextConsents;
        const mandatoryConsents: { [id: string]: yup.BooleanSchema } = {};

        consentsToCheck.forEach(consent => {
            if (consent.isMandatory && consent.hasCheckbox) {
                mandatoryConsents[consent.id] = yup
                    .boolean()
                    .default(false)
                    .oneOf([true], t('eventConsentDepositPage.error.consents'));
            }
        });

        if (!Object.keys(mandatoryConsents).length) {
            // no mandatory consents
            return yup.mixed().default(undefined);
        }

        return yup.object().default({}).shape(mandatoryConsents);
    });

export const schema = (consents?: ConsentDataFragment[]) => (t: TFunction) =>
    yup.object().shape({ consents: consentValidation(t, consents) });

export default reduxForm<ConsentFormToConnectValues, ConsentFormToConnectProps>({
    form: 'consentDeposit',
})(ConsentFormToConnect);
