import { isNil } from 'lodash';
import { ApplicationPhase, Channel } from '../schema';
import { getChannelSetting, getPrivateAccess, getPublicAccess } from './channels';
import { permissionChannels } from './permissions';

export const getLoginUrl = () => '/login';

export const getCompanyRootUrl = (companyCode, locationCode) => `/${companyCode}/${locationCode}`;

export const getCompanyUrlWithDealer = (companyCode, locationCode, dealerName) =>
    `/${companyCode}/${locationCode}/new?d=${dealerName}`;

export const prefixCompany = fn => (companyCode, locationCode, ...args) =>
    `${getCompanyRootUrl(companyCode, locationCode)}${fn(...args)}`;

export const getCompanyLoginUrl = (companyCode, locationCode) =>
    `${getCompanyRootUrl(companyCode, locationCode)}/login`;

export const getLocationCode = (countryCode, zoneCode) =>
    countryCode === zoneCode ? countryCode : `${countryCode}-${zoneCode}`;

export const getConfiguratorUrl = prefixCompany(() => `/configurator`);

// the car model list is the homepage
export const getCarModelsUrl = prefixCompany(() => `/new`);

export const getUsedCarModelsUrl = prefixCompany(() => `/used`);

export const getNewApplicationCalculatorUrl = prefixCompany((variantId, routeType, unitId = null) => {
    switch (routeType) {
        case 'used':
            return `/${routeType}/calculator/${variantId}/${unitId}`;
        default:
            return `/new/calculator/${variantId}`;
    }
});

export const withDealer = (path, dealerName) => `${path}?d=${dealerName}`;

export const getNewApplicationSimpleCalculatorUrl = prefixCompany(() => `/preowned/calculator/`);

export const getDraftUrl = prefixCompany(applicationVersionId => `/apply/${applicationVersionId}`);

export const getInsuranceDraftUrl = prefixCompany(applicationVersionId => `/insuranceApply/${applicationVersionId}`);

export const getNewApplicationCustomerUrl = prefixCompany(() => '/apply/customer');

export const getNewApplicationMyInfoUrl = prefixCompany(() => '/apply/myInfo');

export const getNewApplicationOccupationUrl = prefixCompany(() => '/apply/occupation');

export const getNewApplicationConsentUrl = prefixCompany(() => '/apply/consent');

export const getNewApplicationDepositUrl = prefixCompany(() => '/apply/deposit');

export const getNewApplicationApprovalUrl = prefixCompany(() => '/apply/approval');

export const getLeadsUrl = prefixCompany(() => '/leads');

export const getLeadUrl = prefixCompany(leadId => `/leads/${leadId}`);

export const getInsuranceApplicationsUrl = prefixCompany(() => '/insuranceApplications');

export const getInsuranceApplicationUrl = prefixCompany(applicationId => `/insuranceApplications/${applicationId}`);

export const getInsuranceApplicationResumeUrl = prefixCompany(
    applicationId => `/insuranceApplications/${applicationId}/resume`
);

export const determineApplicationsUrl = application => {
    if (!isNil(application?.insuranceCompany)) {
        return '/insuranceApplications';
    }

    switch (application?.phase) {
        case ApplicationPhase.RESERVATION:
            return '/reservations';

        case ApplicationPhase.FINANCE:
            return '/applications';

        default:
            return '/leads';
    }
};

export const determineApplicationDetailUrl = (suffix = '') => application => {
    const prefix = determineApplicationsUrl(application);

    return `${prefix}/${application?.version?.id}${suffix}`;
};

export const getApplicationsUrl = prefixCompany(determineApplicationsUrl);

export const getApplicationUrl = prefixCompany(determineApplicationDetailUrl());

export const getApplicationResumeUrl = prefixCompany(determineApplicationDetailUrl('/resume'));

export const getEventsUrl = prefixCompany(() => '/event');

export const getEventUrl = prefixCompany(eventId => `/event/${eventId}`);

export const getEventDetailsUrl = prefixCompany(eventId => `/event/details/${eventId}`);

export const getProfileUrl = prefixCompany(() => '/profile');

export const getMaintenanceUrl = prefixCompany(() => '/maintenance');

export const parseCompanyPathname = (url, companyCode, locationCode) => {
    if (!url) {
        return null;
    }

    const companyRootUrl = getCompanyRootUrl(companyCode, locationCode);

    const startIndex = url.toLowerCase().indexOf(companyRootUrl.toLowerCase());

    return url.slice(startIndex);
};

export const getHomeUrl = channel => {
    switch (channel) {
        case Channel.USED:
            return getUsedCarModelsUrl;
        case Channel.EXPRESS:
            return getNewApplicationSimpleCalculatorUrl;
        case Channel.EVENT:
            return getEventsUrl;
        case Channel.NEW:
        default:
            return getCarModelsUrl;
    }
};

export const getPrivateHomeUrl = (user, country) => {
    for (const [channel, permission] of Object.entries(permissionChannels)) {
        // get channel settings
        const setting = getChannelSetting(country, channel);

        // get private settings
        const hasSettingAccess = getPrivateAccess(setting);

        // get permissions
        const hasPermission = permission(user, country.id);

        if (hasSettingAccess && hasPermission) {
            return getHomeUrl(channel);
        }
    }

    return null;
};

export const getPublicHomeUrl = country => {
    for (const channel of Object.values(Channel)) {
        const setting = getChannelSetting(country, channel);

        // get public settings
        if (getPublicAccess(setting)) {
            return getHomeUrl(channel);
        }
    }

    return null;
};
export const getCustomerUrl = prefixCompany(customerId => `/customers/${customerId}`);

export const getCustomersUrl = prefixCompany(() => `/customers`);

export const getActivationUrl = prefixCompany(applicationId => `/activation/${applicationId}`);

export const getTimeoutUrl = prefixCompany(() => '/timeout');

export const getAppointmentsUrl = prefixCompany(() => '/appointments');
export const getAppointmentUrl = prefixCompany(appointmentId => `/appointments/${appointmentId}`);

export const getPorscheFinderUrl = prefixCompany(finderId => `/finder/${finderId}`);
export const getCsvUrl = prefixCompany(porscheCode => `/csv/${porscheCode}`);
