import styled from 'styled-components';

const LinkField = styled.div`
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #ced4da solid;

    .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 100;
        color: #666666;
        touch-action: manipulation;
        white-space: nowrap;
        padding: 9px 0;
        margin-bottom: 0px;
        line-height: 20px;
        margin-right: 0.5rem;
    }

    .link-control {
        a {
            font-size: 14px;
            font-style: normal;
            font-weight: 100;
            line-height: 20px;
            color: #000000;
            text-decoration: underline;
        }
    }
`;

export default LinkField;
