import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { DarkButton, Actions } from '@appvantageasia/afc-ui';
import { parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import React, { useEffect, useCallback, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, ReduxFormContext, change, getFormValues, InjectedFormProps } from 'redux-form';
import * as yup from 'yup';
import { generateOTP, GenerateOtpMutation, GenerateOtpMutationVariables } from '../../../../api/application.graphql';
import OTPField from '../../../../components/shared/form/OTPField';
import { TextAlignCenter } from '../../../../components/ui/TextAlign';
import { Title, OTPLink } from '../../../../components/ui/calculator';
import ChoiceWrap from '../../../../components/ui/calculator/ChoiceWrap';
import { Error } from '../../../../components/ui/form/FormActions';
import { padString } from '../../../../utilities/fp';

type ApprovalFormProps = {
    token: string;
    isLastStep: boolean;
};

const codeLength = 6;

const ApprovalForm = ({
    token,
    handleSubmit,
    isLastStep,
}: ApprovalFormProps & InjectedFormProps<any, ApprovalFormProps>) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const [otpSecret, setOtpSecret] = useState('');

    // get form context
    const dispatch = useDispatch();
    // @ts-ignore
    const { form, valid, error } = useContext(ReduxFormContext);
    // @ts-ignore
    const { expiresAt, display, locked = false } = useSelector(getFormValues(form)) || {};

    useEffect(() => {
        if (!expiresAt) {
            // there's nothing to do, there's no validation to timeout for now
            return () => undefined;
        }

        const limitTime = parseISO(expiresAt).getTime();

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            const diff = (limitTime - new Date().getTime()) / 1000;

            if (diff <= 0) {
                // it's a timeout
                // reset the state
                dispatch(change(form, 'locked', false));
                dispatch(change(form, 'display', null));
                // stop the interval
                clearInterval(intervalId);

                return;
            }

            const minutes = padString('0', 2, Math.floor(diff / 60));
            const seconds = padString('0', 2, Math.ceil(diff % 60));

            // update the display
            dispatch(change(form, 'display', `${minutes}:${seconds}`));
        }, 200);

        // clear interval on re-render
        return () => clearInterval(intervalId);
    }, [expiresAt, form, dispatch]);

    const fetchOtp = useCallback(async () => {
        const { data } = await client.mutate<GenerateOtpMutation, GenerateOtpMutationVariables>({
            mutation: generateOTP,
            variables: { token },
        });

        if (!data?.result) {
            return;
        }

        const { secret, expiresAt } = data.result;
        setOtpSecret(secret);
        dispatch(change(form, 'locked', false));
        dispatch(change(form, 'secret', secret));
        dispatch(change(form, 'expiresAt', expiresAt));
    }, [dispatch, token, form, client]);

    const fetchedRef = useRef(false);

    useEffect(() => {
        if (!fetchedRef.current) {
            // avoid calling it again
            fetchedRef.current = true;
            // generate OTP for approval on mount
            fetchOtp();
        }
    }, [fetchOtp]);

    return (
        <form onSubmit={handleSubmit}>
            <Title>{t('otpPage.title')}</Title>
            <ChoiceWrap>{t('otpPage.description')}</ChoiceWrap>
            <OTPField key={otpSecret} length={codeLength} name="code" />
            {error && (
                <TextAlignCenter>
                    <Error>{error}</Error>
                </TextAlignCenter>
            )}
            <Actions>
                <DarkButton disabled={locked || !valid} type="submit">
                    {isLastStep ? t('otpPage.button.submit') : t('otpPage.button.next')}
                </DarkButton>
            </Actions>
            <TextAlignCenter>
                {/* @ts-ignore */}
                <OTPLink disabled={locked} onClick={!locked ? fetchOtp : undefined}>
                    {display ? t('otpPage.label.resendOtpWithValue', { otp: display }) : t('otpPage.label.resendOtp')}
                </OTPLink>
            </TextAlignCenter>
        </form>
    );
};

export const schema = (t: TFunction) =>
    yup.object().shape({
        code: yup.string().test('len', t('common.error.required'), value => value?.length === codeLength),
    });

export default reduxForm<any, ApprovalFormProps>({
    form: 'approval',
})(ApprovalForm);
