// @ts-ignore
import { OutlineButton } from '@appvantageasia/afc-ui';
import styled from 'styled-components';

export const MyInfoBoxWrap = styled.div`
    border: 0.063rem solid #dddddd;
    padding: 20px;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    line-height: 1.5;
`;

export const MyInfoText = styled.div`
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    color: #000;
`;

export const SingpassLogoContainer = styled.a`
    cursor: pointer;

    svg {
        max-width: 115px;
    }
`;

export const SingpassButtonContainer = styled.div`
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
`;

export const MyInfoManualButton = styled(OutlineButton)`
    color: #000;
    border: 0.063rem solid #000;
`;
