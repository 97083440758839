import React from 'react';
import { completePayment, CompletePaymentMutation, CompletePaymentMutationVariables } from '../../../api/draft.graphql';
import PaymentStatus from '../../../utilities/constants/paymentStatus';
import Deposit from '../../DraftFlow/components/Deposit';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';

class DepositStep extends FlowStep<RemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'deposit';
    }

    public async execute(values: any) {
        const { apolloClient } = this;
        const { token } = this.state;

        const response = await apolloClient.mutate<CompletePaymentMutation, CompletePaymentMutationVariables>({
            mutation: completePayment,
            variables: { token },
        });

        this.stateStore.update({ ...response.data?.response });

        return this.nextStep;
    }

    public setPaymentResult = (nextPaymentResult: any) => this.stateStore.update({ paymentResult: nextPaymentResult });

    public render(): React.ReactElement | null {
        const { paymentResult, application, token, source } = this.state;

        const applicationPaymentResult = application.steps?.payment ? PaymentStatus.AUTHORISED : undefined;

        return (
            <Deposit
                application={application}
                backStep={this.getBackContext()}
                dealerId={application.dealerId}
                isLastStep={this.isLastStep}
                onSubmit={this.submit}
                paymentResult={paymentResult || applicationPaymentResult}
                setPaymentResult={this.setPaymentResult}
                source={source}
                token={token}
            />
        );
    }
}

export default DepositStep;
