// @ts-ignore
import { Footer } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { reduxForm } from 'redux-form';
import Consents from './Consents';
import ListContainer from './ListContainer';
import MainDetails from './MainDetails';
import Settings from './Settings';
import { EventData } from './index';

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

export type EventFormData = EventData & {
    lastModified: string;
    setting: {
        isDepositPaymentMandatory: boolean;
        enablePriceDisclaimer: boolean;
        priceDisclaimers: boolean;
        isReservationInstructionEnabled: boolean;
        reservationInstruction: string;
    };
};

const EventForm = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const onCancel = useCallback(() => history.goBack(), [history]);

    return (
        <>
            <MainDetails />
            <Settings />
            <Consents />
            <ListContainer />
            <FootContainer>
                <FootBar>
                    <FootBarRow>
                        {onCancel && (
                            <FootBarButton onClick={onCancel}>{t('eventDetailsPage.button.cancel')}</FootBarButton>
                        )}
                    </FootBarRow>
                </FootBar>
            </FootContainer>
        </>
    );
};

export default reduxForm<EventFormData, any>({ form: 'event' })(EventForm);
