import { isEmpty, filter, omit } from 'lodash/fp';
import {
    deleteDocument,
    uploadDocument,
    copyDocument,
    uploadDocumentByApplicationId,
    deleteUnattached,
} from './connectedApi';

// if it is public access, deleting document need some validation, so need necessary query
export const uploadFiles = (
    files,
    initialFiles = [],
    initialUploadId = null,
    applicationId = null,
    token = null
) => async dispatch => {
    // split previous and new files
    // first the files we already had and we are keeping
    let existingFiles = filter(file => !(file instanceof File) && !isEmpty(omit(['purpose'], file)), files);
    // then the files we need to upload
    const newFiles = filter(file => file instanceof File, files);

    let uploadId = initialUploadId;

    if (initialFiles.length !== existingFiles.length) {
        // we have files to delete
        // first check for those
        // to do so, we first list ID of files we are going to keep
        const currentIds = existingFiles.map(file => file.id);
        // look for the files for which ID we do not previously listed
        const filesToDelete = filter(file => !currentIds.includes(file.id) && !file.toCopy, initialFiles);

        // we ay now delete them
        await Promise.all(
            filesToDelete.map(file => {
                if (applicationId) {
                    return dispatch(deleteDocument(file.id, applicationId, token));
                }

                return dispatch(deleteUnattached(file.id, uploadId));
            })
        );
    }

    const copyExistingFile = async ({ id }) => {
        const response = await dispatch(copyDocument(id, uploadId));

        return { ...response.attachment, uploadId: response.uploadId };
    };

    const filesToCopy = filter(file => file.toCopy, existingFiles);

    if (filesToCopy.length) {
        // first remove those files from existing
        existingFiles = filter(file => !file.toCopy, existingFiles);

        // call api to copy
        // and replace existing files by responses
        if (!uploadId) {
            // we need to get our first upload it
            // get our first file
            const file = filesToCopy.pop();
            // now upload it
            const result = await copyExistingFile(file);
            // get the upload id
            uploadId = result?.uploadId;
            // add it to existing files
            existingFiles = [...existingFiles, result];
        }

        // copy other documents (if any)
        existingFiles = [...existingFiles, ...(await Promise.all(filesToCopy.map(file => copyExistingFile(file))))];
    }

    const uploadNewFile = async file => {
        if (applicationId) {
            return dispatch(uploadDocumentByApplicationId({ applicationId, file, token, purpose: file.purpose }));
        }

        // upload the file
        const response = await dispatch(uploadDocument({ uploadId, file, purpose: file.purpose }));

        // turn it into an object
        return { ...response.attachment, uploadId: response.uploadId };
    };

    if (!uploadId && newFiles.length) {
        // we have files to upload but no upload ID yet
        // so let's get our first file and process it alone
        const file = newFiles.pop();
        // upload the file and get its upload it
        // which we will reuse for next files to be uploaded
        const result = await uploadNewFile(file);
        // add it to our list
        existingFiles = [...existingFiles, result];
        // and reuse its upload it
        uploadId = result?.uploadId;
    }

    if (newFiles.length) {
        // upload all remaining files
        existingFiles = [...existingFiles, ...(await Promise.all(newFiles.map(uploadNewFile)))];
    }

    return [uploadId, existingFiles];
};

export const uploadFilesFromOccupation = (files, initialFiles, applicationId) => async dispatch => {
    return dispatch(uploadFiles(files, initialFiles, null, applicationId));
};
