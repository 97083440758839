import { AssetCondition, PreOwnerIdType, PreOwnedCarDetails } from '../../../../schema';
import { ExpressVariant } from '../../types';

const id = 'EXPRESS';

const variantTemplate: ExpressVariant = {
    assetCondition: AssetCondition.USED,
    channels: {
        new: [],
        used: [],
        express: true,
    },
    id,
    identifier: 'EXPRESS',
    images: [],
    makeName: 'Porsche',
    modelName: '',
    model: {
        id: 'EXPRESS',
        identifier: 'EXPRESS',
        order: 0,
        make: {
            id: 'EXPRESS',
            identifier: 'EXPRESS',
            name: '',
            order: 0,
        },
        makeId: 'EXPRESS',
        name: '',
        version: { id },
    },
    modelId: 'EXPRESS',
    name: 'EXPRESS',
    options: [],
    order: 0,
    prices: [],
    preOwnedCarDetails: {
        ownerIdType: PreOwnerIdType.SINGAPORENRIC,
    } as PreOwnedCarDetails,
    technicalDetails: {},
    usedCarDetails: {},
    version: { id, isOutdated: false },
    year: 0,
};

export default variantTemplate;
