import styled from 'styled-components';

const ChatHistory = styled.div`
    height: calc(100% - 97px);
    overflow-y: auto;
    background-color: #fff;
    padding: 15px 15px 40px;
`;

export default ChatHistory;
