import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getCompanyIdentifier } from '../../selectors';
import { getUsedCarModelsUrl } from '../../utilities/urls';

const RedirectToUsedCar = () => {
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    return <Redirect to={getUsedCarModelsUrl(companyCode, locationCode)} />;
};

export default RedirectToUsedCar;
