import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useMedia from 'use-media';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { ImageContainer, SelectionContainer } from './Exterior';
import AddField, { Button } from './shared/AddField';
import { SectionTitle } from './shared/ui';

const PackageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    ul {
        align-self: center;
        width: 80%;
        max-width: 400px;
        min-width: 320px;
        margin: 20px 0;
        list-style: inside;
        li {
            margin: 2px;
            font-size: 16px;
            font-weight: 420;
        }
    }

    .subheader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .buttons {
        display: flex;
        flex-direction: row;
    }

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 50px;
    }
`;

export const PriceField = styled.span`
    font-size: 16px;
`;

const GifContainer = styled(ImageContainer)`
    img {
        object-position: left bottom;
    }
`;

type PackageProps = {
    setDisplayPackage: Dispatch<SetStateAction<boolean>>;
};

const Package = ({ setDisplayPackage }: PackageProps) => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { enhancedPackage } = ckdConfiguration;
    const { list } = enhancedPackage;
    const { formatCurrency } = useCompanyFormatting();

    const onClick = useCallback(() => {
        setDisplayPackage(true);
    }, [setDisplayPackage]);

    return (
        <SelectionContainer>
            {isMobile && (
                <GifContainer>
                    <video autoPlay loop muted playsInline>
                        <source src={enhancedPackage.mobileGif} type="video/mp4" />
                    </video>
                </GifContainer>
            )}
            <div className="configurator">
                <PackageContainer>
                    <div className="subheader">
                        <SectionTitle>{enhancedPackage.headline}</SectionTitle>
                        <PriceField>{formatCurrency(enhancedPackage.price)}</PriceField>
                    </div>
                    <ul>
                        {list.map((item: string) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                    <div className="buttons">
                        <Button backgroundColor="#737278" onClick={onClick}>
                            {enhancedPackage.buttons.moreDetails}
                        </Button>
                        <AddField
                            defaultColor="#737278"
                            disabled={false}
                            fieldName="enhancedPackage"
                            name="enhancedPackage"
                        />
                    </div>
                </PackageContainer>
            </div>
        </SelectionContainer>
    );
};

export default Package;
