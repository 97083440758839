import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentZone } from '../../selectors';
import { formatDateTimeOnly, formatDateTimeWithOffset } from '../../utilities/dates';

const useFormatDateTime = (withOffset = true) => {
    const { t } = useTranslation();
    const { timezone } = useSelector(getCurrentZone);

    return useMemo(
        () =>
            withOffset
                ? formatDateTimeWithOffset(timezone, t('dateFormats.dateTimeFormat'))
                : formatDateTimeOnly(timezone, t('dateFormats.dateTimeFormat')),
        [timezone, withOffset, t]
    );
};

export default useFormatDateTime;
