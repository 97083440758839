import { faArrowCircleDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext, touch } from 'redux-form';
import styled from 'styled-components';
import { getAccessToken } from '../../../selectors';
import IconButton from '../IconButton';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    font-size: 1.21rem;
    line-height: normal;
`;

export const Label = styled.label`
    flex-grow: 1;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Placeholder = styled.span`
    color: #808080;
    text-transform: ${props => props.theme.casing};
`;

export const Input = styled.input`
    display: none;
`;

export const getFileName = file => {
    if (file instanceof File) {
        return file.name;
    }

    return file?.metaData?.filename;
};

const Store = {};

const BoxedFileInput = ({
    placeholder,
    value,
    onRemove,
    onChange: change,
    disabled,
    downloadable = false,
    ...props
}) => {
    const fileName = getFileName(value);
    const { purpose, url } = value;
    const { name } = props;

    const dispatch = useDispatch();
    const { form } = useContext(ReduxFormContext);

    const remove = useCallback(() => onRemove(value), [onRemove, value]);

    const onChange = useCallback(
        event => {
            const file = event.target.files[0];
            file.purpose = purpose;
            change(file);
            dispatch(touch(form, name));
        },
        [change, purpose, form, name, dispatch]
    );

    const token = useSelector(getAccessToken);

    const download = useCallback(async () => {
        if (Store[url]) {
            // still loading
            if (Store[url].loading) {
                return;
            }

            // successfully loaded
            if (Store[url].blob) {
                FileSaver.saveAs(Store[url].blob, fileName);

                return;
            }
        }

        // loading start
        Store[url] = { loading: true };

        try {
            const headers = { Authorization: `Bearer ${token}` };
            const response = await fetch(url, { headers });

            if (response.ok) {
                const blob = await response.blob();

                // save the file
                FileSaver.saveAs(blob, fileName);
                // update Store
                Store[url].blob = blob;
            }

            // we have finished loading
            Store[url].loading = false;
        } catch (error) {
            console.error(error);

            Store[url].loading = false;
        }
    }, [fileName, url, token]);

    return (
        <Container>
            <Label disabled={disabled}>
                {fileName ? (
                    <span>{fileName}</span>
                ) : (
                    <>
                        <Placeholder>{placeholder}</Placeholder>{' '}
                        <Input disabled={disabled} onChange={onChange} type="file" {...props} />
                    </>
                )}
            </Label>
            {url && downloadable && <IconButton icon={faArrowCircleDown} onClick={download} size="lg" />}
            {fileName && !disabled && <IconButton icon={faTimesCircle} onClick={remove} size="lg" />}
        </Container>
    );
};

BoxedFileInput.displayName = 'BoxedFileInput';

export const attachmentPropType = PropTypes.shape({
    at: PropTypes.string,
    metaData: PropTypes.shape({ filename: PropTypes.string }),
    purpose: PropTypes.string,
    url: PropTypes.string,
});

BoxedFileInput.propTypes = {
    disabled: PropTypes.bool,
    downloadable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([attachmentPropType, PropTypes.instanceOf(File)]).isRequired,
};

export default BoxedFileInput;
