import { WatchQueryFetchPolicy } from '@apollo/client';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useZone } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { FinanceDataFragment } from '../../../data/useFinanceProducts.graphql';
import useLoadVariants from '../../../data/useLoadVariants';
import processVariantWithFormattedPrices, { ProcessedVariant } from './processVariantWithFormattedPrices';
import sortVariants from './sortVariants';

const useVariants = (
    financeProduct: FinanceDataFragment | null,
    roundNumber: (value: number) => number,
    formatCurrency: (value: number, currency?: string) => string,
    currencySymbol: string,
    channel = Channel.NEW,
    dealerId: string,
    networkPolicy: WatchQueryFetchPolicy = 'cache-first'
): { variants: ProcessedVariant[]; loading: boolean } => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    // get our variants
    const { variants, loading } = useLoadVariants(networkPolicy, channel, dealerId);
    const { id: zoneId } = useZone();

    // we need to do some processing
    return useMemo(
        () => ({
            variants: variants
                .slice()
                .sort(sortVariants)
                .map(
                    processVariantWithFormattedPrices(
                        financeProduct,
                        roundNumber,
                        formatCurrency,
                        currencySymbol,
                        zoneId,
                        t,
                        ct
                    )
                ),
            loading,
        }),
        [variants, financeProduct, roundNumber, formatCurrency, currencySymbol, zoneId, t, loading, ct]
    );
};

export default useVariants;
