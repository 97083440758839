// @ts-ignore
import { Modal, Actions, GrayButton, DarkButton } from '@appvantageasia/afc-ui';
import React, { useMemo, useCallback } from 'react';
import { SubmitSuccess } from '../../ui/calculator';

export type ConfirmationModalProps = {
    title: string;
    content: string;
    confirm: {
        label?: string;
        onClick?: () => void;
    };
    decline: {
        label?: string;
        onClick?: () => void;
    };
    onClose: () => void;
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
    const { title, content, confirm: confirmProp, decline: declineProp, onClose } = props;

    const onClickWithClose = useCallback(
        (onClick?: Function) => {
            // execute click if there's any
            if (onClick) {
                onClick();
            }
            // close the modal after execution
            onClose();
        },
        [onClose]
    );

    const confirm = useMemo(() => {
        const { label, onClick } = confirmProp;

        return {
            label: label || 'Yes',
            onClick: () => onClickWithClose(onClick),
        };
    }, [confirmProp, onClickWithClose]);

    const decline = useMemo(() => {
        const { label, onClick } = declineProp;

        return {
            label: label || 'No',
            onClick: () => onClickWithClose(onClick),
        };
    }, [declineProp, onClickWithClose]);

    return (
        <Modal title={title} isFront showTitle>
            <SubmitSuccess>
                <p>{content}</p>
            </SubmitSuccess>
            <Actions>
                <DarkButton onClick={decline.onClick}>{decline.label}</DarkButton>
                <GrayButton onClick={confirm.onClick}>{confirm.label}</GrayButton>
            </Actions>
        </Modal>
    );
};

export default ConfirmationModal;
