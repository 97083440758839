// @ts-ignore
import { defaultTheme as calculatorTheme } from '@appvantageasia/afc-calculator-ui';
import { DefaultTheme } from 'styled-components';

const defaultTheme = (casing: string): DefaultTheme => ({
    version: 'v1',

    background: '#fff',
    themeHighlightColour: '#ef2c72',
    casing,

    // by default use the large theme
    display: 'large',

    // logo to display in header
    logo: null,

    webFontUrl: null,

    // theme for root routes
    // such as login, root error boundary etc..
    rootLayout: {
        background: '#CECECE',
    },

    calculator: {
        ...calculatorTheme.calculator,
        titleTransform: casing,
        labelTransform: casing,
        valueColor: '#ef2c72',
    },

    // color sets
    colors: {
        // default colors applied to all elements
        defaultColor: '#444444',
        // active color (a.k.a primary color)
        activeColor: '#ef2c72',
        // disable color
        disabledColor: '#999999',
    },

    // component sizing
    sizing: {
        // sizing for title level 1 elements
        h1: {
            large: { fontSize: '32px', lineHeight: '22px', letterSpacing: 0, fontWeight: 500 },
            small: { fontSize: '26px', lineHeight: '22px', letterSpacing: 0, fontWeight: 500 },
        },

        // sizing for title level 2 elements
        h2: {
            large: { fontSize: '26px', lineHeight: '22px', letterSpacing: 0, fontWeight: 400 },
            small: { fontSize: '20px', lineHeight: '22px', letterSpacing: 0, fontWeight: 400 },
        },

        // sizing for title level 3 elements
        h3: {
            large: { fontSize: '20px', lineHeight: '22px', letterSpacing: 0, fontWeight: 400 },
            small: { fontSize: '18px', lineHeight: '22px', letterSpacing: 0, fontWeight: 400 },
        },

        // sizing for paragraph elements
        p: {
            large: { fontSize: '16px', lineHeight: '18px', letterSpacing: 0, fontWeight: 'normal' },
            small: { fontSize: '16px', lineHeight: '18px', letterSpacing: 0, fontWeight: 'normal' },
        },

        disabled: {
            large: { fontSize: '14px', lineHeight: '16px', letterSpacing: 0, fontWeight: 'normal' },
            small: { fontSize: '14px', lineHeight: '16px', letterSpacing: 0, fontWeight: 'normal' },
        },

        // sizing for list elements (ul/ol)
        list: {
            large: { fontSize: '16px', lineHeight: '22px', letterSpacing: 0, fontWeight: 'normal' },
            small: { fontSize: '16px', lineHeight: '22px', letterSpacing: 0, fontWeight: 'normal' },
        },
    },
});

export default defaultTheme;
