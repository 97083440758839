import React from 'react';
import GuaranteedBuybackNamirial from '../../DraftFlow/components/GuaranteedBuybackNamirial';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';

class GuaranteedBuybackStep extends FlowStep<RemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'guaranteedBuyback';
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        return !!application?.steps?.guaranteedBuyback;
    }

    public render(): React.ReactElement | null {
        const { token, source } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        return <GuaranteedBuybackNamirial source={source} token={token} />;
    }
}

export default GuaranteedBuybackStep;
