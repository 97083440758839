import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useMedia from 'use-media';
import { getRuntimeSettings } from '../../../../../selectors';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { TimepieceValues } from '../Timepiece';

const useTimepieceImage = (timepiece?: TimepieceValues) => {
    const isMobileSource = useMedia({ maxWidth: breakpoints.sm });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const { imageOptions, options } = ckdConfiguration.timepiece;

    return useMemo(() => {
        if (timepiece?.hands === options.hands.dropdownOptions[0].value) {
            switch (timepiece?.colouredRing) {
                case options.colouredRing.dropdownOptions[0].value:
                    return isMobileSource ? imageOptions[0].mobileImage : imageOptions[0].desktopImage;
                case options.colouredRing.dropdownOptions[1].value:
                    return isMobileSource ? imageOptions[2].mobileImage : imageOptions[2].desktopImage;
                case options.colouredRing.dropdownOptions[2].value:
                    return isMobileSource ? imageOptions[4].mobileImage : imageOptions[4].desktopImage;
                default:
                    return isMobileSource ? imageOptions[0].mobileImage : imageOptions[0].desktopImage;
            }
        } else {
            switch (timepiece?.colouredRing) {
                case options.colouredRing.dropdownOptions[0].value:
                    return isMobileSource ? imageOptions[1].mobileImage : imageOptions[1].desktopImage;
                case options.colouredRing.dropdownOptions[1].value:
                    return isMobileSource ? imageOptions[3].mobileImage : imageOptions[3].desktopImage;
                case options.colouredRing.dropdownOptions[2].value:
                    return isMobileSource ? imageOptions[5].mobileImage : imageOptions[5].desktopImage;
                default:
                    return isMobileSource ? imageOptions[1].mobileImage : imageOptions[1].desktopImage;
            }
        }
    }, [timepiece, options.hands.dropdownOptions, options.colouredRing.dropdownOptions, isMobileSource, imageOptions]);
};

export default useTimepieceImage;
