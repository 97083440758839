import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { isNil, isArray, isEmpty } from 'lodash/fp';
import { useMemo } from 'react';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';

const isInvalidCalculatorValues = (values?: Partial<CalculatorValues>) =>
    isNil(values?.monthlyInstalments) || (isArray(values?.monthlyInstalments) && !values?.monthlyInstalments[0]);

const useApplyDisabled = (values?: Partial<CalculatorValues>, errors?: CalculatorContext<CalculatorValues>['errors']) =>
    useMemo(() => !isEmpty(errors) || !values || isInvalidCalculatorValues(values), [values, errors]);

export default useApplyDisabled;
