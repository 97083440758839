import styled from 'styled-components';
import { TextAlignCenter } from '../TextAlign';

const CarModelFooter = styled(TextAlignCenter)`
    color: #666;
    font-size: 1rem;
    margin-top: 50px;
`;

export default CarModelFooter;
