import { TFunction } from 'i18next';
import { capitalize, get, getOr } from 'lodash/fp';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadAffinBank } from '../../../../actions';
import { useCountry } from '../../../../hookSelectors';
import { BankPresetOption, Channel, CustomerDetailsSource } from '../../../../schema';
import {
    getAffinBankRaces,
    getRuntimeSettings,
    getAffinBankIdTypes,
    getAffinBankResidentialStatuses,
} from '../../../../selectors';
import useOptions from '../../../../utilities/constants/useOptions';
import { getMinDateOfBirth } from '../../../../utilities/dates';
import {
    fromContextValidation,
    getLastModified,
    onTelKeyPress,
    validateNirc,
    yupExt,
} from '../../../../utilities/forms';
import { ApplicationData } from '../../../routes/ApplicationRoute';
import SubTitle from '../../../ui/SubTitle';
import useCustomerSourceOptions from '../../../utilities/useCustomerSourceOptions';
import usePhone from '../../../utilities/usePhone';
import { useDataMask } from '../../partialForms/CustomerInformationForm';
import useFormatDateTime from '../../useFormatDateTime';
import CountryField from '../CountryField';
import DateField from '../DateField';
import SelectField from '../SelectField';
import TextField from '../TextField';

export type CustomerDetailsProps = {
    application?: ApplicationData;
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const myInfoSource = [CustomerDetailsSource.MYINFO, CustomerDetailsSource.NOT_APPLICABLE];

export const getLabelWithFlag = (label: string, source?: CustomerDetailsSource) => {
    if (source && myInfoSource.includes(source)) {
        return `${label} (${capitalize(CustomerDetailsSource.MYINFO)})`;
    }

    return label;
};

const CustomerDetails = ({ application, allowPrimaryInfoChanges = false, disabled = false }: CustomerDetailsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { code } = useCountry();

    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const customer = getOr(formValues, `${sectionPrefix}`, formValues);

    const isAffinBank = getOr('', 'financeProduct.bank.presetOption', formValues) === BankPresetOption.AFFINBANK;
    const isTTBBank = getOr('', 'financeProduct.bank.presetOption', formValues) === BankPresetOption.TTBBANK;

    useEffect(() => {
        if (isAffinBank) {
            dispatch(loadAffinBank());
        }
    }, [dispatch, isAffinBank]);

    const racesAffin = useSelector(getAffinBankRaces);
    const idTypesAffin = useSelector(getAffinBankIdTypes);
    const residentialStatusesAffin = useSelector(getAffinBankResidentialStatuses);

    const { code: phoneCountryCode, minDigits: minPhoneDigit, maxDigits: maxPhoneDigit } = usePhone();
    const beingMask = useDataMask();

    const withMyInfo = getOr(false, sectionPrefix ? `${sectionPrefix}.withMyInfo` : 'withMyInfo', formValues);

    // get runtime settings
    const { useCustomerBirthDay } = useSelector(getRuntimeSettings);
    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);

    const { genders, residentialStatuses } = useCustomerSourceOptions();
    const { title, nationalities, races, maritalStatuses } = useOptions();

    const formatDateTime = useFormatDateTime();
    const lastModified = getLastModified(formValues.customer?.version, formatDateTime);

    const isEvent = application?.channel === Channel.EVENT;

    const showDateOfBirth = code === 'NZ' || isEvent || useCustomerBirthDay;

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.customerDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag(t('customerDetails.label.title'), customer?.title?.source)}
                        name="title.value"
                        options={title}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag(t('customerDetails.label.fullName'), customer?.name?.source)}
                        maxLength="200"
                        name="name.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag(t('customerDetails.label.firstName'), customer?.firstName?.source)}
                        maxLength="200"
                        name="firstName.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag(t('customerDetails.label.lastName'), customer?.lastName?.source)}
                        maxLength="200"
                        name="lastName.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag(t('customerDetails.label.email'), customer?.email?.source)}
                        name="email.value"
                        type="email"
                        withFocusClear={beingMask}
                    />
                </div>
                {withMyInfo && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label={getLabelWithFlag(t('customerDetails.label.emailBi'), customer?.emailBis?.source)}
                            name="emailBis.value"
                            type="email"
                            withFocusClear={beingMask}
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.mobile'), customer?.phone?.source)}
                        name="phone.value"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        type="tel"
                        withFocusClear={beingMask}
                        disabled
                    />
                </div>
                {withMyInfo && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label={getLabelWithFlag(t('customerDetails.label.mobileBi'), customer?.phoneBis?.source)}
                            maxLength={maxPhoneDigit}
                            minLength={minPhoneDigit}
                            name="phoneBis.value"
                            onKeyPress={onTelKeyPress}
                            prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                            type="tel"
                            withFocusClear={beingMask}
                        />
                    </div>
                )}
                {!isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                t('customerDetails.label.nationality'),
                                customer?.details?.nationality?.source
                            )}
                            name="details.nationality.value"
                            options={nationalities}
                            disabled
                        />
                    </div>
                )}
                {code !== 'NZ' && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={getLabelWithFlag(
                                t('customerDetails.label.identityNumber'),
                                customer?.identityNumber?.source
                            )}
                            maxLength="200"
                            name="identityNumber.value"
                            withFocusClear={beingMask}
                            disabled
                        />
                    </div>
                )}
                {showDateOfBirth && !isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={getLabelWithFlag(
                                t('customerDetails.label.dateOfBirth'),
                                customer?.dateOfBirth?.source
                            )}
                            maxDate={minDateOfBirth}
                            name="dateOfBirth.value"
                            disabled
                        />
                    </div>
                )}
                {!isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(t('customerDetails.label.race'), customer?.details?.race?.source)}
                            name={isAffinBank ? 'details.thirdParty.affinBank.race.value' : 'details.race.value'}
                            options={isAffinBank ? racesAffin : races}
                            disabled
                        />
                    </div>
                )}
                {isAffinBank && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={getLabelWithFlag(
                                    t('customerDetails.label.idType'),
                                    customer?.details?.thirdParty?.affinBank?.idType?.source
                                )}
                                name="details.thirdParty.affinBank.idType.value"
                                options={idTypesAffin}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <CountryField
                                label={getLabelWithFlag(
                                    t('customerDetails.label.idIssueCountry'),
                                    customer?.details?.thirdParty?.affinBank?.idIssueCountry?.source
                                )}
                                name="details.thirdParty.affinBank.idIssueCountry.value"
                                disabled
                            />
                        </div>
                    </>
                )}
                {!isTTBBank && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={getLabelWithFlag(
                                    t('customerDetails.label.gender'),
                                    customer?.details?.gender?.source
                                )}
                                name="details.gender.value"
                                options={genders}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                disabled={!allowPrimaryInfoChanges && disabled}
                                label={getLabelWithFlag(
                                    t('customerDetails.label.maritalStatus'),
                                    customer?.details?.maritalStatus?.source
                                )}
                                name="details.maritalStatus.value"
                                options={maritalStatuses}
                                sort={false}
                            />
                        </div>
                    </>
                )}
                {isAffinBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <CountryField
                            label={getLabelWithFlag(
                                t('customerDetails.label.residenceCountry'),
                                customer?.details?.thirdParty?.affinBank?.residentialCountry?.source
                            )}
                            name="details.thirdParty.affinBank.residentialCountry.value"
                            disabled
                        />
                    </div>
                )}
                {!isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                t('customerDetails.label.residentialStatus'),
                                isAffinBank
                                    ? customer?.details?.thirdParty?.affinBank?.residentialStatus?.source
                                    : customer?.details?.residentialStatus?.source
                            )}
                            name={
                                isAffinBank
                                    ? 'details.thirdParty.affinBank.residentialStatus.value'
                                    : 'details.residentialStatus.value'
                            }
                            options={isAffinBank ? residentialStatusesAffin : residentialStatuses}
                            sort={false}
                            disabled
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        fixedValue={lastModified}
                        label={getLabelWithFlag(t('customerDetails.label.lastModified'), customer?.lastModified)}
                        name="__exclude.lastModified"
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export const schema = (t: TFunction) => ({
    // @ts-ignore
    dateOfBirth: yup.lazy((values, options) => {
        const shouldValidate = get(['context', 'useCustomerBirthDay'], options);

        const isTTBBank = get(['context', 'application', 'bank', 'presetOption'], options) === BankPresetOption.TTBBANK;

        if (shouldValidate && !isTTBBank) {
            return yupExt.customerProperty().shape({
                value: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    }),
    email: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .email(t('common.error.email'))
            .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
    }),
    emailBis: yupExt.customerProperty().shape({
        value: yup
            .string()
            .email(t('common.error.email'))
            .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
    }),
    // @ts-ignore
    identityNumber: yup.lazy((values: any, options: any) => {
        const { context, parent } = options;

        const identityValidator = validateNirc(parent?.details?.nationality?.value || context.countryCode);

        if (context.countryCode === 'NZ') {
            return yupExt.customerProperty().shape({
                value: yup.string().nullable().notRequired(),
            });
        }

        return yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .test(
                    'invalid-identity',
                    t('common.error.identityNumber'),
                    (value: any) => !value || identityValidator(value, parent?.dateOfBirth?.value)
                ),
        });
    }),
    name: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
    }),
    firstName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(100, t('common.error.lessThanOrEqual', { max: 100 })),
    }),
    lastName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(100, t('common.error.lessThanOrEqual', { max: 100 })),
    }),
    phone: yupExt.customerProperty().shape({
        value: fromContextValidation(
            yup.string().required(t('common.error.required')),
            'phonePattern',
            t('common.error.mobile')
        ),
    }),
    // @ts-ignore
    phoneBis: yup.lazy((values, options) => {
        const { parent } = options;

        if (parent?.withMyInfo) {
            return yupExt.customerProperty().shape({
                value: fromContextValidation(yup.string(), 'phonePattern', t('common.error.mobile')),
            });
        }

        return yup.mixed().notRequired();
    }),
});

const optionalForTTB = validation => {
    // @ts-ignore
    return yup.lazy((values, options) => {
        const isTTBBank =
            get(['context', 'application', 'financeProduct', 'bank', 'presetOption'], options) ===
            BankPresetOption.TTBBANK;

        if (isTTBBank) {
            return yup.mixed().notRequired();
        }

        return validation;
    });
};

export const detailsSchema = (t: TFunction) => ({
    gender: optionalForTTB(
        yupExt
            .customerProperty()
            .shape({ value: yup.string().required(t('common.error.required')) })
            .required(t('common.error.required'))
    ),
    maritalStatus: optionalForTTB(
        yupExt
            .customerProperty()
            .shape({ value: yup.string().required(t('common.error.required')) })
            .required(t('common.error.required'))
    ),

    // @ts-ignore
    race: yup.lazy((values, options) => {
        const { context } = options;

        const isTTBBank =
            get(['context', 'application', 'financeProduct', 'bank', 'presetOption'], options) ===
            BankPresetOption.TTBBANK;

        // validate only when it's not an event
        // because porsche finder finance won't have this value for it's applicant
        if (context?.application?.event?.id || isTTBBank) {
            return yup.mixed().notRequired();
        }

        return yupExt
            .customerProperty()
            .shape({ value: yup.string().required(t('common.error.required')) })
            .required(t('common.error.required'));
    }),
    residentialStatus: optionalForTTB(
        yupExt
            .customerProperty()
            .shape({ value: yup.string().required(t('common.error.required')) })
            .required(t('common.error.required'))
    ),
});

export default CustomerDetails;
