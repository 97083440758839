import { omit, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export const Error = styled.p`
    color: #ff0000;
    margin: 10px 0;
    text-align: center;
`;

const CalculatorError = ({ errors, disabled = false, ...props }) => {
    if (disabled) {
        return null;
    }
    // we want to exclude calculator error, usually for invalid configuration
    const cleanedErrors = useMemo(() => omit(['calculator'], errors), [errors]);

    if (isEmpty(cleanedErrors)) {
        // there's no error with displaying
        return null;
    }

    // get the first one
    const error = Object.values(cleanedErrors)[0];

    return <Error {...props}>{error}</Error>;
};

CalculatorError.propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.shape({}),
};

export default CalculatorError;
