import { getOr, omit, set } from 'lodash/fp';
import React from 'react';
import {
    createCustomer,
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../api/customer.graphql';
import {
    completeConsents,
    CompleteConsentsMutation,
    CompleteConsentsMutationVariables,
    completeKyc,
    CompleteKycMutation,
    CompleteKycMutationVariables,
    completeReservationToFinance,
    CompleteReservationToFinanceMutation,
    CompleteReservationToFinanceMutationVariables,
    create,
    CreateMutation,
    CreateMutationVariables,
} from '../../../api/draft.graphql';
import {
    createUsedVariant,
    CreateUsedVariantMutation,
    CreateUsedVariantMutationVariables,
} from '../../../api/miscellaneous.graphql';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import { CustomerType } from '../../../schema';
import { getCalculatorPayload } from '../../../utilities/calculator';
import { mapCreateCustomerToPayload } from '../../../utilities/customer';
import { prepareForGraphQL } from '../../../utilities/forms';
import { buildInsuranceCalculatorValues } from '../../DraftFlow/components/DraftPage/Inner';
import { VehicleDataFragment } from '../../DraftFlow/components/MyInfo/MyInfoStep.graphql';
import { InsuranceCalculatorStepValues } from '../../InsuranceDraftFlow/steps/DraftStep';
import { FlowStep, ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';
import Applicant from '../components/Applicant';

export type EventApplicantStepValues = ApplicationCustomerDataFragment & {
    details?: { vehicles?: Array<{ allowTradeIn: boolean }> };
    referenceId: string;

    insurance?: {
        calculator: InsuranceCalculatorStepValues;
        comment?: string;
    };
};

export type VehicleForm = VehicleDataFragment & { allowTradeIn: boolean };
class EventApplicantStep extends ReduxFormFlowStep<EventFlowState, EventApplicantStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'applicant';
    }

    // eslint-disable-next-line class-methods-use-this
    get label(): string {
        return this.t('eventApplicantPage.label.step');
    }

    public get isCompleted(): boolean {
        const { application, reservation } = this.state;

        return reservation ? application?.steps?.reservationToFinance ?? false : !!application?.id;
    }

    // eslint-disable-next-line class-methods-use-this
    public get ignoreOnBack(): boolean {
        const { application } = this.state;

        // if customer data does not come from my info, we skip this step
        return !application?.customer?.withMyInfo;
    }

    public render(): React.ReactElement | null {
        const {
            application,
            hasTradeIn,
            hasTestDrive,
            dealerId,
            event,
            isCalculatorEnabled,
            appliedForFinancing,
            appliedForInsurance,
            bank,
            insuranceCompany,
            insuranceCalculator,
        } = this.state;

        if (!event) {
            throw new Error('event missing in state');
        }

        if (!application) {
            throw new Error('application or token missing in state');
        }

        if (!dealerId) {
            throw new Error('dealer id missing in state');
        }

        if (appliedForFinancing && !bank) {
            throw new Error('bank missing in state');
        }

        if (appliedForInsurance && !insuranceCompany) {
            throw new Error('insurance company missing in state');
        }

        return (
            <Applicant
                appliedForFinancing={appliedForFinancing}
                appliedForInsurance={appliedForInsurance}
                backStep={this.getBackContext()}
                bank={bank}
                dealerId={dealerId}
                event={event}
                hasTestDrive={hasTestDrive}
                hasTradeIn={hasTradeIn}
                initialValues={{
                    ...application?.customer,
                    consentDraft: { consents: {} },
                    ...buildInsuranceCalculatorValues(insuranceCalculator),
                }}
                insuranceCalculator={insuranceCalculator}
                insuranceCompany={insuranceCompany}
                isCalculatorEnabled={isCalculatorEnabled}
                onSubmit={this.submit}
            />
        );
    }

    protected async execute(values: EventApplicantStepValues) {
        const { apolloClient, contentTranslation } = this;
        const {
            token: tokenInState,
            zone,
            event,
            channel,
            calculator,
            promo,
            hasTestDrive,
            carOfInterest,
            country,
            finderVehicle,
            bookingId,
            dealerId,
            appliedForFinancing,
            appliedForInsurance,
            bank,
            csvConfigurator,
            reservation,
            isCalculatorEnabled,
        } = this.state;

        const { mapIntlValue } = contentTranslation;

        const vehicles = (values?.details?.vehicles || []) as VehicleForm[];

        // check if there's trade in vehicle selected
        const tradeIn = vehicles.find(vehicle => vehicle.allowTradeIn);

        let customerId = values.id;

        let driverLicensePassDate: Date | undefined;
        // For apply insurance, issue date shown in customer form and used as driver license pass date
        if (appliedForInsurance) {
            driverLicensePassDate = values.details?.drivingLicense?.[0]?.classes?.[0]?.issueDate as Date;
        }

        if (!customerId) {
            // create customer payload
            const customerPayload = mapCreateCustomerToPayload(zone.id, {
                ...prepareForGraphQL(omit(['details.vehicles', 'details.employment'], values)),
                zoneId: zone.id,
                type: CustomerType.INDIVIDUAL,
            });

            // create a new customer
            const createResponse = await apolloClient.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
                mutation: createCustomer,
                variables: customerPayload,
            });
            const customer = createResponse?.data?.customer;

            if (!customer) {
                throw new Error('Failed to create customer');
            }

            // keep the ID for the newly created customer
            customerId = customer.id;
        } else {
            // create a clean payload
            const customerPayload = omit(
                ['id', 'type', 'details.vehicles', 'details.employment', 'consentDraft', 'referenceId'],
                values
            ) as UpdateCustomerMutationVariables['data'];

            // update existing customer
            await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                mutation: updateCustomer,
                variables: {
                    id: customerId,
                    token: tokenInState,
                    data: prepareForGraphQL(customerPayload),
                },
            });
        }

        let variantId;
        /** No new car setup */
        if (carOfInterest?.variantName) {
            const variantResponse = await apolloClient.mutate<
                CreateUsedVariantMutation,
                CreateUsedVariantMutationVariables
            >({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId: country.id,
                        // for express use model name as variant name
                        name: mapIntlValue({ [this.i18n.languages[0]]: carOfInterest.variantName }),
                        makeName: mapIntlValue({ [this.i18n.languages[0]]: carOfInterest.variantName }),
                        modelName: mapIntlValue({ [this.i18n.languages[0]]: carOfInterest.variantName }),
                    },
                },
            });
            const expressVariant = variantResponse.data?.variant;
            if (!expressVariant) {
                throw new Error('failed to create the express variant');
            }
            variantId = expressVariant.id;
        } else if (finderVehicle) {
            // create variant for porsche finder vehicle
            const variantResponse = await apolloClient.mutate<
                CreateUsedVariantMutation,
                CreateUsedVariantMutationVariables
            >({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId: country.id,
                        // for express use model name as variant name
                        name: mapIntlValue({ [this.i18n.languages[0]]: finderVehicle.name }),
                        makeName: mapIntlValue({ [this.i18n.languages[0]]: 'Porsche' }),
                        modelName: mapIntlValue({
                            [this.i18n.languages[0]]:
                                finderVehicle.listing?.vehicle.modelSeries.localize || finderVehicle.name,
                        }),
                    },
                },
            });
            const finderVehicleVariant = variantResponse.data?.variant;
            if (!finderVehicleVariant) {
                throw new Error('failed to create the finder vehicle variant');
            }

            variantId = finderVehicleVariant.id;
        } else if (csvConfigurator) {
            const locale = this.i18n.languages[0];
            const variantResponse = await apolloClient.mutate<
                CreateUsedVariantMutation,
                CreateUsedVariantMutationVariables
            >({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId: country.id,
                        // for express use model name as variant name
                        name: mapIntlValue({ [locale]: csvConfigurator?.variant?.name ?? '' }),
                        makeName: mapIntlValue({ [locale]: 'Porsche' }),
                        modelName: mapIntlValue({
                            [locale]: csvConfigurator.model?.name ?? '',
                        }),
                    },
                },
            });
            const csvVehicleVariant = variantResponse.data?.variant;
            if (!csvVehicleVariant) {
                throw new Error('failed to create the csv vehicle variant');
            }

            variantId = csvVehicleVariant.id;
        } else {
            variantId = calculator?.variant || carOfInterest?.variantId;
        }

        // next we build the application
        const applicationPayload = prepareForGraphQL({
            // add event id
            eventId: event?.id,
            // add zone id
            zoneId: zone.id,
            // add dealer id
            dealerId,
            // doesn't exist in event
            appliedForFinancing: appliedForFinancing ?? false,
            proceedWithCustomerDevice: false,
            optionIds: [],
            // add the customer id
            customerId,
            // include booking id if porsche finder flow
            bookingId: finderVehicle && bookingId,
            // add the channel
            channel,
            // porsche finder flow
            financeProductId: calculator?.financeProduct,
            variantId,
            assetCondition: carOfInterest?.assetCondition,
            bank: calculator?.bank ? { id: calculator?.bank } : null,
            // then calculator payload
            calculator: calculator ? getCalculatorPayload(calculator) : {},
            // promotion code
            promoCodeId: promo?.id,
            // trade in vehicle
            tradeIn: tradeIn ? omit(['allowTradeIn'], tradeIn) : null,
            hasTradeIn: !!tradeIn,
            hasTestDrive,
            // application locale
            locale: this.i18n.languages[0],
            finderVehicleId: getOr(null, 'id', finderVehicle),
            csvConfigurator,
            reservationVersionId: reservation?.version.id,
            isCalculatorEnabled,
            // application insurance
            ...(appliedForInsurance &&
                values.insurance && {
                    insurance: {
                        calculator: {
                            ...values.insurance.calculator,
                            displacement: calculator?.displacement || 0,
                            price: calculator?.carPrice || 0,
                            coe: calculator?.coe ? { amount: calculator.coe } : undefined,
                            driverLicensePassDate,
                        },
                        comment: values.insurance.comment,
                    },
                }),
        });

        const draftResponse = await apolloClient.mutate<CreateMutation, CreateMutationVariables>({
            mutation: create,
            variables: { data: applicationPayload },
        });

        const { application, token } = draftResponse.data?.response || {};

        if (!application) {
            throw new Error('application missing in state');
        }

        if (!token) {
            throw new Error('token missing in state');
        }

        // complete consents
        if (values.referenceId) {
            const variables = { token, eventId: values.referenceId };

            const { data } = await apolloClient.mutate<CompleteConsentsMutation, CompleteConsentsMutationVariables>({
                mutation: completeConsents,
                variables,
            });

            this.stateStore.update({ ...data?.response });
        }

        // update the state
        // store in state the vehicles from myinfo
        this.stateStore.update({ application: set('customer.details.vehicles', vehicles, application), token });

        // complete kyc if appliedForFinancing enabled
        if (appliedForFinancing && bank?.isKYCMandatory) {
            const apiResponse = await apolloClient.mutate<CompleteKycMutation, CompleteKycMutationVariables>({
                mutation: completeKyc,
                variables: { token },
            });

            this.stateStore.update({ ...apiResponse.data?.response });
        }

        if (reservation?.id) {
            const apiResponse = await apolloClient.mutate<
                CompleteReservationToFinanceMutation,
                CompleteReservationToFinanceMutationVariables
            >({
                mutation: completeReservationToFinance,
                variables: { token },
            });

            this.stateStore.update({ ...apiResponse.data?.response });
        }

        // update cache key
        this.flow.updateCacheKey();

        return this.nextStep;
    }

    public async executeBefore(): Promise<FlowStep<EventFlowState>> {
        const { application } = this.state;

        // if customer data does not come from my info, we skip this step
        if (!application?.customer?.withMyInfo) {
            return this.nextStep as FlowStep<EventFlowState>;
        }

        return this;
    }
}

export default EventApplicantStep;
