import { isNil, isArray } from 'lodash/fp';
import { useMemo } from 'react';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';

const useApplyDisabled = (values: Partial<CalculatorValues>, isCalculatorEnabled: boolean) =>
    useMemo(() => {
        if (isCalculatorEnabled) {
            return (
                isNil(values?.coe) ||
                !values?.monthlyInstalments ||
                (isArray(values?.monthlyInstalments) && !values?.monthlyInstalments[0])
            );
        }

        return false;
    }, [isCalculatorEnabled, values]);

export default useApplyDisabled;
