import React from 'react';
import { completeOTP, CompleteOtpMutation, CompleteOtpMutationVariables } from '../../../api/draft.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import ApprovalPage from '../../DraftFlow/components/Approval';
import { ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';

export type OTPStepValues = { code: string; secret: string };

class OTPStep extends ReduxFormFlowStep<EventFlowState, OTPStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'otp';
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.approval || false;
    }

    public async execute({ code: otp, secret }: OTPStepValues) {
        const { apolloClient } = this;
        const { token } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        const apiResponse = await apolloClient.mutate<CompleteOtpMutation, CompleteOtpMutationVariables>({
            mutation: completeOTP,
            variables: { token, secret, otp },
        });

        this.stateStore.update({ ...apiResponse?.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { token, channel } = this.state;

        if (!token) {
            throw new Error('Token missing in state');
        }

        return (
            <>
                <HelmetTitle channel={channel} title="OTP" />
                <ApprovalPage
                    backStep={this.getBackContext()}
                    isLastStep={this.isLastStep}
                    onSubmit={this.submit}
                    token={token}
                />
            </>
        );
    }
}

export default OTPStep;
