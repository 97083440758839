import { ApolloClient, useApolloClient } from '@apollo/client';
// @ts-ignore
import { DarkButton, GrayButton, ActionsV2, Actions, Modal, Footer } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useMedia from 'use-media';
import { attachLoading, setNotification } from '../../../../actions';
import {
    CancelApplicationMutation,
    CancelApplicationMutationVariables,
    cancelApplication,
    completeApplication,
    CompleteApplicationMutation,
    CompleteApplicationMutationVariables,
    ApproveApplicationMutation,
    ApproveApplicationMutationVariables,
    approveApplication,
    declineApplication,
    DeclineApplicationMutation,
    DeclineApplicationMutationVariables,
    concludeAgreementApplication,
    ConcludeAgreementApplicationMutation,
    ConcludeAgreementApplicationMutationVariables,
} from '../../../../api/application.graphql';
import { ApplicationPhase, ApplicationStatus, EventExternalSite } from '../../../../schema';
import { getCompanyIdentifier } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import {
    APPLICATION_CANCEL_NOTIFICATION,
    APPLICATION_COMPLETE_NOTIFICATION,
    APPLICATION_APPROVE_NOTIFICATION,
    APPLICATION_DECLINE_NOTIFICATION,
    APPLICATION_CONCLUDE_AGREEMENT_NOTIFICATION,
} from '../../../../utilities/constants/notification';
import { getApplicationsUrl } from '../../../../utilities/urls';
import ModalContent from '../../../ui/calculator/ModalContent';
import ApplicationStatusMobile from '../../ApplicationRoute/actions/ApplicationStatusMobile';
import DownloadDocumentsModal from '../../ApplicationRoute/actions/DownloadDocumentsModal';
import useDownloadDocuments from '../../ApplicationRoute/actions/useDownloadDocuments';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';
import useApplyForFinancing from './useApplyForFinancing';

export const getStatusMessages = (id: string, status: ApplicationStatus) => {
    switch (status) {
        case ApplicationStatus.CANCELLED:
            return APPLICATION_CANCEL_NOTIFICATION(id);

        case ApplicationStatus.COMPLETED:
            return APPLICATION_COMPLETE_NOTIFICATION(id);

        case ApplicationStatus.APPROVED:
            return APPLICATION_APPROVE_NOTIFICATION(id);

        case ApplicationStatus.DECLINED:
            return APPLICATION_DECLINE_NOTIFICATION(id);

        case ApplicationStatus.AGREEMENTCONCLUDED:
            return APPLICATION_CONCLUDE_AGREEMENT_NOTIFICATION(id);

        default:
            return null;
    }
};

export const titles = {
    [ApplicationStatus.CANCELLED]: 'applicationDetailsPage.titles.cancelled',
    [ApplicationStatus.COMPLETED]: 'applicationDetailsPage.titles.completed',
    [ApplicationStatus.APPROVED]: 'applicationDetailsPage.titles.approved',
    [ApplicationStatus.DECLINED]: 'applicationDetailsPage.titles.declined',
    [ApplicationStatus.AGREEMENTCONCLUDED]: 'applicationDetailsPage.titles.agreementConcluded',
};

export const questions = {
    [ApplicationStatus.CANCELLED]: 'applicationDetailsPage.questions.cancelled',
    [ApplicationStatus.COMPLETED]: 'applicationDetailsPage.questions.completed',
    [ApplicationStatus.APPROVED]: 'applicationDetailsPage.questions.approved',
    [ApplicationStatus.DECLINED]: 'applicationDetailsPage.questions.declined',
    [ApplicationStatus.AGREEMENTCONCLUDED]: 'applicationDetailsPage.questions.agreementConcluded',
};

export type UpdateStateActionProps = {
    application: ApplicationDataFragment;
    showApproveDeclineActions?: boolean;
    approveDisabled?: boolean;
    declineDisabled?: boolean;
};

export const getMutation = (client: ApolloClient<any>, id: string, nextStatus: ApplicationStatus) => {
    const variables = { id };

    switch (nextStatus) {
        case ApplicationStatus.CANCELLED:
            return client.mutate<CancelApplicationMutation, CancelApplicationMutationVariables>({
                mutation: cancelApplication,
                variables,
            });

        case ApplicationStatus.COMPLETED:
            return client.mutate<CompleteApplicationMutation, CompleteApplicationMutationVariables>({
                mutation: completeApplication,
                variables,
            });

        case ApplicationStatus.APPROVED:
            return client.mutate<ApproveApplicationMutation, ApproveApplicationMutationVariables>({
                mutation: approveApplication,
                variables,
            });

        case ApplicationStatus.DECLINED:
            return client.mutate<DeclineApplicationMutation, DeclineApplicationMutationVariables>({
                mutation: declineApplication,
                variables,
            });

        case ApplicationStatus.AGREEMENTCONCLUDED:
            return client.mutate<ConcludeAgreementApplicationMutation, ConcludeAgreementApplicationMutationVariables>({
                mutation: concludeAgreementApplication,
                variables,
            });

        default:
            throw new Error('status not handled');
    }
};

const UpdateStateAction = ({
    application,
    showApproveDeclineActions,
    approveDisabled,
    declineDisabled,
}: UpdateStateActionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useApolloClient();
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const url = getApplicationsUrl(companyCode, locationCode, application);

    const [nextStatus, setNextStatus] = useState<ApplicationStatus | null>(null);
    const { id, identifier, status, event } = application;

    const applyForFinancing = useApplyForFinancing();

    if (!identifier) {
        throw new Error('identifier missing in state');
    }

    const [downloadState, downloadAction] = useDownloadDocuments(application);
    const close = useCallback(() => setNextStatus(null), [setNextStatus]);

    const submit = useCallback(async () => {
        if (!nextStatus) {
            return;
        }

        // wait for the update to be completed
        const promise = getMutation(client, id, nextStatus);
        await dispatch(attachLoading(promise));

        // get notification message
        const message = getStatusMessages(identifier, nextStatus);

        if (message) {
            dispatch(setNotification(message.title, message.message));
        }

        // then redirect
        history.push(url);
    }, [dispatch, history, id, identifier, nextStatus, url, client]);

    const voidDisabled = true;
    const completeDisabled = true;

    let showAgreementConcluded = false;

    const isPorscheFinder = event?.setting?.externalSite === EventExternalSite.PORSCHEFINDER;
    const isIccCheckout = event?.setting?.externalSite === EventExternalSite.ICC;

    if (
        (isPorscheFinder || isIccCheckout) &&
        event?.setting?.reservationPeriod &&
        event?.setting?.reservationPeriod > 0 &&
        status === ApplicationStatus.RECEIVED
    ) {
        showAgreementConcluded = true;
    }

    const showApplyForFinancing =
        application.phase === ApplicationPhase.RESERVATION && !application.financeApplication?.identifier;

    return (
        <ActionsV2>
            <DownloadDocumentsModal
                isDownloading={downloadState.isDownloading}
                isShow={downloadState.isShow}
                onClose={downloadAction.onClose}
            />
            {isMobile ? (
                <ApplicationStatusMobile
                    application={application}
                    completeDisabled={completeDisabled}
                    downloadAction={downloadAction.getDocuments}
                    downloadDisabled={downloadState.downloadDisabled}
                    nextStatusProps={setNextStatus}
                    showAgreementConcluded={showAgreementConcluded}
                    showApproveDeclineActions={showApproveDeclineActions}
                    voidDisabled={voidDisabled}
                />
            ) : (
                <>
                    <Footer.FootBarButton
                        disabled={downloadState.downloadDisabled}
                        onClick={downloadAction.getDocuments}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {t('applicationDetailsPage.button.downloadDocuments')}
                    </Footer.FootBarButton>
                    {showApplyForFinancing && (
                        <Footer.FootBarButton onClick={() => applyForFinancing(application)}>
                            {t('applicationDetailsPage.button.applyForFinancing')}
                        </Footer.FootBarButton>
                    )}
                    {showAgreementConcluded && (
                        <Footer.FootBarButton onClick={() => setNextStatus(ApplicationStatus.AGREEMENTCONCLUDED)}>
                            {t('applicationDetailsPage.button.concludeAgreement')}
                        </Footer.FootBarButton>
                    )}
                </>
            )}

            {nextStatus && (
                <Modal onClose={close} title={t(get(nextStatus, titles))} showTitle>
                    <ModalContent>
                        <p>{t(get(nextStatus, questions))}</p>
                    </ModalContent>
                    <Actions>
                        <GrayButton onClick={close}>{t('applicationDetailsPage.button.cancel')}</GrayButton>
                        <DarkButton onClick={submit}>{t('applicationDetailsPage.button.ok')}</DarkButton>
                    </Actions>
                </Modal>
            )}
        </ActionsV2>
    );
};

export default UpdateStateAction;
