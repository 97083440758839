import { isNil } from 'lodash/fp';
import React from 'react';
import {
    CompleteConsentsMutation,
    CompleteConsentsMutationVariables,
    completeConsents,
} from '../../../api/draft.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { ReduxFormFlowStep } from '../../utils/flow';
import Consent from '../components/Consent';
import { NewFlowState } from '../types';
import { getFinancingConsents, getInsuranceConsents, getNonFinancingConsents } from '../utils/consents';

export type ConsentStepValues = Partial<{
    consents: { [consentId: string]: boolean };
    referenceId: string;
}>;

class ConsentStep extends ReduxFormFlowStep<NewFlowState, ConsentStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'consent';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('consentPage.label.step');
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.consentsAndDeclarations || false;
    }

    public async execute({ referenceId }: ConsentStepValues) {
        const { apolloClient } = this;
        const { token } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        const variables = { token, eventId: referenceId };

        const { data } = await apolloClient.mutate<CompleteConsentsMutation, CompleteConsentsMutationVariables>({
            mutation: completeConsents,
            variables,
        });

        this.stateStore.update({ ...data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { bank, application, channel, consents: all } = this.state;
        const consents = application?.appliedForFinancing
            ? getFinancingConsents(all, channel)
            : getNonFinancingConsents(all, channel);

        const insuranceConsents = application?.insuranceApplication ? getInsuranceConsents(all) : [];

        if (!bank) {
            throw new Error('Bank missing in state');
        }

        if (!application) {
            throw new Error('Application missing in state');
        }

        if (isNil(consents) || isNil(insuranceConsents)) {
            throw new Error('Consents missing in state');
        }

        return (
            <>
                <HelmetTitle channel={channel} title="Consents" />
                <Consent
                    backStep={this.getBackContext()}
                    consents={[...consents, ...insuranceConsents]}
                    isLastStep={this.isLastStep}
                    onSubmit={this.submit}
                />
            </>
        );
    }
}

export default ConsentStep;
