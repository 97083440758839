import styled from 'styled-components';

export const MyInfoListWrap = styled.div`
    color: #000;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    font-size: 16px;
`;

export const List = styled.div`
    list-style-type: square;
    display: table;

    li {
        display: table-row;
        line-height: 1.5;

        &::before {
            padding-right: 0.5rem;
            content: '▪︎';
            display: table-cell;
            text-align: right;
        }
    }
`;
