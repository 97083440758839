import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router';
import { useCountry } from '../../hookSelectors';
import { getGlobalPermissions, getIsAuthenticated } from '../../selectors';
import { getCompanyLoginUrl } from '../../utilities/urls';
import CustomerRoute from '../routes/CustomerRoute/Detail';
import CustomersRoute from '../routes/CustomerRoute/List/CustomersRoute';
import Error404NotFound from '../routes/Error404NotFound';
import Error404Suggestion from '../routes/Error404Suggestion';
import Header from '../shared/Header';
import { prefixCustomerPath as prefixPath } from './shared';

const CustomerRouter = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const country = useCountry();
    const location = useLocation();
    const history = useHistory();
    const redirect = `${location.pathname}${location.search}`;

    const { allowPrivateAccess, isActive: isNewCarActive } = country.channelSetting.new;
    const { allowPrivateAccess: allowUsedCarPrivateAccess, isActive: isUsedCarActive } = country.channelSetting.used;
    const { isActive: isExpressActive } = country.channelSetting.express;

    const { mayViewExpressChannel, mayViewNewCarChannel, mayViewUsedCarChannel, mayViewCustomer } = useSelector(
        getGlobalPermissions
    );
    const mayViewOtherChannel =
        mayViewExpressChannel ||
        (mayViewNewCarChannel && allowPrivateAccess) ||
        (mayViewUsedCarChannel && allowUsedCarPrivateAccess);

    /* Without useEffect component will re-render as infinite loop
    when we force to logout because of session expire or login in second tab */
    useEffect(() => {
        // non login, request for login
        if (!isAuthenticated) {
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
        }
    }, [history, isAuthenticated, redirect]);

    // login user without permission
    if (isAuthenticated && !mayViewCustomer) {
        const otherActive = isNewCarActive || isUsedCarActive || isExpressActive;

        return mayViewOtherChannel && otherActive ? <Error404Suggestion /> : <Error404NotFound />;
    }

    return (
        <>
            <Header />
            <Switch>
                <Route component={CustomersRoute} path={prefixPath('/')} exact />
                <Route component={CustomerRoute} path={prefixPath('/:id')} />
                <Redirect to={prefixPath('/')} />
            </Switch>
        </>
    );
};

export default CustomerRouter;
