import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { MakeDataFragment, ModelDataFragment } from '../../../../data/useLoadVariants.graphql';
import { FilterDefinition } from './index';

const useModelFilter = (
    maker: string | null,
    makers: MakeDataFragment[],
    model: string | null,
    models: ModelDataFragment[],
    subModels: ModelDataFragment[],
    setMaker: (makeId: string | null) => void,
    setModel: (modelId: string | null) => void
): FilterDefinition<string> | null => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    // get the current maker to get its label
    const backLabel = useMemo(() => ct(makers.find(item => item.id === maker)?.name), [ct, maker, makers]);

    // callback for changes
    const onChange = useCallback(
        ({ value }) => {
            switch (value) {
                case 'back':
                    // reset maker
                    setMaker(null);
                    break;

                case 'all':
                    // reset model
                    setModel(null);
                    break;

                default:
                    // update model
                    setModel(value);
                    break;
            }
        },
        [setMaker, setModel]
    );

    return useMemo(() => {
        if (models.length < 2) {
            // there is no need to display it because we do not have multiple models
            return null;
        }

        if (!maker && makers.length > 1) {
            // we cannot display it because we need to select the maker first
            return null;
        }

        if (model && subModels.length > 1) {
            // we cannot display it because the user now need to select a sub model
            return null;
        }

        // are we dealing with makers
        const withMakers = makers.length > 1;

        const options = [
            // display the back option only when we have makers
            withMakers && { id: 'back', value: 'back', label: `< ${backLabel}` },
            // all option
            { id: 'all', value: 'all', label: t('chooserPage.filter.selectAll') },
            // model options
            ...models.map(modelOption => ({
                id: modelOption.id,
                value: modelOption.id,
                label: ct(modelOption.name),
            })),
        ].filter(Boolean) as FilterDefinition<string>['options'];

        return {
            key: 'model',
            title: t('chooserPage.filter.carModelTitle'),
            onChange,
            options,
            value: model || 'all',
            isBack: withMakers,
        };
    }, [backLabel, maker, makers.length, model, models, onChange, subModels.length, t, ct]);
};

export default useModelFilter;
