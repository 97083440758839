import styled from 'styled-components';
import breakpoints from '../../utilities/constants/breakpoints';

const SystemContainer = styled.div<{ fixedMenu: boolean }>`
    height: 100%;
    padding-left: ${props => (props.fixedMenu ? 250 : 0)}px;
    transition: all 0.5s ease;

    @media (max-width: ${breakpoints.sm}) {
        padding-left: 0;
    }
`;

export default SystemContainer;
