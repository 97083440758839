import { useQuery } from '@apollo/client';
import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { find, isEqual } from 'lodash/fp';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { InventoryUnitsDataFragment } from '../../../../components/data/useLoadInventoryUnits.graphql';
import {
    getVariantsByDealerId,
    GetVariantsByDealerIdQuery,
    GetVariantsByDealerIdQueryVariables,
} from '../../../../components/data/useLoadVariants.graphql';
import useMustExistDealerIdFromTenantContext from '../../../../components/data/useMustExistDealerIdFromTenantContext';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../components/shared/calculator-next/useCalculatorMeta';
import { useZone } from '../../../../hookSelectors';
import { Channel } from '../../../../schema';
import { getUsedCarModelsUrl } from '../../../../utilities/urls';
import { CalculatorSnapshot } from '../../../utils/getSnapshotFromApplication';
import overrideVariantDataWithUnit from '../../../utils/overrideVariantDataWithUnit';
import useDealerEstablishment from '../../../utils/useDealerEstablishment';
import { UsedCalculatorStepValues } from '../../steps/UsedCalculatorStep';
import * as blocks from './blocks';
import { FinanceData } from './blocks/Financing';
import { GalleryImageType } from './blocks/Gallery';
import { Block, Page, Row } from './ui';

export type SingleProps = {
    onSubmit: (values: UsedCalculatorStepValues) => Promise<void>;
    initialCalculatorValues: Partial<CalculatorValues>;
    initialValues: FinanceData;
    dealerId: string;
    snapshot?: CalculatorSnapshot;
    unit: InventoryUnitsDataFragment;
};

const useVariant = (variantId: string | undefined | null, dealerId: string, unit: InventoryUnitsDataFragment) => {
    const { t } = useTranslation();
    // get the zone id
    const { id: zoneId } = useZone();

    // get data using apollo
    const { data } = useQuery<GetVariantsByDealerIdQuery, GetVariantsByDealerIdQueryVariables>(getVariantsByDealerId, {
        variables: { zoneId, dealerId, channel: Channel.USED },
        fetchPolicy: 'cache-and-network',
    });

    const variants = data?.variants || [];

    return useMemo(() => {
        // get the original variant from the variant list
        const variant = find(item => item.id === variantId, variants);

        if (variant && unit && unit.set.inventory.variantId === variant.version.id) {
            // override it with unit if we have one
            return overrideVariantDataWithUnit(variant, unit, t);
        }

        return variant;
    }, [variants, unit, variantId, t]);
};

const Single = ({
    initialCalculatorValues,
    snapshot,
    unit,
    onSubmit: apply,
    initialValues,
    dealerId,
}: SingleProps): ReactElement | null => {
    // get the variant
    const variant = useVariant(initialCalculatorValues.variant, dealerId, unit);

    // get images for gallery
    const { display } = useTheme();
    const galleryImages: GalleryImageType[] = useMemo(() => {
        if (!variant?.images) {
            return [];
        }

        return variant.images.filter(image => image?.url) as GalleryImageType[];
    }, [variant]);
    const columns = display === 'large' ? 3 : 1;
    // prepare meta
    const variants = useMemo(() => (variant ? [variant] : []), [variant]);
    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId, Channel.USED);

    // state to keep track of latest values in the calculator
    const additionalMeta = useMemo(
        () => ({
            channel: Channel.USED,
            variants,
            snapshot,
            selectedDealerEstablishment,
        }),
        [selectedDealerEstablishment, snapshot, variants]
    );
    const meta = useCalculatorMeta(additionalMeta);

    // state to keep track of latest values in the calculator
    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();

    // submit function
    const onSubmit = useCallback(
        formValues => {
            if (context) {
                const { values, getFieldContext } = context;
                apply({
                    calculator: values,
                    bank: getFieldContext('bank').selectedBank,
                    financeProduct: getFieldContext('financeProduct').selectedFinanceProduct,
                    variant: getFieldContext('variant').selectedVariant,
                    unitId: unit.id,
                    promo: getFieldContext('promoCode').promo,
                    ...formValues,
                });
            }

            return undefined;
        },
        [apply, context, unit]
    );

    const history = useHistory();
    // @ts-ignore
    const onPrev = useCallback(() => history.pushWithCompany(getUsedCarModelsUrl), [history]);

    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    useEffect(() => {
        if (!isEqual(selectedDealerId, dealerId)) {
            onPrev();
        }
    }, [dealerId, history, onPrev, selectedDealerId]);

    if (!variant) {
        // still need to wait for now
        return null;
    }

    return (
        <Page>
            <Block>
                <Row columns={columns}>
                    <blocks.Introduction variant={variant} />
                    <blocks.Financing
                        context={context}
                        dealerId={dealerId}
                        handleChange={setContext}
                        initialCalculatorValues={initialCalculatorValues}
                        initialValues={initialValues}
                        meta={meta}
                        onSubmit={onSubmit}
                        variant={variant}
                    />
                </Row>
            </Block>
            <blocks.Details variant={variant} />
            <blocks.Features variant={variant} />
            {galleryImages.length > 1 && <blocks.Gallery images={galleryImages} />}
        </Page>
    );
};

export default memo(Single);
