import styled from 'styled-components';

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;

    & p {
        margin-bottom: 0;
    }

    & span {
        font-weight: bolder;
    }
`;

export default ModalContent;
