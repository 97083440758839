export const GLOBAL_ERROR_SET = '@GLOBAL_ERROR/SET';

export const GLOBAL_ERROR_UNSET = '@GLOBAL_ERROR/UNSET';

export type SetGlobalError = { type: typeof GLOBAL_ERROR_SET; error: Error };

export const setGlobalError = (error: Error): SetGlobalError => ({ type: GLOBAL_ERROR_SET, error });

export type UnsetGlobalError = { type: typeof GLOBAL_ERROR_UNSET };

export const unsetGlobalError = (): UnsetGlobalError => ({ type: GLOBAL_ERROR_UNSET });

export type GlobalErrorActions = SetGlobalError | UnsetGlobalError;
