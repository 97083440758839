import React from 'react';
import Namirial from '../../DraftFlow/components/Namirial';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';

class NamirialStep extends FlowStep<RemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'namirial';
    }

    public get isCompleted(): boolean {
        const { application } = this.state;

        return !!application?.steps?.approval;
    }

    public render(): React.ReactElement | null {
        const { token, source, application } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        // there's a chance that the application has been updated
        const hasMutation = this.getBackContext()?.identifier && this.getBackContext()?.identifier !== 'preview';
        // if there's a step that mutations the application
        // then we need to recreate signing
        // otherwise we reuse existing signing
        const signing = hasMutation ? application?.signing : undefined;

        return <Namirial signing={signing} source={source} token={token} />;
    }
}

export default NamirialStep;
