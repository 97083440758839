import { RuntimeSettingsActions, RUNTIME_SETTINGS_SET } from '../actions/runtimeSettings';
import { RuntimeSettingsDataFragment } from '../api/miscellaneous.graphql';
import persistState from './persistState';

export type RuntimeSettingsState = RuntimeSettingsDataFragment;

const reducer = (state = {} as RuntimeSettingsState, actions: RuntimeSettingsActions) => {
    switch (actions.type) {
        case RUNTIME_SETTINGS_SET:
            return actions.settings;

        default:
            return state;
    }
};

export default persistState('runtimeSettings', reducer);
