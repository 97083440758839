import { useApolloClient } from '@apollo/client';
import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { omit } from 'lodash/fp';
import React, { useCallback, useRef, ReactElement, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../actions';
import {
    createUsedVariant,
    CreateUsedVariantMutation,
    CreateUsedVariantMutationVariables,
} from '../../../../api/miscellaneous.graphql';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { Container, Title } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import { useCompany, useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { deepOmit } from '../../../../utilities/fp';
import { CalculatorSnapshot } from '../../../utils/getSnapshotFromApplication';
import { ExpressCalculatorStepValues } from '../../steps/ExpressCalculatorStep';
import { ExpressVariant } from '../../types';
import { Page } from '../UsedCalculator/ui';
import { preownedSGMarketSchema, preownedNonSGMarketSchema } from './PreOwnedDetails';
import PreownedForm from './PreownedForm';
import useApplyDisabled from './useApplyDisabled';
import variantTemplate from './variantTemplate';

export type InnerProps = {
    initialVariant?: ExpressVariant;
    initialValues?: Partial<CalculatorValues>;
    snapshot?: CalculatorSnapshot;
    dealerId: string;
    apply: (values: ExpressCalculatorStepValues) => Promise<void>;
};

const Inner = ({
    initialValues: initialCalculatorValues,
    apply,
    snapshot,
    dealerId,
    initialVariant = variantTemplate,
}: InnerProps): ReactElement | null => {
    const { t } = useTranslation();
    const { mapIntlValue, ct, language: defaultLanguage } = useContentTranslation();

    // does this country allow sharing
    const { channelSetting, id: countryId, code } = useCountry();

    const schema = useMemo(() => (code === 'SG' ? preownedSGMarketSchema : preownedNonSGMarketSchema), [code]);
    const validate = useValidation(schema);
    const client = useApolloClient();

    const { name: companyName } = useCompany();
    // override makeName with companyName
    const variant = useRef({ ...initialVariant, makeName: ct(companyName) });

    const { allowFinanceApplication, isInsuranceEnabled } = channelSetting.express;

    // state to keep track of latest values in the calculator
    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const { values, errors } = context || {};

    // we need the redux dispatch function
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

    // callback to create our new variant in database
    const getExpressVariant = useCallback(
        async (variant: ExpressVariant): Promise<ExpressVariant> => {
            const { makeName, modelName, preOwnedCarDetails } = variant;
            const promise = client.mutate<CreateUsedVariantMutation, CreateUsedVariantMutationVariables>({
                mutation: createUsedVariant,
                variables: {
                    data: {
                        countryId,
                        // for express use model name as variant name
                        name: mapIntlValue({ [defaultLanguage]: modelName }),
                        // Converting string to IntlValue type
                        makeName: mapIntlValue({ [defaultLanguage]: makeName }),
                        modelName: mapIntlValue({ [defaultLanguage]: modelName }),
                        preOwnedCarDetails: omit(['vehicleLogCard'], preOwnedCarDetails),
                    },
                },
            });

            // attach loading
            dispatch(attachLoading(promise));

            const expressVariant = (await promise).data?.variant;

            if (!expressVariant) {
                throw new Error('failed to create the express variant');
            }

            const cleanedVariant = deepOmit(['__typename'], expressVariant);

            // map it back to form state
            return {
                ...cleanedVariant,
                makeName: expressVariant.model.make.name,
                modelName: expressVariant.model.name,
                preOwnedCarDetails: {
                    ...cleanedVariant.preOwnedCarDetails,
                    vehicleLogCard: preOwnedCarDetails.vehicleLogCard,
                },
            } as ExpressVariant;
        },
        [client, countryId, mapIntlValue, defaultLanguage, dispatch]
    );

    const [isFinanceChecked, setIsFinanceChecked] = useState(() => allowFinanceApplication);
    const [isInsuranceChecked, setIsInsuranceChecked] = useState(() => isInsuranceEnabled);

    // submission
    const submit = useCallback(
        async variant => {
            // first create the new variant
            const expressVariant = await getExpressVariant(variant);

            if (!context) {
                throw new Error('missing values');
            }

            const { values, getFieldContext } = context;

            // replace carModel id
            return apply({
                calculator: { ...values, variant: expressVariant.id },
                bank: getFieldContext('bank').selectedBank,
                financeProduct: getFieldContext('financeProduct').selectedFinanceProduct,
                variant: expressVariant,
                promo: getFieldContext('promoCode').promo,
                isFinanceEnabled: isFinanceChecked,
                isInsuranceEnabled: isInsuranceChecked,
            });
        },
        [getExpressVariant, context, apply, isFinanceChecked, isInsuranceChecked]
    );

    const applyDisabled = useApplyDisabled(values, errors);

    const onFinanceCheckChange = useCallback(() => {
        setIsFinanceChecked(state => !state);
    }, [setIsFinanceChecked]);

    const onInsuranceCheckChange = useCallback(() => {
        setIsInsuranceChecked(state => !state);
    }, [setIsInsuranceChecked]);

    return (
        <>
            <Container>
                <Navigation />
                <Title>{t('expressCalculatorPage.title')}</Title>
            </Container>
            <Page>
                <PreownedForm
                    applyDisabled={applyDisabled}
                    calculatorErrors={errors}
                    context={context}
                    dealerId={dealerId}
                    initialCalculatorValues={initialCalculatorValues}
                    initialValues={variant.current}
                    isFinanceChecked={isFinanceChecked}
                    isInsuranceChecked={isInsuranceChecked}
                    onCalculatorChange={setContext}
                    onFinanceCheckChange={onFinanceCheckChange}
                    onInsuranceCheckChange={onInsuranceCheckChange}
                    onSubmit={submit}
                    snapshot={snapshot}
                    validate={validate}
                />
            </Page>
        </>
    );
};

export default Inner;
