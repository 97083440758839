import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { resetPassword } from '../../../actions';
import { useContentTranslation } from '../../../i18n';
import { getCompany, getCompanyIdentifier } from '../../../selectors';
import { handleResponseError } from '../../../utilities/forms';
import { getCompanyLoginUrl, getLocationCode, getLoginUrl } from '../../../utilities/urls';
import FadeInDown from '../../animations/FadeInDown';
import { useCopyright } from '../../shared/Footer';
import HelmetTitle from '../../shared/HelmetTitle';
import LanguageSelector from '../../shared/LanguageSelector';
import { LoginLanguageContainer } from '../../ui/LanguageSelector';
import RootStyle from '../../ui/RootStyle';
import LoginContainer from '../../ui/login/LoginContainer';
import LoginFooter from '../../ui/login/LoginFooter';
import { getLoginContainerProps } from '../LoginRoute';
import ResetPasswordForm from './ResetPasswordForm';

const useLoginUrl = () => {
    const { companyCode, countryCode, zoneCode } = useSelector(getCompanyIdentifier);

    if (companyCode && countryCode && zoneCode) {
        return getCompanyLoginUrl(companyCode, getLocationCode(countryCode, zoneCode));
    }

    return getLoginUrl();
};

const ResetPasswordRoute = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loginUrl = useLoginUrl();
    const { ct } = useContentTranslation();

    const onSubmit = useCallback(({ password }) => dispatch(resetPassword(password)).catch(handleResponseError), [
        dispatch,
    ]);

    const onSubmitSuccess = useCallback(() => history.push(loginUrl, { from: 'reset' }), [history, loginUrl]);

    const company = useSelector(getCompany);

    const copyright = useCopyright();

    return (
        <>
            <HelmetTitle title="Reset Password" />
            <RootStyle />
            <FadeInDown>
                <LoginContainer {...getLoginContainerProps(company, ct)}>
                    <ResetPasswordForm onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} />
                </LoginContainer>
                <LoginFooter>{copyright}</LoginFooter>
                {company && (
                    <LoginLanguageContainer>
                        <LanguageSelector isAtLogin />
                    </LoginLanguageContainer>
                )}
            </FadeInDown>
        </>
    );
};

export default ResetPasswordRoute;
