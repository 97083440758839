import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getCompanyRootUrl } from '../../utilities/urls';

const useNotPaidBackAction = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (sessionStorage.getItem('redirectToPayment')) {
            // when user payment in 3rd website,
            // and then user tap browser back button, will return our site,
            // after that, we need to clear new applicaiton state
            // and clear redirectToPayment flag
            // and go to home page
            // @ts-ignore
            history.pushWithCompanyAndState(getCompanyRootUrl, {
                paymentNotCompleted: {
                    title: 'Payment Pending',
                    message:
                        // eslint-disable-next-line max-len
                        'The payment is currently pending approval. Please check back on this application later to proceed.',
                },
            });
            sessionStorage.removeItem('redirectToPayment');
        }
    }, [dispatch, history]);
};

export default useNotPaidBackAction;
