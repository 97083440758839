import { differenceBy, omit, orderBy } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ConsentDataFragment } from '../../../../../api/consents.graphql';
import { BankDataFragment } from '../../../../../components/data/useLoadBank.graphql';
import { CountryScopeFragment } from '../../../../../components/data/useLoadScope.graphql';
import HelmetTitle from '../../../../../components/shared/HelmetTitle';
import useValidation from '../../../../../components/utilities/useValidation';
import useValidationContext from '../../../../../components/utilities/useValidationContext';
import { useCountry } from '../../../../../hookSelectors';
import { Channel, UploadPurpose } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { BackStepContext } from '../../../../utils/flow';
import { Application } from '../../../types';
import { Page } from '../ui';
import CustomerForm, { CustomerFormValues, schema as getSchema } from './CustomerForm';

export type PageProps = {
    onSubmit: (values: any) => Promise<void>;
    step?: string;
    bank?: BankDataFragment;
    initialValues: any;
    country: CountryScopeFragment;
    withMyInfoError?: boolean;
    backStep?: BackStepContext;
    isLastStep: boolean;
    application: Application;
    consents?: ConsentDataFragment[];
};

const normalizeValues = (values: CustomerFormValues) => {
    const { customerInfo } = values;
    const { occupationFiles = [], customerFiles = [], files = [] } = customerInfo;

    return {
        ...omit(['occupationFiles', 'customerFiles'], customerInfo),
        files: [...files, ...occupationFiles, ...customerFiles],
    };
};

const KYCPage = ({
    onSubmit,
    step,
    backStep,
    initialValues: initialValuesProps,
    application,
    consents,
    ...props
}: PageProps) => {
    const schema = useMemo(() => getSchema(), []);
    const validate = useValidation(schema);
    const { code } = useCountry();

    // get runtime settings
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const validation = useValidationContext();
    const sortedConsents = useMemo(() => orderBy('order', 'asc', consents), [consents]);

    const initialValues = useMemo(() => {
        if (!application) {
            return undefined;
        }

        const customerFiles = application.attachments.filter(
            ({ purpose }) =>
                purpose === (step === 'guarantor' ? UploadPurpose.GUARANTOR_IDENTITY : UploadPurpose.CUSTOMER_IDENTITY)
        );

        const occupationFiles = application.attachments.filter(
            ({ purpose }) =>
                purpose === (step === 'guarantor' ? UploadPurpose.GUARANTOR_ATTACHED : UploadPurpose.CUSTOMER_ATTACHED)
        );

        const files = differenceBy('id', application.attachments, [...customerFiles, ...occupationFiles]);

        const customerInfo = step === 'guarantor' ? application.guarantor : application.customer;

        return {
            customerInfo: {
                ...customerInfo,
                customerFiles,
                occupationFiles,
                files,
            },
            consentDraft: { consents: {}, referenceId: undefined },
        };
    }, [application, step]);

    // prepare submit
    const onApply = useCallback(
        (values: any, validate: (values: any) => void) => {
            // first validate values
            validate(values);

            // then proceed with apply function
            return onSubmit({ ...normalizeValues(values), referenceId: values.consentDraft.referenceId });
        },
        [onSubmit]
    );

    const onSaveDraft = useCallback(
        values =>
            onSubmit({
                ...normalizeValues(values),
                draftTo: values.draftTo,
                referenceId: values.consentDraft.referenceId,
            }),
        [onSubmit]
    );

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Customer Details" />
            <CustomerForm
                application={application}
                apply={onApply}
                consents={sortedConsents}
                countryCode={code}
                step={step || 'customer'}
                {...props}
                initialValues={initialValues}
                onSaveDraft={onSaveDraft}
                useCustomerBirthDay={useCustomerBirthDay}
                useCustomerNationality={useCustomerNationality}
                validate={validate}
                validation={validation}
            />
        </Page>
    );
};

export default KYCPage;
