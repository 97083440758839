import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { addBusinessDays } from 'date-fns';
import { omit } from 'lodash/fp';
import React, { memo, ReactElement, useState, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { FinderVehicleDataFragment } from '../../../../components/routes/FinderRoute/data.graphql';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useFormatDateTime from '../../../../components/shared/useFormatDate';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { CalculatorSnapshot } from '../../../utils/getSnapshotFromApplication';
import { EventCalculatorStepValues } from '../../steps/EventCalculatorStep';
import * as blocks from './blocks';
import { FinanceData } from './blocks/Financing';
import { Page, Row, Block } from './ui';

export type EventFlowCalculatorPageProps = {
    onSubmit: (values: EventCalculatorStepValues) => Promise<void>;
    initialCalculatorValues: Partial<CalculatorValues>;
    initialValues: FinanceData;
    dealerId: string;
    snapshot?: CalculatorSnapshot;
    eventId: string;
    variant: VariantDataFragment;
    event: EventDataFragment;
    finderVehicle?: FinderVehicleDataFragment | null;
};

const EventFlowCalculatorPage = ({
    initialCalculatorValues,
    initialValues,
    dealerId,
    snapshot,
    onSubmit: apply,
    variant,
    event,
    finderVehicle,
}: EventFlowCalculatorPageProps): ReactElement | null => {
    // get the variant
    const { display } = useTheme();
    const columns = display === 'large' ? 3 : 1;

    const variants = useMemo(() => [variant], [variant]);

    const { formatCurrency } = useCompanyFormatting();
    const formatDate = useFormatDateTime();

    // state to keep track of latest values in the calculator
    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const [insuranceContext, setInsuranceContext] = useState<CalculatorContext<CalculatorInsuranceValues>>();
    const { values, errors } = context || {};

    // submit function
    const onSubmit = useCallback(
        formValues => {
            // For finder, it's possible that calculator is OFF and able to proceed without FP
            const values = context?.values ?? initialCalculatorValues;
            const getFieldContext = context?.getFieldContext;

            const insuranceCalculator = insuranceContext?.values ?? {};

            const totalPriceFromPage = formValues?.noCalculator?.totalPrice ?? 0;
            const carPriceFromPage = formValues?.noCalculator?.carPrice ?? 0;
            const coeFromPage = formValues?.noCalculator?.coe ?? 0;

            const omitted = omit(['noCalculator'], formValues);

            return apply({
                calculator: {
                    ...values,
                    totalPrice: values?.totalPrice ?? totalPriceFromPage,
                    carPrice: values?.carPrice ?? carPriceFromPage,
                    coe: values?.coe ?? coeFromPage,
                },
                insuranceCalculator: formValues?.appliedForInsurance
                    ? {
                          ...insuranceCalculator,
                          carPrice: insuranceCalculator?.carPrice ?? carPriceFromPage,
                          coe: insuranceCalculator?.coe ?? coeFromPage,
                      }
                    : undefined,
                bank: getFieldContext?.('bank').selectedBank,
                financeProduct: getFieldContext?.('financeProduct').selectedFinanceProduct,
                variant: getFieldContext?.('variant').selectedVariant ?? variant,
                promo: getFieldContext?.('promoCode').promo,
                ...omitted,
            });
        },
        [apply, context, initialCalculatorValues, insuranceContext, variant]
    );

    const paymentAmount = event?.setting?.bookingPayment?.amount;

    const reservationPeriod = event.setting.reservationPeriod ?? 0;
    const reservationInstruction = event.setting.reservationInstruction
        ? event.setting.reservationInstruction
              .replaceAll('<<RESERVATION_PERIOD>>', `${reservationPeriod}`)
              .replaceAll('<<RESERVATION_AMOUNT>>', formatCurrency(paymentAmount))
              .replaceAll('<<RESERVATION_EXPIRE>>', formatDate(addBusinessDays(new Date(), reservationPeriod)))
        : null;

    return (
        <Page>
            <Block>
                <Row columns={columns}>
                    <blocks.Introduction reservationInstruction={reservationInstruction} variant={variant} />
                    <blocks.Financing
                        calculatorErrors={errors}
                        calculatorValues={values}
                        dealerId={dealerId}
                        event={event}
                        finderVehicle={finderVehicle}
                        initialCalculatorValues={initialCalculatorValues}
                        initialValues={initialValues}
                        insuranceCalculatorErrors={insuranceContext?.errors}
                        onCalculatorChange={setContext}
                        onInsuranceCalculatorChange={setInsuranceContext}
                        onSubmit={onSubmit}
                        snapshot={snapshot}
                        variant={variant}
                        variants={variants}
                    />
                </Row>
            </Block>
        </Page>
    );
};

export default memo(EventFlowCalculatorPage);
