import { isEmpty } from 'lodash/fp';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, WrappedFieldArrayProps, GenericFieldArray, Field } from 'redux-form';
import styled from 'styled-components';
import FileFieldContainer, { Action as BaseAction } from '../../ui/form/FileFieldContainer';
import FileField from './FileField';

export const Action = styled(BaseAction)`
    font-size: 0.9rem;
    color: ${props => props.theme.colors.activeColor};
    text-decoration: underline;
`;

const Details = styled.div`
    width: 50%;
    font-size: 0.9rem;
    opacity: 0.8;
`;

type FileFieldsProps = WrappedFieldArrayProps & {
    disabled?: boolean;
    forPurpose?: string | null;
    max?: number;
    label?: string;
    details?: string;
};

export const FileFields = ({
    fields,
    max = 5,
    disabled = false,
    label,
    forPurpose = null,
    details,
    ...props
}: FileFieldsProps) => {
    const { t } = useTranslation();
    const data = (fields.getAll() || []).filter(file => !forPurpose || file.purpose === forPurpose);
    const hasReachMaximum = data.length >= max;

    useEffect(() => {
        if (data.length === 0 && !disabled) {
            // add an empty object
            fields.push({ purpose: forPurpose });
        }
    }, [data, fields, disabled, forPurpose]);

    const addFile = useCallback(() => {
        const hasEmptyItem = fields.getAll().some(file => !(file instanceof File) && isEmpty(file));

        if (!hasEmptyItem) {
            // we can add another one
            fields.push({ purpose: forPurpose });
        }
    }, [fields, forPurpose]);

    const renderFile: (
        name: string,
        index: number,
        { remove }: { remove: (index: number) => void }
    ) => JSX.Element | null = (name, index, { remove }) => {
        if (forPurpose) {
            // we may have to exclude some files form the rendering
            const file = fields.get(index);

            if (file.purpose !== forPurpose) {
                return null;
            }
        }

        return (
            <FileField
                key={name}
                disabled={disabled}
                label={label || t('customerDetails.label.files')}
                name={name}
                onRemove={() => remove(index)}
                placeholder={
                    disabled ? t('customerDetails.placeholder.noUpload') : t('customerDetails.placeholder.upload')
                }
            />
        );
    };

    return (
        <FileFieldContainer {...props}>
            {fields.map(renderFile)}
            {details && <Details>{details}</Details>}
            {!hasReachMaximum && !disabled && <Action onClick={addFile}>Add Another File</Action>}
        </FileFieldContainer>
    );
};

type FileFieldArrayProps = {
    disabled?: boolean;
    forPurpose?: string;
    name?: string;
    rerenderOnEveryChange?: boolean;
    label?: string;
    details?: string;
};

export const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const FileFieldArray = ({ ...props }: FileFieldArrayProps) => <FieldArrayCustom component={FileFields} {...props} />;

export default FileFieldArray;
