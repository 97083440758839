import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentZone } from '../../selectors';
import { formatDateOnly } from '../../utilities/dates';

const useFormatDateTime = () => {
    const { t } = useTranslation();
    const { timezone } = useSelector(getCurrentZone);

    return useMemo(() => formatDateOnly(timezone, t('dateFormats.dateFormat')), [t, timezone]);
};

export default useFormatDateTime;
