import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useMedia from 'use-media';
import { getRuntimeSettings } from '../../../../../selectors';
import breakpoints from '../../../../../utilities/constants/breakpoints';

const useExteriorImage = (exteriorColor?: string, interiorColor?: string) => {
    const isMobileSource = useMedia({ maxWidth: breakpoints.sm });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const { options } = ckdConfiguration.exterior;

    return useMemo(() => {
        if (interiorColor === ckdConfiguration.interior.options[0].name) {
            switch (exteriorColor) {
                case options[0].name:
                    return isMobileSource ? options[0].images[0].mobileImage : options[0].images[0].desktopImage;
                case options[1].name:
                    return isMobileSource ? options[1].images[0].mobileImage : options[1].images[0].desktopImage;
                case options[2].name:
                    return isMobileSource ? options[2].images[0].mobileImage : options[2].images[0].desktopImage;
                default:
                    return isMobileSource ? options[0].images[0].mobileImage : options[0].images[0].desktopImage;
            }
        } else {
            switch (exteriorColor) {
                case options[0].name:
                    return isMobileSource ? options[0].images[1].mobileImage : options[0].images[1].desktopImage;
                case options[1].name:
                    return isMobileSource ? options[1].images[1].mobileImage : options[1].images[1].desktopImage;
                case options[2].name:
                    return isMobileSource ? options[2].images[1].mobileImage : options[2].images[1].desktopImage;
                default:
                    return isMobileSource ? options[0].images[1].mobileImage : options[0].images[1].desktopImage;
            }
        }
    }, [exteriorColor, interiorColor, isMobileSource, ckdConfiguration.interior.options, options]);
};

export default useExteriorImage;
