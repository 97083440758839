import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { attachLoading } from '../../../../actions';
import {
    updateInsuranceApplication,
    UpdateInsuranceApplicationMutation,
    UpdateInsuranceApplicationMutationVariables,
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../../api/application.graphql';
import { useContentTranslation } from '../../../../i18n';
import { InsuranceApplicationStatus } from '../../../../schema';
import { getCompanyIdentifier, getGlobalPermissions } from '../../../../selectors';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { getInsuranceApplicationResumeUrl, getInsuranceApplicationsUrl } from '../../../../utilities/urls';
import applySourceChange from '../../../utilities/applySourceChange';
import { SubmitProvider } from '../ApplicationDetails';
import { ApplicationFormValues } from '../ApplicationForm';

const ReSubmitProvider: SubmitProvider = ({ children, application }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useApolloClient();
    const { getState } = useStore();

    const { mayManageCustomer } = useSelector(getGlobalPermissions);

    const applicationId = application.id;

    const { language: defaultLanguage } = useContentTranslation();

    const onSubmit = useCallback(
        async (values: ApplicationFormValues) => {
            try {
                // update customer
                if (mayManageCustomer) {
                    const { customer } = values;
                    const initialCustomer = application.customer;
                    const modifiedCustomer = applySourceChange(customer, initialCustomer);
                    const { id: customerId, type, ...customerData } = modifiedCustomer;
                    const customerPromise = client.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                        mutation: updateCustomer,
                        variables: { id: customerId, data: prepareForGraphQL(customerData) },
                    });
                    // attach loading
                    dispatch(attachLoading(customerPromise));
                }

                // create payload for update
                const payload: UpdateInsuranceApplicationMutationVariables = {
                    id: applicationId,
                    data: {
                        // set proceedWithCustomerDevice policy
                        proceedWithCustomerDevice: values.proceedWithCustomerDevice,
                    },
                    locale: defaultLanguage,
                };

                // execute the mutation
                const promise = client.mutate<
                    UpdateInsuranceApplicationMutation,
                    UpdateInsuranceApplicationMutationVariables
                >({
                    mutation: updateInsuranceApplication,
                    variables: prepareForGraphQL(payload),
                });

                // attach the promise with load
                dispatch(attachLoading(promise));

                // wait for it to be done
                const { data } = await promise;
                const response = data?.context;

                if (!response) {
                    // that shouldn't happen
                    throw new Error('Context missing in response');
                }

                // get the token and application
                const { token, insuranceApplication: newInsuranceApplication } = response;

                // get company and location code
                const { companyCode, locationCode } = getCompanyIdentifier(getState());

                if (
                    !newInsuranceApplication.proceedWithCustomerDevice &&
                    ![InsuranceApplicationStatus.SUBMITTED, InsuranceApplicationStatus.RECEIVED].includes(
                        newInsuranceApplication.status
                    )
                ) {
                    // move to resume flow
                    const url = getInsuranceApplicationResumeUrl(companyCode, locationCode, newInsuranceApplication.id);
                    history.push(url, { token });

                    return null;
                }

                // go back to application list
                const url = getInsuranceApplicationsUrl(companyCode, locationCode, newInsuranceApplication);
                history.push(url, { submitted: true, application: newInsuranceApplication });

                return null;
            } catch (error) {
                // process the error
                return handleResponseError(error as Error);
            }
        },
        [application.customer, client, dispatch, applicationId, getState, history, defaultLanguage, mayManageCustomer]
    );

    return children(onSubmit);
};

export default ReSubmitProvider;
