import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useStore } from 'react-redux';
import { getCurrentCountry } from '../../selectors';
import createWebSocketClient from '../../utilities/createWebSocketClient';
import { MaintenanceNotification } from '../ui/Maintenance';
import { onMaintenanceUpdate } from './MaintenanceWatcher.graphql';
import useFormatDateTime from './useFormatDateTime';

const nullCallback = () => null;

const MaintenanceWatcher = ({ setMaintenanceBegan }) => {
    // get the country on which we should listen for maintenance
    const [maintenance, setMaintenance] = useState(null);
    const formatDateTime = useFormatDateTime();
    const { t } = useTranslation();

    // state to know if we should display a warning or not
    const [warning, setWarning] = useState(null);

    // main logic to trigger maintenances or warnings
    useEffect(() => {
        const now = new Date().getTime();
        const { isActive, start, end, warningBefore } = maintenance || {};

        const startDate = start && new Date(start).getTime();
        const endDate = end && new Date(end).getTime();

        if (!isActive || now > endDate) {
            // nullify everything
            // there's no maintenance or the maintenance is over
            setWarning(null);
            setMaintenanceBegan(null);

            return nullCallback;
        }

        if (now > startDate && now < endDate) {
            // the maintenance already started
            setWarning(null);
            setMaintenanceBegan(maintenance);

            return nullCallback;
        }

        const maintenanceIn = startDate - now;
        const warningIn = warningBefore && maintenanceIn - warningBefore * 1000;

        const warningTimeout =
            warningBefore &&
            setTimeout(() => {
                setWarning(t('common.maintenanceWarning', { warningTimeout: formatDateTime(start) }));
            }, warningIn);

        const maintenanceTimeout = setTimeout(() => {
            setWarning(null);
            setMaintenanceBegan(maintenance);
        }, maintenanceIn);

        return () => {
            clearTimeout(warningTimeout);
            clearTimeout(maintenanceTimeout);
        };
    }, [maintenance, setWarning, setMaintenanceBegan, formatDateTime, t]);

    // set the maintenance from redux state
    const country = useSelector(getCurrentCountry);
    useEffect(() => {
        setMaintenance(country?.maintenance);
    }, [country]);

    // listen to maintenance updates from the API
    const { getState } = useStore();
    useEffect(() => {
        if (!country) {
            // skip it for now
            return nullCallback;
        }

        // create a websocket client
        const client = createWebSocketClient(getState);

        const variables = { countryId: country.id };
        const observable = client.subscribe({ query: onMaintenanceUpdate, variables }).subscribe(({ data }) => {
            // here it goes
            setMaintenance(data.event);
        });

        return () => {
            // unsubscribe
            observable.unsubscribe();
            // forcefully close the connection
            client.link.subscriptionClient.close(true, true);
        };
    }, [country, getState]);

    if (warning) {
        return <MaintenanceNotification>{warning}</MaintenanceNotification>;
    }

    return null;
};

MaintenanceWatcher.propTypes = {
    setMaintenanceBegan: PropTypes.func.isRequired,
};

export default MaintenanceWatcher;
