import { useMemo } from 'react';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import InsuranceResumeFlow, { InsuranceResumeFlowState } from '../../../../flows/InsuranceResumeFlow/ResumeFlow';
import { FlowType, useManagedFlow, useScrollTop } from '../../../../flows/utils/flow';
import useInsuranceCompleteHandler from '../../../../flows/utils/useInsuranceCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { InsuranceApplicationDataFragment } from '../../InsuranceRoute/data.graphql';

export type ResumeHandlerProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
    consents: ConsentDataFragment[];
    token: string;
};

const getFlowImplementation = (channel: Channel): FlowType<InsuranceResumeFlowState, InsuranceResumeFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
            return InsuranceResumeFlow;

        default:
            return null;
    }
};

const InsuranceResumeFlowHandler = ({ insuranceApplication, consents, token }: ResumeHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();

    // create the initial state
    const initialState = useMemo(
        (): InsuranceResumeFlowState => ({
            channel: insuranceApplication.channel,
            company,
            token,
            zone,
            country,
            insuranceApplication,
            source: ApplicationFlowSource.RESUMEINSURANCE,
            consents,
            insuranceCompany: insuranceApplication.insuranceCompany,
        }),
        [zone, country, insuranceApplication, token, company, consents]
    );

    const flowImplementation = getFlowImplementation(insuranceApplication.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'insuranceResumeFlow');

    useScrollTop(step.identifier);

    useInsuranceCompleteHandler('insuranceResumeFlow');

    return step.render();
};

export default InsuranceResumeFlowHandler;
