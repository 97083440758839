import { flow, get, map, uniqBy } from 'lodash/fp';
import { useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { VariantDataFragment, ModelDataFragment } from '../../../data/useLoadVariants.graphql';

export const getModelsFromVariants = flow([map(({ model }) => model.parent || model), uniqBy(get('id'))]);

export type ModelFilter<TVariant extends VariantDataFragment> = {
    current: string | null;
    set: Dispatch<SetStateAction<string | null>>;
    list: ModelDataFragment[];
    filteredVariants: TVariant[];
};

const useModels = <TVariant extends VariantDataFragment>(variants: TVariant[]): ModelFilter<TVariant> => {
    // first isolate models
    const models = useMemo(() => getModelsFromVariants(variants) as ModelDataFragment[], [variants]);

    // then set a state to change it
    const [current, setCurrent] = useState<string | null>(null);

    // the following effect need to be ran every time the variants changed
    useEffect(() => {
        // by default if there is only one model we are going to pre-select it
        // otherwise set it to null to select all
        setCurrent(!models.length || models.length > 1 ? null : models[0].id);
    }, [variants, models, setCurrent]);

    // filter variants with the current model
    const filteredVariants = useMemo(() => {
        if (!current) {
            // no need to filter
            return variants;
        }

        // limit to the ones for this same maker
        return variants.filter(({ model }) => (model.parent || model).id === current);
    }, [variants, current]);

    return { current, set: setCurrent, list: models, filteredVariants };
};

export default useModels;
