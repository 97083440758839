import * as core from '../utilities/permissions';
import { getUser, getCountryId } from './context';

export const getGlobalPermissions = state => core.getGlobalPermissions(getUser(state), getCountryId(state));

export const mayViewApplication = state => core.mayViewApplication(getUser(state));

export const mayManageApplication = state => core.mayManageApplication(getUser(state));

export const mayViewLead = state => core.mayViewLead(getUser(state));

export const mayManageLead = state => core.mayManageLead(getUser(state));
