import React from 'react';
import { useLocation } from 'react-router-dom';
import Inner from './Inner';

const CsvRoute = () => {
    const { key } = useLocation();

    // trigger re-render when navigates to same page
    return <Inner key={key} />;
};

export default CsvRoute;
