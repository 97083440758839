import { useMemo } from 'react';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import ResumeFlow, { ResumeFlowState } from '../../../../flows/ResumeFlow/ResumeFlow';
import { FlowType, useManagedFlow, useScrollTop } from '../../../../flows/utils/flow';
import useCompleteHandler from '../../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';

export type ResumeHandlerProps = {
    application: ApplicationDataFragment;
    consents: ConsentDataFragment[];
    token: string;
};

const getFlowImplementation = (channel: Channel): FlowType<ResumeFlowState, ResumeFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
        case Channel.USED:
            return ResumeFlow;

        default:
            return null;
    }
};

const ResumeHandler = ({ application, consents, token }: ResumeHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();

    // create the initial state
    const initialState = useMemo(
        (): ResumeFlowState => ({
            channel: application.channel,
            company,
            token,
            zone,
            country,
            application,
            financeProduct: application.financeProduct,
            bank: application.financeProduct?.bank,
            promo: application.promoCode,
            source: ApplicationFlowSource.RESUME,
            consents,
        }),
        [zone, country, application, token, company, consents]
    );

    const flowImplementation = getFlowImplementation(application.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'resumeFlow');

    useScrollTop(step.identifier);

    useCompleteHandler('resumeFlow');

    return step.render();
};

export default ResumeHandler;
