// @ts-ignore
import { Button } from '@appvantageasia/afc-ui';
import { readableColor, lighten } from 'polished';
import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import breakpoints from '../../../../utilities/constants/breakpoints';

export const getSizingStyle = (component: keyof DefaultTheme['sizing']) => ({
    theme,
}: {
    theme: DefaultTheme;
}): FlattenSimpleInterpolation => {
    const sizing = theme.sizing[component][theme.display];

    return css`
        line-height: ${sizing.lineHeight};
        font-size: ${sizing.fontSize};
        font-weight: ${sizing.fontWeight};
        letter-spacing: ${sizing.letterSpacing};
    `;
};

export const Title = styled.h1`
    margin-bottom: 8px;
    ${getSizingStyle('h1')}
    line-height: normal;
`;

export const SubTitle = styled.h2`
    margin-bottom: 17px;
    ${getSizingStyle('h2')}
    text-align: center;
    color: #000;
    line-height: 32px;
    font-size: 32px;
    font-weight: 700;
`;

export const Paragraph = styled.p`
    ${getSizingStyle('p')}
    color: ${props => props.theme.colors.defaultColor};
    line-height: 1.4;
`;

export const DisabledText = styled.p`
    ${getSizingStyle('disabled')}
    color: ${props => props.theme.colors.disabledColor};
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const InventoryText = styled(DisabledText)`
    margin-top: 10px;
    text-align: right;
`;

// max height 430px for 10 : 6 ratio
export const VariantImage = styled(ImgWithFallback)`
    width: 100%;
    object-fit: cover;
    max-height: 430px;
`;

export const Page = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 22px auto 47px;

    @media (max-width: ${breakpoints.sm}) {
        margin-top: 0;
    }

    @media (min-width: ${breakpoints.md}) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (min-width: ${breakpoints.xl}) {
        padding: 0;
    }
`;

export const Row = styled.div<{ columns?: number }>`
    ${({ theme, columns = 2 }) => {
        switch (theme.display) {
            case 'small':
                return undefined;

            default:
                return css`
                    display: grid;
                    grid-gap: 47px;
                    grid-template-columns: repeat(${columns || 2}, 1fr);

                    @media (max-width: ${breakpoints.sm}) {
                        display: block;
                    }
                `;
        }
    }}
`;

export const AsyncBlock = styled.div``;

export const Block = styled.div`
    & + &,
    & + ${AsyncBlock}.used, ${AsyncBlock}.used + &,
    & + ${AsyncBlock}:not(.used) + & {
        margin-top: ${props => `${props.theme.display === 'large' ? 30 : 20}px`};
    }
`;

export const Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 21px;

    & > button + button {
        margin-top: 10px;
    }
`;

export const PrimaryButton = styled(Button)`
    color: #fff;
    background-clip: padding-box;
    background-color: #313539;
    text-align: left;
    min-height: 48px;
    border-radius: 0;
    text-transform: none;

    &:disabled {
        cursor: not-allowed;
        background-color: ${lighten(0.1, '#000')};
        color: ${readableColor('#000')};
    }
`;

export const Container = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;
