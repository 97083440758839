import { useEffect, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { ProcessedVariant } from './processVariantWithFormattedPrices';
import useMonthlyPaymentOptions, { MonthlyPaymentOption } from './useMonthlyPaymentOptions';

export type MonthlyPaymentFilter<TVariant extends ProcessedVariant> = {
    current: MonthlyPaymentOption['value'] | null;
    set: Dispatch<SetStateAction<MonthlyPaymentOption['value'] | null>>;
    list: MonthlyPaymentOption[];
    filteredVariants: TVariant[];
};

const useMonthlyPayment = <TVariant extends ProcessedVariant>(
    currentVariants: TVariant[],
    formatCurrencyValues: (values: number[], unit?: string, separator?: string) => string,
    roundNumber: (value: number) => number,
    currencySymbol: string
): MonthlyPaymentFilter<TVariant> => {
    // get monthly payment options
    // use current list of variants
    const options = useMonthlyPaymentOptions(currentVariants, formatCurrencyValues, roundNumber, currencySymbol);
    // and provide a state to switch between the two
    // by default at null, meaning all
    const [current, setCurrent] = useState<MonthlyPaymentOption['value'] | null>(null);

    // reset filter whenever there is change in variant
    useEffect(() => setCurrent(null), [currentVariants]);

    // and filter our variants
    const filteredVariants = useMemo(() => {
        if (!current) {
            // use them all
            return currentVariants;
        }

        const { min, max } = current;

        return currentVariants.filter(({ monthlyPrice }) => monthlyPrice >= min && monthlyPrice <= max);
    }, [current, currentVariants]);

    return { current, set: setCurrent, list: options, filteredVariants };
};

export default useMonthlyPayment;
