import styled from 'styled-components';

const FooterContainer = styled.div`
    font-size: 0.8rem;
    color: #aaa;
    text-align: center;

    padding-top: 10px;
`;

export default FooterContainer;
