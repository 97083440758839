import { useEffect } from 'react';
import { FinanceDataFragment } from '../../../components/data/useFinanceProducts.graphql';
import { CalculatorValues } from '../../../components/shared/calculator-next/types';
import { ConfigFormValues } from './Configurator/ConfigForm';
import useCalculatorValues from './Configurator/shared/useCalculatorValues';

type ResumeOrderProps = {
    defaultFinanceProduct: FinanceDataFragment;
    values: ConfigFormValues;
    onSubmit: (calculatorValues: Partial<CalculatorValues>) => Promise<void>;
};

const ResumeOrder = ({ defaultFinanceProduct, values, onSubmit }: ResumeOrderProps) => {
    // helps calculate calculator values from the state provided
    const calculatorValues = useCalculatorValues(defaultFinanceProduct, values);

    useEffect(() => {
        if (calculatorValues) {
            onSubmit(calculatorValues);
        }
    }, [calculatorValues, onSubmit]);

    return null;
};

export default ResumeOrder;
