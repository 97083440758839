import styled from 'styled-components';

const ChatInput = styled.input`
    outline: 0;
    border: 0;
    margin: 0 15px;
    width: calc(100% - 79px);
    height: 42px;
    padding: 0;
`;

export default ChatInput;
