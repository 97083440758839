import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Field, change, ReduxFormContext } from 'redux-form';
import styled, { css } from 'styled-components';

const Box = styled.button<{ selected: boolean }>`
    cursor: pointer;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    outline: none;
    margin: 5px;
    padding: 3px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;
    width: 70px;
    height: 70px;
    border-color: #f0f0f0;

    ${props =>
        props.selected &&
        css`
            border-color: red;
        `}
`;

const Inner = styled.div`
    background-color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

type OptionSelectProps = {
    options: { image: string; color: [string]; label: string; name: string }[];
    fieldName: string;
    input: any;
};

export const OptionSelect = ({ options, input, fieldName }: OptionSelectProps) => {
    const { value } = input;
    const dispatch = useDispatch();

    const { form } = useContext(ReduxFormContext);

    const onClick = useCallback(
        option => {
            options.forEach(
                (item, index) => item.name === option && dispatch(change(form, fieldName, options[index].name))
            );
        },
        [dispatch, fieldName, form, options]
    );

    return options.map(item => (
        <Box
            key={item.name}
            aria-label="Color Option Button"
            onClick={() => onClick(item.name)}
            selected={value === item.name}
        >
            {item.color.map(color => (
                <Inner key={color} color={color} />
            ))}
        </Box>
    ));
};

const OptionBoxField = (props: any) => <Field {...props} component={OptionSelect} />;

export default OptionBoxField;
