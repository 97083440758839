// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../i18n';
import NumberField from '../../shared/form-v2/NumberField';
import TextField from '../../shared/form-v2/TextField';

const InsuranceDetails = () => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.insuranceDetails')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.insuranceCompany')}
                            name={formatPath('insuranceCompany.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.insurancePremium')}
                            name="calculator.insurancePremium"
                            type="currency"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default InsuranceDetails;
