import styled from 'styled-components';

const DrawerOverlay = styled.div<{ open: boolean }>`
    height: 100vh;
    width: 100vw;
    display: ${props => (props.open ? 'block' : 'none')};

    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => (props.open ? 1000 : 9999)};
`;

export default DrawerOverlay;
