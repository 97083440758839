import styled from 'styled-components';

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    span {
        font-size: 16px;
    }
    div {
        width: 100%;
    }
    p {
        margin: 0;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
        font-size: 16px;
    }
    span:nth-child(2) {
        min-width: 100px;
        text-align: right;
    }
`;
