import { useQuery } from '@apollo/client';
import { isString } from 'lodash/fp';
import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useCountry } from '../../../hookSelectors';
import { EventExternalSite } from '../../../schema';
import { getCompanyCode, getRuntimeSettings } from '../../../selectors';
import Error404NotFound from '../Error404NotFound';
import ConfiguratorHandler from './ConfiguratorHandler';
import { getData, GetDataQueryVariables, GetDataQuery } from './data.graphql';

const ConfiguratorRoute = () => {
    const { search, key } = useLocation();
    // check if query string appended
    const state = qs.parse(search.substring(1))?.state;
    const valuesFromUrl = isString(state) ? JSON.parse(state) : undefined;

    const companyCode = useSelector(getCompanyCode);
    const { code: countryCode, id: countryId } = useCountry();

    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    // fetch finance product for ckd
    // fetch configurator events
    const { data, loading } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables: {
            externalSite: EventExternalSite.CONFIGURATOR,
            countryId,
            financeProductId: ckdConfiguration.vehicle.financeProductId,
            activeOnly: true,
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        // wait for the loading to be over
        return null;
    }

    // allow configurator when
    // company is porsche, country is malaysia
    // there's an existing event as configurator
    // and there's finance product

    if (companyCode === 'POR' && countryCode === 'MY' && data?.events.items && data?.financeProduct) {
        return (
            <ConfiguratorHandler
                key={key}
                defaultFinanceProduct={data.financeProduct}
                events={data.events.items}
                valuesFromUrl={valuesFromUrl}
            />
        );
    }

    return <Error404NotFound />;
};

export default ConfiguratorRoute;
