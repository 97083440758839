// @ts-ignore
import { ActivityLogContainer, HeadTitle } from '@appvantageasia/afc-ui';
import { map, orderBy, flow, filter } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useMedia from 'use-media';
import { useContentTranslation } from '../../../i18n';
import {
    ApplicationEventDocumentDelete,
    ApplicationEventDocumentUpload,
    ApplicationEventSource,
    ApplicationEventType,
    CustomerInfoSource,
    ConsentDeclarationEvent,
} from '../../../schema';
import breakpoints from '../../../utilities/constants/breakpoints';
import useFormatDateTime from '../../shared/useFormatDateTime';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';
import AddCommentModal from './AddCommentModal';
import { ApplicationDataFragment } from './data.graphql';
import { ApplicationData } from './index';

type Event = ApplicationData['events'][0];

type ComputedEvent = Event & { label: string | JSX.Element };

const withBy = (activity: string, author?: string | null) => (author ? `${activity} by ${author}` : activity);

const withComment = (activity: string, comment?: string | null) => (comment ? `${activity} - ${comment}` : activity);

const formatSource = (item: Event, bank: string | undefined) => {
    switch (item.source) {
        case ApplicationEventSource.USER:
            return item.by?.name;

        case ApplicationEventSource.BANK:
            return bank || '';

        case ApplicationEventSource.CUSTOMER:
            return 'Customer';

        case ApplicationEventSource.GUARANTOR:
            return 'Guarantor';

        case ApplicationEventSource.SYSTEM:
            return 'System';

        case ApplicationEventSource.INSURANCE:
            return 'Insurance';

        default:
            return null;
    }
};

const formatCustomerInfoSource = (source: CustomerInfoSource) => {
    switch (source) {
        case CustomerInfoSource.MYINFO:
            return 'MyInfo';

        case CustomerInfoSource.MANUAL:
            return 'Manual';

        default:
            return null;
    }
};

export const HeadBar = styled.div`
    min-height: 64.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LinkButton = styled.button`
    text-decoration: underline;
    margin-left: 3px;
    background: none;
    border: none;
    color: ${props => (props.disabled ? '#9c9c9c' : '#069')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export const Title = styled(HeadTitle)`
    padding-left: 0;
`;

const formatActivity = (
    item: Event,
    bank: string | undefined,
    firstSubmit: boolean,
    formatCurrencyDown: (value: any, unit?: string) => string,
    formatDateTime: (value: any) => string
): string | JSX.Element | null => {
    const author = formatSource(item, bank);

    switch (item.type) {
        case ApplicationEventType.SHARE:
            return withBy('Shared', author);

        case ApplicationEventType.DRAFT:
            return withBy('Draft is saved', author);

        case ApplicationEventType.CHECK: {
            const { identifier } = item.detail as ConsentDeclarationEvent;

            return withBy(`Consent ${identifier} Checked`, author);
        }

        case ApplicationEventType.SIGNING:
            return withBy(`Signing Initiated`, author);

        case ApplicationEventType.SIGN:
            return withBy(`Signing Completed`, author);

        case ApplicationEventType.SUBMIT:
            if (!firstSubmit) {
                return withBy(`Re-submitted`, author);
            }

            return withBy(`Submitted`, author);

        case ApplicationEventType.RECEIVE:
            return withBy(`Received`, author);

        case ApplicationEventType.APPROVE: {
            const activity = withBy(`Approved`, author);
            // @ts-ignore
            const approvedAmount: number | undefined = item.detail?.approvedAmount;

            if (!approvedAmount) {
                return activity;
            }

            return `${activity} - ${formatCurrencyDown(approvedAmount)}`;
        }

        case ApplicationEventType.DECLINE:
            return withBy(`Declined`, author);

        case ApplicationEventType.CANCEL:
            return withBy(`Cancelled`, author);

        case ApplicationEventType.SUBMISSIONFAIL:
            return withComment(`Submission Failed to ${bank}`, item.comment);

        case ApplicationEventType.UNABLETOSUBMIT:
            return withComment(`Unable to Submit to ${bank}`, item.comment);

        case ApplicationEventType.ACTIVATE:
            return withBy(`Activated`, author);

        case ApplicationEventType.COMPLETE:
            return withBy(`Completed`, author);

        case ApplicationEventType.SIGNREJECT:
            return withBy(`Signing Rejected`, author);

        case ApplicationEventType.DOCUMENTUPLOAD: {
            const { filename } = item.detail as ApplicationEventDocumentUpload;

            return withBy(`Document ${filename} uploaded`, author);
        }

        case ApplicationEventType.DOCUMENTDELETE: {
            const { filename } = item.detail as ApplicationEventDocumentDelete;

            return withBy(`Document ${filename} deleted`, author);
        }

        case ApplicationEventType.CUSTOMERINFORECEIVE: {
            // @ts-ignore
            const source: CustomerInfoSource | undefined = item.detail?.source;
            const infoSource = source && formatCustomerInfoSource(source);

            if (infoSource) {
                return `Applicant's Information Received (${infoSource}) by ${bank}`;
            }

            return `Applicant's Information Received by ${bank}`;
        }

        case ApplicationEventType.GUARANTORINFORECEIVE: {
            // @ts-ignore
            const source: CustomerInfoSource | undefined = item.detail?.source;
            const infoSource = source && formatCustomerInfoSource(source);

            if (infoSource) {
                return `Guarantor's information received (${infoSource})`;
            }

            return `Guarantor's information received`;
        }

        case ApplicationEventType.CANCELLATIONFAIL:
            return withComment(`Cancellation Failed to ${bank}`, item.comment);

        case ApplicationEventType.UNABLETOCANCEL:
            return withComment(`Unable to Cancel to ${bank}`, item.comment);

        case ApplicationEventType.SIGNTIMEOUT:
            return withComment(`Signing Timeout`);

        case ApplicationEventType.UNABLETOCONNECT:
            return withComment(`Unable to Connect`);

        case ApplicationEventType.CONNECTIONFAIL:
            return withComment(`Connection Failed`);

        case ApplicationEventType.PAYING:
            return withBy(`Pending Payment`, author);

        case ApplicationEventType.PAID:
            return withBy(`Payment Completed`, author);

        case ApplicationEventType.PAYMENTTIMEOUT:
            return `Payment Timeout`;

        case ApplicationEventType.UNABLETOPAY:
            return `Unable to Make Payment`;

        case ApplicationEventType.PAYMENTFAIL:
            return withBy(`Payment Failed`, author);

        case ApplicationEventType.PAYMENTCAPTURING:
            return withBy(`Capturing Payment`, author);

        case ApplicationEventType.EMAIL: {
            // @ts-ignore
            const { kind, receiver } = item.detail;

            if (!kind || !receiver) {
                return null;
            }

            return withBy(`Email sent to ${kind}: ${receiver}`, author);
        }

        case ApplicationEventType.ASSIGN: {
            // @ts-ignore
            const { kind, from, to } = item.detail;

            if (!kind || !to) {
                return null;
            }

            const assignedTo = `${kind} is assigned to ${to}`;

            if (from) {
                return `${assignedTo} from ${from}`;
            }

            return assignedTo;
        }

        case ApplicationEventType.REFUNDED:
            return withBy(`Payment Refunded`, author);

        case ApplicationEventType.AGREEMENTCONCLUDED:
            return withBy(`Agreement Concluded`, author);

        case ApplicationEventType.COMMENT: {
            // @ts-ignore
            const { comment } = item.detail;

            const information = withBy(`Comment added by`, author);

            return (
                <div style={{ margin: '10px 0' }}>
                    {information}: <br />
                    {comment}
                </div>
            );
        }

        case ApplicationEventType.APPLIEDFORFINANCING:
            return withBy(`Applied for Financing`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKINITIALIZED:
            return withBy(`Guaranteed Buyback Initiated`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKCOMPLETED:
            return withBy(`Guaranteed Buyback Completed`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKREJECTED:
            return withBy(`Guaranteed Buyback`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKTIMEOUT:
            return withComment(`Guaranteed Buyback Timeout`);

        default:
            // skip it
            return null;
    }
};

export const BaseActivityLog = ({
    id,
    activities,
    canAddComment = false,
}: {
    id: string;
    activities: ComputedEvent[];
    canAddComment?: boolean;
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const formatDateTime = useFormatDateTime();
    const formatDate = useFormatDateTime(false);
    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const [addCommentModal, setAddCommentModal] = useState<JSX.Element | null>();

    const closeAddCommentModal = useCallback(() => {
        setAddCommentModal(null);
        history.go(0);
    }, [setAddCommentModal, history]);

    const openAddCommentModal = useCallback(
        () => setAddCommentModal(<AddCommentModal applicationId={id} onClose={closeAddCommentModal} />),
        [setAddCommentModal, closeAddCommentModal, id]
    );

    return (
        <>
            <HeadBar className="head-bar">
                <Title>{t('applicationDetailsPage.label.activityLog')}</Title>
                {canAddComment && (
                    <LinkButton onClick={openAddCommentModal}>
                        {t('applicationDetailsPage.label.addComment')}
                    </LinkButton>
                )}
            </HeadBar>
            <ActivityLogContainer>
                <table>
                    <thead>
                        <tr>
                            <th>{t('applicationDetailsPage.label.dateOrTime')}</th>
                            <th>{t('applicationDetailsPage.label.activity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activities.map((item, index) => (
                            <tr key={index.toString()}>
                                {isMobile ? (
                                    <td style={{ width: '110px' }}>{formatDate(item.at)}</td>
                                ) : (
                                    <td>{formatDateTime(item.at)}</td>
                                )}
                                <td>{item.label}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ActivityLogContainer>
            {addCommentModal}
        </>
    );
};

export type ActivityLogProps = {
    application: ApplicationDataFragment;
};

const ActivityLog = ({ application }: ActivityLogProps) => {
    const { formatCurrencyDown } = useCompanyFormatting();
    const { ct } = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    const { events, financeProduct, id } = application;
    const bank = financeProduct?.bank;
    const activities: ComputedEvent[] = useMemo(() => {
        let firstSubmit = true;

        return flow([
            map((item: Event): ComputedEvent | null => {
                const entry = formatActivity(item, ct(bank?.name), firstSubmit, formatCurrencyDown, formatDateTime);

                if (entry === null) {
                    // we skip it
                    return null;
                }

                if (item.type === ApplicationEventType.SUBMIT) {
                    firstSubmit = false;
                }

                return { ...item, label: entry };
            }),
            filter(Boolean),
            orderBy('at', 'desc'),
        ])(events);
    }, [events, ct, bank, formatCurrencyDown, formatDateTime]);

    if (!activities.length) {
        return null;
    }

    return <BaseActivityLog activities={activities} id={id} canAddComment />;
};

export default ActivityLog;
