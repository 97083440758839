import React from 'react';
import { useSelector } from 'react-redux';
import { ContentTranslator, useContentTranslation } from '../../../i18n';
import { getCompany } from '../../../selectors';
import FadeInDown from '../../animations/FadeInDown';
import { CompanyScopeFragment } from '../../data/useLoadScope.graphql';
import { useCopyright } from '../../shared/Footer';
import HelmetTitle from '../../shared/HelmetTitle';
import LanguageSelector from '../../shared/LanguageSelector';
import { LoginLanguageContainer } from '../../ui/LanguageSelector';
import RootStyle from '../../ui/RootStyle';
import LoginContainer from '../../ui/login/LoginContainer';
import LoginFooter from '../../ui/login/LoginFooter';
import LoginFlow from './LoginFlow';

export const getLoginContainerProps = (company: CompanyScopeFragment | null, ct: ContentTranslator) => {
    if (!company) {
        return { noBrand: true };
    }

    return {
        brandName: ct(company.name),
        brandWelcome: ct(company.description),
    };
};

const LoginRoute = () => {
    const company = useSelector(getCompany);
    const { ct } = useContentTranslation();

    const copyright = useCopyright();

    return (
        <>
            <HelmetTitle title="Login" />
            <RootStyle />
            <FadeInDown>
                <LoginContainer {...getLoginContainerProps(company, ct)}>
                    <LoginFlow />
                </LoginContainer>
                <LoginFooter>{copyright}</LoginFooter>
                {company && (
                    <LoginLanguageContainer>
                        <LanguageSelector isAtLogin />
                    </LoginLanguageContainer>
                )}
            </FadeInDown>
        </>
    );
};

export default LoginRoute;
