import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    color: #9c9c9cc7;
    font-size: 0.85rem;
`;

const StyledIcon = styled(Icon)<{ checked: boolean }>`
    color: ${props => (props.checked ? '#5fb142' : '#da223a')};
    margin-right: 1rem;

    flex-shrink: 0;
    flex-basis: 15px;
`;

type PasswordRequirementProps = {
    description: string;
    isChecked: boolean;
};

const PasswordRequirement = ({ description, isChecked }: PasswordRequirementProps) => (
    <Container>
        <StyledIcon checked={isChecked} icon={isChecked ? faCheck : faTimes} />
        <div>{description}</div>
    </Container>
);

export default PasswordRequirement;
