import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../i18n';
import { Channel } from '../../../schema';
import { InventoryUnitsDataFragment } from '../../data/useLoadInventoryUnits.graphql';
import { VariantDataFragment } from '../../data/useLoadVariants.graphql';
import { TextAlignCenter } from '../TextAlign';
import CarCard from './CarCard';
import CarListContainer from './CarListContainer';

export type CarData = VariantDataFragment & {
    priceDescription: string;
    monthlyPrice: number;
    unit?: InventoryUnitsDataFragment;
    disabled?: boolean;
};

export type CarListProps = {
    cars: CarData[];
    onClick: (variant: VariantDataFragment) => void;
    withMake?: boolean;
};

const formatDescription = (monthlyPrice: number, priceDescription: string) => {
    if (monthlyPrice === 0) {
        const parts = priceDescription.split(' ');

        return `${parts[0]} ${parts[1]}`;
    }

    return priceDescription;
};
const chooseCarId = (car: CarData) => {
    if (Channel.USED) {
        return car?.unit?.id;
    }

    return car.id;
};

const CarList = ({ cars, onClick, withMake }: CarListProps) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    return (
        <CarListContainer lessThanTwo={cars.length <= 2}>
            {cars.length > 0 ? (
                cars.map(car => (
                    <CarCard
                        key={chooseCarId(car)}
                        description={formatDescription(car.monthlyPrice, car.priceDescription)}
                        disabled={car.disabled}
                        img={car.images[0]?.url}
                        label={withMake ? `${ct(car.model.make.name)} ${car.name}` : car.name}
                        onClick={car.disabled ? undefined : () => onClick(car)}
                    />
                ))
            ) : (
                <TextAlignCenter>{t('chooserPage.list.noCars')}</TextAlignCenter>
            )}
        </CarListContainer>
    );
};

export default memo(CarList);
