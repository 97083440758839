import React from 'react';
import styled, { css } from 'styled-components';
import NextTooltip from './NextTooltip';
import { ReactComponent as CircleInfo } from '../../../assets/images/circle-info.svg';

const StyledTooltip = styled(NextTooltip)`
    & {
        margin-left: 6px;
        margin-top: -1px;
        display: inline-flex;
        width: 24px;

        &:hover {
            color: ${props => props.theme.colors.activeColor};
        }
    }
`;

const TooltipText = styled.p<{ $isTitle?: boolean }>`
    margin-bottom: 0;
    ${({ $isTitle }) =>
        $isTitle
            ? css`
                  font-weight: 600;
                  margin-bottom: 8px;
              `
            : ''}
`;

const InfoTooltip = ({ title, content }: { title?: string; content?: string }) => (
    <StyledTooltip
        tooltip={
            <>
                <TooltipText $isTitle>{title}</TooltipText>
                <TooltipText>{content}</TooltipText>
            </>
        }
    >
        <CircleInfo />
    </StyledTooltip>
);

export default InfoTooltip;
