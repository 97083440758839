import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler } from 'redux-form';
import styled from 'styled-components';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { MiniConfiguratorDetails } from '../Summary/Summary';
import { ConfigFormProps, ConfigFormValues } from './ConfigForm';
import Delivery from './Delivery';
import Exterior from './Exterior';
import FooterWidget from './FooterWidget';
import Interior from './Interior';
import Package from './Package';
import SectionHeaderBar from './SectionHeaderBar';
import Tequipment from './Tequipment';
import Timepiece from './Timepiece';
import SlideShow from './shared/SlideShow';
import useDeliveryImage from './shared/useDeliveryImage';
import useExteriorImage from './shared/useExteriorImage';
import useInteriorImage from './shared/useInteriorImage';
import noImg from '../../../../assets/images/no-image.png';

const MobileView = styled.div`
    margin: 0;
`;

const DesktopView = styled.div<{ fixedPosition: boolean; scrollBarWidth: number }>`
    display: flex;
    flex-direction: row;
    .imageWrapper {
        width: calc(60vw - ${props => props.scrollBarWidth}px);
        max-height: 92vh;
        bottom: 0;
        position: ${props => (props.fixedPosition ? 'sticky' : 'relative')};
        top: ${props => (props.fixedPosition ? '10vh' : '0')};
    }
`;

const ImageContainer = styled.div<{ name: string; imageToShow: string }>`
    position: absolute;
    z-index: ${props => (props.name === props.imageToShow ? '99' : '0')};
    width: 100%;
    height: 100%;

    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left bottom;
    }
`;

const GifContainer = styled(ImageContainer)``;

type ViewContainerProps = {
    onSubmit: SubmitHandler<ConfigFormValues, ConfigFormProps, string>;
    setDisplayPackage: Dispatch<SetStateAction<boolean>>;
    setDisplayTimepiece: Dispatch<SetStateAction<boolean>>;
    setDisplayTerms: Dispatch<SetStateAction<boolean>>;
    calculatorValues: Partial<CalculatorValues>;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    scrollBarWidth: number;
};

const ViewContainer = ({
    onSubmit,
    setDisplayPackage,
    setDisplayTimepiece,
    setDisplayTerms,
    calculatorValues,
    setShowModal,
    scrollBarWidth,
}: ViewContainerProps) => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const [fixedPosition, setPosition] = useState(false);
    const [imageToShow, setImageToShow] = useState('interior');

    const exteriorOptions = ckdConfiguration.exterior.options;
    const interiorOptions = ckdConfiguration.interior.options;

    // get form values
    const { delivery, exteriorColor, interiorColor } = useFormValues<MiniConfiguratorDetails>();

    const exteriorImage = useExteriorImage(exteriorColor, interiorColor);
    const interiorImage = useInteriorImage(exteriorColor, interiorColor);
    const deliveryImage = useDeliveryImage(delivery);

    useEffect(() => {
        if (!isMobile) {
            window.onscroll = () => {
                if (window.scrollY > window.outerHeight - 200) {
                    setPosition(true);
                } else if (window.scrollY < window.outerHeight - 200) {
                    setPosition(false);
                }

                // the height that will trigger component change
                const height = 560;
                const introPageHeight = window.innerHeight + 250;
                if (window.scrollY > 0 && window.scrollY < introPageHeight + height) {
                    setImageToShow('exterior');
                } else if (window.scrollY > introPageHeight + height && window.scrollY < introPageHeight + height * 2) {
                    setImageToShow('interior');
                } else if (
                    window.scrollY > introPageHeight + height * 2 &&
                    window.scrollY < introPageHeight + height * 3
                ) {
                    setImageToShow('package');
                } else if (
                    window.scrollY > introPageHeight + height * 3 &&
                    window.scrollY < introPageHeight + height * 4
                ) {
                    setImageToShow('tequipment');
                } else if (
                    window.scrollY > introPageHeight + height * 4 &&
                    window.scrollY < introPageHeight + height * 5
                ) {
                    setImageToShow('timepiece');
                } else if (window.scrollY > introPageHeight + height * 5) {
                    setImageToShow('delivery');
                }
            };
        }
    }, [exteriorOptions, interiorOptions, isMobile]);

    if (isMobile) {
        return (
            <MobileView>
                <SectionHeaderBar text={ckdConfiguration.sectionHeader[0]} />
                <Exterior />
                <Interior />
                <SectionHeaderBar text={ckdConfiguration.sectionHeader[1]} />
                <Package setDisplayPackage={setDisplayPackage} />
                <Tequipment />
                <Timepiece setDisplayTerms={setDisplayTerms} setDisplayTimepiece={setDisplayTimepiece} />
                <Delivery onSubmit={onSubmit} />
                <FooterWidget calculatorValues={calculatorValues} setShowModal={setShowModal} />
            </MobileView>
        );
    }

    return (
        <DesktopView fixedPosition={fixedPosition} scrollBarWidth={scrollBarWidth}>
            <div className="imageWrapper">
                <ImageContainer imageToShow={imageToShow} name="exterior">
                    <ImgWithFallback alt="Exterior Color" fallbackSrc={noImg} src={exteriorImage} />
                </ImageContainer>
                <ImageContainer imageToShow={imageToShow} name="interior">
                    <ImgWithFallback alt="Interior Color" fallbackSrc={noImg} src={interiorImage} />
                </ImageContainer>
                <GifContainer imageToShow={imageToShow} name="package">
                    <video autoPlay loop muted playsInline>
                        <source src={ckdConfiguration.enhancedPackage.desktopGif} type="video/mp4" />
                    </video>
                </GifContainer>
                <ImageContainer imageToShow={imageToShow} name="tequipment">
                    <SlideShow isArrowColorGray={false} options={Object.values(ckdConfiguration.tequipment.options)} />
                </ImageContainer>
                <ImageContainer imageToShow={imageToShow} name="timepiece">
                    <SlideShow options={Object.values(ckdConfiguration.timepiece.options)} isArrowColorGray />
                </ImageContainer>
                <ImageContainer imageToShow={imageToShow} name="delivery">
                    <ImgWithFallback alt="Delivery" fallbackSrc={noImg} src={deliveryImage} />
                </ImageContainer>
            </div>
            <div className="selectorWrapper">
                <SectionHeaderBar text={ckdConfiguration.sectionHeader[0]} />
                <Exterior />
                <Interior />
                <SectionHeaderBar text={ckdConfiguration.sectionHeader[1]} />
                <Package setDisplayPackage={setDisplayPackage} />
                <Tequipment />
                <Timepiece setDisplayTerms={setDisplayTerms} setDisplayTimepiece={setDisplayTimepiece} />
                <Delivery onSubmit={onSubmit} />
                <FooterWidget calculatorValues={calculatorValues} setShowModal={setShowModal} />
            </div>
        </DesktopView>
    );
};

export default ViewContainer;
