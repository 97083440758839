import { GrayButton, Actions, Modal, ActionsCenter } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getApplicationUrl, getInsuranceApplicationUrl } from '../../utilities/urls';
import { SubmitSuccess } from '../ui/calculator';

const SubmitSuccessModalWithNoSession = ({
    onClose,
    application,
    updated = false,
    isInsurance = false,
    hasSendCustomerConfirmationEmail = false,
}) => {
    const history = useHistory();

    const onClick = useCallback(() => {
        if (isInsurance) {
            history.pushWithCompanyAndState(getInsuranceApplicationUrl, null, application.version.id);
        } else {
            history.pushWithCompanyAndState(getApplicationUrl, null, application);
        }
    }, [isInsurance, history, application]);

    const title = updated ? 'application updated' : 'application submitted';

    return (
        <Modal onClose={onClose} title={title} showClose showTitle>
            <SubmitSuccess>
                {updated ? (
                    <p>
                        The application for <span>{application?.customer?.name?.value}</span> has been updated.
                    </p>
                ) : (
                    <>
                        <p>
                            The application for <span>{application?.customer?.name?.value}</span> has been submitted.
                        </p>
                        {hasSendCustomerConfirmationEmail ? (
                            <p>
                                An email will be sent to <span>{application?.customer?.email?.value}</span>.
                            </p>
                        ) : (
                            <p>We will contact you shortly.</p>
                        )}
                    </>
                )}
            </SubmitSuccess>
            <Actions>
                <ActionsCenter>
                    <GrayButton onClick={onClick}>view application</GrayButton>
                </ActionsCenter>
            </Actions>
        </Modal>
    );
};

SubmitSuccessModalWithNoSession.propTypes = {
    application: PropTypes.shape({
        customer: PropTypes.shape({
            email: PropTypes.shape({
                value: PropTypes.string,
            }),
            name: PropTypes.shape({
                value: PropTypes.string,
            }),
        }),
        id: PropTypes.string.isRequired,
        version: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    hasSendCustomerConfirmationEmail: PropTypes.bool,
    isInsurance: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    updated: PropTypes.bool,
};

export default SubmitSuccessModalWithNoSession;
