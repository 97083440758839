import React, { useContext, ComponentType } from 'react';
import { ModalContext, Modal } from './ModalProvider';

export const useModal = () => useContext(ModalContext);

export type WrappedComponentProp = { [prop: string]: any };

export type WithModalProps = { modal: Modal };

export const withModal = <T extends WithModalProps = WithModalProps>(WrappedComponent: ComponentType<T>) => {
    const Component = (props: Omit<T, keyof WithModalProps>) => {
        const modal = useModal();

        return <WrappedComponent {...(props as T)} modal={modal} />;
    };

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    Component.displayName = `withModal(${displayName})`;

    return Component;
};
