import { useLazyQuery, useQuery } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router';
import {
    getApplicationByToken,
    GetApplicationByTokenQuery,
    GetApplicationByTokenQueryVariables,
} from '../../../api/application.graphql';
import { useCountry } from '../../../hookSelectors';
import Error404Suggestion from '../Error404Suggestion';
import { getEvent, GetEventQuery, GetEventQueryVariables } from './EventRoute.graphql';

type RouterParams = { eventId: string };

export type EventFlowBootstrapProps = {
    children: (event: GetEventQuery['event'], application?: GetApplicationByTokenQuery['application']) => ReactElement;
    token?: string;
};

const EventBootstrap = ({ token, children }: EventFlowBootstrapProps) => {
    const { eventId: id } = useParams<RouterParams>();

    const { data: eventData, loading: eventLoading } = useQuery<GetEventQuery, GetEventQueryVariables>(getEvent, {
        fetchPolicy: 'cache-and-network',
        variables: { id },
    });

    const [fetchApplication, { data: applicationData, loading: applicationLoading, called }] = useLazyQuery<
        GetApplicationByTokenQuery,
        GetApplicationByTokenQueryVariables
    >(getApplicationByToken, {
        fetchPolicy: 'network-only',
    });

    const { channelSetting } = useCountry();
    const { isActive } = channelSetting.event;

    useEffect(() => {
        if (!called && token) {
            fetchApplication({
                variables: { token },
            });
        }
    }, [token, called, fetchApplication]);

    if (eventLoading || applicationLoading) {
        // still loading
        return null;
    }

    const application = applicationData?.application;
    const event = eventData?.event;

    const isEventExpired = event?.period?.end && event.period.end < new Date();
    if (!isActive || !event || !event.isActive || isEventExpired) {
        // application is mandatory
        return <Error404Suggestion header={false} />;
    }

    return children(event, application);
};

export default EventBootstrap;
