import { flow, get, map, uniqBy, filter } from 'lodash/fp';
import { useMemo, useState, useEffect } from 'react';
import { ModelDataFragment, VariantDataFragment } from '../../../data/useLoadVariants.graphql';

const getSubModelsFromVariants = flow([
    map(({ model }) => model.parentId && model),
    uniqBy(get('id')),
    filter(Boolean),
]);

const useSubModels = <TVariant extends VariantDataFragment>(variants: TVariant[], currentModel: string | null) => {
    // first isolate sub models
    const subModels = useMemo(() => getSubModelsFromVariants(variants) as ModelDataFragment[], [variants]);

    // then set a state to change it
    const [current, setCurrent] = useState<string | null>(null);

    // the following effect need to be ran every time the variants changed
    useEffect(() => {
        // by default if there is only one sub model we are going to pre-select it
        // otherwise set it to null to select all
        // make and model is necessary, sub model is option,
        // so only model have value, sub model can have value
        if (currentModel) {
            setCurrent(!subModels.length || subModels.length > 1 ? null : subModels[0].id);
        } else {
            setCurrent(null);
        }
    }, [variants, subModels, setCurrent, currentModel]);

    // filter variants with the current model
    const filteredVariants = useMemo(() => {
        if (!current) {
            // no need to filter
            return variants;
        }

        // limit to the ones for this same maker
        return variants.filter(({ model }) => model.parentId && model.id === current);
    }, [variants, current]);

    return { current, set: setCurrent, list: subModels, filteredVariants };
};

export default useSubModels;
