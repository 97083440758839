import styled, { css } from 'styled-components';
import HeaderController from './HeaderController';

export const LoginAccessContainer = styled.div`
    background-color: #efefef;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.9rem;
    padding: 5px 30px;

    > div:not(:nth-child(1)) {
        color: rgb(213, 0, 28);
        cursor: pointer;
        box-shadow: none;
        outline: none;
    }

    @media (max-width: 450px) {
        padding: 5px 15px;
    }
`;

const HeaderContainer = styled.div<{ height?: string }>`
    height: ${props => (props.height && props.height !== '' ? props.height : '78px')};
    transition: height 0.8s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 0px;

    & > * {
        flex: 1;
    }

    & > ${HeaderController} {
        display: flex;
        justify-content: flex-end;
    }

    ${({ theme }) => {
        switch (theme.version) {
            case 'v2': {
                const isLarge = theme.display === 'large';
                const padding = isLarge ? 40 : 20;

                return css`
                    padding: 14px ${padding}px 14px ${padding}px;

                    svg {
                        fill: ${theme.colors.activeColor};
                        color: ${theme.colors.activeColor};
                    }
                `;
            }

            case 'v1':
            default:
                return css`
                    padding: 14px 30px;

                    @media (max-width: 450px) {
                        padding: 14px 15px;
                    }
                `;
        }
    }}
`;

export default HeaderContainer;
