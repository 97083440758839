import { get } from 'lodash/fp';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, WrappedFieldArrayProps, FieldType, Field, GenericFieldArray, ReduxFormContext } from 'redux-form';
import { loadDrivingLicenseClasses, loadDrivingLicenseValidityCodes } from '../../../../../actions';
import DateField from '../../../../../components/shared/form-v2/DateField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import { useCountry } from '../../../../../hookSelectors';
import { AppOptions } from '../../../../../reducers/options';
import { CustomerDetailsSource, LicenseType } from '../../../../../schema';
import { getDrivingLicenseClasses, getDrivingLicenseValidityCodes } from '../../../../../selectors';
import { getLicenseTypeOptions } from '../../../../../utilities/constants/options';

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

type LicenseClassesProps = {
    disabled?: boolean;
    fields: FieldType[];
    licenseType: string;
    drivingLicenseClasses: {
        value: string;
        label: string;
    }[];
};

const LicenseClasses = ({ disabled = false, fields, licenseType, drivingLicenseClasses }: LicenseClassesProps) => {
    const { t } = useTranslation();

    return fields.map(field => {
        const isQualified = licenseType === LicenseType.QUALIFIED;

        return (
            <Fragment key={field}>
                <SelectField.Outline
                    disabled={disabled}
                    label={t('customerDetails.label.licenseClass')}
                    name={`${field}.licenseClass`}
                    options={drivingLicenseClasses}
                />
                {isQualified && (
                    <DateField
                        disabled={disabled}
                        label={t('customerDetails.label.licenseIssueDate')}
                        name={`${field}.issueDate`}
                    />
                )}
            </Fragment>
        );
    });
};

type LicenseItemProps = {
    name: string;
    disabled?: boolean;
    index: number;
    value: { type: LicenseType; source: CustomerDetailsSource };
    drivingLicenseValidityCodes: AppOptions['drivingLicenseValidities'];
    drivingLicenseClasses: AppOptions['drivingLicenseClasses'];
};

const LicenseItem = ({
    name,
    disabled: disabledFromProps = false,
    value,
    drivingLicenseValidityCodes,
    drivingLicenseClasses,
    index,
}: LicenseItemProps) => {
    const { t } = useTranslation();
    // modify driving license options
    const drivingLicenseClassesOptions = useMemo(
        () => drivingLicenseClasses.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseClasses, value.source]
    );
    const drivingLicenseValidityCodesOptions = useMemo(
        () => drivingLicenseValidityCodes.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseValidityCodes, value.source]
    );

    const { sectionPrefix } = useContext(ReduxFormContext);
    // first create memoized watched fields
    const watchedFields = useMemo(
        () => [
            {
                path: name,
                properties: ['type', 'classes', 'validity', 'expiryDate'],
            },
        ],
        [name]
    );

    // get the field state
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    // is this field disabled
    const disabled = disabledFromProps || get(name, fieldState);

    // get the license type
    const licenseType = get('type', value);

    // we may have some extra fields depending the type
    const hasExtraFields = [LicenseType.QUALIFIED, LicenseType.PROVISIONAL].includes(licenseType);

    const { code } = useCountry();

    return (
        <>
            <SelectField.Outline
                disabled={disabled}
                label={t('customerDetails.label.licenseType')}
                name={`${name}.type`}
                options={getLicenseTypeOptions(t)}
                sort={false}
                isFullWidth
            />
            {hasExtraFields && (
                <>
                    <SelectField.Outline
                        disabled={disabled}
                        label={t('customerDetails.label.licenseValidity')}
                        name={`${name}.validity`}
                        options={drivingLicenseValidityCodesOptions}
                    />
                    <DateField
                        disabled={disabled}
                        label={t('customerDetails.label.licenseExpiryDate')}
                        name={`${name}.expiryDate`}
                    />
                    {code !== 'TH' && (
                        <FieldArrayCustom
                            component={LicenseClasses}
                            disabled={disabled}
                            drivingLicenseClasses={drivingLicenseClassesOptions}
                            licenseType={licenseType}
                            name={`${name}.classes`}
                        />
                    )}
                </>
            )}
        </>
    );
};

type LicenseArrayProps = WrappedFieldArrayProps & { disabled?: boolean };

const LicenseArray = ({ fields, disabled }: LicenseArrayProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDrivingLicenseClasses());
        dispatch(loadDrivingLicenseValidityCodes());
    }, [dispatch]);

    const drivingLicenseClasses = useSelector(getDrivingLicenseClasses);
    const drivingLicenseValidityCodes = useSelector(getDrivingLicenseValidityCodes);

    const items = fields.map((field, index) => (
        <LicenseItem
            key={field}
            disabled={disabled}
            drivingLicenseClasses={drivingLicenseClasses}
            drivingLicenseValidityCodes={drivingLicenseValidityCodes}
            index={index}
            name={field}
            value={fields.get(index)}
        />
    ));

    useEffect(() => {
        if (fields.length < 1) {
            fields.push({
                classes: [{}],
                source: CustomerDetailsSource.MANUAL,
            });
        }
    }, []);

    return <> {items}</>;
};

type DrivingLicenseProps = { disabled?: boolean };

const DrivingLicense = ({ ...props }: DrivingLicenseProps) => (
    <FieldArrayCustom component={LicenseArray} name="details.drivingLicense" rerenderOnEveryChange {...props} />
);

export default DrivingLicense;
