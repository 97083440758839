import { orderBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import { Container, Title } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import { BackStepContext } from '../../../utils/flow';
import { ConsentStepValues } from '../../steps/ConsentStep';
import ConsentForm, { schema } from './ConsentForm';

export type ConsentPageProps = {
    onSubmit: (values: ConsentStepValues) => Promise<void>;
    backStep?: BackStepContext;
    isLastStep: boolean;
    consents: ConsentDataFragment[];
};

const ConsentPage = ({ consents, onSubmit, backStep, isLastStep }: ConsentPageProps) => {
    const { t } = useTranslation();
    const validate = useValidation(schema);

    const sortedConsents = useMemo(() => orderBy('order', 'asc', consents), [consents]);

    // prepare initialValues
    const initialValues = {
        referenceId: undefined,
        consents: {},
    }; // todo

    // promise only consents have value, then execute the validation
    if (consents.length <= 0) {
        return null;
    }

    return (
        <Container>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <Title>{t('consentPage.title')}</Title>
            <ConsentForm
                consents={sortedConsents}
                initialValues={initialValues}
                isLastStep={isLastStep}
                onSubmit={onSubmit}
                validate={validate}
            />
        </Container>
    );
};

export default ConsentPage;
