import React from 'react';
import { FlowStep } from '../../utils/flow';
import { GuarantorFlowState } from '../GuarantorFlow';
import SubmittedPage from '../components/SubmittedPage';

class SubmitStep extends FlowStep<GuarantorFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'submit';
    }

    render() {
        return <SubmittedPage />;
    }
}

export default SubmitStep;
