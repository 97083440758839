import React, { memo } from 'react';
import { InsuranceApplicationStatus } from '../../../../schema';
import useFormChanged from '../../ApplicationRoute/actions/useFormChanged';
import { InsuranceApplicationDataFragment } from '../data.graphql';
import ContinueAction from './ContinueAction';
import ReSubmitAction from './ReSubmitAction';
import UpdateStateAction from './UpdateStateAction';

export type ActionsProps = {
    application: InsuranceApplicationDataFragment;
};

const Actions = ({ application }: ActionsProps) => {
    const hasChanged = useFormChanged();

    switch (application.status) {
        case InsuranceApplicationStatus.RECEIVED:
            return <UpdateStateAction application={application} showApproveDeclineActions />;

        case InsuranceApplicationStatus.DRAFT:
        case InsuranceApplicationStatus.PENDINGCUSTOMERCONFIRMATION:
        case InsuranceApplicationStatus.SIGNING:
        case InsuranceApplicationStatus.SIGNINGREJECTED:
        case InsuranceApplicationStatus.SIGNINGTIMEOUT:
        case InsuranceApplicationStatus.UNABLETOCONNECT:
        case InsuranceApplicationStatus.CONNECTIONFAILED:
            if (hasChanged) {
                return <ReSubmitAction application={application} />;
            }

            return <ContinueAction application={application} />;

        default:
            return <UpdateStateAction application={application} />;
    }
};

export default memo(Actions);
