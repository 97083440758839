import React from 'react';
import { SubmitProvider } from '../ApplicationDetails';
import ReSubmitProvider from './ReSubmitProvider';
import RetrySubmitProvider from './RetrySubmitProvider';
import useFormChanged from './useFormChanged';

const FailedSubmitProvider: SubmitProvider = props => {
    const hasChanged = useFormChanged();

    if (hasChanged) {
        return <ReSubmitProvider {...props} />;
    }

    return <RetrySubmitProvider {...props} />;
};

export default FailedSubmitProvider;
