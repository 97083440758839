import * as Sentry from '@sentry/react';
import { map, flow } from 'lodash/fp';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { updateConsent } from './application.graphql';
import { getPublicPrefix } from './helpers';
import * as miscellaneous from './miscellaneous.graphql';
import qrcode from './deposit-qrcode.png';

export const processNationalities = flow([map(({ code, name }) => ({ value: code, label: name }))]);
export const processCountries = flow([map(({ code, name, phone }) => ({ value: code, label: name, phone }))]);
export const processMarkets = flow([map(({ code, name, phone }) => ({ value: code, label: name, phone }))]);
export const processEmirates = flow([map(({ code, name }) => ({ value: code, label: name }))]);
export const processDialingCodes = flow([
    map(({ code, name, phone }) => ({
        value: `+${phone.code}`,
        label: name,
        countryCode: code,
        phone,
    })),
]);

export const fetchAppOptions = async client => {
    const { data } = await client.query({ query: miscellaneous.queryAppOptions, fetchPolicy: 'cache-first' });

    return data.options;
};

export const fetchMarkets = async client => {
    const { markets } = await fetchAppOptions(client);

    return processMarkets(markets);
};

export const fetchCountries = async client => {
    const { countries } = await fetchAppOptions(client);

    return processCountries(countries);
};

export const fetchNationalities = async client => {
    const { nationalities } = await fetchAppOptions(client);

    return processNationalities(nationalities);
};

export const fetchStatusOptions = async client => {
    const { statusOptions } = await fetchAppOptions(client);

    return statusOptions;
};

export const fetchDialingCodes = async client => {
    const { markets } = await fetchAppOptions(client);

    return processDialingCodes(markets);
};

export const fetchCountriesDialingCodes = async client => {
    const { countries } = await fetchAppOptions(client);

    return processDialingCodes(countries);
};

export const fetchGenders = async client => {
    const { genders } = await fetchAppOptions(client);

    return genders;
};

export const fetchOccupations = async client => {
    const { occupations } = await fetchAppOptions(client);

    return occupations;
};

export const fetchDownpaymentTo = async client => {
    const { downpaymentTo } = await fetchAppOptions(client);

    return downpaymentTo;
};

export const fetchRaces = async client => {
    const { races } = await fetchAppOptions(client);

    return races;
};

export const fetchMaritalStatus = async client => {
    const { maritalStatuses } = await fetchAppOptions(client);

    return maritalStatuses;
};

export const fetchResidentialStatus = async client => {
    const { residentialStatuses } = await fetchAppOptions(client);

    return residentialStatuses;
};

export const fetchDrivingLicenseValidityCodes = async client => {
    const { drivingLicenseValidities } = await fetchAppOptions(client);

    return drivingLicenseValidities;
};

export const fetchDrivingLicenseClasses = async client => {
    const { drivingLicenseClasses } = await fetchAppOptions(client);

    return drivingLicenseClasses;
};

export const fetchCities = async client => {
    const { cities } = await fetchAppOptions(client);

    return cities;
};

export const fetchTimezones = async client => {
    const { timezones } = await fetchAppOptions(client);

    return timezones;
};

export const fetchLanguages = async client => {
    const { languages } = await fetchAppOptions(client);

    return languages;
};

export const fetchRelationships = async client => {
    const { relationships } = await fetchAppOptions(client);

    return relationships;
};

export const fetchEducations = async client => {
    const { educations } = await fetchAppOptions(client);

    return educations;
};

export const fetchAddressTypes = async client => {
    const { addressTypes } = await fetchAppOptions(client);

    return addressTypes;
};

export const fetchIncomeTypes = async client => {
    const { incomeTypes } = await fetchAppOptions(client);

    return incomeTypes;
};

export const fetchResidenceTypes = async client => {
    const { residenceTypes } = await fetchAppOptions(client);

    return residenceTypes;
};

export const fetchEmirates = async client => {
    const { emirates } = await fetchAppOptions(client);

    return processEmirates(emirates);
};

export const fetchAffinBankOptions = async client => {
    return (await fetchAppOptions(client))?.affinBank ?? {};
};

export const fetchAffinBankAddressTypes = async client => {
    return (await fetchAffinBankOptions(client))?.addressTypes ?? [];
};

export const fetchAffinBankRaces = async client => {
    return (await fetchAffinBankOptions(client))?.races ?? [];
};

export const fetchAffinBankResidenceStatuses = async client => {
    return (await fetchAffinBankOptions(client))?.residentialStatuses ?? [];
};

export const fetchAffinBankIdTypes = async client => {
    return (await fetchAffinBankOptions(client))?.idTypes ?? [];
};

const depositStaticHtml = renderToStaticMarkup(
    <div style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <div style={{ textAlign: 'center', fontSize: '1.3rem', marginTop: '30px' }}>
            Please make payment using the PayNow QR Code below.
        </div>
        <div
            style={{
                birder: '1px solid #BFBFBF',
                margin: '10px 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60px',
            }}
        >
            <span style={{ textTransform: 'uppercase', color: '#666666' }}>Depost Amount</span>
            <span style={{ color: '#D5001C', fontSize: '1.2rem', marginTop: '4px' }}>$1,000</span>
        </div>
        <div
            style={{
                margin: '30px 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img alt="QR Code" src={qrcode} style={{ width: '200px', height: '200px', cursor: 'pointer' }} />
            <a
                href={qrcode}
                style={{ textAlign: 'center', color: '#D5001C', fontSize: '1.2rem', textDecoration: 'none' }}
                download
            >
                Download QR Code Image
            </a>
        </div>
        <div style={{ textAlign: 'center', fontSize: '1.3rem', marginTop: '30px', lineHeight: '25px' }}>
            Once the payment is approved, our Sales Consultant will contact you to complete the Vehicle Sales Agreement.
        </div>
    </div>
);

// todo fetch deposit payment static html info
export const fetchDepositPaymentStaticHtml = () => Promise.resolve(depositStaticHtml);

export const updateConsentOrDeclaration = async (client, variables) => {
    const { data } = await client.mutate({ mutation: updateConsent, variables, fetchPolicy: 'no-cache' });

    return data.consents;
};

export const bookInventory = async (client, variantId, options, referenceId, unitId = undefined) => {
    const variables = { variantId, options, referenceId, unitId };
    const { data } = await client.mutate({ mutation: miscellaneous.executeBookInventory, variables });

    return data.response;
};

export const fetchMaintenance = async (GET, companyCode, countryCode, isAuthenticated) => {
    const query = { companyCode, countryCode };
    const options = { query, urlPrefix: getPublicPrefix(isAuthenticated) };
    const { data } = await GET('/Maintenance', null, options);

    return data;
};

export const fetchRuntimeSettings = async client => {
    const { data } = await client.query({ query: miscellaneous.queryRuntimeSettings, fetchPolicy: 'network-only' });
    const settings = data.runtimeSettings;

    // update sentry environment
    Sentry.setTag('environment', settings.environment);

    return settings;
};

export const createUsedVariant = async (client, variables) => {
    const { data } = await client.mutate({ mutation: miscellaneous.createUsedVariant, variables });

    return data.variant;
};

export const updateUsedVariant = async (client, variables) => {
    const { data } = await client.mutate({ mutation: miscellaneous.updateUsedVariant, variables });

    return data.variant;
};
