import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import {
    getInventoryUnit,
    GetInventoryUnitQuery,
    GetInventoryUnitQueryVariables,
} from '../../../../components/data/useLoadInventoryUnit.graphql';
import { InventoryUnitsDataFragment } from '../../../../components/data/useLoadInventoryUnits.graphql';
import RedirectToCompanyHome from '../../../../components/utilities/RedirectToCompanyHome';

export type UnitProviderProps = {
    unitId: string;
    applicationId?: string;
    children: (unit: InventoryUnitsDataFragment) => ReactElement;
};

const UnitProvider = ({ unitId, applicationId, children }: UnitProviderProps) => {
    const { data, loading } = useQuery<GetInventoryUnitQuery, GetInventoryUnitQueryVariables>(getInventoryUnit, {
        variables: { id: unitId, referenceId: applicationId },
        fetchPolicy: 'cache-and-network',
    });

    const unit = data?.unit;

    if (!unit && !loading) {
        return <RedirectToCompanyHome />;
    }

    if (!unit) {
        return null;
    }

    return children(unit);
};

export default UnitProvider;
