import styled from 'styled-components';

const Title = styled.h1`
    font-size: 1.43rem;
    font-weight: 400;
    text-transform: ${props => props.theme.casing};
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    margin-top: 40px;

    @media (max-width: 450px) {
        margin-top: 0;
    }
`;

export default Title;
