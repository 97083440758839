import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';
import Card from '../Card';

const RootContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0;

    @media (min-width: ${breakpoints.sm}) {
        margin-top: 3.5%;
        width: auto;
    }
`;

const MainContainer = styled(Card)`
    width: 100%;
    box-sizing: content-box;

    @media (min-width: ${breakpoints.sm}) {
        width: 320px;
    }
`;

const BrandContainer = styled(Card)`
    padding: 80px 40px;
    width: 220px;
    background: #222034;
    color: #ffffff;
    white-space: pre-wrap;
    display: none;
    box-sizing: content-box;

    @media (min-width: ${breakpoints.md}) {
        display: block;
    }

    h1 {
        font-weight: bold;
    }

    div {
        font-weight: 400;
        line-height: 1.5;
    }
`;

type LoginContainerProps = {
    noBrand?: boolean;
    brandName?: string;
    brandWelcome?: string | null;
    children: React.ReactNode;
    className?: string;
};

const LoginContainer = ({ noBrand, brandName, brandWelcome, children, className }: LoginContainerProps) => (
    <div className={className}>
        <RootContainer>
            <MainContainer>{children}</MainContainer>
            {!noBrand && (
                <BrandContainer>
                    <h1>{brandName}</h1>
                    <div>{brandWelcome}</div>
                </BrandContainer>
            )}
        </RootContainer>
    </div>
);

export default styled(LoginContainer)`
    display: flex;
    justify-content: center;
`;
