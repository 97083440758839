import styled from 'styled-components';
import breakpoints from '../../../../utilities/constants/breakpoints';

export const Grid = styled.div`
    display: grid;
    grid-gap: 47px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: ${breakpoints.sm}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const ExpressGrid = styled(Grid)`
    padding: 0 30px;
`;

export const Disclaimer = styled.p`
    color: #666666;
`;

export const Emphasis = styled.a`
    color: ${props => props.theme.themeHighlightColour};
    border-bottom: 1px solid ${props => props.theme.themeHighlightColour};

    :hover {
        color: ${props => props.theme.themeHighlightColour};
        text-decoration: none;
    }
`;
