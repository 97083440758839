import { VariantDataFragment } from '../../../data/useLoadVariants.graphql';

const sortVariants = (a: VariantDataFragment, b: VariantDataFragment) => {
    /* first sort by makers */

    const aMakerOrder = a.model.make.order;
    const bMakerOrder = b.model.make.order;

    if (aMakerOrder !== bMakerOrder) {
        return aMakerOrder - bMakerOrder;
    }

    /* then sort by models */

    const aModelOrder = a.model.parent ? a.model.parent.order : a.model.order;
    const bModelOrder = b.model.parent ? b.model.parent.order : b.model.order;

    if (aModelOrder !== bModelOrder) {
        return aModelOrder - bModelOrder;
    }

    /* then sort by sub models */

    const aSubModelOrder = a.model.parentId ? a.model.order : 0;
    const bSubModelOrder = b.model.parentId ? b.model.order : 0;

    if (aSubModelOrder !== bSubModelOrder) {
        return aSubModelOrder - bSubModelOrder;
    }

    /* finally variants */

    return a.order - b.order;
};

export default sortVariants;
