import { uniq } from 'lodash/fp';
import { useMemo } from 'react';
import { Channel } from '../../schema';
import useFinanceProducts from '../data/useFinanceProducts';
import useLoadVariants from '../data/useLoadVariants';

const useCalculatorData = (channel: Channel, dealerId?: string, bank?: string | null, eventId?: string) => {
    const { variants: allVariants } = useLoadVariants('cache-and-network', channel, dealerId);
    const { financeProducts: allFinanceProducts } = useFinanceProducts('cache-first', channel, dealerId, eventId);

    // get finance products
    const financeProducts = useMemo(() => {
        if (!bank) {
            // we don't need to filter
            return allFinanceProducts;
        }

        return allFinanceProducts.filter(product => product.bankId === bank);
    }, [bank, allFinanceProducts]);

    // get available variant for our finance products
    const variants = useMemo(() => {
        if (!bank) {
            // no need to filter anything
            return allVariants;
        }

        // list the ids first
        const variantIds = uniq(financeProducts.flatMap(product => product.variants));

        return allVariants.filter(variant => variantIds.includes(variant.version.id));
    }, [financeProducts, allVariants, bank]);

    return useMemo(() => ({ variants, financeProducts }), [financeProducts, variants]);
};

export default useCalculatorData;
