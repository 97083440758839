import { useApolloClient } from '@apollo/client';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { get, toPairs } from 'lodash/fp';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, reduxForm, InjectedFormProps, ReduxFormContext } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import * as yup from 'yup';
import { attachLoading } from '../../../../../actions';
import {
    updateConsent,
    UpdateConsentMutation,
    UpdateConsentMutationVariables,
} from '../../../../../api/application.graphql';
import { ConsentDataFragment } from '../../../../../api/consents.graphql';
import { AdhocEventType } from '../../../../../schema';
import { ConsentStepValues } from '../../../steps/ConsentStep';
import { Buttons, PrimaryButton } from '../../Calculator/ui';
import ConsentField from '../../Consent/ConsentField';
import ConsentNonInteractiveField from '../../Consent/ConsentNonInteractiveField';

export type ConsentDepositStepValues = ConsentStepValues & {
    referenceId?: string;
    consentStatuses?: Record<string, boolean>;
};

export const renderConsent = (consent: ConsentDataFragment) => {
    const { hasCheckbox, id } = consent;

    if (hasCheckbox) {
        return <ConsentField key={id} consent={consent} name={`consents.${id}`} />;
    }

    return <ConsentNonInteractiveField key={id} consent={consent} name={`consents.${id}`} />;
};

const useConsentChangeEffect = (
    consentStatuses: { [consentId: string]: boolean },
    change: InjectedFormProps['change']
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const client = useApolloClient();
    const previousConsents = useRef(consentStatuses);
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const referenceId = useMemo(() => get(`${sectionPrefix}.referenceId`, formValues), [formValues, sectionPrefix]);

    useEffect(() => {
        toPairs(consentStatuses).forEach(async ([uniqueId, value]) => {
            if (get(uniqueId, previousConsents.current) !== value) {
                // prepare variables
                const variables = {
                    data: {
                        type: value ? AdhocEventType.CHECK : AdhocEventType.UNCHECK,
                        referenceId,
                        detail: { id: uniqueId },
                    },
                };

                // call the API
                const promise = client
                    .mutate<UpdateConsentMutation, UpdateConsentMutationVariables>({
                        mutation: updateConsent,
                        variables,
                    })
                    .then(apiResponse => apiResponse?.data?.consents);

                // attach the promise and wait
                const response = await dispatch<typeof promise>(attachLoading(promise));

                if (!response) {
                    throw new Error('Failed to sign consent');
                }

                change(`${sectionPrefix}.referenceId`, response.referenceId);
            }
        });

        previousConsents.current = consentStatuses;
    }, [consentStatuses, dispatch, referenceId, client, change, t, sectionPrefix]);
};

export type ConsentFormProps = {
    consents: ConsentDataFragment[];
    consentStatuses?: Record<string, boolean>;
    change: InjectedFormProps['change'];
};

export const ConsentForm = ({ consents, consentStatuses = {}, change }: ConsentFormProps) => {
    useConsentChangeEffect(consentStatuses, change);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{consents.map(renderConsent)}</>;
};

export type ConsentFormToConnectProps = {
    token: string;
    consents: ConsentDataFragment[];
    showNextButton: boolean;
    setValid: (valid: boolean) => unknown;
    setReferenceId: (referenceId: string) => unknown;
    referenceId?: string;
};
export type ConsentFormToConnectValues = Partial<ConsentDepositStepValues>;

const ConsentFormToConnect = ({
    token,
    consents,
    valid,
    handleSubmit,
    showNextButton,
    setValid,
    change,
}: ConsentFormToConnectProps & InjectedFormProps<ConsentFormToConnectValues, ConsentFormToConnectProps>) => {
    const { t } = useTranslation();
    const { consents: consentStatuses }: ConsentFormToConnectValues =
        useSelector(getFormValues('consentDeposit')) || {};

    useConsentChangeEffect(consentStatuses || {}, change);

    useEffect(() => {
        setValid && setValid(valid);
    }, [setValid, valid]);

    return (
        <>
            {consents.map(renderConsent)}
            {showNextButton && (
                <Buttons>
                    <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                        <FontAwesomeIcon icon={faAngleRight} /> {t('eventConsentDepositPage.button.next')}
                    </PrimaryButton>
                </Buttons>
            )}
        </>
    );
};

export const consentValidation = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((values: any, options: any) => {
        const consents = get('context.consents', options) as ConsentDataFragment[];

        if (!consents) {
            throw new Error('Consent missing in state/props');
        }

        const mandatoryConsents: { [id: string]: yup.BooleanSchema } = {};

        consents.forEach(consent => {
            if (consent.isMandatory && consent.hasCheckbox) {
                mandatoryConsents[consent.id] = yup
                    .boolean()
                    .default(false)
                    .oneOf([true], t('eventConsentDepositPage.error.consents'));
            }
        });

        if (!Object.keys(mandatoryConsents).length) {
            // no mandatory consents
            return yup.mixed().default(undefined);
        }

        return yup.object().default({}).shape(mandatoryConsents);
    });

export const schema = (t: TFunction) => yup.object().shape({ consents: consentValidation(t) });

export default reduxForm<ConsentFormToConnectValues, ConsentFormToConnectProps>({
    form: 'consentDeposit',
})(ConsentFormToConnect);
