import { subYears } from 'date-fns';
import { getOr } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import useOptions from '../../../utilities/constants/useOptions';
import DateField from '../../shared/form-v2/DateField';
import IdentityNumberField from '../../shared/form-v2/IdentityNumberField';
import SelectField from '../../shared/form-v2/SelectField';
import TextField from '../../shared/form-v2/TextField';
import { getLabelWithFlag } from '../../shared/form-v2/steps/CustomerDetails';
import { useDataMask } from '../../shared/partialForms/CustomerInformationForm';
import SubTitle from '../../ui/SubTitle';
import useCustomerSourceOptions from '../../utilities/useCustomerSourceOptions';
import usePhone from '../../utilities/usePhone';

export type CustomerDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const CustomerDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: CustomerDetailsProps) => {
    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();

    const { title, nationalities, maritalStatuses } = useOptions();
    const customer = getOr(formValues, 'customer', formValues);
    const minDateOfBirth = useMemo(() => subYears(new Date(), 18), []);

    const { code: phoneCountryCode } = usePhone();
    const beingMask = useDataMask();

    const customerSourceOptions = useCustomerSourceOptions();

    const { t } = useTranslation();

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.customerDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Title', customer?.title?.source)}
                        name="customer.title.value"
                        options={title}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={disabled}
                        label={getLabelWithFlag('Full Name', customer?.name?.source)}
                        name="customer.name.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={disabled}
                        label={getLabelWithFlag('Email 1', customer?.email?.source)}
                        name="customer.email.value"
                        withFocusClear={beingMask}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Mobile No. 1', customer?.phone?.source)}
                        name="customer.phone.value"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        withFocusClear={beingMask}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Nationality', customer?.details?.nationality?.source)}
                        name="customer.details.nationality.value"
                        options={nationalities}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <IdentityNumberField
                        label={getLabelWithFlag('Identity No.', customer?.identityNumber?.source)}
                        name="customer.identityNumber.value"
                        placeholder={t('customerDetails.placeholder.identityNumber')}
                        withFocusClear={beingMask}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        label={getLabelWithFlag('Date of Birth', customer?.dateOfBirth?.source)}
                        max={minDateOfBirth}
                        name="customer.dateOfBirth.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Gender', customer?.details?.gender?.source)}
                        name="customer.details.gender.value"
                        options={getOr([], 'genders', customerSourceOptions)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={disabled}
                        label={getLabelWithFlag('Marital Status', customer?.details?.maritalStatus?.source)}
                        name="customer.details.maritalStatus.value"
                        options={maritalStatuses}
                        sort={false}
                    />
                </div>
            </div>
        </>
    );
};

export default CustomerDetails;
