import { useQuery } from '@apollo/client';
import { Paging, TableList, Portlet, Footer } from '@appvantageasia/afc-ui';
import { flow, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { ReduxFormContext, getFormValues } from 'redux-form';
import { exportEventsApplication } from '../../../../actions/export';
import { useZone } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { ApplicationPhase, EventExternalSite } from '../../../../schema';
import { getLeadUrl } from '../../../../utilities/urls';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { Content } from '../../../ui/PageContainer';
import { getSortOrderEnum } from '../../AppointmentRoute/List/AppointmentListRoute';
import { Checkbox, CheckboxContainer } from './ApplicationList';
import Download from './Download';
import { withEventApplications } from './EventApplicationsContext';
import { getEventLeadsByZoneId } from './data.graphql';
import { useReducerActions } from './reducer';

const { FootBarButton, FootBarRow, FootBar } = Footer;

const useColumns = (name, onCheckboxChange, isAllCheckboxSelected, hasFinanceProduct, hasBank, checkedIds) => {
    const { t } = useTranslation();
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();
    const history = useHistory();

    return useMemo(() => {
        const columns = [
            {
                key: 'checkBox',
                label: '',
                showCheckbox: true,
                getValue: item => (
                    <CheckboxContainer>
                        <Checkbox
                            checked={isAllCheckboxSelected || checkedIds.includes(item.version.id)}
                            onChange={() => onCheckboxChange(item.version.id)}
                            type="checkbox"
                        />
                    </CheckboxContainer>
                ),

                checkBox: (
                    <CheckboxContainer>
                        <Checkbox
                            checked={isAllCheckboxSelected}
                            onChange={() => onCheckboxChange('all')}
                            type="checkbox"
                        />
                    </CheckboxContainer>
                ),
            },
            {
                key: 'leadDate',
                sortKey: 'version.createdAt',
                label: t('eventDetailsPage.label.leadDate'),
                getValue: flow([get('version.createdAt'), formatDateTime]),
            },
            {
                key: 'leadId',
                sortKey: 'identifier',
                label: t('eventDetailsPage.label.leadID'),
                getValue: get('identifier'),
                underlined: true,
                onClick: (event, item) => {
                    event.stopPropagation();
                    history.pushWithCompanyAndState(getLeadUrl, { previous: `Event ${name}` }, item.version.id);
                },
            },
            {
                key: 'dealer',
                sortKey: formatPath('dealer.name'),
                label: t('eventDetailsPage.label.leadDealer'),
                getValue: get(formatPath('dealer.name')),
            },
            {
                key: 'salesPerson',
                sortKey: 'assignee.name',
                label: t('eventDetailsPage.label.leadSalesPerson'),
                getValue: get('assignee.name'),
            },
            {
                key: 'customer',
                sortKey: 'customer.name.value',
                label: t('eventDetailsPage.label.leadCustomer'),
                getValue: get('customer.name.value'),
            },
            {
                key: 'variant',
                sortKey: formatPath('variant.name'),
                label: t('eventDetailsPage.label.leadVariant'),
                getValue: get(formatPath('variant.name')),
            },
            hasBank && {
                key: 'bank',
                sortKey: 'bank.name',
                label: t('eventDetailsPage.label.leadBank'),
                getValue: get(formatPath('bank.name')),
            },
            hasFinanceProduct && {
                key: 'financeProduct',
                sortKey: formatPath('financeProduct.name'),
                label: t('eventDetailsPage.label.leadFinancialProduct'),
                getValue: get(formatPath('financeProduct.name')),
            },
            {
                key: 'lastUpdatedDt',
                sortKey: 'version.updatedAt',
                label: t('eventDetailsPage.label.leadLastActivity'),
                getValue: flow([get('version.updatedAt'), formatDateTime]),
            },
        ];

        return columns.filter(Boolean);
    }, [
        isAllCheckboxSelected,
        t,
        formatDateTime,
        formatPath,
        hasBank,
        hasFinanceProduct,
        checkedIds,
        onCheckboxChange,
        history,
        name,
    ]);
};

const LeadList = ({
    dealerIds,
    eventApplicationsDispatch: reducerDispatch,
    eventApplicationsState: reducerState,
    setSubmissionCounter,
}) => {
    const { t } = useTranslation();
    const zone = useZone();
    const { language } = useContentTranslation();
    const { form } = useContext(ReduxFormContext);
    const { name, identifier, id } = useSelector(getFormValues(form)) || {};

    const { data } = useQuery(getEventLeadsByZoneId, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: zone.id,
            dealerIds: dealerIds?.length ? dealerIds : [],
            eventIds: id ? [id] : undefined,
            paging: {
                limit: reducerState.paging.pageSize,
                offset: (reducerState.paging.page - 1) * reducerState.paging.pageSize,
            },
            sorting: [
                {
                    type: reducerState.sortedOn.type,
                    order: getSortOrderEnum(reducerState.sortedOn.order),
                },
            ],
            locale: language,
        },
    });

    const { pagingProps, sorting, onCheckboxChange } = useReducerActions(
        reducerState,
        reducerDispatch,
        data?.results?.count ?? 0
    );

    useEffect(() => {
        if (setSubmissionCounter) {
            setSubmissionCounter(pagingProps?.itemCount);
        }
    }, [pagingProps.itemCount, setSubmissionCounter]);

    const leadsData = useMemo(() => {
        if (!data || !data?.results?.count) {
            return [];
        }

        return data?.results?.items?.map(lead => ({
            ...lead,
            variant: lead.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT ? null : lead.variant,
        }));
    }, [data]);

    const [downloadModal, setDownloadModal] = useState(false);
    const dispatch = useDispatch();
    const location = useParams();

    const { hasBank, hasFinanceProduct } = useMemo(
        () => ({
            hasFinanceProduct: leadsData.some(lead => lead.financeProduct),
            hasBank: leadsData.some(lead => lead.bank.name),
        }),
        [leadsData]
    );

    const columns = useColumns(
        name,
        onCheckboxChange,
        reducerState.isCheckedAll,
        hasBank,
        hasFinanceProduct,
        reducerState.checkedIds
    );

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        password => setDownloadModal(<Download onClose={closeDownloadModal} password={password} type="lead" />),
        [closeDownloadModal]
    );

    const download = useCallback(() => {
        openDownloadModal();
        dispatch(
            exportEventsApplication(
                location.id,
                identifier,
                reducerState.checkedIds,
                ApplicationPhase.LEAD,
                dealerIds,
                language
            )
        ).then(password => {
            if (password) {
                openDownloadModal(password);
            } else {
                setDownloadModal(null);
            }
        });
    }, [dealerIds, dispatch, identifier, language, location.id, openDownloadModal, reducerState.checkedIds]);

    if (!pagingProps.itemCount) {
        return null;
    }

    return (
        <Portlet name={t('eventDetailsPage.subHeading.leads')} closable open>
            <div className="container-fluid">
                <Content>
                    <TableList columns={columns} items={leadsData} sorting={sorting} />
                </Content>
                <Paging {...pagingProps} />
                {downloadModal}
                <FootBar />
                <FootBarRow>
                    <div style={{ flex: 1 }} />
                    <FootBarButton
                        disabled={reducerState.isCheckedAll ? false : reducerState.checkedIds.length === 0}
                        onClick={download}
                    >
                        {t('eventDetailsPage.button.download')}
                    </FootBarButton>
                </FootBarRow>
            </div>
        </Portlet>
    );
};

LeadList.propTypes = {
    dealerIds: PropTypes.arrayOf(PropTypes.string),
    eventApplicationsDispatch: PropTypes.func,
    eventApplicationsState: PropTypes.shape({}),
    setSubmissionCounter: PropTypes.func,
};

export default withEventApplications(LeadList);
