import styled from 'styled-components';

const Container = styled.div`
    max-width: 450px;
    margin: 0 auto;

    @media (max-width: 450px) {
        margin: 0 15px;

        h1 {
            margin-top: 10px;
        }
    }
`;

export default Container;
