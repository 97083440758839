import { useQuery } from '@apollo/client';
// @ts-ignore
import { Paging, TableList } from '@appvantageasia/afc-ui';
import { get, orderBy, flow } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { setNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { getCountryId } from '../../../../selectors';
import useLoading from '../../../../useLoading';
import { searchOnKeys } from '../../../../utilities/fp';
import { getEventDetailsUrl, getEventUrl } from '../../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../../shared/HelmetTitle';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { renderActive, renderCalendar, renderHighlightedDate } from '../../../ui/Cells';
import PageContainer, { Title, Content } from '../../../ui/PageContainer';
import { TextAlignCenter } from '../../../ui/TextAlign';
import useListContext, { useListContextFromState } from '../../../utilities/useListContext';
import usePaging from '../../../utilities/usePaging';
import useSearch from '../../../utilities/useSearch';
import useSorting from '../../../utilities/useSorting';
import { getEvents } from './EventsRoute.graphql';

const isExpired = flow([get('period.end'), it => new Date(it) < new Date()]);
const isExternalLinkClickable = (item: { id: string }) => !isExpired(item) && get('isActive', item);

const useColumns = () => {
    // get formatting
    const { t } = useTranslation();
    const formatDateTime = useFormatDateTime();
    const history = useHistory();

    return useMemo(
        () => [
            {
                key: 'identifier',
                sortKey: 'identifier',
                label: t('eventsListPage.list.columns.eventID'),
                getValue: get('identifier'),
            },
            {
                key: 'name',
                sortKey: 'name',
                label: t('eventsListPage.list.columns.eventName'),
                getValue: get('name'),
            },
            {
                key: 'period.start',
                sortKey: 'period.start',
                label: t('eventsListPage.list.columns.startDate'),
                getValue: renderHighlightedDate('period.start', isExpired, formatDateTime),
            },
            {
                key: 'period.end',
                sortKey: 'period.end',
                label: t('eventsListPage.list.columns.endDate'),
                getValue: renderHighlightedDate('period.end', isExpired, formatDateTime),
            },
            {
                key: 'setting.isDepositPaymentMandatory',
                sortKey: 'setting.isDepositPaymentMandatory',
                label: t('eventsListPage.list.columns.payment'),
                getValue: renderActive('setting.isDepositPaymentMandatory'),
            },
            {
                key: 'url',
                // function instead of string to sort based on true/false
                sortKey: (item: { period: { end: Date }; isActive: boolean }) =>
                    !isExpired(item) && get('isActive', item),
                label: t('eventsListPage.list.columns.url'),
                getValue: renderCalendar(isExternalLinkClickable),
                onClick: (event: { stopPropagation: () => void }, item: { id: string }) => {
                    event.stopPropagation();

                    if (isExternalLinkClickable(item)) {
                        // @ts-ignore
                        history.pushWithCompany(getEventUrl, item.id);
                    }
                },
            },
            {
                key: 'isActive',
                sortKey: 'isActive',
                label: t('eventsListPage.list.columns.active'),
                getValue: renderActive('isActive'),
            },
        ],
        [formatDateTime, history, t]
    );
};

const EventsRoute = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const columns = useColumns();
    const { formatPath } = useContentTranslation();

    const searchFields = [
        'name',
        'identifier',
        'customer.name.value',
        'variant.name',
        formatPath('financeProduct.name'),
        'status',
    ];
    // get initial list context
    const [, getInitial] = useListContextFromState();

    const countryId = useSelector(getCountryId);
    const dealerId = useMustExistDealerIdFromTenantContext();
    const variables = { countryId, dealerId };
    const { data, loading, error: apiError } = useQuery(getEvents, { variables, fetchPolicy: 'cache-and-network' });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    useLoading(isLoading);

    // searching comes first
    const search = useSearch('events', getInitial('search'));
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items, searchFields]);
    const matchedItems = useMemo(() => searchMethod(search), [searchMethod, search]);

    // then sort
    const sorting = useSorting({
        initialKey: getInitial('sorting.key', 'version.updatedAt'),
        initialOrder: getInitial('sorting.order'),
    });
    const sortedItems = useMemo(() => orderBy(sorting.key, sorting.order, matchedItems), [matchedItems, sorting]);

    // and finally paging
    const [pagedItems, paging] = usePaging(sortedItems, { initialPage: getInitial('paging.page'), search });

    // make a context to bump/restore those configuration
    const listContext = useListContext(paging, sorting, search);

    // callback on items
    const history = useHistory();
    const onItemClick = useCallback(
        item => {
            // @ts-ignore
            history.pushWithCompanyAndState(getEventDetailsUrl, { listContext }, item.id);
        },
        [history, listContext]
    );

    const { error = null } = (useLocation().state as { error: string | null }) ?? {};
    useEffect(() => {
        if (error) {
            dispatch(setNotification('Error', error));
        }
    }, [dispatch, error]);

    const renderMessage = () => {
        return !isLoading ? <TextAlignCenter>{t('eventsListPage.list.noEvents')}</TextAlignCenter> : null;
    };

    if (apiError) {
        throw apiError;
    }

    return (
        <PageContainer>
            <HelmetTitle channel={Channel.EVENT} title="List" />
            <Title>{t('eventsListPage.title')}</Title>
            <Content>
                {items.length ? (
                    <TableList columns={columns} items={pagedItems} onItemClick={onItemClick} sorting={sorting} />
                ) : (
                    renderMessage()
                )}
            </Content>
            <Paging {...paging} />
        </PageContainer>
    );
};

export default EventsRoute;
