import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { DraftFlow } from '../../../flows/DraftFlow';
import { useManagedFlow } from '../../../flows/utils/flow';
import useCompleteHandler from '../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import { getLocationCode, getCarModelsUrl } from '../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import Chatbot from '../../shared/Chatbot';
import Footer from '../../shared/Footer';
import Header from '../../shared/Header';
import PublicSessionTimer from '../../shared/PublicSessionTimer';
import { CalculatorValues } from '../../shared/calculator-next/types';
import RedirectToUsedCar from '../../utilities/RedirectToUsedCar';
import NotFoundRoute from '../NotFoundRoute';

export type LocationState = {
    lead?: {
        calculator: CalculatorValues;
        leadId: string;
        snapshot: any;
    };
};

const NewRoute = () => {
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();
    const dealerId = useMustExistDealerIdFromTenantContext();
    const { state } = useLocation<LocationState>();
    const leadState = state?.lead;
    const isAuthenticated = useSelector(getIsAuthenticated);

    const { step } = useManagedFlow(
        DraftFlow,
        {
            isAuthenticated,
            channel: Channel.NEW,
            zone,
            country,
            company,
            dealerId,
            ...leadState,
            source: ApplicationFlowSource.DRAFT,
        },
        'newSubmitFlow'
    );
    useCompleteHandler('newSubmitFlow');

    const url = useMemo(() => {
        const locationCode = getLocationCode(country.code, zone.code);

        return getCarModelsUrl(company.code, locationCode);
    }, [company.code, country.code, zone.code]);

    const { isActive } = country.channelSetting.new;
    const { isActive: isUsedCarActive } = country.channelSetting.used;

    if (!isActive) {
        if (isUsedCarActive) {
            return <RedirectToUsedCar />;
        }

        return <NotFoundRoute />;
    }

    return (
        <>
            <Header channel={Channel.NEW} />
            <Chatbot channel={Channel.NEW} />
            <PublicSessionTimer url={url} />
            {step.render()}
            <Footer />
        </>
    );
};

export default NewRoute;
