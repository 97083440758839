import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { attachLoading } from '../../../../actions';
import {
    retryBankSubmission,
    RetryBankSubmissionMutation,
    RetryBankSubmissionMutationVariables,
} from '../../../../api/application.graphql';
import { getCompanyIdentifier } from '../../../../selectors';
import { getApplicationsUrl } from '../../../../utilities/urls';
import { SubmitProvider } from '../ApplicationDetails';

const RetrySubmitProvider: SubmitProvider = ({ children, application }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useApolloClient();

    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const url = getApplicationsUrl(companyCode, locationCode, application);

    const applicationId = application.id;

    const onSubmit = useCallback(async () => {
        // call the api
        const promise = client.mutate<RetryBankSubmissionMutation, RetryBankSubmissionMutationVariables>({
            mutation: retryBankSubmission,
            variables: { applicationId },
        });

        // and wait for it
        await dispatch(attachLoading(promise));

        // then go back to application list
        history.push(url);
    }, [dispatch, history, url, client, applicationId]);

    return children(onSubmit);
};

export default RetrySubmitProvider;
