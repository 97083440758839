import styled from 'styled-components';

const FileFieldContainer = styled.div`
    grid-column: 1 /3;

    & > *:not(:first-child) {
        margin-top: 5px;
    }

    &::after {
        display: block;
        content: '';
        clear: both;
    }
`;

export const Action = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    text-transform: ${props => props.theme.casing};
    cursor: pointer;
    font-size: 1.07rem;
    font-weight: 400;
    float: right;
`;

export default FileFieldContainer;
