import styled from 'styled-components';

const SubTitle = styled.h2`
    font-size: 1.28rem;
    font-weight: 400;
    text-transform: ${props => props.theme.casing};
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
`;

export default SubTitle;
