import React, { ReactNode } from 'react';
import { useFixedMenuController } from '../../components/shared/FixedMenuController';
import { Navigation as Components } from '../../components/ui/calculator';
import { ReactComponent as Back } from '../../assets/images/back.svg';

const { Block, Container, Fill } = Components;

export type NavigationProps = {
    onPrev?: () => void;
    prevText?: string;
    children?: JSX.Element | ReactNode;
};

const Navigation = ({ prevText, onPrev, children = null }: NavigationProps) => {
    const { fixedMenu } = useFixedMenuController();

    return (
        <Container fixedMenu={fixedMenu}>
            {onPrev && (
                <Block>
                    <button onClick={onPrev} type="button">
                        <Back />
                        <span>{prevText}</span>
                    </button>
                </Block>
            )}
            <Fill />
            {children}
        </Container>
    );
};

export default Navigation;
