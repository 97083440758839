import styled from 'styled-components';
import IconButton from '../IconButton';

const ChatInputContainer = styled.div`
    height: 48px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;

    & > ${IconButton} {
        margin: 15px;
    }
`;

export default ChatInputContainer;
