import { displayFields } from '@appvantageasia/afc-calculator-ui-next';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import CompanyCalculator from '../../../../components/shared/calculator-next/CompanyCalculator';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../components/shared/calculator-next/useCalculatorMeta';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { Channel } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { CalculatorSnapshot } from '../../../utils/getSnapshotFromApplication';
import { SubTitle } from '../Configurator/shared/ui';
import { Row } from './shared/ui';

const CarPriceSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    div {
        width: 100%;
    }
    p {
        margin: 10px 0;
        font-size: 12px;
        font-style: italic;
    }
`;

const MonthlyPayment = styled.span`
    display: inline-block;
    cursor: pointer;

    /* Mobile Screen */
    @media (max-width: ${breakpoints.sm}) {
        padding-left: 90px;
    }
`;

type Errors = { [field: string]: string };

type CarPriceSectionProps = {
    totalPrice: string;
    monthlyInstalment: string;
    calculatorValues: Partial<CalculatorValues>;
    calculatorErrors?: Errors;
    variants: VariantDataFragment[];
    fallbackFinanceProduct: FinanceDataFragment;
    snapshot: CalculatorSnapshot;
    dealerId: string;
    event?: EventDataFragment;
};

const CarPriceSection = ({
    fallbackFinanceProduct,
    totalPrice,
    monthlyInstalment,
    variants,
    calculatorValues,
    snapshot,
    dealerId,
    event,
}: CarPriceSectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { formatCurrency } = useCompanyFormatting();

    const formatedReservationDesposit = useMemo(() => {
        if (event?.setting?.bookingPayment?.amount) {
            return formatCurrency(event?.setting?.bookingPayment?.amount);
        }

        return formatCurrency(ckdConfiguration.vehicle.reservationDeposit);
    }, [event, ckdConfiguration, formatCurrency]);

    const [open, setOpen] = useState(false);
    const toggle = useCallback(() => setOpen(state => !state), [setOpen]);

    const additionalMeta = useMemo(
        () => ({
            snapshot,
            channel: Channel.EVENT,
            financeProducts: [fallbackFinanceProduct],
            variants,
            allowOutdated: true,
        }),
        [fallbackFinanceProduct, snapshot, variants]
    );
    const meta = useCalculatorMeta(additionalMeta);

    return (
        <CarPriceSectionContainer>
            <Row>
                <SubTitle>{ckdConfiguration.summary.totalPrice}</SubTitle>
                <SubTitle>{totalPrice}</SubTitle>
            </Row>
            <Row>
                <span>{ckdConfiguration.summary.monthlyInstalment}</span>
                <MonthlyPayment onClick={toggle}>
                    From {monthlyInstalment} per month{' '}
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} size="sm" />
                </MonthlyPayment>
            </Row>
            <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: ckdConfiguration.summary.priceDisclaimer,
                }}
            />
            {/* calculator is purely used for display */}
            <CompanyCalculator channel={Channel.EVENT} initialValues={calculatorValues} meta={meta} disabled>
                <displayFields.CarModelPriceField
                    fieldKey="carModelAndPrice"
                    isViewable={() => false}
                    size={2}
                    override
                />
                <displayFields.CarModelField
                    fieldKey="variant"
                    isViewable={() => false}
                    labelTKey="calculator.label.carModel"
                    override
                />
                <displayFields.CarPriceField
                    fieldKey="carPrice"
                    isViewable={() => false}
                    labelTKey="calculator.label.carPrice"
                    override
                />
                <displayFields.MonthlyInstalmentField
                    fieldKey="monthlyInstalment"
                    isViewable={context => open && displayFields.isMonthlyInstalmentViewable(context)}
                    labelTKey="calculator.label.monthlyInstalment"
                    renderValue={context =>
                        displayFields.formatCurrency(context, calculatorValues.monthlyInstalments?.[0]?.amount ?? 0)
                    }
                    override
                />
                <displayFields.MultipleMonthlyInstalmentsField
                    fieldKey="monthlyInstalments"
                    isViewable={context => open && displayFields.isMultipleMonthlyInstalmentsViewable(context)}
                    labelTKey="calculator.label.monthlyInstalment"
                    override
                />
                <displayFields.TotalPriceField
                    fieldKey="totalPrice"
                    isViewable={context => open && displayFields.isTotalPriceViewable(context)}
                    labelTKey="calculator.label.totalPrice"
                    override
                />
                <displayFields.BankField
                    fieldKey="bank"
                    isViewable={() => false}
                    labelTKey="calculator.label.bank"
                    override
                />
                <displayFields.MarginOfFinanceField
                    fieldKey="loan.percentage"
                    isViewable={() => false}
                    labelTKey="calculator.label.marginOfFinance"
                    override
                />
                <displayFields.DownPaymentField
                    fieldKey="downPayment"
                    isViewable={context => open && displayFields.isDownPaymentViewable(context)}
                    labelTKey="calculator.label.downPayment"
                    renderValue={context =>
                        displayFields.formatCurrencyPercentage(context, calculatorValues?.downPayment || {})
                    }
                    override
                />
                <displayFields.PaymentTermField
                    fieldKey="tenure"
                    isViewable={context => open && displayFields.isPaymentTermViewable(context)}
                    labelTKey="calculator.label.paymentTerm"
                    renderValue={() => `${calculatorValues?.tenure} months`}
                    override
                />
                <displayFields.LoanField
                    fieldKey="loan"
                    isViewable={context => open && displayFields.isLoanViewable(context)}
                    labelTKey="calculator.label.loanAmount"
                    renderValue={context =>
                        displayFields.formatCurrencyPercentage(context, calculatorValues?.loan || {})
                    }
                    override
                />
                <displayFields.InterestRateField
                    fieldKey="interestRate"
                    isViewable={context => open && displayFields.isInterestRateViewable(context)}
                    labelTKey="calculator.label.interestRate"
                    renderValue={() => `${calculatorValues?.interestRate}% p.a. flat`}
                    override
                />
                <displayFields.FinanceProductField
                    fieldKey="financeProduct"
                    isViewable={() => open}
                    labelTKey="calculator.label.financeProduct"
                    override
                />
                <displayFields.BalloonPaymentField
                    fieldKey="balloon"
                    isViewable={() => false}
                    labelTKey="calculator.label.balloonPayment"
                    override
                />
                <displayFields.DepositField
                    fieldKey="deposit"
                    isViewable={() => false}
                    labelTKey="calculator.label.deposit"
                    override
                />
                <displayFields.PaymentModeField
                    fieldKey="paymentMode"
                    isViewable={() => false}
                    labelTKey="calculator.label.paymentMode"
                    override
                />
            </CompanyCalculator>
            {event?.setting?.isDepositPaymentMandatory && (
                <Row style={{ marginTop: open ? '10px' : '0' }}>
                    <span>{ckdConfiguration.summary.reservationDeposit}</span>
                    <span>{formatedReservationDesposit}</span>
                </Row>
            )}
        </CarPriceSectionContainer>
    );
};

export default CarPriceSection;
