import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { usePaymentCallback } from '../../shared/Payment';

const PaymentCallbackAction = props => {
    const { applicationId } = props;

    // then callback
    const paymentCallback = usePaymentCallback(props);

    useEffect(() => {
        if (paymentCallback) {
            paymentCallback();
        }
    }, [applicationId, paymentCallback]);

    return null;
};

PaymentCallbackAction.propTypes = {
    applicationId: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    referer: PropTypes.string.isRequired,
    resultCode: PropTypes.string.isRequired,
};

export default PaymentCallbackAction;
