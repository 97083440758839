import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCountries } from '../../../actions';
import { getCountries } from '../../../selectors';
import SelectField from './SelectField';

const CountryField = props => {
    const dispatch = useDispatch();
    const countries = useSelector(getCountries);

    useEffect(() => {
        dispatch(loadCountries());
    }, [dispatch]);

    return <SelectField.Outline options={countries} {...props} clearable={false} />;
};

export default CountryField;
