// @ts-ignore
import { CalculatorContext, ContentTranslation, IntlValue } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { CalculatorSelect as Select } from '@appvantageasia/afc-ui';
import { sortBy, get, flow, map, uniqBy, filter, identity } from 'lodash/fp';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { VariantDataFragment } from '../../../../data/useLoadVariants.graphql';
import { CalculatorInsuranceValues, CalculatorValues } from '../../types';
import EditionModal from '../EditionModal';

type Variant = VariantDataFragment;

type VariantFieldContext = {
    variants: Variant[];
};

type VariantFieldProps = {
    labelTKey: string;
    financeContext: CalculatorContext<CalculatorValues>;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
    close: () => void;
};

const valueKey = 'variant';

type Option<T> = {
    label: string;
    value: T;
};

type VariantState = {
    make: string;
    model: string;
    subModel: string | null;
    variant: string;
};

type SetMakeAction = { type: 'setMake'; make: string };
type SetModelAction = { type: 'setModel'; model: string };
type SetSubModelAction = { type: 'setSubModel'; subModel: string | null };
type SetVariantAction = { type: 'setVariant'; variant: string };
type SetAllAction = { type: 'setAll'; make: string; model: string; subModel: string | null; variant: string };
type VariantAction = SetMakeAction | SetModelAction | SetSubModelAction | SetVariantAction | SetAllAction;

const variantReducer = (state: VariantState, action: VariantAction) => {
    switch (action.type) {
        case 'setMake':
            return { ...state, make: action.make };

        case 'setModel':
            return { ...state, model: action.model };

        case 'setSubModel':
            return { ...state, subModel: action.subModel };

        case 'setVariant':
            return { ...state, variant: action.variant };

        case 'setAll':
            return { ...action };

        default:
            return state;
    }
};

const getMakesFromVariants = (variants: Variant[], ct: ContentTranslation, language: string) =>
    flow([
        map(get('model.make')),
        uniqBy(get('id')),
        sortBy(['order', `name.${language}`]),
        map((make: { id: string; name: IntlValue<string> }) => ({
            value: make.id,
            label: ct(make.name),
        })),
    ])(variants);

const getModelsFromVariants = (makeVariants: Variant[], ct: ContentTranslation, language: string) =>
    flow([
        map(({ model }) => model.parent || model),
        uniqBy(get('id')),
        sortBy(['order', `name.${language}`]),
        map((model: { id: string; name: IntlValue<string> }) => ({
            value: model.id,
            label: ct(model.name),
        })),
    ])(makeVariants);

const getSubModelsFromVariants = (modelVariants: Variant[], ct: ContentTranslation, language: string) =>
    flow([
        map(({ model }) => model.parentId && model),
        filter(Boolean),
        uniqBy(get('id')),
        sortBy(['order', `name.${language}`]),
        map((subModel: { id: string; name: IntlValue<string> }) => ({
            value: subModel.id,
            label: ct(subModel.name),
        })),
    ])(modelVariants);

const getVariantsFromSubModel = (subModel: string, variants: Variant[], ct: ContentTranslation, language: string) =>
    flow([
        subModel ? filter((variant: Variant) => variant.model?.id === subModel) : identity,
        sortBy(['order', `name.${language}`]),
        map((variant: { id: string; name: IntlValue<string> }) => ({
            value: variant.id,
            label: ct(variant.name),
        })),
    ])(variants);

const VariantField = (props: VariantFieldProps) => {
    const { ct, language } = useContentTranslation();
    const { financeContext: context } = props;
    const { values, getFieldContext } = context;
    const { variants } = getFieldContext('variant') as VariantFieldContext;

    const initialVariantId = (get(valueKey, values) as unknown) as string | undefined;

    const initialVariant = useMemo(() => variants.find(({ id }) => id === initialVariantId) || variants?.[0], [
        variants,
        initialVariantId,
    ]);

    const [state, dispatch] = useReducer(variantReducer, {
        make: initialVariant.model.make.id,
        model: initialVariant.model?.parentId || initialVariant.model?.id,
        subModel: initialVariant.model?.parentId ? initialVariant.model?.id : null,
        // for variants we use the variant ID
        variant: initialVariant.id,
    });

    const { make } = state;

    // get makes from variants
    const makes = useMemo(() => getMakesFromVariants(variants, ct, language), [variants, ct, language]);

    // previous state persisting in a ref
    const previousStateRef = useRef(state);
    const { current: previousState } = previousStateRef;

    // filter with make
    const makeVariants = useMemo(() => {
        return variants.filter(variant => variant.model.make.id === make);
    }, [make, variants]);

    // get models from make variants
    const models = useMemo(() => getModelsFromVariants(makeVariants, ct, language), [makeVariants, ct, language]);

    // if the make changed, get the first model
    // otherwise keep using the one in the state
    const model = previousState.make === make ? state.model : models[0].value;

    // filter with model
    const modelVariants = useMemo(
        () => makeVariants.filter(variant => (variant.model?.parentId || variant.model?.id) === model),
        [model, makeVariants]
    );

    // get sub models from model variants
    const subModels = useMemo(() => getSubModelsFromVariants(modelVariants, ct, language), [
        modelVariants,
        ct,
        language,
    ]);

    // if the model changed, get the first sub model
    // otherwise keep using the one in the state
    const subModel = previousState.model === model ? state.subModel : subModels[0]?.value;

    // then filter with sub models
    const availableVariants = useMemo(() => {
        return getVariantsFromSubModel(subModel, modelVariants, ct, language);
    }, [subModel, modelVariants, ct, language]);

    // if either the model or sub model changed, get the first available variant
    // otherwise keep using the one in the state
    const currentVariant =
        previousState.model === model && previousState.subModel === subModel
            ? state.variant
            : availableVariants[0]?.value;

    // finally update previous state
    previousStateRef.current = { make, model, subModel, variant: currentVariant };

    // keep the state up to date
    useEffect(() => {
        // this one will trigger a react update
        // however we re-filtered every level of the variant definition in one-shot
        // and it will be persisted with the help of the reference
        dispatch({ type: 'setAll', make, model, subModel, variant: currentVariant });
    }, [dispatch, make, model, subModel, currentVariant]);

    // get selected entries
    const selected = useMemo(
        () => ({
            make: makes.find((option: Option<string>) => option.value === make),
            model: models.find((option: Option<string>) => option.value === model),
            subModel: subModels.find((option: Option<string>) => option.value === subModel),
            variant: availableVariants.find((option: Option<string>) => option.value === currentVariant),
        }),
        [make, makes, model, models, subModel, subModels, currentVariant, availableVariants]
    );

    const actions = useMemo(
        () => ({
            setMake: ({ value: make }: Option<string>) => dispatch({ type: 'setMake', make }),
            setModel: ({ value: model }: Option<string>) => dispatch({ type: 'setModel', model }),
            setSubModel: ({ value: subModel }: Option<string>) => dispatch({ type: 'setSubModel', subModel }),
            setVariant: ({ value: variant }: Option<string>) => dispatch({ type: 'setVariant', variant }),
        }),
        [dispatch]
    );

    return (
        <EditionModal {...props} value={currentVariant} valueKey={valueKey}>
            {makes.length > 1 && (
                <Select key="make" name="make" onChange={actions.setMake} options={makes} value={selected.make} />
            )}
            <Select key="model" name="model" onChange={actions.setModel} options={models} value={selected.model} />
            {subModels.length > 0 && (
                <Select
                    key="subModel"
                    name="subModel"
                    onChange={actions.setSubModel}
                    options={subModels}
                    value={selected.subModel}
                />
            )}
            <Select
                key="variant"
                name="variant"
                onChange={actions.setVariant}
                options={availableVariants}
                value={selected.variant}
            />
        </EditionModal>
    );
};

export default VariantField;
