import { isEmpty } from 'lodash/fp';
import React, { ComponentType, memo, useMemo } from 'react';
import { Channel } from '../../../schema';
import useCalculatorData from '../../utilities/useCalculatorData';
import PromoCodeCalculator, { PromoCodeCalculatorProps } from './PromoCodeCalculator';

export type ConnectedCalculatorProps = Omit<PromoCodeCalculatorProps, 'calculatorComponent'> & {
    disabledBank?: boolean;
    calculatorComponent?: ComponentType<PromoCodeCalculatorProps>;
    channel: Channel;
    eventId?: string;
};

const ConnectedCalculator = ({
    initialValues,
    disabledBank = false,
    calculatorComponent: Calculator = PromoCodeCalculator,
    channel = Channel.NEW,
    dealerId,
    meta: initialMeta,
    eventId,
    ...props
}: ConnectedCalculatorProps) => {
    const { bank } = initialValues;

    // get our variants
    const { variants, financeProducts } = useCalculatorData(channel, dealerId, disabledBank ? bank : null, eventId);

    const meta = useMemo(
        () => ({
            variants,
            financeProducts,
            ...initialMeta,
        }),
        [financeProducts, initialMeta, variants]
    );

    // finance products and variants are still missing for calculator context
    if ([financeProducts, variants].some(isEmpty)) {
        return null;
    }

    return <Calculator {...props} channel={channel} dealerId={dealerId} initialValues={initialValues} meta={meta} />;
};

export default memo(ConnectedCalculator);
