// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { sortBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { assetConditionOptions } from '../../../../../utilities/constants/options';
import { ApplicationData } from '../../../../routes/ApplicationRoute';
import { ApplicationFormValues } from '../../../../routes/ApplicationRoute/ApplicationForm';
import useCompanyFormatting from '../../../../utilities/useCompanyFormatting';
import useFormValues from '../../../../utilities/useFormValues';
import NumberField from '../../NumberField';
import SelectField from '../../SelectField';
import TextAreaField from '../../TextAreaField';
import TextField from '../../TextField';

export type NewProps = {
    application: ApplicationData;
};

const New = ({ application }: NewProps) => {
    const { t } = useTranslation();
    const { formatPath, ct } = useContentTranslation();
    const { booking, variant } = application;
    const formats = useCompanyFormatting();

    const { options } = useFormValues<ApplicationFormValues>();

    const vin = booking?.unit?.details?.vin;
    const hasSubModel = !!variant.model.parentId;

    const optionsText = useMemo(() => {
        return sortBy(['group.order'], options)
            .map(
                ({ name, price, group }) =>
                    `${ct(group.name)}: ${ct(name)}${price ? ` - ${formats.formatCurrency(price)}` : ''}`
            )
            .join('\n');
    }, [ct, formats, options]);

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.vehicleOfInterest')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.make')}
                            name={formatPath(
                                hasSubModel ? 'variant.model.parent.make.name' : 'variant.model.make.name'
                            )}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.model')}
                            name={formatPath(hasSubModel ? 'variant.model.parent.name' : 'variant.model.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.subModal')}
                            name={formatPath(hasSubModel ? 'variant.model.name' : '')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.variant')}
                            name={formatPath('variant.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.vehicleID')}
                            name="variant.identifier"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('applicationDetailsPage.label.assetCondition')}
                            name="variant.assetCondition"
                            options={assetConditionOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.yearOfManufacture')}
                            name="variant.year"
                            disabled
                        />
                    </div>
                    {vin && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField fixedValue={vin} label={t('applicationDetailsPage.label.vinNumber')} disabled />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.startingPrice')}
                            name="calculator.carPrice"
                            type="currency"
                            disabled
                        />
                    </div>
                    {options.length > 0 && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextAreaField
                                fixedValue={optionsText}
                                label={t('applicationDetailsPage.label.carOptions')}
                                name="carOptions"
                                disabled
                            />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default New;
