import { flow, filter, map, join } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../../selectors';
import { deepOmit } from '../../../../../utilities/fp';
import { MiniConfiguratorDetailsDataFragment } from '../../../../routes/ApplicationRoute/data.graphql';
import useCompanyFormatting from '../../../../utilities/useCompanyFormatting';
import TextAreaField from '../../TextAreaField';

type MiniConfiguratorDetailsProps = {
    miniConfiguratorDetails: MiniConfiguratorDetailsDataFragment;
};

const MiniConfiguratorDetails = ({ miniConfiguratorDetails: values }: MiniConfiguratorDetailsProps) => {
    const { t } = useTranslation();
    // clean values
    const miniConfiguratorDetails = useMemo(() => deepOmit(['__typename'], values), [values]);

    const { formatCurrency } = useCompanyFormatting();
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const deliveryOptions = ckdConfiguration.delivery.options;

    // build mini configurator content
    const details = useMemo(() => {
        if (!miniConfiguratorDetails) {
            return null;
        }

        const {
            delivery,
            enhancedPackage,
            exteriorColor,
            interiorColor,
            tequipment,
            timepiece,
        } = miniConfiguratorDetails;

        const equipments = tequipment
            ? flow([
                  Object.keys,
                  filter((key: keyof MiniConfiguratorDetailsDataFragment['tequipment']) => !!tequipment[key]),
                  map((key: keyof MiniConfiguratorDetailsDataFragment['tequipment']) => {
                      const value = tequipment[key];

                      return `${value.label} - ${formatCurrency(value.price)}`;
                  }),
                  join('; '),
              ])(tequipment)
            : null;

        const timepieceDescription = timepiece
            ? `${timepiece.colouredRing} colour ring with ${timepiece.hands} hands${
                  timepiece.engraving ? `; Engraving - ${timepiece.engraving}` : ''
              }`
            : null;

        const deliveryPrice = delivery.label === deliveryOptions[1].label ? 'Charges may apply' : 'Included';

        return [
            `Exterior Color: ${exteriorColor} - Included`,
            `Interior Color:  ${interiorColor} - Included`,
            enhancedPackage && `Package: ${enhancedPackage.label} - ${formatCurrency(enhancedPackage.price)}`,
            equipments && `Tequipment: ${equipments}`,
            timepieceDescription && `Timepiece:  ${timepieceDescription}`,
            `Delivery: ${delivery.label} - ${deliveryPrice}`,
        ]
            .filter(Boolean)
            .join('\n');
    }, [deliveryOptions, formatCurrency, miniConfiguratorDetails]);

    return (
        <TextAreaField
            fixedValue={details}
            label={t('applicationDetailsPage.label.configuratorOptions')}
            name="__exclude.miniConfigurator"
            disabled
        />
    );
};

export default MiniConfiguratorDetails;
