import React from 'react';
import { updateCustomer, UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../api/customer.graphql';
import {
    completeGuarantor,
    CompleteGuarantorMutation,
    CompleteGuarantorMutationVariables,
} from '../../../api/draft.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { ApplicationEventSource, ApplicationFlowSource } from '../../../schema';
import { prepareForGraphQL } from '../../../utilities/forms';
import { ReduxFormFlowStep } from '../../utils/flow';
import { uploadFiles } from '../../utils/uploads';
import GuarantorPage from '../components/KYC/index';
import { NewFlowState } from '../types';
import { KYCStepValues } from './KYCStep';

class GuarantorStep extends ReduxFormFlowStep<NewFlowState, KYCStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return ApplicationFlowSource.GUARANTOR;
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('guarantorPage.label.step');
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.guarantor || false;
    }

    protected async execute({ files, ...customerData }: KYCStepValues) {
        const { apolloClient } = this;
        const { token, application } = this.state;

        if (!token) {
            throw new Error('token is missing in state');
        }

        if (!application) {
            throw new Error('application is missing in state');
        }

        // upload files
        await uploadFiles(
            apolloClient,
            [...files, ...application.attachments],
            application.attachments,
            null,
            application.id,
            token,
            ApplicationEventSource.GUARANTOR
        );

        // update the customer
        const { id: customerId, type, referenceId, ...data } = customerData;
        await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
            mutation: updateCustomer,
            variables: { id: customerId, data: prepareForGraphQL(data), token },
        });

        // then complete KYC
        const apiResponse = await apolloClient.mutate<CompleteGuarantorMutation, CompleteGuarantorMutationVariables>({
            mutation: completeGuarantor,
            variables: { token },
        });

        // update state
        this.stateStore.update({ ...apiResponse.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { bank, country, application, withMyInfoError, channel } = this.state;

        if (!bank) {
            throw new Error('Bank missing in state');
        }

        if (!application) {
            throw new Error('application missing in state');
        }

        return (
            <>
                <HelmetTitle channel={channel} title="Guarantor Details" />
                <GuarantorPage
                    application={application}
                    backStep={this.getBackContext()}
                    bank={bank}
                    country={country}
                    initialValues={{ ...application?.guarantor, files: application.attachments }}
                    isLastStep={this.isLastStep}
                    onSubmit={this.submit}
                    step="guarantor"
                    withMyInfoError={withMyInfoError}
                />
            </>
        );
    }
}

export default GuarantorStep;
