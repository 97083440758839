export const requirements = t => [
    {
        description: t('companyAuthenticationPage.error.atLeastUpperCase', { min: '1', range: '[A-Z]' }),
        regex: /[A-Z]/,
    },
    {
        description: t('companyAuthenticationPage.error.atLeastLowerCase', { min: '1', range: '[a-z]' }),
        regex: /[a-z]/,
    },
    {
        description: t('companyAuthenticationPage.error.atLeastNumber', { min: '1', range: '[0-9]' }),
        regex: /[0-9]/,
    },
    {
        description: t('companyAuthenticationPage.error.atLeastSpecialCharacter', {
            min: '1',
            range: `[!@#^&*()={}[]';,.?-]`,
        }),
        regex: /[!@#^&*()={}[\]';,.?-]/,
    },
    {
        description: t('companyAuthenticationPage.error.atLeastCharacter', {
            size: '8',
        }),
        regex: /.{8,}/,
    },
];

export const passwordRegex = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#^&*()={}[\]';,.?-]).*$/;
