import { State } from '../reducers';
import { getLocationCode } from '../utilities/urls';

export const getContext = (state: State) => state.context;

export const getDealerId = (state: State) => getContext(state)?.dealerId;

export const getCompany = (state: State) => getContext(state)?.company;

export const getCurrentCountry = (state: State) => getContext(state)?.country;

export const getCurrentZone = (state: State) => getContext(state)?.zone;

export const getCompanyCode = (state: State) => getCompany(state)?.code;

export const getCountryCode = (state: State) => getCurrentCountry(state)?.code;

export const getZoneCode = (state: State) => getCurrentZone(state)?.code;

export const getCompanyId = (state: State) => getCompany(state)?.id;

export const getZoneId = (state: State) => getCurrentZone(state)?.id;

export const getCountryId = (state: State) => getCurrentCountry(state)?.id;

export const getCompanyIdentifier = (state: State) => {
    const countryCode = getCountryCode(state);
    const zoneCode = getZoneCode(state);

    return {
        // ids
        countryId: getCountryId(state),
        companyId: getCompanyId(state),
        zoneId: getZoneId(state),
        // codes
        companyCode: getCompanyCode(state),
        countryCode,
        zoneCode,
        // location code
        locationCode: getLocationCode(countryCode, zoneCode),
    };
};

export const getUser = (state: State) => getContext(state).user;

export const getCompanyApiIdentifier = (state: State) => ({
    companyId: getCompanyId(state),
    countryId: getCountryId(state),
    zoneId: getZoneId(state),
    countryCode: getCountryCode(state),
});
