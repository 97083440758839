import { Modal as BaseModal } from '@appvantageasia/afc-ui';
import styled from 'styled-components';

const Modal = styled(BaseModal)`
    & > div {
        overflow: visible;
    }
`;

export default Modal;
