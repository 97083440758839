/* eslint-disable max-classes-per-file */
import { get } from 'lodash/fp';
import { parseJsonFromResponse } from './httpUtilities';

export class ResponseError extends Error {
    constructor(response, message = 'Response error') {
        super(message);
        this.response = response;
    }
}

export class BadRequest extends ResponseError {
    constructor(response, message = 'Bad Request') {
        super(response, message);
    }

    static async parseResponse(response) {
        const body = await parseJsonFromResponse(response);
        const error = new BadRequest(response, get('msg', body));

        return Promise.reject(error);
    }
}

export class Unauthorized extends ResponseError {
    constructor(response, message = 'Unauthorized') {
        super(response, message);
    }

    static parseResponse(response) {
        const error = new Unauthorized(response);

        return Promise.reject(error);
    }
}

export class PermissionDenied extends ResponseError {
    constructor(response, message = 'Permission Denied') {
        super(response, message);
    }

    static parseResponse(response) {
        const error = new PermissionDenied(response);

        return Promise.reject(error);
    }
}

export class BadGateway extends ResponseError {
    constructor(response, message = 'Bad Gateway') {
        super(response, message);
    }

    static parseResponse(response) {
        const error = new BadGateway(response);

        return Promise.reject(error);
    }
}

export class InternalError extends ResponseError {
    constructor(response, message = 'Internal Error') {
        super(response, message);
    }

    static parseResponse(response) {
        const error = new InternalError(response);

        return Promise.reject(error);
    }
}
