import { ApplicationDataFragment, GetApplicationQuery } from '../../components/routes/ApplicationRoute/data.graphql';
import { CalculatorValues } from '../../components/shared/calculator-next/types';
import { getCalculatorFromApplication } from '../../utilities/application';
import { CalculatorSourceValues } from './getCalculatorFromApplication';

type Application = ApplicationDataFragment;

export type CalculatorSnapshot = {
    calculator: Partial<CalculatorValues>;
    variant: Application['variant'];
    financeProduct: Application['financeProduct'];
    options: Application['options'];
    promo: Application['promoCode'];
    vin?: string;
};

export type SnapshotSourceValues = CalculatorSourceValues &
    Pick<GetApplicationQuery['application'], 'dealerId' | 'booking'>;

const getSnapshotFromApplication = (application: SnapshotSourceValues): CalculatorSnapshot => ({
    calculator: getCalculatorFromApplication(application),
    variant: application.variant,
    financeProduct: application.financeProduct,
    options: application.options,
    promo: application.promoCode,
    vin: application.booking?.unit?.details?.vin,
});

export default getSnapshotFromApplication;
