import { identity } from 'lodash/fp';
import React, { ComponentType, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationEventType, Channel, InsuranceApplicationStatus, Maybe } from '../../../schema';
import { getRuntimeSettings } from '../../../selectors';
import useValidationContext from '../../utilities/useValidationContext';
import ApplicationForm, { ApplicationFormSubmit, ApplicationFormValues } from './ApplicationForm';
import ContinueProvider from './actions/ContinueProvider';
import { InsuranceApplicationDataFragment } from './data.graphql';

export type ApplicationDetailsProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
};

export type SubmitProvider = ComponentType<{
    children: (submit: ApplicationFormSubmit) => ReactElement;
    application: InsuranceApplicationDataFragment;
    initialValues: ApplicationFormValues;
}>;

const NoSubmit: SubmitProvider = ({ children }) => children(identity);

const getAssetCondition = (application: InsuranceApplicationDataFragment): string => {
    if (application.channel === Channel.EXPRESS) {
        return 'Pre-owned';
    }

    return application.variant.assetCondition;
};

const getOriginalRegistrationDate = (insuranceApplication: InsuranceApplicationDataFragment): Maybe<Date> => {
    const submittedEvent = insuranceApplication.events.find(({ type }) => type === ApplicationEventType.SUBMIT);

    return submittedEvent ? submittedEvent.at : null;
};

const getInitialValues = (insuranceApplication: InsuranceApplicationDataFragment): ApplicationFormValues => ({
    variant: insuranceApplication.variant,
    assigneeId: insuranceApplication.assigneeId,
    customer: insuranceApplication.customer,
    proceedWithCustomerDevice: insuranceApplication.proceedWithCustomerDevice,
    attachments: insuranceApplication.attachments,
    assetCondition: getAssetCondition(insuranceApplication),
    insuranceCompany: insuranceApplication.insuranceCompany,
    calculator: {
        ...insuranceApplication.calculator,
        totalPrice: insuranceApplication.calculator.price + (insuranceApplication.calculator?.coe?.amount ?? 0),
        originalRegistrationDate: getOriginalRegistrationDate(insuranceApplication),
    },
});

const ApplicationDetails = ({ insuranceApplication }: ApplicationDetailsProps) => {
    const initialValues = useMemo(() => getInitialValues(insuranceApplication), [insuranceApplication]);

    // get some context information
    const validation = useValidationContext();

    // get runtime settings
    const { useCustomerNationality } = useSelector(getRuntimeSettings);

    const { status } = insuranceApplication;

    const [disabled, SubmitProvider]: [boolean, SubmitProvider] = useMemo(() => {
        switch (status) {
            case InsuranceApplicationStatus.PENDINGCUSTOMERCONFIRMATION:
            case InsuranceApplicationStatus.SIGNING:
            case InsuranceApplicationStatus.SIGNINGREJECTED:
            case InsuranceApplicationStatus.SIGNINGTIMEOUT:
            case InsuranceApplicationStatus.UNABLETOCONNECT:
            case InsuranceApplicationStatus.CONNECTIONFAILED:
            case InsuranceApplicationStatus.DRAFT:
                return [false, ContinueProvider];

            default:
                return [true, NoSubmit];
        }
    }, [status]);

    if (!insuranceApplication) {
        return null;
    }

    return (
        <SubmitProvider application={insuranceApplication} initialValues={initialValues}>
            {onSubmit => (
                <ApplicationForm
                    disabled={disabled}
                    initialValues={initialValues}
                    insuranceApplication={insuranceApplication}
                    onSubmit={onSubmit}
                    useCustomerNationality={useCustomerNationality}
                    validation={validation}
                    allowPrimaryInfoChanges
                />
            )}
        </SubmitProvider>
    );
};

export default ApplicationDetails;
