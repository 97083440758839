import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import LeadRoute from '../routes/LeadRoute';
import LeadsRoute from '../routes/LeadsRoute';
import Header from '../shared/Header';
import { prefixLeadPath as prefixPath } from './shared';

const LeadRouter = () => (
    <>
        <Header />
        <Switch>
            <Route component={LeadsRoute} path={prefixPath('/')} exact />
            <Route component={LeadRoute} path={prefixPath('/:id')} exact />
            <Redirect to={prefixPath('/')} />
        </Switch>
    </>
);

export default LeadRouter;
