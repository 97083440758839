import { useApolloClient } from '@apollo/client';
import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getApplication,
    getInsuranceApplication,
    getPollingGuaranteedBuybackStatus,
    setNotification,
} from '../../../actions';
import { GetScopeAccessQuery, GetScopeAccessQueryVariables, getScopeAccess } from '../../../api/scope.graphql';
import { ApplicationFlowSource, Channel, GuaranteedBuybackSigner, SigningStatus } from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import { PRIVATE_ACCESS } from '../../../utilities/constants/access';
import {
    getApplicationsUrl,
    getCompanyLoginUrl,
    getHomeUrl,
    getInsuranceApplicationsUrl,
    getLocationCode,
    parseCompanyPathname,
} from '../../../utilities/urls';
import { NamirialCallbackSession, getNotification } from './NamirialCallback';

export type GuaranteedBuybackCallbackSession = NamirialCallbackSession & {
    redirectionUrl: string;
    guaranteedBuybackSigner: GuaranteedBuybackSigner;
};

// Intentionally put the same callback key with namirial
// as callback for validating 2 documents (agreements and guaranteed buyback)
// is inside 1 envelope, and same callback webhook (status-update)
const GUARANTEED_BUYBACK_CALLBACK_STORAGE_KEY = 'namirialCallback';

export const guaranteedBuybackSession = {
    start: (session: GuaranteedBuybackCallbackSession) => {
        window.localStorage.setItem(GUARANTEED_BUYBACK_CALLBACK_STORAGE_KEY, JSON.stringify(session));

        window.location.replace(session.redirectionUrl);
    },
    stop: () => window.localStorage.removeItem(GUARANTEED_BUYBACK_CALLBACK_STORAGE_KEY),
    get: () =>
        JSON.parse(
            window.localStorage.getItem(GUARANTEED_BUYBACK_CALLBACK_STORAGE_KEY) || '{}'
        ) as GuaranteedBuybackCallbackSession,
};

const cleanGuaranteedBuybackSession = () => window.localStorage.removeItem(GUARANTEED_BUYBACK_CALLBACK_STORAGE_KEY);

const GuaranteedBuybackCallback = () => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector(getIsAuthenticated);

    const waitForResponse = useCallback(async () => {
        const session = guaranteedBuybackSession.get();

        if (!session || !session.envelopeId) {
            window.location.replace(window.location.origin);

            return;
        }

        const {
            applicationVersionId,
            source,
            redirectionUrl,
            countryCode,
            companyCode,
            zoneCode,
            channel,
            applicationPhase,
            origin,
            originApplicationVersionId,
            guaranteedBuybackSigner,
        } = session;

        // from query string, application should be same with applicationVersionId
        const { access, applicationId }: { access?: string; applicationId?: string } = qs.parse(
            window.location.search,
            {
                ignoreQueryPrefix: true,
            }
        );

        if (applicationId !== applicationVersionId) {
            window.location.replace(window.location.origin);

            return;
        }

        // Check whether authentication is needed
        const isPrivateAccess = (access?.toLowerCase() as string) === PRIVATE_ACCESS;

        // check whether authentication is needed
        const isAuthenticationRequired = isPrivateAccess && !isAuthenticated;

        const locationCode = getLocationCode(countryCode, zoneCode);
        const companyLoginUrl = getCompanyLoginUrl(companyCode, locationCode);

        if (isAuthenticationRequired) {
            // need to authenticate first
            history.push(companyLoginUrl, {
                // we want the user to come back here after login
                redirect: window.location.href,
            });
        }

        // begin repeating fetch envelope status
        // this will wait for a while
        const response = ((await dispatch(
            getPollingGuaranteedBuybackStatus(applicationVersionId, source, guaranteedBuybackSigner)
        )) as unknown) as { status: SigningStatus; newRedirectionUrl?: string };

        const scopeResponse = await client.query<GetScopeAccessQuery, GetScopeAccessQueryVariables>({
            query: getScopeAccess,
            variables: { companyCode, countryCode, zoneCode },
            fetchPolicy: 'network-only',
        });

        const companyRootUrl = getHomeUrl(channel)(companyCode, locationCode);
        const channelSetting = scopeResponse.data?.scope?.country?.channelSetting;
        const applicationsUrl = getApplicationsUrl(companyCode, locationCode, {
            phase: applicationPhase,
        });
        const insuranceApplicationsUrl = getInsuranceApplicationsUrl(companyCode, countryCode);

        const getAllowPublicAccess = () => {
            switch (channel) {
                case Channel.USED:
                    return channelSetting?.used?.allowPublicAccess;

                case Channel.NEW:
                    return channelSetting?.new?.allowPublicAccess;

                default:
                    return false;
            }
        };

        const allowPublicAccess = getAllowPublicAccess();
        const refererPathName = parseCompanyPathname(origin, companyCode, locationCode);

        const { title, message } = (t(getNotification(response.status), { returnObjects: true }) || {}) as {
            message: string;
            title: string;
        };

        const newLocationState = {
            applicationVersionId:
                source === ApplicationFlowSource.INSURANCE ? applicationVersionId : originApplicationVersionId,
            isInsurance: source === ApplicationFlowSource.INSURANCE,
        };

        const flowSourceHandler = async (isSuccessful: boolean) => {
            switch (source) {
                case ApplicationFlowSource.DRAFT:
                case ApplicationFlowSource.INSURANCE: {
                    if (isSuccessful) {
                        // dispatch(setNotification(title, message));
                        history.push(companyRootUrl, {
                            ...newLocationState,
                            submitted: true,
                        });
                    } else {
                        history.push(companyRootUrl, {
                            ...newLocationState,
                            signNotCompleted: {
                                title,
                                message,
                            },
                        });
                    }
                    break;
                }

                case ApplicationFlowSource.REMOTE:
                case ApplicationFlowSource.REMOTEINSURANCE: {
                    if (isSuccessful && allowPublicAccess) {
                        // redirect to home page with notification
                        dispatch(setNotification(title, message));
                        history.push(companyRootUrl);
                    } else if (isSuccessful && !allowPublicAccess) {
                        history.push(refererPathName, {
                            ...newLocationState,
                            isApplicationSubmitted: true,
                            signingRedirectionMessage: {
                                title,
                                message,
                            },
                        });
                    } else if (allowPublicAccess) {
                        // redirect to home page with notification
                        dispatch(setNotification(title, message));
                        history.push(companyRootUrl);
                    } else {
                        history.push(refererPathName, {
                            ...newLocationState,
                            isApplicationSubmitted: false,
                            signingRedirectionMessage: {
                                title,
                                message,
                            },
                        });
                    }

                    break;
                }

                case ApplicationFlowSource.RESUME: {
                    if (isSuccessful) {
                        // we need to get application
                        // as applications url expects an application instead of id
                        const application = await dispatch(getApplication(applicationId));
                        history.push(applicationsUrl, {
                            submitted: true,
                            application,
                        });
                    } else {
                        dispatch(setNotification(title, message));
                        history.push(applicationsUrl);
                    }

                    break;
                }

                case ApplicationFlowSource.RESUMEINSURANCE: {
                    if (isSuccessful) {
                        const application = await dispatch(getInsuranceApplication(applicationId));
                        history.push(insuranceApplicationsUrl, {
                            submitted: true,
                            application,
                        });
                    } else {
                        dispatch(setNotification(title, message));
                        history.push(applicationsUrl);
                    }

                    break;
                }

                case ApplicationFlowSource.EVENT: {
                    const appId = originApplicationVersionId;
                    const urlToRedirect = `${refererPathName}?applicationId=${appId}`;

                    if (isSuccessful) {
                        history.push(urlToRedirect);
                    } else {
                        dispatch(setNotification(title, message));
                        history.push(urlToRedirect);
                    }

                    break;
                }

                default:
                    break;
            }
        };

        // based on response status, it will determine if we need to still continue the journey or not
        // because the signers is not all yet completed
        // once it's rejected or timeout on the first try, we will redirect to the application list
        switch (response.status) {
            case SigningStatus.COMPLETED: {
                // if there is a new redirection url, we go back with to journey
                // with the new redirection url
                if (response.newRedirectionUrl) {
                    history.push(refererPathName, {
                        ...newLocationState,
                        guaranteedBuybackRedirectUrl: redirectionUrl,
                    });
                } else {
                    flowSourceHandler(true);
                }

                break;
            }

            case SigningStatus.REJECTED:
            case SigningStatus.TIMEOUT: {
                flowSourceHandler(false);
                break;
            }

            default:
                break;
        }
    }, [client, dispatch, history, isAuthenticated, t]);

    useEffect(() => {
        waitForResponse();

        return cleanGuaranteedBuybackSession;
    }, [waitForResponse]);

    return null;
};

export default GuaranteedBuybackCallback;
