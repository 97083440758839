import React from 'react';
import GuarantorMyInfoPage from '../../../components/ui/calculator/GuarantorMyInfoPage';
import { ApplicationFlowSource } from '../../../schema';
import { FlowStep } from '../../utils/flow';
import MyInfo from '../components/MyInfo';
import { NewFlowState } from '../types';
import { MyInfoStepValues } from './MyInfoStep';

class GuarantorMyInfoStep extends FlowStep<NewFlowState, MyInfoStepValues> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'guarantorMyInfo';
    }

    // eslint-disable-next-line class-methods-use-this
    get label(): string {
        return 'My Info';
    }

    public render(): React.ReactElement | null {
        const { bank, application, token } = this.state;

        if (!application) {
            throw new Error('application is missing in state');
        }

        if (!application.guarantor) {
            throw new Error('guarantor is missing in state');
        }

        if (!token) {
            throw new Error('token is missing in state');
        }

        return (
            <MyInfo
                applicationId={application.id}
                backStep={this.getBackContext()}
                bank={bank}
                customer={application.guarantor}
                onSubmit={this.submit}
                pageComponent={GuarantorMyInfoPage}
                source={ApplicationFlowSource.GUARANTOR}
                step="guarantorMyInfo"
                token={token}
            />
        );
    }

    protected async execute({ customer, hasError }: MyInfoStepValues): Promise<FlowStep<NewFlowState> | null> {
        const { application } = this.state;

        if (!application) {
            throw new Error('application is missing in state');
        }

        this.stateStore.update({
            application: { ...application, guarantor: customer },
            withMyInfoError: hasError,
        });

        return this.nextStep;
    }
}

export default GuarantorMyInfoStep;
