// @ts-ignore
import { DarkButton } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCountry } from '../../hookSelectors';
import { Channel } from '../../schema';
import { getGlobalPermissions, getIsAuthenticated } from '../../selectors';
import { getCarModelsUrl, getUsedCarModelsUrl, getNewApplicationSimpleCalculatorUrl } from '../../utilities/urls';
import Header from '../shared/Header';
import { Container, Buttons, Title, Content } from '../ui/GenericPage';

type Error404Props = {
    header?: boolean;
};

const Error404Suggestion = ({ header = true }: Error404Props) => {
    const country = useCountry();
    const { t } = useTranslation();
    const { allowPublicAccess, allowPrivateAccess, isActive } = country.channelSetting.new;
    const {
        allowPublicAccess: allowUsedCarPublicAccess,
        allowPrivateAccess: allowUsedCarPrivateAccess,
        isActive: isUsedCarActive,
    } = country.channelSetting.used;
    const { isActive: isExpressActive } = country.channelSetting.express;

    const { mayViewNewCarChannel, mayViewUsedCarChannel, mayViewExpressChannel } = useSelector(getGlobalPermissions);

    const isAuthenticated = useSelector(getIsAuthenticated);
    const history = useHistory();

    const handleClick = useCallback(
        (channel: Channel) => {
            switch (channel) {
                case Channel.NEW:
                    // @ts-ignore
                    history.pushWithCompanyAndState(getCarModelsUrl);
                    break;
                case Channel.USED:
                    // @ts-ignore
                    history.pushWithCompanyAndState(getUsedCarModelsUrl);
                    break;
                case Channel.EXPRESS:
                    // @ts-ignore
                    history.pushWithCompanyAndState(getNewApplicationSimpleCalculatorUrl);
                    break;
            }
        },
        [history]
    );

    const mayViewNewCar = isAuthenticated ? allowPrivateAccess && mayViewNewCarChannel : allowPublicAccess;
    const mayViewUsedCar = isAuthenticated
        ? allowUsedCarPrivateAccess && mayViewUsedCarChannel
        : allowUsedCarPublicAccess;

    const buttons = [
        mayViewNewCar && isActive && (
            <DarkButton onClick={() => handleClick(Channel.NEW)}>{t('error404Page.button.newCars')}</DarkButton>
        ),
        mayViewUsedCar && isUsedCarActive && (
            <DarkButton onClick={() => handleClick(Channel.USED)}>{t('error404Page.button.usedCars')}</DarkButton>
        ),
        mayViewExpressChannel && isExpressActive && (
            <DarkButton onClick={() => handleClick(Channel.EXPRESS)}>{t('error404Page.button.preOwned')}</DarkButton>
        ),
    ].filter(Boolean);

    return (
        <>
            {
                // to handle header duplication for event route
                header ? <Header /> : null
            }
            <Container>
                <Title>{t('error404Page.pageNotFound')}</Title>
                {buttons.length ? <Content>{t('error404Page.recommendMessage')}</Content> : null}
                <Buttons>{buttons}</Buttons>
            </Container>
        </>
    );
};

export default Error404Suggestion;
