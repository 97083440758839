import styled, { css } from 'styled-components';

export type ChatAvatarContainerProps = { isAlt?: boolean };

const ChatAvatarContainer = styled.div<ChatAvatarContainerProps>`
    border-radius: 50%;
    display: inline-block;
    background-color: #000;
    color: #fff;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    font-size: 18px;

    ${props =>
        props.isAlt
            ? css`
                  right: 15px;
                  float: right;
              `
            : css`
                  right: auto;
                  float: left;
              `}
`;

export default ChatAvatarContainer;
