import React from 'react';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import { Container } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import { BackStepContext } from '../../../utils/flow';
import ApprovalForm, { schema } from './ApprovalForm';

export type ApprovalPageProps = {
    onSubmit: (values: any) => Promise<void>;
    token: string;
    backStep?: BackStepContext;
    isLastStep: boolean;
};

const ApprovalPage = ({ onSubmit, token, backStep, isLastStep }: ApprovalPageProps) => {
    const validate = useValidation(schema);

    return (
        <Container>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <ApprovalForm isLastStep={isLastStep} onSubmit={onSubmit} token={token} validate={validate} />
        </Container>
    );
};

export default ApprovalPage;
