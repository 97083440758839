import { pick, omit } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountry, useZone } from '../../../hookSelectors';
import { Channel } from '../../../schema';
import useCompanyFormatting, { Formats } from '../../utilities/useCompanyFormatting';
import { CalculatorMeta } from './types';

export const useChannelSetting = (channel: Channel) => {
    const { channelSetting } = useCountry();

    return useMemo(() => {
        switch (channel) {
            case Channel.NEW:
                return channelSetting.new;

            case Channel.USED:
                return channelSetting.used;

            case Channel.EXPRESS:
                return channelSetting.express;

            case Channel.EVENT:
                return channelSetting.event;

            default:
                throw new Error('Channel not implemented');
        }
    }, [channel, channelSetting]);
};

export const useCoe = (channel: Channel, isEditable: AdditionalMeta['isCoeEditable']) => {
    const { coe, channelSetting } = useCountry();

    return useMemo(() => {
        let editable: boolean | undefined = false;

        switch (channel) {
            case Channel.NEW:
                editable = channelSetting.new.isCoeEditable;
                break;

            case Channel.EXPRESS:
                editable = channelSetting.express.isCoeEditable;
                break;

            case Channel.EVENT:
                editable = isEditable;
                break;

            default:
                break;
        }

        return {
            amount: coe?.amount,
            editable,
        };
    }, [channel, channelSetting.express.isCoeEditable, channelSetting.new.isCoeEditable, coe, isEditable]);
};

type AdditionalMeta = Omit<
    CalculatorMeta,
    | 't'
    | 'countryId'
    | 'zoneId'
    | 'coe'
    | 'ppsr'
    | 'establishment'
    | 'allowOptions'
    | 'variants'
    | 'financeProducts'
    | keyof Formats
> & {
    channel: Channel;
};

/* helper hook to merge common meta with calculator specific metas */
const useCalculatorMeta = (meta: AdditionalMeta): Partial<CalculatorMeta> => {
    const { channel } = meta;
    // setup translation
    const { t } = useTranslation();
    // setup formats
    const formats = useCompanyFormatting();
    // setup country settings
    // channel settings to be merged with events later
    const { ppsr, establishment, luxuryTax } = useCountry();
    const channelSettings = useChannelSetting(channel);
    const coe = useCoe(channel, meta.isCoeEditable);

    // setup zone settings
    const { id: zoneId } = useZone();

    return useMemo(() => {
        return {
            t,
            zoneId,
            coe,
            ppsr,
            establishment,
            luxuryTax,
            ...pick(
                [
                    'allowOptions',
                    'isPromoCodeEnabled',
                    'isCoeEnabled',
                    'isPpsrAndEstablishmentEnabled',
                    'allowReverseCalculator',
                    'alwaysShowPromoCode',
                    'isDealerOptionsEnabled',
                    'isLuxuryTaxEnabled',
                ],
                channelSettings
            ),
            ...formats,
            // additional meta
            ...omit(['channel'], meta),
        };
    }, [channelSettings, coe, establishment, formats, luxuryTax, meta, ppsr, t, zoneId]);
};

export default useCalculatorMeta;
