import React, { useMemo, useRef } from 'react';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { CsvConfiguratorDataFragment } from '../../../../components/routes/CsvRoute/data.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { useZone } from '../../../../hookSelectors';
import { AssetCondition, Channel } from '../../../../schema';
import { EventCalculatorStepValues } from '../../../EventFlow/steps/EventCalculatorStep';
import Page from './Page';

export type CalculatorProps = {
    onSubmit: (values: EventCalculatorStepValues) => Promise<void>;
    initialValues?: Partial<CalculatorValues>;
    dealerId?: string;
    snapshot?: any;
    event: EventDataFragment;
    hasTradeIn?: boolean;
    hasTestDrive?: boolean;
    isCalculatorEnabled?: boolean;
    appliedForFinancing?: boolean;
    csvConfigurator: CsvConfiguratorDataFragment;
    variant: VariantDataFragment;
};

const CsvCalculator = ({
    onSubmit,
    snapshot,
    initialValues: initialValuesFromProps,
    dealerId,
    event,
    hasTestDrive,
    hasTradeIn,
    isCalculatorEnabled,
    appliedForFinancing,
    csvConfigurator,
    variant: variantFromProps,
}: CalculatorProps) => {
    const { id: zoneId } = useZone();

    const { totalPrice, variant: variantConfigurator } = csvConfigurator;

    const variant = useRef<VariantDataFragment>({
        id: 'EVENT',
        identifier: 'EVENT',
        name: { en: variantConfigurator?.name ?? '' },
        options: [],
        model: {
            id: 'EVENT',
            identifier: 'EVENT',
            order: 0,
            make: {
                id: 'EVENT',
                identifier: 'EVENT',
                name: '',
                order: 0,
            },
            makeId: 'EVENT',
            name: '',
            version: { id: 'EVENT' },
        },
        modelId: 'EVENT',
        prices: [
            {
                zoneId,
                value: totalPrice ?? 0,
            },
        ],
        assetCondition: AssetCondition.USED,
        channels: { new: [], used: [], express: true },
        version: { id: variantFromProps.version.id, isOutdated: false },
        year: 0,
        order: 0,
        technicalDetails: {},
        usedCarDetails: {},
        images: [
            {
                id: variantConfigurator.identifier ?? '',
                url: variantConfigurator.imageUrl,
                metaData: { filename: '' },
            },
        ],
    });

    // get initial calculator values
    const initialCalculatorValues = useMemo(
        (): Partial<CalculatorValues> => ({ ...initialValuesFromProps, variant: variant.current.id }),
        [initialValuesFromProps]
    );

    const initialValues = useMemo(
        () => ({ hasTradeIn, hasTestDrive, isCalculatorEnabled, dealerId, appliedForFinancing }),
        [hasTestDrive, hasTradeIn, isCalculatorEnabled, appliedForFinancing, dealerId]
    );

    return (
        <>
            <HelmetTitle channel={Channel.EVENT} title="CsvCalculator" />
            <Page
                event={event}
                initialCalculatorValues={initialCalculatorValues}
                initialValues={initialValues}
                onSubmit={onSubmit}
                snapshot={snapshot}
                variant={variant.current}
            />
        </>
    );
};

export default CsvCalculator;
