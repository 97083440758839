// @ts-ignore
import { Modal as BaseModal } from '@appvantageasia/afc-ui';
import styled from 'styled-components';

export const SelectButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;

    .select {
        width: 100%;
        margin: 0 5px;
    }
`;

export const Modal = styled(BaseModal)`
    & > div {
        overflow: visible;
    }
`;
