import { displayFields, Values } from '@appvantageasia/afc-calculator-ui-next';
import React, { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

export type RemotePromoCodeFieldProps<CalculatorValues extends object = any> = displayFields.PromoCodeFieldProps<
    CalculatorValues
> & {
    containerId: string;
};

const Container = styled.div`
    display: grid;
    margin-bottom: 10px;
`;

const RemotePromoCodeField = ({ containerId, ...props }: RemotePromoCodeFieldProps<Values>) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);

    // we need to retrieve the DOM node as soon as it is available
    useLayoutEffect(() => {
        // provide our node
        setContainer(document.getElementById(containerId));
    }, [containerId, setContainer]);

    const children = (
        <Container>
            <displayFields.PromoCodeField {...props} />
        </Container>
    );

    return container && createPortal(children, container);
};

export default RemotePromoCodeField;
