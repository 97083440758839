import { TFunction } from 'i18next';
import { filter, omit, isEmpty, getOr, get } from 'lodash/fp';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext, Normalizer } from 'redux-form';
import * as yup from 'yup';
import FileFieldArray from '../../../../../components/shared/form-v2/FileFieldArray';
import IdentityNumberField from '../../../../../components/shared/form-v2/IdentityNumberField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import usePhone from '../../../../../components/utilities/usePhone';
import { useCountry } from '../../../../../hookSelectors';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import useOptions from '../../../../../utilities/constants/useOptions';
import { onTelKeyPress, fromContextValidation, yupExt, validateNirc } from '../../../../../utilities/forms';
import { Title } from '../ui';
import validateFile from './validateFile';

const watchedFields = ['name', 'email', 'title', 'phone', 'emailBis', 'identityNumber', 'phoneBis'];

type CustomerInformationFormProps = {
    isMyInfo?: boolean;
    disabled?: boolean;
    withFiles?: boolean;
    step: string;
    onNormalizeNames: {
        onNormalizeFullName: Normalizer;
    };
    hasTestDrive?: boolean;
    allowPrimaryEdition?: boolean;
};

const CustomerInformationForm = ({
    isMyInfo = false,
    disabled = false,
    withFiles = false,
    step,
    onNormalizeNames: { onNormalizeFullName },
    hasTestDrive = false,
    allowPrimaryEdition = false,
}: CustomerInformationFormProps) => {
    const { sectionPrefix, values }: any = useContext(ReduxFormContext);
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const { code: phoneCountryCode, minDigits: minPhoneDigit, maxDigits: maxPhoneDigit } = usePhone();
    const { t } = useTranslation();

    const withMyInfo = getOr(false, sectionPrefix ? `${sectionPrefix}.withMyInfo` : 'withMyInfo', values);

    const { title } = useOptions();
    const { code } = useCountry();

    return (
        <>
            <Title>
                {step === 'guarantor' ? t('kycPage.guarantorDetailsTitle') : t('kycPage.customerDetailsTitle')}
            </Title>
            <SelectField.Outline
                disabled={disabled || fieldState.title}
                label={t('eventCustomerDetails.label.title')}
                name="title.value"
                options={title}
            />
            <TextField
                disabled={disabled || fieldState.name}
                label={t('customerDetails.label.name')}
                maxLength="200"
                name="name.value"
                normalize={onNormalizeFullName}
            />
            <TextField
                disabled={disabled || fieldState.email || allowPrimaryEdition}
                label={withMyInfo ? t('customerDetails.label.emailWithMyInfo') : t('customerDetails.label.email')}
                name="email.value"
                type="email"
            />
            {withMyInfo && (
                <TextField
                    disabled={disabled || fieldState.emailBis || allowPrimaryEdition}
                    label={t('customerDetails.label.emailBi')}
                    name="emailBis.value"
                    type="email"
                />
            )}
            <TextField
                label={withMyInfo ? t('customerDetails.label.mobileWithMyInfo') : t('customerDetails.label.mobile')}
                name="phone.value"
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                type="tel"
            />
            {withMyInfo && (
                <TextField
                    disabled={disabled || fieldState.phoneBis || allowPrimaryEdition}
                    label={t('customerDetails.label.mobileBi')}
                    maxLength={maxPhoneDigit}
                    minLength={minPhoneDigit}
                    name="phoneBis.value"
                    onKeyPress={onTelKeyPress}
                    prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                    type="tel"
                />
            )}
            {code !== 'NZ' && (
                <IdentityNumberField
                    disabled={disabled || fieldState.identityNumber || allowPrimaryEdition}
                    label={t('customerDetails.label.identityNumber')}
                    maxLength="200"
                    name="identityNumber.value"
                    placeholder={t('customerDetails.placeholder.identityNumber')}
                />
            )}
            {!withMyInfo && withFiles && (
                <FileFieldArray
                    disabled={disabled}
                    forPurpose={
                        step === 'guarantor' ? UploadPurpose.GUARANTOR_IDENTITY : UploadPurpose.CUSTOMER_IDENTITY
                    }
                    label={t('customerDetails.label.customerFiles')}
                    name="customerFiles"
                    rerenderOnEveryChange
                />
            )}
        </>
    );
};

export default CustomerInformationForm;

export const rootSchema = (t: TFunction) => ({
    email: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .email(t('common.error.email'))
            .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
    }),
    emailBis: yup
        .object()
        .nullable()
        .when('withMyInfo', {
            is: withMyInfo => withMyInfo,
            then: yupExt.customerProperty().shape({
                value: yup
                    .string()
                    .email(t('common.error.email'))
                    .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
            }),
        }),
    // @ts-ignore
    identityNumber: yup.lazy((values: any, options: any) => {
        const { context, parent } = options;

        if (context.countryCode === 'NZ') {
            return yupExt.customerProperty().shape({
                value: yup.string().nullable().notRequired(),
            });
        }

        const identityValidator = validateNirc(parent?.details?.nationality?.value || context.countryCode);

        return yupExt.customerProperty().shape({
            value: yup
                .string()
                .required(t('common.error.required'))
                .test(
                    'invalid-identity',
                    t('common.error.identityNumber'),
                    (value: any) => !value || identityValidator(value, parent?.dateOfBirth?.value)
                ),
        });
    }),
    name: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
    }),
    phone: yupExt.customerProperty().shape({
        value: fromContextValidation(
            yup.string().required(t('common.error.required')),
            'phonePattern',
            t('common.error.mobile')
        ),
    }),
    phoneBis: yup
        .object()
        .nullable()
        .when('withMyInfo', {
            is: withMyInfo => withMyInfo,
            then: yupExt.customerProperty().shape({
                value: fromContextValidation(yup.string(), 'phonePattern', t('common.error.mobile')),
            }),
        }),
    withMyInfo: yup.boolean().required(t('common.error.required')),
    title: yupExt.customerProperty().shape({ value: yup.string().notRequired() }).notRequired(),
});
export const customerFilesSchema = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((value: any, { parent, context }: any) => {
        const { withMyInfo } = parent;
        const isTTBBank = get('application.financeProduct.bank.presetOption', context) === BankPresetOption.TTBBANK;

        const validator = yup
            .array()
            .transform((currentValue: any) =>
                filter(item => {
                    if (!item) {
                        return false;
                    }

                    if (
                        item.purpose !== UploadPurpose.CUSTOMER_IDENTITY &&
                        item.purpose !== UploadPurpose.GUARANTOR_IDENTITY
                    ) {
                        // wrong purpose, skip it
                        return false;
                    }

                    // ensure it's not an empty item
                    return item instanceof File || !isEmpty(omit(['purpose'], item));
                }, currentValue)
            )
            .of(
                yup.mixed().test(
                    'extension-validation',
                    t('common.error.fileExtension'),
                    // @ts-ignore
                    validateFile
                )
            );

        if (withMyInfo || isTTBBank) {
            // it's not mandatory
            return validator;
        }

        return validator.min(1, t('common.error.filesRequired'));
    });
