// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { EventExternalSite } from '../../../../../schema';
import { assetConditionOptions, yesNoOptions } from '../../../../../utilities/constants/options';
import { ApplicationData } from '../../../../routes/ApplicationRoute';
import NumberField from '../../NumberField';
import SelectField from '../../SelectField';
import TextField from '../../TextField';
import MiniConfiguratorDetails from './MiniConfiguratorDetails';

export type EventProps = {
    application: ApplicationData;
};

const Event = ({ application }: EventProps) => {
    const { t } = useTranslation();
    const { booking, variant, miniConfiguratorDetails, finderVehicle } = application;

    const isFinderVehicleDetailsExists = useMemo(() => !!finderVehicle?.listing?.id, [finderVehicle]);
    const { formatPath } = useContentTranslation();

    const vin = booking?.unit?.details?.vin;

    const subModelExists = variant.model.parentId;

    // if submodel exist replace the name
    const modelName = formatPath(subModelExists ? 'variant.model.parent.name' : 'variant.model.name');

    // only show model when variant is created adhoc
    const isExpress = variant.channels.express;

    if (application.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT) {
        // do not show for marketing reconsent
        return null;
    }

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.vehicleOfInterest')} closable open>
            <div className="container-fluid">
                <div className="row">
                    {!isFinderVehicleDetailsExists && (
                        <>
                            {!isExpress && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField
                                        label={t('applicationDetailsPage.label.make')}
                                        name={formatPath('variant.model.make.name')}
                                        disabled
                                    />
                                </div>
                            )}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label={t('applicationDetailsPage.label.model')} name={modelName} disabled />
                            </div>
                            {!isExpress && (
                                <>
                                    {subModelExists && (
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <TextField
                                                label={t('applicationDetailsPage.label.subModal')}
                                                name={formatPath('variant.model.name')}
                                                disabled
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={t('applicationDetailsPage.label.variant')}
                                            name={formatPath('variant.name')}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}
                            {vin && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField
                                        label={t('applicationDetailsPage.label.vinNumber')}
                                        name="variant.vin"
                                        disabled
                                    />
                                </div>
                            )}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label={t('applicationDetailsPage.label.assetCondition')}
                                    name="assetCondition"
                                    options={assetConditionOptions}
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={t('applicationDetailsPage.label.dealer')}
                                    name={formatPath('dealer')}
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.startingPrice')}
                                    name="calculator.carPrice"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            {miniConfiguratorDetails && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <MiniConfiguratorDetails miniConfiguratorDetails={miniConfiguratorDetails} />{' '}
                                </div>
                            )}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label={t('applicationDetailsPage.label.testDrive')}
                                    name="hasTestDrive"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </div>
                        </>
                    )}
                    {isFinderVehicleDetailsExists && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Inventory ID" name="finderVehicle.listing.id" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label="Vehicle ID"
                                    name="finderVehicle.listing.vehicle.orderTypeCode"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="VIN" name="finderVehicle.listing.vehicle.vin" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Vehicle Name" name="finderVehicle.name" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Condition" name="finderVehicleCondition" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label="Color"
                                    name="finderVehicle.listing.vehicle.exteriorColor.name.localize"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label="Price"
                                    name="finderVehicle.listing.price.value"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label="Test Drive"
                                    name="hasTestDrive"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default Event;
