import { useQuery } from '@apollo/client';
// @ts-ignore
import { DarkButton, GrayButton, ActionsCenter, Actions, Modal } from '@appvantageasia/afc-ui';
import { isNil } from 'lodash/fp';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getIsAuthenticated } from '../../selectors';
import { getApplicationUrl } from '../../utilities/urls';
import {
    getApplication,
    GetApplicationQuery,
    GetApplicationQueryVariables,
} from '../routes/ApplicationRoute/data.graphql';
import { SubmitSuccess } from '../ui/calculator';

type LocationState = {
    signNotCompleted?: any;
    paymentNotCompleted?: any;
    applicationVersionId?: string;
};

const PendingModal = () => {
    const { t } = useTranslation();
    // sign not completed or payment not complete modal management
    const { signNotCompleted = null, paymentNotCompleted = null, applicationVersionId } =
        useLocation<LocationState>().state ?? {};

    const [[showPendingModal, pendingInfo], setShowPendingModal] = useState([
        !!signNotCompleted || !!paymentNotCompleted,
        signNotCompleted || paymentNotCompleted,
    ]);

    const onClose = useCallback(() => setShowPendingModal(() => [false, null]), [setShowPendingModal]);

    const history = useHistory();
    const isAuthenticated = useSelector(getIsAuthenticated);

    const { data } = useQuery<GetApplicationQuery, GetApplicationQueryVariables>(getApplication, {
        fetchPolicy: 'cache-first',
        skip: isNil(applicationVersionId) || !showPendingModal,
        variables: { id: applicationVersionId as string },
    });

    const { application } = data || {};

    const goTo = useCallback(() => {
        // @ts-ignore
        history.pushWithCompanyAndState(getApplicationUrl, null, application);
    }, [application, history]);

    if (!showPendingModal || !isAuthenticated || !application) {
        return null;
    }

    return (
        <Modal onClose={onClose} title={pendingInfo.title} showClose showTitle>
            <SubmitSuccess>
                <p>{pendingInfo.message}</p>
            </SubmitSuccess>
            <Actions>
                {isAuthenticated && (
                    <>
                        <GrayButton onClick={goTo}>{t('pendingModal.button.viewApplication')}</GrayButton>
                        <DarkButton onClick={onClose}>{t('pendingModal.button.done')}</DarkButton>
                    </>
                )}
                {!isAuthenticated && (
                    <ActionsCenter>
                        <DarkButton onClick={onClose}>{t('pendingModal.button.done')}</DarkButton>
                    </ActionsCenter>
                )}
            </Actions>
        </Modal>
    );
};

export default PendingModal;
