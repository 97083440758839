import styled from 'styled-components';

const AvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;

    & > *:first-child {
        margin-bottom: 10px;
    }
`;

export default AvatarContainer;
