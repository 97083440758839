import React from 'react';
import { FinanceDataFragment } from '../../../components/data/useFinanceProducts.graphql';
import { BankDataFragment } from '../../../components/data/useLoadBank.graphql';
import { PromoDataFragment } from '../../../components/data/useLoadPromo.graphql';
import { VariantDataFragment } from '../../../components/data/useLoadVariants.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../components/shared/calculator-next/types';
import { FlowStep } from '../../utils/flow';
import getDealerIdFromFlowState from '../../utils/getDealerIdFromFlowState';
import Calculator from '../components/Calculator';
import { NewFlowState } from '../types';

export type CalculatorStepValues = {
    calculator: Partial<CalculatorValues>;
    insuranceCalculator?: Partial<CalculatorInsuranceValues>;
    variant: VariantDataFragment;
    financeProduct?: FinanceDataFragment;
    bank?: BankDataFragment;
    promo?: PromoDataFragment | null;
    isInsuranceEnabled?: boolean;
    isFinanceEnabled?: boolean;
};

class CalculatorStep extends FlowStep<NewFlowState, CalculatorStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'calculator';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('newCalculatorPage.label.step');
    }

    public render(): React.ReactElement | null {
        const { calculator, snapshot, channel, insuranceCalculator } = this.state;
        const dealerId = getDealerIdFromFlowState(this.state);

        return (
            <>
                <HelmetTitle channel={channel} title="Calculator" />
                <Calculator
                    dealerId={dealerId}
                    initialInsuranceValues={insuranceCalculator}
                    initialValues={calculator}
                    onSubmit={this.submit}
                    snapshot={snapshot}
                />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute({
        calculator,
        insuranceCalculator,
        bank,
        financeProduct,
        promo,
        variant,
        isInsuranceEnabled,
        isFinanceEnabled,
    }: CalculatorStepValues) {
        // persist the bank and calculator, as well as the finance product
        this.stateStore.update({
            calculator,
            insuranceCalculator,
            bank,
            financeProduct,
            snapshot: undefined,
            promo,
            disableFinanceApplication: !financeProduct?.variants.some(
                assignedVariant => assignedVariant === variant.version.id
            ),
            withInsurance: isInsuranceEnabled,
            withFinancing: isFinanceEnabled,
        });

        return this.nextStep;
    }
}

export default CalculatorStep;
