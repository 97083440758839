import { TFunction } from 'i18next';
import { toNumber, get } from 'lodash/fp';
import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { yupExt } from '../../../../utilities/forms';
import { ApplicationFormValues } from '../../../routes/ApplicationRoute/ApplicationForm';
import { ApplicationCustomerDataFragment } from '../../../routes/ApplicationRoute/data.graphql';
import SubTitle from '../../../ui/SubTitle';
import CPFContributions from '../../partialForms/CPFContributions';
import NumberField from '../NumberField';
import TextField from '../TextField';
import { getLabelWithFlag } from './CustomerDetails';

const OccupationDetails = () => {
    const { t } = useTranslation();
    const { sectionPrefix } = useContext(ReduxFormContext) as { sectionPrefix: string };

    // get form values
    const { form } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);
    const formValues = useSelector(valueSelector) as ApplicationCustomerDataFragment & ApplicationFormValues;

    // get the employment section
    const cpfContributions = get(
        [sectionPrefix, 'details', 'employment', 'cpfContributions'].filter(Boolean),
        formValues
    );

    const employment = get(sectionPrefix ? `${sectionPrefix}.details.employment` : 'details.employment', formValues);

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.occupationDetails')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.employer'), employment?.employer?.source)}
                        name="details.employment.employer.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag(t('customerDetails.label.occupation'), employment?.occupation?.source)}
                        name="details.employment.occupation.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={getLabelWithFlag(
                            t('customerDetails.label.assessableIncome'),
                            employment?.assessableIncome?.source
                        )}
                        name="details.employment.assessableIncome.value"
                        type="currency"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={getLabelWithFlag(
                            t('customerDetails.label.assessmentYear'),
                            employment?.assessmentYear?.source
                        )}
                        maxLength="4"
                        name="details.employment.assessmentYear.value"
                        normalize={toNumber}
                        type="year"
                        disabled
                    />
                </div>
            </div>
            <CPFContributions history={cpfContributions} />
        </>
    );
};

export const schema = (t: TFunction) => ({
    // @ts-ignore
    employment: yup.lazy((values, options) => {
        const { context } = options;

        // validate only when it's not an event
        // because porsche finder finance won't have this value for it's applicant
        if (context?.application?.event?.id) {
            return yup.mixed().notRequired();
        }

        return yupExt.customerProperty().shape({
            assessableIncome: yupExt
                .customerProperty()
                .shape({ value: yup.string().required(t('common.error.required')) }),
            assessmentYear: yupExt.customerProperty().shape({
                value: yup
                    .string()
                    .required(t('common.error.required'))
                    .matches(/^[0-9]+$/, t('common.error.digitsOnly'))
                    .min(4, t('common.error.exact', { count: 4 }))
                    .max(4, t('common.error.exact', { count: 4 })),
            }),
            employer: yupExt.customerProperty().shape({
                value: yup
                    .string()
                    .required(t('common.error.required'))
                    .max(124, t('common.error.lessThanOrEqual', { max: 124 })),
            }),
            occupation: yupExt.customerProperty().shape({
                value: yup
                    .string()
                    .required(t('common.error.required'))
                    .max(100, t('common.error.lessThanOrEqual', { max: 100 })),
            }),
        });
    }),
});

export default OccupationDetails;
