export enum FilterDateRange {
    NONE = 'none',
    TODAY = 'today',
    WEEK = 'week',
    MONTH = 'month',
}

export type AppointmentState = {
    startAppointmentDate?: Date;
    endAppointmentDate?: Date;
    dateRangeId?: FilterDateRange;
};

type DateOrUndefined = Date | undefined;

export type SetDateRangeAction = {
    type: 'SET_DATE_RANGE';
    payload: [DateOrUndefined, DateOrUndefined, FilterDateRange];
};

export type AppointmentAction = SetDateRangeAction;

export const appointmentReducer = (state: AppointmentState, action: AppointmentAction) => {
    switch (action.type) {
        case 'SET_DATE_RANGE':
            return {
                ...state,
                startAppointmentDate: action.payload[0],
                endAppointmentDate: action.payload[1],
                dateRangeId: action.payload[2],
            };

        default:
            return state;
    }
};
