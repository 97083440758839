// @ts-ignore
import React, { useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { ApplicationPhase } from '../../../schema';
import { getApplicationsUrl } from '../../../utilities/urls';
import Title from '../../shared/form-v2/Title';
import FixedMenuFormTabContainer from '../../ui/form/FixedMenuFormTab';
import { useListContextFromState } from '../../utilities/useListContext';
import Navigation from '../NewApplicationRoutes/shared/Navigation';
import ApplicationDetails from './ApplicationDetails';
import { ApplicationData } from './index';

export type DetailsProps = {
    application: ApplicationData;
};

export const ApplicationDetailContainer = styled.div`
    .appContainer {
        @media (max-width: 450px) {
            padding: 0 20px;

            .appActionBtn {
                padding: 0 10px;
            }
        }
    }
`;

const Details = ({ application }: DetailsProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { previous } = useLocation<{ previous: string }>().state || {};

    const prevText = useMemo(() => {
        if (previous) {
            return previous;
        }

        return t('applicationDetailsPage.button.reservationPrevious');
    }, [previous, t]);

    const [listContext] = useListContextFromState();
    const onPrev = useCallback(() => {
        // if there is previous source
        // navigate back
        if (previous) {
            history.goBack();
        } else {
            // else go back to listing
            // @ts-ignore
            history.pushWithCompanyAndState(getApplicationsUrl, { listContext }, application);
        }
    }, [application, history, listContext, previous]);

    const title = useMemo(() => {
        if (application.phase === ApplicationPhase.RESERVATION) {
            return `${t('applicationDetailsPage.reservationTitle')} - ${application.identifier}`;
        }

        return t('applicationDetailsPage.title');
    }, [application, t]);

    return (
        <ApplicationDetailContainer>
            <FixedMenuFormTabContainer className="appContainer">
                <Navigation onPrev={onPrev} prevText={prevText} />
                <Title customSpace="20px">{title}</Title>
                <ApplicationDetails key="reservation" application={application} onCancel={onPrev} />
            </FixedMenuFormTabContainer>
        </ApplicationDetailContainer>
    );
};

export default memo(Details);
