import { addDays } from 'date-fns';
import { TFunction } from 'i18next';
import { get, getOr, toNumber } from 'lodash/fp';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadIncomeTypes } from '../../../../../actions';
import { BankPresetOption } from '../../../../../schema';
import { getIncomeTypes } from '../../../../../selectors';
import { yesNoOptions } from '../../../../../utilities/constants/options';
import { onNumKeyPress, yupExt } from '../../../../../utilities/forms';
import SubTitle from '../../../../ui/SubTitle';
import useBankPresetLabel from '../../../../utilities/useBankPresetLabel';
import DateField from '../../DateField';
import NumberField from '../../NumberField';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

export type IdentityDetailsProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const OccupationDetails = ({ allowPrimaryInfoChanges = false, disabled = false }: IdentityDetailsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    useEffect(() => {
        dispatch(loadIncomeTypes());
    }, [dispatch]);

    const incomeTypes = useSelector(getIncomeTypes);
    const formValues = getValues();
    const bankPresetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);
    const salaryTransferredToBank = getOr(
        null,
        `${sectionPrefix}.details.employment.salaryTransferredToBank.value`,
        formValues
    );

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);

    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.occupationDetails')} </SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.incomeType')}
                        name="details.employment.incomeType.value"
                        options={incomeTypes}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={t('customerDetails.label.monthlyIncome')}
                        name="details.employment.monthlyIncome.value"
                        type="currency"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.employer')}
                        name="details.employment.employer.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        label={t('customerDetails.label.dateOfJoining')}
                        name="details.employment.dateOfJoining.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.totalWorkExperience')}
                        name="details.employment.totalWorkExperience.value"
                        normalize={toNumber}
                        onKeyPress={onNumKeyPress}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.designation')}
                        name="details.employment.designation.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={t('customerDetails.label.salaryTransferredToBank')}
                        name="details.employment.salaryTransferredToBank.value"
                        options={yesNoOptions}
                        disabled
                    />
                </div>
                {isEnbdBankPreset && !salaryTransferredToBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Bank Name" name="details.thirdParty.enbdBank.bankName.value" disabled />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={t('customerDetails.label.bankAccountNumber')}
                        name="details.employment.bankAccountNumber.value"
                        onKeyPress={onNumKeyPress}
                        disabled
                    />
                </div>
                {isEnbdBankPreset && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={getBankPresetLabel('preferredFirstPaymentDate')}
                            maxDate={addDays(new Date(), 45)}
                            minDate={new Date()}
                            name="details.thirdParty.enbdBank.preferredFirstPaymentDate.value"
                            disabled
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export const occupationEnbdSchema = (t: TFunction) => ({
    preferredFirstPaymentDate: yupExt.customerProperty().shape({
        value: yup.date().required(t('common.error.required')),
    }),
    // @ts-ignore
    bankName: yup.lazy((value, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

        const salaryTransferredToBank = get(
            'context.values.customerInfo.details.employment.salaryTransferredToBank.value',
            options
        );

        if (!isEnbdBankPreset || (isEnbdBankPreset && !salaryTransferredToBank)) {
            return yupExt.customerProperty().shape({
                value: yup.string().nullable().required(t('common.error.required')),
            });
        }

        return yupExt.customerProperty().shape({
            value: yup.string().nullable(),
        });
    }),
});

export const schema = (t: TFunction) => ({
    employment: yupExt.customerProperty().shape({
        bankAccountNumber: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 }));
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
        dateOfJoining: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.string();
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
        designation: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 }));
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
        employer: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 }));
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
        monthlyIncome: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.string().max(200, t('common.error.lessThanOrEqual', { max: 200 }));
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
        salaryTransferredToBank: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.boolean();
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
        totalWorkExperience: yupExt.customerProperty().shape({
            // @ts-ignore
            value: yup.lazy((value, { context }) => {
                const isEnbdBankPreset =
                    get('application.financeProduct.bank.presetOption', context) === BankPresetOption.ENBDBANK;

                const base = yup.number().max(200, t('common.error.lessThanOrEqual', { max: 200 }));
                if (isEnbdBankPreset) {
                    return base.nullable();
                }

                return base.required(t('common.error.required'));
            }),
        }),
    }),
});

export default OccupationDetails;
