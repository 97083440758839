import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { attachLoading } from '../../../../actions';
import {
    updateCustomer,
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables,
} from '../../../../api/application.graphql';
import { getCompanyIdentifier, getGlobalPermissions } from '../../../../selectors';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { getApplicationsUrl } from '../../../../utilities/urls';
import applySourceChange from '../../../utilities/applySourceChange';
import { SubmitProvider } from '../ApplicationDetails';
import { ApplicationFormValues } from '../ApplicationForm';

const SaveChangesProvider: SubmitProvider = ({ children, application }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useApolloClient();
    const { getState } = useStore();

    const { mayManageCustomer } = useSelector(getGlobalPermissions);

    const onSubmit = useCallback(
        async (values: ApplicationFormValues) => {
            try {
                // update customer
                if (mayManageCustomer) {
                    const { customer } = values;
                    const initialCustomer = application.customer;
                    const modifiedCustomer = applySourceChange(customer, initialCustomer);
                    const { id: customerId, type, ...customerData } = modifiedCustomer;
                    const customerPromise = client.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
                        mutation: updateCustomer,
                        variables: { id: customerId, data: prepareForGraphQL(customerData) },
                    });

                    // attach loading
                    dispatch(attachLoading(customerPromise));
                }

                // get company and location code
                const { companyCode, locationCode } = getCompanyIdentifier(getState());

                // go back to application list
                const url = getApplicationsUrl(companyCode, locationCode, application);
                history.push(url, { updated: true, application });

                return null;
            } catch (error) {
                // process the error
                return handleResponseError(error as Error);
            }
        },
        [application, client, dispatch, getState, history, mayManageCustomer]
    );

    return children(onSubmit);
};

export default SaveChangesProvider;
