// eslint-disable-next-line import/prefer-default-export
export enum TransactionStatus {
    AUTHORISED = 'AUTHORISED',
    CAPTURED = 'CAPTURED',
    CAPTURE_REQUEST_SENT = 'CAPTURE_REQUEST_SENT',
    PREPARED = 'PREPARED',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    CANCELLED = 'CANCELLED',
}
