import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { BookingStatus, Channel } from '../../../../schema';
import { getNewApplicationCalculatorUrl } from '../../../../utilities/urls';
import useFirstFinanceProduct from '../../../data/useFirstFinanceProduct';
import useLoadInventoryUnits from '../../../data/useLoadInventoryUnits';
import useMustExistDealerIdFromTenantContext from '../../../data/useMustExistDealerIdFromTenantContext';
import Chatbot from '../../../shared/Chatbot';
import Footer from '../../../shared/Footer';
import Header from '../../../shared/Header';
import HelmetTitle from '../../../shared/HelmetTitle';
import PendingModal from '../../../shared/PendingModal';
import { CarList, CarModelFooter, CarModelHeader } from '../../../ui/car-models';
import useCompanyFormatting from '../../../utilities/useCompanyFormatting';
import Filter from '../CarListRoute/Filter';
import { useFormatDownPayment } from '../CarListRoute/Inner';
import SubmitSuccessModal from '../CarListRoute/SubmitSucessModal';
import useMakers from '../CarListRoute/useMakers';
import useModels from '../CarListRoute/useModels';
import useMonthlyPayment from '../CarListRoute/useMonthlyPayment';
import useSubModels from '../CarListRoute/useSubModels';
import useVariants from '../CarListRoute/useVariants';
import useComputed from './useComputed';

const Inner = () => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const {
        roundNumber,
        formatCurrency,
        formatCurrencyValues,
        formatPercentage,
        currencySymbol,
    } = useCompanyFormatting();

    const country = useCountry();
    const { allowFinanceApplication, isFinanceApplicationMandatory } = country.channelSetting.used;

    const dealerId = useMustExistDealerIdFromTenantContext();
    // get the default finance product
    const financeProduct = useFirstFinanceProduct(Channel.USED, dealerId);

    // get inventories
    const units = useLoadInventoryUnits(dealerId);

    // then get our variants (all of them)
    const { variants, loading } = useVariants(
        financeProduct,
        roundNumber,
        formatCurrency,
        currencySymbol,
        Channel.USED,
        dealerId,
        // for this list we truly need cache and network
        'cache-and-network'
    );

    // then compute units
    const { variants: availableVariants, units: availableUnits } = useComputed(
        variants,
        units,
        financeProduct,
        roundNumber,
        formatCurrency,
        currencySymbol,
        t
    );

    // list makers
    const makers = useMakers(availableVariants);
    // then models
    const models = useModels(makers.filteredVariants);
    // then sub models
    const subModels = useSubModels(models.filteredVariants, models.current);

    // and finally monthly payments
    const monthlyPayments = useMonthlyPayment(
        subModels.filteredVariants,
        formatCurrencyValues,
        roundNumber,
        currencySymbol
    );

    // now filter units
    const filteredUnits = useMemo(() => {
        // get variant ids
        const variantIds = monthlyPayments.filteredVariants.map((variant: any) => variant.id);

        return availableUnits.filter(unit => variantIds.includes(unit.id));
    }, [monthlyPayments.filteredVariants, availableUnits]);

    // history & callback to navigate on click
    const history = useHistory();
    const onClick = useCallback(
        variant => {
            // @ts-ignore
            history.pushWithCompany(getNewApplicationCalculatorUrl, variant.id, 'used', variant.unit.id);
        },
        [history]
    );

    // finally get the percentage down payment for the current financial product
    const formatedDownPayment = useFormatDownPayment(financeProduct, formatPercentage, currencySymbol);

    const hasMandatoryFinancing = allowFinanceApplication && isFinanceApplicationMandatory;

    const finalVariants = useMemo(
        () =>
            filteredUnits.map(car => {
                const isReserved = car.unit?.booking?.status === BookingStatus.RESERVED;

                // check if we have a finance product to proceed
                const hasNoFinanceProduct = hasMandatoryFinancing && !financeProduct;

                return {
                    ...car,
                    disabled: isReserved || hasNoFinanceProduct,
                };
            }),
        [filteredUnits, hasMandatoryFinancing, financeProduct]
    );

    return (
        <>
            <HelmetTitle channel={Channel.USED} title="Chooser" />
            <Header channel={Channel.USED} />
            <CarModelHeader>
                <Filter
                    channel={Channel.USED}
                    maker={makers.current}
                    makers={makers.list}
                    model={models.current}
                    models={models.list}
                    monthlyPayment={monthlyPayments.current}
                    monthlyPayments={monthlyPayments.list}
                    setMaker={makers.set}
                    setModel={models.set}
                    setMonthlyPayment={monthlyPayments.set}
                    setSubModel={subModels.set}
                    subModel={subModels.current}
                    subModels={subModels.list}
                />
            </CarModelHeader>
            {!loading && <CarList cars={finalVariants} onClick={onClick} withMake={makers.list.length > 1} />}
            {financeProduct && (
                <CarModelFooter>
                    {t('chooserPage.disclaimer', {
                        financeProduct: ct(financeProduct.name),
                        downPayment: formatedDownPayment,
                        term: financeProduct.termSetting.default,
                    })}
                </CarModelFooter>
            )}
            <SubmitSuccessModal />
            <PendingModal />
            <Footer />
            <Chatbot channel={Channel.USED} />
        </>
    );
};

export default Inner;
