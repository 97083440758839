import { isNil, isEmpty, isFinite } from 'lodash/fp';
import { useMemo } from 'react';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { Unit } from '../../../../schema';

const getInitialValue = (min: number, max: number, initial: number) => {
    // return original default value if still valid
    if (initial >= min && initial <= max) {
        return initial;
    }

    // otherwise return max value as default
    return max;
};

export const getDownPaymentSetting = (
    downPaymentSetting: FinanceDataFragment['downPaymentSetting'],
    refinements: FinanceDataFragment['downPaymentSetting'] | undefined,
    totalPrice?: number
) => {
    // there's no express setting passed
    if (isNil(refinements)) {
        return downPaymentSetting;
    }

    // express downpayment changes was min
    const currencySetting: Partial<FinanceDataFragment['downPaymentSetting']> = {};
    if (downPaymentSetting.defaultUnit === Unit.CURRENCY) {
        // in this case without total price we won't be able to return a valid downpayment setting
        // hence the original setting is returned
        if (!isFinite(totalPrice)) {
            return downPaymentSetting;
        }

        currencySetting.min = (refinements.min * (totalPrice ?? 0)) / 100;
    }

    const mergedSetting = {
        ...downPaymentSetting,
        ...refinements,
        ...currencySetting,
    };

    // express setting is not yet configured
    if (isNil(mergedSetting.min) || isNil(mergedSetting.max)) {
        return mergedSetting;
    }

    // calculate min percentage
    // min and max from express setting
    const { min: updatedMin, max } = mergedSetting;
    const min = updatedMin > downPaymentSetting.min ? updatedMin : downPaymentSetting.min;

    const initial = getInitialValue(min, max, downPaymentSetting.default);

    return {
        ...mergedSetting,
        default: initial,
        min,
        max,
    };
};

export const getLoanSetting = (
    loanSetting: FinanceDataFragment['loanSetting'],
    refinements: FinanceDataFragment['loanSetting'] | undefined,
    totalPrice?: number
) => {
    // there's no express setting passed
    if (isNil(refinements)) {
        return loanSetting;
    }

    // express loan changes was max
    const currencySetting: Partial<FinanceDataFragment['loanSetting']> = {};
    if (loanSetting.defaultUnit === Unit.CURRENCY) {
        // in this case without total price we won't be able to return a valid loan setting
        // hence the original setting is returned
        if (!isFinite(totalPrice)) {
            return loanSetting;
        }

        currencySetting.max = (refinements.max * (totalPrice ?? 0)) / 100;
    }

    const mergedSetting = {
        ...loanSetting,
        ...refinements,
        ...currencySetting,
    };

    // express setting is not yet configured
    if (isNil(mergedSetting.min) || isNil(mergedSetting.max)) {
        return mergedSetting;
    }

    // calculate max percentage
    // min and max from express setting
    const { min, max: updatedMax } = mergedSetting;
    const max = updatedMax < loanSetting.max ? updatedMax : loanSetting.max;

    const initial = getInitialValue(min, max, loanSetting.default);

    return {
        ...mergedSetting,
        default: initial,
        min,
        max,
    };
};

export const getTermSetting = (
    termSetting: FinanceDataFragment['termSetting'],
    refinements: FinanceDataFragment['termSetting'] | undefined
) => {
    // there's no refinements passed
    if (isNil(refinements)) {
        return termSetting;
    }

    const mergedSetting = {
        ...termSetting,
        ...refinements,
    };

    // express setting is not yet configured
    if (isNil(mergedSetting.min) || isNil(mergedSetting.max)) {
        return mergedSetting;
    }

    // calculate max range
    // min and max from express setting
    const { min, max: updatedMax, step } = mergedSetting;
    // ensure updated max is divisible by interval
    const calculatedMax = Math.floor(updatedMax / step) * step;
    const max = calculatedMax < termSetting.max ? calculatedMax : termSetting.max;

    const initial = getInitialValue(min, max, termSetting.default);

    return {
        ...mergedSetting,
        default: initial,
        min,
        max,
    };
};

// refine existing finance products
const useRefineFinanceProducts = (
    financeProducts: FinanceDataFragment[],
    refinements: Partial<FinanceDataFragment> | undefined,
    variantVersionId: string,
    totalPrice?: number
) =>
    useMemo(() => {
        // refinements not exist
        if (isNil(refinements)) {
            return financeProducts.map(financeProduct => ({
                ...financeProduct,
                variants: [variantVersionId],
            }));
        }

        // express finance product is not yet ready
        if (isEmpty(refinements)) {
            return [];
        }

        return financeProducts.map(financeProduct => {
            return {
                ...financeProduct,
                variants: [variantVersionId],
                downPaymentSetting: getDownPaymentSetting(
                    financeProduct.downPaymentSetting,
                    refinements?.downPaymentSetting,
                    totalPrice
                ),
                loanSetting: getLoanSetting(financeProduct.loanSetting, refinements?.loanSetting, totalPrice),
                termSetting: getTermSetting(financeProduct.termSetting, refinements?.termSetting),
            };
        });
    }, [refinements, financeProducts, variantVersionId, totalPrice]);

export default useRefineFinanceProducts;
