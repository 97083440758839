import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { getApplication, getProceedApplicationToken } from '../../actions';
import { restoreNewApplication, setNewApplication } from '../../actions/newApplicationv2';
import { getCompanyIdentifier } from '../../selectors';
import { mapApplicationToState } from '../../utilities/application';
import PaymentStatus from '../../utilities/constants/paymentStatus';
import { getCompanyRootUrl, parseCompanyPathname } from '../../utilities/urls';

// eslint-disable-next-line import/prefer-default-export
export const usePaymentCallback = ({ applicationId, referer, resultCode, entry }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch, getState } = useStore();

    // verify if it is from new application or application detial
    // if has new in referer, then it's newFlow
    // otherwise it's applicationDetailFlow
    const isNewFlow = referer?.toLowerCase().includes('/apply');
    const isRemoteFlow = referer?.toLowerCase().includes('/remote');
    const isResumeFlow = referer?.toLowerCase().includes('/resume');

    const { companyCode, locationCode } = getCompanyIdentifier(getState());

    return useMemo(() => {
        const getApplicationState = async () => {
            // fetch the application
            const application = await dispatch(getApplication(applicationId));

            return mapApplicationToState(application, t);
        };

        const getToken = async () => {
            // fetch the application
            const token = await dispatch(getProceedApplicationToken(entry));

            return token;
        };

        const onDefault = async () => {
            // fetch application state
            const applicationState = await getApplicationState();
            const token = await getToken();
            const refererPathName = parseCompanyPathname(referer, companyCode, locationCode);

            if (isNewFlow) {
                dispatch(restoreNewApplication(applicationState));
                dispatch(setNewApplication('meta', { token, paymentResult: resultCode, noBack: true }, true));

                history.push(refererPathName);
            } else if (isRemoteFlow) {
                history.push(refererPathName, { token, paymentResult: resultCode });
            } else if (isResumeFlow) {
                history.push(refererPathName, { token, paymentResult: resultCode, noBack: true });
            }
        };

        const onPending = async () => {
            // from payment page redirect
            // when it is pending status, then go to home page,
            const { message, title } = t('pendingModal.paymentPending', { returnObjects: true });
            history.pushWithCompanyAndState(getCompanyRootUrl, {
                paymentNotCompleted: {
                    title,
                    message,
                },
            });
            // we need it when dispaly PendingModal
            sessionStorage.setItem('applicationTempVersionId', applicationId);
        };

        // clear redirectToSigning anyway in here
        if (sessionStorage.getItem('redirectToPayment')) {
            sessionStorage.removeItem('redirectToPayment');
        }

        switch (resultCode) {
            case PaymentStatus.PENDDING:
                return onPending;

            default:
                return onDefault;
        }
    }, [
        applicationId,
        companyCode,
        dispatch,
        entry,
        history,
        isNewFlow,
        isRemoteFlow,
        isResumeFlow,
        locationCode,
        referer,
        resultCode,
        t,
    ]);
};
