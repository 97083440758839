import styled from 'styled-components';

const ProfileTitle = styled.div`
    height: 65px;
    font-size: 1.43rem;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0 30px;

    @media (max-width: 450px) {
        padding: 0 15px;
    }
`;

export default ProfileTitle;
