import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ConfiguratorFlow, { ConfiguratorFlowState } from '../../../flows/ConfiguratorFlow/ConfiguratorFlow';
import { MiniConfiguratorDetails } from '../../../flows/ConfiguratorFlow/components/Summary/Summary';
import { useManagedFlow, useScrollTop } from '../../../flows/utils/flow';
import useCompleteHandler from '../../../flows/utils/useCompleteHandler';
import { getRuntimeSettings } from '../../../selectors';
import { FinanceDataFragment } from '../../data/useFinanceProducts.graphql';
import { EventDataFragment } from '../EventRoute/EventRoute.graphql';

type ConfiguratorHandlerProps = {
    events: EventDataFragment[];
    valuesFromUrl?: MiniConfiguratorDetails;
    defaultFinanceProduct: FinanceDataFragment;
};

declare global {
    interface Window {
        dataLayer: IArguments[] | undefined;
        gtag: any;
    }
}

const ConfiguratorHandler = ({ valuesFromUrl, ...props }: ConfiguratorHandlerProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { facebookPixelId } = ckdConfiguration;

    // Facebook Pixel
    useEffect(() => {
        let script: HTMLScriptElement | undefined;

        // use setTimeout to execute it onto the next ticks to ensure document metadata are updated
        setTimeout(() => {
            if (facebookPixelId) {
                // create the FBQ object/function
                const fbq = function () {
                    // @ts-ignore
                    // eslint-disable-next-line prefer-spread, prefer-rest-params
                    fbq.callMethod ? fbq.callMethod.apply(fbq, arguments) : fbq.queue.push(arguments);
                };

                // update window
                // @ts-ignore
                window.fbq = fbq;

                // set properties same way Facebook does
                // @ts-ignore
                fbq.push = fbq;
                // @ts-ignore
                fbq.loaded = !0;
                // @ts-ignore
                fbq.version = '2.0';
                // @ts-ignore
                fbq.queue = [];

                // start events
                // @ts-ignore
                fbq('init', facebookPixelId);
                // @ts-ignore
                fbq('track', 'PageView');

                // @ts-ignore
                // eslint-disable-next-line no-underscore-dangle
                if (!window._fbq) {
                    // @ts-ignore
                    // eslint-disable-next-line no-underscore-dangle
                    window._fbq = fbq;
                }

                // prepare script
                script = document.createElement('script');
                script.src = 'https://connect.facebook.net/en_US/fbevents.js';
                script.async = true;

                // push it into body
                document.body.appendChild(script);
            }
        });

        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [facebookPixelId]);

    // create the initial state
    const initialState = useMemo(
        (): ConfiguratorFlowState => ({
            exteriorColor: ckdConfiguration.exterior.options[0].name,
            interiorColor: ckdConfiguration.interior.options[0].name,
            enhancedPackage: false,
            tequipment: undefined,
            timepiece: undefined,
            delivery: '',
            variantId: ckdConfiguration.vehicle.actualIdentifider,
            isResumeOrder: !!valuesFromUrl,
            ...valuesFromUrl,
            ...props,
        }),
        [
            ckdConfiguration.exterior.options,
            ckdConfiguration.interior.options,
            ckdConfiguration.vehicle.actualIdentifider,
            props,
            valuesFromUrl,
        ]
    );

    const { step } = useManagedFlow(ConfiguratorFlow, initialState, 'configuratorFlow');

    useScrollTop(step.identifier);

    useCompleteHandler('configuratorFlow');

    return step.render();
};

export default ConfiguratorHandler;
