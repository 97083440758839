import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import React, { memo, useCallback, useMemo, useState } from 'react';
import useFinanceProductRefinements from '../../../flows/DraftFlow/components/ExpressCalculator/useFinanceProductRefinements';
import useRefineCalculatorSnapshot from '../../../flows/DraftFlow/components/ExpressCalculator/useRefineCalculatorSnapshot';
import useRefineFinanceProducts from '../../../flows/DraftFlow/components/ExpressCalculator/useRefineFinanceProducts';
import { CalculatorSnapshot } from '../../../flows/utils/getSnapshotFromApplication';
import useDealerEstablishment from '../../../flows/utils/useDealerEstablishment';
import { Channel } from '../../../schema';
import { VariantDataFragment, VariantPreOwnedCarDataFragment } from '../../data/useLoadVariants.graphql';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import useCalculatorData from '../../utilities/useCalculatorData';
import PromoCodeCalculator, { PromoCodeCalculatorProps } from './PromoCodeCalculator';
import expressComputingFields from './expressComputingFields';
import { CalculatorValues } from './types';
import useCalculatorMeta from './useCalculatorMeta';

export type ExpressCalculatorProps = {
    variant: VariantDataFragment;
    preOwnedCarDetails: VariantPreOwnedCarDataFragment;
    snapshot: CalculatorSnapshot;
    meta?: PromoCodeCalculatorProps['meta'];
} & Omit<PromoCodeCalculatorProps, 'calculatorComponent' | 'meta'>;

const ExpressCalculator = ({
    dealerId,
    variant,
    initialValues: init,
    preOwnedCarDetails,
    snapshot: initialSnapshot,
    meta: initialMeta,
    onChange,
    ...props
}: ExpressCalculatorProps) => {
    const [totalPrice, setTotalPrice] = useState(0);
    const variantId = variant.id;
    const variantVersionId = variant.version.id;
    const financialProductRefinements = useFinanceProductRefinements(preOwnedCarDetails);
    const snapshot = useRefineCalculatorSnapshot(financialProductRefinements, initialSnapshot, totalPrice);
    const { financeProducts } = useCalculatorData(Channel.EXPRESS, dealerId);

    const expressFinanceProducts = useRefineFinanceProducts(
        financeProducts,
        financialProductRefinements,
        variantVersionId,
        totalPrice
    );

    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    const { selectedDealerEstablishment } = useDealerEstablishment(selectedDealerId as string, Channel.EXPRESS);

    const onChangeEnhanced = useCallback(
        (context: CalculatorContext<CalculatorValues>) => {
            if (context.values.totalPrice) {
                setTotalPrice(context.values.totalPrice);
            }

            if (onChange) {
                onChange(context);
            }
        },
        [onChange]
    );

    const initialValues = useMemo(() => ({ ...init, variant: variantId }), [variantId, init]);

    const additionalMeta = useMemo(
        () => ({
            ...initialMeta,
            channel: Channel.EXPRESS,
            variants: [variant],
            financeProducts: expressFinanceProducts,
            snapshot,
            selectedDealerEstablishment,
        }),
        [expressFinanceProducts, initialMeta, selectedDealerEstablishment, snapshot, variant]
    );
    const meta = useCalculatorMeta(additionalMeta);

    if (!expressFinanceProducts?.length) {
        // something is still missing to properly initialize a stateful component
        // such as the calculator
        return null;
    }

    return (
        <PromoCodeCalculator
            {...props}
            dealerId={dealerId}
            fields={expressComputingFields}
            initialValues={initialValues}
            meta={meta}
            onChange={onChangeEnhanced}
        >
            <displayFields.CarModelPriceField fieldKey="carModelAndPrice" isViewable={() => false} size={2} override />
            <displayFields.CarModelField
                fieldKey="variant"
                isViewable={() => false}
                labelTKey="calculator.label.carModel"
                size={2}
                override
            />
            <displayFields.CarPriceField
                fieldKey="carPrice"
                isViewable={() => true}
                labelTKey="expressCalculatorPage.calculator.carPrice"
                size={2}
                override
            />
        </PromoCodeCalculator>
    );
};

export default memo(ExpressCalculator);
