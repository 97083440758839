import { useMemo } from 'react';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import RemoteFlow, { RemoteFlowState } from '../../../../flows/RemoteFlow/RemoteFlow';
import { FlowType, useManagedFlow, useScrollTop } from '../../../../flows/utils/flow';
import useCompleteHandler from '../../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';

export type RemoteFlowHandlerProps = {
    application: ApplicationDataFragment;
    consents: ConsentDataFragment[];
    token: string;
    submitted: boolean;
};

const getFlowImplementation = (channel: Channel): FlowType<RemoteFlowState, RemoteFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
        case Channel.USED:
            return RemoteFlow;

        default:
            return null;
    }
};

const RemoteFlowHandler = ({ application, consents, token, submitted }: RemoteFlowHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();

    // create the initial state
    const initialState = useMemo(
        (): RemoteFlowState => ({
            channel: application.channel,
            company,
            token,
            zone,
            country,
            application,
            financeProduct: application.financeProduct,
            bank: application.financeProduct?.bank,
            promo: application.promoCode,
            source: ApplicationFlowSource.REMOTE,
            consents,
            submitted,
        }),
        [application, company, token, zone, country, consents, submitted]
    );

    const flowImplementation = getFlowImplementation(application.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'remoteFlow');

    useScrollTop(step.identifier);

    useCompleteHandler('remoteFlow');

    return step.render();
};

export default RemoteFlowHandler;
