import { Actions, GrayButton, DarkButton } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { exportApplications } from '../../actions';
import { useContentTranslation } from '../../i18n';
import { Channel } from '../../schema';
import LoadingLayer from '../common/LoadingLayer';
import useMustExistDealerIdFromTenantContext from '../data/useMustExistDealerIdFromTenantContext';
import { Modal, SelectButton } from '../ui/DownloadModal';
import { TextAlignCenter } from '../ui/TextAlign';
import BlockSelect from '../ui/form/BlockSelect';
import { useDownloadChannels } from '../utilities/useDownloadChannels';

export const DurationUnit = {
    m: 'm',
    d: 'd',
    w: 'w',
};

export const channelOptions = [
    { value: Channel.NEW, label: 'New' },
    { value: Channel.USED, label: 'Used' },
    { value: Channel.EXPRESS, label: 'Pre-owned' },
    { value: Channel.EVENT, label: 'Event' },
];

const DownloadModal = ({ onClose, type }) => {
    const { t } = useTranslation();

    const { language } = useContentTranslation();

    const dispatch = useDispatch();

    const durationOptions = useMemo(
        () => [
            { value: { amount: 0, unit: DurationUnit.m }, label: 'Current Month' },
            { value: { amount: 1, unit: DurationUnit.m }, label: 'Last Month' },
            { value: { amount: 3, unit: DurationUnit.m }, label: 'Last 3 Months' },
            { value: { amount: 6, unit: DurationUnit.m }, label: 'Last 6 Months' },
            { value: { amount: 12, unit: DurationUnit.m }, label: 'Last 12 Months' },
        ],
        []
    );

    const [duration, setDuration] = useState(durationOptions[0]);
    const [channel, setChannel, { channelValue, usedChannelOptions }] = useDownloadChannels(channelOptions);

    const dealerId = useMustExistDealerIdFromTenantContext();

    const [loadingStatus, setloadingStatus] = useState(false);
    const [passwordGenerated, setPasswordGenerated] = useState({
        isGenerated: false,
        password: '',
    });

    const onDurationChange = useCallback(value => setDuration(value), [setDuration]);
    const onChannelChange = useCallback(value => setChannel(value), [setChannel]);

    const download = useCallback(() => {
        setloadingStatus(true);
        dispatch(exportApplications(duration.value, channelValue, type, dealerId, language)).then(password => {
            setloadingStatus(false);
            if (password) {
                setPasswordGenerated({
                    isGenerated: true,
                    password,
                });
            } else {
                onClose();
            }
        });
    }, [dispatch, duration.value, channelValue, type, dealerId, language, onClose]);

    return (
        <Modal
            containerClassName="modal-container"
            onClose={onClose}
            title={t('downloadModal.title', { type })}
            showClose
            showTitle
        >
            {loadingStatus && <LoadingLayer />}

            {passwordGenerated.isGenerated ? (
                <TextAlignCenter>
                    {t('downloadModal.label.password')} <br /> {passwordGenerated.password}
                </TextAlignCenter>
            ) : (
                <>
                    <TextAlignCenter>{t('downloadModal.subHeading', { type: type.toLowerCase() })}</TextAlignCenter>
                    <SelectButton>
                        <BlockSelect
                            className="select"
                            defaultValue={channel}
                            onChange={onChannelChange}
                            options={usedChannelOptions}
                            value={channel}
                        />
                        <BlockSelect
                            className="select"
                            defaultValue={duration}
                            onChange={onDurationChange}
                            options={durationOptions}
                            value={duration}
                        />
                    </SelectButton>
                    <Actions>
                        <GrayButton onClick={onClose}>{t('downloadModal.button.cancel')}</GrayButton>
                        <DarkButton onClick={download}>{t('downloadModal.button.download')}</DarkButton>
                    </Actions>
                </>
            )}
        </Modal>
    );
};

DownloadModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default DownloadModal;
