import React, { useState, useCallback, useMemo, createContext, ReactElement, ReactNode } from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal';

export type Modal = {
    show: (options: Omit<ConfirmationModalProps, 'onClose'>) => void;
};

export const ModalContext = createContext<Modal>(null!);

export type ModalProviderProps = {
    children: ReactElement | ReactNode;
};

const ModalProvider = ({ children }: ModalProviderProps) => {
    const [modal, setModal] = useState<ConfirmationModalProps | null>(null);

    const onClose = useCallback(() => setModal(null), []);

    const show = useCallback(
        (options: Omit<ConfirmationModalProps, 'onClose'>) => {
            setModal({ ...options, onClose });
        },
        [onClose]
    );

    const context = useMemo(
        () => ({
            show,
        }),
        [show]
    );

    return (
        <ModalContext.Provider value={context}>
            {children}
            {modal && <ConfirmationModal {...modal} />}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
