import { get } from 'lodash/fp';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useMedia from 'use-media';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { useContentTranslation } from '../../../../../i18n';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import { Block, Paragraph, Container, Title, VariantImage } from '../ui';
import noImg from '../../../../../assets/images/no-image.png';

export type IntroductionProps = {
    variant: VariantDataFragment;
};

const ImageContainer = styled(Block)`
    @media (max-width: ${breakpoints.sm}) {
        margin-bottom: 20px;
    }
`;

const Description = styled.div`
    margin-bottom: 30px;
`;

const IntroductionContainer = styled.div`
    grid-column: auto / span 2;
`;

const Introduction = ({ variant }: IntroductionProps) => {
    const { formatPath } = useContentTranslation();

    const imageElement = (
        <VariantImage alt={variant?.name} fallbackSrc={noImg} src={variant?.images[0]?.url || noImg} />
    );

    const descriptionElement = (
        <>
            <Title>{get(formatPath('name'), variant)}</Title>
            <Paragraph>{get(formatPath('description'), variant)}</Paragraph>
        </>
    );

    const isMobile = useMedia({ maxWidth: breakpoints.sm });
    const orderedElements = useMemo(() => {
        if (isMobile) {
            return (
                <>
                    <ImageContainer>{imageElement}</ImageContainer>
                    <Container>
                        <Description>{descriptionElement}</Description>
                    </Container>
                </>
            );
        }

        return (
            <>
                <Container>
                    <Description>{descriptionElement}</Description>
                </Container>
                <ImageContainer>{imageElement}</ImageContainer>
            </>
        );
    }, [isMobile, descriptionElement, imageElement]);

    return <IntroductionContainer>{orderedElements}</IntroductionContainer>;
};

export default Introduction;
