import { useApolloClient } from '@apollo/client';
import { map } from 'lodash/fp';
import React, { useState, useCallback, useMemo, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ReduxFormContext } from 'redux-form';
import { attachLoading, setNotification } from '../../../actions';
import {
    updateApplication,
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables,
} from '../../../api/application.graphql';
import { createCustomer, CreateCustomerMutation, CreateCustomerMutationVariables } from '../../../api/customer.graphql';
import { useZone } from '../../../hookSelectors';
import { getCalculatorPayload } from '../../../utilities/calculator';
import { mapCreateCustomerToPayload } from '../../../utilities/customer';
import { prepareForGraphQL } from '../../../utilities/forms';
import { getApplicationsUrl } from '../../../utilities/urls';
import useValidationContext from '../../utilities/useValidationContext';
import AddGuarantorFormModal from './AddGuarantorFormModal';
import { ApplicationData } from './index';

type ChildrenProps = {
    addGuarantor: () => void;
    canAddGuarantor: boolean;
};

export type AddGuarantorProviderProps = {
    application: ApplicationData;
    children: (props: ChildrenProps) => ReactElement;
};

const AddGuarantorProvider = ({ application, children }: AddGuarantorProviderProps) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const zone = useZone();
    const [open, setOpen] = useState(false);

    const { appliedForFinancing, bank } = application;
    const isGuarantorIncluded = bank?.isGuarantorIncluded || false;

    // get form values
    // @ts-ignore
    const { initialValues } = useContext(ReduxFormContext);
    const { guarantor } = initialValues;
    // show guarantor modal
    const addGuarantor = useCallback(() => setOpen(true), [setOpen]);
    // hide guarantor modal
    const onClose = useCallback(() => setOpen(false), []);
    // prepare guarantor context
    const context = useMemo(
        () => ({
            application,
            addGuarantor,
            // check if there's guarantor tied to the application
            canAddGuarantor: isGuarantorIncluded && appliedForFinancing && !guarantor?.id,
        }),
        [application, addGuarantor, isGuarantorIncluded, appliedForFinancing, guarantor]
    );

    const history = useHistory();
    const dispatch = useDispatch();

    const validation = useValidationContext();

    const onSubmit = useCallback(
        async values => {
            // prepare guarantor payload
            const guarantorPayload = mapCreateCustomerToPayload(zone.id, {
                withMyInfo: false,
                ...prepareForGraphQL(values),
                zoneId: zone.id,
            });

            // create guarantor
            const customerPromise = client.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
                mutation: createCustomer,
                variables: guarantorPayload,
            });

            dispatch(attachLoading(customerPromise));

            const response = await customerPromise;

            const guarantor = response?.data?.customer;

            if (!guarantor) {
                throw new Error('Failed to create guarantor');
            }

            // update application form
            const updateApplicationPayload: UpdateApplicationMutationVariables = {
                id: application.id,
                data: {
                    // the remark
                    remark: initialValues.remark,
                    // always send link to guarantor
                    proceedWithCustomerDevice: true,
                    // build options
                    optionIds: map(option => option.id, initialValues.calculator.carOptions),
                    // finance product id and variant id comes from the calculator
                    financeProductId: initialValues.financeProduct?.id,
                    variantId: initialValues.variant.id,
                    // then calculator payload
                    calculator: getCalculatorPayload(initialValues.calculator),
                    promoCodeId: initialValues.promoCode?.id,
                    guarantorId: guarantor.id,
                },
            };

            const promise = client.mutate<UpdateApplicationMutation, UpdateApplicationMutationVariables>({
                mutation: updateApplication,
                variables: prepareForGraphQL(updateApplicationPayload),
            });

            dispatch(attachLoading(promise));

            // display the notification
            const { title, message }: { title: string; message: string } = t('notification.guarantorAdded', {
                returnObjects: true,
            });
            dispatch(setNotification(title, message));

            // then close modal
            onClose();

            // then go back to application list
            // @ts-ignore
            history.pushWithCompany(getApplicationsUrl, application);
        },
        [client, dispatch, history, onClose, t, zone.id, application, initialValues]
    );

    return (
        <>
            {children(context)}
            {open && <AddGuarantorFormModal onClose={onClose} onSubmit={onSubmit} validation={validation} />}
        </>
    );
};

export default AddGuarantorProvider;
