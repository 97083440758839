import styled from 'styled-components';

const ChatReplyContainer = styled.div`
    position: absolute;
    bottom: 40px;
    width: 100%;
    height: 50px;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
`;

export default ChatReplyContainer;
