import React from 'react';
import PreviewPage from '../../RemoteFlow/components/PreviewPage';
import { FlowStep } from '../../utils/flow';
import { GuarantorFlowState } from '../GuarantorFlow';

class PreviewStep extends FlowStep<GuarantorFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'preview';
    }

    public render(): React.ReactElement | null {
        const { application, channel } = this.state;

        return <PreviewPage application={application} channel={channel} onSubmit={this.submit} />;
    }
}

export default PreviewStep;
