import { useEffect } from 'react';

type WindowHero = {
    (): void;
    q: any;
};

declare global {
    interface Window {
        HeroWebPluginSettings: { applicationId: string };
        HeroObject: string;
        hero: WindowHero;
    }
}

const heroSrc = 'https://cdn.usehero.com/loader.js';
const heroArguments = [window, document, 'script', heroSrc, 'hero'];

const Hero = ({ applicationId }: { applicationId: string }) => {
    useEffect(() => {
        let script: HTMLScriptElement | undefined;

        // applicationId is required before initializing hero
        if (applicationId) {
            window.HeroWebPluginSettings = { applicationId };

            window.HeroObject = 'hero';
            window.hero =
                window.hero ||
                function () {
                    (window.hero.q = window.hero.q || []).push(...heroArguments);
                };

            script = document.createElement('script');
            script.async = true;
            script.src = heroSrc;

            document.body.appendChild(script);
        }

        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [applicationId]);

    return null;
};

export default Hero;
