import {
    OutlineError,
    OutlineWrapper,
    PureError,
    OutlineSelect,
    PureSelect,
    BoxedSelect,
    BoxedWrapper,
} from '@appvantageasia/afc-ui';
import { orderBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, cloneElement } from 'react';
import { Field } from 'redux-form';

const SelectInput = props => {
    const {
        input,
        meta,
        options,
        disabled = false,
        clearable = false,
        sort = true,
        label,
        selectComponent: Select = BoxedSelect,
        wrapperComponent: Wrapper = BoxedWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        ...selectProps
    } = props;

    const { value, onChange, name } = input;

    const handleChange = useCallback(option => onChange(option.value), [onChange]);
    const currentOption = useMemo(() => options.find(option => option.value === value), [value, options]) || null;
    const currentOptions = useMemo(() => {
        const ascVal = orderBy(['label'], ['asc'], options);

        return sort ? ascVal : options;
    }, [options, sort]);

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    return (
        <Wrapper label={label} meta={meta} name={name} {...wrapperProps}>
            {cloneElement(
                <Select
                    isClearable={clearable}
                    isDisabled={disabled}
                    name={name}
                    onChange={handleChange}
                    options={currentOptions}
                    sort={sort}
                    value={currentOption}
                    {...selectProps}
                />,
                { hasError }
            )}
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
};

SelectInput.propTypes = {
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        }).isRequired
    ).isRequired,
    selectComponent: PropTypes.elementType,
    sort: PropTypes.bool,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const SelectField = props => <Field {...props} component={SelectInput} />;

SelectField.Outline = props => (
    <SelectField
        errorComponent={OutlineError}
        selectComponent={OutlineSelect}
        wrapperComponent={OutlineWrapper}
        {...props}
    />
);

SelectField.Pure = props => (
    <SelectField errorComponent={PureError} selectComponent={PureSelect} wrapperComponent={OutlineWrapper} {...props} />
);

SelectField.FullWidth = props => (
    <SelectField errorComponent={OutlineError} wrapperComponent={BoxedWrapper.FullWidth} {...props} />
);

export default SelectField;
