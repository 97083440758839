// @ts-ignore
import { Modal, Actions, DarkButton, ActionsCenter } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '../../../../components/ui/Notification';

export type PayGateConfirmationModalProps = {
    onClose: () => void;
    onConfirm: () => void;
};

const PayGateConfirmationModal = ({ onClose, onConfirm }: PayGateConfirmationModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose} title={t('payGateConfirmationModal.title')} showClose showTitle>
            <Info>{t('payGateConfirmationModal.message')}</Info>
            <Actions>
                <ActionsCenter>
                    <DarkButton onClick={onConfirm}>{t('payGateConfirmationModal.button.proceed')}</DarkButton>
                </ActionsCenter>
            </Actions>
        </Modal>
    );
};

export default PayGateConfirmationModal;
