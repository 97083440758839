import { find, set, isNil, get } from 'lodash/fp';
import PubSub from 'pubsub-js';
import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { MiniConfiguratorDetails } from '../../../flows/ConfiguratorFlow/components/Summary/Summary';
import getCalculatorFromApplication from '../../../flows/utils/getCalculatorFromApplication';
import { usePersistedFlow } from '../../../flows/utils/persistency';
import { RuntimeSettingsState } from '../../../reducers/runtimeSettings';
import { Channel, EventExternalSite, PromoCodeUnit } from '../../../schema';
import { getCompanyIdentifier, getIsAuthenticated, getRuntimeSettings } from '../../../selectors';
import { getCompanyLoginUrl, getConfiguratorUrl } from '../../../utilities/urls';
import useDealerIdFromTenantContext from '../../data/useDealerIdFromTenantContext';
import { PromoDataFragment } from '../../data/useLoadPromo.graphql';

import Chatbot from '../../shared/Chatbot';
import Footer from '../../shared/Footer';
import PublicSessionTimer from '../../shared/PublicSessionTimer';
import { CalculatorValues } from '../../shared/calculator-next/types';
import { ApplicationDataFragment } from '../ApplicationRoute/data.graphql';
import Error404Suggestion from '../Error404Suggestion';
import { generateCacheKey, serialize, unserialize } from '../wip/DraftRoute';
import EventBootstrap from './EventBootstrap';
import EventHandler from './EventHandler';
import { EventDataFragment } from './EventRoute.graphql';

type LocationState = {
    miniConfiguratorDetails: MiniConfiguratorDetails;
    calculator: CalculatorValues;
    promo?: PromoDataFragment;
};

const pubSubChannel = 'eventSubmitFlow';

const onIdle = () => PubSub.publishSync(`${pubSubChannel}.timeout`);

type CKDOption = { label: string };

const mapMiniConfiguratorDetailsToState = (
    ckdConfiguration: RuntimeSettingsState['ckdConfiguration'],
    miniConfiguratorDetails?: ApplicationDataFragment['miniConfiguratorDetails']
) => {
    if (!ckdConfiguration || !miniConfiguratorDetails) {
        return undefined;
    }

    return {
        exteriorColor: ckdConfiguration.exterior.options.find(
            ({ label }: CKDOption) => miniConfiguratorDetails.exteriorColor === label
        )?.name,
        interiorColor: ckdConfiguration.interior.options.find(
            ({ label }: CKDOption) => miniConfiguratorDetails.interiorColor === label
        )?.name,
        delivery: ckdConfiguration.delivery.options.find(
            ({ label }: CKDOption) => miniConfiguratorDetails.delivery.label === label
        )?.name,
        enhancedPackage: !!miniConfiguratorDetails.enhancedPackage,
        timepiece: miniConfiguratorDetails.timepiece
            ? {
                  colouredRing: ckdConfiguration.timepiece.options.colouredRing.dropdownOptions.find(
                      ({ label }: CKDOption) => label === miniConfiguratorDetails.timepiece?.colouredRing
                  )?.value,
                  engraving: miniConfiguratorDetails.timepiece.engraving || undefined,
                  hands: ckdConfiguration.timepiece.options.hands.dropdownOptions.find(
                      ({ label }: CKDOption) => label === miniConfiguratorDetails.timepiece?.hands
                  )?.value,
              }
            : undefined,
        tequipment: miniConfiguratorDetails.tequipment
            ? Object.keys(miniConfiguratorDetails.tequipment).reduce((acc, value) => {
                  if (get(`tequipment.${value}`, miniConfiguratorDetails)) {
                      return set(value, true, acc);
                  }

                  return acc;
              }, {})
            : undefined,
    };
};

const mapCalculatorToState = (application: ApplicationDataFragment, event: EventDataFragment) => {
    const calculator = getCalculatorFromApplication(application);
    const { promoCode } = application;
    const { externalSite } = event.setting;

    if (externalSite !== EventExternalSite.CONFIGURATOR) {
        // just return the calculator
        return calculator;
    }

    // for configurator, we need to modify the calculator
    // by subtracting the promo code (if there's any) to the total price
    let promoValue = 0;
    const { carPrice = 0 } = calculator;
    if (promoCode && promoCode?.value) {
        // check promo value more than starting price
        const isExceededCarPrice = promoCode.value > carPrice;
        const availablePromoPrice = isExceededCarPrice ? carPrice : promoCode.value;

        const isPercentage = promoCode?.unit === PromoCodeUnit.PERCENTAGE;
        promoValue = isPercentage ? (carPrice * promoCode.value) / 100 : availablePromoPrice;
    }

    return set('totalPrice', (calculator.totalPrice || 0) - promoValue, calculator);
};

const Inner = () => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const location = useLocation();
    const { pathname, search } = location;
    const state = location.state as LocationState;
    const history = useHistory();
    const redirect = `${pathname}${search}`;
    const dealerId = useDealerIdFromTenantContext();

    // extract parameters from the URL query
    const { applicationId } = qs.parse(search, { ignoreQueryPrefix: true });

    const { token } =
        usePersistedFlow(
            ['eventSubmitFlow', applicationId].filter(Boolean).join(':'),
            pubSubChannel,
            serialize,
            unserialize,
            generateCacheKey
        ) || {};

    return (
        <>
            <EventBootstrap token={token}>
                {(event, application) => {
                    if (
                        !event ||
                        event.dealers.length === 0 ||
                        (dealerId && isNil(find(['id', dealerId], event.dealers)))
                    ) {
                        // something is not right
                        return <Error404Suggestion header={false} />;
                    }

                    const { isActive } = event;
                    const { allowPublicAccess, externalSite } = event.setting;

                    // is this done in BE before?
                    if (!isActive) {
                        return <Error404Suggestion header={false} />;
                    }

                    // not authenticate for login access, request to login
                    if (!isAuthenticated && !allowPublicAccess) {
                        // @ts-ignore
                        history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
                    }

                    const calculator =
                        state?.calculator || (application ? mapCalculatorToState(application, event) : undefined);
                    const miniConfiguratorDetails =
                        state?.miniConfiguratorDetails ||
                        mapMiniConfiguratorDetailsToState(ckdConfiguration, application?.miniConfiguratorDetails);
                    const promo = state?.promo || application?.promoCode;

                    if (externalSite === EventExternalSite.CONFIGURATOR && !miniConfiguratorDetails) {
                        // @ts-ignore
                        history.pushWithCompanyAndState(getConfiguratorUrl);
                    }

                    // redirect to configurator if myckd
                    const url =
                        externalSite === EventExternalSite.CONFIGURATOR
                            ? getConfiguratorUrl(companyCode, locationCode)
                            : redirect;

                    return (
                        <>
                            <Chatbot channel={Channel.EVENT} event={event} />
                            <PublicSessionTimer onIdle={onIdle} url={url} />
                            <EventHandler
                                application={application}
                                calculator={calculator}
                                dealerId={dealerId}
                                event={event}
                                miniConfiguratorDetails={miniConfiguratorDetails}
                                promo={promo}
                                token={token}
                            />
                        </>
                    );
                }}
            </EventBootstrap>
            <Footer />
        </>
    );
};

export default Inner;
