import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import qs from 'qs';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getCustomerDataFromMyInfo,
    GetCustomerDataFromMyInfoMutation,
    GetCustomerDataFromMyInfoMutationVariables,
} from '../../../flows/DraftFlow/components/MyInfo/MyInfoStep.graphql';
import { ApplicationFlowSource, MyInfoExcludableSection } from '../../../schema';
import { deepCustomerMerge, deepOmit } from '../../../utilities/fp';
import { ApplicationCustomerDataFragment } from '../ApplicationRoute/data.graphql';

const localKey = 'myInfoCallback';

type Response = { code: string; state: string };

export type MyInfoCallbackSession = {
    // state ID
    stateId: string;
    // application ID
    applicationId: string;
    // source channel
    source: ApplicationFlowSource;
    // MyInfo response
    response?: Response;
    // original URL
    origin: string;
    // token
    token: string;
    // step
    step: string;
    // optional state to store data
    state?: any;
};

export const startMyInfoSession = (
    requestUrl: string,
    source: ApplicationFlowSource,
    applicationId: string,
    token: string,
    step: string,
    state?: any
) => {
    const { state: stateId }: { state?: string } = qs.parse(new URL(requestUrl).search, { ignoreQueryPrefix: true });

    if (!stateId) {
        throw new Error('state ID missing in request URL for MyInfo');
    }

    const data: MyInfoCallbackSession = {
        stateId,
        applicationId,
        source,
        origin: window.location.href,
        token,
        state,
        step,
    };

    // persist something in our local storage
    localStorage.setItem(localKey, JSON.stringify(data));

    // then redirect
    window.location.replace(requestUrl);
};

export const getMyInfoSession = (applicationId: string, source: ApplicationFlowSource) => {
    const rawData = localStorage.getItem(localKey);

    if (!rawData) {
        return null;
    }

    const myInfo = JSON.parse(rawData) as MyInfoCallbackSession;

    if (myInfo.applicationId === applicationId && source === myInfo.source && myInfo.response?.code) {
        return myInfo;
    }

    return null;
};

export const getMyInfoData = (
    client: ApolloClient<NormalizedCacheObject>,
    applicationId: string,
    source: ApplicationFlowSource,
    { bankId, eventId }: { bankId?: string; eventId?: string },
    initialCustomer: ApplicationCustomerDataFragment,
    exclude?: MyInfoExcludableSection[]
): Promise<{ error: boolean; customer: ApplicationCustomerDataFragment }> | null => {
    const myInfo = getMyInfoSession(applicationId, source);

    if (!myInfo) {
        // MyInfo state not available
        return null;
    }

    const fetchData = async () => {
        const { code } = myInfo.response || {};

        if (!code) {
            throw new Error('code is missing in session');
        }

        // delete the state as it has been consumed
        localStorage.removeItem(localKey);

        const { data } = await client.mutate<
            GetCustomerDataFromMyInfoMutation,
            GetCustomerDataFromMyInfoMutationVariables
        >({
            mutation: getCustomerDataFromMyInfo,
            variables: { bankId, eventId, code, source, exclude },
            fetchPolicy: 'no-cache',
        });

        // clean the data from myInfo
        const cleanedData = deepOmit(
            ['__typename'],
            data?.fetchMyInfo
        ) as GetCustomerDataFromMyInfoMutation['fetchMyInfo'];

        // get customer data
        let customer = initialCustomer;

        const hasError = isEmpty(cleanedData);

        if (!hasError) {
            customer = {
                // merge the customer data
                ...deepCustomerMerge(customer, cleanedData),
                // specify it as coming from MyInfo
                withMyInfo: true,
            };
        }

        return { error: hasError, customer };
    };

    return fetchData();
};

const MyInfoCallback = () => {
    const history = useHistory();
    const localState = localStorage.getItem(localKey);

    useEffect(() => {
        if (!localState) {
            // do nothing
            window.location.replace(window.location.origin);

            return;
        }

        const session = JSON.parse(localState) as MyInfoCallbackSession;

        const response = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        }) as Response;

        if (response.state !== session.stateId) {
            // stop here
            window.location.replace(window.location.origin);

            return;
        }

        const nextData: MyInfoCallbackSession = { ...session, response };

        // update the state
        localStorage.setItem(localKey, JSON.stringify(nextData));

        switch (session.source) {
            case ApplicationFlowSource.GUARANTOR:
            case ApplicationFlowSource.REMOTE: {
                const url = new URL(session.origin);
                history.replace(url.pathname, { token: session.token });
                break;
            }

            default:
                window.location.replace(session.origin);
                break;
        }
    }, [localState, history]);

    return null;
};

export default MyInfoCallback;
