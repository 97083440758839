import { saveAs } from 'file-saver';
import { head, get } from 'lodash/fp';
import { nanoid } from 'nanoid';
import qs from 'query-string';
import { generateEventApplicationFilename } from '../helpers/fileHelper';

// try first to use timezone for specified zone, else use the timezone from the first zone in the country
export const getTimeZone = context => context.zone?.timezone ?? head(context.country.zones)?.timezone;

const getHeaderValueFromResponse = (response, key) => {
    for (const [k, v] of response.headers.entries()) {
        if (k === key) {
            return v;
        }
    }

    return null;
};

const getFileNameFromResponse = response => {
    const disposition = getHeaderValueFromResponse(response, 'content-disposition');
    if (disposition?.indexOf('attachment') !== -1) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
        if (matches && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }

    return null;
};

export const exportApplications = async (context, headers, duration, channel, type, dealerId, locale) => {
    const { amount, unit } = duration;
    const query = qs.stringify(
        {
            timezone: getTimeZone(context),
            duration: amount,
            durationUnit: unit,
            channel,
            type,
            dealerIds: [dealerId],
            locale,
        },
        { arrayFormat: 'index' }
    );

    const path = ['/l/export/applications', get('country.id', context), get('zone.id', context)]
        .filter(Boolean)
        .join('/');
    const response = await fetch(`${path}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getFileNameFromResponse(response));

    return getHeaderValueFromResponse(response, 'x-excel-password');
};

export const exportInsuranceApplications = async (context, headers, duration, channel, type, dealerId, locale) => {
    const { amount, unit } = duration;
    const query = qs.stringify(
        {
            timezone: getTimeZone(context),
            duration: amount,
            durationUnit: unit,
            channel,
            type,
            dealerIds: [dealerId],
            locale,
        },
        { arrayFormat: 'index' }
    );

    const path = ['/l/export/insuranceApplications', get('country.id', context), get('zone.id', context)]
        .filter(Boolean)
        .join('/');
    const response = await fetch(`${path}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getFileNameFromResponse(response));

    return getHeaderValueFromResponse(response, 'x-excel-password');
};

export const exportEventsApplication = async (
    context,
    headers,
    eventId,
    identifier,
    leadOrApplicationVersionIds,
    phase,
    dealerIds,
    locale
) => {
    const nonce = nanoid();

    const headersObj = {
        ...headers,
        'Content-Type': 'application/json',
    };

    const body = leadOrApplicationVersionIds.length !== 0 ? { nonce, leadOrApplicationVersionIds } : { nonce };

    const runExport = async (filePurpose, queryPurpose) => {
        const query = qs.stringify(
            {
                purpose: queryPurpose,
                timezone: getTimeZone(context),
                phase,
                dealerIds,
                locale,
            },
            { arrayFormat: 'index' }
        );

        const response = await fetch(`/l/export/eventLeadsAndApplications/${eventId}?${query}`, {
            method: 'POST',
            headers: headersObj,
            body: JSON.stringify(body),
        });

        const blob = await response.blob();

        saveAs(blob, generateEventApplicationFilename(context, identifier, filePurpose));

        return getHeaderValueFromResponse(response, 'x-excel-password');
    };

    const [password] = await Promise.all([runExport('BP', 'BP_UPLOAD'), runExport('BP_LEAD', 'BP_LEAD_UPLOAD')]);

    return password;
};

export const exportApplicationDocuments = async (context, headers, applicationId) => {
    const query = qs.stringify({ timezone: getTimeZone(context) });
    const path = `/l/export/applications/${context.country.id}/documents/${applicationId}?${query}`;
    const response = await fetch(`${path}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getFileNameFromResponse(response));

    return getHeaderValueFromResponse(response, 'x-document-password');
};

export const exportAppointments = async (context, headers, duration, channel, type, dealerId, locale) => {
    const { amount, unit } = duration;
    const query = qs.stringify(
        {
            timezone: getTimeZone(context),
            duration: amount,
            durationUnit: unit,
            channel,
            type,
            dealerIds: [dealerId],
            locale,
        },
        { arrayFormat: 'index' }
    );

    const path = ['/l/export/appointments', get('country.id', context), get('zone.id', context)]
        .filter(Boolean)
        .join('/');
    const response = await fetch(`${path}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getFileNameFromResponse(response));

    return getHeaderValueFromResponse(response, 'x-excel-password');
};
