import React from 'react';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';
import SubmittedPage from '../components/SubmittedPage';

class SubmitStep extends FlowStep<RemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'submit';
    }

    public async executeBefore(): Promise<FlowStep<RemoteFlowState>> {
        // trigger events
        this.flow.dispatchCompleted();

        return this;
    }

    render() {
        const { application, bank } = this.state;

        return (
            <SubmittedPage
                dealerId={application.dealerId}
                remoteFlowAcknowledgmentInfo={bank?.remoteFlowAcknowledgmentInfo}
            />
        );
    }
}

export default SubmitStep;
