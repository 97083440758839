import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../actions';
import { submitPayment, SubmitPaymentResponse } from '../../../../api/ttb';
import { startTTBPaymentSession } from '../../../../components/routes/wip/TTBPaymentCallback';
import { ApplicationFlowSource, PaymentStatus } from '../../../../schema';
import { Application } from '../../../DraftFlow';
import TTBConfirmationModal from '../../../DraftFlow/components/TTBDeposit/TTBConfirmationModal';
import useTTBPayment from '../../../DraftFlow/components/TTBDeposit/useTTBPayment';

type TTBPaymentProps = {
    application: Application;
    source: ApplicationFlowSource;
    onSubmit: () => void;
    token: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const TTBPayment = ({ application, onSubmit, source, token, showModal, setShowModal }: TTBPaymentProps) => {
    const { status } = useTTBPayment(application.version.id, source);
    const isSuccessful = application.steps?.payment || status === PaymentStatus.SUCCESS;
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

    const [result, setResult] = useState<SubmitPaymentResponse>();

    const actions = useMemo(
        () => ({
            close: () => setShowModal(false),
            confirm: async () => {
                setShowModal(false);
                // call submit api
                if (token) {
                    const result = await dispatch<Promise<SubmitPaymentResponse | undefined>>(
                        attachLoading(submitPayment(token))
                    );
                    setResult(result);
                }
            },
        }),
        [setShowModal]
    );

    useEffect(() => {
        if (result) {
            startTTBPaymentSession(source, application.version.id);
            // navigate to payment page
            window.location.href = result.url;
        }
    }, [result, source]);

    useEffect(() => {
        if (isSuccessful) {
            onSubmit();
        }
    }, [isSuccessful, onSubmit]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{showModal && <TTBConfirmationModal onClose={actions.close} onConfirm={actions.confirm} />}</>;
};

export default TTBPayment;
