import { create, update, remove, copy, extract, removeUnattached } from './attachment.graphql';

export const createAttachment = async (client, { file, uploadId, purpose }) => {
    const { data } = await client.mutate({
        mutation: create,
        variables: {
            file,
            purpose,
            uploadId,
        },
    });

    return data.attachment;
};

export const updateAttachment = async (client, { file, applicationId, token, purpose }) => {
    const { data } = await client.mutate({
        mutation: update,
        variables: {
            file,
            purpose,
            id: applicationId,
            token,
        },
    });

    return data.attachment;
};

export const copyAttachment = async (client, attachmentId, uploadId) => {
    const { data } = await client.mutate({
        mutation: copy,
        variables: {
            attachmentId,
            uploadId,
        },
    });

    return data.attachment;
};

export const deleteAttachment = async (client, attachmentId, applicationId, token) =>
    client.mutate({
        mutation: remove,
        variables: {
            applicationId,
            attachmentId,
            token,
        },
    });

export const deleteUnattached = async (client, attachmentId, uploadId) =>
    client.mutate({
        mutation: removeUnattached,
        variables: {
            uploadId,
            attachmentId,
        },
    });

export const extractNric = async (client, uploadId) => {
    const { data } = await client.mutate({
        mutation: extract,
        variables: {
            uploadId,
        },
    });

    return data.nric;
};
