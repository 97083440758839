import { pick, omit } from 'lodash/fp';
import { CustomerType } from '../schema';

const getCustomerPayload = customer => {
    const data = {
        type: CustomerType.INDIVIDUAL,
        ...pick(
            [
                'title',
                'name',
                'firstName',
                'lastName',
                'email',
                'emailBis',
                'phone',
                'phoneBis',
                'identityNumber',
                'nationality',
                'dateOfBirth',
                'withMyInfo',
            ],
            customer
        ),
    };

    if (customer.details) {
        data.details = omit(['files'], customer.details);
    }

    return data;
};

export const mapUpdateCustomerToPayload = (customerId, customer) => ({
    id: customerId,
    data: omit(['type', 'withMyInfo'], getCustomerPayload(customer)),
});

/* map an customer state into a payload ready for creating */
export const mapCreateCustomerToPayload = (zoneId, customer) => ({
    data: {
        zoneId,
        ...getCustomerPayload(customer),
    },
});
