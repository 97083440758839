import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { exportApplicationDocuments } from '../../../../actions';
import { Channel } from '../../../../schema';
import { ApplicationDataFragment } from '../data.graphql';

type UseDownloadDocumentsResult = [
    {
        password: string | null;
        downloadDisabled: boolean;
        isShow: boolean;
        isDownloading: boolean;
    },
    {
        onClose: () => void;
        getDocuments: () => void;
    }
];

const useDownloadDocuments = (application: ApplicationDataFragment): UseDownloadDocumentsResult => {
    const [isShow, setIsShow] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [password, setPassword] = useState(null);

    const dispatch = useDispatch();

    const downloadDisabled = useMemo(() => {
        return !(application.attachments.length > 0);
    }, [application.attachments]);

    const getDocuments = useCallback(() => {
        setPassword(null);
        setIsDownloading(true);

        // @ts-ignore
        dispatch(exportApplicationDocuments(application.id) as Promise<any>).then(newPassword => {
            setIsDownloading(false);
            if (newPassword) {
                setIsShow(true);

                setPassword(newPassword);
            }
        });
    }, [application.id, dispatch]);

    const onClose = useCallback(() => {
        setIsShow(false);
    }, []);

    return [
        { downloadDisabled, isDownloading, password, isShow },
        { onClose, getDocuments },
    ];
};

export default useDownloadDocuments;
