import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { Channel } from '../../schema';
import { getCurrentCountry, getIsAuthenticated, getUser } from '../../selectors';
import { prefixCompany, getHomeUrl, getPrivateHomeUrl, getPublicHomeUrl } from '../../utilities/urls';
import { prefixEventPath } from '../routers/shared';

const useHomeUrl = (channel?: Channel) => {
    // get user context
    const user = useSelector(getUser);

    // check if it's authenticated or not
    const isAuthenticated = useSelector(getIsAuthenticated);

    // get country
    const country = useSelector(getCurrentCountry);

    // get location
    const { pathname, state } = useLocation();

    return useMemo(() => {
        // when user is in event route
        const eventMatch = matchPath<{ eventId: string }>(pathname, {
            path: prefixEventPath('/:eventId'),
            exact: true,
        });
        // set current event route as home
        if (eventMatch) {
            return {
                url: prefixCompany(() => `/event/${eventMatch.params.eventId}`),
                state,
            };
        }

        // user is currently in a channel route
        if (channel) {
            return { url: getHomeUrl(channel) };
        }

        if (!country) {
            return null;
        }

        return { url: isAuthenticated ? getPrivateHomeUrl(user, country) : getPublicHomeUrl(country) };
    }, [channel, country, isAuthenticated, pathname, state, user]);
};

export default useHomeUrl;
