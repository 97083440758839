import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';

const DealerSelect = props => {
    const theme = useTheme();
    const { disabled = false } = props;

    const styles = useMemo(
        () => ({
            control: provided => ({
                ...provided,
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                minHeight: 'auto',
                height: '18px',
            }),
            menu: provided => ({
                ...provided,
                top: '20px',
                left: '-16px',
                right: '-16px',
                marginTop: 0,
                marginBottom: 0,
                width: 'auto',
                border: '1px solid #ced4da',
                borderTop: 0,
                borderRadius: 0,
                boxShadow: 'none',
            }),
            menuList: provided => ({
                ...provided,
                padding: 0,
            }),
            option: (provided, { isSelected }) => ({
                ...provided,
                cursor: 'pointer',
                minHeight: '38px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                backgroundColor: 'transparent',
                color: isSelected ? theme?.themeHighlightColour : '#000000',
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#FFFFFF',
                },
                ':active': {
                    ...provided[':active'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#000000',
                },
            }),
            indicatorsContainer: () => ({ height: '22px', color: theme?.themeHighlightColour }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: (provided, { isDisabled }) => ({
                padding: '0',
                display: isDisabled ? 'none' : 'flex',
            }),
            input: provided => ({
                ...provided,
                margin: 0,
                height: '18px',
                padding: 0,
            }),
            valueContainer: provided => ({
                ...provided,
                padding: '0',
                height: '18px',
                justifyContent: 'flex-end',
            }),
            singleValue: (provided, { isDisabled }) => ({
                ...provided,
                position: 'relative',
                maxWidth: 'none',
                marginLeft: 0,
                marginRight: 0,
                top: 'unset',
                transform: 'none',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                color: isDisabled ? '#444444' : theme?.themeHighlightColour,
            }),
        }),
        [theme]
    );

    return <Select {...props} isDisabled={disabled} styles={styles} />;
};

DealerSelect.propTypes = {
    disabled: PropTypes.bool,
};

export default DealerSelect;
