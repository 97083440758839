import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { InsuranceCompanyDataFragment } from '../../../../components/data/useLoadInsuranceCompany.graphql';
import { PromoDataFragment } from '../../../../components/data/useLoadPromo.graphql';
import { ZoneScopeFragment } from '../../../../components/data/useLoadScope.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useValidation from '../../../../components/utilities/useValidation';
import useValidationContext from '../../../../components/utilities/useValidationContext';
import { useCountry } from '../../../../hookSelectors';
import { Channel, CustomerDetailsSource, EventExternalSite } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import useOptions from '../../../../utilities/constants/useOptions';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import { buildInsuranceCalculatorValues } from '../../../DraftFlow/components/DraftPage/Inner';
import Navigation from '../../../utils/Navigation';
import { BackStepContext } from '../../../utils/flow';
import CustomerForm, { CustomerFormValues, schema as getSchema } from './CustomerForm';
import { Page } from './ui';

export type InnerProps = {
    submit: (values: any) => Promise<any>;
    zone: ZoneScopeFragment;
    dealerId?: string;
    backStep?: BackStepContext;
    channel: Channel;
    event: EventDataFragment;
    variant?: VariantDataFragment;
    calculator?: Partial<CalculatorValues>;
    financeProduct?: FinanceDataFragment;
    promo?: PromoDataFragment | null;
    withMyInfoError?: boolean;
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    initialValues: CustomerFormValues | null;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    bookingId?: string;
    step: string;
    appliedForFinancing?: boolean;
    appliedForInsurance?: boolean;
    bank?: BankDataFragment;
    isCalculatorEnabled?: boolean;
    insuranceCalculator?: Partial<CalculatorInsuranceValues>;
    insuranceCompany?: InsuranceCompanyDataFragment;
};

const Inner = ({ submit, backStep, event, initialValues: init, dealerId, step, ...props }: InnerProps) => {
    const { calculator, variant, miniConfiguratorDetails, insuranceCalculator, appliedForInsurance } = props;
    const { type } = useSelector(getRuntimeSettings);

    const hasCalculator = !!calculator && (!!variant || !!miniConfiguratorDetails);
    const schema = useMemo(
        () => getSchema(hasCalculator, type, event.setting.externalSite === EventExternalSite.MARKETINGRECONSENT),
        [event, hasCalculator, type]
    );
    const validate = useValidation(schema);

    // get runtime settings
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const { code: countryCode } = useCountry();
    const validation = useValidationContext();

    // default city option only show if there is one option
    // for default show based on urlCountryCode
    const { cities = [] } = useOptions();
    const defaultCity = useMemo(() => {
        const citiesList = cities?.filter(({ countryCode: urlCountryCode }) => urlCountryCode === countryCode);

        return citiesList.length === 1 ? citiesList[0].name : null;
    }, [cities, countryCode]);

    const initialValues = useMemo(() => {
        // return default init value if there's any
        if (init) {
            return init;
        }

        return {
            dealerId,
            customerInfo: {
                withMyInfo: false,
                details: {
                    residentialAddress: {
                        street: '',
                        country: countryCode,
                        city: defaultCity,
                        source: CustomerDetailsSource.MANUAL,
                    },
                    nationality: {
                        value: countryCode,
                        source: CustomerDetailsSource.MANUAL,
                    },
                },
                ...(appliedForInsurance &&
                    insuranceCalculator?.dateOfBirth && {
                        dateOfBirth: {
                            value: new Date(insuranceCalculator?.dateOfBirth),
                            source: CustomerDetailsSource.MANUAL,
                        },
                    }),
            },
            consentDraft: { consents: {} },

            ...buildInsuranceCalculatorValues(insuranceCalculator),
        };
    }, [init, countryCode, defaultCity, dealerId, insuranceCalculator, appliedForInsurance]);

    // prepare submit
    const onApply = useCallback(
        (values: any, validate: (values: any) => void) => {
            // first validate values
            validate(values);

            // then proceed with apply function
            return submit(values);
        },
        [submit]
    );

    const [referenceId, setReferenceId] = useState<string>();

    // need dealerId to fetch variants
    if (!event) {
        // still need to wait for now
        return null;
    }

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Customer Details" />
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <CustomerForm
                {...props}
                apply={onApply}
                countryCode={countryCode}
                dealerId={dealerId}
                event={event}
                initialValues={initialValues}
                referenceId={referenceId}
                setReferenceId={setReferenceId}
                step={step}
                useCustomerBirthDay={useCustomerBirthDay}
                useCustomerNationality={useCustomerNationality}
                validate={validate}
                validation={validation}
            />
        </Page>
    );
};

export default Inner;
