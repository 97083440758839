import { useSelector } from 'react-redux';
import * as selectors from './selectors';

export const useCountry = () => {
    const country = useSelector(selectors.getCurrentCountry);

    if (!country) {
        throw new Error('Country missing in state');
    }

    return country;
};

export const useCompany = () => {
    const company = useSelector(selectors.getCompany);

    if (!company) {
        throw new Error('Company missing in state');
    }

    return company;
};

export const useZone = () => {
    const zone = useSelector(selectors.getCurrentZone);

    if (!zone) {
        throw new Error('Zone missing in state');
    }

    return zone;
};
