import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import React, { useCallback, useEffect, ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../actions';
import { BankDataFragment } from '../../../../components/data/useLoadBank.graphql';
import { ApplicationCustomerDataFragment } from '../../../../components/routes/ApplicationRoute/data.graphql';
import { getMyInfoData, startMyInfoSession } from '../../../../components/routes/wip/MyInfoCallback';
import { Container } from '../../../../components/ui/calculator';
import { useBankTheme } from '../../../../components/utilities/ThemeProvider';
import { ApplicationFlowSource } from '../../../../schema';
import Navigation from '../../../utils/Navigation';
import { BackStepContext } from '../../../utils/flow';

const useMyInfoLink = (
    applicationId: string,
    token: string,
    source: ApplicationFlowSource,
    step: string,
    bank?: BankDataFragment
) =>
    useCallback(() => {
        const requestUrl = bank?.myInfo?.requestUrl;

        if (!requestUrl) {
            throw new Error('Request URL for MyInfo missing in bank');
        }

        startMyInfoSession(requestUrl, source, applicationId, token, step);
    }, [bank, source, applicationId, token, step]);

const useMyInfoInitialization = (
    applicationId: string,
    onSubmit: (values: any) => Promise<void>,
    initialCustomer: ApplicationCustomerDataFragment,
    source: ApplicationFlowSource,
    bank?: BankDataFragment
) => {
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const bankId = bank?.id;
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

    useEffect(() => {
        const myInfoPromise = getMyInfoData(client, applicationId, source, { bankId }, initialCustomer);

        if (myInfoPromise) {
            const promise = myInfoPromise.then(({ error, customer }) => onSubmit({ customer, hasError: error }));
            dispatch(attachLoading(promise));
        }
    }, [client, bankId, dispatch, initialCustomer, applicationId, onSubmit, source]);
};

export type MyInfoRouteProps = {
    bank?: BankDataFragment;
    onSubmit: (values: any) => Promise<void>;
    applicationId: string;
    customer: ApplicationCustomerDataFragment;
    backStep?: BackStepContext;
    source: ApplicationFlowSource;
    token: string;
    pageComponent: ComponentType<{ goToManual: () => void; goToMyInfo: () => void }>;
    step: string;
};

const MyInfoRoute = ({
    bank,
    onSubmit,
    applicationId,
    customer,
    backStep,
    token,
    source,
    pageComponent: Page,
    step,
}: MyInfoRouteProps) => {
    const goToMyInfo = useMyInfoLink(applicationId, token, source, step, bank);

    // handle my info state
    useMyInfoInitialization(applicationId, onSubmit, customer, source, bank);

    // override the theme with the bank
    useBankTheme(bank);

    return (
        <Container>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <Page goToManual={() => onSubmit({ customer, hasError: false })} goToMyInfo={goToMyInfo} />
        </Container>
    );
};

export default MyInfoRoute;
