import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { requestNewPassword } from '../../../actions';
import { handleResponseError } from '../../../utilities/forms';
import useValidation from '../../utilities/useValidation';
import RequestNewPasswordForm, { schema as RequestNewPasswordFormSchema } from './RequestNewPasswordForm';
import RequestNewPasswordSuccess from './RequestNewPasswordSuccess';

type RequestNewPasswordStepProps = {
    setContext: Dispatch<SetStateAction<{ step: string }>>;
};

const RequestNewPasswordStep = ({ setContext }: RequestNewPasswordStepProps) => {
    const { i18n } = useTranslation();
    const validate = useValidation(RequestNewPasswordFormSchema);
    const dispatch = useDispatch();
    const [done, setDone] = useState(false);

    const onSubmit = useCallback(
        ({ username }) => {
            // @ts-ignore
            return dispatch(requestNewPassword(username, i18n.language)).catch(handleResponseError);
        },
        [dispatch]
    );

    const goBackToLogin = useCallback(() => setContext({ step: 'credentials' }), [setContext]);

    if (done) {
        return <RequestNewPasswordSuccess goBackToLogin={goBackToLogin} />;
    }

    return <RequestNewPasswordForm onSubmit={onSubmit} onSubmitSuccess={() => setDone(true)} validate={validate} />;
};

export default RequestNewPasswordStep;
