import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { SubTitle, Title } from './shared/ui';
import noImg from '../../../../assets/images/no-image.png';
import comfort from './assets/comfort.png';
import design from './assets/design.png';
import performance from './assets/performance.png';
import price from './assets/price.png';

const IntroPageContainer = styled.div`
    img {
        max-width: 100vw;
        height: 55vh;
        object-fit: cover;
    }
    video {
        max-width: 100vw;
        height: 47vh;
        object-fit: cover;
    }
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        video {
            height: 60vh;
        }
    }
    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        video {
            height: 76vh;
        }
    }
`;

const TextContainer = styled.div<{ isSafari: boolean; isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    margin: 20px;
    p {
        font-size: 16px;
        margin: 10px 0;

        a {
            color: #d5001c;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    div {
        align-self: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 120px;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0 20px;

        img {
            width: auto;
            height: 65px;
            margin: 2px;
            border-radius: 8px;

            /* solution for blurry images in desktop version chromium based browsers */
            image-rendering: ${props => (props.isSafari || props.isMobile ? 'auto' : '-webkit-optimize-contrast')};
        }
    }

    /* Tablet Screen */
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
        div {
            max-width: 40%;
            justify-content: space-evenly;
            flex-wrap: nowrap;

            img {
                height: 70px;
            }
        }
    }

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 15px 80px;

        div {
            max-width: 40%;
            margin: 30px 0;
            justify-content: space-evenly;
            flex-wrap: nowrap;

            img {
                margin: 4px;
                height: 100px;
            }
        }
    }
`;

// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
const isSafari =
    // @ts-ignore
    /constructor/i.test(window.HTMLElement) ||
    (value => {
        return value.toString() === '[object SafariRemoteNotification]';
        // @ts-ignore
    })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification));

const IntroPage = () => {
    const isMobileSource = useMedia({ maxWidth: breakpoints.sm });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { introPage } = ckdConfiguration;

    return (
        <IntroPageContainer>
            <video autoPlay muted playsInline>
                {isMobileSource ? (
                    <source src={introPage.mobileVideo} type="video/mp4" />
                ) : (
                    <source src={introPage.desktopVideo} type="video/mp4" />
                )}
            </video>
            <TextContainer isMobile={isMobileSource} isSafari={isSafari}>
                <Title>{introPage.headline}</Title>
                <SubTitle>{introPage.subline}</SubTitle>
                <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: ckdConfiguration.introPage.body,
                    }}
                />
                <div>
                    <ImgWithFallback alt="Design Icon" fallbackSrc={noImg} src={design} />
                    <ImgWithFallback alt="Performance Icon" fallbackSrc={noImg} src={performance} />
                    <ImgWithFallback alt="Comfort Icon" fallbackSrc={noImg} src={comfort} />
                    <ImgWithFallback alt="Price Icon" fallbackSrc={noImg} src={price} />
                </div>
            </TextContainer>
        </IntroPageContainer>
    );
};

export default IntroPage;
