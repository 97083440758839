import React from 'react';
import { RemoteFlow } from '../../../../schema';
import RemoteBoostrap from './RemoteBoostrap';
import RemoteData from './RemoteData';
import RemoteHandler from './RemoteHandler';
import RemoteShield from './RemoteShield';

const RemoteRoute = () => (
    <RemoteData flow={RemoteFlow.CUSTOMER}>
        {context => (
            <RemoteBoostrap context={context} handlerComponent={RemoteHandler} shieldComponent={RemoteShield} />
        )}
    </RemoteData>
);

export default RemoteRoute;
