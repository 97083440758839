// @ts-ignore
import { DarkButton, Actions, Modal, GrayButton } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextAlignCenter } from '../../components/ui/TextAlign';

export type OptionsCheckModalProps = {
    close: () => void;
    ok: () => void;
    title: string;
    message: string;
};

const OptionsCheckModal = ({ close, ok, title, message }: OptionsCheckModalProps) => {
    const { t } = useTranslation();
    const { button }: { button: { [key: string]: string } } = t('optionsCheckModal', { returnObjects: true });

    return (
        <Modal onClose={close} title={title} showClose showTitle>
            <TextAlignCenter>{message}</TextAlignCenter>
            <Actions>
                <GrayButton onClick={close}>{button.decline}</GrayButton>
                <DarkButton onClick={ok}>{button.confirm}</DarkButton>
            </Actions>
        </Modal>
    );
};

export default OptionsCheckModal;
