import { InsuranceValues } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { DarkButton, Actions } from '@appvantageasia/afc-ui';
import { get, isEmpty } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps, FormSection, SubmissionError } from 'redux-form';
import { ZoneScopeFragment } from '../../../../../components/data/useLoadScope.graphql';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import TextareaField from '../../../../../components/shared/form/TextareaField';
import useFormValues from '../../../../../components/utilities/useFormValues';
import { useCountry } from '../../../../../hookSelectors';
import { Channel, CustomerDetailsSource } from '../../../../../schema';
import { getIsAuthenticated } from '../../../../../selectors';
import CustomerDetailsForm from './CustomerDetailsForm';
import DraftModalProvider from './DraftModalProvider';

export type CustomerFormProps = {
    countryCode: string;
    apply: (values: any, validate: (values: any) => void) => Promise<any>;
    zone: ZoneScopeFragment;
    useCustomerBirthDay: boolean;
    useCustomerNationality: boolean;
    validation: any;
    channel: Channel;
    disableFinanceApplication?: boolean;
    insuranceCalculator?: Partial<InsuranceValues> | undefined;
};

const CustomerForm = (props: CustomerFormProps & InjectedFormProps<any, CustomerFormProps>) => {
    const { valid, handleSubmit, change, disableFinanceApplication } = props;
    const { t } = useTranslation();

    // get authentication status
    const isAuthenticated = useSelector(getIsAuthenticated);

    return (
        <DraftModalProvider change={change} handleSubmit={handleSubmit}>
            {setDraftModal => (
                <>
                    <CustomerDetailsForm disabled={disableFinanceApplication} />
                    <FormSection name="application">
                        <TextareaField.Raw
                            label={t('calculator.label.remarks')}
                            name="remark"
                            placeholder={t('calculator.placeholder.remarks')}
                            style={{ width: '100%' }}
                        />
                    </FormSection>
                    <Actions>
                        {isAuthenticated && (
                            <DarkButton disabled={!valid} onClick={() => setDraftModal(true)} type="button">
                                {t('draftPage.button.draft')}
                            </DarkButton>
                        )}
                        <DarkButton disabled={!valid} onClick={handleSubmit} type="button" value="submit">
                            {t('draftPage.button.next')}
                        </DarkButton>
                    </Actions>
                </>
            )}
        </DraftModalProvider>
    );
};

export default reduxForm<any, CustomerFormProps>({
    form: 'customer',
    // @ts-ignore
    onSubmit: (values, dispatch, props) => {
        const { validate, apply } = props;

        // @ts-ignore
        return apply(values, nextValues => {
            if (!validate) {
                return;
            }

            const errors = validate(nextValues, props);

            if (!isEmpty(errors)) {
                throw new SubmissionError(errors);
            }
        });
    },
})(CustomerForm);
