import {
    BoxedInput,
    BoxedWrapper,
    OutlineError,
    OutlineInput,
    OutlineWrapper,
    PureError,
    PureInput,
} from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Field } from 'redux-form';

export const TextInput = props => {
    const {
        input,
        inputComponent: Input = BoxedInput,
        wrapperComponent: Wrapper = BoxedWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        meta,
        label,
        onBlurValue,
        withFocusClear,
        autoFocus,
        inputRef,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    const { onChange, onFocus: defaultOnFocus, onBlur: defaultOnBlur, value } = input;

    const [lastValue, setLastValue] = useState(value);

    const onFocus = useMemo(() => {
        if (withFocusClear) {
            return () => {
                setLastValue(value);

                return onChange('');
            };
        }

        return defaultOnFocus;
    }, [withFocusClear, onChange, defaultOnFocus, setLastValue, value]);

    const onBlur = useMemo(() => {
        if (withFocusClear) {
            return event => {
                if (event.target.value !== '') {
                    return onChange(event.target.value);
                }

                return onChange(lastValue);
            };
        }

        if (onBlurValue !== undefined) {
            return event => {
                if (event.target.value === '') {
                    return onChange(onBlurValue);
                }

                return defaultOnBlur(event);
            };
        }

        return defaultOnBlur;
    }, [onBlurValue, onChange, defaultOnBlur, lastValue, withFocusClear]);

    const nativeInputRef = useRef(null);
    const ref = inputRef || nativeInputRef;

    useEffect(() => {
        if (autoFocus && ref.current) {
            // element despite being in DOM is not yet visible and therefor focus won't persist
            // as a work around we use a timeout to wait to apply the focus on a later tick
            setTimeout(() => {
                if (ref.current) {
                    // protect us against exceptions on unmounted components
                    ref.current.focus();
                }
            }, 100);
        }
    }, [autoFocus, ref]);

    return (
        <Wrapper label={label} meta={meta} name={input.name} {...wrapperProps}>
            <Input {...input} inputRef={ref} onBlur={onBlur} onFocus={onFocus} {...inputProps} />
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
};

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
    autoFocus: PropTypes.bool,
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }).isRequired,
    inputComponent: PropTypes.elementType,
    inputRef: PropTypes.shape({}),
    label: PropTypes.string,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    onBlurValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withFocusClear: PropTypes.bool,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const TextField = props => <Field component={TextInput} {...props} />;

TextField.FullWidth = props => (
    <TextField errorComponent={PureError} wrapperComponent={BoxedWrapper.FullWidth} {...props} />
);

TextField.Outline = props => (
    <TextField
        errorComponent={OutlineError}
        inputComponent={OutlineInput}
        wrapperComponent={OutlineWrapper}
        {...props}
    />
);

TextField.PureGroup = props => (
    <TextField errorComponent={PureError} inputComponent={PureInput} wrapperComponent={OutlineWrapper} {...props} />
);

export default TextField;
