import React, { createContext, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'use-media';
import breakpoints from '../../utilities/constants/breakpoints';

type MenuState = {
    fixedMenu: boolean;
    showMenu: boolean;
    onToggle?: () => void;
    onClose?: () => void;
    onFixedToggle?: () => void;
};
const initialState: MenuState = {
    fixedMenu: false,
    showMenu: false,
};

export const Context = createContext<MenuState>(initialState);

export const useFixedMenuController = () => useContext(Context);

type FixedMenuControllerProps = {
    children: ReactElement[];
};

const FixedMenuController = ({ children }: FixedMenuControllerProps) => {
    const [menuState, setMenuState] = useState(initialState);
    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const context = useMemo(
        () => ({
            ...menuState,
            onToggle: () => setMenuState(prevState => ({ ...prevState, showMenu: !prevState.showMenu })),
            onFixedToggle: () => {
                setMenuState(prevState => {
                    if (isMobile) {
                        return { showMenu: !prevState.showMenu, fixedMenu: false };
                    }

                    return { showMenu: true, fixedMenu: !prevState.fixedMenu };
                });
            },
            onClose: () => {
                if (!menuState.fixedMenu) {
                    setMenuState({ fixedMenu: false, showMenu: false });
                }
            },
        }),
        [setMenuState, menuState, isMobile]
    );

    useEffect(() => {
        if (isMobile) {
            setMenuState(prevState => ({ ...prevState, fixedMenu: false }));
        }
    }, [isMobile]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};
export default FixedMenuController;
