import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../ui/calculator';

export type RemoteMessageProps = { message: string; title: string };

const Body = styled.div`
    color: #000;
    text-align: center;
    line-height: 1.72rem;
    margin-top: 20px;
`;

const Title = styled.div`
    margin-top: 80px;
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 1.21rem;
    text-transform: uppercase;
`;

const RemoteMessage = ({ message, title }: RemoteMessageProps) => (
    <Container>
        <Title>{title}</Title>
        <Body>{message}</Body>
    </Container>
);

export default RemoteMessage;
