import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { createFormValidation } from '../../utilities/forms';

const useValidation = (schema: (t: TFunction) => yup.Schema<any>) => {
    // prepare translation
    const { t } = useTranslation();

    return useMemo(() => {
        // translate schema
        const translatedSchema = schema(t);

        // create validation
        return createFormValidation(translatedSchema);
    }, [schema, t]);
};

export default useValidation;
