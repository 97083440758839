// @ts-ignore
import { Modal } from '@appvantageasia/afc-ui';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ApplicationCustomerDataFragment } from '../../../../../components/routes/ApplicationRoute/data.graphql';
import * as SearchCustomerUI from '../../../../../components/ui/calculator/SearchCustomer';
import { SearchingStateCore } from './index';
import { ReactComponent as RadioChecked } from '../../../../../assets/images/radio_checked.svg';
import { ReactComponent as RadioUnChecked } from '../../../../../assets/images/radio_unchecked.svg';

const { PreInfo, List, RowLine, LineLeft, LineRight, Item } = SearchCustomerUI;

export type ExistingCustomerModalProps = SearchingStateCore & {
    list: ApplicationCustomerDataFragment[];
};

const ExistingCustomerModal = ({ list, onComplete, reset, values: initialValues }: ExistingCustomerModalProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedCustomer, setSelectedCustomer] = useState<Partial<ApplicationCustomerDataFragment> | null>(null);

    const onConfirm = useCallback(() => {
        if (selectedCustomer) {
            onComplete(selectedCustomer);
        }
    }, [selectedCustomer, onComplete]);

    // function to render existing items/matches
    const renderItem = (item: ApplicationCustomerDataFragment) => {
        const { id, name, phone, email, identityNumber } = item;

        return (
            <Item key={id} onClick={() => setSelectedCustomer(item)}>
                {selectedCustomer === item ? <RadioChecked fill={theme.themeHighlightColour} /> : <RadioUnChecked />}
                <div>
                    <RowLine>
                        {/* @ts-ignore */}
                        <LineLeft withBold={name?.value === initialValues.name?.value}>{name?.value}</LineLeft>
                        {/* @ts-ignore */}
                        <LineRight withBold={phone?.value === initialValues.phone?.value}>{phone?.value}</LineRight>
                    </RowLine>
                    <RowLine>
                        {/* @ts-ignore */}
                        <LineLeft withBold={email?.value === initialValues.email?.value}>{email?.value}</LineLeft>
                        {/* @ts-ignore */}
                        <LineRight withBold={identityNumber?.value === initialValues.identityNumber?.value}>
                            {identityNumber?.value}
                        </LineRight>
                    </RowLine>
                </div>
            </Item>
        );
    };

    return (
        <Modal
            onClose={reset}
            onConfirm={onConfirm}
            title={t('draftPage.nricModal.title')}
            showClose
            showConfirm
            showTitle
        >
            <PreInfo>{t('draftPage.nricModal.partialTitle')}</PreInfo>
            <List>
                {/* render existing items */}
                {list.map(renderItem)}
                {/* render the new item */}
                <Item onClick={() => setSelectedCustomer(initialValues)}>
                    {selectedCustomer === initialValues ? (
                        <RadioChecked fill={theme.themeHighlightColour} />
                    ) : (
                        <RadioUnChecked />
                    )}
                    <div> {t('draftPage.nricModal.button.new')} </div>
                </Item>
            </List>
        </Modal>
    );
};

export default ExistingCustomerModal;
