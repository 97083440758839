import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getIsAuthenticated } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { Title } from './shared/ui';

const SectionHeaderBarContainer = styled.div<{ isAuthenticated: boolean }>`
    position: sticky;
    top: ${props => (props.isAuthenticated ? '76px' : '48px')};
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;
    z-index: 1000;

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding-top: 30px;
    }
`;
type SectionHeaderBarProps = {
    text: string;
};

const SectionHeaderBar = ({ text }: SectionHeaderBarProps) => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    return (
        <SectionHeaderBarContainer isAuthenticated={isAuthenticated}>
            <Title>{text}</Title>
        </SectionHeaderBarContainer>
    );
};

export default SectionHeaderBar;
