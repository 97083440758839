import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { BackStepContext } from '../../../utils/flow';
import upArrow from '../Configurator/assets/up.png';

const BackStepContainer = styled.div`
    align-self: center;
    padding-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    align-items: center;
    cursor: pointer;
    img {
        width: 20px;
        height: 15px;
    }
    span {
        padding-left: 10px;
    }

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 5px 20px;
        position: relative;
    }
`;

type GoBackButtonProps = {
    backStep: BackStepContext;
};

const GoBackButton = ({ backStep }: GoBackButtonProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    return (
        <BackStepContainer onClick={backStep.goTo}>
            <img alt="arrow" src={upArrow} />
            <span>{ckdConfiguration.summary.goBack}</span>
        </BackStepContainer>
    );
};

export default GoBackButton;
