import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues, ReduxFormContext } from 'redux-form';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import { useContentTranslation } from '../../../../../i18n';
import { CarOfInterestType } from '../../../steps/EventDraftStep';
import { Title } from '../ui';
import CarOfInterestSelection from './CarOfInterestSelection';

export type CarOfInterestChooserProps = {
    event: EventDataFragment;
    disabled: boolean;
    showDealerSelection: boolean;
    dealerId: string;
};

const CarOfInterestChooser = ({ event, disabled, showDealerSelection, dealerId }: CarOfInterestChooserProps) => {
    const { form } = useContext(ReduxFormContext);

    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const dealerOptions = useMemo(() => event.dealers.map(({ id, name }) => ({ value: id, label: ct(name) })), [
        ct,
        event.dealers,
    ]);

    const formData: { carOfInterest?: CarOfInterestType } = useSelector(getFormValues('customer'));
    const values = formData.carOfInterest;

    const dispatch = useDispatch();

    useEffect(() => {
        if (dealerOptions.length === 1) {
            dispatch(change(form, 'carOfInterest.dealerId', dealerOptions[0].value));
        }
    }, [dealerOptions, dispatch, form]);

    useEffect(() => {
        if (values?.dealerId) {
            dispatch(change('customer', 'dealerId', values.dealerId));
        }
    }, [dispatch, values]);

    return (
        <>
            <Title>{t('eventVehicleOfInterest.title')}</Title>
            <div style={{ marginBottom: 21 }}>
                {showDealerSelection && (
                    <SelectField.OutlineLeft
                        disabled={disabled || dealerOptions.length === 1}
                        name="dealerId"
                        options={dealerOptions}
                        placeholder={t('eventVehicleOfInterest.placeholder.preferredDealership')}
                    />
                )}
                <CarOfInterestSelection dealerId={dealerId} disabled={disabled} />
            </div>
        </>
    );
};

export default CarOfInterestChooser;
