import { useLazyQuery } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { InsuranceCompanyDataFragment } from '../../data/useLoadInsuranceCompany.graphql';
import RedirectToCompanyHome from '../../utilities/RedirectToCompanyHome';
import useInsuranceCompany from '../../utilities/useInsuranceCompany';
import { getApplication, GetApplicationQuery, GetApplicationQueryVariables } from '../ApplicationRoute/data.graphql';

export type ApplyForInsuranceProps = {
    applicationId: string;
    language: string;
    children: (
        application: GetApplicationQuery['application'],
        insuranceCompany: InsuranceCompanyDataFragment
    ) => ReactElement;
};

const ApplyForInsuranceBootstrap = ({ children, applicationId, language }: ApplyForInsuranceProps) => {
    const insuranceCompany = useInsuranceCompany();

    const [fetchApplication, { data, loading: applicationLoading, called: applicationCalled }] = useLazyQuery<
        GetApplicationQuery,
        GetApplicationQueryVariables
    >(getApplication, {
        fetchPolicy: 'network-only',
    });
    const application = data?.application;

    useEffect(() => {
        if (!applicationCalled) {
            fetchApplication({
                variables: { id: applicationId, locale: language },
            });
        }
    }, [applicationId, applicationCalled, fetchApplication, language]);

    if (applicationLoading || !applicationCalled) {
        // still loading
        return null;
    }

    if (!application || !insuranceCompany) {
        // application and insuranceCompany are mandatory
        return <RedirectToCompanyHome />;
    }

    return children(application, insuranceCompany);
};

export default ApplyForInsuranceBootstrap;
