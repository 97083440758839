// @ts-ignore
import { ActionsV2 as Actions, Footer } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { getGlobalPermissions } from '../../../../selectors';
import useFormChanged from '../../ApplicationRoute/actions/useFormChanged';
import { ApplicationDataFragment } from '../../ApplicationRoute/data.graphql';
import useFormDirty from '../../shared/useFormDirty';
import UpdateStateAction from './UpdateStateAction';

export type SaveChangesActionProps = {
    application: ApplicationDataFragment;
};

const SaveChangesAction = ({ application }: SaveChangesActionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mayManageReservation } = useSelector(getGlobalPermissions);

    const onClick = useCallback(() => dispatch(submit('application')), [dispatch]);

    const hasChanged = useFormChanged();
    const hasErrors = useFormDirty('application');

    const disabled = hasErrors || !mayManageReservation || !hasChanged;

    return (
        <>
            <UpdateStateAction application={application} />
            <Actions>
                <Footer.FootBarButton disabled={disabled} onClick={onClick}>
                    {t('applicationDetailsPage.button.save')}
                </Footer.FootBarButton>
            </Actions>
        </>
    );
};

export default SaveChangesAction;
