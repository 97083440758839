import { omit } from 'lodash/fp';
import React from 'react';
import { updateCustomer, UpdateCustomerMutation, UpdateCustomerMutationVariables } from '../../../api/customer.graphql';
import {
    completeKyc,
    CompleteKycMutation,
    CompleteKycMutationVariables,
    completeConsents,
    CompleteConsentsMutation,
    CompleteConsentsMutationVariables,
    SaveDraftMutation,
    SaveDraftMutationVariables,
    saveDraft,
} from '../../../api/draft.graphql';
import { prepareForGraphQL } from '../../../utilities/forms';
import KYCRoute from '../../DraftFlow/components/KYC';
import { KYCStepValues } from '../../DraftFlow/steps/KYCStep';
import { getFinancingConsents, getNonFinancingConsents } from '../../DraftFlow/utils/consents';
import { FlowStep } from '../../utils/flow';
import { uploadFiles } from '../../utils/uploads';
import { RemoteFlowState } from '../RemoteFlow';

class KYCStep extends FlowStep<RemoteFlowState, KYCStepValues> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'kyc';
    }

    protected async execute({
        files,
        referenceId,
        draftTo,
        ...customerData
    }: KYCStepValues): Promise<FlowStep<RemoteFlowState> | null> {
        const { apolloClient } = this;
        const { token, application } = this.state;

        // upload files
        await uploadFiles(
            apolloClient,
            [...files, ...application.attachments],
            application.attachments,
            null,
            application.id,
            token
        );

        // update the customer
        const { id: customerId, type, ...data } = customerData;
        await apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
            mutation: updateCustomer,
            variables: { id: customerId, data: prepareForGraphQL(omit(['details.vehicles'], data)), token },
        });

        // complete consents
        await apolloClient.mutate<CompleteConsentsMutation, CompleteConsentsMutationVariables>({
            mutation: completeConsents,
            variables: { token, eventId: referenceId },
        });

        // check if application is saved as draft
        if (draftTo) {
            await apolloClient.mutate<SaveDraftMutation, SaveDraftMutationVariables>({
                mutation: saveDraft,
                variables: { token, assigneeId: draftTo },
            });

            // trigger events
            this.flow.dispatchCompleted();

            // no next step from here
            return null;
        }

        // then complete KYC
        const apiResponse = await apolloClient.mutate<CompleteKycMutation, CompleteKycMutationVariables>({
            mutation: completeKyc,
            variables: { token },
        });

        // update state
        this.stateStore.update({ ...apiResponse.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { bank, country, application, withMyInfoError, channel, consents: allConsents } = this.state;

        const consents = application?.appliedForFinancing
            ? getFinancingConsents(allConsents, channel)
            : getNonFinancingConsents(allConsents, channel);

        return (
            <KYCRoute
                application={application}
                backStep={this.getBackContext()}
                bank={bank}
                consents={consents}
                country={country}
                initialValues={{ ...application?.customer, files: application.attachments }}
                isLastStep={this.isLastStep}
                onSubmit={this.submit}
                withMyInfoError={withMyInfoError}
            />
        );
    }
}

export default KYCStep;
