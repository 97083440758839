import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../selectors';
import { formatDeliveryPrice } from '../Configurator/shared/RadioField';
import { SubTitle } from '../Configurator/shared/ui';
import { Row, SectionContainer } from './shared/ui';

type DeliverySectionProps = {
    formatCurrency: (price: number) => string;
    deliveryMethod: string;
};

const DeliverySection = ({ formatCurrency, deliveryMethod }: DeliverySectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const delivery = useMemo(
        () =>
            ckdConfiguration.delivery.options.find(
                (item: { name: string; label: string; price: number }) => deliveryMethod === item.name
            ),
        [deliveryMethod, ckdConfiguration.delivery.options]
    );

    return (
        <SectionContainer>
            <SubTitle>{ckdConfiguration.summary.delivery}</SubTitle>
            <Row>
                <span>{delivery.label}</span>
                <span>
                    {formatDeliveryPrice(
                        delivery.price,
                        delivery.label,
                        ckdConfiguration.delivery.options,
                        formatCurrency
                    )}
                </span>
            </Row>
        </SectionContainer>
    );
};

export default DeliverySection;
