import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../actions/context';
import { getUser as getStateUser } from '../../selectors';
import { UserDataFragment } from '../routes/LoginRoute/api.graphql';
import { getUser, GetUserQuery } from './useLoadUser.graphql';

export type User = UserDataFragment;

const useLoadUser = (): [boolean, User | null] => {
    const { data, loading } = useQuery<GetUserQuery>(getUser, { fetchPolicy: 'cache-and-network' });
    const userFromState = useSelector(getStateUser);
    const user = data?.user || userFromState;

    // update state
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setUser(user));
    }, [dispatch, user]);

    return [loading, user];
};

export default useLoadUser;
