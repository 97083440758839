import * as account from '../api/account';
import { getCompanyIdentifier, getIsAuthenticated } from '../selectors';
import { getCompanyLoginUrl, getLocationCode, getLoginUrl } from '../utilities/urls';
import { unsetAuthorization } from './authorization';
import { setUser } from './context';

export const clearPersistedSessions = () => {
    Object.keys(sessionStorage).forEach(key => {
        const isSession = ['draftFlow', 'resumeFlow'].some(prefix => key.startsWith(prefix));

        if (isSession) {
            sessionStorage.removeItem(key);
        }
    });
};

/* actions to perform whenever the user is being logged out */
export const logout = (from: string | null = null, noRedirection = false) => async (
    dispatch,
    getState,
    { history, client }
) => {
    const wasAuthenticated = getIsAuthenticated(getState());
    const historyState = { from: from || (wasAuthenticated && 'expired') || null };

    // call logout api from manual
    if (from === 'manual') {
        await account.logout(client);
    }

    // redirect to the company login route
    const { companyCode, countryCode, zoneCode } = getCompanyIdentifier(getState());

    // we need to reset a few things in the redux store
    dispatch(unsetAuthorization());
    dispatch(setUser(null));

    // clean persisted sessions
    clearPersistedSessions();

    if (noRedirection) {
        return;
    }

    if (companyCode && countryCode && zoneCode) {
        // redirect to brand login
        const locationCode = getLocationCode(countryCode, zoneCode);
        history.push(getCompanyLoginUrl(companyCode, locationCode), historyState);
    } else {
        // redirect to general login
        history.push(getLoginUrl(), historyState);
    }
};
