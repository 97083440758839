// @ts-ignore
import { Actions } from '@appvantageasia/afc-ui';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Navigation from '../../../../components/routes/NewApplicationRoutes/shared/Navigation';
import { Container } from '../../../../components/ui/calculator';
import useValidation from '../../../../components/utilities/useValidation';
import { ApplicationFlowSource, Channel, ChannelSetting } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import { TransactionStatus } from '../../../../utilities/constants/transactionStatus';
import DepositConsentForm, { schema } from '../../../EventFlow/components/ConsentDeposit/ConsentForm';
import { PageTitle, Title } from '../../../EventFlow/components/DraftPage/ui';
import { BackStepContext } from '../../../utils/flow';
import { Application } from '../../types';
import { Buttons, PrimaryButton } from '../Calculator/ui';
import { PaymentContainer } from '../Deposit';
import PaymentAmount from '../Deposit/PaymentAmount';
import PaymentSuccess from '../Deposit/PaymentSuccess';
import usePaymentConsents from '../Deposit/hooks/usePaymentConsents';
import PaymentWidget from './PaymentWidget';
import usePorschePayment from './usePorschePayment';

export type PageProps = {
    application: Application;
    token: string;
    dealerId: string;
    channel?: Channel;
    onSubmit: (values?: any) => void;
    backStep?: BackStepContext;
    isLastStep: boolean;
    source: ApplicationFlowSource;
};

export const SubmittedState = [TransactionStatus.CAPTURED, TransactionStatus.CAPTURE_REQUEST_SENT];

const Deposit = ({
    onSubmit,
    backStep,
    isLastStep,
    application,
    token,
    dealerId,
    source,
    channel = Channel.NEW,
}: PageProps) => {
    const { t } = useTranslation();
    const [consentGiven, setConsentGiven] = useState(false);
    const [referenceId, setReferenceId] = useState<string>();

    const {
        config,
        status,
        onSubmit: submitPayment,
        setPaymentData,
        hasPaymentData,
        paymentMethods,
    } = usePorschePayment(application.version.id, application.channel, source, token);
    const isSuccessful = application.steps?.payment || (status && SubmittedState.includes(status));

    useEffect(() => {
        if (isSuccessful) {
            onSubmit();
        }
    }, [isSuccessful, onSubmit]);

    const country = useSelector(getCurrentCountry);

    const { consents, loading: consentsLoading } = usePaymentConsents(dealerId);

    const validate = useValidation(schema(consents));

    const hasDeposit = country?.channelSetting[channel.toLowerCase() as keyof ChannelSetting];
    const hasConsents = hasDeposit ? !!consents?.length : false;

    const disabled = (hasConsents && !consentGiven) || !hasPaymentData;

    if (consentsLoading) {
        return null;
    }

    return (
        <PaymentContainer>
            {backStep && <Navigation onPrev={backStep.goTo} prevText={backStep.label} />}
            <PageTitle>{t('depositPage.title')}</PageTitle>
            <div className="row">
                {hasConsents && (
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <Container>
                            <Title>{t('eventConsentDepositPage.paymentConsentTitle')}</Title>
                            <DepositConsentForm
                                consents={consents}
                                referenceId={referenceId}
                                setReferenceId={setReferenceId}
                                setValid={setConsentGiven}
                                showNextButton={!hasDeposit}
                                token={token}
                                validate={validate}
                            />
                        </Container>
                    </div>
                )}
                <div className={hasConsents ? 'col-md-6 col-sm-12 col-xs-12' : 'col-md-12 col-sm-12 col-xs-12'}>
                    <Container>
                        <Title>{t('eventConsentDepositPage.depositTitle')}</Title>
                        <PaymentAmount channel={application.channel} />
                        {config && !isSuccessful && (
                            <>
                                <br />
                                <PaymentWidget config={config} onPaymentUpdate={setPaymentData} />
                                <Buttons>
                                    <PrimaryButton disabled={disabled} onClick={submitPayment}>
                                        <FontAwesomeIcon icon={faAngleRight} />{' '}
                                        {t('eventConsentDepositPage.button.pay')}
                                    </PrimaryButton>
                                </Buttons>
                            </>
                        )}
                        {isSuccessful && <PaymentSuccess isLastStep={isLastStep} />}
                    </Container>
                </div>
            </div>
        </PaymentContainer>
    );
};

export default Deposit;
