import { from, ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';
import { createBrowserHistory } from 'history';
import { mapValues } from 'lodash/fp';
import React, { PureComponent } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { compose } from 'redux';
import { initializeRuntimeSettings, setNotification, unsetGlobalError } from '../actions';
import { logout } from '../actions/shared';
import createStore from '../createStore';
import TranslationsProvider from '../i18n';
import { getAccessToken, getCompanyIdentifier } from '../selectors';
import { DELETE, GET, POST, PUT, withAuthorization, withGlobalErrors, withLogout } from '../utilities/httpMethods';
import AppRouter from './routers';
import FixedMenuController from './shared/FixedMenuController';
import HeaderController from './shared/HeaderController';
import LogoutOnInactivity from './shared/LogoutOnInactivity';
import LogoutOnOverride from './shared/LogoutOnOverride';
import MetaData from './shared/MetaData';
import Notification from './shared/Notification';
import RefreshAuthorization from './shared/RefreshAuthorization';
import RootErrorBoundary from './shared/errors/RootErrorBoundary';
import ModalProvider from './shared/modal/ModalProvider';
import GlobalStyle from './ui/GlobalStyle';
import LoadingLayer from './ui/LoadingLayer';
import HttpProvider from './utilities/HttpProvider';
import ThemeProvider from './utilities/ThemeProvider';

class App extends PureComponent {
    constructor(props) {
        super(props);

        // first we create the history object
        this.history = createBrowserHistory();

        const authLink = new ApolloLink((operation, forward) => {
            operation.setContext(({ headers }) => {
                const token = getAccessToken(this.store.getState());

                if (!token) {
                    return { headers };
                }

                return {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        ...headers,
                    },
                };
            });

            return forward(operation);
        });

        const errorHandlingLink = onError(({ graphQLErrors }) => {
            if (graphQLErrors) {
                for (const err of graphQLErrors) {
                    switch (err.extensions.code) {
                        case 'UNAUTHENTICATED':
                            this.store.dispatch(logout());
                            break;

                        case 'GRAPHQL_VALIDATION_FAILED':
                            this.store.dispatch(setNotification('Error', err.message));
                            break;

                        default:
                            break;
                    }
                }
            }
        });

        const httpLink = ApolloLink.split(
            operation => extractFiles(operation).files.size > 0,
            createUploadLink({ uri: '/graphql' }),
            new HttpLink({ uri: '/graphql' })
        );

        this.client = new ApolloClient({
            link: from([authLink, errorHandlingLink, httpLink]),
            cache: new InMemoryCache(),
            connectToDevTools: process.env.NODE_ENV === 'development',
        });

        // add methods to it
        this.history.pushWithCompany = this.pushWithCompany;
        this.history.pushWithCompanyAndState = this.pushWithCompanyAndState;

        // we are going to memoize an empty http method object
        this.httpMethods = {};

        // create the redux store
        this.store = createStore({
            history: this.history,
            httpMethods: this.httpMethods,
            client: this.client,
        });

        // then initialize the http methods
        this.initializeHttpMethods();

        // initialize runtime settings
        this.store.dispatch(initializeRuntimeSettings);

        this.history.listen(() => {
            // whenever th page change we may unset any global error we had
            this.store.dispatch(unsetGlobalError());
        });
    }

    pushWithCompanyAndState = (getUrl, state, ...args) => {
        const { companyCode, locationCode } = getCompanyIdentifier(this.store.getState());
        const url = getUrl(companyCode, locationCode, ...args);

        this.history.push(url, state);
    };

    pushWithCompany = (getUrl, ...args) => {
        this.pushWithCompanyAndState(getUrl, null, ...args);
    };

    initializeHttpMethods() {
        const { store, httpMethods } = this;

        // list methods
        const methods = { GET, POST, DELETE, PUT };

        // anonymous versions
        httpMethods.anonymous = { ...methods };

        // we are going to apply multiple middleware on authorized methods
        const composeAuthorizedMethods = compose(
            withAuthorization(store.getState),
            withLogout(store.dispatch),
            withGlobalErrors(store.dispatch)
        );

        // authorized versions (with bearer token)
        httpMethods.authorized = mapValues(composeAuthorizedMethods, methods);

        // give a direct access to methods as authorized
        Object.entries(httpMethods.authorized).forEach(([key, method]) => {
            httpMethods[key] = method;
        });
    }

    render() {
        const { store, httpMethods, history, client } = this;

        return (
            <ApolloProvider client={client}>
                <TranslationsProvider>
                    <StoreProvider store={store}>
                        <MetaData />
                        <HttpProvider value={httpMethods}>
                            <ThemeProvider>
                                <ModalProvider>
                                    <Router history={history}>
                                        <RootErrorBoundary>
                                            <GlobalStyle />
                                            <FixedMenuController>
                                                <HeaderController>
                                                    <LoadingLayer />
                                                    <Notification />
                                                    <LogoutOnInactivity />
                                                    <LogoutOnOverride />
                                                    <RefreshAuthorization />
                                                    <AppRouter />
                                                </HeaderController>
                                            </FixedMenuController>
                                        </RootErrorBoundary>
                                    </Router>
                                </ModalProvider>
                            </ThemeProvider>
                        </HttpProvider>
                    </StoreProvider>
                </TranslationsProvider>
            </ApolloProvider>
        );
    }
}

export default App;
