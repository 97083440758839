import { TFunction } from 'i18next';
import { pick, some, isEmpty, flow, negate, toNumber } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import NumberField from '../../../../../../components/shared/form-v2/NumberField';
import TextField from '../../../../../../components/shared/form-v2/TextField';
import { onNumKeyPress } from '../../../../../../utilities/forms';
import { Title } from '../../ui';

type TradeInVehicleFormProps = {
    disabled?: boolean;
};

const TradeInVehicleForm = ({ disabled = false }: TradeInVehicleFormProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('eventTradeInVehicle.title')}</Title>
            <TextField disabled={disabled} label={`${t('eventTradeInVehicle.label.brand')}*`} name="tradeIn.make" />
            <TextField disabled={disabled} label={`${t('eventTradeInVehicle.label.model')}*`} name="tradeIn.model" />
            <NumberField
                label={`${t('eventTradeInVehicle.label.modelYear')}*`}
                maxLength="4"
                name="tradeIn.year"
                normalize={toNumber}
                type="year"
            />
            <TextField
                disabled={disabled}
                label={`${t('eventTradeInVehicle.label.mileage')}*`}
                name="tradeIn.mileage"
                normalize={toNumber}
                onKeyPress={onNumKeyPress}
                placeholder={`${t('eventTradeInVehicle.placeholder.mileage')}*`}
            />
        </>
    );
};

export default TradeInVehicleForm;

export const checkHasTradeIn = flow([pick(['make', 'mileage', 'model', 'year']), some(negate(isEmpty))]);
export const vehicleSchema = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((values: any, options: any) => {
        const { context } = options;

        if (context.event?.setting.allowTradeIn && checkHasTradeIn(values)) {
            return yup.object().shape({
                make: yup.string().required(t('common.error.required')),
                mileage: yup.number().required(t('common.error.required')),
                model: yup.string().required(t('common.error.required')),
                year: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    });
