import { TFunction } from 'i18next';
import { get, isNil } from 'lodash/fp';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import NumberField from '../../../../../components/shared/form-v2/NumberField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import { yupExt } from '../../../../../utilities/forms';
import { Application } from '../../../types';
import { Title } from '../ui';

type GuarantedBuybackDetailsProps = {
    disabled?: boolean;
    application: Application;
    change: InjectedFormProps['change'];
};

const watchedFields = [
    'details.guaranteedBuyback.maxMileage',
    'details.guaranteedBuyback.excessMileageCharge',
    'details.guaranteedBuyback.vehicleModelYear',
];

const wrapWithAsterisk = (text: string) => `${text}*`;

const GuaranteedBuybackDetails = ({ disabled = false, application, change }: GuarantedBuybackDetailsProps) => {
    const { t } = useTranslation();
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const values = getValues();

    const vehicleModelYear = get(`${sectionPrefix}.details.guaranteedBuyback.vehicleModelYear.value`, values);
    const applicationVehicleModelYear = application?.variant?.year;

    // Prefill vehicle model year
    useEffect(() => {
        if (isNil(vehicleModelYear) && applicationVehicleModelYear) {
            change(`${sectionPrefix}.details.guaranteedBuyback.vehicleModelYear.value`, applicationVehicleModelYear);
        }
    }, [change, applicationVehicleModelYear, vehicleModelYear, sectionPrefix]);

    return (
        <div className="mt-5">
            <Title>{t('kycPage.guaranteedBuybackDetailsTitle')}</Title>
            <NumberField
                disabled={disabled || fieldState.details.guaranteedBuyback.maxMileage}
                label={wrapWithAsterisk(t('customerDetails.label.maxMileage'))}
                name="details.guaranteedBuyback.maxMileage.value"
                type="number"
            />
            <NumberField
                disabled={disabled || fieldState.details.guaranteedBuyback.excessMileageCharge}
                label={wrapWithAsterisk(t('customerDetails.label.excessMileageCharge'))}
                name="details.guaranteedBuyback.excessMileageCharge.value"
                type="currency"
            />
            <NumberField
                disabled={disabled || fieldState.details.guaranteedBuyback.vehicleModelYear}
                label={wrapWithAsterisk(t('customerDetails.label.vehicleModelYear'))}
                name="details.guaranteedBuyback.vehicleModelYear.value"
                type="year"
            />
        </div>
    );
};

export default GuaranteedBuybackDetails;

export const guaranteedBuybackSchema = (t: TFunction) => ({
    guaranteedBuyback: yupExt.customerProperty().shape({
        excessMileageCharge: yupExt.customerProperty().shape({
            value: yup.number().required(t('common.error.required')),
        }),

        maxMileage: yupExt.customerProperty().shape({
            value: yup.number().required(t('common.error.required')),
        }),

        vehicleModelYear: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),
    }),
});
