import React from 'react';
import { useSelector } from 'react-redux';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { ConfigFormValues } from './ConfigForm';
import { ImageContainer, OptionContainer, SelectionContainer } from './Exterior';
import OptionBoxField from './shared/OptionBoxField';
import { SectionTitle } from './shared/ui';
import useInteriorImage from './shared/useInteriorImage';
import noImg from '../../../../assets/images/no-image.png';

const Interior = () => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const interiorOptions = ckdConfiguration.interior.options;

    // get form values
    const values = useFormValues<ConfigFormValues>();
    const interiorImage = useInteriorImage(values?.exteriorColor, values?.interiorColor);

    return (
        <SelectionContainer>
            {isMobile && (
                <ImageContainer>
                    <ImgWithFallback alt={ckdConfiguration.interior.headline} fallbackSrc={noImg} src={interiorImage} />
                </ImageContainer>
            )}
            <div className="configurator">
                <OptionContainer>
                    <SectionTitle>{ckdConfiguration.interior.headline}</SectionTitle>
                    <div>
                        <OptionBoxField fieldName="interiorColor" name="interiorColor" options={interiorOptions} />
                    </div>
                    <span>
                        {interiorOptions.find((item: { name: string }) => item.name === values.interiorColor)?.label}
                    </span>
                </OptionContainer>
            </div>
        </SelectionContainer>
    );
};

export default Interior;
