import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useContext, useMemo, useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReduxFormContext, change } from 'redux-form';
import * as yup from 'yup';
import CountryField from '../../../../../components/shared/form-v2/CountryField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import usePhone from '../../../../../components/utilities/usePhone';
import { useCountry } from '../../../../../hookSelectors';
import useOptions from '../../../../../utilities/constants/useOptions';
import { fromContextValidation, onTelKeyPress, yupExt } from '../../../../../utilities/forms';
import { Buttons } from '../../Calculator/ui';
import { Title, TitlePlaceholder, ContactHint } from '../ui';

const watchedFields = [
    'email',
    'phone',
    {
        path: 'details.residentialAddress',
        properties: ['street', 'block', 'building', 'unit', 'city', 'postalCode', 'country'],
    },
];

type ContactFormProps = {
    disabled?: boolean;
    split?: boolean;
    submit?: ReactElement;
};

type SelectResidentialCityFieldProps = {
    disabled: boolean;
    label: string;
};

export const SelectResidentialCityField = ({ disabled, label }: SelectResidentialCityFieldProps) => {
    const { getValues, sectionPrefix, form } = useContext(ReduxFormContext);
    const dispatch = useDispatch();

    const values = getValues();

    const countryId = get(
        sectionPrefix ? `${sectionPrefix}.details.residentialAddress.country` : 'details.residentialAddress.country',
        values
    );
    const { countries = [], cities = [] } = useOptions();

    const selectedCountry = useMemo(() => countries.filter(({ value }) => value === countryId)[0]?.label, [
        countries,
        countryId,
    ]);
    const calculatedCityOptions = useMemo(
        () =>
            cities
                ?.filter(({ countryCode }) => countryCode === countryId)
                ?.map(({ name }) => ({ label: name, value: name })),
        [cities, countryId]
    );

    useEffect(() => {
        // when there is no city for that country
        // use the country as a city
        if (calculatedCityOptions.length < 1 && selectedCountry) {
            dispatch(
                change(
                    form,
                    sectionPrefix
                        ? `${sectionPrefix}.details.residentialAddress.city`
                        : 'details.residentialAddress.city',
                    selectedCountry
                )
            );
        }

        // if city options changes, pre-select the first one
        if (calculatedCityOptions.length >= 1) {
            dispatch(
                change(
                    form,
                    sectionPrefix
                        ? `${sectionPrefix}.details.residentialAddress.city`
                        : 'details.residentialAddress.city',
                    calculatedCityOptions[0].value
                )
            );
        }
    }, [form, calculatedCityOptions, dispatch, sectionPrefix, selectedCountry]);

    if (!calculatedCityOptions.length) {
        return null;
    }

    return (
        <SelectField.Outline
            disabled={disabled || calculatedCityOptions.length === 1}
            label={label}
            name="details.residentialAddress.city"
            options={calculatedCityOptions}
        />
    );
};

const ContactForm = ({ disabled = false, split = false, submit }: ContactFormProps) => {
    const { t } = useTranslation();
    const { code: phoneCountryCode, minDigits: minPhoneDigit, maxDigits: maxPhoneDigit } = usePhone();
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const addressDisabled = disabled || fieldState.details?.residentialAddress;
    const values = getValues();

    const withMyInfo = get(sectionPrefix ? `${sectionPrefix}.withMyInfo` : 'withMyInfo', values);

    const { code } = useCountry();

    const firstSection = (
        <>
            <Title>{t('eventContactDetails.title')}</Title>
            <TextField
                disabled={disabled || fieldState.email}
                label={t('eventContactDetails.label.email')}
                name="email.value"
                type="email"
            />
            <TextField
                disabled={disabled || fieldState.phone}
                label={t('eventContactDetails.label.phone')}
                maxLength={maxPhoneDigit}
                minLength={minPhoneDigit}
                name="phone.value"
                onKeyPress={onTelKeyPress}
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                type="tel"
            />
            {withMyInfo && <ContactHint>{t('eventContactDetails.tip')}</ContactHint>}
        </>
    );

    const secondSection = (
        <>
            {split ? <TitlePlaceholder /> : null}
            {values?.details?.residentialAddress?.countryName ? (
                <TextField
                    disabled={addressDisabled}
                    label={t('eventContactDetails.label.country')}
                    name="details.residentialAddress.countryName"
                />
            ) : (
                <CountryField
                    disabled={addressDisabled}
                    label={t('eventContactDetails.label.country')}
                    name="details.residentialAddress.country"
                />
            )}
            <SelectResidentialCityField disabled={addressDisabled} label={t('eventContactDetails.label.city')} />
            <TextField
                disabled={addressDisabled}
                label={t('eventContactDetails.label.postalCode')}
                name="details.residentialAddress.postalCode"
            />
            {code === 'TH' ? (
                <TextField
                    disabled={addressDisabled}
                    label={t('eventContactDetails.label.address')}
                    name="details.residentialAddress.fullAddress"
                />
            ) : (
                <TextField
                    disabled={addressDisabled}
                    label={t('eventContactDetails.label.street')}
                    name="details.residentialAddress.street"
                />
            )}
            <TextField
                disabled={addressDisabled}
                label={t('eventContactDetails.label.unit')}
                name="details.residentialAddress.unit"
            />
            {submit ? <Buttons>{submit}</Buttons> : null}
        </>
    );

    if (split) {
        return (
            <>
                <div>{firstSection}</div>
                <div>{secondSection}</div>
            </>
        );
    }

    return (
        <>
            {firstSection}
            {secondSection}
        </>
    );
};

export default ContactForm;

// remove error message for required in events
export const contactSchema = (t: TFunction) => ({
    email: yupExt.customerProperty().shape({
        value: yup.string().required(t('common.error.required')).email(t('common.error.email')),
    }),

    phone: yupExt.customerProperty().shape({
        value: fromContextValidation(
            yup.string().required(t('common.error.required')),
            'phonePattern',
            t('common.error.mobile')
        ),
    }),
});

export const addressSchema = (t: TFunction) => ({
    city: yup
        .string()
        .required(' ')
        .max(66, t('common.error.max', { max: 66 })),
    country: yup.string().required(t('common.error.required')),
    postalCode: yup
        .string()
        .required(t('common.error.required'))
        .max(6, t('common.error.max', { max: 6 })),
    // @ts-ignore
    street: yup.lazy((value: String, { context }: any) => {
        const countryCode = get('countryCode', context);

        if (countryCode === 'TH') {
            return yup
                .string()
                .notRequired()
                .max(66, t('common.error.max', { max: 66 }));
        }

        return yup
            .string()
            .required(t('common.error.required'))
            .max(66, t('common.error.max', { max: 66 }));
    }),
});
