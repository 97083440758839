import { formatDateTimeWithOffset } from './dates';

/* map profile object into an profile state */
// eslint-disable-next-line import/prefer-default-export
export const mapProfileToState = (profile, dialingCodes, countryCode, zone = null, format = null) => {
    const defaultDialingCode = dialingCodes?.find(item => item.countryCode === countryCode);

    return {
        ...profile,
        phonePrefix: profile.phonePrefix || defaultDialingCode?.value,
        lastModified: `${formatDateTimeWithOffset(zone, format)(profile.version?.updatedAt)} ${
            profile.version?.updatedBy?.username ? `by ${profile.version?.updatedBy?.username}` : ''
        }`,
    };
};
