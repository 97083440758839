// @ts-ignore
import { DarkButton } from '@appvantageasia/afc-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useCountry } from '../../hookSelectors';
import Header from '../shared/Header';
import { Container, Title, Content } from '../ui/GenericPage';
import useHomeUrl from '../utilities/useHomeUrl';

const TimeoutRoute = () => {
    const { t } = useTranslation();
    const { url, state } = useLocation<{ url: string; state: {} }>().state || {};
    const { url: homeUrl } = useHomeUrl() || {};
    const history = useHistory();
    const country = useCountry();

    const back = useCallback(() => {
        if (url) {
            history.push(url, state);

            return;
        }

        if (homeUrl) {
            // @ts-ignore
            history.pushWithCompany(homeUrl);
        }
    }, [history, homeUrl, state, url]);

    return (
        <>
            <Header />
            <Container>
                <Title>{t('timeoutPage.title')}</Title>
                <Content>{t('timeoutPage.content', { minutes: country.sessionTimeout })}</Content>
                <DarkButton onClick={back}>{t('timeoutPage.button.back')}</DarkButton>
            </Container>
        </>
    );
};

export default TimeoutRoute;
