import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import styled, { css } from 'styled-components';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import { ConfiguratorFlowState } from '../../ConfiguratorFlow';
import { MiniConfiguratorDetails, SummaryFormProps } from '../Summary/Summary';
import TermsAndConditionsModal from '../Summary/TermsAndConditionsModal';
import Banner from './Banner';
import Disclaimer from './Disclaimer';
import FooterDrawer from './FooterDrawer';
import Header from './Header';
import IntroPage from './IntroPage';
import { TequipmentMaterials } from './Tequipment';
import { TimepieceValues } from './Timepiece';
import ViewContainer from './ViewContainer';
import PackageModal from './shared/PackageModal';
import TimepieceModal from './shared/TimepieceModal';
import useCalculatorValues from './shared/useCalculatorValues';

export const PageContainer = styled.div<{ isBlur: boolean }>`
    margin-bottom: 0;
    ${props =>
        props.isBlur &&
        css`
            .pageWrapper {
                filter: blur(15px);
                -webkit-filter: blur(15px);
            }
        `}
`;

export type ConfigFormProps = {
    setCalculatorValues: (values: Partial<CalculatorValues>) => void;
    initialValues: Omit<ConfiguratorFlowState, 'isResumeOrder'>;
};

export type ConfigFormValues = {
    tequipment?: TequipmentMaterials;
    timepiece?: TimepieceValues;
    exteriorColor: string;
    interiorColor: string;
    delivery: string;
    enhancedPackage: boolean;
};

const ConfigForm = ({
    setCalculatorValues,
    initialValues,
    handleSubmit,
}: ConfigFormProps & InjectedFormProps<ConfigFormValues, ConfigFormProps>) => {
    const [displayPackage, setDisplayPackage] = useState(false);
    const [displayTimepiece, setDisplayTimepiece] = useState(false);
    const [displayTimepieceTerms, setDisplayTimepieceTerms] = useState(false);
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const [showModal, setShowModal] = useState(false);
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    const [displaySummaryTerms, setDisplaySummaryTerms] = useState(false);

    // get form values
    const {
        defaultFinanceProduct,
        timepiece,
        tequipment,
        exteriorColor,
        interiorColor,
        delivery,
        enhancedPackage,
    } = useFormValues<MiniConfiguratorDetails & SummaryFormProps>();

    const values = {
        tequipment,
        exteriorColor,
        interiorColor,
        delivery,
        enhancedPackage,
    };

    const calculatorValues = useCalculatorValues(defaultFinanceProduct, values);
    const onClose = useCallback(() => setIsBannerVisible(false), [setIsBannerVisible]);
    const [scrollBarWidth, setScrollBarWidth] = useState(0);

    useEffect(() => {
        setCalculatorValues(calculatorValues);
    }, [calculatorValues, setCalculatorValues]);

    const isBlur = displayPackage || displayTimepiece || displayTimepieceTerms || showModal;

    useEffect(() => {
        !isBlur ? (document.body.style.overflow = 'unset') : (document.body.style.overflow = 'hidden');
        setScrollBarWidth(window.innerWidth - document.documentElement.clientWidth);
    }, [isBlur, showModal]);

    return (
        <PageContainer isBlur={isBlur}>
            <div className="pageWrapper">
                <Header />
                {isBannerVisible && <Banner onClose={onClose} />}
                <IntroPage />
                <ViewContainer
                    calculatorValues={calculatorValues}
                    onSubmit={handleSubmit}
                    scrollBarWidth={scrollBarWidth}
                    setDisplayPackage={setDisplayPackage}
                    setDisplayTerms={setDisplayTimepieceTerms}
                    setDisplayTimepiece={setDisplayTimepiece}
                    setShowModal={setShowModal}
                />
                <Disclaimer />
            </div>
            {displayPackage && (
                <PackageModal options={ckdConfiguration.enhancedPackage.popUps} setDisplayPackage={setDisplayPackage} />
            )}
            {displayTimepiece && timepiece && (
                <TimepieceModal
                    options={ckdConfiguration.timepiece.options}
                    setDisplayTimepiece={setDisplayTimepiece}
                    timepiece={timepiece}
                />
            )}
            {displayTimepieceTerms && (
                <TermsAndConditionsModal
                    body={ckdConfiguration.timepiece.termsAndConditions.body}
                    headline={ckdConfiguration.timepiece.termsAndConditions.headline}
                    setDisplayTerms={setDisplayTimepieceTerms}
                />
            )}
            {showModal && <FooterDrawer setDisplaySummaryTerms={setDisplaySummaryTerms} setShowModal={setShowModal} />}
            {displaySummaryTerms && (
                <TermsAndConditionsModal
                    body={ckdConfiguration.summary.termsAndConditions.body}
                    headline={ckdConfiguration.summary.termsAndConditions.headline}
                    setDisplayTerms={setDisplaySummaryTerms}
                />
            )}
        </PageContainer>
    );
};

export default reduxForm<ConfigFormValues, ConfigFormProps>({})(ConfigForm);
