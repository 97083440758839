import { useApolloClient } from '@apollo/client';
import { VariantOption } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Actions, DarkButton, Modal } from '@appvantageasia/afc-ui';
import { uniqBy, get, last } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { attachLoading, setNotification } from '../../actions';
import {
    shareFinanceContexts,
    ShareFinanceContextsMutation,
    ShareFinanceContextsMutationVariables,
} from '../../api/application.graphql';
import { createCustomer, CreateCustomerMutation, CreateCustomerMutationVariables } from '../../api/customer.graphql';
import {
    getFinanceProducts,
    GetFinanceProductsQuery,
    GetFinanceProductsQueryVariables,
} from '../../components/data/useFinanceProducts.graphql';
import {
    getVariantsByDealerId,
    GetVariantsByDealerIdQuery,
    GetVariantsByDealerIdQueryVariables,
} from '../../components/data/useLoadVariants.graphql';
import { CalculatorValues } from '../../components/shared/calculator-next/types';
import TextField from '../../components/shared/form/TextField';
import useCustomerNamesSynchronization from '../../components/shared/useCustomerNamesSynchronization';
import BoxedGrid from '../../components/ui/form/BoxedGrid';
import useCustomerSource from '../../components/utilities/useCustomerSource';
import { useZone } from '../../hookSelectors';
import { Channel, CustomerDetailsStringValuePayload, CustomerType } from '../../schema';
import { getCalculatorPayload } from '../../utilities/calculator';
import { handleResponseError } from '../../utilities/forms';

const watchedFields = ['name', 'firstName', 'lastName', 'email'];

export type ShareFormValues = {
    name: CustomerDetailsStringValuePayload;
    firstName: CustomerDetailsStringValuePayload;
    lastName: CustomerDetailsStringValuePayload;
    email: CustomerDetailsStringValuePayload;
};

export type ShareFormProps = {
    onClose: () => void;
    calculators: CalculatorValues[];
    channel: Channel;
    dealerId: string;
};

const extname = (filename: string): string => {
    const suffix = last(filename.split('.'));
    if (suffix?.length) {
        return `.${suffix}`;
    }

    return '';
};

const ShareModal = styled(Modal)`
    z-index: 10000;
`;

const ShareForm = ({
    onClose,
    calculators,
    handleSubmit,
    channel,
    dealerId,
    change,
    submitting,
}: ShareFormProps & InjectedFormProps<ShareFormValues, ShareFormProps>) => {
    const { t, i18n } = useTranslation();
    const client = useApolloClient();
    const { id: zoneId } = useZone();
    const { dispatch }: { dispatch: ThunkDispatch<any, any, any> } = useStore();
    const { onNormalizeFullName } = useCustomerNamesSynchronization();

    const onShare = useCallback(
        event => {
            return handleSubmit(async values => {
                // get variants
                const variants =
                    (
                        await client.query<GetVariantsByDealerIdQuery, GetVariantsByDealerIdQueryVariables>({
                            query: getVariantsByDealerId,
                            variables: { zoneId, dealerId, channel },
                            fetchPolicy: 'cache-first',
                        })
                    ).data?.variants || [];

                const execute = async () => {
                    // first, create customer,
                    const customer = await client.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
                        mutation: createCustomer,
                        variables: {
                            data: {
                                ...values,
                                zoneId,
                                withMyInfo: false,
                                type: CustomerType.INDIVIDUAL,
                            },
                        },
                    });

                    const customerId = customer.data?.customer?.id;

                    if (!customerId) {
                        throw new Error('Missing customer ID');
                    }

                    // todo: remove this call, this should be done instead on BE
                    // get finance products
                    const financeProducts = dealerId
                        ? (
                              await client.query<GetFinanceProductsQuery, GetFinanceProductsQueryVariables>({
                                  query: getFinanceProducts,
                                  variables: { dealerId, channel },
                                  fetchPolicy: 'cache-first',
                              })
                          ).data?.financeProducts || []
                        : [];

                    // define if there's multiple makes
                    const hasMakes = uniqBy(get('model.makeId'), variants).length > 1;

                    return client.mutate<ShareFinanceContextsMutation, ShareFinanceContextsMutationVariables>({
                        mutation: shareFinanceContexts,
                        variables: {
                            customerId,
                            dealerId,
                            zoneId,
                            shouldCreateLead: true,
                            channel,
                            locale: i18n.languages[0],
                            data: calculators.map((calculator): ShareFinanceContextsMutationVariables['data'][0] => {
                                const variant = variants.find(item => item.id === calculator.variant);

                                const carOptions = calculator?.getFieldContext?.('carOptions');
                                const options = carOptions?.selectedOptions
                                    ? Object.values<VariantOption>(carOptions?.selectedOptions) || []
                                    : [];

                                if (!variant) {
                                    throw new Error('Variant not found');
                                }

                                const financeProduct = financeProducts.find(
                                    item => item.id === calculator.financeProduct
                                );

                                if (!financeProduct) {
                                    throw new Error('Finance product not found');
                                }

                                const bank = calculator.getFieldContext && calculator.getFieldContext('bank');

                                return {
                                    financeProductId: calculator.financeProduct,
                                    makeId: hasMakes ? variant.model.make.id : undefined,
                                    bankId: bank && bank.availableBanks.length > 1 ? calculator.bank : undefined,
                                    variant: { id: calculator.variant },
                                    optionGroups: options.map(option => ({
                                        id: option.groupId || '',
                                        options: [
                                            {
                                                id: option.id,
                                                selected: true,
                                                image: option.image?.url,
                                            },
                                        ],
                                    })),
                                    unitId: calculator.unitId,
                                    calculator: getCalculatorPayload(calculator),
                                };
                            }),
                        },
                    });
                };

                try {
                    // share the calculators
                    await dispatch(attachLoading(execute()));

                    // and display the notification
                    const { title, message }: { title: string; message: string } = t('notification.share', {
                        returnObjects: true,
                    });
                    dispatch(setNotification(title, message));
                    onClose();

                    return null;
                } catch (error) {
                    return handleResponseError(error as Error);
                }
            })(event);
        },
        [handleSubmit, client, zoneId, channel, i18n.languages, calculators, dispatch, t, dealerId, onClose]
    );

    useCustomerSource(watchedFields);

    return (
        <ShareModal onClose={onClose} title={t('shareModal.title')} showClose showTitle>
            <BoxedGrid>
                <TextField.FullWidth
                    label={t('shareModal.label.name')}
                    name="name.value"
                    normalize={onNormalizeFullName}
                    autoFocus
                />
                <TextField.FullWidth label={t('shareModal.label.email')} name="email.value" type="email" />
            </BoxedGrid>
            <Actions>
                <DarkButton disabled={submitting} onClick={onShare}>
                    {t('shareModal.button.send')}
                </DarkButton>
            </Actions>
        </ShareModal>
    );
};

export default reduxForm<ShareFormValues, ShareFormProps>({
    form: 'shareForm',
})(ShareForm);
