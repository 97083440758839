import React from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler } from 'redux-form';
import styled from 'styled-components';
import useMedia from 'use-media';
import ImgWithFallback from '../../../../components/shared/ImgWithFallback';
import useFormValues from '../../../../components/utilities/useFormValues';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { ConfigFormValues, ConfigFormProps } from './ConfigForm';
import { ImageContainer, SelectionContainer } from './Exterior';
import { Button } from './shared/AddField';
import RadioField from './shared/RadioField';
import { SectionTitle } from './shared/ui';
import useDeliveryImage from './shared/useDeliveryImage';
import noImg from '../../../../assets/images/no-image.png';

const DeliveryContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    .estimatedDelivery {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
`;

const ProceedButton = styled(Button)`
    width: 300px;
    align-self: center;
`;

const RadioFieldContainer = styled.div`
    align-self: center;
    width: 80%;
    max-width: 400px;
    min-width: 320px;
    margin-top: 20px;
`;

type DeliveryProps = {
    onSubmit: SubmitHandler<ConfigFormValues, ConfigFormProps, string>;
};

const Delivery = ({ onSubmit }: DeliveryProps) => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { delivery } = ckdConfiguration;
    const { options: deliveryOptions } = delivery;

    // get form values
    const values = useFormValues<ConfigFormValues>();
    const deliveryImage = useDeliveryImage(values?.delivery);

    return (
        <SelectionContainer>
            {isMobile && (
                <ImageContainer>
                    <ImgWithFallback alt="Delivery" fallbackSrc={noImg} src={deliveryImage} />
                </ImageContainer>
            )}
            <div className="configurator">
                <DeliveryContainer>
                    <SectionTitle>{delivery.headline}</SectionTitle>
                    <RadioFieldContainer>
                        <RadioField name="delivery" options={deliveryOptions} />
                    </RadioFieldContainer>
                    {!!delivery.estimatedDelivery && (
                        <span className="estimatedDelivery">{delivery.estimatedDelivery}</span>
                    )}
                    <ProceedButton
                        backgroundColor="#737278"
                        disabled={values.delivery === ''}
                        onClick={onSubmit}
                        selected={values.delivery !== ''}
                    >
                        {delivery.button}
                    </ProceedButton>
                </DeliveryContainer>
            </div>
        </SelectionContainer>
    );
};

export default Delivery;
