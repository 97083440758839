import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import * as yup from 'yup';
import {
    saveCustomerOrder,
    SaveCustomerOrderMutation,
    SaveCustomerOrderMutationVariables,
} from '../../../../api/configurator.graphql';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../components/shared/form-v2/TextField';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { useCountry } from '../../../../hookSelectors';
import { getRuntimeSettings } from '../../../../selectors';
import { Button } from '../Configurator/shared/AddField';
import { ModalContainer, ModalWrapper, CrossContainer, ContentContainer } from '../Configurator/shared/PackageModal';
import { SubTitle } from '../Configurator/shared/ui';
import useCalculatorValues from '../Configurator/shared/useCalculatorValues';
import { MiniConfiguratorDetails, SummaryFormValues } from './Summary';
import getCleanCKDValues from './shared/getCleanCKDValues';
import close from '../Configurator/assets/close.png';

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 100%;
    height: 100%;
    background-color: white;
    .textWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 10px 0;
            span {
                width: 50%;
                text-align: left;
            }
        }
        span {
            margin-top: 20px;
            align-self: center;
        }
        input {
            text-align: start;
        }
    }
`;

const EmailButton = styled(Button)`
    width: 80% !important;
    margin-top: 20px;
    align-self: center;
`;

type EmailPopupProps = {
    setButton: Dispatch<SetStateAction<boolean>>;
    data: MiniConfiguratorDetails;
    dealerOptions: { label: string; value: string }[];
    defaultFinanceProduct: FinanceDataFragment;
    events: EventDataFragment[];
};

const schema = yup.object().shape({
    dealerId: yup.string().required(),
    email: yup.string().required().email(),
});

const EmailPopup = ({ setButton, data, dealerOptions, defaultFinanceProduct, events }: EmailPopupProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const [emailSent, setEmailSent] = useState(false);
    const client = useApolloClient();
    const { id: countryId, googleTagManager } = useCountry();
    const { formatCurrency } = useCompanyFormatting();
    const { dealerId, promo } = (useSelector(getFormValues('summary')) || {}) as SummaryFormValues;
    const calculatorValues = useCalculatorValues(defaultFinanceProduct, data, promo);

    const event = useMemo(() => events.find(({ dealers }) => dealers.find(({ id }) => id === dealerId)), [
        dealerId,
        events,
    ]);

    const dealerName = useMemo(() => dealerOptions.find(dealer => dealer.value === dealerId)?.label, [
        dealerId,
        dealerOptions,
    ]);

    // Prepare for Google Tag Manager Data Layer Push
    const googleTagManagerId = googleTagManager?.id;

    const tagManagerArgs = useMemo(() => {
        const cleanCKDValues = getCleanCKDValues({
            ckdConfiguration,
            exterior: data.exteriorColor,
            interior: data.interiorColor,
            isEnhancedPackageSeleted: data.enhancedPackage,
            tEquipment: data.tequipment,
            isTimepieceSelected: !!data.timepiece?.isAdded,
            deliveryMethod: data.delivery,
        });

        return {
            dataLayer: {
                event: 'SaveYourOrderButtonClick',
                dealer: dealerName,
                details: {
                    miniConfiguratorDetails: cleanCKDValues,
                    promoCode: calculatorValues.promoCode,
                    carPrice: formatCurrency(calculatorValues.totalPrice),
                    monthlyPayment: calculatorValues.monthlyInstalments
                        ? formatCurrency(calculatorValues.monthlyInstalments[0].amount)
                        : undefined,
                    eventName: event?.name,
                },
            },
            dataLayerName: 'PageDataLayer',
        };
    }, [calculatorValues, ckdConfiguration, data, dealerName, event, formatCurrency]);

    // get form values
    const values = useSelector(getFormValues('summary')) as SummaryFormValues;
    const isValid = schema.isValidSync(values);

    const sendEmail = useCallback(() => {
        if (isValid) {
            if (googleTagManagerId) {
                TagManager.dataLayer(tagManagerArgs);
            }

            client.mutate<SaveCustomerOrderMutation, SaveCustomerOrderMutationVariables>({
                mutation: saveCustomerOrder,
                variables: {
                    countryId,
                    email: values.email,
                    dealerId: values.dealerId,
                    // omit becomes partial :/
                    data: omit(['variantId', 'timepiece.isAdded'], data) as SaveCustomerOrderMutationVariables['data'],
                },
            });
            setEmailSent(true);
        }
    }, [googleTagManagerId, client, countryId, data, isValid, tagManagerArgs, values.dealerId, values.email]);

    const onClose = useCallback(() => {
        setButton(false);
    }, [setButton]);

    return (
        <ModalContainer>
            <ModalWrapper width="400px">
                <ContentContainer>
                    <CrossContainer marginLeft="350px" onClick={onClose}>
                        <img alt="cross" src={close} />
                    </CrossContainer>
                    <TextContainer>
                        <div className="textWrapper">
                            <SubTitle>{ckdConfiguration.summary.emailPopup.headline}</SubTitle>
                            <div className="body">
                                <p>{ckdConfiguration.summary.emailPopup.body}</p>
                            </div>
                            <SelectField.Outline
                                label={ckdConfiguration.summary.preferredDealership}
                                name="dealerId"
                                options={dealerOptions}
                                placeholder="Select"
                            />
                            <TextField name="email" placeholder="email@example.com" type="email" />
                            {emailSent ? (
                                <span>{ckdConfiguration.summary.emailPopup.message}</span>
                            ) : (
                                <EmailButton backgroundColor="#737278" onClick={sendEmail} selected>
                                    {ckdConfiguration.summary.emailPopup.button}
                                </EmailButton>
                            )}
                        </div>
                    </TextContainer>
                </ContentContainer>
            </ModalWrapper>
        </ModalContainer>
    );
};

export default EmailPopup;
