import React from 'react';
import { RemoteFlow } from '../../../../schema';
import RemoteBoostrap from '../RemoteRoute/RemoteBoostrap';
import RemoteData from '../RemoteRoute/RemoteData';
import GuarantorShield from './GuanratorShield';
import GuarantorHandler from './GuarantorHandler';

const RemoteRoute = () => (
    <RemoteData flow={RemoteFlow.GUARANTOR}>
        {context => (
            <RemoteBoostrap context={context} handlerComponent={GuarantorHandler} shieldComponent={GuarantorShield} />
        )}
    </RemoteData>
);

export default RemoteRoute;
