import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../DraftPage/ui';
import DrivingLicense from '../shared/DrivingLicense';

const DrivingLicenseForm = () => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('eventDrivingLicense.title')}</Title>
            <DrivingLicense />
        </>
    );
};

export default DrivingLicenseForm;
