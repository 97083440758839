import React, { useMemo } from 'react';
import { EventExternalSite } from '../../../../schema';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import EventForm from './EventForm';
import { EventData } from './index';

export type EventDetailsProps = {
    event: EventData;
};

const EventDetails = ({ event }: EventDetailsProps) => {
    const formatDateTime = useFormatDateTime();
    const initialValues = useMemo(() => {
        if (!event) {
            return null;
        }

        return {
            ...event,
            status: event.isActive ? 'Active' : 'Non Active',
            lastModified: getLastModified(event.version, formatDateTime),
            setting: {
                ...event.setting,
                allowFinder: event.setting.externalSite === EventExternalSite.PORSCHEFINDER,
                enablePriceDisclaimer: !!event.setting.priceDisclaimers?.length,
                priceDisclaimers: event.setting.priceDisclaimers || [],
                isReservationInstructionEnabled: !!event.setting.reservationInstruction,
                reservationInstruction: event.setting.reservationInstruction || null,
            },
        };
    }, [event, formatDateTime]);

    return <EventForm initialValues={initialValues} />;
};

export default EventDetails;
