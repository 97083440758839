import styled from 'styled-components';

const CalculatorFooter = styled.p<{ maxWidth?: string }>`
    color: #888888;
    text-align: center;
    font-size: 0.9rem;
    margin: 8px auto;
    line-height: 1.6;
    max-width: ${props => props.maxWidth || '450px'};
`;

export default CalculatorFooter;
