import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import TranslationsProvider from '../../i18n';
import { getCurrentCountry } from '../../selectors';
import { MaintenanceDataFragment } from '../data/useLoadScope.graphql';
import RedirectRoute from '../routes/RedirectRoute';
import GuarantorRoute from '../routes/wip/GuanratorRoute';
import InsuranceRemoteRoute from '../routes/wip/InsuranceRemoteRoute';
import RemoteRoute from '../routes/wip/RemoteRoute';
import HelmetTitle from '../shared/HelmetTitle';
import MaintenanceWatcher from '../shared/MaintenanceWatcher';
import withCompanyProvider from '../utilities/withCompanyProvider';
import InternalRouter from './InternalRouter';
import MaintenanceRouter from './MaintenanceRouter';
import { prefixCompanyPath as prefixPath } from './shared';

const CompanyRouter = () => {
    // get the country at first
    const currentCountry = useSelector(getCurrentCountry);

    const googleTagManagerId = currentCountry?.googleTagManager?.id;

    if (googleTagManagerId) {
        /* we can set and enable other optional values that are commented if needed */
        const tagManagerArgs = {
            /* GTM id, must be something like GTM-000000 (required) */
            gtmId: googleTagManagerId,

            /* Object that contains all of the information that we want to pass to Google Tag Manager. (optional) */
            dataLayer: {},

            /* Custom name for dataLayer object. (optional) */
            dataLayerName: 'PageDataLayer',

            /* Additional events such as 'gtm.start': new Date().getTime(),event:'gtm.js' (optional) */
            // events: {},

            /* Use to set environments. (optional) */
            // auth: '',

            /* Use to set environments, something like env-00. (optional) */
            // preview: '',
        };

        TagManager.initialize(tagManagerArgs);
    }

    const [maintenanceBegan, setMaintenanceBegan] = useState<MaintenanceDataFragment | null>(null);

    if (maintenanceBegan) {
        return <MaintenanceRouter {...maintenanceBegan} />;
    }

    return (
        <TranslationsProvider key={currentCountry?.id} country={currentCountry}>
            <HelmetTitle />
            <MaintenanceWatcher setMaintenanceBegan={setMaintenanceBegan} />
            <Switch>
                <Route component={RemoteRoute} path={prefixPath('/remote/:applicationId/:sourceId')} exact />
                <Route
                    component={InsuranceRemoteRoute}
                    path={prefixPath('/insuranceRemote/:applicationId/:sourceId')}
                    exact
                />
                <Route component={GuarantorRoute} path={prefixPath('/guarantor/:applicationId/:sourceId')} exact />
                <Route component={RedirectRoute} path={prefixPath('/redirect')} exact />
                <InternalRouter />
            </Switch>
        </TranslationsProvider>
    );
};

export default withCompanyProvider(CompanyRouter);
