import React from 'react';
import styled from 'styled-components';
import PureFileInput from '../../ui/form/PureFileInput';
import TextField from './TextField';

const FileFieldError = styled.div`
    display: block;
    text-align: right;
    color: #ff0000;
    padding: 9px 0;
`;

const FileField = props => <TextField errorComponent={FileFieldError} inputComponent={PureFileInput} {...props} />;

export default FileField;
