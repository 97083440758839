/* eslint-disable no-undef */
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil, get, flow } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ContentTranslation, useContentTranslation } from '../../../../i18n';
import { ApplicationStages, Stages, StageType } from '../../../../schema';
import {
    getActivationUrl,
    getApplicationUrl,
    getInsuranceApplicationUrl,
    getLeadUrl,
} from '../../../../utilities/urls';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { Formats, withCompanyFormatting } from '../../../utilities/useCompanyFormatting';
import { CustomerApplicationJourneyFragment } from './CustomerRoute.graphql';
import { ApplicationStatusStyle, JourneySection } from './JourneyStyle';

type ApplicationJourneyProps = {
    formats: Formats;
    journeys: {
        [key: string]: CustomerApplicationJourneyFragment[];
    };
};

const renderStageIcon = (index: number, stage: StageType | undefined | null) => {
    switch (stage) {
        case StageType.DECLINED:
            return (
                <div className="stage-icon times">
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            );

        case StageType.PRESENT:
            return <div className="stage-icon in-progress">{index + 1}</div>;

        case StageType.FINISHED:
            return (
                <div className="stage-icon checked">
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            );

        default:
            return <div className="stage-icon disabled" />;
    }
};

const renderStage = (
    { name, stage, extra, date, label }: Stages,
    index: number,
    application: CustomerApplicationJourneyFragment,
    onClick: (application: CustomerApplicationJourneyFragment, name: ApplicationStages) => void,
    formatDateTime: (value: Date) => string
) => (
    <div
        aria-hidden="true"
        className={`stage-flow-item ${isNil(stage) ? 'disabled' : ''} `}
        onClick={() => onClick(application, name)}
        role="button"
    >
        {renderStageIcon(index, stage)}
        <div className="info">
            <div className="info-title">{label}</div>
            <div className="info-item">{date && formatDateTime(date)}</div>
            {extra && extra.map((extraInfo: string) => <div className="info-item"> {extraInfo}</div>)}
        </div>
    </div>
);

const renderApplication = (
    application: CustomerApplicationJourneyFragment,
    formatCurrency: Formats['formatCurrency'],
    onClick: (application: CustomerApplicationJourneyFragment, stage: ApplicationStages) => void,
    formatDateTime: (date: Date) => string,
    { ct }: ContentTranslation
) => (
    <div key={application.id} className="journey-item">
        <div className="quote-info">
            <div>
                <div>{flow([get('variant.name'), ct])(application)}</div>
                <div>{flow([get('financeProduct.name'), ct])(application)}</div>
                <div>{formatCurrency(get('calculator.loan.amount', application))}</div>
            </div>
            <div className="status-info">
                <ApplicationStatusStyle background={application.statusText.color}>
                    {application.statusText.label}
                </ApplicationStatusStyle>
            </div>
        </div>
        <div className="stage-info">
            {application.stages.map((stage: Stages, index: number) =>
                renderStage(stage, index, application, onClick, formatDateTime)
            )}
        </div>
    </div>
);

const ApplicationJourney = ({ formats, journeys }: ApplicationJourneyProps) => {
    const history = useHistory();
    const formatDateTime = useFormatDateTime();
    const contentTranslation = useContentTranslation();

    const redirect = useCallback(
        (application, stage) => {
            switch (stage) {
                case ApplicationStages.LEAD:
                    // @ts-ignore
                    history.pushWithCompanyAndState(getLeadUrl, application?.version?.id);
                    break;

                case ApplicationStages.FINANCE:
                    // @ts-ignore
                    history.pushWithCompanyAndState(getApplicationUrl, null, application);
                    break;

                case ApplicationStages.ACTIVATION:
                    // @ts-ignore
                    history.pushWithCompanyAndState(getActivationUrl, application?.version?.id);
                    break;

                case ApplicationStages.INSURANCE:
                    // @ts-ignore
                    history.pushWithCompanyAndState(
                        getInsuranceApplicationUrl,
                        null,
                        application?.insuranceApplication?.version?.id
                    );
                    break;

                default:
                    break;
            }
        },
        [history]
    );

    const { formatCurrencyDown } = formats;

    return (
        <JourneySection>
            {Object.entries(journeys).map(([channel, applications]) => (
                <div key={channel} className="journey-section">
                    <div className="title">{channel}</div>
                    {applications.map(application =>
                        renderApplication(application, formatCurrencyDown, redirect, formatDateTime, contentTranslation)
                    )}
                </div>
            ))}
        </JourneySection>
    );
};

export default withCompanyFormatting(ApplicationJourney);
