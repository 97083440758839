import qs from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { EventFlowState, ReservationLocationState } from '../../../flows/EventFlow/EventSubmitFlow';
import { usePersistedFlow } from '../../../flows/utils/persistency';
import { checkTokenValidity } from '../../../flows/utils/tokens';
import { Channel } from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import { getCompanyLoginUrl } from '../../../utilities/urls';
import Chatbot from '../../shared/Chatbot';
import Footer from '../../shared/Footer';
import Header from '../../shared/Header';
import PublicSessionTimer from '../../shared/PublicSessionTimer';
import EventHandler from '../EventRoute/EventHandler';
import FinderBootstrap from './FinderBootstrap';
import FinderVehicleBookingWatcher from './FinderVehicleBookingWatcher';

type PersistedData = { token?: string; step?: string; bookingId?: string };

export const serialize = (data: EventFlowState, stepIdentifier: string) => {
    const persistedData: PersistedData = {
        token: data.token,
        bookingId: data.bookingId,
        step: stepIdentifier,
    };

    return JSON.stringify(persistedData);
};

export const unserialize = (serializedData: string): PersistedData => {
    const data = JSON.parse(serializedData) as PersistedData;

    if (data?.token && !checkTokenValidity(data?.token)) {
        // start with an empty state
        return {};
    }

    return data;
};

export const generateCacheKey = (pubSubChannel: string, state: EventFlowState) =>
    `${pubSubChannel}:${state.application?.version.id}`;

const Inner = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const location = useLocation<ReservationLocationState>() || {};
    const { pathname, search, state } = location;
    const history = useHistory();
    const redirect = `${pathname}${search}`;

    // extract parameters from the URL query
    const { applicationId } = qs.parse(search, { ignoreQueryPrefix: true });

    const { token, bookingId } =
        usePersistedFlow(
            ['eventSubmitFlow', applicationId].filter(Boolean).join(':'),
            'eventSubmitFlow',
            serialize,
            unserialize,
            generateCacheKey
        ) || {};

    return (
        <>
            <Header channel={Channel.EVENT} />
            <FinderBootstrap bookingId={bookingId} reservation={state?.reservation} token={token}>
                {context => {
                    const { event, isListingSame, finderVehicle, isExpired, ...props } = context;
                    const { allowPublicAccess } = event.setting;

                    if (!isAuthenticated && !allowPublicAccess) {
                        // @ts-ignore
                        history.pushWithCompanyAndState(getCompanyLoginUrl, { redirect });
                    }

                    return (
                        <>
                            {finderVehicle && (
                                <FinderVehicleBookingWatcher finderVehicle={finderVehicle} isExpired={isExpired} />
                            )}
                            <Chatbot channel={Channel.EVENT} event={event} />
                            <PublicSessionTimer url={redirect} />
                            <EventHandler
                                {...props}
                                bookingId={isListingSame ? bookingId : undefined}
                                event={event}
                                finderVehicle={finderVehicle}
                                reservation={state?.reservation}
                                token={token}
                            />
                        </>
                    );
                }}
            </FinderBootstrap>
            <Footer />
        </>
    );
};

export default Inner;
