// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../i18n';
import { assetConditionOptions } from '../../../../utilities/constants/options';
import DateField from '../../../shared/form-v2/DateField';
import NumberField from '../../../shared/form-v2/NumberField';
import SelectField from '../../../shared/form-v2/SelectField';
import TextField from '../../../shared/form-v2/TextField';
import { InsuranceApplicationDataFragment } from '../data.graphql';

export type NewProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
};

const New = ({ insuranceApplication }: NewProps) => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();
    const variant = insuranceApplication?.variant;

    const hasSubModel = !!variant.model.parentId;

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.vehicleOfInterest')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.make')}
                            name={formatPath(
                                hasSubModel ? 'variant.model.parent.make.name' : 'variant.model.make.name'
                            )}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.model')}
                            name={formatPath(hasSubModel ? 'variant.model.parent.name' : 'variant.model.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.subModal')}
                            name={formatPath(hasSubModel ? 'variant.model.name' : '')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.variant')}
                            name={formatPath('variant.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.vehicleID')}
                            name="variant.identifier"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('applicationDetailsPage.label.assetCondition')}
                            name="assetCondition"
                            options={assetConditionOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.yearOfManufacture')}
                            name="variant.year"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.displacement')}
                            name="calculator.displacement"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={t('applicationDetailsPage.label.originalRegistrationDate')}
                            name="calculator.originalRegistrationDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.totalPrice')}
                            name="calculator.totalPrice"
                            type="currency"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default New;
