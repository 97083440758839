import { TFunction } from 'i18next';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadRelationships } from '../../../../../actions';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import usePhone from '../../../../../components/utilities/usePhone';
import { getRelationships } from '../../../../../selectors';
import { onTextKeyPress, onTelKeyPress, fromContextValidation, yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';

const watchedFields = [
    'identityNumber',
    'details.emiratesIdExpiry',
    'details.uaeResidentSince',
    {
        path: 'details.reference',
        properties: ['name', 'relationship', 'phone'],
    },
];
type ReferenceDetailsFormProps = {
    disabled?: boolean;
};

const ReferenceDetails = ({ disabled = false }: ReferenceDetailsFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sectionPrefix }: any = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const { code: phoneCountryCode } = usePhone();

    useEffect(() => {
        dispatch(loadRelationships());
    }, [dispatch]);

    const relationships = useSelector(getRelationships);

    return (
        <>
            <Title>{t('kycPage.referenceDetailsTitle')}</Title>
            <TextField
                autoComplete={false}
                disabled={disabled || fieldState.details.reference}
                label={t('referenceDetails.label.name')}
                name="details.reference.name"
                onKeyPress={onTextKeyPress}
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.reference}
                label={t('customerDetails.label.relationship')}
                name="details.reference.relationship"
                options={relationships}
            />
            <TextField
                disabled={disabled || fieldState.details.reference}
                label={t('referenceDetails.label.contactNumber')}
                maxLength="10"
                name="details.reference.phone"
                onKeyPress={onTelKeyPress}
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
            />
        </>
    );
};

export default ReferenceDetails;

export const rootSchema = (t: TFunction) => ({
    reference: yupExt.customerProperty().shape({
        name: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
        phone: fromContextValidation(
            yup
                .string()
                .required(t('common.error.required'))
                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
            'phonePattern',
            t('common.error.mobile')
        ),
        relationship: yup.string().required(t('common.error.required')),
    }),
});
