import {
    completeGuarantorConfirmation,
    CompleteGuarantorConfirmationMutation,
    CompleteGuarantorConfirmationMutationVariables,
} from '../../../api/draft.graphql';
import { FlowStep } from '../../utils/flow';
import { GuarantorFlowState } from '../GuarantorFlow';

class ConfirmationStep extends FlowStep<GuarantorFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'confirmation';
    }

    // eslint-disable-next-line class-methods-use-this
    public get isShadowStep(): boolean {
        return true;
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.guarantorConfirmation || false;
    }

    public async executeBefore(): Promise<FlowStep<GuarantorFlowState>> {
        const { apolloClient } = this;
        const { token } = this.state;

        const apiResponse = await apolloClient.mutate<
            CompleteGuarantorConfirmationMutation,
            CompleteGuarantorConfirmationMutationVariables
        >({
            mutation: completeGuarantorConfirmation,
            variables: { token },
        });

        // update state
        this.stateStore.update({ ...apiResponse.data?.response });

        return this.nextStep as FlowStep<GuarantorFlowState>;
    }
}

export default ConfirmationStep;
