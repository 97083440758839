import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { useZone } from '../../hookSelectors';
import { Channel } from '../../schema';
import {
    VariantDataFragment,
    getVariantsByDealerId,
    GetVariantsByDealerIdQuery,
    GetVariantsByDealerIdQueryVariables,
} from './useLoadVariants.graphql';

export type Variant = VariantDataFragment;

export type LoadVariants = {
    loading: boolean;
    variants: Variant[];
};

const useLoadVariants = (
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
    channel: Channel,
    dealerId: string | null | undefined
): LoadVariants => {
    // get zone id
    const { id: zoneId } = useZone();

    // get data using apollo
    const { data, loading } = useQuery<GetVariantsByDealerIdQuery, GetVariantsByDealerIdQueryVariables>(
        getVariantsByDealerId,
        {
            skip: isNil(dealerId),
            variables: {
                zoneId,
                dealerId: dealerId as string,
                channel,
            },
            fetchPolicy,
        }
    );

    return useMemo(
        () => ({
            variants: data?.variants || [],
            loading,
        }),
        [data, loading]
    );
};

export default useLoadVariants;
