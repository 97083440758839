import React from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import ImgWithFallback from '../../shared/ImgWithFallback';
import Card from '../Card';
import { TextAlignCenter } from '../TextAlign';
import noImg from '../../../assets/images/no-image.png';

export interface CarCardContainerProps {
    disabled?: boolean;
}

export const CarCardContainer = styled(Card as StyledComponent<'div', any, CarCardContainerProps, never>)`
    color: #000000;
    padding: 0;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.7;
        `}

    ${TextAlignCenter} {
        line-height: 1.5;
    }
`;

const Label = styled.h3`
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 1.28rem;
    color: #000;
`;

const Description = styled.span`
    font-size: 1rem;
    color: #666;
`;

const ImageContainer = styled.button`
    border: none;
    outline: none;
    cursor: pointer;
    display: block;
    background: transparent;

    max-width: 300px;
    height: 150px;

    margin: 0 auto;

    position: relative;

    & > img {
        max-width: 100%;
        object-fit: cover;
    }

    ${props =>
        props.disabled &&
        css`
            cursor: auto;
        `}
`;

export type CarCardProps = {
    label: string;
    description: string;
    onClick?: () => void;
    disabled?: boolean;
    img?: string | null;
};

const CarCard = ({ img, label, description, onClick, disabled }: CarCardProps) => (
    <CarCardContainer disabled={disabled} onClick={onClick}>
        <ImageContainer disabled={disabled}>
            <ImgWithFallback alt={label} fallbackSrc={noImg} src={img || noImg} />
        </ImageContainer>
        <TextAlignCenter>
            <Label>{label}</Label>
            <Description>{description}</Description>
        </TextAlignCenter>
    </CarCardContainer>
);

export default CarCard;
