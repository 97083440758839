import { isNil } from 'lodash/fp';
import React from 'react';
import {
    CompleteInsuranceConsentsMutation,
    CompleteInsuranceConsentsMutationVariables,
    completeInsuranceConsents,
} from '../../../api/draft.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import Consent from '../../DraftFlow/components/Consent';
import { getCountryInsuranceConsents } from '../../DraftFlow/utils/consents';
import { ReduxFormFlowStep } from '../../utils/flow';
import { InsuranceNewFlowState } from '../types';

export type ConsentStepValues = Partial<{
    consents: { [consentId: string]: boolean };
    referenceId: string;
}>;

class ConsentStep extends ReduxFormFlowStep<InsuranceNewFlowState, ConsentStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'consent';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('consentPage.label.step');
    }

    public get isCompleted(): boolean {
        return this.state.insuranceApplication?.steps?.consentsAndDeclarations || false;
    }

    public async execute({ referenceId }: ConsentStepValues) {
        const { apolloClient } = this;
        const { token } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        const variables = { token, eventId: referenceId };

        const { data } = await apolloClient.mutate<
            CompleteInsuranceConsentsMutation,
            CompleteInsuranceConsentsMutationVariables
        >({
            mutation: completeInsuranceConsents,
            variables,
        });

        this.stateStore.update({ ...data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { insuranceApplication, channel, consents: all } = this.state;
        const consents = getCountryInsuranceConsents(all, channel);

        if (!insuranceApplication) {
            throw new Error('Insurance Application missing in state');
        }

        if (isNil(consents)) {
            throw new Error('Consents missing in state');
        }

        return (
            <>
                <HelmetTitle channel={channel} title="Consents" />
                <Consent
                    backStep={this.getBackContext()}
                    consents={consents}
                    isLastStep={this.isLastStep}
                    onSubmit={this.submit}
                />
            </>
        );
    }
}

export default ConsentStep;
