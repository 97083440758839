import React from 'react';
import { ApplicationCustomerDataFragment } from '../../../components/routes/ApplicationRoute/data.graphql';
import MyInfoPage from '../../../components/ui/calculator/MyInfoPage';
import { ApplicationFlowSource } from '../../../schema';
import { FlowStep } from '../../utils/flow';
import MyInfoRoute from '../components/MyInfo';
import { NewFlowState } from '../types';

export type MyInfoStepValues = {
    customer: ApplicationCustomerDataFragment;
    hasError: boolean;
};

class MyInfoStep extends FlowStep<NewFlowState, MyInfoStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'myInfo';
    }

    // eslint-disable-next-line class-methods-use-this
    get label(): string {
        return this.t('myInfoPage.label.step');
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.kyc || false;
    }

    public render(): React.ReactElement | null {
        const { application, bank, token } = this.state;

        if (!bank) {
            throw new Error('Bank missing in state');
        }

        if (!application) {
            throw new Error('application missing in state');
        }

        if (!token) {
            throw new Error('Token missing in state');
        }

        return (
            <MyInfoRoute
                applicationId={application.id}
                backStep={this.getBackContext()}
                bank={bank}
                customer={application.customer}
                onSubmit={this.submit}
                pageComponent={MyInfoPage}
                source={ApplicationFlowSource.DRAFT}
                step={this.identifier}
                token={token}
            />
        );
    }

    public async execute({ customer, hasError }: MyInfoStepValues) {
        const { application } = this.state;

        if (!application) {
            throw new Error('application missing in state');
        }

        this.stateStore.update({
            application: { ...application, customer },
            withMyInfoError: hasError,
        });

        return this.nextStep;
    }
}

export default MyInfoStep;
