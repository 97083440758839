import React from 'react';
import { completeOTP, CompleteOtpMutation, CompleteOtpMutationVariables } from '../../../api/draft.graphql';
import Approval from '../../DraftFlow/components/Approval';
import { OTPStepValues } from '../../DraftFlow/steps/OTPStep';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';

class OTPStep extends FlowStep<RemoteFlowState, OTPStepValues> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'otp';
    }

    public async execute({ code: otp, secret }: OTPStepValues) {
        const { apolloClient } = this;
        const { token } = this.state;

        const apiResponse = await apolloClient.mutate<CompleteOtpMutation, CompleteOtpMutationVariables>({
            mutation: completeOTP,
            variables: { token, secret, otp },
        });

        this.stateStore.update({ ...apiResponse?.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { token } = this.state;

        return (
            <Approval
                backStep={this.getBackContext()}
                isLastStep={this.isLastStep}
                onSubmit={this.submit}
                token={token}
            />
        );
    }
}

export default OTPStep;
