import { defaultComputingFields, CalculatorHelpers } from '@appvantageasia/afc-calculator-ui-next';
import { set } from 'lodash/fp';
import { CalculatorMeta, CalculatorValues } from '../types';

const { tenure } = defaultComputingFields;

const validate = (helpers: CalculatorHelpers<CalculatorValues>, meta: CalculatorMeta) => {
    const { t } = meta;
    const { values, getFieldContext } = helpers;
    const { selectedFinanceProduct } = getFieldContext('financeProduct');

    // no finance product to validate
    if (!selectedFinanceProduct) {
        return;
    }

    const { termSetting } = selectedFinanceProduct;

    // validate if express finance product is correctly configured
    if (termSetting.max < termSetting.min) {
        helpers.setError('tenure', t('expressCalculatorPage.error.paymentTerm'));

        return;
    }

    if (values.tenure !== undefined && (termSetting.max < values.tenure || termSetting.min > values.tenure)) {
        helpers.setError('tenure', t('calculator.error.invalidTenure'));
    }
};

const tenureField = set('validate', validate)(tenure);

export default tenureField;
