import styled from 'styled-components';

const PageContainer = styled.div`
    margin: 0 65px;

    @media (max-width: 450px) {
        margin: 0 15px;
    }
`;

export const Title = styled.h1`
    text-transform: ${props => props.theme.casing};
    font-size: 1.43rem;
    text-align: center;
    padding: 20px 0 10px;
`;

export const Content = styled.div`
    td:first-child {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export default PageContainer;
