import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getContext } from '../../selectors';
import useLoadScope from '../data/useLoadScope';
import useLoadUser from '../data/useLoadUser';
import Error404NotFound from '../routes/Error404NotFound';

const locationRegex = /^(?<countryCode>[a-zA-Z0-9]+)(-(?<zoneCode>[a-zA-Z0-9-]+))?$/;

const parseLocationCode = locationCode => locationCode.match(locationRegex)?.groups ?? {};

const CompanyProvider = ({ children }) => {
    // get company code and country code from react router
    const { companyCode, locationCode } = useParams();
    // extract the zone code
    const { countryCode, zoneCode = countryCode } = useMemo(() => parseLocationCode(locationCode), [locationCode]);

    // load the scope
    const [isScopeLoading] = useLoadScope(companyCode, countryCode, zoneCode);

    // get the scope from redux
    // because we want to be sure the scope is wildly available
    const scope = useSelector(getContext);
    const zone = scope?.zone;

    // load the user
    const [isUserLoading] = useLoadUser();

    if (!isScopeLoading && !zone) {
        // return no company country page
        return <Error404NotFound />;
    }

    if (isUserLoading || !zone) {
        // return an empty page to keep the user waiting
        // either we are still trying to get the zone or the user, maybe both
        return null;
    }

    return children(scope);
};

const withCompanyProvider = WrappedComponent => {
    const EnhancedComponent = props => (
        <CompanyProvider>{scope => <WrappedComponent {...props} {...scope} />}</CompanyProvider>
    );

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    EnhancedComponent.displayName = `withCompanyProvider(${displayName})`;

    return EnhancedComponent;
};

export default withCompanyProvider;
