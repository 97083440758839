// @ts-ignore
import { Footer } from '@appvantageasia/afc-ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, InjectedFormProps, submit } from 'redux-form';
import Navigation from '../../../../flows/utils/Navigation';
import { RuntimeType } from '../../../../schema';
import { getGlobalPermissions } from '../../../../selectors';
import { getCustomersUrl } from '../../../../utilities/urls';
import AFCApplicantStep from '../../../shared/form-v2/steps/ApplicantStep';
import PMEApplicantStep from '../../../shared/form-v2/steps/PME/ApplicantStep';
import { Title } from '../../../ui/PageContainer';
import FixedMenuFormTabContainer from '../../../ui/form/FixedMenuFormTab';
import { useListContextFromState } from '../../../utilities/useListContext';
import useRuntimeType from '../../../utilities/useRuntimeType';
import { ApplicationCustomerDataFragment } from '../../ApplicationRoute/data.graphql';
import useFormDirty from '../../shared/useFormDirty';
import ApplicationJourney from './ApplicationJourney';
import { CustomerApplicationJourneyFragment } from './CustomerRoute.graphql';

type FormProps = {
    disabled?: boolean;
    journeys: {
        [key: string]: CustomerApplicationJourneyFragment[];
    };
    validation: {
        phonePattern: RegExp | null;
    };
};

const { FootContainer, FootBar, FootBarButton, FootBarRow } = Footer;

type ApplicantStepComponentType = ({
    allowPrimaryInfoChanges,
    disabled,
}: {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
}) => JSX.Element;
const Form = (props: FormProps & InjectedFormProps<{ customer: ApplicationCustomerDataFragment }, FormProps>) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const hasErrors = useFormDirty('customer');
    const { mayManageCustomer } = useSelector(getGlobalPermissions);

    const ApplicantStep = useRuntimeType<ApplicantStepComponentType>({
        [RuntimeType.PME]: PMEApplicantStep,
        [RuntimeType.AFC]: AFCApplicantStep,
    });

    // for back nav click
    const { previous } = useLocation<{ previous: string }>().state || {};
    const prevText = useMemo(() => previous || t('customerDetailsPage.button.previous'), [previous, t]);
    const [listContext] = useListContextFromState();
    const onPrev = useCallback(() => {
        // if there is previous source
        // navigate back
        if (previous) {
            history.goBack();
        } else {
            // else go back to listing
            // @ts-ignore
            history.pushWithCompanyAndState(getCustomersUrl, { listContext });
        }
    }, [history, listContext, previous]);

    // for get journeys
    const { journeys } = props;

    // handel cancel btn
    const onCancel = useCallback(() => history.goBack(), [history]);

    // handel submit btn
    const onClick = useCallback(async () => {
        // submit the application
        return dispatch(submit('customer'));
    }, [dispatch]);

    return (
        <FixedMenuFormTabContainer style={{ marginBottom: '50px' }}>
            <Navigation onPrev={onPrev} prevText={prevText} />
            <Title>{t('customerDetailsPage.title')}</Title>
            <ApplicantStep disabled={!mayManageCustomer} />
            <ApplicationJourney journeys={journeys} />
            <FootContainer>
                <FootBar>
                    <FootBarRow>
                        {onCancel && (
                            <FootBarButton onClick={onCancel}>{t('customerDetailsPage.button.cancel')}</FootBarButton>
                        )}
                        <div style={{ flex: 1 }} />
                        <FootBarButton disabled={!mayManageCustomer || hasErrors} onClick={onClick}>
                            {t('customerDetailsPage.button.save')}
                        </FootBarButton>
                    </FootBarRow>
                </FootBar>
            </FootContainer>
        </FixedMenuFormTabContainer>
    );
};

export default reduxForm<{ customer: ApplicationCustomerDataFragment }, FormProps>({ form: 'customer' })(Form);
