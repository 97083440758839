import { TFunction } from 'i18next';
import { get, filter, omit, isEmpty } from 'lodash/fp';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext, Normalizer } from 'redux-form';
import * as yup from 'yup';
import DateField from '../../../../../components/shared/form-v2/DateField';
import NationalityField from '../../../../../components/shared/form-v2/NationalityField';
import SelectField from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import useCustomerSourceOptions from '../../../../../components/utilities/useCustomerSourceOptions';
import usePhone from '../../../../../components/utilities/usePhone';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import useOptions from '../../../../../utilities/constants/useOptions';
import { getMinDateOfBirth } from '../../../../../utilities/dates';
import { onTextKeyPress, onTelKeyPress, fromContextValidation, yupExt } from '../../../../../utilities/forms';
import { Title } from '../ui';
import validateFile from './validateFile';

const watchedFields = [
    'title',
    'email',
    'firstName',
    'lastName',
    'phone',
    'dateOfBirth',
    'details.nationality',
    'details.gender',
    'details.maritalStatus',
    'details.education',
    'details.nationality',
];

type CustomerInformationFormProps = {
    disabled?: boolean;
    onNormalizeNames: {
        onNormalizeFirstName: Normalizer;
        onNormalizeLastName: Normalizer;
    };
    hasTestDrive?: boolean;
    allowPrimaryEdition?: boolean;
    bankPresetOption?: BankPresetOption;
};
const CustomerInformationForm = ({
    disabled = false,
    onNormalizeNames: { onNormalizeFirstName, onNormalizeLastName },
    hasTestDrive = false,
    allowPrimaryEdition = false,
    bankPresetOption,
}: CustomerInformationFormProps) => {
    const { t } = useTranslation();
    const { sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);
    const { code: phoneCountryCode } = usePhone();
    const minDateOfBirth = useMemo(() => getMinDateOfBirth(), []);

    const { genders } = useCustomerSourceOptions();
    const { maritalStatuses, title, educations } = useOptions();

    const wrapWithAsterisk = useCallback(
        (text: string) => (bankPresetOption === BankPresetOption.ENBDBANK ? `${text}*` : text),
        [bankPresetOption]
    );

    return (
        <>
            <Title>{t('kycPage.customerDetailsTitle')}</Title>
            <SelectField.Outline
                disabled={disabled || fieldState.title}
                label={wrapWithAsterisk(t('eventCustomerDetails.label.title'))}
                name="title.value"
                options={title}
            />
            <TextField
                disabled={disabled || fieldState.firstName}
                label={wrapWithAsterisk(t('customerDetails.label.firstName'))}
                name="firstName.value"
                normalize={onNormalizeFirstName}
                onKeyPress={onTextKeyPress}
            />
            <TextField
                disabled={disabled || fieldState.lastName}
                label={wrapWithAsterisk(t('customerDetails.label.lastName'))}
                name="lastName.value"
                normalize={onNormalizeLastName}
                onKeyPress={onTextKeyPress}
            />
            <TextField
                disabled={disabled || fieldState.email}
                label={wrapWithAsterisk(t('customerDetails.label.email'))}
                name="email.value"
                type="email"
            />
            <TextField
                label={wrapWithAsterisk(t('customerDetails.label.mobile'))}
                maxlength="10"
                name="phone.value"
                onKeyPress={onTelKeyPress}
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                type="tel"
            />
            <DateField
                disabled={disabled || fieldState.dateOfBirth}
                label={wrapWithAsterisk(t('customerDetails.label.dateOfBirth'))}
                maxDate={minDateOfBirth}
                name="dateOfBirth.value"
            />
            <NationalityField
                disabled={disabled || fieldState.details.nationality}
                label={t('customerDetails.label.nationality')}
                name="details.nationality.value"
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.gender}
                label={t('customerDetails.label.gender')}
                name="details.gender.value"
                options={genders}
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.maritalStatus}
                label={t('customerDetails.label.maritalStatus')}
                name="details.maritalStatus.value"
                options={maritalStatuses}
                sort={false}
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.education}
                label={t('customerDetails.label.education')}
                name="details.education.value"
                options={educations}
                sort={false}
            />
        </>
    );
};

export default CustomerInformationForm;

export const rootSchema = (t: TFunction) => ({
    email: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .email(t('common.error.email'))
            .max(320, t('common.error.lessThanOrEqual', { max: 320 })),
    }),
    firstName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
    }),
    lastName: yupExt.customerProperty().shape({
        value: yup
            .string()
            .required(t('common.error.required'))
            .max(200, t('common.error.lessThanOrEqual', { max: 200 })),
    }),
    phone: yupExt.customerProperty().shape({
        value: fromContextValidation(
            yup
                .string()
                .required(t('common.error.required'))
                .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
            'phonePattern',
            t('common.error.mobile')
        ),
    }),
    // @ts-ignore
    dateOfBirth: yup.lazy((values, options) => {
        const shouldValidate = get(['context', 'useCustomerBirthDay'], options);
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

        if (shouldValidate || isEnbdBankPreset) {
            return yupExt.customerProperty().shape({
                value: yup.string().required(t('common.error.required')),
            });
        }

        return yup.mixed().notRequired();
    }),

    // @ts-ignore
    title: yup.lazy((values, options) => {
        const isEnbdBankPreset =
            get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.ENBDBANK;

        if (isEnbdBankPreset) {
            return yupExt
                .customerProperty()
                .shape({ value: yup.string().required(t('common.error.required')) })
                .required();
        }

        return yupExt.customerProperty().shape({ value: yup.string().notRequired() }).notRequired();
    }),
});

export const detailsSchema = (t: TFunction) => ({
    gender: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    maritalStatus: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    nationality: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
    education: yupExt
        .customerProperty()
        .shape({ value: yup.string().required(t('common.error.required')) })
        .required(t('common.error.required')),
});

export const customerFilesSchema = (t: TFunction) =>
    // @ts-ignore
    yup.lazy((value: any, { parent }: any) => {
        const validator = yup
            .array()
            .transform((currentValue: any) =>
                filter(item => {
                    if (!item) {
                        return false;
                    }

                    if (item.purpose !== UploadPurpose.CUSTOMER_ATTACHED) {
                        // wrong purpose, skip it
                        return false;
                    }

                    // ensure it's not an empty item
                    return item instanceof File || !isEmpty(omit(['purpose'], item));
                }, currentValue)
            )
            .of(
                yup.mixed().test(
                    'extension-validation',
                    t('common.error.fileExtension'),
                    // @ts-ignore
                    validateFile
                )
            );

        return validator.min(1, t('common.error.filesRequired'));
    });
