import styled from 'styled-components';

const ChatContainer = styled.div`
    position: fixed;
    right: 25px;
    bottom: 25px;
    border: 1px solid #ddd;
    width: 300px;
    height: 621px;
    z-index: 100;

    @media (max-width: 450px) {
        height: 100%;
        width: 100%;
        right: 0;
        bottom: 0;
        border: 0;
    }
`;

export default ChatContainer;
