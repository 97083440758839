import React, { useCallback, useState, memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import useFormValues from '../../../../components/utilities/useFormValues';
import { PromoCodeUnit } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import { SubTitle } from '../Configurator/shared/ui';
import PromoField from './PromoField';
import { SummaryFormValues } from './Summary';
import { SectionContainer, Row } from './shared/ui';

const PromoMessage = styled.p<{ messageColor?: string }>`
    color: ${props => (props.messageColor ? props.messageColor : '#f52f2f​')};
    text-align: left;
    font-size: 12px;
`;

type PromoSectionProps = {
    form: string;
    eventDealerIds: string[];
};

const PromoSection = ({ form, eventDealerIds }: PromoSectionProps) => {
    const { formatCurrency } = useCompanyFormatting();
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { price: carPrice } = ckdConfiguration.vehicle;

    const { promo } = useFormValues<SummaryFormValues>();

    const [promoMessage, setPromoMessage] = useState('');

    const getPromoMessage = useCallback(
        promo => {
            if (promo && promo.remainingQuantity > 0) {
                return ckdConfiguration.summary.successfulPromo;
            }

            if (promo && promo.remainingQuantity <= 0) {
                return ckdConfiguration.summary.usedUpPromo;
            }

            return ckdConfiguration.summary.invalidPromo;
        },
        [ckdConfiguration]
    );

    // calculate promo price
    const discountedPrice = useMemo(() => {
        if (promo && promo.value) {
            // check promo value more than starting price
            const isExceededCarPrice = promo.value > carPrice;
            const availablePromoPrice = isExceededCarPrice ? carPrice : promo.value;

            return formatCurrency(
                promo.unit === PromoCodeUnit.PERCENTAGE ? (carPrice * promo.value) / 100 : availablePromoPrice
            );
        }

        return null;
    }, [carPrice, formatCurrency, promo]);

    useEffect(() => {
        if (promo !== undefined) {
            setPromoMessage(getPromoMessage(promo));
        }
    }, [getPromoMessage, promo]);

    return (
        <SectionContainer>
            <SubTitle>{ckdConfiguration.summary.promoCode}</SubTitle>
            <PromoField eventDealerIds={eventDealerIds} form={form} />
            <PromoMessage messageColor={promo ? '#54de06' : '#f52f2f'}>{promoMessage}</PromoMessage>
            {promo && (
                <>
                    {promo?.value && (
                        <Row>
                            <span>{promo.identifier}</span>
                            <span>- {discountedPrice}</span>
                        </Row>
                    )}
                    <Row>
                        <span>{promo.description.split(',')[0]}</span>
                        <span>{ckdConfiguration.summary.included}</span>
                    </Row>
                    <span>{promo.description.split(',')[1]}</span>
                </>
            )}
        </SectionContainer>
    );
};

export default memo(PromoSection);
