import { isNil } from 'lodash/fp';
import PubSub from 'pubsub-js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { setNotification } from '../../actions';
import { ChannelSettingDataFragment } from '../../components/data/useLoadScope.graphql';
import {
    ApplicationDataFragment,
    ApplicationInsuranceApplicationDataFragment,
} from '../../components/routes/ApplicationRoute/data.graphql';
import { useCountry } from '../../hookSelectors';
import { Channel } from '../../schema';
import { getIsAuthenticated } from '../../selectors';
import { getHomeUrl } from '../../utilities/urls';
import { Flow } from './flow';

const getAllowPublicAccess = (
    application: ApplicationDataFragment | ApplicationInsuranceApplicationDataFragment,
    channelSetting: ChannelSettingDataFragment
) => {
    switch (application?.channel) {
        case Channel.USED:
            return channelSetting.used.allowPublicAccess;

        case Channel.NEW:
            return channelSetting.new.allowPublicAccess;

        default:
            return false;
    }
};

const useCompleteHandler = <
    State extends {
        application?: ApplicationDataFragment;
        insuranceApplication?: ApplicationInsuranceApplicationDataFragment;
    }
>(
    pubSubChannel: string
) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const isAuthenticated = useSelector(getIsAuthenticated);

    const { channelSetting } = useCountry();

    useEffect(() => {
        const token = PubSub.subscribe(`${pubSubChannel}.completed`, (msg: string, flow: Flow<State>) => {
            const { application, insuranceApplication } = flow.state;

            const availableApplication = application || insuranceApplication;

            if (!availableApplication) {
                throw new Error('Application missing in state');
            }

            const url = getHomeUrl(availableApplication?.channel);

            if (isAuthenticated) {
                // @ts-ignore
                history.pushWithCompanyAndState(url, {
                    submitted: true,
                    applicationVersionId: availableApplication?.version.id,
                    isInsurance: isNil(application),
                });
            } else if (getAllowPublicAccess(availableApplication, channelSetting)) {
                dispatch(
                    setNotification(
                        t('notification.applicationSubmitted.title'),
                        t('notification.applicationSubmitted.message')
                    )
                );

                // @ts-ignore
                history.pushWithCompanyAndState(url);
            }
        });

        return () => {
            PubSub.unsubscribe(token);
        };
    }, [pubSubChannel, dispatch, history, isAuthenticated, channelSetting, t]);
};

export default useCompleteHandler;
