import React from 'react';
import useFormChanged from '../../ApplicationRoute/actions/useFormChanged';
import { SubmitProvider } from '../ApplicationDetails';
import ReSubmitProvider from './ReSubmitProvider';
import ResumeProvider from './ResumeProvider';

const ContinueProvider: SubmitProvider = props => {
    const hasChanged = useFormChanged();

    if (hasChanged) {
        return <ReSubmitProvider {...props} />;
    }

    return <ResumeProvider {...props} />;
};

export default ContinueProvider;
