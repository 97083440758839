import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setNotification, getApplication, getPollingValidEnvelopeStatus } from '../../actions';
import { SigningStatus, Channel } from '../../schema';
import { getCompanyIdentifier, getCurrentCountry } from '../../selectors';
import { mapApplicationToState } from '../../utilities/application';
import { getApplicationsUrl, getCompanyRootUrl, parseCompanyPathname } from '../../utilities/urls';

const useEnvelopeStatus = applicationId => {
    const [envelopeStatus, setEnvelopeStatus] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPollingValidEnvelopeStatus(applicationId)).then(setEnvelopeStatus);
    }, [dispatch, applicationId]);

    return envelopeStatus;
};

const getChannelSetting = channel => {
    const { channelSetting } = useSelector(getCurrentCountry);

    switch (channel) {
        case Channel.EXPRESS:
            return channelSetting.express;

        case Channel.USED:
            return channelSetting.used;

        case Channel.NEW:
            return channelSetting.new;

        default:
            throw new Error('Not implemented');
    }
};

// eslint-disable-next-line import/prefer-default-export
export const useSigningCallback = (applicationId, isAuthenticated, referer) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch, getState } = useStore();
    const envelopeStatus = useEnvelopeStatus(applicationId);

    // verify if submit or resubmit
    // if has new in referer, then it's submit
    // otherwise it's resubmit
    const isNew = referer?.toLowerCase().includes('/apply');
    const isRemoteFlow = referer?.toLowerCase().includes('/remote');

    const { companyCode, locationCode } = getCompanyIdentifier(getState());

    return useMemo(() => {
        // prepare notifications
        const { applicationSubmitted, signingCancelled, signingTimeOut } = t('notification', { returnObjects: true });
        const getApplicationState = async () => {
            // fetch the application
            const application = await dispatch(getApplication(applicationId));
            // if user copy signing page to another tab, then operate,
            // we can not get applicationTempVersionId, so we need to store again
            // then in SignNotCompletedModal we can get it
            sessionStorage.setItem('applicationTempVersionId', application.version.id);

            // and return an application state
            return { application, applicationState: mapApplicationToState(application, t) };
        };

        const onSigned = async () => {
            // fetch application state
            const { application } = await getApplicationState();
            const { channel } = application;
            const { allowPublicAccess } = getChannelSetting(channel);

            // we need to clear applicationTempVersionId when we signed successfully
            sessionStorage.removeItem('applicationTempVersionId');

            if (isNew) {
                // move back to the homage page
                // add some state to specify when we move in from a submitted application
                if (isAuthenticated) {
                    history.pushWithCompanyAndState(getCompanyRootUrl, { submitted: true });
                } else {
                    const { title, message } = applicationSubmitted;
                    dispatch(setNotification(title, message));
                    history.pushWithCompanyAndState(getCompanyRootUrl);
                }
            } else if (isRemoteFlow) {
                const refererPathName = parseCompanyPathname(referer, companyCode, locationCode);
                if (allowPublicAccess) {
                    // if allow public access, we need to go to home page
                    const { title, message } = applicationSubmitted;
                    dispatch(setNotification(title, message));
                    history.pushWithCompanyAndState(getCompanyRootUrl);
                } else {
                    // in that case, go back to comming page
                    history.push(refererPathName, {
                        signingRedirectionMessage: applicationSubmitted,
                    });
                }
            } else {
                // from applicatio detail
                history.pushWithCompanyAndState(getApplicationsUrl, { submitted: true, application });
            }
        };

        const onFailure = async (title, message) => {
            // fetch application state
            const { application } = await getApplicationState();
            const { channel } = application;
            const { allowPublicAccess } = getChannelSetting(channel);
            // from signing page redirect
            // for going to home page,
            // we need clear new application state
            // and prepare session data, just in case user need to login again from redirection
            if (isNew) {
                history.pushWithCompanyAndState(getCompanyRootUrl, {
                    signNotCompleted: {
                        title,
                        message,
                    },
                });
            } else if (isRemoteFlow) {
                const refererPathName = parseCompanyPathname(referer, companyCode, locationCode);
                if (allowPublicAccess) {
                    // if allow public access, we need to go to home page
                    dispatch(setNotification(title, message));
                    history.pushWithCompanyAndState(getCompanyRootUrl);
                } else {
                    // in that case, go back to comming page
                    history.push(refererPathName, {
                        signingRedirectionMessage: {
                            title,
                            message,
                        },
                    });
                }
            } else {
                // from application detail
                dispatch(setNotification(title, message));
                history.pushWithCompany(getApplicationsUrl);
            }
        };

        const onDefault = () => {
            // reason might be there's no application, envelope
            // we redirect back to home page
            history.pushWithCompany(getCompanyRootUrl);
        };

        // clear redirectToSigning anyway in here
        if (sessionStorage.getItem('redirectToSigning')) {
            sessionStorage.removeItem('redirectToSigning');
        }

        switch (envelopeStatus) {
            case SigningStatus.COMPLETED:
                return onSigned;

            case SigningStatus.REJECTED:
                return () => onFailure(signingCancelled.title, signingCancelled.message);

            case SigningStatus.TIMEOUT:
                return () => onFailure(signingTimeOut.title, signingTimeOut.message);

            // status is still retrieving
            case null:
                return null;

            default:
                return onDefault;
        }
    }, [
        applicationId,
        companyCode,
        dispatch,
        envelopeStatus,
        history,
        isAuthenticated,
        isNew,
        isRemoteFlow,
        locationCode,
        referer,
        t,
    ]);
};
