import React from 'react';
import { RemoteFlow } from '../../../../schema';
import InsuranceRemoteValidation from './InsuranceRemoteValidation';
import Shield, { ShieldProps } from './Shield';

export type RemoteShieldProps = Pick<
    ShieldProps,
    'applicationId' | 'insuranceCompanyName' | 'handlerComponent' | 'submitted'
>;

const InsuranceRemoteShield = (props: RemoteShieldProps) => (
    <Shield
        {...props}
        flow={RemoteFlow.INSURANCE}
        // ignore ts validation on this prop for now as it's too strict
        // @ts-ignore
        validationComponent={InsuranceRemoteValidation}
    />
);

export default InsuranceRemoteShield;
