import React from 'react';
import { Channel } from '../../../../schema';
import { InsuranceApplicationDataFragment } from '../data.graphql';
import Express from './Express';
import New from './New';

export type VehicleStepProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
    disabled?: boolean;
};

const VehicleStep = ({ insuranceApplication, disabled }: VehicleStepProps) => {
    const channel = insuranceApplication?.channel;

    switch (channel) {
        case Channel.NEW:
            return <New insuranceApplication={insuranceApplication} />;

        case Channel.EXPRESS:
            return <Express disabled={disabled} />;

        default:
            return null;
    }
};

export default VehicleStep;
