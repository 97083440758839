// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { memo } from 'react';
import CustomerDetails from './CustomerDetails';
import DrivingLicenseDetails from './DrivingLicenseDetails';

export type CustomerProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
};

const Customer = ({ disabled, allowPrimaryInfoChanges }: CustomerProps) => {
    return (
        <Portlet name="Applicant Details" closable open>
            <div className="container-fluid">
                <CustomerDetails allowPrimaryInfoChanges={allowPrimaryInfoChanges} disabled={disabled} />
                <DrivingLicenseDetails disabled={disabled} />
            </div>
        </Portlet>
    );
};

export default memo(Customer);
