import PubSub from 'pubsub-js';
import { useEffect, useRef, useState } from 'react';
import { Flow } from './flow';

export type PersistedData = { token?: string };

export const usePersistedFlow = <State extends {}, PersistedState = Partial<State>>(
    initialCacheKey: string,
    pubSubChannel: string,
    serialize: (data: State, stepIdentifier: string) => string,
    unserialize: (serializedData: string) => PersistedState,
    generateCacheKey: (pubSubChannel: string, state: State) => string
): PersistedState | undefined => {
    const [cacheKey, setCacheKey] = useState<string>(initialCacheKey);

    const initialState = useRef<PersistedState | undefined | null>(null);

    if (initialState.current === null) {
        const serializedData = sessionStorage.getItem(cacheKey);

        initialState.current = serializedData ? unserialize(serializedData) : undefined;
    }

    useEffect(() => {
        const token = PubSub.subscribe(pubSubChannel, (msg: string, flow: Flow<State>) => {
            switch (msg) {
                case `${pubSubChannel}.initialized`:
                case `${pubSubChannel}.updated`:
                    sessionStorage.setItem(cacheKey, serialize(flow.state, flow.currentStep.identifier));
                    break;

                case `${pubSubChannel}.timeout`:
                case `${pubSubChannel}.completed`:
                    sessionStorage.removeItem(cacheKey);
                    break;

                case `${pubSubChannel}.updateCacheKey`: {
                    // generate new cache key
                    const key = generateCacheKey(pubSubChannel, flow.state);
                    // get data from storage
                    const data = sessionStorage.getItem(cacheKey);

                    if (data) {
                        // update local and session key
                        sessionStorage.setItem(key, data);
                        sessionStorage.removeItem(cacheKey);
                        setCacheKey(key);
                    }

                    break;
                }

                default:
                    break;
            }
        });

        return () => {
            PubSub.unsubscribe(token);
        };
    }, [pubSubChannel, cacheKey, serialize, generateCacheKey]);

    return initialState.current;
};
