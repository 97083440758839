import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const OTPBox = styled.div`
    border: 1px solid #ced4da;
    margin: 5px;
`;

export const OTPInput = styled.input`
    width: 40px;
    height: 50px;
    border: 0;
    background: transparent;
    outline: none;
    font-size: 2rem;
    text-align: center;
`;
