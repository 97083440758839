import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { attachLoading } from '../../../../actions';
import {
    resumeInsuranceApplication,
    ResumeInsuranceApplicationMutation,
    ResumeInsuranceApplicationMutationVariables,
} from '../../../../api/application.graphql';
import { getCompanyIdentifier } from '../../../../selectors';
import { handleResponseError } from '../../../../utilities/forms';
import { getInsuranceApplicationResumeUrl, getInsuranceApplicationsUrl } from '../../../../utilities/urls';
import { SubmitProvider } from '../ApplicationDetails';
import { ApplicationFormValues } from '../ApplicationForm';

const ResumeProvider: SubmitProvider = ({ children, application }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const history = useHistory();
    const { getState } = useStore();

    const onSubmit = useCallback(
        async ({ proceedWithCustomerDevice }: ApplicationFormValues) => {
            try {
                const promise = client.mutate<
                    ResumeInsuranceApplicationMutation,
                    ResumeInsuranceApplicationMutationVariables
                >({
                    mutation: resumeInsuranceApplication,
                    variables: {
                        id: application.id,
                        proceedWithCustomerDevice,
                    },
                });

                // attach the loader
                dispatch(attachLoading(promise));

                // wait for it
                const { data } = await promise;

                // get company and location code
                const { companyCode, locationCode } = getCompanyIdentifier(getState());

                if (proceedWithCustomerDevice) {
                    // go back to application list
                    const url = getInsuranceApplicationsUrl(companyCode, locationCode, application);
                    history.push(url, { submitted: true, application });

                    return null;
                }

                // get a token
                const token = data?.result?.token;

                if (!token) {
                    // that should not happen
                    throw new Error('Token missing in response');
                }

                // then go to resume flow
                const url = getInsuranceApplicationResumeUrl(companyCode, locationCode, application.id);
                history.push(url, { token });

                return null;
            } catch (error) {
                // process the error
                return handleResponseError(error as Error);
            }
        },
        [dispatch, history, getState, application, client]
    );

    return children(onSubmit);
};

export default ResumeProvider;
