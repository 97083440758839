// @ts-ignore
import { Portlet, PortletButton } from '@appvantageasia/afc-ui';
import { filter, get, isEmpty, omit } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useCompany, useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import {
    Channel,
    PromoCodeUnit,
    UploadPurpose,
    FinanceProductType,
    FinanceProductSubType,
    ApplicationPhase,
    TotalInterestPayableDisplayMode,
} from '../../../../schema';
import { getGlobalPermissions } from '../../../../selectors';
import { settlementInstalmentOnOptions, usePaymentModeOptions } from '../../../../utilities/constants/options';
import { createFormValidation, yupExt } from '../../../../utilities/forms';
import { PromoDataFragment } from '../../../data/useLoadPromo.graphql';
import { ApplicationFormValues } from '../../../routes/ApplicationRoute/ApplicationForm';
import { ApplicationDataFragment } from '../../../routes/ApplicationRoute/data.graphql';
import useCompanyFormatting, { Formats } from '../../../utilities/useCompanyFormatting';
import useFormValues from '../../../utilities/useFormValues';
import validateFile from '../../../utilities/validateFile';
import MonthlyInstallment from '../MonthlyInstallmentField';
import NumberField from '../NumberField';
import SelectField from '../SelectField';
import TextAreaField from '../TextAreaField';
import TextField from '../TextField';

export type FinanceStepProps = {
    application: ApplicationDataFragment;
    disabled?: boolean;
    openRecalculate?: () => void;
};

const useHasCoe = (channel: Channel) => {
    // check whether COE should be displayed based on country and channel
    const { code, channelSetting } = useCountry();

    if (code !== 'SG') {
        return false;
    }

    switch (channel) {
        case Channel.EXPRESS:
            return channelSetting.express.isCoeEnabled;

        case Channel.NEW:
            return channelSetting.new.isCoeEnabled;

        default:
            return false;
    }
};

const useHasDealerOptions = (channel: Channel) => {
    const { channelSetting } = useCountry();

    switch (channel) {
        case Channel.EXPRESS:
            return channelSetting.express.isDealerOptionsEnabled;

        case Channel.NEW:
            return channelSetting.new.isDealerOptionsEnabled;

        default:
            return false;
    }
};

const useHasPpsrAndEstablishment = (channel: Channel) => {
    // check whether Ppsr and Establishment should be displayed based on country and channel
    const { code, channelSetting } = useCountry();

    if (code !== 'NZ') {
        return false;
    }

    switch (channel) {
        case Channel.EXPRESS:
            return channelSetting.express.isPpsrAndEstablishmentEnabled;

        case Channel.NEW:
            return channelSetting.new.isPpsrAndEstablishmentEnabled;

        default:
            return false;
    }
};

const useHasLuxuryTax = (channel: Channel) => {
    // check whether Ppsr and Establishment should be displayed based on country and channel
    const { code, channelSetting } = useCountry();

    if (code !== 'TW') {
        return false;
    }

    switch (channel) {
        case Channel.EXPRESS:
            return channelSetting.express.isLuxuryTaxEnabled;

        case Channel.NEW:
            return channelSetting.new.isLuxuryTaxEnabled;

        default:
            return false;
    }
};

const formatPromoCode = (
    promoCode: PromoDataFragment,
    { formatCurrencyDown, formatPercentage }: Formats,
    carPrice?: number | null
) => {
    const { unit, value, identifier } = promoCode;

    // check if promo code or downpayment has value
    if (!value || !carPrice) {
        return identifier;
    }

    // build promo code
    const isExceededCarPrice = value > carPrice;
    const availablePromoPrice = isExceededCarPrice ? carPrice : value;

    const isPercentage = unit === PromoCodeUnit.PERCENTAGE;

    const formattedValue = isPercentage && carPrice ? (carPrice * value) / 100 : availablePromoPrice;

    const formatterPercentage = `(${formatPercentage(-value)})`;

    return `${identifier}: ${formatCurrencyDown(-formattedValue)} ${isPercentage ? formatterPercentage : ''}`;
};

const formatApplicationType = ({
    reservationDeposit,
    appliedForFinancing,
}: {
    reservationDeposit?: number | null;
    appliedForFinancing: boolean;
}) => {
    if (reservationDeposit && appliedForFinancing) {
        return 'Finance + Payment';
    }
    if (appliedForFinancing) {
        return 'Finance';
    }
    if (reservationDeposit) {
        return 'Payment';
    }

    return null;
};

const formatApplicationStatus = ({ text, extra }: { text: string; extra?: string | null }) => {
    if (extra) {
        return `${text} \r\n${extra}`;
    }

    return text;
};

const FinanceStep = ({ application, disabled = false, openRecalculate }: FinanceStepProps) => {
    const { mayViewRecalculate } = useSelector(getGlobalPermissions);
    const showCOE = useHasCoe(application.channel);
    const showDealerOptions = useHasDealerOptions(application.channel);
    const isPpsrAndEstablishmentEnabled = useHasPpsrAndEstablishment(application.channel);
    const isLuxuryTaxEnabled = useHasLuxuryTax(application.channel);
    const formats = useCompanyFormatting();
    const { code } = useCountry();
    const company = useCompany();

    const { formatPath } = useContentTranslation();
    const { t } = useTranslation();
    const paymentModeOptions = usePaymentModeOptions();

    const { calculator, promoCode: promo } = useFormValues<ApplicationFormValues>();
    const selectedPaymentMode = paymentModeOptions.find(item => item.value === calculator?.paymentMode)?.label;

    const showPaymentMode = get('financeProduct.paymentMode.show', application);

    const isApplication = !application.isLead;
    // format values
    const term = calculator?.tenure ? `${calculator.tenure} Months` : '';
    const applicationType = formatApplicationType(application);

    const isLeasing =
        application?.financeProduct?.type === FinanceProductType.LEASING &&
        application?.financeProduct?.subType === FinanceProductSubType.LEASE;

    const isBalloonGFV = application?.financeProduct?.subType === FinanceProductSubType.HIREPURCHASEBALLOONGFV;

    const canShowMarginOfFinance = (company.code === 'POR' && code === 'MY') || isBalloonGFV;

    const isUccl = application?.financeProduct?.subType === FinanceProductSubType.UCCLLEASING;

    const showResidualValue =
        application?.financeProduct?.type === FinanceProductType.FINANCE &&
        (application?.financeProduct?.subType === FinanceProductSubType.LEASEPURCHASE || isBalloonGFV);

    const showTradeIn = application?.financeProduct?.tradeInSetting?.show;

    const showTotalInterestPayable =
        application?.financeProduct?.totalInterestPayableSetting?.displayMode ===
        TotalInterestPayableDisplayMode.CALCULATOR;

    const promoCode = promo ? formatPromoCode(promo, formats, calculator?.carPrice) : null;

    const status = formatApplicationStatus(application.statusText);

    const action = openRecalculate && !disabled && mayViewRecalculate && application.financeProduct && (
        <PortletButton onClick={openRecalculate}>{t('applicationDetailsPage.button.recalculate')}</PortletButton>
    );

    return (
        <Portlet action={action} name={t('applicationDetailsPage.subHeading.financingDetails')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.bank')}
                            name={formatPath('bank.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.financialProduct')}
                            name={formatPath('financeProduct.name')}
                            disabled
                        />
                    </div>
                    {showPaymentMode && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                fixedValue={selectedPaymentMode}
                                label={t('calculator.label.paymentMode')}
                                name="calculator.paymentMode"
                                options={paymentModeOptions}
                                disabled
                            />
                        </div>
                    )}
                    {/* Hide Interest Rate for finance product type 'Leasing' and sub type 'Lease'  */}
                    {!isLeasing && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={t('applicationDetailsPage.label.interestRate')}
                                name="calculator.interestRate"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={term}
                            label={t('applicationDetailsPage.label.paymentTerm')}
                            name="paymentTerm"
                            disabled
                        />
                    </div>
                    {/* Hide Downpayment for finance product type 'Leasing' and sub type 'Lease'  */}
                    {!isLeasing && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('applicationDetailsPage.label.downPayment')}
                                name="calculator.downPayment.amount"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {canShowMarginOfFinance && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('applicationDetailsPage.label.marginOfFinance')}
                                name="calculator.loan.percentage"
                                type="percentage"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={
                                isBalloonGFV
                                    ? t('applicationDetailsPage.label.hpAmount')
                                    : t('applicationDetailsPage.label.loanAmount')
                            }
                            name="calculator.loan.amount"
                            type="currency"
                            disabled
                        />
                    </div>
                    {showCOE && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('applicationDetailsPage.label.coe')}
                                name="calculator.coe"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {isPpsrAndEstablishmentEnabled && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.ppsrFee')}
                                    name="calculator.ppsr"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.establishmentFee')}
                                    name="calculator.establishment"
                                    type="currency"
                                    disabled
                                />
                            </div>
                        </>
                    )}
                    {isLuxuryTaxEnabled && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('applicationDetailsPage.label.luxuryTaxFee')}
                                name="calculator.luxuryTax"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {company.code === 'POR' && code === 'TW' && isUccl && (
                        <>
                            {/* Display License Plate Fee only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.licensePlateFee')}
                                    name="calculator.licensePlateFee"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            {/* Display Commission only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={t('applicationDetailsPage.label.commission')}
                                    name="calculator.commission"
                                    disabled
                                />
                            </div>
                            {/* Display Fixed Interest Rate only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.fixedInterestRate')}
                                    name="calculator.fixedInterestRate"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            {/* Display License Tax and Fuel Tax only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.licenseAndFuelTax')}
                                    name="calculator.licenseAndFuelTax"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            {/* Display Displacement only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.displacement')}
                                    name="calculator.displacement"
                                    disabled
                                />
                            </div>
                            {/* Display Insurance Fee only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.insuranceFee')}
                                    name="calculator.insuranceFee.amount"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            {/* Display Tax loss only for finance product sub type UCCL Leasing */}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.taxLoss')}
                                    name="calculator.taxLoss"
                                    type="currency"
                                    disabled
                                />
                            </div>
                        </>
                    )}
                    {/* Display Security Deposit only for finance product type 'Leasing' and sub type 'Lease' */}
                    {isLeasing && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('applicationDetailsPage.label.securityDeposit')}
                                name="calculator.deposit.amount"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {showDealerOptions && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label="Dealer Options"
                                name="calculator.dealerOptions"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.totalPrice')}
                            name="calculator.totalPrice"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <MonthlyInstallment application={application} />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={promoCode}
                            label={t('applicationDetailsPage.label.promoCode')}
                            name="promoCode"
                            disabled
                        />
                    </div>
                    {isApplication && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={t('applicationDetailsPage.label.reservationDepositPaid')}
                                name="reservationDeposit"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {showResidualValue && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={
                                    isBalloonGFV
                                        ? t('applicationDetailsPage.label.assuredResaleValueField')
                                        : t('applicationDetailsPage.label.residualValue')
                                }
                                name="calculator.residualValue.amount"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {isBalloonGFV && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.estimatedSurplus')}
                                    name="calculator.estimatedSurplus"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label={t('applicationDetailsPage.label.settlementInstalment')}
                                    name="financeProduct.balloonGFVSetting.settlementInstalmentOn"
                                    options={settlementInstalmentOnOptions}
                                    disabled
                                />
                            </div>
                        </>
                    )}
                    {showTradeIn && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label="Trade-In Equity"
                                name="calculator.tradeInEquity"
                                type="currency"
                                allowNegative
                                disabled
                            />
                        </div>
                    )}
                    {showTotalInterestPayable && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label="Total Interest Payable"
                                name="calculator.totalInterestPayable"
                                type="currency"
                                allowNegative
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={applicationType}
                            label={t('applicationDetailsPage.label.applicationType')}
                            name="applicationType"
                            disabled
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextAreaField
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.remarks')}
                            name="remark"
                        />
                    </div>
                    {isApplication && application.phase === ApplicationPhase.FINANCE && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextAreaField
                                fixedValue={status}
                                label={t('applicationDetailsPage.label.applicationStatus')}
                                name="applicationStatus"
                                disabled
                            />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export const detailsSchema = {
    employment: yupExt.customerProperty().shape({
        assessableIncome: yupExt.customerProperty().shape({ value: yup.string().required('Required') }),
        assessmentYear: yupExt.customerProperty().shape({
            value: yup
                .string()
                .required('Required')
                .matches(/^[0-9]+$/, 'Must be only digits')
                .min(4, 'Must be exactly 4 digits')
                .max(4, 'Must be exactly 4 digits'),
        }),
        employer: yupExt.customerProperty().shape({
            value: yup.string().required('Required').max(124, 'Should be less then or equal to 124 characters'),
        }),
        occupation: yupExt.customerProperty().shape({
            value: yup.string().required('Required').max(100, 'Should be less then or equal to 100 characters'),
        }),
    }),
};

export const schema = yup.object().shape({
    details: yupExt.customerProperty().shape(detailsSchema),
    // @ts-ignore
    files: yup.lazy((value, { parent }) => {
        const { withMyInfo } = parent;

        const validator = yup
            .array()
            .transform(currentValue =>
                filter(item => {
                    if (item.purpose !== UploadPurpose.CUSTOMER_ATTACHED) {
                        // wrong purpose, skip it
                        return false;
                    }

                    // ensure it's not an empty item
                    return item instanceof File || !isEmpty(omit(['purpose'], item));
                }, currentValue)
            )
            // @ts-ignore
            .of(yup.mixed().test('extension-validation', 'File type not supported', validateFile));

        if (withMyInfo) {
            // it's not mandatory
            return validator;
        }

        return validator.min(1, 'Files are required');
    }),
});

export const validate = createFormValidation(schema);

export default FinanceStep;
