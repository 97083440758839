import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import LoginRoute from '../routes/LoginRoute';
import ResetPasswordRoute from '../routes/ResetPasswordRoute';
import { prefixLoginPath as prefixPath } from './shared';

const CompanyLoginRouter = () => (
    <Switch>
        <Route component={LoginRoute} path={prefixPath('/')} exact />
        <Route component={ResetPasswordRoute} path={prefixPath('/resetpassword')} exact />
        <Redirect to={prefixPath('/')} />
    </Switch>
);

export default CompanyLoginRouter;
