// @ts-ignore
import { Paging, TableList } from '@appvantageasia/afc-ui';
import { flow, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMedia from 'use-media';
import breakpoints from '../../../../utilities/constants/breakpoints';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import Status from '../../../ui/ApplicationStatus';
import { renderChannelOption } from '../../../ui/Cells';
import { Content } from '../../../ui/PageContainer';
import { TextAlignCenter } from '../../../ui/TextAlign';

const renderStatus = () => ({ latestApplication }) => {
    if (!latestApplication) {
        return '';
    }

    const {
        statusText: { label, color },
    } = latestApplication;

    return <Status color={color}>{label} </Status>;
};

const useMobileColumns = () => {
    // get formatting
    return useMemo(
        () => [
            {
                key: 'customer',
                sortKey: 'name.value',
                label: 'Customer',
                getValue: get('name.value'),
            },
            {
                key: 'latestApplicationId',
                sortKey: 'latestApplication.identifier',
                label: 'Latest Lead/Appl. ID',
                getValue: get('latestApplication.identifier'),
            },
            {
                key: 'status',
                sortKey: 'latestApplication.status',
                label: 'Status',
                getValue: renderStatus(get('latestApplication.statusText')),
            },
        ],
        []
    );
};

const useColumns = () => {
    const { t } = useTranslation();
    // get formatting
    const formatDateTime = useFormatDateTime();

    return useMemo(
        () => [
            {
                key: 'customer',
                sortKey: 'name.value',
                label: t('customersListPage.list.columns.customer'),
                getValue: get('name.value'),
            },
            {
                key: 'zone',
                sortKey: 'zone.code',
                label: t('customersListPage.list.columns.zone'),
                getValue: get('zone.code'),
            },
            {
                key: 'salesPerson',
                sortKey: 'latestApplication.assignee.name',
                label: t('customersListPage.list.columns.salesPerson'),
                getValue: get('latestApplication.assignee.name'),
            },
            {
                key: 'channel',
                sortKey: 'latestApplication.channel',
                label: t('customersListPage.list.columns.channel'),
                getValue: value => renderChannelOption(value?.latestApplication, t),
            },
            {
                key: 'latestApplicationId',
                sortKey: 'latestApplication.identifier',
                label: t('customersListPage.list.columns.leadOrApplicationID'),
                getValue: get('latestApplication.identifier'),
            },
            {
                key: 'status',
                sortKey: 'latestApplication.status',
                label: t('customersListPage.list.columns.status'),
                getValue: renderStatus(get('latestApplication.statusText')),
            },
            {
                key: 'lastActivity',
                sortKey: 'latestApplication.version.updatedAt',
                label: t('customersListPage.list.columns.lastActivity'),
                getValue: flow([get('latestApplication.version.updatedAt'), formatDateTime]),
            },
        ],
        [formatDateTime, t]
    );
};

const CustomerLists = ({ items, onItemClick, sorting, paging, loading }) => {
    const columns = useColumns();
    const mobileColumns = useMobileColumns();
    const { t } = useTranslation();
    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const renderMessage = () => {
        return !loading ? <TextAlignCenter>{t('customersListPage.list.noCustomers')}</TextAlignCenter> : null;
    };

    return items.length ? (
        <>
            <Content>
                {isMobile ? (
                    <TableList columns={mobileColumns} items={items} onItemClick={onItemClick} sorting={sorting} />
                ) : (
                    <TableList columns={columns} items={items} onItemClick={onItemClick} sorting={sorting} />
                )}
            </Content>
            <Paging {...paging} />
        </>
    ) : (
        renderMessage()
    );
};

CustomerLists.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    paging: PropTypes.shape({}),
    sorting: PropTypes.shape({}),
};

export default CustomerLists;
