/* eslint-disable */
import React from 'react';

const isIE =
    window &&
    (window.navigator.userAgent.indexOf('MSIE') !== -1 || window.navigator.appVersion.indexOf('Trident/') > 0);

class FileDrop extends React.PureComponent {
    static defaultProps = {
        frame: window ? window.document : undefined,
    };

    frameDragCounter;

    constructor(props) {
        super(props);
        this.frameDragCounter = 0;
        this.state = { draggingOverFrame: false, draggingOverTarget: false };
    }

    static eventHasFiles = event => {
        // In most browsers this is an array, but in IE11 it's an Object :(
        let hasFiles = false;
        const { types } = event.dataTransfer;
        for (const keyOrIndex in types) {
            if (types[keyOrIndex] === 'Files') {
                hasFiles = true;
                break;
            }
        }

        return hasFiles;
    };

    resetDragging = () => {
        this.frameDragCounter = 0;
        this.setState({ draggingOverFrame: false, draggingOverTarget: false });
    };

    handleWindowDragOverOrDrop = event => {
        // This prevents the browser from trying to load whatever file the user dropped on the window
        event.preventDefault();
    };

    handleFrameDrag = event => {
        // Only allow dragging of files
        if (!FileDrop.eventHasFiles(event)) {
            return;
        }

        // We are listening for events on the 'frame', so every time the user drags over any element in the frame's tree,
        // the event bubbles up to the frame. By keeping count of how many "dragenters" we get, we can tell if they are still
        // "draggingOverFrame" (b/c you get one "dragenter" initially, and one "dragenter"/one "dragleave" for every bubble)
        // This is far better than a "dragover" handler, which would be calling `setState` continuously.
        this.frameDragCounter += event.type === 'dragenter' ? 1 : -1;

        if (this.frameDragCounter === 1) {
            this.setState({ draggingOverFrame: true });
            if (this.props.onFrameDragEnter) {
                this.props.onFrameDragEnter(event);
            }

            return;
        }

        if (this.frameDragCounter === 0) {
            this.setState({ draggingOverFrame: false });
            if (this.props.onFrameDragLeave) {
                this.props.onFrameDragLeave(event);
            }
        }
    };

    handleFrameDrop = event => {
        if (!this.state.draggingOverTarget) {
            this.resetDragging();
            if (this.props.onFrameDrop) {
                this.props.onFrameDrop(event);
            }
        }
    };

    handleDragOver = event => {
        if (FileDrop.eventHasFiles(event)) {
            this.setState({ draggingOverTarget: true });
            if (!isIE) {
                event.dataTransfer.dropEffect = this.props.dropEffect;
            }
            if (this.props.onDragOver) {
                this.props.onDragOver(event);
            }
        }
    };

    handleDragLeave = event => {
        this.setState({ draggingOverTarget: false });
        if (this.props.onDragLeave) {
            this.props.onDragLeave(event);
        }
    };

    handleDrop = event => {
        if (this.props.onDrop && FileDrop.eventHasFiles(event)) {
            const files = event.dataTransfer ? event.dataTransfer.files : null;
            this.props.onDrop(files, event);
        }
        this.resetDragging();
    };

    stopFrameListeners = frame => {
        frame.removeEventListener('dragenter', this.handleFrameDrag);
        frame.removeEventListener('dragleave', this.handleFrameDrag);
        frame.removeEventListener('drop', this.handleFrameDrop);
    };

    startFrameListeners = frame => {
        frame.addEventListener('dragenter', this.handleFrameDrag);
        frame.addEventListener('dragleave', this.handleFrameDrag);
        frame.addEventListener('drop', this.handleFrameDrop);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.frame !== this.props.frame) {
            this.resetDragging();
            this.stopFrameListeners(this.props.frame);
            this.startFrameListeners(nextProps.frame);
        }
    }

    componentDidMount() {
        this.startFrameListeners(this.props.frame);
        this.resetDragging();
        window.addEventListener('dragover', this.handleWindowDragOverOrDrop);
        window.addEventListener('drop', this.handleWindowDragOverOrDrop);
    }

    componentWillUnmount() {
        this.stopFrameListeners(this.props.frame);
        window.removeEventListener('dragover', this.handleWindowDragOverOrDrop);
        window.removeEventListener('drop', this.handleWindowDragOverOrDrop);
    }

    render() {
        let className = 'file-drop';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }

        let fileDropTargetClassName = 'file-drop-target';
        if (this.state.draggingOverFrame) {
            fileDropTargetClassName += ' file-drop-dragging-over-frame';
        }
        if (this.state.draggingOverTarget) {
            fileDropTargetClassName += ' file-drop-dragging-over-target';
        }

        return (
            <div
                className={className}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
            >
                <div className={fileDropTargetClassName}>{this.props.children}</div>
            </div>
        );
    }
}

export default FileDrop;
