import styled from 'styled-components';

const ResetPasswordButton = styled.button`
    color: #3688ff;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-size: 100%;
    text-decoration: underline;
    background-color: transparent;
    outline: none;
`;

export default ResetPasswordButton;
