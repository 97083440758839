import { useMemo } from 'react';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import InsuranceRemoteFlow, {
    InsuranceRemoteFlowState,
} from '../../../../flows/InsuranceRemoteFlow/InsuranceRemoteFlow';
import { FlowType, useManagedFlow, useScrollTop } from '../../../../flows/utils/flow';
import useCompleteHandler from '../../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { InsuranceApplicationDataFragment } from '../../InsuranceRoute/data.graphql';

export type RemoteFlowHandlerProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
    consents: ConsentDataFragment[];
    token: string;
    submitted: boolean;
};

const getFlowImplementation = (channel: Channel): FlowType<InsuranceRemoteFlowState, InsuranceRemoteFlow> | null => {
    switch (channel) {
        case Channel.NEW:
        case Channel.EXPRESS:
            return InsuranceRemoteFlow;

        default:
            return null;
    }
};

const InsuranceRemoteFlowHandler = ({ insuranceApplication, consents, token, submitted }: RemoteFlowHandlerProps) => {
    // get zone and country from redux store
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();

    // create the initial state
    const initialState = useMemo(
        (): InsuranceRemoteFlowState => ({
            channel: insuranceApplication.channel,
            company,
            token,
            zone,
            country,
            insuranceApplication,
            source: ApplicationFlowSource.REMOTEINSURANCE,
            consents,
            submitted,
            insuranceCompany: insuranceApplication.insuranceCompany,
        }),
        [insuranceApplication, company, token, zone, country, consents, submitted]
    );

    const flowImplementation = getFlowImplementation(insuranceApplication.channel);

    if (!flowImplementation) {
        throw new Error('Channel not implemented');
    }

    const { step } = useManagedFlow(flowImplementation, initialState, 'insuranceRemoteFlow');

    useScrollTop(step.identifier);

    useCompleteHandler('insuranceRemoteFlow');

    return step.render();
};

export default InsuranceRemoteFlowHandler;
