import { useSelector } from 'react-redux';
import { FormErrors, getFormError } from 'redux-form';

type FormErrorProps = {
    form: string;
    children: (error: FormErrors<{}, string>) => JSX.Element;
    externalError?: string | null;
};

const FormError = ({ form, children, externalError = null }: FormErrorProps) => {
    const error = useSelector(getFormError(form)) || externalError;

    return error ? children(error) : null;
};

export default FormError;
