import { get, last } from 'lodash/fp';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import { FinderVehicleDataFragment } from '../../../../components/routes/FinderRoute/data.graphql';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { useZone } from '../../../../hookSelectors';
import { AssetCondition, Channel } from '../../../../schema';
import ShareProvider from '../../../utils/ShareProvider';
import { EventCalculatorStepValues } from '../../steps/EventCalculatorStep';
import Page from './Page';
import { Banner } from './blocks';

type RouterParams = { eventId: string; variantId: string };

export type CalculatorProps = {
    onSubmit: (values: EventCalculatorStepValues) => Promise<void>;
    initialValues?: Partial<CalculatorValues>;
    dealerId: string;
    snapshot?: any;
    event: EventDataFragment;
    finderVehicle: FinderVehicleDataFragment;
    hasTradeIn?: boolean;
    hasTestDrive?: boolean;
    isCalculatorEnabled?: boolean;
    appliedForFinancing?: boolean;
    appliedForInsurance?: boolean;
};

const Calculator = ({
    onSubmit,
    snapshot,
    initialValues: initialValuesFromProps,
    dealerId,
    event,
    finderVehicle,
    hasTestDrive,
    hasTradeIn,
    isCalculatorEnabled,
    appliedForFinancing,
    appliedForInsurance,
}: CalculatorProps) => {
    // get the initial variant id from react router
    const { eventId } = useParams<RouterParams>();
    const { id: zoneId } = useZone();

    // prepare for banner
    const [banner, setBanner] = useState(true);

    const closeBanner = useCallback(() => {
        setBanner(false);
    }, [setBanner]);

    const variant = useRef<VariantDataFragment>({
        id: 'EVENT',
        identifier: 'EVENT',
        name: { en: finderVehicle.name || '' },
        options: [],
        model: {
            id: 'EVENT',
            identifier: 'EVENT',
            order: 0,
            make: {
                id: 'EVENT',
                identifier: 'EVENT',
                name: '',
                order: 0,
            },
            makeId: 'EVENT',
            name: '',
            version: { id: 'EVENT' },
        },
        modelId: 'EVENT',
        prices: [
            {
                zoneId,
                value: finderVehicle.listing?.price.value || 0,
            },
        ],
        assetCondition: AssetCondition.USED,
        channels: { new: [], used: [], express: true },
        version: { id: finderVehicle.id, isOutdated: false },
        year: 0,
        order: 0,
        technicalDetails: {},
        usedCarDetails: {},
        images: [
            {
                id: get('listing.vehicle.images.edges[0].node.id', finderVehicle),
                url: last(finderVehicle.listing?.vehicle.images.edges[0].node.variants)?.url,
                metaData: { filename: '' },
            },
        ],
    });

    // get initial calculator values
    const initialCalculatorValues = useMemo(
        (): Partial<CalculatorValues> => ({ ...initialValuesFromProps, variant: variant.current.id }),
        [initialValuesFromProps]
    );

    const initialValues = useMemo(
        () => ({ hasTradeIn, hasTestDrive, isCalculatorEnabled, appliedForFinancing, appliedForInsurance }),
        [hasTestDrive, hasTradeIn, isCalculatorEnabled, appliedForFinancing, appliedForInsurance]
    );

    return (
        <>
            <HelmetTitle channel={Channel.EVENT} title="Calculator" />
            {banner && finderVehicle.url && <Banner onClose={closeBanner} url={finderVehicle.url} />}
            <ShareProvider channel={Channel.EVENT} dealerId={dealerId}>
                <Page
                    dealerId={dealerId}
                    event={event}
                    eventId={eventId}
                    finderVehicle={finderVehicle}
                    initialCalculatorValues={initialCalculatorValues}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    snapshot={snapshot}
                    variant={variant.current}
                />
            </ShareProvider>
        </>
    );
};

export default Calculator;
