import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationPhase } from '../../../../schema';
import DealerSelectionDropDown from '../../../ui/DealerSelectionDropDown';
import ApplicationList from './ApplicationList';
import LeadList from './LeadList';

const ListContainer = () => {
    const { t } = useTranslation();

    const [dealerIds, setDealerIds] = useState(null);

    const [{ financingCount, reservationCount, leadCount }, setSubmissionCounter] = useState({
        financingCount: 0,
        reservationCount: 0,
        leadCount: 0,
    });

    const isDealerFilterShown = financingCount > 0 || reservationCount > 0 || leadCount > 0;

    const handleFinancingCount = useCallback(counter => {
        setSubmissionCounter(oldCounter => ({ ...oldCounter, financingCount: counter }));
    }, []);

    const handleReservationCount = useCallback(counter => {
        setSubmissionCounter(oldCounter => ({ ...oldCounter, reservationCount: counter }));
    }, []);

    const handleLeadCount = useCallback(counter => {
        setSubmissionCounter(oldCounter => ({ ...oldCounter, leadCount: counter }));
    }, []);

    return (
        <>
            <DealerSelectionDropDown isShow={isDealerFilterShown} onValueChanged={setDealerIds} />
            <ApplicationList
                dealerIds={dealerIds}
                phase={ApplicationPhase.RESERVATION}
                setSubmissionCounter={handleReservationCount}
                title={t('eventDetailsPage.subHeading.reservationApplications')}
            />
            <ApplicationList
                dealerIds={dealerIds}
                phase={ApplicationPhase.FINANCE}
                setSubmissionCounter={handleFinancingCount}
                title={t('eventDetailsPage.subHeading.financeApplications')}
            />
            <LeadList dealerIds={dealerIds} setSubmissionCounter={handleLeadCount} />
        </>
    );
};

export default ListContainer;
