import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { setNotification } from '../../../../actions';
import {
    cleanPayGatePaymentSession,
    getPayGatePaymentSession,
} from '../../../../components/routes/wip/PayGatePaymentCallback';
import { ApplicationFlowSource } from '../../../../schema';
import { PayGateTransactionStatus } from '../../../../utilities/constants/PayGateTransactionStatus';

const usePayGatePayment = (applicationId: string, source: ApplicationFlowSource) => {
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const { t } = useTranslation();
    const [status, setStatus] = useState<PayGateTransactionStatus | null>(null);

    const processStatus = useCallback(
        payGateStatus => {
            switch (+payGateStatus) {
                case PayGateTransactionStatus.DECLINED:
                    dispatch(
                        setNotification(
                            t('notification.paymentRefused.title'),
                            t('notification.paymentRefused.message')
                        )
                    );
                    break;
            }

            setStatus(+payGateStatus);
        },
        [dispatch, t]
    );

    useEffect(() => {
        const session = getPayGatePaymentSession(applicationId, source);

        if (session) {
            cleanPayGatePaymentSession();
            processStatus(session.response?.status);
        }
    }, [applicationId, processStatus, source]);

    return useMemo(() => ({ status }), [status]);
};

export default usePayGatePayment;
