import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const InputBox = styled.div`
    border: 1px solid #ced4da;
    margin: 10px;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
`;

export const Input = styled.input`
    width: 45px;
    height: 55px;
    border: 0;
    background: transparent;
    outline: none;
    font-size: 2rem;
    text-align: center;
`;
