import { useTenantManagementContext } from '../shared/tenantManagementContext';

const useDealerIdFromTenantContext = () => {
    const {
        current: { dealerId },
    } = useTenantManagementContext();

    return dealerId;
};

export default useDealerIdFromTenantContext;
