import React from 'react';
import { useTranslation } from 'react-i18next';
import { FinanceProductSubType } from '../../../schema';
import { ApplicationDataFragment } from '../../routes/ApplicationRoute/data.graphql';
import useCompanyFormatting from '../../utilities/useCompanyFormatting';
import NumberField from './NumberField';
import TextField from './TextField';

const mapMonthlyInstalmentPrefix = (interestOnlyTerms: number, terms: number) => {
    const divisible = interestOnlyTerms % 12 === 0 && terms % 12 === 0;
    if (divisible) {
        const interestOnlyYear = interestOnlyTerms / 12;
        const totalYear = terms / 12;

        const firstText = interestOnlyYear === 1 ? 'Year 1' : `Year 1 to ${interestOnlyYear}`;

        const secondText =
            interestOnlyYear + 1 === totalYear
                ? `Year ${interestOnlyYear + 1}`
                : `Year ${interestOnlyYear + 1} to ${totalYear}`;

        return [firstText, secondText];
    }

    const firstText = interestOnlyTerms === 1 ? 'Month 1' : `Month 1 to ${interestOnlyTerms}`;

    const secondText =
        interestOnlyTerms + 1 === terms
            ? `Month ${interestOnlyTerms + 1}`
            : `Month ${interestOnlyTerms + 1} to ${terms}`;

    return [firstText, secondText];
};

type computeDisplayMonthlyInstalmentResponse = {
    monthlyInstallmentPrefix: string;
    subsequentMonthlyPaymentPrefix: string;
};

const computeDisplayMonthlyInstalment = (
    application: ApplicationDataFragment
): computeDisplayMonthlyInstalmentResponse | null => {
    const { calculator, financeProduct } = application;
    const { term } = calculator;
    const termSetting = financeProduct?.termSetting;
    const interestOnly = termSetting?.interestOnly;

    if (interestOnly && term) {
        const [monthlyInstallmentPrefix, subsequentMonthlyPaymentPrefix] = mapMonthlyInstalmentPrefix(
            interestOnly,
            term
        );

        return {
            monthlyInstallmentPrefix,
            subsequentMonthlyPaymentPrefix,
        };
    }

    return null;
};

export type MonthlyInstallmentProps = {
    application: ApplicationDataFragment;
};

const MonthlyInstallment = ({ application }: MonthlyInstallmentProps) => {
    const { t } = useTranslation();
    const { formatCurrency } = useCompanyFormatting();
    const { calculator } = application;

    if (calculator?.monthlyInstalments && calculator?.monthlyInstalments?.length > 1) {
        switch (application.financeProduct?.subType) {
            case FinanceProductSubType.DEFERREDPRINCIPAL: {
                const computeDisplay = computeDisplayMonthlyInstalment(application);

                if (computeDisplay === null) {
                    return null;
                }

                const { monthlyInstallmentPrefix, subsequentMonthlyPaymentPrefix } = computeDisplay;
                const firstInstallment = calculator?.monthlyInstalments?.[0].amount;
                const secondInstallment = calculator?.monthlyInstalments?.[1].amount;

                return (
                    <>
                        <TextField
                            fixedValue={formatCurrency(firstInstallment)}
                            label={`${t('applicationDetailsPage.label.monthlyInstalment')} ${monthlyInstallmentPrefix}`}
                            disabled
                        />

                        <TextField
                            fixedValue={formatCurrency(secondInstallment)}
                            label={`${t(
                                'applicationDetailsPage.label.monthlyInstalment'
                            )} ${subsequentMonthlyPaymentPrefix}`}
                            disabled
                        />
                    </>
                );
            }

            case FinanceProductSubType.HIREPURCHASEBALLOONGFV:
                return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {calculator.monthlyInstalments.map(({ amount, start, end }) => (
                            <TextField
                                fixedValue={formatCurrency(amount)}
                                label={t(
                                    start !== end
                                        ? 'applicationDetailsPage.label.gfvMonthlyInstalments'
                                        : 'applicationDetailsPage.label.gfvMonthlyInstalment',
                                    {
                                        start,
                                        end,
                                    }
                                )}
                                disabled
                            />
                        ))}
                    </>
                );

            default:
                return null;
        }
    }

    return (
        <NumberField
            label={t('applicationDetailsPage.label.monthlyInstalment')}
            name="calculator.monthlyInstalments[0].amount"
            type="currency"
            disabled
        />
    );
};

export default MonthlyInstallment;
