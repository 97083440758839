import styled from 'styled-components';
import IconButton from '../IconButton';

const HeaderController = styled.div`
    align-items: center;
    & > ${IconButton} {
        svg {
            color: #666;
            width: 20px;
            font-size: 1.28rem;
        }
    }

    & > *:not(:last-child) {
        margin-right: 20px;
    }
`;

export default HeaderController;
