import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setScope } from '../../actions/context';
import {
    getScope,
    CompanyScopeFragment,
    CountryScopeFragment,
    ZoneScopeFragment,
    GetScopeQuery,
} from './useLoadScope.graphql';

export type Company = CompanyScopeFragment;

export type Country = CountryScopeFragment;

export type Zone = ZoneScopeFragment;

export type Scope = {
    company?: Company | null;
    country?: Country | null;
    zone?: Zone | null;
};

const emptyScope = { company: null, country: null, zone: null };

const useLoadScope = (companyCode: string, countryCode: string, zoneCode: string): [boolean, Scope | null] => {
    // use apollo to fetch data
    const variables = useMemo(() => ({ companyCode, countryCode, zoneCode }), [companyCode, countryCode, zoneCode]);
    const { loading, data, refetch } = useQuery<GetScopeQuery>(getScope, {
        variables,
        fetchPolicy: 'cache-and-network',
    });

    // update the redux state
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setScope(data?.scope || emptyScope));
    }, [dispatch, data]);

    // refetch when the scope changed
    const previousVariables = useRef(variables);
    useEffect(() => {
        if (previousVariables.current !== variables) {
            refetch(variables);
            previousVariables.current = variables;
        }
    }, [variables, refetch]);

    return [loading, data?.scope || null];
};

export default useLoadScope;
