export const parseJsonFromResponse = async response => {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
    }

    return response;
};

export const writeAuthorizationHeader = accessToken => `Bearer ${accessToken}`;
