import { format } from 'date-fns';
import { isNil } from 'lodash/fp';
import numeral from 'numeral';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import { useZone } from '../../../../../hookSelectors';
import { Block, Container, SubTitle, getSizingStyle, Row } from '../ui';

const List = styled.ol`
    width: 100%;
    padding-left: 0;

    li {
        padding: 10px 0;
        color: #444444;
        display: flex;
        ${getSizingStyle('p')}
        border-bottom: 1px solid #ced4da;
        justify-content: space-between;

        span {
            font-weight: normal;
            color: #666;
        }
    }
`;

type Item = { label: string; value: string };

export const useColumns = <T extends unknown>(items: T[], numberOfColumns: number): T[][] =>
    useMemo(() => {
        const columnMinSize = Math.floor(items.length / numberOfColumns);
        const remainingLeftOver = items.length - columnMinSize * numberOfColumns;

        return items.reduce<T[][]>((acc, item, index) => {
            let columnIndex = acc.length - 1;

            if (columnIndex < 0) {
                // special condition to create the first column
                acc[0] = [];
                // update column index
                columnIndex = 0;
            }

            const hasLeftOver = columnIndex < remainingLeftOver;
            const columnMaxSize = columnMinSize + (hasLeftOver ? 1 : 0);

            if (acc[columnIndex].length === columnMaxSize) {
                // this is a new column
                acc.push([]);
                // update column index
                columnIndex += 1;
            }

            // add to the last group
            acc[columnIndex].push(item);

            return acc;
        }, []);
    }, [items, numberOfColumns]);

export type DetailsProps = {
    variant: VariantDataFragment;
};

const formatNumber = (value: number): string => numeral(value).format('0,0');

const Details = ({ variant }: DetailsProps): ReactElement => {
    const { t } = useTranslation();
    const { id: zoneId } = useZone();
    const { formatCurrency } = useCompanyFormatting();

    // compute items at first
    const computedItems = useMemo((): Item[] => {
        const price = variant.prices.find(item => item.zoneId === zoneId)?.value;
        const { usedCarDetails, technicalDetails } = variant;

        return [
            { label: t('usedCalculatorPage.usedCarDetails.price'), value: formatCurrency(price) },
            !isNil(usedCarDetails.depreciation) && {
                label: t('usedCalculatorPage.usedCarDetails.depreciation'),
                value: t('usedCalculatorPage.usedCarDetails.depreciationValue', {
                    depreciation: formatCurrency(usedCarDetails.depreciation),
                }),
            },
            !isNil(usedCarDetails.mileage) && {
                label: t('usedCalculatorPage.usedCarDetails.mileage'),
                value: t('usedCalculatorPage.usedCarDetails.mileageValue', {
                    mileage: formatNumber(usedCarDetails.mileage),
                }),
            },
            !isNil(usedCarDetails.registrationDate) && {
                label: t('usedCalculatorPage.usedCarDetails.registrationDate'),
                value: format(new Date(usedCarDetails.registrationDate), t('dateFormats.dateFormat')),
            },
            { label: t('usedCalculatorPage.usedCarDetails.manufactured'), value: variant.year },
            !isNil(usedCarDetails.omv) && {
                label: t('usedCalculatorPage.usedCarDetails.omv'),
                value: formatCurrency(usedCarDetails.omv),
            },
            !isNil(usedCarDetails.arf) && {
                label: t('usedCalculatorPage.usedCarDetails.arf'),
                value: formatCurrency(usedCarDetails.arf),
            },
            !isNil(usedCarDetails.coe) && {
                label: t('usedCalculatorPage.usedCarDetails.coe'),
                value: formatCurrency(usedCarDetails.coe),
            },
            !isNil(usedCarDetails.deregistrationValue) && {
                label: t('usedCalculatorPage.usedCarDetails.deregistrationValue'),
                value: formatCurrency(usedCarDetails.deregistrationValue),
            },
            !isNil(usedCarDetails.roadTax) && {
                label: t('usedCalculatorPage.usedCarDetails.roadTax'),
                value: t('usedCalculatorPage.usedCarDetails.roadTaxValue', {
                    roadTax: formatCurrency(usedCarDetails.roadTax),
                }),
            },
            !isNil(usedCarDetails.noOfOwners) && {
                label: t('usedCalculatorPage.usedCarDetails.noOfOwners'),
                value: usedCarDetails.noOfOwners,
            },
            !isNil(technicalDetails.displacement) && {
                label: t('usedCalculatorPage.usedCarDetails.displacement'),
                value: t('usedCalculatorPage.usedCarDetails.displacementValue', {
                    displacement: formatNumber(technicalDetails.displacement),
                }),
            },
            !isNil(technicalDetails.ratedOutput) && {
                label: t('usedCalculatorPage.usedCarDetails.ratedOutput'),
                value: t('usedCalculatorPage.usedCarDetails.ratedOutputValue', {
                    ratedOutput: formatNumber(technicalDetails.ratedOutput),
                }),
            },
            !isNil(technicalDetails.ratedTorque) && {
                label: t('usedCalculatorPage.usedCarDetails.ratedTorque'),
                value: t('usedCalculatorPage.usedCarDetails.ratedTorqueValue', {
                    ratedTorque: formatNumber(technicalDetails.ratedTorque),
                }),
            },
            !isNil(technicalDetails.transmission) && {
                label: t('usedCalculatorPage.usedCarDetails.transmission'),
                value: technicalDetails.transmission,
            },
            !isNil(technicalDetails.fuelConsumption) && {
                label: t('usedCalculatorPage.usedCarDetails.fuelConsumption'),
                value: t('usedCalculatorPage.usedCarDetails.fuelConsumptionValue', {
                    fuelConsumption: formatNumber(technicalDetails.fuelConsumption),
                }),
            },
            !isNil(technicalDetails.co2Emissions) && {
                label: t('usedCalculatorPage.usedCarDetails.co2Emissions'),
                value: t('usedCalculatorPage.usedCarDetails.co2EmissionsValue', {
                    co2Emissions: formatNumber(technicalDetails.co2Emissions),
                }),
            },
            !isNil(technicalDetails.cylinderArrangementNo) && {
                label: t('usedCalculatorPage.usedCarDetails.cylinderArrangementNo'),
                value: formatNumber(technicalDetails.cylinderArrangementNo),
            },
            !isNil(technicalDetails.numberOfAirbags) && {
                label: t('usedCalculatorPage.usedCarDetails.numberOfAirbags'),
                value: formatNumber(technicalDetails.numberOfAirbags),
            },
            !isNil(technicalDetails.driveTrain) && {
                label: t('usedCalculatorPage.usedCarDetails.driveTrain'),
                value: technicalDetails.driveTrain,
            },
        ].filter(Boolean) as Item[];
    }, [variant, t, formatCurrency, zoneId]);

    // then predict how many columns we want
    const { display } = useTheme();

    // split the items
    const columns = useColumns(computedItems, display === 'large' ? 3 : 1);

    const columnsElements = columns.map((items, index) => (
        <List key={index.toString()}>
            {items.map(item => (
                <li key={item.label}>
                    <span>{item.label}</span>
                    {item.value}
                </li>
            ))}
        </List>
    ));

    return (
        <Block id="section-details">
            <Container>
                <SubTitle>{t('usedCalculatorPage.usedCarDetails.title')}</SubTitle>
                {columns.length > 1 ? <Row columns={columns.length}>{columnsElements}</Row> : columnsElements}
            </Container>
        </Block>
    );
};

export default Details;
