// @ts-ignore
import { DarkButton, GrayButton, Actions, Modal } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, WrappedFieldProps } from 'redux-form';
import { CommonFieldProps } from 'redux-form/lib/Field';
import { ConsentDataFragment } from '../../../../api/consents.graphql';
import ConsentText from '../../../../components/ui/calculator/ConsentText';
import PrettyCheckbox from '../../../../components/ui/form/PrettyCheckbox';
import { useContentTranslation } from '../../../../i18n';

type ConsentInputProps = {
    consent: ConsentDataFragment;
};

const ConsentInput = ({ input, consent }: WrappedFieldProps & ConsentInputProps) => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();
    const { value = false, onChange, name } = input;
    const [show, setShow] = useState(false);

    const label = get(formatPath('name'), consent);
    const description = get(formatPath('description'), consent);
    const legalMarkup = get(formatPath('legalMarkup'), consent);

    const isChecked = Boolean(value);

    const onClick = useCallback(
        event => {
            // prevent the event
            event.preventDefault();

            if (legalMarkup) {
                // use the modal to confirm the action
                setShow(true);
            } else {
                // directly toggle the checked state
                onChange(!isChecked);
            }

            return false;
        },
        [onChange, legalMarkup, isChecked]
    );

    const closeWindow = (nextValue: boolean) => () => {
        onChange(nextValue);
        setShow(false);
    };

    return (
        <>
            <PrettyCheckbox checked={isChecked} label={label} name={name} onClick={onClick}>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: description || '' }} />
            </PrettyCheckbox>
            {show && (
                <Modal onClose={closeWindow(false)} title={label} showTitle>
                    {legalMarkup && <ConsentText dangerouslySetInnerHTML={{ __html: legalMarkup }} />}
                    <Actions>
                        <GrayButton onClick={closeWindow(false)}>{t('consentPage.button.disagree')}</GrayButton>
                        <DarkButton onClick={closeWindow(true)}>{t('consentPage.button.agree')}</DarkButton>
                    </Actions>
                </Modal>
            )}
        </>
    );
};

const ConsentField = (props: ConsentInputProps & Partial<CommonFieldProps>) => (
    <Field {...props} component={ConsentInput} />
);

export default ConsentField;
