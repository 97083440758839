import React from 'react';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { FlowStep } from '../../utils/flow';
import getDealerIdFromFlowState from '../../utils/getDealerIdFromFlowState';
import ExpressCalculator from '../components/ExpressCalculator';
import { ExpressVariant, NewFlowState } from '../types';
import { CalculatorStepValues } from './CalculatorStep';

export type ExpressCalculatorStepValues = CalculatorStepValues & {
    variant: ExpressVariant;
    isInsuranceEnabled?: boolean;
    isFinanceEnabled?: boolean;
};

class ExpressCalculatorStep extends FlowStep<NewFlowState, ExpressCalculatorStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'calculator';
    }

    public render(): React.ReactElement | null {
        const { calculator, snapshot, expressVariant, channel } = this.state;
        const dealerId = getDealerIdFromFlowState(this.state);

        return (
            <>
                <HelmetTitle channel={channel} title="Calculator" />
                <ExpressCalculator
                    dealerId={dealerId}
                    initialValues={calculator}
                    initialVariant={expressVariant}
                    onSubmit={this.submit}
                    snapshot={snapshot}
                />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute({
        calculator,
        bank,
        variant,
        promo,
        financeProduct,
        isInsuranceEnabled,
        isFinanceEnabled,
    }: ExpressCalculatorStepValues) {
        // persist bank and calculator
        this.stateStore.update({
            calculator,
            bank,
            snapshot: undefined,
            expressVariant: variant,
            promo,
            financeProduct,
            withInsurance: isInsuranceEnabled,
            withFinancing: isFinanceEnabled,
        });

        return this.nextStep;
    }
}

export default ExpressCalculatorStep;
