import React from 'react';
import { RemoteFlow } from '../../../../schema';
import RemoteData from '../RemoteRoute/RemoteData';
import InsuranceRemoteBootstrap from './InsuranceRemoteBootstrap';
import InsuranceRemoteShield from './InsuranceRemoteShield';
import RemoteHandler from './RemoteHandler';

const RemoteRoute = () => (
    <RemoteData flow={RemoteFlow.INSURANCE}>
        {context => (
            <InsuranceRemoteBootstrap
                context={context}
                handlerComponent={RemoteHandler}
                shieldComponent={InsuranceRemoteShield}
            />
        )}
    </RemoteData>
);

export default RemoteRoute;
