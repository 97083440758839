import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZoneScopeFragment } from '../../../../components/data/useLoadScope.graphql';
import { VariantDataFragment } from '../../../../components/data/useLoadVariants.graphql';
import {
    getReservedAppointmentDates,
    GetReservedAppointmentDatesQuery,
    GetReservedAppointmentDatesQueryVariables,
} from '../../../../components/routes/AppointmentRoute/Detail/Details.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import HelmetTitle from '../../../../components/shared/HelmetTitle';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { ApplicationFlowSource, Channel } from '../../../../schema';
import { MiniConfiguratorDetails } from '../../../ConfiguratorFlow/components/Summary/Summary';
import Navigation from '../../../utils/Navigation';
import { BackStepContext } from '../../../utils/flow';
import { CarOfInterestType } from '../../steps/EventDraftStep';
import { EventGrid, Page, PageTitle, Space } from '../DraftPage/ui';
import DealerInformation from '../shared/DealerInformation';
import SelectedCar from '../shared/SelectedCar';
import AppointmentFlowForm from './AppointmentFlowForm';

export type AppointmentStepValues = {
    appointmentDate: Date;
};

export type AppointmentFlowPageProps = {
    backStep?: BackStepContext;
    channel: Channel;
    dealerId: string;
    calculator?: Partial<CalculatorValues>;
    event: EventDataFragment;
    isLastStep: boolean;
    onSubmit: (values: AppointmentStepValues) => Promise<void>;
    variant?: VariantDataFragment;
    carOfInterest?: CarOfInterestType;
    miniConfiguratorDetails?: MiniConfiguratorDetails;
    source: ApplicationFlowSource;
    zone: ZoneScopeFragment;
    isCalculatorEnabled?: boolean;
};

const AppointmentFlowPage = ({
    backStep,
    calculator,
    event,
    variant,
    carOfInterest,
    miniConfiguratorDetails,
    dealerId,
    onSubmit,
    zone,
    isCalculatorEnabled,
}: AppointmentFlowPageProps) => {
    const { t } = useTranslation();

    const { data, loading } = useQuery<GetReservedAppointmentDatesQuery, GetReservedAppointmentDatesQueryVariables>(
        getReservedAppointmentDates,
        {
            fetchPolicy: 'network-only',
            variables: {
                filter: {
                    zoneId: zone.id,
                    dealerIds: [dealerId],
                },
            },
            skip: !dealerId || !zone.id,
        }
    );

    if (loading) {
        return null;
    }

    return (
        <Page>
            <HelmetTitle channel={Channel.EVENT} title="Book an Appointment" />
            {backStep && <Navigation onPrev={backStep.goTo} />}
            <PageTitle>{t('eventAppointmentPage.title')}</PageTitle>
            <EventGrid maxColumn={2}>
                <Space>
                    <SelectedCar
                        calculator={calculator}
                        carOfInterest={carOfInterest}
                        dealerId={dealerId}
                        event={event}
                        isCalculatorEnabled={isCalculatorEnabled}
                        miniConfiguratorDetails={miniConfiguratorDetails}
                        variant={variant}
                    />
                    <DealerInformation dealerId={dealerId} />
                </Space>
                <Space>
                    <AppointmentFlowForm onSubmit={onSubmit} reservedAppointments={data?.reserved ?? []} zone={zone} />
                </Space>
            </EventGrid>
        </Page>
    );
};

export default AppointmentFlowPage;
