import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    GetApplicationByTokenQuery,
    GetApplicationByTokenQueryVariables,
    getApplicationByToken,
} from '../../../api/application.graphql';
import { guaranteedBuybackSession } from '../../../components/routes/wip/GuaranteedBuybackCallback';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ApplicationFlowSource, GuaranteedBuybackSigner, SigningStatus } from '../../../schema';
import useLoading from '../../../useLoading';
import { maxRetries, retryGapInMilliseconds, useBlock } from './Namirial';

const useGuaranteedSigningInfo = (token: string, source: ApplicationFlowSource) => {
    const client = useApolloClient();
    const zone = useZone();
    const company = useCompany();
    const country = useCountry();
    const history = useHistory();

    const path = history.location.pathname;

    useEffect(() => {
        let tries = 0;
        let timeoutId: number;

        const handler = async () => {
            if (tries > maxRetries) {
                window.clearInterval(timeoutId);

                throw new Error('Fail to create guaranteed buyback signing');
            }

            // Get application journey
            const response = await client.query<GetApplicationByTokenQuery, GetApplicationByTokenQueryVariables>({
                query: getApplicationByToken,
                variables: { token },
                fetchPolicy: 'network-only',
            });

            // Ensure path is still the same
            if (path !== history.location.pathname) {
                return;
            }

            const { application } = response.data;
            const signingId = application?.guaranteedBuybackSigners?.signing?.id;

            const dealerRedirectionUrl = application?.guaranteedBuybackSigners?.dealer?.redirectionUrl;
            const customerRedirectionUrl = application?.guaranteedBuybackSigners?.customer?.redirectionUrl;

            const isDealerCompleted = application?.guaranteedBuybackSigners?.dealer?.status === SigningStatus.COMPLETED;

            const usedRedirectionUrl = isDealerCompleted ? customerRedirectionUrl : dealerRedirectionUrl;

            const signer =
                application?.guaranteedBuybackSigners?.dealer?.status === SigningStatus.INPROGRESS
                    ? GuaranteedBuybackSigner.DEALER
                    : GuaranteedBuybackSigner.CUSTOMER;

            if (signingId && usedRedirectionUrl) {
                // Start namirial session is just
                // storing to localstorage
                // and redirecting back to redirection url
                guaranteedBuybackSession.start({
                    applicationId: application.id,
                    applicationVersionId: application.version.id,
                    applicationPhase: application.phase,
                    channel: application.channel,
                    companyCode: company.code,
                    countryCode: country.code,
                    envelopeId: signingId,
                    originApplicationVersionId: application.version.id,
                    redirectionUrl: usedRedirectionUrl,
                    source,
                    zoneCode: zone.code,
                    origin: window.location.href,
                    guaranteedBuybackSigner: signer,
                    isBankSigning: true,
                    isGuaranteedBuybackSessionEnabled: true,
                });
            } else {
                tries += 1;
                timeoutId = window.setTimeout(handler, retryGapInMilliseconds);
            }
        };

        timeoutId = window.setTimeout(handler, retryGapInMilliseconds);

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [client, company.code, country.code, history.location.pathname, path, source, token, zone.code]);
};

/**
 * Guaranteed buyback namirial is different with Namirial component
 *
 * Namirial only handled agreement PDF for application for 1 recipient
 * While guaranteed buyback need to handle two recipient for one envelope
 *
 * We should use, reusable component/hook as much as possible
 */
type GuaranteedBuybackNamirialProps = {
    token: string;
    source: ApplicationFlowSource;
};
const GuaranteedBuybackNamirial = ({ token, source }: GuaranteedBuybackNamirialProps) => {
    useBlock();

    useGuaranteedSigningInfo(token, source);

    useLoading(true);

    return null;
};

export default GuaranteedBuybackNamirial;
