// @ts-ignore
import { Portlet, Paging, TableList } from '@appvantageasia/afc-ui';
import { flow, get, orderBy } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext, getFormValues } from 'redux-form';
import { useContentTranslation } from '../../../../i18n';
import { renderActive, renderNameOrDescription } from '../../../ui/Cells';
import { Content } from '../../../ui/PageContainer';
import usePaging from '../../../utilities/usePaging';
import useSorting from '../../../utilities/useSorting';

const useColumns = () => {
    const { t } = useTranslation();
    const { ct, formatPath } = useContentTranslation();

    return useMemo(
        () => [
            {
                key: 'name',
                sortKey: 'name',
                label: t('eventDetailsPage.label.nameAndDescription'),
                getValue: renderNameOrDescription('name', 'description', ct),
            },
            {
                key: 'hasCheckbox',
                sortKey: 'hasCheckbox',
                label: t('eventDetailsPage.label.checkbox'),
                getValue: renderActive('hasCheckbox'),
                textAlignCenter: true,
            },
            {
                key: 'isMandatory',
                sortKey: 'isMandatory',
                label: t('eventDetailsPage.label.mandatory'),
                getValue: renderActive('isMandatory'),
                textAlignCenter: true,
            },
            {
                key: 'isActive',
                sortKey: 'isActive',
                label: t('eventDetailsPage.label.active'),
                getValue: renderActive('isActive'),
                textAlignCenter: true,
            },
        ],
        [formatPath, t]
    );
};

const Consents = () => {
    const { t } = useTranslation();
    // @ts-ignore
    const { form } = useContext(ReduxFormContext);
    // @ts-ignore
    const { consentsAndDeclarations: items = [] } = useSelector(getFormValues(form)) || {};

    const columns = useColumns();

    // then sort
    const sorting = useSorting({ initialKey: 'version.updatedAt' });
    // @ts-ignore
    const sortedItems = useMemo(() => orderBy(sorting.key, sorting.order, items), [items, sorting]);

    // and finally paging
    const [pagedItems, paging] = usePaging(sortedItems);

    // there were no items
    if (!items.length) {
        return null;
    }

    return (
        <Portlet name={t('eventDetailsPage.subHeading.consents')} closable open>
            <div className="container-fluid">
                <Content>
                    <TableList columns={columns} items={pagedItems} sorting={sorting} />
                </Content>
                <Paging {...paging} />
            </div>
        </Portlet>
    );
};

export default Consents;
