// @ts-ignore
import React, { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getEventsUrl } from '../../../../utilities/urls';
import Title from '../../../shared/form-v2/Title';
import FixedMenuFormTabContainer from '../../../ui/form/FixedMenuFormTab';
import { useListContextFromState } from '../../../utilities/useListContext';
import Navigation from '../../NewApplicationRoutes/shared/Navigation';
import EventDetails from './EventDetails';
import { EventData } from './index';

export type DetailsProps = {
    event: EventData;
};

const Details = ({ event }: DetailsProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [listContext] = useListContextFromState();
    const onPrev = useCallback(() => {
        // @ts-ignore
        history.pushWithCompanyAndState(getEventsUrl, { listContext });
    }, [history, listContext]);

    return (
        <FixedMenuFormTabContainer style={{ marginBottom: '50px' }}>
            <Navigation onPrev={onPrev} prevText={t('eventDetailsPage.button.previous')} />
            <Title>{event.name}</Title>
            <EventDetails key="event" event={event} />
        </FixedMenuFormTabContainer>
    );
};

export default memo(Details);
