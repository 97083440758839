import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../selectors';
import { SubTitle } from '../Configurator/shared/ui';
import { Row, SectionContainer } from './shared/ui';

type ExteriorSectionProps = {
    formatCurrency: (price: number) => string;
    exteriorColor: string;
};

const ExteriorSection = ({ formatCurrency, exteriorColor }: ExteriorSectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const exterior = useMemo(
        () => ckdConfiguration.exterior.options.find((item: { name: string }) => item.name === exteriorColor),
        [exteriorColor, ckdConfiguration.exterior.options]
    );

    return (
        <SectionContainer>
            <SubTitle>{ckdConfiguration.summary.exteriorColor}</SubTitle>
            <Row>
                <span>{exterior.label}</span>
                <span>{ckdConfiguration.exterior.price}</span>
            </Row>
        </SectionContainer>
    );
};

export default ExteriorSection;
