// @ts-ignore
import { DarkButton, Actions } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm, InjectedFormProps, FormErrors } from 'redux-form';
import * as yup from 'yup';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { createFormValidation } from '../../../../utilities/forms';
import CodeField from '../../../shared/form/CodeField';
import FormError from '../../../shared/form/FormError';
import { TextAlignCenter } from '../../../ui/TextAlign';
import { Container, Notice, Title } from '../../../ui/calculator';
import { Error } from '../../../ui/form/FormActions';

const codeLength = 4;

export type InsuranceRemoteValidationFormProps = {
    insuranceCompanyName: { [key: string]: string };
};

const RemoteValidationForm = ({
    valid,
    handleSubmit,
    insuranceCompanyName,
}: InsuranceRemoteValidationFormProps & InjectedFormProps<any, InsuranceRemoteValidationFormProps>) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const { code } = useCountry();

    return (
        <form onSubmit={handleSubmit}>
            <Container>
                <Title>{t('remoteInsurancePage.title')}</Title>
                <Notice>
                    {t(
                        [
                            `remoteInsurancePage.description.${code.toLowerCase()}`,
                            'remoteInsurancePage.description.others',
                        ],
                        {
                            insurance: ct(insuranceCompanyName),
                        }
                    )}
                </Notice>
                <CodeField length={codeLength} name="code" />
                <FormError form="validationCode">
                    {(error: FormErrors<{}, string>) => (
                        <TextAlignCenter>
                            <Error>{error}</Error>
                        </TextAlignCenter>
                    )}
                </FormError>
                <Actions>
                    <DarkButton disabled={!valid} type="submit">
                        {t('remoteInsurancePage.button.next')}
                    </DarkButton>
                </Actions>
            </Container>
        </form>
    );
};

const schema = yup.object().shape({
    code: yup.string().test('len', '', val => val?.length === codeLength),
});

const InsuranceRemoteValidation = reduxForm<any, InsuranceRemoteValidationFormProps>({
    form: 'validationCode',
    validate: createFormValidation(schema),
})(RemoteValidationForm);

export default InsuranceRemoteValidation;
