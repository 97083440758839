import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/cross-platforms.css';
import './assets/index.css';

Sentry.init({
    dsn: process.env.sentryDsn,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    maxValueLength: Infinity,
});

ReactDOM.render(<App />, document.getElementById('root'));
