import { parseISO } from 'date-fns';
import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import useServerDateTime from '../../../../components/utilities/useServerDateTime';
import { Maybe } from '../../../../schema';

// refer to AFC-2274 for express calculator computation logic

const vehicleLifespanInMonths = 120;
const omvThreshold = 20000;

// calculate month difference manually
const differenceInMonths = (end: Date, start: Date) => {
    const years = end.getFullYear() - start.getFullYear();
    const months = end.getMonth() - start.getMonth();
    const days = end.getDate() - start.getDate();

    // if day is negative, last month is not a whole month
    const offset = days < 0 ? -1 : 0;

    return years * 12 + months + offset;
};

export const getTenureSetting = (
    applicationDate: Date,
    coeExpiryDate?: string | Date
): Partial<FinanceDataFragment['termSetting']> => {
    // disable tenure as there's still missing parameters
    if (isNil(coeExpiryDate)) {
        return {
            editable: false,
            default: undefined,
            min: undefined,
            max: undefined,
        };
    }

    const date = coeExpiryDate instanceof Date ? coeExpiryDate : parseISO(coeExpiryDate);

    // determine new max term from coe
    return {
        max: differenceInMonths(date, applicationDate),
    };
};

export const getMaxLoanPercentage = (registrationDate: Date, omv: number, applicationDate: Date): number => {
    // add 1 day offset for current day
    const monthsUsed = differenceInMonths(applicationDate, registrationDate);
    const monthsRemaining = vehicleLifespanInMonths - monthsUsed;

    const proratedOmv = (monthsRemaining * omv) / vehicleLifespanInMonths;

    if (proratedOmv > omvThreshold) {
        return 60;
    }

    return 70;
};

export const getLoanSetting = (
    applicationDate: Date,
    registrationDate?: string | Date,
    omv?: number
): Partial<FinanceDataFragment['loanSetting']> => {
    // disable loan as there's still missing parameters
    if (isNil(registrationDate) || isNil(omv)) {
        return {
            editable: false,
            default: undefined,
            min: undefined,
            max: undefined,
        };
    }

    const date = registrationDate instanceof Date ? registrationDate : parseISO(registrationDate);

    return {
        max: getMaxLoanPercentage(date, omv, applicationDate),
    };
};

export const getDownPaymentSetting = (
    loanSetting: Partial<FinanceDataFragment['loanSetting']>
): Partial<FinanceDataFragment['downPaymentSetting']> => {
    // disable down payment as there's still missing parameters
    if (!loanSetting.max) {
        return {
            editable: false,
            default: undefined,
            min: undefined,
            max: undefined,
        };
    }

    return {
        min: 100 - loanSetting.max,
    };
};

type EssentialVehicleDetails = {
    coeExpiryDate?: Maybe<Date>;
    originalRegistrationDate: Date | string;
    omv?: Maybe<number>;
};

const useFinanceProductRefinements = (
    vehicleDetails?: EssentialVehicleDetails | null | undefined
): Partial<FinanceDataFragment> | undefined => {
    const currentDate = useServerDateTime();

    return useMemo(() => {
        // cannot perform refinements since there's not enough data
        if (
            isNil(vehicleDetails?.coeExpiryDate) ||
            isNil(vehicleDetails?.originalRegistrationDate) ||
            isNil(vehicleDetails?.omv) ||
            isNil(currentDate)
        ) {
            return undefined;
        }

        const termSetting = getTenureSetting(currentDate, vehicleDetails?.coeExpiryDate);

        const loanSetting = getLoanSetting(currentDate, vehicleDetails?.originalRegistrationDate, vehicleDetails?.omv);
        const downPaymentSetting = getDownPaymentSetting(loanSetting);

        return {
            downPaymentSetting,
            loanSetting,
            termSetting,
        } as Partial<FinanceDataFragment>;
    }, [currentDate, vehicleDetails]);
};

export default useFinanceProductRefinements;
