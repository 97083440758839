import { useLazyQuery, useQuery } from '@apollo/client';
import { isNil } from 'lodash/fp';
import React, { ReactElement, useEffect } from 'react';
import {
    getApplicationByToken,
    GetApplicationByTokenQuery,
    GetApplicationByTokenQueryVariables,
} from '../../../../api/application.graphql';
import {
    getFlowConsentsAndDeclarations,
    ConsentDataFragment,
    GetFlowConsentsAndDeclarationsQuery,
    GetFlowConsentsAndDeclarationsQueryVariables,
} from '../../../../api/consents.graphql';
import RedirectToCompanyHome from '../../../utilities/RedirectToCompanyHome';

export type DraftFlowBootstrapProps = {
    token: string;
    children: (application: GetApplicationByTokenQuery['application'], consents: ConsentDataFragment[]) => ReactElement;
};

const DraftBootstrap = ({ children, token }: DraftFlowBootstrapProps) => {
    const [
        fetchApplication,
        { data: applicationData, loading: applicationLoading, called: applicationCalled },
    ] = useLazyQuery<GetApplicationByTokenQuery, GetApplicationByTokenQueryVariables>(getApplicationByToken, {
        fetchPolicy: 'network-only',
    });
    const application = applicationData?.application;

    const { data: consentsData, loading: consentsLoading } = useQuery<
        GetFlowConsentsAndDeclarationsQuery,
        GetFlowConsentsAndDeclarationsQueryVariables
    >(getFlowConsentsAndDeclarations, {
        fetchPolicy: 'network-only',
        variables: {
            consent: {
                dealerId: application?.dealerId!,
                bankId: application?.bank?.id,
                insuranceId: application?.insuranceApplication?.insuranceId,
            },
        },
        skip: isNil(application),
    });

    useEffect(() => {
        if (!applicationCalled) {
            fetchApplication({
                variables: { token },
            });
        }
    }, [token, applicationCalled, fetchApplication]);

    if (applicationLoading || !applicationCalled || consentsLoading) {
        // still loading
        return null;
    }

    if (!application) {
        // application is mandatory
        return <RedirectToCompanyHome />;
    }

    return children(application, consentsData?.result!);
};

export default DraftBootstrap;
