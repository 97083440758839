import { combineReducers } from 'redux';
import { reducer as formReducer, FormStateMap } from 'redux-form';
import authorizationReducer, { AuthorizationState } from './authorization';
import contextReducer, { ContextState } from './context';
import globalErrorsReducer, { GlobalErrorState } from './globalErrors';
import loadingReducer, { LoadingState } from './loading';
import notificationReducer, { NotificationState } from './notification';
import optionsReducer, { OptionsState } from './options';
import runtimeSettingsReducer, { RuntimeSettingsState } from './runtimeSettings';

export type State = {
    form: FormStateMap;
    authorization: AuthorizationState;
    context: ContextState;
    loading: LoadingState;
    options: OptionsState;
    globalErrors: GlobalErrorState;
    notification: NotificationState;
    runtimeSettings: RuntimeSettingsState;
};

const rootReducer = combineReducers<State>({
    form: formReducer,
    authorization: authorizationReducer,
    context: contextReducer,
    loading: loadingReducer,
    options: optionsReducer,
    globalErrors: globalErrorsReducer,
    notification: notificationReducer,
    runtimeSettings: runtimeSettingsReducer,
});

export default rootReducer;
