import { CurrencyField, useValidate, Error, CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { TFunction } from 'i18next';
import { get } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CalculatorInsuranceValues, CalculatorValues } from '../../types';
import EditionModal from '../EditionModal';

type CarPriceFieldProps = {
    labelTKey: string;

    financeContext: CalculatorContext<CalculatorValues>;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
    close: () => void;
};

const valueKey = 'carPrice';

const CarPriceField = (props: CarPriceFieldProps) => {
    const { t } = useTranslation();
    const { financeContext } = props;
    const { values } = financeContext;

    const initialValue = (get(valueKey, values) as unknown) as number | undefined;

    const [value, setValue] = useState(initialValue);

    // error management
    const [error, setError] = useState<string | null>(null);
    const disabled = !!error;
    const schema = yup.number().min(0, t('calculator.error.carPriceMin')).required();
    const validate = useValidate(schema, setError);

    const onChange = useCallback(
        value => {
            // set new value
            setValue(value);

            // execute validations
            validate(value);
        },
        [setValue]
    );

    return (
        <EditionModal {...props} disabled={disabled} value={value} valueKey={valueKey}>
            <CurrencyField handleChange={onChange} initialValue={initialValue} />
            <Error>{error}</Error>
        </EditionModal>
    );
};

export default CarPriceField;
