import React from 'react';
import { SubmitProvider } from '../ApplicationDetails';
import ReSubmitProvider from './ReSubmitProvider';
import ResumeProvider from './ResumeProvider';
import useFormChanged from './useFormChanged';

const ContinueProvider: SubmitProvider = props => {
    const hasChanged = useFormChanged();

    if (hasChanged) {
        return <ReSubmitProvider {...props} />;
    }

    return <ResumeProvider {...props} />;
};

export default ContinueProvider;
