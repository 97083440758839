// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { isNil } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../../../schema';
import { yesNoOptions } from '../../../../utilities/constants/options';
import { ApplicationData } from '../../../routes/ApplicationRoute';
import DateField from '../DateField';
import NumberField from '../NumberField';
import SelectField from '../SelectField';
import TextField from '../TextField';
import { getLabelWithFlag } from './CustomerDetails';

export type TradeInVehicleStepProps = {
    application: ApplicationData;
};

const TradeInVehicleStep = ({ application }: TradeInVehicleStepProps) => {
    const { t } = useTranslation();
    const { customer, channel, tradeIn, calculator, event } = application;

    const { withMyInfo } = customer;
    const isEvent = channel === Channel.EVENT;

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.vehicleToTradeIn')} closable open>
            <div className="container-fluid">
                <div className="row">
                    {isEvent && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={getLabelWithFlag(t('applicationDetailsPage.label.make'), tradeIn?.source)}
                                    name="tradeIn.make"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={getLabelWithFlag(t('applicationDetailsPage.label.model'), tradeIn?.source)}
                                    name="tradeIn.model"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={getLabelWithFlag(
                                        t('applicationDetailsPage.label.registrationNo'),
                                        tradeIn?.source
                                    )}
                                    name="tradeIn.registrationNo"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('applicationDetailsPage.label.mileage')}
                                    name="tradeIn.mileage"
                                    disabled
                                />
                            </div>
                            {withMyInfo && (
                                <>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.yearOfManufacture'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.year"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <DateField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.registrationDate'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.registrationDate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <DateField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.roadTaxExpiryDate'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.roadTaxExpiryDate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.engineCapacity'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.engineCapacity"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.propellant'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.propellant"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.primaryColor'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.color.primary"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.secondaryColor'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.color.secondary"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.vehicleStatus'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.status"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.vehicleScheme'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.scheme"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.coeCategory'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.coe.category"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <DateField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.coeExpiryDate'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.coe.expiryDate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.quotaPremium'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.quotaPremium"
                                            type="currency"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.openMarketValue'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.openMarketValue"
                                            type="currency"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag(
                                                t('applicationDetailsPage.label.noOfTransfers'),
                                                tradeIn?.source
                                            )}
                                            name="tradeIn.noOfTransfer"
                                            disabled
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12 ">
                        <SelectField.Outline
                            label={t('applicationDetailsPage.label.tradeIn')}
                            name="hasTradeIn"
                            options={yesNoOptions}
                            disabled
                        />
                    </div>
                    {event?.setting?.allowTradeInAmountInput && !isNil(calculator.expectedTradeInAmount) && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={getLabelWithFlag(
                                    t('applicationDetailsPage.label.expectedTradeInAmount'),
                                    tradeIn?.source
                                )}
                                name="calculator.expectedTradeInAmount"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {event?.setting?.allowTradeInAmountInput && !isNil(calculator.expectedCashPayment) && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={getLabelWithFlag(
                                    t('applicationDetailsPage.label.expectedCashPayment'),
                                    tradeIn?.source
                                )}
                                name="calculator.expectedCashPayment"
                                type="currency"
                                allowNegative
                                disabled
                            />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default TradeInVehicleStep;
