import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 26px;
    color: #000000;
`;

export const SubTitle = styled(Title)`
    font-size: 17.5px;
`;

export const SectionTitle = styled(Title)`
    font-size: 21px;
    text-align: center;
`;
