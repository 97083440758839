// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FieldArray, getFormValues, ReduxFormContext, WrappedFieldArrayProps } from 'redux-form';
import { useCountry } from '../../../../hookSelectors';
import { EventExternalSite } from '../../../../schema';
import {
    applyCoeVehicleConditionOptions,
    eventExternalSiteOptions,
    publishedOptions,
} from '../../../../utilities/constants/options';
import NumberField from '../../../shared/form-v2/NumberField';
import SelectField from '../../../shared/form-v2/SelectField';
import TextAreaField from '../../../shared/form-v2/TextAreaField';
import { EventFormData } from './EventForm';

const Disclaimers = ({ fields }: WrappedFieldArrayProps<any>) => {
    const { t } = useTranslation();

    return fields.map((field, index) => (
        <div key={field} className="col-md-4 col-sm-12 col-xs-12">
            <TextAreaField
                label={`${t('eventDetailsPage.label.priceDisclaimer')} ${index + 1}`}
                name={field}
                disabled
            />
        </div>
    ));
};

const Settings = () => {
    const { t } = useTranslation();
    const { form } = useContext(ReduxFormContext);
    const { code: countryCode, channelSetting } = useCountry();
    const isCoeEnabled = countryCode === 'SG';

    const isPpsrAndEstablishmentEnabled = countryCode === 'NZ';

    const { setting }: Partial<EventFormData> = useSelector(getFormValues(form)) || {};

    return (
        <Portlet name={t('eventDetailsPage.subHeading.settings')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.loginAccess')}
                            name="setting.allowPrivateAccess"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.publicAccess')}
                            name="setting.allowPublicAccess"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                    {channelSetting.event.isDepositPaymentMandatory && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label={t('eventDetailsPage.label.paymentDeposit')}
                                    name="setting.isDepositPaymentMandatory"
                                    options={publishedOptions}
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label={t('eventDetailsPage.label.paymentAmount')}
                                    name="setting.bookingPayment.amount"
                                    type="currency"
                                    disabled
                                />
                            </div>
                        </>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.tradeIn')}
                            name="setting.allowTradeIn"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                    {setting?.allowTradeIn && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={t('eventDetailsPage.label.tradeInAmountInput')}
                                name="setting.allowTradeInAmountInput"
                                options={publishedOptions}
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.testDrive')}
                            name="setting.allowTestDrive"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                    {setting?.allowTestDrive && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={t('eventDetailsPage.label.appointment')}
                                name="setting.isAppointmentEnabled"
                                options={publishedOptions}
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.externalSite')}
                            name="setting.externalSite"
                            options={eventExternalSiteOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.priceDisclaimers')}
                            name="setting.enablePriceDisclaimer"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                    {isCoeEnabled && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={t('eventDetailsPage.label.coe')}
                                name="setting.isCoeEnabled"
                                options={publishedOptions}
                                disabled
                            />
                        </div>
                    )}
                    {setting?.externalSite === EventExternalSite.PORSCHEFINDER && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={t('eventDetailsPage.label.coeVehicleCondition')}
                                name="setting.coeVehicleCondition"
                                options={applyCoeVehicleConditionOptions}
                                disabled
                            />
                        </div>
                    )}
                    {isPpsrAndEstablishmentEnabled && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={t('eventDetailsPage.label.ppsrAndEstablishment')}
                                name="setting.isPpsrAndEstablishmentEnabled"
                                options={publishedOptions}
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.promoCode')}
                            name="setting.isPromoCodeEnabled"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('eventDetailsPage.label.reservationInstruction')}
                            name="setting.isReservationInstructionEnabled"
                            options={publishedOptions}
                            disabled
                        />
                    </div>
                </div>
                {setting?.enablePriceDisclaimer && (
                    <>
                        <hr />
                        <div className="row">
                            {/* @ts-ignore */}
                            <FieldArray component={Disclaimers} name="setting.priceDisclaimers" />
                        </div>
                    </>
                )}
                {setting?.isReservationInstructionEnabled && (
                    <>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextAreaField
                                    label={t('eventDetailsPage.label.reservationInstruction')}
                                    name="setting.reservationInstruction"
                                    disabled
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Portlet>
    );
};

export default Settings;
