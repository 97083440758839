import React from 'react';
import { useTranslation } from 'react-i18next';
import useOptions from '../../../utilities/constants/useOptions';
import DateField from '../../shared/form-v2/DateField';
import SelectField from '../../shared/form-v2/SelectField';
import TextAreaField from '../../shared/form-v2/TextAreaField';
import TextField from '../../shared/form-v2/TextField';
import SubTitle from '../../ui/SubTitle';

export type DrivingLicenseDetailsProps = {
    disabled?: boolean;
};

const DrivingLicenseDetails = ({ disabled }: DrivingLicenseDetailsProps) => {
    const { claimDiscountOptions, yearsOfDrivingOptions } = useOptions();
    const { t } = useTranslation();

    return (
        <>
            <SubTitle>{t('applicationDetailsPage.subHeading.drivingLicense')}</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={disabled}
                        label={t('customerDetails.label.yearsOfDriving')}
                        name="calculator.yearsOfDriving"
                        options={yearsOfDrivingOptions}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DateField
                        disabled={disabled}
                        label={t('customerDetails.label.driverLicensePassDate')}
                        name="calculator.driverLicensePassDate"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={disabled}
                        label={t('customerDetails.label.noClaimDiscount')}
                        name="calculator.ncd"
                        options={claimDiscountOptions}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={disabled}
                        label={t('customerDetails.label.existingCarPlate')}
                        name="calculator.existingCarPlate"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextAreaField disabled={disabled} label={t('customerDetails.label.claimHistory')} name="comment" />
                </div>
            </div>
        </>
    );
};

export default DrivingLicenseDetails;
