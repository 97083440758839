import qs from 'qs';
import * as api from '../api';
import { UploadPurpose } from '../schema';
import { getCompany, getCurrentCountry } from '../selectors';
import { setAuthorization } from './authorization';
import { attachLoading } from './loading';

export const refreshAuthorization = () => async (dispatch, getState, { client }) => {
    // call API to get new authorization
    const data = await api.refreshAuthorization(client);
    // update authorization
    const { token } = data;
    dispatch(setAuthorization(token));

    return data;
};

export const requestNewPassword = (username, locale) => (dispatch, getState, { client }) => {
    const { id: countryId } = getCurrentCountry(getState()) || {};
    const { id: companyId } = getCompany(getState()) || {};
    const promise = api.emitRequestNewPassword(client, username, countryId, companyId, locale);

    return dispatch(attachLoading(promise));
};

export const resetPassword = password => (dispatch, getState, { history, client }) => {
    const { location } = history;
    const { secret } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const promise = api.consumeResetPassword(client, secret, password);

    return dispatch(attachLoading(promise));
};

export const updateApplicationStatus = (id, status) => (dispatch, getState, { client }) => {
    const promise = api.updateApplicationStatus(client, id, status);

    return dispatch(attachLoading(promise));
};

export const copyDocument = (id, uploadId) => (dispatch, getState, { client }) =>
    dispatch(attachLoading(dispatch(attachLoading(api.copyAttachment(client, id, uploadId)))));

export const uploadDocument = ({ purpose = UploadPurpose.CUSTOMER_ATTACHED, ...formData }) => (
    dispatch,
    getState,
    { client }
) => {
    const promise = api.createAttachment(client, { ...formData, purpose });

    return dispatch(attachLoading(promise));
};

export const uploadDocumentByApplicationId = formData => (dispatch, getState, { client }) => {
    const promise = api.updateAttachment(client, formData);

    return dispatch(attachLoading(promise));
};

export const deleteDocument = (id, applicationId, query = null) => (dispatch, getState, { client }) => {
    const promise = api.deleteAttachment(client, id, applicationId, query);

    return dispatch(attachLoading(promise));
};

export const deleteUnattached = (id, uploadId) => (dispatch, getState, { client }) => {
    const promise = api.deleteUnattached(client, id, uploadId);

    return dispatch(attachLoading(promise));
};

export const retrySubmitApplication = applicationId => (dispatch, getState, { client }) =>
    dispatch(attachLoading(api.retryApplication(client, applicationId)));

export const getProceedApplicationToken = entry => (dispatch, getState, { client }) =>
    dispatch(attachLoading(api.fetchProceedApplicationToken(client, entry)));
