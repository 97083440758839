import { isEmpty, isNil } from 'lodash/fp';
import { useTenantManagementContext } from '../shared/tenantManagementContext';

/**
 * similar to `useDealerIdFromTenantContext`, but throw error when dealer id not present
 */
const useMustExistDealerIdFromTenantContext = () => {
    const {
        current: { dealerId },
    } = useTenantManagementContext();

    if (isNil(dealerId) || isEmpty(dealerId)) {
        throw new Error('dealer id is not specified');
    }

    return dealerId;
};

export default useMustExistDealerIdFromTenantContext;
