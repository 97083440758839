import { Document } from '@appvantageasia/afc-ui';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import { uploadFiles } from '../../../../actions';
import { handleResponseError } from '../../../../utilities/forms';
import FileDropBox from './FileDropBox';
import { withModal } from './ModalConsumers';

const { PreviewUpload, ErrorMessageDiv, LoadingIndicator } = Document;

const DocumentUpload = ({ meta, input, referenceId, activeCategory }) => {
    const [uploading, setUploading] = useState(false);
    const { change } = useContext(ReduxFormContext);

    const dispatch = useDispatch();
    const handleUpload = useCallback(
        async file => {
            const fileWithPurpose = file;
            fileWithPurpose.purpose = activeCategory.purpose;

            setUploading(true);

            dispatch(uploadFiles([fileWithPurpose], [], null, referenceId, null))
                .then(res => {
                    dispatch(change(input.name, [...input.value, res[1][0]]));
                })
                .catch(handleResponseError);

            setUploading(false);
        },
        [activeCategory.purpose, dispatch, referenceId, change, input.name, input.value]
    );

    const handleDrop = useCallback(
        filesArg => {
            if (filesArg[0]) {
                return handleUpload(filesArg[0]);
            }

            return Promise.resolve();
        },
        [handleUpload]
    );

    const onChange = useCallback(event => handleDrop(event.target.files), [handleDrop]);

    const { error, touched, active } = meta;
    const { onBlur, onFocus } = input;

    return (
        <>
            <PreviewUpload>
                <FileDropBox className="upload" onDrop={handleDrop}>
                    <input onBlur={onBlur} onChange={onChange} onFocus={onFocus} type="file" />
                    <div className="replace-style">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div>upload</div>
                        {uploading && (
                            <LoadingIndicator>
                                <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                            </LoadingIndicator>
                        )}
                    </div>
                </FileDropBox>
            </PreviewUpload>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </>
    );
};

DocumentUpload.propTypes = {
    activeCategory: PropTypes.shape({
        id: PropTypes.number,
        purpose: PropTypes.string,
    }),
    input: PropTypes.shape().isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool,
        error: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
    referenceId: PropTypes.string.isRequired,
};

export default withModal(DocumentUpload);
