// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import { get, pick } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSection } from 'redux-form';
import * as yup from 'yup';
import { BankPresetOption } from '../../../../../schema';
import { yupExt } from '../../../../../utilities/forms';
import { schema as drivingLicenseSchema } from '../DrivingLicenseDetails';
import ContactDetails, { contactPMESASchema, schema as contactDetailsSchema } from './ContactDetails';
import CustomerDetails, { schema as customerDetailsSchema, detailsSchema } from './CustomerDetails';
import GuaranteedBuybackDetails from './GuaranteedBuybackDetails';
import IdentityDetails, { identityEnbdSchema } from './IdentityDetails';
import OccupationDetails, { occupationEnbdSchema, schema as occupationSchema } from './OccupationDetails';
import QuotationDetails from './QuotationDetails';
import ReferenceDetails, { schema as referenceSchema } from './ReferenceDetails';

export type ApplicantStepProps = { allowPrimaryInfoChanges?: boolean; disabled?: boolean };

const ApplicantStep = ({ allowPrimaryInfoChanges, disabled }: ApplicantStepProps) => {
    const { t } = useTranslation();

    return (
        <FormSection name="customer">
            <Portlet name={t('applicationDetailsPage.subHeading.applicantDetails')} closable open>
                <div className="container-fluid">
                    <CustomerDetails allowPrimaryInfoChanges={allowPrimaryInfoChanges} disabled={disabled} />
                    <IdentityDetails />
                    <ReferenceDetails />
                    <ContactDetails />
                    <OccupationDetails />
                    <QuotationDetails />
                    <GuaranteedBuybackDetails />
                </div>
            </Portlet>
        </FormSection>
    );
};

export const schema = (t: TFunction) => {
    return {
        ...customerDetailsSchema(t),
        details: yupExt.customerProperty().shape({
            ...contactDetailsSchema(t),
            ...detailsSchema(t),
            ...drivingLicenseSchema(t),
            ...occupationSchema(t),
            ...referenceSchema(t),
            thirdParty: yupExt
                .customerProperty()
                .shape({
                    // @ts-ignore
                    enbdBank: yup.lazy((value, options) => {
                        const isEnbdBankPreset =
                            get('context.application.financeProduct.bank.presetOption', options) ===
                            BankPresetOption.ENBDBANK;

                        if (isEnbdBankPreset) {
                            return yupExt.customerProperty().shape({
                                ...identityEnbdSchema(t),
                                ...occupationEnbdSchema(t),
                            });
                        }

                        return yupExt.customerProperty().nullable();
                    }),
                    // @ts-ignore
                    pmeSA: yup.lazy((value, options) => {
                        const isPMESAPreset =
                            get('context.application.financeProduct.bank.presetOption', options) ===
                            BankPresetOption.PMESA;

                        if (isPMESAPreset) {
                            return yupExt.customerProperty().shape({
                                ...contactPMESASchema(t),
                            });
                        }

                        return yupExt.customerProperty().shape({});
                    }),
                })
                .nullable(),
        }),
    };
};

export const customerPrimaryInfoSchema = (t: TFunction) => ({
    ...pick(['email'], customerDetailsSchema(t)),
    details: yupExt.customerProperty().shape(pick(['maritalStatus'], detailsSchema(t))),
});

export const onlyCustomerPrimaryInfoSchema = (t: TFunction) => ({
    ...pick(['email'], customerDetailsSchema(t)),
});

export default ApplicantStep;
