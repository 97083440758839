import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { getLoadingTasks } from '../../selectors';
import { spring } from '../animations/ZoomInAndOut';

export type LoadingLayerProps = { className: string };

const LoadingLayer = ({ className }: LoadingLayerProps) => {
    const loadingTasks = useSelector(getLoadingTasks);
    const hasTasks = loadingTasks.filter(task => !task.silent).length > 0;

    const transitions = useTransition(hasTasks, null, spring);

    const elements = transitions.map(
        ({ item, key, props: { opacity, transform } }) =>
            item && (
                <animated.div
                    key={key}
                    className={className}
                    style={{
                        // @ts-ignore
                        background: opacity.interpolate(value => `rgba(255, 255, 255, ${value * 0.1}`),
                    }}
                >
                    <animated.div style={{ transform, opacity }}>
                        <Icon color="black" icon={faSpinner} size="3x" spin />
                    </animated.div>
                </animated.div>
            )
    );

    return <div>{elements}</div>;
};

const StyledLoadingLayer = styled(LoadingLayer)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        width: 50px;
        height: 50px;
    }
`;

export default StyledLoadingLayer;
