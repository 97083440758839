import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import styled from 'styled-components';
import { getPromoFromIdentifier } from '../../../../components/data/useLoadPromo';
import TextField from '../../../../components/shared/form/TextField';
import useFormValues from '../../../../components/utilities/useFormValues';
import { useCountry } from '../../../../hookSelectors';
import { getRuntimeSettings } from '../../../../selectors';
import { Button } from '../Configurator/shared/AddField';
import { SummaryFormValues } from './Summary';

const FieldWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const ApplyButton = styled(Button)`
    margin: 0 0 0 30px;
    height: 36px;
`;

type PromoFieldProps = {
    form: string;
    eventDealerIds: string[];
};

const PromoField = ({ form, eventDealerIds }: PromoFieldProps) => {
    const { id: countryId } = useCountry();

    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

    const { promoCode } = useFormValues<SummaryFormValues>();

    const dispatch = useDispatch();

    const applyPromoCode = useCallback(async () => {
        // fetch promo value
        const promo = eventDealerIds
            ? await getPromoFromIdentifier(client, countryId, promoCode || '', eventDealerIds)
            : null;

        dispatch(change(form, 'promo', promo));
    }, [client, countryId, dispatch, eventDealerIds, form, promoCode]);

    return (
        <FieldWrapper>
            <TextField.Outline name="promoCode" placeholder={ckdConfiguration.summary.promoPlaceholder} />
            <ApplyButton backgroundColor="#d5001c" onClick={applyPromoCode}>
                {ckdConfiguration.summary.apply}
            </ApplyButton>
        </FieldWrapper>
    );
};

export default PromoField;
