import React from 'react';
import RemoteFlowBootstrap from './RemoteFlowBootstrap';
import RemoteFlowHandler from './RemoteFlowHandler';

export type RemoteHandlerProps = { token: string; submitted: boolean };

const RemoteHandler = ({ token, submitted }: RemoteHandlerProps) => (
    <RemoteFlowBootstrap token={token}>
        {(application, consents) => (
            <RemoteFlowHandler application={application} consents={consents} submitted={submitted} token={token} />
        )}
    </RemoteFlowBootstrap>
);

export default RemoteHandler;
