import { getOr } from 'lodash/fp';
import { State } from '../reducers';
import { AppOptions, Option, DialingCodesOption } from '../reducers/options';

export const getOptions = <T>(key: string, defaultValue: T) => (state: State): T =>
    getOr(defaultValue, `options.${key}`, state);

export const getCountries = getOptions<Option<string>[]>('countries', []);

export const getNationalities = getOptions<Option<string>[]>('nationalities', []);

export const getOccupations = getOptions<Option<string>[]>('occupations', []);

export const getDownpaymentTo = getOptions<Option<string>[]>('downpaymentTo', []);

export const getStatusOptions = getOptions<AppOptions['statusOptions']>('statusOptions', []);

export const getDialingCodes = getOptions<DialingCodesOption[]>('dialingCodes', []);

export const getCountriesDialingCodes = getOptions<DialingCodesOption[]>('countriesDialingCodes', []);

export const getGenders = getOptions<AppOptions['genders']>('genders', []);

export const getRaces = getOptions<AppOptions['races']>('races', []);

export const getMaritalStatuses = getOptions<AppOptions['maritalStatuses']>('maritalStatuses', []);

export const getResidentialStatuses = getOptions<AppOptions['residentialStatuses']>('residentialStatuses', []);

export const getDrivingLicenseValidityCodes = getOptions<AppOptions['drivingLicenseValidities']>(
    'drivingLicenseValidityCodes',
    []
);

export const getDrivingLicenseClasses = getOptions<AppOptions['drivingLicenseClasses']>('drivingLicenseClasses', []);

export const getCities = getOptions<AppOptions['cities']>('cities', []);

export const getTimezones = getOptions<AppOptions['timezones']>('timezones', []);

export const getLanguages = getOptions<AppOptions['languages']>('languages', []);

export const getRelationships = getOptions<AppOptions['relationships']>('relationships', []);

export const getEducations = getOptions<AppOptions['educations']>('educations', []);

export const getAddressTypes = getOptions<AppOptions['addressTypes']>('addressTypes', []);

export const getIncomeTypes = getOptions<AppOptions['incomeTypes']>('incomeTypes', []);

export const getResidenceTypes = getOptions<AppOptions['residenceTypes']>('residenceTypes', []);

export const getEmirates = getOptions<Option<string>[]>('emirates', []);

export const getAffinBank = getOptions<AppOptions['affinBank']>('affinBank', {} as AppOptions['affinBank']);

export const getAffinBankAddressTypes = getOptions<AppOptions['affinBank']['addressTypes']>(
    'affinBank.addressTypes',
    []
);

export const getAffinBankRaces = getOptions<AppOptions['affinBank']['races']>('affinBank.races', []);

export const getAffinBankIdTypes = getOptions<AppOptions['affinBank']['idTypes']>('affinBank.idTypes', []);

export const getAffinBankResidentialStatuses = getOptions<AppOptions['affinBank']['residentialStatuses']>(
    'affinBank.residentialStatuses',
    []
);
