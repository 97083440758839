import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRuntimeSettings } from '../../../../selectors';
import { TequipmentMaterials } from '../Configurator/Tequipment';
import { SubTitle } from '../Configurator/shared/ui';
import { Row, SectionContainer } from './shared/ui';

type TequipmentSectionProps = {
    formatCurrency: (price: number) => string;
    tequipment: TequipmentMaterials;
};

const TequipmentSection = ({ formatCurrency, tequipment }: TequipmentSectionProps) => {
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { options } = ckdConfiguration.tequipment;
    const tequipmentData = useMemo(() => {
        if (tequipment) {
            const selectedTequipment = Object.entries(tequipment).filter(([key, value]) => value !== false);

            return Object.entries(options).map(([key, value]: any) => {
                return selectedTequipment.map((item: [string, boolean | undefined]) => {
                    if (item[0] === key) {
                        return (
                            <Row key={key}>
                                <span>{value.label}</span>
                                <span>{formatCurrency(value.price)}</span>
                            </Row>
                        );
                    }

                    return null;
                });
            });
        }

        return null;
    }, [formatCurrency, options, tequipment]);

    return (
        <SectionContainer>
            <SubTitle>{ckdConfiguration.summary.tequipment}</SubTitle>
            {tequipmentData}
        </SectionContainer>
    );
};

export default TequipmentSection;
