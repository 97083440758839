import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ReduxFormContext, touch } from 'redux-form';
import styled from 'styled-components';
import IconButton from '../IconButton';
import Tooltip from '../tooltip/Tooltip';
import useTooltip from '../tooltip/useTooltip';
import { Label, Input, attachmentPropType, getFileName } from './BoxedFileInput';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: right;

    & > *:not(:last-child) {
        margin-right: 1rem;
        max-width: 100px;
        margin-left: auto;
    }
`;

const FileName = styled.span`
    color: ${props => props.theme.themeHighlightColour};
    display: contents;
`;

const Placeholder = styled.span`
    color: #808080;
`;

const PureFileInput = ({ placeholder, disabled, onChange: change, value, purpose, onRemove, ...props }) => {
    const fileName = getFileName(value);
    const { name } = props;
    const { form } = useContext(ReduxFormContext);
    const dispatch = useDispatch();
    const onChange = useCallback(
        event => {
            const file = event.target.files[0];
            // have to get purpose from the file value
            const { purpose: forPurpose } = value;
            // fallback to purpose if not available in value
            file.purpose = forPurpose || purpose;

            change(file);
            dispatch(touch(form, name));
        },
        [value, purpose, change, dispatch, form, name]
    );

    const remove = useCallback(() => {
        change({});
        if (onRemove) {
            onRemove();
        }
        dispatch(touch(form, name));
    }, [change, dispatch, form, name, onRemove]);

    const valueRef = useRef(null);
    const tooltipRef = useTooltip(valueRef);

    return (
        <>
            <Container>
                <Label ref={valueRef}>
                    {fileName ? (
                        <FileName>{fileName}</FileName>
                    ) : (
                        <>
                            <Placeholder>{placeholder}</Placeholder>{' '}
                            <Input disabled={disabled} onChange={onChange} type="file" {...props} />
                        </>
                    )}
                </Label>

                {fileName && !disabled && <IconButton icon={faTimesCircle} onClick={remove} size="lg" />}
            </Container>
            <Tooltip ref={tooltipRef}>{fileName}</Tooltip>
        </>
    );
};

PureFileInput.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([attachmentPropType, PropTypes.instanceOf(File)]).isRequired,
};

export default PureFileInput;
