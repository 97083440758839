import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getCompanyIdentifier } from '../../../selectors';
import { getHomeUrl } from '../../../utilities/urls';
import Header from '../../shared/Header';
import PublicSessionTimer from '../../shared/PublicSessionTimer';
import RedirectToCompanyHome from '../../utilities/RedirectToCompanyHome';
import ApplyForInsuranceBootstrap from './ApplyForInsuranceBootstrap';
import ApplyForInsuranceHandler from './ApplyForInsuranceHandler';

export type LocationParams = { id: string };

const pubSubChannel = 'applyForInsuranceRoute';

const onIdle = () => PubSub.publishSync(`${pubSubChannel}.timeout`);

const ApplyForInsuranceRoute = () => {
    const { language } = useContentTranslation();
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const { id: applicationVersionId } = useParams<LocationParams>();

    return (
        <ApplyForInsuranceBootstrap applicationId={applicationVersionId} language={language}>
            {(application, insuranceCompany) => {
                if (applicationVersionId !== application.version.id) {
                    return <RedirectToCompanyHome />;
                }

                const getUrl = getHomeUrl(application.channel);

                return (
                    <>
                        <PublicSessionTimer onIdle={onIdle} url={getUrl(companyCode, locationCode)} />
                        <ApplyForInsuranceHandler application={application} insuranceCompany={insuranceCompany} />
                    </>
                );
            }}
        </ApplyForInsuranceBootstrap>
    );
};

export default ApplyForInsuranceRoute;
