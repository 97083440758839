import { max, min } from 'lodash/fp';
import { useMemo } from 'react';
import { ProcessedVariant } from './processVariantWithFormattedPrices';

export type MonthlyPaymentOption = {
    id: string;
    value: { min: number; max: number };
    label: string;
};

const useMonthlyPaymentOptions = <TVariant extends ProcessedVariant>(
    variants: TVariant[],
    formatCurrencyValues: (values: number[], unit?: string, separator?: string) => string,
    roundNumber: (value: number) => number,
    currencySymbol: string
) =>
    useMemo(() => {
        if (variants === null || variants.length === 0 || variants.length < 3) {
            // we didn't receive the variants yet
            // don't create options if variants is less than 3
            return [];
        }

        // how many options do we want for the monthly payment
        const steps = variants.length > 5 ? 3 : 2;

        // get the monthly price for every variant
        const monthlyPayments = variants.map(variant => variant.monthlyPrice);
        // from those extract the min & max
        const minPayment = min(monthlyPayments) as number;
        const maxPayment = max(monthlyPayments) as number;

        // we may now compute the range for each step
        const range = (maxPayment - minPayment) / steps;

        // start with an empty list for our options
        const options: MonthlyPaymentOption[] = [];

        // then fill it in
        for (let step = 0; step < steps; step++) {
            // compute min & max
            const minValue = step === 0 ? minPayment : options[step - 1].value.max + 1;
            const maxValue = step === steps - 1 ? maxPayment : minValue + range;

            options.push({
                // provide an uniq ID
                id: `${minValue}${maxValue}`,
                // the value is an object with the two limits
                value: {
                    min: roundNumber(minValue),
                    max: roundNumber(maxValue),
                },
                // then provide a human readable label
                label: formatCurrencyValues([minValue, maxValue], currencySymbol, ' - '),
            });
        }

        return options;
    }, [formatCurrencyValues, roundNumber, variants, currencySymbol]);

export default useMonthlyPaymentOptions;
