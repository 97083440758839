import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useContentTranslation } from '../../i18n';
import { Channel } from '../../schema';
import { getCompany, getCurrentCountry } from '../../selectors';
import { getLabelFromChannel } from '../../utilities/channels';

type HelmetTitleProps = {
    channel?: Channel;
    title?: string;
};

const HelmetTitle = ({ channel, title }: HelmetTitleProps) => {
    const company = useSelector(getCompany);
    const country = useSelector(getCurrentCountry);
    const { ct } = useContentTranslation();

    const channelLabel = channel && getLabelFromChannel(channel);

    const companyText = company ? `${ct(company?.name)} AFC ` : 'AFC ';
    const countryText = country ? `: ${country.code} ` : '';
    const channelText = channelLabel ? `: ${channelLabel} ` : '';
    const titleText = title ? `: ${title}` : '';

    const helmetTitle = companyText + countryText + channelText + titleText;

    return (
        <Helmet>
            <title>{helmetTitle}</title>
        </Helmet>
    );
};

export default HelmetTitle;
