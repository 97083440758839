/* eslint-disable no-underscore-dangle */
import { getMyInfoSession } from '../../components/routes/wip/MyInfoCallback';
import { ApplicationFlowSource } from '../../schema';
import { RemoteFlowState } from '../RemoteFlow/RemoteFlow';
import { Flow, FlowStep } from '../utils/flow';
import * as flowSteps from './steps';

export type GuarantorFlowState = RemoteFlowState;

export type GuarantorFlowStep = FlowStep<GuarantorFlowState>;

class GuarantorFlow extends Flow<GuarantorFlowState> {
    protected initialize(): FlowStep<GuarantorFlowState> {
        const { application } = this.state;

        if (application) {
            const myInfo = getMyInfoSession(application.id, ApplicationFlowSource.GUARANTOR);

            if (myInfo) {
                const step = this.getStep(myInfo.step);

                if (step) {
                    return step;
                }
            }

            for (const step of this.steps) {
                if (!step.isShadowStep && !step.isCompleted) {
                    return step;
                }
            }
        }

        return super.initialize();
    }

    protected plannify(): FlowStep<GuarantorFlowState>[] {
        if (this.state.submitted) {
            // only show submitted step
            return [this.createStep(flowSteps.SubmitStep)];
        }

        // there's always the calculator and draft step
        const steps: FlowStep<GuarantorFlowState>[] = [this.createStep(flowSteps.PreviewStep)];

        const { bank } = this.state;

        if (bank?.isKYCMandatory) {
            if (bank?.myInfo) {
                // there's my info integration
                steps.push(this.createStep(flowSteps.MyInfoStep));
            }

            // we need to collect KYC fields
            steps.push(this.createStep(flowSteps.KYCStep));
        }

        steps.push(this.createStep(flowSteps.ConfirmationStep));

        return [...steps, this.createStep(flowSteps.SubmitStep)];
    }
}

export default GuarantorFlow;
