import React from 'react';
import { useSelector } from 'react-redux';
import { getGlobalPermissions } from '../../../selectors';
import Customer from './Customer';
import InsuranceDetails from './InsuranceDetails';
import Summary from './Summary';
import VehicleStep from './VehicleStep';
import { InsuranceApplicationDataFragment } from './data.graphql';

type ApplicationProps = {
    insuranceApplication: InsuranceApplicationDataFragment;
    disabled?: boolean;
    allowPrimaryInfoChanges?: boolean;
};

const Application = ({ insuranceApplication, disabled, allowPrimaryInfoChanges }: ApplicationProps) => {
    const { mayManageInsuranceApplication } = useSelector(getGlobalPermissions);

    return (
        <>
            <Summary disabled={!mayManageInsuranceApplication} insuranceApplication={insuranceApplication} />
            <Customer allowPrimaryInfoChanges={allowPrimaryInfoChanges} disabled={disabled} />
            <VehicleStep disabled={disabled} insuranceApplication={insuranceApplication} />
            <InsuranceDetails />
        </>
    );
};

export default Application;
