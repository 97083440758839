import styled from 'styled-components';

const ChatHeader = styled.div`
    height: 50px;
    background-color: #000;
    position: relative;

    & > svg {
        position: absolute;
        height: 100%;
        vertical-align: middle;
        padding: 0 15px;
        color: #fff;
        width: 60px;
        filter: brightness(10);
        cursor: pointer;
    }
`;

export default ChatHeader;
