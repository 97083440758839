import React from 'react';
import { useSelector } from 'react-redux';
import { FormSection } from 'redux-form';
import styled from 'styled-components';
import useMedia from 'use-media';
import CheckboxField from '../../../../components/shared/form/CheckboxField';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { Channel } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { SelectionContainer } from './Exterior';
import { PriceField } from './Package';
import SlideShow from './shared/SlideShow';
import { SectionTitle } from './shared/ui';

const TequipmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .checkbox-group {
        width: 80%;
        max-width: 400px;
        min-width: 320px;
        margin-top: 30px;
        .checkbox {
            display: flex;
            min-height: 35px;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 2px;
            align-items: center;
            font-size: 16px;
            color: #444444;

            span {
                margin-left: 20px;
                min-width: 80px;
                text-align: right;
            }
        }
    }
`;

export type TequipmentMaterials = {
    bicycleRack?: boolean;
    carCareCase?: boolean;
    floorMats?: boolean;
    luggageCompartment?: boolean;
    luggageNet?: boolean;
};

const Tequipment = () => {
    const isMobile = useMedia({ maxWidth: breakpoints.xl });
    const { formatCurrency } = useCompanyFormatting();
    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { tequipment } = ckdConfiguration;
    const { options } = tequipment;

    return (
        <FormSection name="tequipment">
            <SelectionContainer>
                {isMobile && <SlideShow isArrowColorGray={false} options={Object.values(options)} />}
                <div className="configurator">
                    <TequipmentContainer>
                        <SectionTitle>{tequipment.headline}</SectionTitle>
                        <div className="checkbox-group">
                            {Object.entries(options).map(([key, value]: any) => (
                                <div key={key} className="checkbox">
                                    <CheckboxField channel={Channel.EVENT} label={value.label} name={key} />
                                    <PriceField>{formatCurrency(value.price)}</PriceField>
                                </div>
                            ))}
                        </div>
                    </TequipmentContainer>
                </div>
            </SelectionContainer>
        </FormSection>
    );
};

export default Tequipment;
