import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
    QueryServerDateTimeQuery,
    QueryServerDateTimeQueryVariables,
    queryServerDateTime,
} from '../../api/miscellaneous.graphql';

const useServerDateTime = () => {
    const { data } = useQuery<QueryServerDateTimeQuery, QueryServerDateTimeQueryVariables>(queryServerDateTime, {
        fetchPolicy: 'network-only',
    });

    return useMemo(() => {
        if (!data?.dateTime) {
            return undefined;
        }

        return new Date(data.dateTime);
    }, [data]);
};

export default useServerDateTime;
