// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContentTranslation } from '../../../../../i18n';
import { assetConditionOptions, yesNoOptions } from '../../../../../utilities/constants/options';
import { ApplicationData } from '../../../../routes/ApplicationRoute';
import DateField from '../../DateField';
import NumberField from '../../NumberField';
import SelectField from '../../SelectField';
import TextField from '../../TextField';

export type UsedProps = {
    application: ApplicationData;
};

const Used = ({ application }: UsedProps) => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();
    const { booking, variant } = application;

    const vin = booking?.unit?.details?.vin;
    const hasSubModel = !!variant.model.parentId;

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.vehicleOfInterest')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.make')}
                            name={formatPath(
                                hasSubModel ? 'variant.model.parent.make.name' : 'variant.model.make.name'
                            )}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.model')}
                            name={formatPath(hasSubModel ? 'variant.model.parent.name' : 'variant.model.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.subModal')}
                            name={formatPath(hasSubModel ? 'variant.model.name' : '')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.variant')}
                            name={formatPath('variant.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.vehicleID')}
                            name="variant.identifier"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('applicationDetailsPage.label.assetCondition')}
                            name="variant.assetCondition"
                            options={assetConditionOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.yearOfManufacture')}
                            name="variant.year"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.depreciation')}
                            name="variant.usedCarDetails.depreciation"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DateField
                            label={t('applicationDetailsPage.label.registrationDate')}
                            name="variant.usedCarDetails.registrationDate"
                            disabled
                        />
                    </div>

                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.omv')}
                            name="variant.usedCarDetails.omv"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.arf')}
                            name="variant.usedCarDetails.arf"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.qpPaid')}
                            name="variant.usedCarDetails.coe"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.deRegistrationValue')}
                            name="variant.usedCarDetails.deregistrationValue"
                            type="currency"
                            disabled
                        />
                    </div>
                    {vin && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField fixedValue={vin} label={t('applicationDetailsPage.label.vinNumber')} disabled />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label={t('applicationDetailsPage.label.startingPrice')}
                            name="calculator.carPrice"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('applicationDetailsPage.label.testDrive')}
                            name="hasTestDrive"
                            options={yesNoOptions}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default Used;
