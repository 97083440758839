import styled from 'styled-components';

const Title = styled.h1`
    color: #161616;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: 500;
    line-height: 1.2;
`;

export default Title;
