import { useQuery } from '@apollo/client';
import { flatMap, flow, get, isEmpty, isNumber, map, omit, uniq } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import { PromoDataFragment } from '../../../../components/data/useLoadPromo.graphql';
import { EventDataFragment } from '../../../../components/routes/EventRoute/EventRoute.graphql';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import { useTenantManagementContext } from '../../../../components/shared/tenantManagementContext';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { getRuntimeSettings } from '../../../../selectors';
import breakpoints from '../../../../utilities/constants/breakpoints';
import { getEventUrl } from '../../../../utilities/urls';
import variantTemplate from '../../../DraftFlow/components/ExpressCalculator/variantTemplate';
import { TermsAndConditions } from '../Configurator/Timepiece';
import { Button } from '../Configurator/shared/AddField';
import { SubTitle, Title } from '../Configurator/shared/ui';
import useCalculatorValues from '../Configurator/shared/useCalculatorValues';
import CarPriceSection from './CarPriceSection';
import DeliverySection from './DeliverySection';
import ExteriorSection from './ExteriorSection';
import InteriorSection from './InteriorSection';
import PackageSection from './PackageSection';
import PromoSection from './PromoSection';
import { MiniConfiguratorDetails, SummaryFormValues } from './Summary';
import TequipmentSection from './TequipmentSection';
import TimepieceSection from './TimepieceSection';
import { getData, GetDataQuery, GetDataQueryVariables } from './data/data.graphql';
import getCleanCKDValues from './shared/getCleanCKDValues';
import { Row } from './shared/ui';

const SummaryDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > * {
        width: 80%;
    }

    input,
    label {
        font-size: 16px;
        color: #444444;
    }
    margin-bottom: 30px;

    /* Desktop and Laptop Screen */
    @media (min-width: ${breakpoints.xl}) {
        padding: 30px 20px;
        margin-bottom: 50px;
    }
`;

const OrderButton = styled(Button)`
    width: 80%;
    margin-top: 30px;
`;

const EstimatedDelivery = styled.span`
    margin-top: 10px;
    font-size: 16px;
`;

const Line = styled.hr`
    width: 80%;
    border-top-color: #ced4da;
`;

const BoldLine = styled(Line)`
    border-top-color: black;
`;

export type OrderDetailsProps = {
    initialData: MiniConfiguratorDetails;
    form: string;
    defaultFinanceProduct: FinanceDataFragment;
    events: EventDataFragment[];
    setDisplayEmailPopup?: Dispatch<SetStateAction<boolean>>;
    setDisplayTerms: Dispatch<SetStateAction<boolean>>;
    updatePromoCode?: (promo?: PromoDataFragment, promoCode?: string) => void;
    updateDealerSelection?: (dealerId: string) => void;
    isConfigPage?: boolean;
};

const OrderDetails = ({
    initialData,
    form,
    events,
    defaultFinanceProduct,
    setDisplayEmailPopup,
    setDisplayTerms,
    updatePromoCode,
    updateDealerSelection,
}: OrderDetailsProps) => {
    const { ct } = useContentTranslation();
    const history = useHistory();
    const { id: countryId, googleTagManager } = useCountry();

    const { formatCurrency } = useCompanyFormatting();
    const { dealerId, promo, promoCode } = (useSelector(getFormValues(form)) || {}) as SummaryFormValues;
    const calculatorValues = useCalculatorValues(defaultFinanceProduct, initialData, promo);

    // filter configurator event by dealer id
    const event = useMemo(() => events.find(({ dealers }) => dealers.find(({ id }) => id === dealerId)), [
        dealerId,
        events,
    ]);

    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { tequipment, timepiece, enhancedPackage } = initialData;

    // check there is a numeric on totalprice
    const totalPrice = isNumber(calculatorValues?.totalPrice)
        ? formatCurrency(calculatorValues.totalPrice)
        : 'XXX,XXX.XX';

    const monthlyInstalment = calculatorValues?.monthlyInstalments
        ? formatCurrency(calculatorValues.monthlyInstalments[0].amount)
        : 'XXX,XXX.XX';
    const hasSelectedDelivery = !isEmpty(initialData.delivery);

    const { update } = useTenantManagementContext();

    const saveCustomerOrder = useCallback(() => {
        if (setDisplayEmailPopup) {
            setDisplayEmailPopup(true);
        }
    }, [setDisplayEmailPopup]);

    const handleTermsAndConditions = useCallback(() => {
        setDisplayTerms(true);
    }, [setDisplayTerms]);

    // fetch dealer data
    const { data } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables: { countryId },
    });

    const eventDealerIds = useMemo(() => flow(flatMap(get('dealers')), map(get('id')), uniq)(events), [events]);

    // then map as options
    const dealerOptions = useMemo(() => {
        if (!data?.dealers) {
            return [];
        }

        return (
            data.dealers.items
                .filter(({ id }) => eventDealerIds.includes(id))
                .map(({ id, name }) => ({ value: id, label: ct(name) })) || []
        );
    }, [eventDealerIds, data, ct]);

    const dealerName = useMemo(() => dealerOptions.find(dealer => dealer.value === dealerId)?.label, [
        dealerId,
        dealerOptions,
    ]);

    // Prepare for Google Tag Manager Data Layer Push
    const googleTagManagerId = googleTagManager?.id;

    const tagManagerArgs = useMemo(() => {
        const cleanCKDValues = getCleanCKDValues({
            ckdConfiguration,
            exterior: initialData.exteriorColor,
            interior: initialData.interiorColor,
            isEnhancedPackageSeleted: initialData.enhancedPackage,
            tEquipment: initialData.tequipment,
            isTimepieceSelected: !!initialData.timepiece?.isAdded,
            deliveryMethod: initialData.delivery,
        });

        return {
            dataLayer: {
                event: 'PlaceYourOrderButtonClick',
                dealer: dealerName,
                details: {
                    miniConfiguratorDetails: cleanCKDValues,
                    promoCode: calculatorValues.promoCode,
                    carPrice: formatCurrency(calculatorValues.totalPrice),
                    monthlyPayment: calculatorValues.monthlyInstalments
                        ? formatCurrency(calculatorValues.monthlyInstalments[0].amount)
                        : undefined,
                    eventName: event?.name,
                },
            },
            dataLayerName: 'PageDataLayer',
        };
    }, [calculatorValues, ckdConfiguration, dealerName, event, formatCurrency, initialData]);

    const onClick = useCallback(() => {
        if (event) {
            if (dealerId) {
                update({ dealerId });
            }

            if (googleTagManagerId) {
                TagManager.dataLayer(tagManagerArgs);
            }

            // @ts-ignored
            history.pushWithCompanyAndState(
                getEventUrl,
                {
                    variantId: initialData.variantId,
                    miniConfiguratorDetails: omit(['variantId', 'timepiece.isAdded'], initialData),
                    calculator: calculatorValues,
                    promo,
                },
                event.id
            );
        }
    }, [event, dealerId, googleTagManagerId, history, initialData, calculatorValues, promo, update, tagManagerArgs]);

    useEffect(() => {
        if (dealerId && updateDealerSelection) {
            updateDealerSelection(dealerId);
        }
        if (updatePromoCode) {
            updatePromoCode(promo, promoCode);
        }
    }, [dealerId, promo, promoCode, updateDealerSelection, updatePromoCode]);

    const variant = useRef({
        ...variantTemplate,
        id: initialData.variantId as string,
        version: { isOutdated: false, id: initialData.variantId as string },
    });

    // create snapshot to prevent initialization calculation
    const snapshot = useMemo(
        () => ({
            calculator: calculatorValues,
            financeProduct: defaultFinanceProduct,
            variant: variant.current,
            options: [],
            promo,
        }),
        [calculatorValues, defaultFinanceProduct, promo]
    );

    return (
        <SummaryDetailsContainer>
            <Row>
                <Title>{ckdConfiguration.summary.headline}</Title>
            </Row>
            <br />
            <Row>
                <SubTitle>{ckdConfiguration.summary.basePrice}</SubTitle>
                <span>{formatCurrency(ckdConfiguration.vehicle.price)}</span>
            </Row>
            <Line />
            {enhancedPackage && (
                <>
                    <PackageSection formatCurrency={formatCurrency} />
                    <Line />
                </>
            )}
            <ExteriorSection exteriorColor={initialData.exteriorColor} formatCurrency={formatCurrency} />
            <Line />
            <InteriorSection formatCurrency={formatCurrency} interiorColor={initialData.interiorColor} />
            <Line />
            {tequipment && (
                <>
                    <TequipmentSection formatCurrency={formatCurrency} tequipment={tequipment} />
                    <Line />
                </>
            )}
            {hasSelectedDelivery && (
                <>
                    <DeliverySection deliveryMethod={initialData.delivery} formatCurrency={formatCurrency} />
                    <Line />
                </>
            )}
            <PromoSection eventDealerIds={eventDealerIds} form={form} />
            <BoldLine />
            <CarPriceSection
                calculatorValues={calculatorValues}
                dealerId={dealerId || dealerOptions[0]?.value}
                event={event}
                fallbackFinanceProduct={defaultFinanceProduct}
                monthlyInstalment={monthlyInstalment}
                snapshot={snapshot}
                totalPrice={totalPrice}
                variants={[variant.current]}
            />
            {timepiece && <TimepieceSection formatCurrency={formatCurrency} />}
            {!!ckdConfiguration.delivery.estimatedDelivery && (
                <EstimatedDelivery>{ckdConfiguration.delivery.estimatedDelivery}</EstimatedDelivery>
            )}
            <TermsAndConditions onClick={handleTermsAndConditions}>
                <span>{ckdConfiguration.summary.termsAndConditions.headline}</span>
            </TermsAndConditions>
            {form === 'summary' && (
                <>
                    <SelectField.Outline
                        label={ckdConfiguration.summary.preferredDealership}
                        name="dealerId"
                        options={dealerOptions}
                        placeholder="Select"
                    />
                    <OrderButton backgroundColor="#d5001c" disabled={!hasSelectedDelivery} onClick={onClick}>
                        {ckdConfiguration.summary.placeOrder}
                    </OrderButton>
                    <Button backgroundColor="white" color="black" onClick={saveCustomerOrder} withBorder>
                        {ckdConfiguration.summary.saveOrder}
                    </Button>
                </>
            )}
        </SummaryDetailsContainer>
    );
};

export default OrderDetails;
