import styled from 'styled-components';

const Paragraph = styled.p`
    font-size: 16px;
    line-height: 1.4;
    white-space: pre-line;
    margin-bottom: 1rem;
`;

export default Paragraph;
