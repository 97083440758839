import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Actions, DarkButton } from '@appvantageasia/afc-ui';
import { find, get, isEmpty, isEqual } from 'lodash/fp';
import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import { FinanceDataFragment } from '../../../../components/data/useFinanceProducts.graphql';
import useLoadVariants from '../../../../components/data/useLoadVariants';
import useMustExistDealerIdFromTenantContext from '../../../../components/data/useMustExistDealerIdFromTenantContext';
import BaseCalculator from '../../../../components/shared/calculator-next/BaseCalculator';
import ConnectedCalculator from '../../../../components/shared/calculator-next/ConnectedCalculator';
import InsuranceCalculator, {
    InsuranceCalculatorErrors,
} from '../../../../components/shared/calculator-next/InsuranceCalculator';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../components/shared/calculator-next/useCalculatorMeta';
import CalculatorPortlet from '../../../../components/ui/CalculatorPortlet';
import { CalculatorError, CalculatorFooter, Container, VariantImage } from '../../../../components/ui/calculator';
import { PlusIcon, ShareIcon } from '../../../../components/ui/calculator/Navigation';
import { useCountry } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { useGetCalculatorFooterText } from '../../../../utilities/calculator';
import { getCarModelsUrl } from '../../../../utilities/urls';
import { useApplyCalculator } from '../../../utils/ApplyCalculatorProvider';
import Navigation from '../../../utils/Navigation';
import { useShareFunction } from '../../../utils/ShareProvider';
import { CalculatorStepValues } from '../../steps/CalculatorStep';
import CarOptionsField from './CarOptionsField';
import NavBlock from './NavBlock';
import { cloneCalculatorValues } from './Page';
import useApplyDisabled from './useApplyDisabled';
import noImg from '../../../../assets/images/no-image.png';

export const useVariant = (dealerId: string, variantId?: string | null) => {
    // get the variants (the connected calculator will handle the loading effect)
    const { variants } = useLoadVariants('cache-first', Channel.NEW, dealerId);

    return useMemo(() => {
        return find(item => item.id === variantId, variants);
    }, [variants, variantId]);
};

export type SingleProps = {
    initialValues: Partial<CalculatorValues>;
    dealerId: string;
    setCalculators: Dispatch<SetStateAction<any[]>>;
    snapshot?: any;
    fallbackFinanceProduct?: FinanceDataFragment | null;
    selectedDealerEstablishment?: number;
};
const Single = ({
    initialValues,
    dealerId,
    setCalculators,
    snapshot,
    fallbackFinanceProduct,
    selectedDealerEstablishment,
}: SingleProps) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const { channelSetting } = useCountry();

    const contextChangeRef = useRef<CalculatorContext<CalculatorValues>['change']>();

    const {
        allowSharing,
        isFinanceApplicationMandatory,
        allowFinanceApplication,
        isInsuranceEnabled,
        isDepositPaymentMandatory,
    } = channelSetting.new;

    const shouldDisplayFinanceOption = !isFinanceApplicationMandatory && allowFinanceApplication;
    const shouldDisplayInsuranceOption = isInsuranceEnabled;

    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    // calculator context states
    const additionalMeta = useMemo(
        () => ({
            snapshot,
            channel: Channel.NEW,
            selectedDealerEstablishment,
        }),
        [snapshot, selectedDealerEstablishment]
    );
    const meta = useCalculatorMeta(additionalMeta);

    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const [insuranceContext, setInsuranceContext] = useState<CalculatorContext<CalculatorInsuranceValues>>();
    const [insuranceErrors, setInsuranceErrors] = useState<null | InsuranceCalculatorErrors>(null);
    const { values, errors } = context || {};

    // the variant may eventually change later
    const variant = useVariant(dealerId, values?.variant || initialValues.variant);

    // we need to render option outside the calculator
    // so we can create a div with an unique id
    const optionContainerId = useMemo(uuid, []);

    useEffect(() => {
        // Retrigger calculator when dealer established changed
        // Because the value of dealer establishment is provided through meta
        if (contextChangeRef.current && selectedDealerEstablishment) {
            contextChangeRef.current('dealerEstablishment', selectedDealerEstablishment);
        }
    }, [selectedDealerEstablishment]);

    const onChangeContext = useCallback((calculatorContext: CalculatorContext<CalculatorValues>) => {
        setContext(calculatorContext);

        if (!contextChangeRef.current) {
            contextChangeRef.current = calculatorContext.change;
        }
    }, []);

    // if there is no stock, disable next button
    // const remainStock = useMemo(() => {
    //     if (calculatorContext !== null) {
    //         const stock = calculatorContext.carOptions.stocks;
    //         // stocks are null when inventory is not active
    //         return stock === null || stock > 0;
    //     }
    //     return false;
    // }, [calculatorContext]);

    // comparison function
    const compare = useCallback(() => {
        if (values) {
            setCalculators([values, cloneCalculatorValues(values)]);
        }
    }, [setCalculators, values]);

    // share function
    const share = useShareFunction();

    const [isFinanceChecked, setIsFinanceChecked] = useState<boolean>(shouldDisplayFinanceOption);
    const [isInsuranceChecked, setIsInsuranceChecked] = useState<boolean>(isInsuranceEnabled);

    // submit function
    const submit = useApplyCalculator<CalculatorStepValues>();
    const onSubmit = useCallback(() => {
        if (!context) {
            throw new Error('missing values');
        }

        const { values, getFieldContext } = context;

        return submit({
            calculator: values,
            insuranceCalculator: insuranceContext?.values,
            bank: getFieldContext('bank').selectedBank,
            financeProduct: getFieldContext('financeProduct').selectedFinanceProduct,
            variant: getFieldContext('variant').selectedVariant,
            promo: getFieldContext('promoCode').promo,
            isFinanceEnabled: isFinanceChecked,
            isInsuranceEnabled: isInsuranceChecked,
        });
    }, [submit, context, insuranceContext, isFinanceChecked, isInsuranceChecked]);

    const availableFinanceProductsForVariant = useMemo(() => {
        if (!context) {
            return [];
        }

        const { getFieldContext } = context;

        return getFieldContext('bank').availableFinanceProducts;
    }, [context]);

    const insuranceCalculatorHasError = !isEmpty(insuranceErrors) || !isEmpty(insuranceContext?.errors);
    const shouldShowInsuranceError = isInsuranceChecked && insuranceCalculatorHasError && shouldDisplayInsuranceOption;

    // Shown finance error should happen when
    // 1. Finance is mandatory
    // 2. If finance is optional, it should based on the checkbox
    const shouldShowFinanceError = isFinanceApplicationMandatory || (shouldDisplayFinanceOption && isFinanceChecked);

    // is the submit disabled
    const submitStates = useApplyDisabled(
        values,
        fallbackFinanceProduct,
        variant,
        availableFinanceProductsForVariant,
        errors
    );

    const shouldDisable = useMemo(() => {
        const financeAndInsuranceDisabled =
            shouldDisplayFinanceOption &&
            shouldDisplayInsuranceOption &&
            !isDepositPaymentMandatory &&
            !isFinanceChecked &&
            !isInsuranceChecked;

        const financeHasError = submitStates.disabled;

        if (financeAndInsuranceDisabled) {
            return true;
        }

        // Same condition for displaying finance error
        if (financeHasError && (isFinanceChecked || isFinanceApplicationMandatory)) {
            return true;
        }

        if (shouldShowInsuranceError) {
            return true;
        }

        return false;
    }, [
        shouldDisplayFinanceOption,
        shouldDisplayInsuranceOption,
        isDepositPaymentMandatory,
        isFinanceChecked,
        isInsuranceChecked,
        submitStates.disabled,
        isFinanceApplicationMandatory,
        shouldShowInsuranceError,
    ]);

    const onFinanceCheckChange = useCallback(() => {
        setIsFinanceChecked(state => !state);
    }, [setIsFinanceChecked]);

    const onInsuranceCheckChange = useCallback(() => {
        setIsInsuranceChecked(state => !state);
    }, [setIsInsuranceChecked]);

    const history = useHistory();
    // @ts-ignore
    const onPrev = useCallback(() => history.pushWithCompany(getCarModelsUrl), [history]);

    useEffect(() => {
        if (!isEqual(selectedDealerId, dealerId)) {
            onPrev();
        }
    }, [dealerId, history, onPrev, selectedDealerId]);

    const country = useCountry();
    const { formatPath } = useContentTranslation();
    const { variants } = useLoadVariants('cache-and-network', Channel.NEW, dealerId);

    const selectedBankEstablishment = fallbackFinanceProduct?.bank.establishment;

    // get the text footer from the country
    const getCalculatorFooterText = useGetCalculatorFooterText();

    const calculatorFooterText = getCalculatorFooterText(
        get(formatPath('channelSetting.new.priceDisclaimer'), country),
        country?.coe?.amount,
        country?.ppsr?.amount,
        country?.establishment?.amount,
        country?.luxuryTax?.amount,
        selectedDealerEstablishment,
        selectedBankEstablishment
    );

    const insuranceCalculatorFooterText = getCalculatorFooterText(
        get(formatPath('channelSetting.new.insurancePriceDisclaimer'), country),
        country?.coe?.amount,
        country?.ppsr?.amount,
        country?.establishment?.amount,
        country?.luxuryTax?.amount,
        selectedDealerEstablishment,
        selectedBankEstablishment
    );

    const handleInsurancePremiumChange = useCallback(({ errors }: { errors: InsuranceCalculatorErrors }) => {
        setInsuranceErrors(errors);
    }, []);

    const renderFinanceOnlyCalculator = useCallback(() => {
        return (
            <CalculatorPortlet closeable={false} displayOption={false} name={t('newCalculatorPage.label.finance')} open>
                <ConnectedCalculator
                    // by using key we turn it into a controller component
                    key={initialValues.variant}
                    channel={Channel.NEW}
                    dealerId={dealerId}
                    initialValues={initialValues}
                    meta={meta}
                    onChange={onChangeContext}
                >
                    <CarOptionsField
                        containerId={optionContainerId}
                        fieldKey="carOptions"
                        isViewable={displayFields.isCarOptionsViewable}
                        size={0}
                    />
                    <displayFields.CarModelPriceField
                        fieldKey="carModelAndPrice"
                        isViewable={() => true}
                        size={2}
                        override
                    />
                    <displayFields.CarModelField
                        fieldKey="variant"
                        isViewable={() => false}
                        labelTKey="calculator.label.carModel"
                        override
                    />
                    <displayFields.CarPriceField
                        fieldKey="carPrice"
                        isViewable={() => false}
                        labelTKey="calculator.label.carPrice"
                        override
                    />
                </ConnectedCalculator>
                <CalculatorFooter dangerouslySetInnerHTML={{ __html: calculatorFooterText || '' }} />
            </CalculatorPortlet>
        );
    }, [calculatorFooterText, dealerId, initialValues, meta, onChangeContext, optionContainerId, t]);

    const renderDefaultCalculator = useCallback(() => {
        return (
            <>
                <div style={{ marginBottom: shouldDisplayFinanceOption ? '0 ' : '5px' }}>
                    <BaseCalculator
                        channel={Channel.NEW}
                        financeContext={context}
                        insuranceContext={insuranceContext}
                    />
                </div>
                <CalculatorPortlet
                    checked={isFinanceChecked}
                    displayOption={shouldDisplayFinanceOption}
                    name={t('newCalculatorPage.label.finance')}
                    onClick={onFinanceCheckChange}
                    closeable
                    open
                >
                    <ConnectedCalculator
                        // by using key we turn it into a controller component
                        key={initialValues.variant}
                        channel={Channel.NEW}
                        dealerId={dealerId}
                        initialValues={initialValues}
                        meta={meta}
                        onChange={onChangeContext}
                    >
                        <CarOptionsField
                            containerId={optionContainerId}
                            fieldKey="carOptions"
                            isViewable={displayFields.isCarOptionsViewable}
                            size={0}
                        />
                        <displayFields.CarModelPriceField
                            fieldKey="carModelAndPrice"
                            isViewable={() => false}
                            size={2}
                            override
                        />
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => false}
                            labelTKey="calculator.label.carModel"
                            override
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                        <displayFields.COEField
                            fieldKey="coe"
                            isViewable={() => false}
                            labelTKey="calculator.label.coe"
                            override
                        />
                        <displayFields.TotalPriceField
                            fieldKey="totalPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.totalPrice"
                            override
                        />
                    </ConnectedCalculator>
                    <CalculatorFooter dangerouslySetInnerHTML={{ __html: calculatorFooterText || '' }} />
                </CalculatorPortlet>
                {shouldDisplayInsuranceOption && (
                    <CalculatorPortlet
                        checked={isInsuranceChecked}
                        displayOption={shouldDisplayInsuranceOption}
                        name={t('newCalculatorPage.label.insurance')}
                        onClick={onInsuranceCheckChange}
                        closeable
                        open
                    >
                        <InsuranceCalculator
                            key={initialValues.variant}
                            channel={Channel.NEW}
                            dealerId={dealerId}
                            initialValues={initialValues}
                            meta={meta}
                            onChange={setInsuranceContext}
                            onInsurancePremiumChange={handleInsurancePremiumChange}
                            variants={variants}
                        >
                            <displayFields.COEField
                                fieldKey="coe"
                                isViewable={() => false}
                                labelTKey="calculator.label.coe"
                            />
                            <displayFields.CarModelPriceField fieldKey="totalPrice" isViewable={() => false} override />
                        </InsuranceCalculator>
                        <CalculatorFooter dangerouslySetInnerHTML={{ __html: insuranceCalculatorFooterText || '' }} />
                    </CalculatorPortlet>
                )}
            </>
        );
    }, [
        calculatorFooterText,
        context,
        dealerId,
        handleInsurancePremiumChange,
        initialValues,
        insuranceCalculatorFooterText,
        insuranceContext,
        isFinanceChecked,
        isInsuranceChecked,
        meta,
        onChangeContext,
        onFinanceCheckChange,
        onInsuranceCheckChange,
        optionContainerId,
        shouldDisplayFinanceOption,
        shouldDisplayInsuranceOption,
        variants,
        t,
    ]);

    const insuranceContextErrors = useMemo(() => insuranceContext?.errors, [insuranceContext]);

    const usedInsuranceError = useMemo(
        () => (!isEmpty(insuranceContextErrors) ? insuranceContextErrors : insuranceErrors),
        [insuranceContextErrors, insuranceErrors]
    );

    return (
        <>
            <Container>
                <Navigation onPrev={onPrev} prevText={t('newCalculatorPage.button.previous')}>
                    <NavBlock icon={<PlusIcon />} label={t('newCalculatorPage.button.compare')} onClick={compare} />
                    {allowSharing && (
                        <NavBlock
                            disabled={submitStates.disabled}
                            icon={<ShareIcon />}
                            label={t('newCalculatorPage.button.share')}
                            onClick={() => values && share([values])}
                        />
                    )}
                </Navigation>
                <VariantImage alt={ct(variant?.name)} fallbackSrc={noImg} src={variant?.images[0]?.url || noImg} />
            </Container>
            <div id={optionContainerId} />
            <Container>
                {isFinanceApplicationMandatory && allowFinanceApplication && !isInsuranceEnabled
                    ? renderFinanceOnlyCalculator()
                    : renderDefaultCalculator()}
                {submitStates.hidden || (
                    <Actions>
                        <DarkButton disabled={shouldDisable} onClick={onSubmit}>
                            {t('newCalculatorPage.button.next')}
                        </DarkButton>
                    </Actions>
                )}
                <CalculatorError disabled={!shouldShowFinanceError} errors={errors} />
                {shouldShowInsuranceError && insuranceCalculatorHasError && (
                    <CalculatorError errors={usedInsuranceError} />
                )}
            </Container>
        </>
    );
};

export default memo(Single);
