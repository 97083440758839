import React from 'react';
import { useLocation, useParams } from 'react-router';
import { usePersistedFlow } from '../../../../flows/utils/persistency';
import RedirectToCompanyHome from '../../../utilities/RedirectToCompanyHome';
import { serialize, unserialize, generateCacheKey } from '../DraftRoute';
import InsuranceResumeBootstrap from './InsuranceResumeBootstrap';
import InsuranceResumeFlowHandler from './InsuranceResumeFlowHandler';

type LocationParams = { id: string };

type LocationState = { token?: string };

const InsuranceResumeRoute = () => {
    const { token: tokenFromLocation } = useLocation<LocationState>().state || {};
    const { id: applicationVersionId } = useParams<LocationParams>();

    const { token = tokenFromLocation } =
        usePersistedFlow(
            `insuranceResumeFlow:${applicationVersionId}`,
            'insuranceResumeFlow',
            serialize,
            unserialize,
            generateCacheKey
        ) || {};

    if (!token) {
        return <RedirectToCompanyHome />;
    }

    return (
        <InsuranceResumeBootstrap token={token}>
            {(insuranceApplication, consents) => (
                <InsuranceResumeFlowHandler
                    consents={consents}
                    insuranceApplication={insuranceApplication}
                    token={token}
                />
            )}
        </InsuranceResumeBootstrap>
    );
};

export default InsuranceResumeRoute;
