import styled, { css } from 'styled-components';

const CheckboxContainer = styled.label`
    display: flex;
    align-items: center;
    margin: ${props => props.margin};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

CheckboxContainer.Checkbox = styled.div`
    background-color: ${props => (props.isV2Design && props.checked ? '#313539' : '#fff')};
    width: ${props => (props.isV2Design ? '22px' : '25px')};
    height: ${props => (props.isV2Design ? '22px' : '25px')};
    display: flex;
    align-items: center;

    ${props =>
        props.isV2Design &&
        props.checked &&
        css`
            color: #fff;
            text-align: center;
            font-size: 12px;
            line-height: 22px;
        `}

    svg {
        width: ${props => (props.isV2Design ? '22px !important' : '25px')};
    }

    input {
        display: none;
    }
`;

CheckboxContainer.Label = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-align: left;
    font-size: ${props => (props.isV2Design ? '16px' : '1.21rem')};
    color: #000;
`;

export default CheckboxContainer;
