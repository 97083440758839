const validateFile = (file: File | object) => {
    if (!(file instanceof File)) {
        return true;
    }

    const extension = file.name.split('.').pop()?.toLowerCase();
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];

    return !!extension && allowedExtensions.includes(extension);
};

export default validateFile;
