import React, { useContext, ComponentType } from 'react';
import { ModalContext, ModalContextData } from './ModalProvider';

export const useModal = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error('Modal context not provided');
    }

    return context;
};

export type WithModalInjectedProps = {
    modal: ModalContextData;
};

export const withModal = <Props extends {}>(
    WrappedComponent: ComponentType<Props & WithModalInjectedProps>
): ComponentType<Props> => {
    const EnhancedComponent = (props: Props) => {
        const modal = useModal();

        return <WrappedComponent {...props} modal={modal} />;
    };

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    EnhancedComponent.displayName = `withModal(${displayName})`;

    return EnhancedComponent;
};
