import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAuthorization } from '../../actions';
import { logout } from '../../actions/shared';
import { getAuthorization } from '../../selectors';

const getTokenPart = (token, part) => atob(token.split('.')[part]);

const RefreshAuthorization = ({ refreshAt = 0.9, retriesInterval = 60 }) => {
    const dispatch = useDispatch();

    // get the authorization object
    const authorization = useSelector(getAuthorization);

    useEffect(() => {
        // the timeout ID
        let timer = null;

        const { accessToken } = authorization;

        if (accessToken) {
            // we are currently logged in
            // so we need to keep the token alive
            // first we need to know more about it
            const { iat, exp } = JSON.parse(getTokenPart(accessToken, 1));
            const countdown = (exp - iat) * refreshAt * 1000;

            // we want to avoid concurrent tries
            let pendingTry = null;

            // then create a function to refresh our token
            const refresh = async () => {
                // get the current timestamp
                const now = Date.now();

                if (now < countdown || pendingTry !== null) {
                    // no need to go further for now
                    return;
                }

                try {
                    // refresh the token
                    pendingTry = dispatch(refreshAuthorization());

                    // wait for it
                    await pendingTry;
                } catch (error) {
                    // print the error because maybe it is not network related
                    console.error(error);
                    // we are going to logout
                    dispatch(logout());
                } finally {
                    // the try ended
                    pendingTry = null;
                }
            };

            // start the interval
            timer = setInterval(refresh, retriesInterval * 1000);
        }

        return () => {
            // we need to stop the timer
            clearTimeout(timer);
        };
    }, [authorization, dispatch, refreshAt, retriesInterval]);

    return null;
};

RefreshAuthorization.propTypes = {
    // percentage of timeout at which we refresh the token
    refreshAt: PropTypes.number,
    // interval in seconds between retries
    retriesInterval: PropTypes.number,
};

export default RefreshAuthorization;
