import React from 'react';
import { Navigation as Components } from '../../../../components/ui/calculator';

const { Block } = Components;

export type NavBlockProps = {
    onClick: () => void;
    label: string;
    icon: JSX.Element;
    disabled?: boolean;
};

const NavBlock = ({ onClick, label, icon, disabled }: NavBlockProps) => (
    <Block>
        <button disabled={disabled} onClick={onClick} type="button">
            <span>{label}</span>
            {icon}
        </button>
    </Block>
);

export default NavBlock;
