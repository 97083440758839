import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Document } from '@appvantageasia/afc-ui';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { handleResponseError } from '../../../../../utilities/forms';
import FileDropBox from '../../../ApplicationRoute/Document/FileDropBox';
import { withModal } from '../../../ApplicationRoute/Document/ModalConsumers';
import {
    uploadAppointmentAttachment,
    UploadAppointmentAttachmentMutation,
    UploadAppointmentAttachmentMutationVariables,
} from '../Details.graphql';

const { PreviewUpload, ErrorMessageDiv, LoadingIndicator } = Document;

export type DocumentUploadProps = WrappedFieldProps & {
    referenceId: string;
};

const DocumentUpload = ({ meta, input, referenceId }: DocumentUploadProps) => {
    const [uploading, setUploading] = useState(false);

    const client = useApolloClient();

    const handleUpload = useCallback(
        async file => {
            setUploading(true);

            // get the upload url
            client
                .mutate<UploadAppointmentAttachmentMutation, UploadAppointmentAttachmentMutationVariables>({
                    mutation: uploadAppointmentAttachment,
                    variables: { file, id: referenceId },
                })
                .then(({ data }) => {
                    if (data) {
                        const previousDocuments = isArray(input.value) ? input.value : [];

                        input.onChange([...previousDocuments, data?.attachment]);
                    }
                })
                .catch(handleResponseError);

            setUploading(false);
        },
        [client, referenceId, input]
    );

    const handleDrop = useCallback(
        filesArg => {
            if (filesArg[0]) {
                return handleUpload(filesArg[0]);
            }

            return Promise.resolve();
        },
        [handleUpload]
    );

    const onChange = useCallback(event => handleDrop(event.target.files), [handleDrop]);

    const { error, touched, active } = meta;
    const { onBlur, onFocus } = input;

    return (
        <>
            <PreviewUpload>
                <FileDropBox className="upload" onDrop={handleDrop}>
                    <input onBlur={onBlur} onChange={onChange} onFocus={onFocus} type="file" />
                    <div className="replace-style">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div>upload</div>
                        {uploading && (
                            <LoadingIndicator>
                                <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                            </LoadingIndicator>
                        )}
                    </div>
                </FileDropBox>
            </PreviewUpload>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </>
    );
};

export default withModal(DocumentUpload);
