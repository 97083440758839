import { useQuery } from '@apollo/client';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import {
    getProceedWithCustomerDeviceContext,
    GetProceedWithCustomerDeviceContextQuery,
    GetProceedWithCustomerDeviceContextQueryVariables,
} from '../../../../api/application.graphql';
import { RemoteFlow } from '../../../../schema';
import useLoading from '../../../../useLoading';

export type RemoteContext = GetProceedWithCustomerDeviceContextQuery['ProceedWithCustomerDeviceContext'] & {
    versionId: string;
};

export type RemoteDataProps = {
    children: (context: RemoteContext) => ReactElement | null;
    flow: RemoteFlow;
};

type Params = { applicationId: string; sourceId: string };

const RemoteData = ({ children, flow }: RemoteDataProps) => {
    const { applicationId, sourceId } = useParams<Params>();

    const { data, loading } = useQuery<
        GetProceedWithCustomerDeviceContextQuery,
        GetProceedWithCustomerDeviceContextQueryVariables
    >(getProceedWithCustomerDeviceContext, {
        variables: { applicationId, source: sourceId, flow },
    });

    useLoading(loading);

    const context = data?.ProceedWithCustomerDeviceContext;

    if (loading || !context) {
        return null;
    }

    return children({ versionId: applicationId, ...context });
};

export default RemoteData;
