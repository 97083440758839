import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../components/shared/calculator-next/types';
import { Channel } from '../../../../schema';
import ApplyCalculatorProvider from '../../../utils/ApplyCalculatorProvider';
import OptionsControlProvider from '../../../utils/OptionsControlProvider';
import ShareProvider from '../../../utils/ShareProvider';
import Page from './Page';

type RouterParams = { variantId: string };

export type CalculatorProps = {
    onSubmit: (values: any) => Promise<void>;
    dealerId: string;
    initialValues?: Partial<CalculatorValues>;
    initialInsuranceValues?: Partial<CalculatorInsuranceValues>;
    snapshot?: any;
};

const Calculator = ({
    onSubmit,
    snapshot,
    initialValues: initialValuesFromProps,
    initialInsuranceValues,
    dealerId,
}: CalculatorProps) => {
    // get the initial variant id from react router
    const { variantId: initialVariantId } = useParams<RouterParams>();

    // get initial values
    const initialValues = useMemo(() => {
        if (!initialValuesFromProps) {
            return { variant: initialVariantId };
        }

        return { ...initialValuesFromProps, ...initialInsuranceValues };
    }, [initialVariantId, initialValuesFromProps, initialInsuranceValues]);

    return (
        <OptionsControlProvider>
            <ApplyCalculatorProvider handleSubmit={onSubmit}>
                <ShareProvider channel={Channel.NEW} dealerId={dealerId}>
                    <Page dealerId={dealerId} initialValues={initialValues} snapshot={snapshot} />
                </ShareProvider>
            </ApplyCalculatorProvider>
        </OptionsControlProvider>
    );
};

export default Calculator;
