import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getCompanyIdentifier } from '../../../selectors';
import { getInsuranceApplicationsUrl } from '../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../shared/HelmetTitle';
import Details from './Details';
import {
    getInsuranceApplication,
    GetInsuranceApplicationQuery,
    GetInsuranceApplicationQueryVariables,
} from './data.graphql';

export type LocationParams = { id: string };

export type InsuranceApplicationData = GetInsuranceApplicationQuery['insuranceApplication'];

const InsuranceRoute = () => {
    const { language } = useContentTranslation();
    const history = useHistory();
    const { id } = useParams<LocationParams>();
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    const { data, loading } = useQuery<GetInsuranceApplicationQuery, GetInsuranceApplicationQueryVariables>(
        getInsuranceApplication,
        {
            variables: { id },
            fetchPolicy: 'no-cache',
        }
    );

    const insuranceApplication = data?.insuranceApplication;
    const application = insuranceApplication?.application;
    const url = getInsuranceApplicationsUrl(companyCode, locationCode, application);

    useEffect(() => {
        if (insuranceApplication?.dealerId) {
            if (!isEqual(selectedDealerId, insuranceApplication.dealerId)) {
                history.push(url);
            }
        }
    }, [insuranceApplication, selectedDealerId, history, url]);

    if (loading) {
        // wait for the loading to be over
        return null;
    }

    if (!insuranceApplication) {
        return <Redirect to={url} />;
    }

    return (
        <>
            <HelmetTitle title="Insurance Application Details" />
            <Details key={insuranceApplication.id} application={insuranceApplication} />
        </>
    );
};

export default InsuranceRoute;
