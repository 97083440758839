import styled from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';

const CarModelHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    margin: 0 auto;
    max-width: 1024px;

    & > * {
        margin-top: 20px;

        margin-bottom: 20px;

        padding: 0 10px;

        width: 100%;

        @media (min-width: ${breakpoints.md}) {
            width: auto;
        }
    }
`;

export default CarModelHeader;
