import { ApolloError } from '@apollo/client';
import { flow, get, isArray, isObject, mapValues, omit, set, trim, isNil, isEmpty } from 'lodash/fp';
import { FormErrors, SubmissionError } from 'redux-form';
import * as yup from 'yup';
import { VersionDataFragment } from '../components/routes/ApplicationRoute/data.graphql';
import { CustomerDetailsSource } from '../schema';
import { BadRequest } from './HttpErrors';

export const requiredString = (msg: string) => yup.string().nullable().required(msg);
export const requiredNumber = (msg: string) => yup.number().nullable().required(msg);
export const requiredBool = (msg: string) => yup.bool().nullable().required(msg);

export type FormValidation<TValues = any, TProps = any> = (values: TValues, props: TProps) => FormErrors<TValues>;

export const createFormValidation = <TFormData = any>(schema: yup.Schema<any>): FormValidation<TFormData, any> => (
    values,
    props
) => {
    try {
        schema.validateSync(values, { abortEarly: false, context: props });
    } catch (validationError) {
        if (validationError instanceof yup.ValidationError) {
            return validationError.inner.reduce((errors, error) => {
                const {
                    path,
                    errors: [message],
                } = error;

                if (get(path, errors)) {
                    // do not override errors, only keep the first
                    return errors;
                }

                return set(path, message, errors);
            }, {});
        }

        throw validationError;
    }

    return {};
};

export const fromContextValidation = (
    instance: yup.StringSchema<any>,
    validationKey: string,
    errorMessage: string,
    allowEmpty: boolean = false
) =>
    // @ts-ignore
    yup.lazy((values: any, options: any) => {
        const validation = get(['context', 'validation', validationKey], options);

        if (!validation) {
            // nothing to test yet
            return instance;
        }

        return instance.test('match-validation', errorMessage, value => {
            if (allowEmpty && (isNil(value) || isEmpty(value))) {
                return true;
            }

            return validation.test(value);
        });
    });

export const handleResponseError = (error: Error) => {
    if (error instanceof BadRequest) {
        throw new SubmissionError({ _error: error.message });
    }

    if (error instanceof ApolloError) {
        // because we already handle GRAPHQL_VALIDATION_FAILED reaction in App.js
        // so here we do not need throw error
        for (const err of error.graphQLErrors) {
            // @ts-ignore
            switch (err.extensions.code) {
                case 'GRAPHQL_VALIDATION_FAILED':
                    return;

                default:
                    break;
            }
        }

        const messages = error.graphQLErrors.map(({ message }) => `${message}`).join(', ');
        throw new SubmissionError({ _error: messages });
    }

    if (error instanceof SubmissionError) {
        // throw it back
        throw error;
    }

    // print ouf the error
    console.error(error);

    throw new SubmissionError({ _error: 'Something wrong happened' });
};

export const prepareForGraphQL = (data: any): any => {
    if (data instanceof Date) {
        return data.toISOString();
    }

    if (isArray(data)) {
        return data.map(prepareForGraphQL);
    }

    if (isObject(data)) {
        return flow([omit(['__typename', '__exclude']), mapValues(prepareForGraphQL)])(data);
    }

    return data;
};

const validSGNirc = (input: string, dateOfBirth?: Date | string) => {
    const match = input.match(/^(?<prefix>[STFGM])(?<digits>\d{7})(?<checksum>[A-Z])$/);

    if (!match) {
        return false;
    }

    // @ts-ignore
    const { prefix, digits, checksum } = match.groups;

    // validate only DoB of local singaporean
    if ((prefix === 'S' || prefix === 'T') && dateOfBirth) {
        if (new Date(dateOfBirth).getFullYear() < 2000 && prefix !== 'S') {
            return false;
        }

        if (new Date(dateOfBirth).getFullYear() > 2000 && prefix !== 'T') {
            return false;
        }
    }

    let offset = 0;

    if (prefix === 'T' || prefix === 'G') {
        offset = 4;
    } else if (prefix === 'M') {
        offset = 3;
    }

    const weights = [2, 7, 6, 5, 4, 3, 2];

    const sum = (digits as string)
        .split('')
        .reduce((accumulation, char, index) => accumulation + parseInt(char, 10) * weights[index], offset);

    let checksums = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];

    if (prefix === 'F' || prefix === 'G') {
        checksums = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
    } else if (prefix === 'M') {
        checksums = ['K', 'L', 'J', 'N', 'P', 'Q', 'R', 'T', 'U', 'W', 'X'];
    }

    let index = sum % 11;

    if (prefix === 'M') {
        index = 10 - index;
    }

    return checksum === checksums[index];
};

export const validateNirc = (countryCode: string) => {
    switch (countryCode) {
        case 'SG':
            return validSGNirc;

        default:
            return () => true;
    }
};

class CustomerProperty extends yup.object {
    constructor() {
        super();

        this.withMutation(() => {
            // avoid null values
            this.transform(value => value || {});
        });
    }

    resolve(options: any) {
        if (
            [CustomerDetailsSource.MYINFO, CustomerDetailsSource.NOT_APPLICABLE].includes(get('source', options.value))
        ) {
            // @ts-ignore
            return yup.mixed().notRequired().resolve(options);
        }

        // avoid null values and undefined
        // @ts-ignore
        return super.resolve({ ...options, value: options.value || {} });
    }
}

export const yupExt = {
    ...yup,
    customerProperty: () => new CustomerProperty(),
};

export const onTelKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const prevent = keyCode < 48 || keyCode > 57;

    if (prevent) {
        event.preventDefault();
    }

    return prevent;
};

export const createFileSizeValidator = (sizeInMiB: number = 5) => (file: File | object) => {
    if (!(file instanceof File)) {
        return true;
    }

    const fileSize = Math.round(file.size / 1024);

    return fileSize <= 1024 * sizeInMiB;
};

export const getLastModified = (
    version: Pick<VersionDataFragment, 'updatedBy' | 'updatedAt'>,
    format: (date: string | Date) => string
) => {
    if (!version) {
        return 'never modified';
    }

    const { updatedBy, updatedAt } = version;

    const by = trim(updatedBy?.name || 'System');

    return `${format(updatedAt)} by ${by}`;
};

export const validateTextFieldOnly = (value: string) => {
    // L Unicode Category for Letter
    // M Unicode Category for Mark
    const match = /^[\p{L}\p{M}\s]+$/u;

    return match.test(value);
};

// to block special characters
export const validateTextAndNumFieldOnly = (value: string) => {
    const match = /^[a-zA-Z0-9\s]+$/;

    return match.test(value);
};

export const validateNumFieldOnly = (value: string) => {
    const match = /^[0-9\s]+$/;

    return match.test(value);
};

export const onTextKeyPress = (event: KeyboardEvent) => {
    const prevent = validateTextFieldOnly(event.key);

    if (!prevent) {
        event.preventDefault();
    }

    return prevent;
};

export const onTextOrNumKeyPress = (event: KeyboardEvent) => {
    const prevent = validateTextAndNumFieldOnly(event.key);

    if (!prevent) {
        event.preventDefault();
    }

    return prevent;
};

export const onNumKeyPress = (event: KeyboardEvent) => {
    const prevent = validateNumFieldOnly(event.key);

    if (!prevent) {
        event.preventDefault();
    }

    return prevent;
};
