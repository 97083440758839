import React from 'react';
import { FormSection } from 'redux-form';

import PreOwnedDetails from '../../../flows/DraftFlow/components/ExpressCalculator/PreOwnedDetails';
import { Grid } from '../../../flows/DraftFlow/components/ExpressCalculator/ui';
import Calculator, { ExpressCalculatorProps } from '../../shared/calculator-next/ExpressCalculator';

type ExpressWrapperProps = {
    countryCode: string;
    children?: React.ReactNode;
};

const ExpressWrapper = ({ countryCode, children }: ExpressWrapperProps) => {
    if (countryCode === 'SG') {
        return (
            <Grid>
                <FormSection name="expressVariant">
                    <PreOwnedDetails computationsOnly />
                </FormSection>
                <div>{children}</div>
            </Grid>
        );
    }

    return <div>{children}</div>;
};

export type ExpressCalculatorWithCountry = ExpressCalculatorProps & { countryCode: string };

const ExpressCalculator = ({ countryCode, ...props }: ExpressCalculatorWithCountry) => (
    <ExpressWrapper countryCode={countryCode}>
        <Calculator {...props} />
    </ExpressWrapper>
);

export default ExpressCalculator;
