/* helper function to validate the token */

export const checkTokenValidity = (token: string): boolean => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const { exp } = JSON.parse(atob(base64)) as { exp: number };

    return new Date() < new Date(exp * 1000);
};

export const validateToken = (token: string): void => {
    if (!checkTokenValidity(token)) {
        // token is invalid
        throw new Error('Session expired');
    }
};
