import styled from 'styled-components';

const PaymentWidgetContainer = styled.div`
    guest-payment-widget #payment-widget {
        padding: 0;
        margin-top: 16px;
    }
`;

export default PaymentWidgetContainer;
