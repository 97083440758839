import styled from 'styled-components';

const OTPLink = styled.a<{ disabled?: boolean }>`
    line-height: 2.5;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    color: ${props => (props.disabled ? '#7e7e7e' : props.theme.themeHighlightColour)};

    @media (max-width: 450px) {
        margin-top: 0;
    }
`;

export default OTPLink;
