import { ApolloClient, useApolloClient } from '@apollo/client';
// @ts-ignore
import { DarkButton, GrayButton, ActionsV2, Actions, Modal, Footer } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { attachLoading, setNotification } from '../../../../actions';
import {
    CancelInsuranceApplicationMutation,
    CancelInsuranceApplicationMutationVariables,
    DeclineInsuranceApplicationMutation,
    DeclineInsuranceApplicationMutationVariables,
    ApproveInsuranceApplicationMutation,
    ApproveInsuranceApplicationMutationVariables,
    declineInsuranceApplication,
    approveInsuranceApplication,
    cancelInsuranceApplication,
} from '../../../../api/application.graphql';
import { InsuranceApplicationStatus } from '../../../../schema';
import { getCompanyIdentifier, getGlobalPermissions } from '../../../../selectors';
import {
    APPLICATION_CANCEL_NOTIFICATION,
    APPLICATION_APPROVE_NOTIFICATION,
    APPLICATION_DECLINE_NOTIFICATION,
} from '../../../../utilities/constants/notification';
import { getApplicationsUrl } from '../../../../utilities/urls';
import ModalContent from '../../../ui/calculator/ModalContent';
import { InsuranceApplicationDataFragment } from '../data.graphql';

export const getStatusMessages = (id: string, status: InsuranceApplicationStatus) => {
    switch (status) {
        case InsuranceApplicationStatus.CANCELLED:
            return APPLICATION_CANCEL_NOTIFICATION(id);

        case InsuranceApplicationStatus.APPROVED:
            return APPLICATION_APPROVE_NOTIFICATION(id);

        case InsuranceApplicationStatus.DECLINED:
            return APPLICATION_DECLINE_NOTIFICATION(id);

        default:
            return null;
    }
};

export const titles = {
    [InsuranceApplicationStatus.CANCELLED]: 'applicationDetailsPage.titles.cancelled',
    [InsuranceApplicationStatus.APPROVED]: 'applicationDetailsPage.titles.approved',
    [InsuranceApplicationStatus.DECLINED]: 'applicationDetailsPage.titles.declined',
};

export const questions = {
    [InsuranceApplicationStatus.CANCELLED]: 'applicationDetailsPage.questions.cancelled',
    [InsuranceApplicationStatus.APPROVED]: 'applicationDetailsPage.questions.approved',
    [InsuranceApplicationStatus.DECLINED]: 'applicationDetailsPage.questions.declined',
};

export type UpdateStateActionProps = {
    application: InsuranceApplicationDataFragment;
    showApproveDeclineActions?: boolean;
    approveDisabled?: boolean;
    declineDisabled?: boolean;
};

export const getMutation = (client: ApolloClient<any>, id: string, nextStatus: InsuranceApplicationStatus) => {
    const variables = { id };

    switch (nextStatus) {
        case InsuranceApplicationStatus.CANCELLED:
            return client.mutate<CancelInsuranceApplicationMutation, CancelInsuranceApplicationMutationVariables>({
                mutation: cancelInsuranceApplication,
                variables,
            });

        case InsuranceApplicationStatus.APPROVED:
            return client.mutate<ApproveInsuranceApplicationMutation, ApproveInsuranceApplicationMutationVariables>({
                mutation: approveInsuranceApplication,
                variables,
            });

        case InsuranceApplicationStatus.DECLINED:
            return client.mutate<DeclineInsuranceApplicationMutation, DeclineInsuranceApplicationMutationVariables>({
                mutation: declineInsuranceApplication,
                variables,
            });

        default:
            throw new Error('status not handled');
    }
};

const UpdateStateAction = ({
    application,
    showApproveDeclineActions,
    approveDisabled,
    declineDisabled,
}: UpdateStateActionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useApolloClient();
    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const { mayManageInsuranceApplication } = useSelector(getGlobalPermissions);

    const url = getApplicationsUrl(companyCode, locationCode, application);

    const [nextStatus, setNextStatus] = useState<InsuranceApplicationStatus | null>(null);
    const { id, identifier, status } = application;

    if (!identifier) {
        throw new Error('identifier missing in state');
    }

    const close = useCallback(() => setNextStatus(null), [setNextStatus]);

    const submit = useCallback(async () => {
        if (!nextStatus) {
            return;
        }

        // wait for the update to be completed
        const promise = getMutation(client, id, nextStatus);
        await dispatch(attachLoading(promise));

        // get notification message
        const message = getStatusMessages(identifier, nextStatus);

        if (message) {
            dispatch(setNotification(message.title, message.message));
        }

        // then redirect
        history.push(url);
    }, [dispatch, history, id, identifier, nextStatus, url, client]);

    const voidDisabled =
        !mayManageInsuranceApplication ||
        [InsuranceApplicationStatus.CANCELLED || InsuranceApplicationStatus.COMPLETED].includes(status);

    return (
        <ActionsV2>
            {mayManageInsuranceApplication && showApproveDeclineActions && (
                <>
                    <Footer.FootBarButton
                        disabled={voidDisabled}
                        onClick={() => setNextStatus(InsuranceApplicationStatus.CANCELLED)}
                    >
                        {t('applicationDetailsPage.button.void')}
                    </Footer.FootBarButton>
                    <Footer.FootBarButton
                        disabled={approveDisabled}
                        onClick={() => setNextStatus(InsuranceApplicationStatus.APPROVED)}
                    >
                        {t('applicationDetailsPage.button.approve')}
                    </Footer.FootBarButton>
                    <Footer.FootBarButton
                        disabled={declineDisabled}
                        onClick={() => setNextStatus(InsuranceApplicationStatus.DECLINED)}
                    >
                        {t('applicationDetailsPage.button.decline')}
                    </Footer.FootBarButton>
                </>
            )}
            {nextStatus && (
                <Modal onClose={close} title={t(get(nextStatus, titles))} showTitle>
                    <ModalContent>
                        <p>{t(get(nextStatus, questions))}</p>
                    </ModalContent>
                    <Actions>
                        <GrayButton onClick={close}>{t('applicationDetailsPage.button.cancel')}</GrayButton>
                        <DarkButton onClick={submit}>{t('applicationDetailsPage.button.ok')}</DarkButton>
                    </Actions>
                </Modal>
            )}
        </ActionsV2>
    );
};

export default UpdateStateAction;
