import styled from 'styled-components';

const ChatButton = styled.div`
    position: fixed;
    right: 0;
    bottom: 50px;
    border-radius: 4px 0 0 4px;
    background-color: ${props => props.theme.colors.activeColor};
    border: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    z-index: 99;
`;

export default ChatButton;
