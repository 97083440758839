import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, ReduxFormContext } from 'redux-form';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { getRuntimeSettings } from '../../../../../selectors';
import useDealerOptionsSelect from './useDealerOptionsSelect';

type CarOfInterestMarketingReconsentProps = {
    showDealerSelection: boolean;
    disabled: boolean;
    event: EventDataFragment;
};

const CarOfInterestMarketingReconsent = ({
    disabled,
    showDealerSelection,
    event,
}: CarOfInterestMarketingReconsentProps) => {
    const dispatch = useDispatch();
    const { form } = useContext(ReduxFormContext);
    const { marketingReconsentVariant } = useSelector(getRuntimeSettings);

    const renderDealerSelect = useDealerOptionsSelect({ event, showDealerSelection });

    useEffect(() => {
        // update form to use dummy variant id
        if (marketingReconsentVariant) {
            dispatch(change(form, 'carOfInterest.variantId', marketingReconsentVariant));
        }
    }, [dispatch, form, marketingReconsentVariant]);

    return renderDealerSelect({ disabled });
};

export default CarOfInterestMarketingReconsent;
