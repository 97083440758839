import { useQuery, WatchQueryFetchPolicy, FetchPolicy, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { isEmpty, orderBy, uniqBy } from 'lodash/fp';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Channel, Maybe } from '../../schema';
import { getCountryId } from '../../selectors';
import {
    getFinanceProduct,
    getFinanceProducts,
    GetFinanceProductQuery,
    GetFinanceProductsQuery,
    FinanceDataFragment,
} from './useFinanceProducts.graphql';
import { BankDataFragment } from './useLoadBank.graphql';

export type FinanceProduct = FinanceDataFragment;

export const getFinanceProductById = async (
    client: ApolloClient<NormalizedCacheObject>,
    financeProductId: string,
    fetchPolicy: FetchPolicy = 'cache-first'
): Promise<Maybe<FinanceProduct>> => {
    const variables = { id: financeProductId };
    const { data } = await client.query<GetFinanceProductQuery>({ query: getFinanceProduct, variables, fetchPolicy });

    return data.financeProduct || null;
};

const useFinanceProducts = (
    fetchPolicy: WatchQueryFetchPolicy,
    channel: Channel,
    dealerId?: string,
    eventId?: string
): { financeProducts: FinanceProduct[]; banks: BankDataFragment[] } => {
    // get country id
    const countryId = useSelector(getCountryId);

    // get data using apollo
    const { data } = useQuery<GetFinanceProductsQuery>(getFinanceProducts, {
        variables: { countryId, dealerId, channel, eventId },
        fetchPolicy,
        skip: isEmpty(countryId) || isEmpty(dealerId),
    });

    const banks = useMemo(
        () =>
            uniqBy(
                'id',
                data?.financeProducts.map(financeProduct => financeProduct.bank)
            ),
        [data]
    );

    return useMemo(
        () => ({
            financeProducts: orderBy('order', 'asc', data?.financeProducts || []),
            banks: orderBy('order', 'asc', banks || []),
        }),
        [banks, data]
    );
};

export default useFinanceProducts;
