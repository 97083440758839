import React, { ReactElement, useState } from 'react';
import { InjectedFormProps } from 'redux-form';
import DraftModal from './DraftModal';

type DraftModalProviderProps<T> = {
    children: (setDraftModal: React.Dispatch<React.SetStateAction<boolean>>) => ReactElement | null;
} & Pick<InjectedFormProps<any, T>, 'handleSubmit' | 'change'>;

const DraftModalProvider = <T extends unknown>({ children, handleSubmit, change }: DraftModalProviderProps<T>) => {
    const [showDraft, setDraftModal] = useState(false);

    return (
        <>
            {children(setDraftModal)}
            {showDraft && (
                <DraftModal change={change} handleSubmit={handleSubmit} onClose={() => setDraftModal(false)} />
            )}
        </>
    );
};

export default DraftModalProvider;
