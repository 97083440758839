import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import TextField from '../../../../components/shared/form/TextField';
import useCustomerNamesSynchronization from '../../../../components/shared/useCustomerNamesSynchronization';
import BoxedGrid from '../../../../components/ui/form/BoxedGrid';
import useCustomerSource from '../../../../components/utilities/useCustomerSource';
import usePhone from '../../../../components/utilities/usePhone';
import { onTelKeyPress, yupExt } from '../../../../utilities/forms';

const customerFields = ['name', 'firstName', 'lastName', 'email', 'phone', 'identityNumber'];

const AddGuarantorForm = () => {
    const { t } = useTranslation();
    const { code: phoneCountryCode, minDigits: minPhoneDigit, maxDigits: maxPhoneDigit } = usePhone();

    const { sectionPrefix } = useContext(ReduxFormContext);
    const { onNormalizeFullName } = useCustomerNamesSynchronization(sectionPrefix);
    useCustomerSource(customerFields, sectionPrefix);

    return (
        <BoxedGrid>
            <TextField.FullWidth
                label={t('guarantorDetails.label.name')}
                name="name.value"
                normalize={onNormalizeFullName}
            />
            <TextField.FullWidth label={t('guarantorDetails.label.email')} name="email.value" type="email" />
            <TextField
                label={t('guarantorDetails.label.mobile')}
                maxLength={maxPhoneDigit}
                minLength={minPhoneDigit}
                name="phone.value"
                onKeyPress={onTelKeyPress}
                prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                type="tel"
            />
            <TextField
                label={t('guarantorDetails.label.identityNumber')}
                name="identityNumber.value"
                placeholder={t('customerDetails.placeholder.identityNumber')}
            />
        </BoxedGrid>
    );
};

export const schema = yup.object().shape({
    guarantor: yupExt.customerProperty().shape({
        email: yup.string().required('Required').email(),
        // @ts-ignore
        identityNumber: yup.lazy((values, options) => {
            const { context } = options;
            if (context.countryCode === 'NZ') {
                return yup.string().nullable().notRequired();
            }

            return yup.string().required('Required');
        }),
        name: yup.string().required('Required'),
    }),
});

export default AddGuarantorForm;
