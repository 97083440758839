import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { attachLoading } from '../../../../actions';
import {
    create,
    CreateMutation,
    CreateMutationVariables,
    extract,
    ExtractMutation,
    ExtractMutationVariables,
} from '../../../../api/attachment.graphql';
import OcrButton from '../../../../components/ui/OcrButton';
import { useCountry } from '../../../../hookSelectors';
import { UploadPurpose } from '../../../../schema';
import { getNationalities } from '../../../../selectors';
import OcrModal from './OcrModal';
import { ReactComponent as Icon } from '../../../../assets/images/ocrCamera.svg';

const formatDate = (value: string | undefined | null, dateFormat: string): string | undefined => {
    if (value) {
        const dateOfBirthStrArr = value.split('-');
        const dateOfBirthNumArr = dateOfBirthStrArr.map(item => parseInt(item, 10));
        const [day, month, year] = dateOfBirthNumArr;

        try {
            return format(new Date(year, month - 1, day, 0, 0, 0, 0), dateFormat);
        } catch (error) {
            return undefined;
        }
    }

    return undefined;
};

type Results = ExtractMutation['nric'] & { uploadId?: string | null };

export type Files = { front?: File | null; back?: File | null; card?: File | null };

const uploadOcrDocuments = async (
    client: ApolloClient<NormalizedCacheObject>,
    countryCode: string,
    files: Files
): Promise<Results> => {
    let uploadId: string | null = null;

    const { front, back, card } = files;

    if (front) {
        const response = await client.mutate<CreateMutation, CreateMutationVariables>({
            mutation: create,
            variables: { file: front, uploadId, purpose: UploadPurpose.NRIC_FRONT },
        });

        uploadId = response.data?.attachment?.uploadId || null;
    }

    if (back) {
        const response = await client.mutate<CreateMutation, CreateMutationVariables>({
            mutation: create,
            variables: { file: back, uploadId, purpose: UploadPurpose.NRIC_BACK },
        });

        uploadId = response.data?.attachment?.uploadId || null;
    }

    if (card) {
        const response = await client.mutate<CreateMutation, CreateMutationVariables>({
            mutation: create,
            variables: { file: card, uploadId, purpose: UploadPurpose.NRIC_NAMED_CARD },
        });

        uploadId = response.data?.attachment?.uploadId || null;
    }

    const nric = await client.mutate<ExtractMutation, ExtractMutationVariables>({
        mutation: extract,
        variables: { uploadId, countryCode },
    });

    return { ...nric.data?.nric, uploadId };
};

const OcrManagement = () => {
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const dispatch = useDispatch() as ThunkDispatch<any, any, any>;
    const { code: countryCode } = useCountry();
    const nationalities = useSelector(getNationalities) as { label: string; value: string }[];
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();

    const onButtonClick = useCallback(() => setShowModal(true), [setShowModal]);
    const onModalClose = useCallback(() => setShowModal(false), [setShowModal]);

    const onModalValidation = useCallback(
        async files => {
            // execute the file upload and extraction
            const result = await dispatch<Promise<Results | undefined>>(
                attachLoading(uploadOcrDocuments(client, countryCode, files))
            );

            if (!result) {
                return;
            }

            const { uploadId, name, email, phone, nationalId, birthday, nationality } = result;

            const foundNationality = nationalities.find(
                item => item.label.toLowerCase() === nationality?.toLowerCase()
            );

            // update the form
            dispatch(change('customer', 'uploadId', uploadId));
            dispatch(change('customer', 'customer.name.value', name || undefined));
            dispatch(change('customer', 'customer.firstName.value', name || undefined));
            dispatch(change('customer', 'customer.lastName.value', name ? '.' : undefined));
            dispatch(change('customer', 'customer.email.value', email || undefined));
            dispatch(change('customer', 'customer.phone.value', phone || undefined));
            dispatch(change('customer', 'customer.identityNumber.value', nationalId || undefined));
            dispatch(
                change('customer', 'customer.dateOfBirth.value', formatDate(birthday, t('dateFormats.ISOFormat')))
            );
            dispatch(change('customer', 'customer.details.nationality.value', foundNationality?.value || undefined));

            // close the modal
            setShowModal(false);
        },
        [setShowModal, dispatch, nationalities, countryCode, client]
    );

    return (
        <>
            <OcrButton onClick={onButtonClick}>
                <Icon fill="#ffffff" />
                <span>{t('draftPage.button.ocr')}</span>
            </OcrButton>
            {showModal && <OcrModal onClose={onModalClose} onValidation={onModalValidation} />}
        </>
    );
};

export default OcrManagement;
