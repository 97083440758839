import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import { faAngleDown, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import useFirstFinanceProduct from '../../../../../components/data/useFirstFinanceProduct';
import useLoadPromo from '../../../../../components/data/useLoadPromo';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { CompanyCalculatorProps } from '../../../../../components/shared/calculator-next/CompanyCalculator';
import ConnectedCalculator from '../../../../../components/shared/calculator-next/ConnectedCalculator';
import { CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import ReservationDepositField from '../../../../../components/shared/calculator/ReservationDepositField';
import { CalculatorError } from '../../../../../components/ui/calculator';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import { useCountry, useZone } from '../../../../../hookSelectors';
import { useContentTranslation } from '../../../../../i18n';
import { Channel, PromoCodeUnit } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import { useGetCalculatorFooterText } from '../../../../../utilities/calculator';
import useDealerEstablishment from '../../../../utils/useDealerEstablishment';
import useApplyDisabled from '../../Calculator/useApplyDisabled';
import RemotePromoCodeField from '../RemotePromoCodeField';
import { Buttons, Container, DisabledText, PrimaryButton } from '../ui';
import FinancingForm from './FinancingForm';

export type FinancingProps = Pick<CompanyCalculatorProps, 'meta' | 'initialValues'> & {
    dealerId: string;
    variant: VariantDataFragment;
    handleChange: CompanyCalculatorProps['onChange'];
    initialCalculatorValues: CompanyCalculatorProps['initialValues'];
    context: CalculatorContext<CalculatorValues> | undefined;
};

export type FinanceData = {
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
};

const CalculatorContainer = styled.div``;

const CalculatorToggle = styled.div`
    display: block;
    text-align: right;
    margin-top: 6px;
    margin-bottom: 17px;
`;

const MonthlyPayment = styled.div`
    display: inline-block;
    color: ${props => props.theme.themeHighlightColour};
    cursor: pointer;
    span {
        font-style: italic;
    }
`;

type PriceProps = {
    promo?: number;
    isPromoValid?: Boolean;
};

const Price = styled.div<PriceProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;

    span:first-child {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        ${props =>
            props.theme.display === 'small' &&
            css`
                text-align: left;
            `}
    }

    span:last-child {
        font-weight: 500;
        font-size: 26px;
        margin-left: 17px;
        white-space: nowrap;
        ${props =>
            props.promo &&
            props.isPromoValid &&
            css`
                color: #44444480;
                text-decoration: line-through;
            `}
    }

    span:last-of-type {
        margin-top: 6px;
    }
`;

const PriceContainer = styled.div`
    line-height: 10px;
`;

const Financing = ({
    dealerId,
    handleSubmit,
    variant,
    handleChange: onChange,
    initialCalculatorValues: initialValues,
    meta,
    context,
}: FinancingProps & InjectedFormProps<FinanceData, FinancingProps>) => {
    const { t } = useTranslation();
    const country = useSelector(getCurrentCountry);
    const { formatPath } = useContentTranslation();
    // get the text footer from the zone
    const { id: zoneId } = useZone();
    const fallbackFinanceProduct = useFirstFinanceProduct(Channel.USED, dealerId);

    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.USED);

    const selectedBankEstablishment = fallbackFinanceProduct?.bank.establishment;

    const getCalculatorFooterText = useGetCalculatorFooterText();
    const calculatorFooterText = getCalculatorFooterText(
        get(formatPath('channelSetting.new.priceDisclaimer'), country),
        country?.coe?.amount,
        country?.ppsr?.amount,
        country?.establishment?.amount,
        country?.luxuryTax?.amount,
        selectedDealerEstablishment,
        selectedBankEstablishment
    );

    // get countryId for loading promo
    const { id: countryId, channelSetting } = useCountry();

    const { formatCurrency } = useCompanyFormatting();

    // set state for calculator toggle
    const [open, setOpen] = useState(false);
    const toggle = useCallback(() => setOpen(state => !state), [setOpen]);

    // we need to render promo code outside the calculator
    // so we can create a div with an unique id
    const promoContainerId = useMemo(uuid, []);

    // prepare to set values
    const { values, errors } = context || {};

    const submitStates = useApplyDisabled(values, fallbackFinanceProduct, variant);

    const price = variant.prices.find(item => item.zoneId === zoneId)?.value;

    const promo = useLoadPromo(countryId, dealerId, values?.promoCode);
    const isPromoValid = promo?.deductValue && promo?.remainingQuantity > 0;

    const promoAmount = useMemo(() => {
        if (promo && promo.value) {
            switch (promo.unit) {
                case PromoCodeUnit.PERCENTAGE: {
                    if (!price) {
                        return 0;
                    }

                    return price * (promo.value / 100);
                }
                case PromoCodeUnit.CURRENCY:
                    return promo.value;
                default:
                    return undefined;
            }
        }

        return 0;
    }, [promo, price]);

    const priceElement = (
        <Price isPromoValid={isPromoValid} promo={promoAmount}>
            <span>{t('usedCalculatorPage.label.price')}</span>
            <span>{formatCurrency(price)}</span>
        </Price>
    );

    const promoElement =
        promoAmount && isPromoValid ? (
            <Price>
                <span>{t('usedCalculatorPage.label.finalPrice')}</span>
                <span>{price && formatCurrency(price - promoAmount)}</span>
            </Price>
        ) : null;

    const { allowTradeIn, allowTestDrive } = channelSetting.used;

    return (
        <Container>
            <PriceContainer>
                {priceElement}
                {promoElement}
                <CalculatorToggle>
                    <MonthlyPayment onClick={toggle}>
                        <Trans
                            components={{ s: <span /> }}
                            i18nKey="usedCalculatorPage.label.monthlyPayment"
                            values={{ monthlyPayment: formatCurrency(get('monthlyInstalments[0].amount', values)) }}
                        />
                        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                    </MonthlyPayment>
                </CalculatorToggle>
            </PriceContainer>
            <CalculatorContainer>
                <ConnectedCalculator
                    channel={Channel.USED}
                    dealerId={dealerId}
                    initialValues={initialValues}
                    meta={meta}
                    onChange={onChange}
                >
                    <displayFields.CarModelPriceField
                        fieldKey="carModelAndPrice"
                        isViewable={() => false}
                        size={2}
                        override
                    />
                    <displayFields.CarModelField
                        fieldKey="variant"
                        isViewable={() => false}
                        labelTKey="calculator.label.carModel"
                        override
                    />
                    <displayFields.CarPriceField
                        fieldKey="carPrice"
                        isViewable={() => false}
                        labelTKey="calculator.label.carPrice"
                        override
                    />
                    <displayFields.MonthlyInstalmentField
                        fieldKey="monthlyInstalment"
                        isViewable={context => open && displayFields.isMonthlyInstalmentViewable(context)}
                        labelTKey="calculator.label.monthlyInstalment"
                        override
                    />
                    <displayFields.MultipleMonthlyInstalmentsField
                        fieldKey="monthlyInstalments"
                        isViewable={context => open && displayFields.isMultipleMonthlyInstalmentsViewable(context)}
                        labelTKey="calculator.label.monthlyInstalment"
                        override
                    />
                    <displayFields.TotalPriceField
                        fieldKey="totalPrice"
                        isViewable={context => open && displayFields.isTotalPriceViewable(context)}
                        labelTKey="calculator.label.totalPrice"
                        override
                    />
                    <displayFields.BankField
                        fieldKey="bank"
                        isViewable={context => open && displayFields.isBankViewable(context)}
                        labelTKey="calculator.label.bank"
                        override
                    />
                    <displayFields.DownPaymentField
                        fieldKey="downPayment"
                        isViewable={context => open && displayFields.isDownPaymentViewable(context)}
                        labelTKey="calculator.label.downPayment"
                        override
                    />
                    <displayFields.PaymentTermField
                        fieldKey="tenure"
                        isViewable={context => open && displayFields.isPaymentTermViewable(context)}
                        labelTKey="calculator.label.paymentTerm"
                        override
                    />
                    <displayFields.LoanField
                        fieldKey="loan"
                        isViewable={context => open && displayFields.isLoanViewable(context)}
                        labelTKey="calculator.label.loanAmount"
                        override
                    />
                    <displayFields.MarginOfFinanceField
                        fieldKey="loan.percentage"
                        isViewable={context => open && displayFields.isMarginOfFinanceViewable(context)}
                        labelTKey="calculator.label.marginOfFinance"
                    />
                    <displayFields.HPAmountField
                        fieldKey="loan.amount"
                        isViewable={context => open && displayFields.isHPAmountViewable(context)}
                        labelTKey="calculator.label.hpAmount"
                    />
                    <displayFields.InterestRateField
                        fieldKey="interestRate"
                        isViewable={context => open && displayFields.isInterestRateViewable(context)}
                        labelTKey="calculator.label.interestRate"
                        override
                    />
                    <displayFields.FinanceProductField
                        fieldKey="financeProduct"
                        isViewable={() => open}
                        labelTKey="calculator.label.financeProduct"
                        override
                    />
                    <displayFields.BalloonPaymentField
                        fieldKey="balloon"
                        isViewable={context => open && displayFields.isBalloonPaymentViewable(context)}
                        labelTKey="calculator.label.balloonPayment"
                        override
                    />
                    <displayFields.DepositField
                        fieldKey="deposit"
                        isViewable={context => open && displayFields.isDepositViewable(context)}
                        labelTKey="calculator.label.deposit"
                        override
                    />
                    <displayFields.PaymentModeField
                        fieldKey="paymentMode"
                        isViewable={context => open && displayFields.isPaymentModeViewable(context)}
                        labelTKey="calculator.label.paymentMode"
                        override
                    />
                    <displayFields.ResidualValueField
                        fieldKey="residualValue"
                        isViewable={context => open && displayFields.isResidualValueViewable(context)}
                        labelTKey="calculator.label.residualValueField"
                        override
                    />
                    <displayFields.AssuredResaleValueField
                        fieldKey="residualValue.amount"
                        isViewable={context => open && displayFields.isAssuredResaleValueViewable(context)}
                        labelTKey="calculator.label.assuredResaleValueField"
                        override
                    />
                    <displayFields.EstimatedSurplusField
                        fieldKey="estimatedSurplus"
                        isViewable={context => open && displayFields.isEstimatedSurplusViewable(context)}
                        labelTKey="calculator.label.estimatedSurplus"
                        override
                    />
                    <RemotePromoCodeField
                        containerId={promoContainerId}
                        fieldKey="promoCode"
                        isViewable={displayFields.isPromoCodeViewable}
                        labelTKey="calculator.label.promoCode"
                        size={2}
                    />
                </ConnectedCalculator>
            </CalculatorContainer>
            {calculatorFooterText && open && (
                <DisabledText dangerouslySetInnerHTML={{ __html: calculatorFooterText }} />
            )}
            <div id={promoContainerId} />
            <ReservationDepositField channel={Channel.USED} />
            <FinancingForm allowTestDrive={allowTestDrive} allowTradeIn={allowTradeIn} />
            {submitStates.hidden || (
                <Buttons>
                    <PrimaryButton disabled={submitStates.disabled} onClick={handleSubmit}>
                        <FontAwesomeIcon icon={faAngleRight} /> {t('usedCalculatorPage.button.next')}
                    </PrimaryButton>
                </Buttons>
            )}
            <CalculatorError errors={errors} />
        </Container>
    );
};

export default reduxForm<FinanceData, FinancingProps>({ form: 'financing' })(Financing);
