import styled from 'styled-components';

const Notice = styled.p`
    margin-top: 40px;
    color: #000;
    font-size: 1.3rem;
    font-weight: bold;
    white-space: pre-line;
`;

export default Notice;
