import { find, flow, get, head, isNil, map } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { clearPersistedSessions, logout } from '../../actions/shared';
import { useContentTranslation } from '../../i18n';
import { getCompanyIdentifier, getUser } from '../../selectors';
import { getCompanyRootUrl, getLocationCode } from '../../utilities/urls';
import { getRootUrl } from '../routes/LoginRoute/shared';
import DealerSelect from '../ui/DealerSelect';
import { LoginAccessContainer } from '../ui/header/HeaderContainer';
import { useTenantManagementContext } from './tenantManagementContext';

const DropDownContainer = styled.div`
    display: inline-block;
`;

const WelcomeContainer = styled.div`
    display: inline-block;
    padding-right: 18px;
`;

const LoginAccess = () => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const user = useSelector(getUser);
    const { companyId, countryId, companyCode, countryCode, zoneCode } = useSelector(getCompanyIdentifier);
    const {
        current: { dealerId },
        update,
    } = useTenantManagementContext();
    const [dealers, first, dealer] = useMemo(() => {
        const all = flow(
            get('availableCompanies'),
            find(['id', companyId]),
            get('countries'),
            find(['id', countryId]),
            get('dealers'),
            map(({ id, name }) => ({ value: id, label: ct(name) }))
        )(user);

        return [all, head(all), find(['value', dealerId], all)];
    }, [user, companyId, countryId, dealerId, ct]);

    useEffect(() => {
        if (isNil(dealer) && first) {
            update({ dealerId: first.value });
        }
    }, [dealer, update, first]);

    const history = useHistory();
    const dispatch = useDispatch();
    // there is existing path to redirect
    const location = useLocation();

    const onDealerChange = useCallback(
        ({ value }) => {
            update({ dealerId: value });

            // clean persisted sessions
            clearPersistedSessions();

            if (location?.pathname) {
                // exisitng path to redirect after selection
                history.push(location.pathname);
            } else {
                const locationCode = getLocationCode(countryCode, zoneCode);
                const url = getRootUrl(user, companyCode, countryCode);

                if (url) {
                    history.push(url(companyCode, locationCode));
                } else {
                    history.push(getCompanyRootUrl(companyCode, locationCode));
                }
            }
        },
        [update, countryCode, zoneCode, user, location.pathname, history, companyCode]
    );

    const onClick = useCallback(() => dispatch(logout('manual')), [dispatch]);

    return (
        <LoginAccessContainer>
            <div>
                <WelcomeContainer>{t('loginBanner.welcome', { name: user.name })}</WelcomeContainer>
                {dealer ? (
                    <DropDownContainer>
                        <DealerSelect
                            disabled={dealers.length === 1}
                            onChange={onDealerChange}
                            options={dealers}
                            value={dealer}
                        />
                    </DropDownContainer>
                ) : null}
            </div>
            <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
                {t('loginBanner.button.signOut')}
            </div>
        </LoginAccessContainer>
    );
};

export default LoginAccess;
