import styled from 'styled-components';

const ConsentText = styled.div`
    overflow: auto;

    font-weight: 400;
    line-height: 1.5;

    h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700;
    }

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    ol[type='a'] {
        list-style-type: lower-alpha;
    }

    ol[type='i'] {
        list-style-type: lower-roman;
    }

    strong {
        font-weight: bold;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
        margin: unset;
        padding: unset;
    }
`;

export default ConsentText;
