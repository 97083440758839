import { useSelector } from 'react-redux';
import { RuntimeType } from '../../schema';
import { getRuntimeSettings } from '../../selectors';

const useRuntimeType = <T>(options: { [key in RuntimeType]: T }) => {
    const { type: runtimeType } = useSelector(getRuntimeSettings);

    switch (runtimeType) {
        case RuntimeType.PME:
            return options[RuntimeType.PME];

        case RuntimeType.AFC:
        default:
            return options[RuntimeType.AFC];
    }
};

export default useRuntimeType;
