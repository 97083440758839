import React, { ComponentType, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { setNotification } from '../../../../actions';
import SubmittedPage from '../../../../flows/RemoteFlow/components/SubmittedPage';
import { useCountry } from '../../../../hookSelectors';
import { Channel } from '../../../../schema';
import { getCompanyRootUrl } from '../../../../utilities/urls';
import Footer from '../../../shared/Footer';
import Header from '../../../shared/Header';
import { RemoteContext } from './RemoteData';
import RemoteMessage from './RemoteMessage';

type Notification = { title: string; message: string };

export type RemoteBoostrapProps = {
    context: RemoteContext;
    handlerComponent: ComponentType<{ token: string; submitted: boolean }>;
    shieldComponent: ComponentType<{
        applicationId: string;
        bankName: string;
        handlerComponent: ComponentType<{ token: string; submitted: boolean }>;
        submitted: boolean;
    }>;
};

export type LocationState = {
    token?: string;
    signingRedirectionMessage?: Notification;
    isApplicationSubmitted?: boolean;
};

const RemoteBoostrap = ({ context, handlerComponent: Handler, shieldComponent: Shield }: RemoteBoostrapProps) => {
    const { t } = useTranslation();
    const {
        submitted,
        expired,
        bankName,
        remoteFlowAcknowledgmentInfo,
        dealerId,
        versionId,
        channel,
        assigneeName,
        assigneePhone,
    } = context;

    const remoteSubmitted: Notification = t('notification.remoteSubmitted', { returnObjects: true });
    const remoteExpired: Notification = t('notification.remoteExpired', {
        returnObjects: true,
        assigneeName,
        assigneePhone,
    });

    // we may have a token
    const { token = null, signingRedirectionMessage = null, isApplicationSubmitted = false } =
        useLocation<LocationState>().state ?? {};

    const { channelSetting } = useCountry();
    const allowPublicAccess = useMemo(() => {
        switch (channel) {
            case Channel.USED:
                return channelSetting.used.allowPublicAccess;

            case Channel.NEW:
                return channelSetting.new.allowPublicAccess;

            default:
                return false;
        }
    }, [channel, channelSetting]);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (expired === null || submitted === null) {
            return;
        }

        if (allowPublicAccess) {
            if (submitted) {
                dispatch(setNotification(remoteSubmitted.title, remoteSubmitted.message));
            }

            if (!submitted && expired) {
                dispatch(setNotification(remoteExpired.title, remoteExpired.message));
            }

            if (submitted || expired) {
                // @ts-ignore
                history.pushWithCompanyAndState(getCompanyRootUrl);
            }
        }
    }, [allowPublicAccess, expired, history, submitted, dispatch, t, remoteSubmitted, remoteExpired]);

    if (isApplicationSubmitted) {
        return (
            <>
                <Header />
                <SubmittedPage
                    dealerId={dealerId ?? ''}
                    remoteFlowAcknowledgmentInfo={remoteFlowAcknowledgmentInfo ?? ''}
                />
                <Footer />
            </>
        );
    }

    // when there is message from signing redirect
    // display remote message
    if (signingRedirectionMessage) {
        return (
            <>
                <Header />
                <RemoteMessage {...signingRedirectionMessage} />
                <Footer />
            </>
        );
    }

    // when this email link already expired,
    // then if user tap the email link again
    // will display remote expired message
    if (expired) {
        return (
            <>
                <Header />
                <RemoteMessage {...remoteExpired} />
                <Footer />
            </>
        );
    }

    // if redirect from 3rd payment website,
    // we do not need NRIC validation
    if (token) {
        return (
            <>
                <Header />
                <Handler submitted={submitted} token={token} />
                <Footer />
            </>
        );
    }

    if (!bankName) {
        throw new Error('Data missing from context');
    }

    // if the link is not expired
    // and application has not submitted
    // and it is not redirection from signing redirect
    // adn it is not redirection from payment
    // then go through normal flow
    return (
        <>
            <Header />
            <Shield applicationId={versionId} bankName={bankName} handlerComponent={Handler} submitted={submitted} />
            <Footer />
        </>
    );
};

export default RemoteBoostrap;
