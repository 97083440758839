import {
    OutlineError,
    OutlineWrapperV2 as OutlineWrapper,
    OutlineSelectV2 as OutlineSelect,
    // @ts-ignore
} from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import { get, getOr, isEmpty, isNil, sumBy } from 'lodash/fp';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, ReduxFormContext, WrappedFieldArrayProps } from 'redux-form';
import * as yup from 'yup';
import { Action, FieldArrayCustom } from '../../../../../components/shared/form-v2/FileFieldArray';
import NumberField from '../../../../../components/shared/form-v2/NumberField';
import SelectField, { SelectInput } from '../../../../../components/shared/form-v2/SelectField';
import TextField from '../../../../../components/shared/form-v2/TextField';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import useCustomerSource from '../../../../../components/utilities/useCustomerSource';
import { CustomerDetailsSource, CustomerQuotationOptionPayload, RoundingMode } from '../../../../../schema';
import useOptions from '../../../../../utilities/constants/useOptions';
import { yupExt } from '../../../../../utilities/forms';
import { formatCurrency, roundNumber } from '../../../../../utilities/numberHelper';
import { Application } from '../../../types';
import { Title } from '../ui';

type QuotationDetailsProps = {
    disabled?: boolean;
    application: Application;
    change: InjectedFormProps['change'];
};

type OptionFieldArrayProps = {
    disabled?: boolean;
    name: string;
    dealerOptionDetails?: { description?: string | null; amount: number }[];
    dealerVatOptions: { label: string; value: boolean }[];
    change: InjectedFormProps['change'];
};

type OptionFieldsProps = WrappedFieldArrayProps & OptionFieldArrayProps;

type VatAndPriceFieldsProps = {
    name: string;
    currentValue: CustomerQuotationOptionPayload;
    change: InjectedFormProps['change'];
    disabled: boolean;
    dealerVatOptions: { label: string; value: boolean }[];
    index: number;
};

const VAT_MULTIPLIER = 1.05;

// Net value, is the value without VAT, specific to two decimals
const getNetAmountFromGross = (grossAmount: number) =>
    roundNumber(2, RoundingMode.ROUNDING)(grossAmount / VAT_MULTIPLIER);

// Gross value, is the value with VAT, based on company formats (which is set in admin>country UI)
const getGrossAmountFromNet = (netAmount: number, rounding: number) =>
    roundNumber(rounding, RoundingMode.ROUNDING)(netAmount * VAT_MULTIPLIER);

const VatAndPriceFields = ({
    name,
    change,
    currentValue,
    disabled,
    index,
    dealerVatOptions,
}: VatAndPriceFieldsProps) => {
    const { t } = useTranslation();

    // Because, when it's inside array
    // It's become sectioned field
    // Thus, we must access the section prefix
    // so `change` function can access the correct field
    const { sectionPrefix } = useContext(ReduxFormContext);

    const companyFormats = useCompanyFormatting();

    const { price, isVatIncluded } = useMemo(
        () => ({
            isVatIncluded: currentValue?.isVatIncluded ?? false,
            price: currentValue?.price ?? 0,
        }),
        [currentValue]
    );

    // Using component without field
    // As we need to detect the change immediately
    // So that validation won't be flashed
    const handleVatChange = useCallback(
        (newValue: boolean) => {
            if (newValue !== isVatIncluded) {
                change(`${sectionPrefix}.${name}.isVatIncluded`, newValue);
                change(
                    `${sectionPrefix}.${name}.price`,
                    newValue
                        ? getGrossAmountFromNet(price, companyFormats?.currencyPrecision ?? 0)
                        : getNetAmountFromGross(price)
                );
            }
        },
        [change, companyFormats.currencyPrecision, isVatIncluded, name, price, sectionPrefix]
    );

    return (
        <>
            <SelectInput
                disabled={disabled}
                errorComponent={OutlineError}
                input={{
                    name: `${name}.isVatIncluded`,
                    value: isVatIncluded,
                    onChange: handleVatChange,
                }}
                label={t('customerDetails.label.optionPrice', { no: index + 1 })}
                meta={{
                    touched: true,
                    active: true,
                }}
                options={dealerVatOptions}
                selectComponent={OutlineSelect}
                wrapperComponent={OutlineWrapper}
            />
            <NumberField
                disabled={disabled}
                label=""
                name={`${name}.price`}
                overrideFormats={{
                    currencyPrecision: 2,
                }}
                type="currency"
            />
        </>
    );
};
const OptionFields = ({
    disabled = false,
    fields,
    dealerOptionDetails,
    dealerVatOptions,
    change,
}: OptionFieldsProps) => {
    const { t } = useTranslation();

    const data = fields.getAll() || [];

    // Handle initialization dealer option details from calculator in customer
    useEffect(() => {
        if (data.length === 0 && !disabled && dealerOptionDetails?.length) {
            // Map it
            dealerOptionDetails.forEach(dealerOptionDetail => {
                fields.push({
                    description: { value: dealerOptionDetail.description ?? '', source: CustomerDetailsSource.MANUAL },
                    price: getNetAmountFromGross(dealerOptionDetail.amount),
                    isVatIncluded: false,
                });
            });
        }
    }, [data, dealerOptionDetails, disabled, fields]);

    const addAnotherOption = useCallback(() => {
        const hasEmptyItem = fields.getAll()?.some(item => isEmpty(item));

        if (!hasEmptyItem) {
            // we can add another one
            fields.push({
                description: { value: '', source: CustomerDetailsSource.MANUAL },
                price: 0,
                isVatIncluded: false,
            });
        }
    }, [fields]);

    return (
        <div style={{ paddingBottom: !disabled ? 21 : 0 }}>
            {fields.map((name, index) => (
                <div key={name} style={{ paddingBottom: fields.length > 1 ? 21 : 0 }}>
                    <TextField
                        disabled={disabled}
                        label={t('customerDetails.label.optionDescription', { no: index + 1 })}
                        name={`${name}.description.value`}
                    />
                    <VatAndPriceFields
                        change={change}
                        currentValue={fields.get(index)}
                        dealerVatOptions={dealerVatOptions}
                        disabled={disabled}
                        index={index}
                        name={name}
                    />
                    {fields.length > 1 && (
                        <Action onClick={() => fields.remove(index)}>{t('customerDetails.label.removeOption')}</Action>
                    )}
                </div>
            ))}

            {!disabled && <Action onClick={addAnotherOption}>{t('customerDetails.label.addAnotherOption')}</Action>}
        </div>
    );
};
const OptionFieldArray = (props: OptionFieldArrayProps) => <FieldArrayCustom {...props} component={OptionFields} />;

const watchedFields = [
    'details.quotation.fullName',
    'details.quotation.commissionNo',
    'details.quotation.engineNo',
    'details.quotation.chassisNo',
    'details.quotation.exteriorColor',
    'details.quotation.downpaymentTo',
    'details.quotation.companyName',
    'details.quotation.financeInsuranceManager',
];

const wrapWithAsterisk = (text: string) => `${text}*`;

// PS: This is a preset name for Finance Manager, only for ENBD with quotation
const presetNameFinanceManager = 'Bernard Tellis';

const QuotationDetails = ({ disabled = false, application, change }: QuotationDetailsProps) => {
    const { t } = useTranslation();
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    const values = getValues();

    const { downpaymentTo: downpaymentToOptions, dealerVatOptions } = useOptions();

    const initialFirstName = get(`${sectionPrefix}.firstName.value`, values);
    const initialLastName = get(`${sectionPrefix}.lastName.value`, values);
    const quotationFullName = get(`${sectionPrefix}.details.quotation.fullName.value`, values);
    const financeManager = get(`${sectionPrefix}.details.quotation.financeInsuranceManager.value`, values);

    useEffect(() => {
        if (initialFirstName && initialLastName && isNil(quotationFullName)) {
            change(`${sectionPrefix}.details.quotation.fullName.value`, `${initialFirstName} ${initialLastName}`);
        }
    }, [change, initialFirstName, initialLastName, quotationFullName, sectionPrefix]);

    useEffect(() => {
        if (isNil(financeManager)) {
            change(`${sectionPrefix}.details.quotation.financeInsuranceManager.value`, presetNameFinanceManager);
        }
    }, [change, financeManager, sectionPrefix]);

    return (
        <>
            <Title>{t('kycPage.quotationDetailsTitle')}</Title>
            <TextField
                disabled={disabled || fieldState.details.quotation.fullName}
                label={wrapWithAsterisk(t('customerDetails.label.fullName'))}
                name="details.quotation.fullName.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.commissionNo}
                label={wrapWithAsterisk(t('customerDetails.label.commissionNo'))}
                name="details.quotation.commissionNo.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.engineNo}
                label={wrapWithAsterisk(t('customerDetails.label.engineNo'))}
                name="details.quotation.engineNo.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.chassisNo}
                label={wrapWithAsterisk(t('customerDetails.label.chassisNo'))}
                name="details.quotation.chassisNo.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.exteriorColor}
                label={wrapWithAsterisk(t('customerDetails.label.exteriorColor'))}
                name="details.quotation.exteriorColor.value"
            />
            <OptionFieldArray
                change={change}
                dealerOptionDetails={application.calculator?.dealerOptionDetails ?? []}
                dealerVatOptions={dealerVatOptions}
                disabled={disabled || (application.calculator?.dealerOptions?.amount ?? 0) === 0}
                name="details.quotation.options"
            />
            <SelectField.Outline
                disabled={disabled || fieldState.details.quotation.downpaymentTo}
                label={t('customerDetails.label.downpaymentTo')}
                name="details.quotation.downpaymentTo.value"
                options={downpaymentToOptions}
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.companyName}
                label={wrapWithAsterisk(t('customerDetails.label.companyName'))}
                name="details.quotation.companyName.value"
            />
            <TextField
                disabled={disabled || fieldState.details.quotation.financeInsuranceManager}
                label={wrapWithAsterisk(t('customerDetails.label.financeInsuranceManager'))}
                name="details.quotation.financeInsuranceManager.value"
            />
        </>
    );
};

export default QuotationDetails;

export const quotationSchema = (t: TFunction) => ({
    quotation: yupExt.customerProperty().shape({
        chassisNo: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        commissionNo: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        companyName: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        downpaymentTo: yupExt.customerProperty().shape({
            value: yup.string().nullable().notRequired(),
        }),

        engineNo: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        exteriorColor: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        financeInsuranceManager: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        fullName: yupExt.customerProperty().shape({
            value: yup.string().required(t('common.error.required')),
        }),

        // @ts-ignore
        options: yup.lazy((values: any[], { context }: any) => {
            const totalDealerOptions = getOr(0, 'application.calculator.dealerOptions.amount', context);

            if (totalDealerOptions > 0) {
                const companyFormats = get('companyFormats', context);
                const companyCurrencyPrecision = companyFormats?.currencyPrecision ?? 0;

                const totalInputNetPrice = sumBy('price', values?.filter(item => !item?.isVatIncluded) ?? []);
                const totalInputGrossPrice = sumBy('price', values?.filter(item => item?.isVatIncluded) ?? []);

                const grossFromNetInputs = getGrossAmountFromNet(totalInputNetPrice, companyCurrencyPrecision);

                const actualGrossInputPrice = totalInputGrossPrice + grossFromNetInputs;

                return yup.array().of(
                    yup.object().shape({
                        description: yupExt.customerProperty().shape({
                            value: yup.string().nullable(),
                        }),

                        price: yup.number().test({
                            name: 'is-option-price-valid',
                            test: (value, context) => actualGrossInputPrice === totalDealerOptions,
                            message: t('customerDetails.error.optionPriceGross', {
                                value: formatCurrency(companyCurrencyPrecision)(
                                    totalDealerOptions,
                                    companyFormats?.currencySymbol
                                ),
                            }),
                        }),
                    })
                );
            }

            return yup.object().shape({}).nullable();
        }),
    }),
});
