import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Modal } from '@appvantageasia/afc-ui';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculatorInsuranceValues, CalculatorValues } from '../types';

type EditionModalProps = {
    labelTKey: string;
    valueKey: string;
    financeContext: CalculatorContext<CalculatorValues>;
    insuranceContext: CalculatorContext<CalculatorInsuranceValues> | undefined;
    close: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    value: any;
};

const EditionModal = ({
    labelTKey,
    valueKey,
    financeContext,
    insuranceContext,
    close,
    children,
    disabled,
    value,
}: EditionModalProps) => {
    const { t } = useTranslation();
    // callback to apply changes
    const onValidation = useCallback(
        event => {
            event.preventDefault();

            if (disabled) {
                return false;
            }

            if (insuranceContext) {
                insuranceContext.change(valueKey as keyof CalculatorInsuranceValues, value);
            }

            if (financeContext) {
                financeContext.change(valueKey as keyof CalculatorValues, value);
            }

            close();

            return false;
        },
        [disabled, close, insuranceContext, financeContext, valueKey, value]
    );

    return (
        <Modal
            disabledConfirm={disabled}
            onClose={close}
            onConfirm={onValidation}
            title={t(labelTKey)}
            showClose
            showConfirm
            showTitle
        >
            <form onSubmit={onValidation}>
                {children}
                <input style={{ display: 'none' }} type="submit" />
            </form>
        </Modal>
    );
};

export default EditionModal;
