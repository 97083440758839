import { UpdateApplicationMutationVariables } from '../../api/application.graphql';
import { CalculatorValues } from '../../components/shared/calculator-next/types';
import { PaymentMode } from '../../schema';

type CalculatorPayload = UpdateApplicationMutationVariables['data']['calculator'];

const getCalculatorPayload = (calculator: Partial<CalculatorValues>): CalculatorPayload => ({
    balloon: calculator.balloon,
    paymentMode: (calculator.paymentMode ?? PaymentMode.ADVANCE) as PaymentMode,
    adjustments: [],
    downPayment: calculator.downPayment,
    loan: calculator.loan,
    price: calculator.carPrice,
    term: calculator.tenure,
    interestRate: calculator.interestRate,
    deposit: calculator.deposit,
    averageMileage: calculator.mileage,
    coe: calculator.coe ? { amount: calculator.coe } : undefined,
    ppsr: calculator.ppsr ? { amount: calculator.ppsr } : undefined,
    establishment: calculator.establishment ? { amount: calculator.establishment } : undefined,
    luxuryTax: calculator.luxuryTax ? { amount: calculator.luxuryTax } : undefined,
    monthlyInstalments: calculator.monthlyInstalments,
    tradeIn: calculator.tradeIn,
    residualValue: calculator.residualValue,
    cashPayment: calculator.cashPayment,
    totalPrice: calculator.totalPrice,
    tradeInEquity: calculator.tradeInEquity,
    liability: calculator.liability,
    dealerOptions: calculator.dealerOptions ? { amount: calculator.dealerOptions } : undefined,
    dealerOptionDetails: calculator.dealerOptionDetails,
    estimatedSurplus: calculator.estimatedSurplus,
    totalInterestPayable: calculator.totalInterestPayable,
    licensePlateFee: calculator.licensePlateFee,
    commission: calculator.commission,
    fixedInterestRate: calculator.fixedInterestRate,
    licenseAndFuelTax: calculator.licenseAndFuelTax,
    displacement: calculator.displacement,
    insuranceFee: calculator.insuranceFee,
    taxLoss: calculator.taxLoss,
    expectedTradeInAmount: calculator.expectedTradeInAmount,
    expectedCashPayment: calculator.expectedCashPayment,
    totalAmountPayable: calculator.totalAmountPayable,
    nzFee: calculator.nzFee,
    dealerEstablishment: calculator.dealerEstablishment,
});

export default getCalculatorPayload;
