import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container } from '../../../components/ui/calculator';
import { useContentTranslation } from '../../../i18n';
import { Bank } from '../../DraftFlow';
import DealerInformation from '../../EventFlow/components/shared/DealerInformation';

export type SubmittedPageProps = {
    remoteFlowAcknowledgmentInfo?: Bank['remoteFlowAcknowledgmentInfo'];
    dealerId: string;
};

const Content = styled.div`
    color: #000;
    line-height: 1.72rem;
    margin-top: 30px;
`;

const Title = styled.div`
    margin-top: 80px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 2.5em;
`;

const SubmittedPage = ({ dealerId, remoteFlowAcknowledgmentInfo }: SubmittedPageProps) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();
    const title = t('applicationSubmitPage.label.title');

    return (
        <Container>
            <Title>{title}</Title>
            <Content dangerouslySetInnerHTML={{ __html: ct(remoteFlowAcknowledgmentInfo) || '' }} />
            <DealerInformation dealerId={dealerId} />
        </Container>
    );
};

export default SubmittedPage;
