import React, { useMemo } from 'react';
import { Channel } from '../../../schema';
import ConnectedCalculator, { ConnectedCalculatorProps } from '../../shared/calculator-next/ConnectedCalculator';

export type UsedCalculatorProps = Omit<ConnectedCalculatorProps, 'channel' | 'variants'>;

const UsedCalculator = (props: UsedCalculatorProps) => {
    const { meta: initialMeta } = props;

    const meta = useMemo(
        () => ({
            ...initialMeta,
            ...(initialMeta?.snapshot?.variant && { variants: [initialMeta?.snapshot?.variant] }),
        }),
        [initialMeta]
    );

    if (!initialMeta.snapshot) {
        throw new Error('snapshot is mandatory for this used calculator');
    }

    return <ConnectedCalculator {...props} channel={Channel.USED} meta={meta} />;
};

export default UsedCalculator;
