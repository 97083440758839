import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import IconButton from '../ui/IconButton';
import SearchInput from '../ui/header/SearchInput';

const SearchHeader = ({ onChange, initialValue = null }) => {
    const inputRef = useRef(null);
    const previousValueRef = useRef(null);
    const [value, setValue] = useState(initialValue);

    const { clear, onInputChange, onSubmit, toggle } = useMemo(
        () => ({
            onInputChange: () => setValue(inputRef.current.value),
            onSubmit: event => {
                event.preventDefault();
                onChange(inputRef.current.value);

                return false;
            },
            clear: () => setValue(''),
            toggle: () => setValue(currentValue => (currentValue === null ? '' : null)),
        }),
        [setValue, onChange, inputRef]
    );

    useEffect(() => {
        if (previousValueRef.current && (value === '' || value === null)) {
            // reset also to none here
            onChange('');
        }

        previousValueRef.current = value;
    }, [value, onChange, previousValueRef]);

    if (value === null) {
        // we only show the button for now
        return <IconButton icon={faSearch} onClick={toggle} />;
    }

    return (
        <SearchInput
            clear={clear}
            inputRef={inputRef}
            onInputChange={onInputChange}
            onSubmit={onSubmit}
            toggle={toggle}
            value={value}
        />
    );
};

SearchHeader.propTypes = {
    initialValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default SearchHeader;
