import styled from 'styled-components';

export const Container = styled.div`
    text-align: center;
    margin-top: 80px;

    img {
        margin-bottom: 50px;
    }
`;

export const Title = styled.h1`
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 1;
`;

export const Content = styled.span`
    display: block;
    font-size: 1.21rem;
    margin-bottom: 10px;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`;
