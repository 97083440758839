import { useApolloClient } from '@apollo/client';
import { Footer } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { addApplicationComment } from '../../../api/application.graphql';
import { ApplicationEventType } from '../../../schema';
import TextareaField from '../../shared/form/TextareaField';
import { CloseButton, ActionContainer } from '../../ui/form/Comment';
import Modal from '../../ui/form/Modal';

const AddCommentModal = ({ onClose, applicationId }) => {
    const { t } = useTranslation();

    const [comment, setComment] = useState('');

    const client = useApolloClient();

    const onChange = useCallback(event => setComment(event.target.value), [setComment]);

    const addCommentHandler = useCallback(async () => {
        if (!comment) {
            return;
        }

        const variables = {
            data: {
                type: ApplicationEventType.COMMENT,
                detail: { comment },
                applicationId,
            },
        };

        await client.mutate({
            mutation: addApplicationComment,
            variables,
        });

        onClose();
    }, [applicationId, client, comment, onClose]);

    return (
        <Modal
            containerClassName="modal-container"
            onClose={onClose}
            title={t('commentModal.title')}
            isOpen
            showClose
            showTitle
        >
            <div>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <TextareaField.Raw
                    maxLength={255}
                    onChange={onChange}
                    rows={4}
                    style={{ width: '100%' }}
                    value={comment}
                />
                <ActionContainer>
                    <Footer.FootBarButton onClick={onClose}>{t('commentModal.button.cancel')}</Footer.FootBarButton>
                    <Footer.FootBarButton onClick={addCommentHandler}>
                        {t('commentModal.button.save')}
                    </Footer.FootBarButton>
                </ActionContainer>
            </div>
        </Modal>
    );
};

AddCommentModal.propTypes = {
    applicationId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AddCommentModal;
