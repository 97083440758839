import { defaultComputingFields, CalculatorHelpers, ComputeFunction } from '@appvantageasia/afc-calculator-ui-next';
import { flow, set } from 'lodash/fp';
import { PromoCodeUnit } from '../../../../schema';
import { PromoDataFragment } from '../../../data/useLoadPromo.graphql';
import { Formats } from '../../../utilities/useCompanyFormatting';
import { CalculatorValues } from '../types';

const { totalPrice } = defaultComputingFields;

interface CalculatorFieldMeta {
    promo: PromoDataFragment;
    roundNumber?: Formats['roundNumber'];
    allowOptions?: boolean;
    isDealerOptionsEnabled?: boolean;
    isCoeEnabled?: boolean;
    isPpsrAndEstablishmentEnabled?: boolean;
    isLuxuryTaxEnabled: boolean;
    selectedDealerEstablishment: number;
}

const getPromoValue = (helpers: CalculatorHelpers<CalculatorValues>, { roundNumber, promo }: CalculatorFieldMeta) => {
    let promoValue = 0;

    const { carPrice = 0 } = helpers.values;

    // For promo code with percentage, we will calculate percentage based on starting car price
    if (promo && promo.value && promo.deductValue && promo.remainingQuantity > 0) {
        // check promo price exceed car price
        const isExceededInitialPrice = promo.value > carPrice;

        if (promo.unit === PromoCodeUnit.CURRENCY) {
            promoValue = isExceededInitialPrice ? carPrice : promo.value;
        } else if (promo.unit === PromoCodeUnit.PERCENTAGE) {
            promoValue = (carPrice * promo.value) / 100;
        }
    }

    if (roundNumber) {
        return roundNumber(promoValue);
    }

    return promoValue;
};

export const computeTotalPrice: ComputeFunction<CalculatorValues, CalculatorFieldMeta> = (
    helpers,
    meta,
    previousValues
) => {
    return (totalPrice.computeValue(helpers, meta, previousValues) || 0) - getPromoValue(helpers, meta);
};

const totalPriceField = flow([
    set('computeValue', computeTotalPrice),
    set('onUpdates', {
        carPrice: computeTotalPrice,
        coe: computeTotalPrice,
        ppsr: computeTotalPrice,
        establishment: computeTotalPrice,
        luxuryTax: computeTotalPrice,
        promoCode: computeTotalPrice,
        dealerOptions: computeTotalPrice,
        carOptions: computeTotalPrice,
    }),
])(totalPrice);

export default totalPriceField;
