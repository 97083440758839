import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { Channel, FinanceProductType, FinanceProductSubType, BalloonMode } from '../../schema';
import useFinanceProducts, { FinanceProduct } from './useFinanceProducts';

const hasTableBasedOnVehicle: (
    financeProduct: FinanceProduct,
    types: { type: FinanceProductType; subType: FinanceProductSubType }[]
) => boolean = (financeProduct, types) => {
    const { type: financeProductType, subType: financeProductSubType, balloonSetting } = financeProduct;
    const hasBalloonTable = !isNil(balloonSetting) && balloonSetting.mode === BalloonMode.TABLE;
    const matchedSkippedType = !!types.find(
        ({ type, subType }) => type === financeProductType && subType === financeProductSubType
    );

    return hasBalloonTable || matchedSkippedType;
};

const skippedFinanceProducts = [
    { type: FinanceProductType.FINANCE, subType: FinanceProductSubType.LEASEPURCHASE },
    {
        type: FinanceProductType.LEASING,
        subType: FinanceProductSubType.LEASE,
    },
];

const useFirstFinanceProduct = (channel: Channel, dealerId: string, eventId?: string): FinanceProduct | null => {
    const { financeProducts, banks } = useFinanceProducts('cache-first', channel, dealerId, eventId);

    return useMemo(() => {
        const defaultFinanceProductsByBank = banks.map(bank => {
            const financeProductsByBank = financeProducts.filter(item => item.bankId === bank.id);

            return financeProductsByBank.find(product => !hasTableBasedOnVehicle(product, skippedFinanceProducts));
        });

        return defaultFinanceProductsByBank.find(product => product) || null;
    }, [banks, financeProducts]);
};

export default useFirstFinanceProduct;
