const breakpoints = {
    xs: '320px',
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    '2xl': '1670px',
};

export default breakpoints;
