import React, { useMemo } from 'react';
import useOptions from '../../../utilities/constants/useOptions';
import SelectField from './SelectField';

export type MartialStatusProps = {
    label?: string;
    name: string;
    purpose?: string;
};

const MartialStatus = ({ label, purpose, ...props }: MartialStatusProps) => {
    const { maritalStatuses } = useOptions();

    const filteredMaritalStatuses = useMemo(() => {
        if (purpose) {
            return maritalStatuses?.filter(maritalStatus => maritalStatus.purpose?.includes(purpose));
        }

        return maritalStatuses;
    }, [maritalStatuses, purpose]);

    return <SelectField label={label} options={filteredMaritalStatuses} {...props} clearable={false} />;
};

export default MartialStatus;
