// @ts-ignore
import { RawTextareaWrapper } from '@appvantageasia/afc-ui';
import { faTrashAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';
import IconButton from '../../../../components/ui/IconButton';
import VsoButton from '../../../../components/ui/VsoButton';
import { FileInput, NewFile } from '../../../utils/uploads';

const VsoContainer = styled(RawTextareaWrapper)`
    margin-bottom: 15px;
`;

const FileContainer = styled.div`
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const FileName = styled.div`
    span {
        padding-left: 8px;
    }
`;

const Action = styled.div`
    padding: 0 5px;
`;

type VsoUploadingBaseProps = {
    name: string;
    purpose: string;
    max: number;
};

type VsoUploadingProps = WrappedFieldProps & VsoUploadingBaseProps;

const VsoUploading = (props: VsoUploadingProps) => {
    const { name, purpose, input, max } = props;

    const { t } = useTranslation();
    const files = useMemo(() => ((input?.value ?? []) as unknown) as FileInput[], [input]);

    const ref = useRef<HTMLInputElement | null>(null);

    const onButtonClick = useCallback(() => {
        if (files?.length >= max) {
            return;
        }

        ref.current?.click();
    }, [files, max]);

    const onChange = useCallback(
        (event: any) => {
            const eventTarget = event.target;

            if (eventTarget && eventTarget.files[0]) {
                const file = eventTarget.files[0];

                file.purpose = purpose;
                input.onChange([...(files as FileInput[]), file]);

                if (ref.current) {
                    ref.current.value = '';
                }
            }
        },
        [files, input, purpose]
    );

    const remove = useCallback(
        (index: number) => {
            const list = [...(files as FileInput[])];
            list.splice(index, 1);
            input?.onChange(list);
        },
        [files, input]
    );

    return (
        <VsoContainer label={t('draftPage.label.vso')}>
            <input ref={ref} id="file" name={name} onChange={onChange} style={{ display: 'none' }} type="file" />
            <VsoButton onClick={onButtonClick}>{t('draftPage.button.vso')}</VsoButton>

            {files &&
                files.map((file, index) => (
                    <FileContainer key={index.toString()}>
                        <FileName>
                            <Icon icon={faPaperclip} />
                            <span>{(file as NewFile).name}</span>
                        </FileName>
                        <Action>
                            <IconButton icon={faTrashAlt} onClick={() => remove(index)} />
                        </Action>
                    </FileContainer>
                ))}
        </VsoContainer>
    );
};

const VsoUploadingField = (props: VsoUploadingBaseProps) => <Field component={VsoUploading} {...props} />;

export default VsoUploadingField;
