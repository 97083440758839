import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getCompanyIdentifier } from '../../../selectors';
import { getCalculatorFromApplication } from '../../../utilities/application';
import { getLeadsUrl } from '../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import HelmetTitle from '../../shared/HelmetTitle';
import { ApplicationData, LocationParams } from '../ApplicationRoute';
import { calculatorDisplay, getConditionText } from '../ApplicationRoute/ApplicationDetails';
import Details from './Details';
import { LeadFormValues } from './LeadDetails';
import { getLead } from './data.graphql';

const getInitialValues = (application: ApplicationData) =>
    calculatorDisplay<LeadFormValues>(
        {
            variant: application.variant,
            bank: application.financeProduct?.bank,
            financeProduct: application.financeProduct,
            assigneeId: application.assigneeId,
            calculator: getCalculatorFromApplication(application),
            customer: application.customer,
            remark: application.remark,
            promoCode: application.promoCode,
            options: application.options,
            tradeIn: application?.tradeIn,
            hasTradeIn: !!application?.hasTradeIn,
            hasTestDrive: !!application?.hasTestDrive,
            assetCondition: application?.assetCondition || application.variant.assetCondition,
            finderVehicleCondition: getConditionText(application.finderVehicle),
            finderVehicle: application.finderVehicle,
        },
        application
    );

const LeadRoute = () => {
    const { language } = useContentTranslation();
    const history = useHistory();
    const { id } = useParams<LocationParams>();
    const variables = { id, locale: language };
    const { data, loading } = useQuery(getLead, { variables, fetchPolicy: 'no-cache' });
    const lead = data?.lead;

    const selectedDealerId = useMustExistDealerIdFromTenantContext();

    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);
    const url = getLeadsUrl(companyCode, locationCode);

    const initialValues = useMemo(() => lead && getInitialValues(lead), [lead]);

    useEffect(() => {
        if (lead?.dealerId) {
            if (!isEqual(selectedDealerId, lead.dealerId)) {
                history.push(url);
            }
        }
    }, [lead, selectedDealerId, history, url]);

    if (!loading && !lead) {
        return <Redirect to={url} />;
    }

    if (loading && !lead) {
        // wait for the loading to be over
        return null;
    }

    return (
        <>
            <HelmetTitle title="Lead Details" />
            <Details application={lead} initialValues={initialValues} />
        </>
    );
};

export default LeadRoute;
