import React from 'react';
import { animated, useSpring } from 'react-spring';

export const spring = {
    from: { opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)' },
    to: { opacity: 1, transform: 'scale3d(1, 1, 1)' },
};

type ZoomInProps = {
    children: React.ReactNode;
};

const ZoomIn = ({ children }: ZoomInProps) => {
    const animation = useSpring(spring);

    return <animated.div style={animation}>{children}</animated.div>;
};

export default ZoomIn;
