import { get } from 'lodash/fp';

type RequiredFlowState = {
    dealerId?: string | null;
    application?: { dealerId?: string | null } | null;
};

/**
try to get `dealerId` from application first, else read from `dealerId` on `state`
the later is usually given when bootstrap the flow
ideally this should be an accessor on the state, however state is designed as a type
*/
const getDealerIdFromFlowState = (state: RequiredFlowState) => {
    return get('application.dealerId', state) ?? get('dealerId', state);
};

export default getDealerIdFromFlowState;
