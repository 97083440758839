import qs from 'qs';
import { useEffect } from 'react';
import { ApplicationFlowSource } from '../../../schema';

const localKey = 'aydenCallback';

type Response = {
    applicationId: string;
    access: string;
    resultCode: string;
    entry: string;
};

export type AydenCallbackSession = {
    // application ID
    applicationId: string;
    // source channel
    source: ApplicationFlowSource;
    // MyInfo response
    response?: Response;
    // original URL
    origin: string;
};

export const startAydenSession = (source: AydenCallbackSession['source'], applicationId: string) => {
    const data: AydenCallbackSession = {
        applicationId,
        source,
        origin: window.location.href,
    };

    // persist something in our local storage
    localStorage.setItem(localKey, JSON.stringify(data));
};

export const getAydenSession = (applicationId: string, source: AydenCallbackSession['source']) => {
    const rawData = localStorage.getItem(localKey);

    if (!rawData) {
        return null;
    }

    const data = JSON.parse(rawData) as AydenCallbackSession;

    if (data.applicationId === applicationId && data.source === source && data.response) {
        return data;
    }

    return null;
};

export const cleanAydenSession = () => localStorage.removeItem(localKey);

const AydenCallback = () => {
    const localState = localStorage.getItem(localKey);

    useEffect(() => {
        if (!localState) {
            // do nothing
            window.location.replace(window.location.origin);

            return;
        }

        const session = JSON.parse(localState) as AydenCallbackSession;
        const response = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as Response;

        if (response.applicationId !== session.applicationId) {
            // stop here
            window.location.replace(window.location.origin);

            return;
        }

        const nextData: AydenCallbackSession = { ...session, response };

        // update the state
        localStorage.setItem(localKey, JSON.stringify(nextData));

        const url = new URL(session.origin);

        // update search/query
        url.search = window.location.search;

        window.location.replace(url.href);
    }, [localState]);

    return null;
};

export default AydenCallback;
