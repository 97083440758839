import React, { useCallback, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CalculatorValues } from '../../../../components/shared/calculator-next/types';
import useCompanyFormatting from '../../../../components/utilities/useCompanyFormatting';
import { getCurrentCountry, getRuntimeSettings } from '../../../../selectors';
import { ConfiguratorFlowState } from '../../ConfiguratorFlow';
import { ConfigPageStepValues } from '../../steps/ConfigPageStep';
import getCleanCKDValues from '../Summary/shared/getCleanCKDValues';
import Inner, { ConfigFormValues } from './ConfigForm';

type ConfigPageProps = {
    submit: (values: ConfigPageStepValues) => Promise<void>;
    initialValues: Omit<ConfiguratorFlowState, 'isResumeOrder'>;
};

const ConfigPage = ({ submit, ...props }: ConfigPageProps) => {
    const { key } = useLocation();

    const { ckdConfiguration } = useSelector(getRuntimeSettings);
    const { formatCurrency } = useCompanyFormatting();

    // state to keep track of latest calculator values
    const [calculatorValues, setCalculatorValues] = useState<Partial<CalculatorValues>>();
    const currentCountry = useSelector(getCurrentCountry);

    // Prepare for Google Tag Manager Data Layer Push
    const googleTagManagerId = currentCountry?.googleTagManager?.id;

    const onSubmit = useCallback(
        ({ timepiece, ...values }: ConfigFormValues) => {
            if (!calculatorValues) {
                throw new Error('missing calculator values');
            }

            const cleanCKDValues = getCleanCKDValues({
                ckdConfiguration,
                exterior: values.exteriorColor,
                interior: values.interiorColor,
                isEnhancedPackageSeleted: values.enhancedPackage,
                tEquipment: values.tequipment,
                isTimepieceSelected: !!timepiece?.isAdded,
                deliveryMethod: values.delivery,
            });

            const details = {
                miniConfiguratorDetails: cleanCKDValues,
                promoCode: calculatorValues.promoCode,
                carPrice: formatCurrency(calculatorValues.totalPrice),
                monthlyPayment: calculatorValues.monthlyInstalments
                    ? formatCurrency(calculatorValues.monthlyInstalments[0].amount)
                    : undefined,
            };

            const tagManagerArgs = {
                dataLayer: {
                    event: 'ProceedToSummaryButtonClick',
                    details,
                },
                dataLayerName: 'PageDataLayer',
            };

            if (googleTagManagerId) {
                TagManager.dataLayer(tagManagerArgs);
            }

            submit({
                ...values,
                calculatorValues,
                timepiece: timepiece?.isAdded ? timepiece : undefined,
                variantId: ckdConfiguration.vehicle.actualIdentifider,
            });
        },
        [calculatorValues, ckdConfiguration, formatCurrency, googleTagManagerId, submit]
    );

    // redux form issue when re-initializing form with same name :(
    // https://github.com/redux-form/redux-form/issues/2982
    // create dynamic form to allow form re-initialization when navigating to same page
    const form = useMemo(() => `configurator${key}`, [key]);

    return <Inner key={key} form={form} onSubmit={onSubmit} setCalculatorValues={setCalculatorValues} {...props} />;
};

export default ConfigPage;
