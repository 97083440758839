import styled from 'styled-components';

const Message = styled.div`
    color: #000;
    text-align: center;
    line-height: 1.72rem;
    margin-top: 20px;
`;

export const MessageTitle = styled.div`
    margin-top: 80px;
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 1.21rem;
    text-transform: uppercase;
`;

Message.Title = MessageTitle;

Message.Link = styled(Message)`
    text-decoration: underline;
    cursor: pointer;
`;

export default Message;
