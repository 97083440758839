import React from 'react';
import { completePayment, CompletePaymentMutation, CompletePaymentMutationVariables } from '../../../api/draft.graphql';
import HelmetTitle from '../../../components/shared/HelmetTitle';
import { ReduxFormFlowStep } from '../../utils/flow';
import TTBDeposit from '../components/TTBDeposit';
import { NewFlowState } from '../types';

class TTBDepositStep extends ReduxFormFlowStep<NewFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'deposit';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('depositPage.label.step');
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.payment || false;
    }

    public async execute() {
        const { apolloClient } = this;
        const { token } = this.state;

        if (!token) {
            throw new Error('Token missing in state');
        }

        const response = await apolloClient.mutate<CompletePaymentMutation, CompletePaymentMutationVariables>({
            mutation: completePayment,
            variables: { token },
        });

        this.stateStore.update({ ...response.data?.response });

        return this.nextStep;
    }

    public render(): React.ReactElement | null {
        const { bank, application, token, channel, source } = this.state;

        if (!bank) {
            throw new Error('Bank missing in state');
        }

        if (!application) {
            throw new Error('Application missing in state');
        }

        if (!token) {
            throw new Error('Token missing in state');
        }

        return (
            <>
                <HelmetTitle channel={channel} title="Deposit" />
                <TTBDeposit
                    application={application}
                    backStep={this.getBackContext()}
                    channel={channel}
                    dealerId={application.dealerId}
                    onSubmit={this.submit}
                    source={source}
                    token={token}
                />
            </>
        );
    }
}

export default TTBDepositStep;
