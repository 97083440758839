import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useMedia from 'use-media';
import { getRuntimeSettings } from '../../../../../selectors';
import breakpoints from '../../../../../utilities/constants/breakpoints';

const useInteriorImage = (exteriorColor: string, interiorColor: string) => {
    const isMobileSource = useMedia({ maxWidth: breakpoints.sm });
    const { ckdConfiguration } = useSelector(getRuntimeSettings);

    const { options: interiorOption } = ckdConfiguration.interior;
    const { options: exteriorOption } = ckdConfiguration.exterior;

    return useMemo(() => {
        if (interiorColor === interiorOption[0].name) {
            switch (exteriorColor) {
                case exteriorOption[0].name:
                    return isMobileSource
                        ? interiorOption[0].images[0].mobileImage
                        : interiorOption[0].images[0].desktopImage;
                case exteriorOption[1].name:
                    return isMobileSource
                        ? interiorOption[0].images[1].mobileImage
                        : interiorOption[0].images[1].desktopImage;
                case exteriorOption[2].name:
                    return isMobileSource
                        ? interiorOption[0].images[2].mobileImage
                        : interiorOption[0].images[2].desktopImage;
                default:
                    return isMobileSource
                        ? interiorOption[0].images[0].mobileImage
                        : interiorOption[0].images[0].desktopImage;
            }
        } else {
            switch (exteriorColor) {
                case exteriorOption[0].name:
                    return isMobileSource
                        ? interiorOption[1].images[0].mobileImage
                        : interiorOption[1].images[0].desktopImage;
                case exteriorOption[1].name:
                    return isMobileSource
                        ? interiorOption[1].images[1].mobileImage
                        : interiorOption[1].images[1].desktopImage;
                case exteriorOption[2].name:
                    return isMobileSource
                        ? interiorOption[1].images[2].mobileImage
                        : interiorOption[1].images[2].desktopImage;
                default:
                    return isMobileSource
                        ? interiorOption[1].images[0].mobileImage
                        : interiorOption[1].images[0].desktopImage;
            }
        }
    }, [interiorColor, interiorOption, exteriorColor, exteriorOption, isMobileSource]);
};

export default useInteriorImage;
