export const getConfig = async (channel, zoneId, token) => {
    const response = await fetch(`/api/Adyen/configuration?zoneId=${zoneId}&channel=${channel}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};

export const getPaymentMethods = async (channel, zoneId, token) => {
    const response = await fetch(`/api/Adyen/PaymentMethods?zoneId=${zoneId}&channel=${channel}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};

// Check a way to make a post using custom hooks
export const submitPayment = async (data, token) => {
    const response = await fetch('/api/Adyen/payment', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};

export const submitAdditionalDetails = async (data, token) => {
    const response = await fetch('/api/Adyen/additionalDetails', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    return response.json();
};
