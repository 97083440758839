import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import {
    getDealerContactInformation,
    GetDealerContactInformationQuery,
    GetDealerContactInformationQueryVariables,
} from '../../api/dealers.graphql';

const useDealerContactInformation = (id: string) =>
    useQuery<GetDealerContactInformationQuery, GetDealerContactInformationQueryVariables>(getDealerContactInformation, {
        variables: { dealerId: id },
        skip: isEmpty(id),
        fetchPolicy: 'cache-first',
    });
export default useDealerContactInformation;
