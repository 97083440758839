import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Times } from '../../../../../assets/images/times.svg';

export type BannerProps = {
    url: string;
    onClose: () => void;
};

export const BannerContainer = styled.div`
    font-size: 14px;
    font-weight: 700;
    position: relative;
    background-color: #bcafb433;
    padding: 9px 30px;
    color: #000;
    margin-bottom: 50px;

    span + span {
        font-weight: normal;
    }

    svg {
        cursor: pointer;
        position: absolute;
        top: 11px;
        right: 15px;
        width: 16px;
    }
`;

export const BannerLink = styled.a`
    color: #d5001c;
    text-decoration: underline;
    cursor: pointer;
`;

const Banner = ({ url, onClose }: BannerProps) => (
    <BannerContainer>
        <span>
            <Trans components={{ e: <BannerLink href={url} /> }} i18nKey="eventCalculatorPage.banner" />
        </span>

        <Times fill="#000" onClick={onClose} />
    </BannerContainer>
);

export default Banner;
