import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReduxFormContext } from 'redux-form';
import { useContentTranslation } from '../../../i18n';
import { ApplicationPhase } from '../../../schema';
import { getCompanyIdentifier } from '../../../selectors';
import { getSourceLabel } from '../../../utilities/application';
import { getLastModified, handleResponseError } from '../../../utilities/forms';
import { getApplicationsUrl } from '../../../utilities/urls';
import TextAreaField from '../../shared/form-v2/TextAreaField';
import TextField from '../../shared/form-v2/TextField';
import UserField from '../../shared/form-v2/UserField';
import { formatApplicationStatus } from '../../shared/form-v2/steps/Summary';
import useFormatDateTime from '../../shared/useFormatDate';
import {
    updateInsuranceAssignee,
    UpdateInsuranceAssigneeMutation,
    UpdateInsuranceAssigneeMutationVariables,
} from '../ApplicationRoute/Action.graphql';
import LinkField from '../AppointmentRoute/LinkField';
import { InsuranceApplicationDataFragment } from './data.graphql';

export type SummaryProps = {
    disabled?: boolean;
    insuranceApplication: InsuranceApplicationDataFragment;
};

const Summary = ({ insuranceApplication, disabled = false }: SummaryProps) => {
    const { t } = useTranslation();
    const { formatPath, ct } = useContentTranslation();
    const applicationId = insuranceApplication.id;
    const client = useApolloClient();

    const status = useMemo(() => {
        return insuranceApplication?.statusText ? formatApplicationStatus(insuranceApplication?.statusText) : '';
    }, [insuranceApplication]);

    const onAssigneeChange = useCallback(
        value => {
            client
                .mutate<UpdateInsuranceAssigneeMutation, UpdateInsuranceAssigneeMutationVariables>({
                    mutation: updateInsuranceAssignee,
                    variables: { insuranceApplicationId: applicationId, assigneeId: value },
                })
                .catch(handleResponseError);
        },
        [client, applicationId]
    );

    const dealerIds = useMemo(() => [insuranceApplication.dealerId], [insuranceApplication]);

    // format datetimes
    const formatDateTime = useFormatDateTime();
    const createdDate = formatDateTime(insuranceApplication.version?.createdAt);
    const lastModified = getLastModified(insuranceApplication.version, formatDateTime);
    const source = getSourceLabel(insuranceApplication.channel, insuranceApplication.access, t);

    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    const referralLinkPrefix = getApplicationsUrl(companyCode, locationCode, {
        phase: insuranceApplication.application?.phase,
    });

    return (
        <Portlet name={t('applicationDetailsPage.subHeading.insuranceApplicationDetails')} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={insuranceApplication?.identifier}
                            label={t('applicationDetailsPage.label.insuranceApplicationID')}
                            name="__exclude.applicationId"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={createdDate}
                            label={t('applicationDetailsPage.label.dateCreated')}
                            name="__exclude.createdDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={ct(insuranceApplication.dealer?.name)}
                            label={t('applicationDetailsPage.label.dealer')}
                            name={formatPath('__exclude.dealer.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={insuranceApplication.version.createdBy?.name}
                            label={t('applicationDetailsPage.label.createdBy')}
                            name="__exclude.createdBy"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserField
                            dealerIds={dealerIds}
                            disabled={disabled}
                            label={t('applicationDetailsPage.label.assignedTo')}
                            name="assigneeId"
                            onChangeEvent={onAssigneeChange}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={source}
                            label={t('applicationDetailsPage.label.channel')}
                            name="__exclude.source"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={lastModified}
                            label={t('applicationDetailsPage.label.lastModified')}
                            name="__exclude.lastModified"
                            disabled
                        />
                    </div>

                    <div className="col-md-8 col-sm-12 col-xs-12">
                        <LinkField>
                            <div className="label">{t('applicationDetailsPage.label.applicationReference')}</div>
                            <div className="link-control">
                                <Link to={`${referralLinkPrefix}/${insuranceApplication.application?.version.id}`}>
                                    {insuranceApplication?.application?.identifier}
                                </Link>
                            </div>
                        </LinkField>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextAreaField
                            fixedValue={status}
                            label={t('applicationDetailsPage.label.applicationStatus')}
                            name="applicationStatus"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default Summary;
