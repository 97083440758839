import React from 'react';
import { useLocation, useParams } from 'react-router';
import { usePersistedFlow } from '../../../../flows/utils/persistency';
import RedirectToCompanyHome from '../../../utilities/RedirectToCompanyHome';
import { serialize, unserialize, generateCacheKey } from '../DraftRoute';
import ResumeBootstrap from './ResumeBootstrap';
import ResumeHandler from './ResumeHandler';

type LocationParams = { id: string };

type LocationState = { token?: string };

const ResumeRoute = () => {
    const { token: tokenFromLocation } = useLocation<LocationState>().state || {};
    const { id: applicationVersionId } = useParams<LocationParams>();

    const { token = tokenFromLocation } =
        usePersistedFlow(
            `resumeFlow:${applicationVersionId}`,
            'resumeFlow',
            serialize,
            unserialize,
            generateCacheKey
        ) || {};

    if (!token) {
        return <RedirectToCompanyHome />;
    }

    return (
        <ResumeBootstrap token={token}>
            {(application, consents) => <ResumeHandler application={application} consents={consents} token={token} />}
        </ResumeBootstrap>
    );
};

export default ResumeRoute;
