import React, { createContext, ReactNode, useMemo } from 'react';
import { FinderVehicleDataFragment } from './data.graphql';

export const FinderContext = createContext<FinderVehicleDataFragment | null>(null);

export type FinderProviderProps = {
    finderVehicle: FinderVehicleDataFragment;
    children?: ReactNode;
};

export const FinderProvider = ({ finderVehicle, children }: FinderProviderProps) => {
    const memoizedFinderVehicle = useMemo(() => finderVehicle, [finderVehicle]);

    return <FinderContext.Provider value={memoizedFinderVehicle}>{children}</FinderContext.Provider>;
};

export const useFinderContext = () => {
    const context = React.useContext(FinderContext);
    if (!context) {
        throw new Error('useFinderContext must be used within a FinderProvider');
    }

    return context;
};
