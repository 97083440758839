import { AuthorizationActions, AUTHORIZATION_UNSET, AUTHORIZATION_SET } from '../actions/authorization';
import persistState from './persistState';

export type AuthorizationState = { accessToken?: string | null };

const reducer = (state: AuthorizationState = {}, action: AuthorizationActions): AuthorizationState => {
    switch (action.type) {
        case AUTHORIZATION_SET:
            return action.authorization;

        case AUTHORIZATION_UNSET:
            return {};

        default:
            return state;
    }
};

export default persistState('authorization', reducer);
