import React, { useCallback, useContext, Dispatch, SetStateAction } from 'react';
import { ReduxFormContext } from 'redux-form';
import styled from 'styled-components';
import useFormValues from '../../../../components/utilities/useFormValues';
import breakpoints from '../../../../utilities/constants/breakpoints';
import OrderDetails from '../Summary/OrderDetails';
import { MiniConfiguratorDetails, SummaryFormProps, SummaryWrapper } from '../Summary/Summary';
import { CrossContainer, ModalContainer } from './shared/PackageModal';
import close from './assets/close.png';

const ModalWrapper = styled.div`
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 22vh;
    bottom: 0;
    width: calc(100% - 60vw);
    height: auto;
    overflow-y: auto;

    @media (max-width: ${breakpoints.xl}) {
        width: 100%;
        height: auto;
        padding: 30px 0px;
    }
`;

type FooterDrawerProps = {
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setDisplaySummaryTerms: Dispatch<SetStateAction<boolean>>;
};

const FooterDrawer = ({ setShowModal, setDisplaySummaryTerms }: FooterDrawerProps) => {
    const { form } = useContext(ReduxFormContext);

    // get form values
    const {
        defaultFinanceProduct,
        delivery,
        enhancedPackage,
        events,
        exteriorColor,
        interiorColor,
        tequipment,
        timepiece,
        variantId,
    } = useFormValues<MiniConfiguratorDetails & SummaryFormProps>();

    const onModalClose = useCallback(() => setShowModal(false), [setShowModal]);

    const initialData = {
        delivery,
        enhancedPackage,
        exteriorColor,
        interiorColor,
        tequipment,
        timepiece,
        variantId,
    };

    return (
        <ModalContainer>
            <ModalWrapper>
                <SummaryWrapper>
                    <CrossContainer onClick={onModalClose} style={{ right: 0, top: 0 }}>
                        <img alt="cross" src={close} />
                    </CrossContainer>
                    <OrderDetails
                        defaultFinanceProduct={defaultFinanceProduct}
                        events={events}
                        form={form}
                        initialData={initialData}
                        setDisplayTerms={setDisplaySummaryTerms}
                    />
                </SummaryWrapper>
            </ModalWrapper>
        </ModalContainer>
    );
};

export default FooterDrawer;
