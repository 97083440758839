import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const LOADING_REGISTER = '@LOADING/REGISTER';

export const LOADING_UNREGISTER = '@LOADING/UNREGISTER';

export type RegisterLoading = { type: typeof LOADING_REGISTER; task: { id: string; silent: boolean } };

export const registerLoading = (taskId: string, silent = false): RegisterLoading => ({
    type: LOADING_REGISTER,
    task: { id: taskId, silent },
});

export type UnregisterLoading = { type: typeof LOADING_UNREGISTER; taskId: string };

export const unregisterLoading = (taskId: string): UnregisterLoading => ({ type: LOADING_UNREGISTER, taskId });

export const attachLoading = <R>(promise: Promise<R>, silent = false) => async (
    dispatch: Dispatch<any>
): Promise<R | undefined> => {
    // get the task id
    const taskId = uuidv4();

    // register the task
    dispatch(registerLoading(taskId, silent));

    try {
        // wait for results
        const result = await promise;

        // stop the loading
        dispatch(unregisterLoading(taskId));

        return result;
    } catch (error) {
        // stop the promise
        dispatch(unregisterLoading(taskId));

        // @ts-ignore
        if (!error || !error.muteOnloading) {
            // we should not mute this error
            return Promise.reject(error);
        }
    }

    return undefined;
};

export type LoadingActions = RegisterLoading | UnregisterLoading;

// withLoading is a asynchronised action factory

export const withLoading = (promise: Promise<any>) => (dispatch: Dispatch<any>) => {
    if (promise instanceof Promise) {
        // inform we are listening another promise
        dispatch({ type: 'LOADING_BEGIN' });

        // wrap our promise
        return promise
            .then(resolved => {
                // inform it ended
                dispatch({ type: 'LOADING_END' });

                // return the resolved value
                return resolved;
            })
            .catch(error => {
                // inform it ended
                dispatch({ type: 'LOADING_END' });

                // keep the rejection ongoing
                return Promise.reject(error);
            });
    }

    // somehow we ended with something else than a promise
    // let's have some warning about it
    console.warn(`withLoading received a ${typeof promise} instead of Promise`);

    // fallback on what we expect
    return Promise.resolve();
};
