import { NOTIFICATION_SET, NOTIFICATION_UNSET, NotificationActions } from '../actions/notification';

export type NotificationState = { title: string; message: string } | null;

const reducer = (state: NotificationState = null, action: NotificationActions): NotificationState => {
    switch (action.type) {
        case NOTIFICATION_SET:
            return action.notification;

        case NOTIFICATION_UNSET:
            return null;

        default:
            return state;
    }
};

export default reducer;
