import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { attachLoading } from '../../../../../actions';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationPhase, Channel } from '../../../../../schema';
import { getCompanyIdentifier } from '../../../../../selectors';
import { getSourceLabel } from '../../../../../utilities/application';
import { appointmentPurposeOptions, appointmentStatusOptions } from '../../../../../utilities/constants/options';
import { handleResponseError } from '../../../../../utilities/forms';
import { getApplicationsUrl, getLeadsUrl } from '../../../../../utilities/urls';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import UserField from '../../../../shared/form-v2/UserField';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import useFormValues from '../../../../utilities/useFormValues';
import LinkField from '../../LinkField';
import {
    updateAssigneeAppointment,
    UpdateAssigneeAppointmentMutation,
    UpdateAssigneeAppointmentMutationVariables,
} from '../Details.graphql';
import { AppointmentFormValues } from '../Form/context';
import FieldAppointmentDateTime from './FieldAppointmentDateTime';

type SectionSummaryProps = {
    dealerId: string;
    channel: Channel;
};

const SectionSummary = ({ dealerId, channel }: SectionSummaryProps) => {
    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();

    const dispatch = useDispatch();

    const { latestApplication, id, version } = useFormValues<AppointmentFormValues>();

    const formatDateTime = useFormatDateTime();

    const { companyCode, locationCode } = useSelector(getCompanyIdentifier);

    const referralLinkPrefix = useMemo(() => {
        switch (latestApplication.phase) {
            case ApplicationPhase.LEAD:
                return getLeadsUrl(companyCode, locationCode);

            case ApplicationPhase.RESERVATION:
                return getApplicationsUrl(companyCode, locationCode, { phase: ApplicationPhase.RESERVATION });

            case ApplicationPhase.FINANCE:
            default:
                return getApplicationsUrl(companyCode, locationCode, { phase: ApplicationPhase.FINANCE });
        }
    }, [companyCode, latestApplication, locationCode]);

    const client = useApolloClient();
    const onAssigneeChange = useCallback(
        value => {
            try {
                const promise = client.mutate<
                    UpdateAssigneeAppointmentMutation,
                    UpdateAssigneeAppointmentMutationVariables
                >({
                    mutation: updateAssigneeAppointment,
                    variables: {
                        id,
                        assigneeId: value,
                    },
                });

                dispatch(attachLoading(promise));
            } catch (error) {
                handleResponseError(error as Error);
            }
        },
        [client, dispatch, id]
    );

    const dealerIds = useMemo(() => [dealerId], [dealerId]);

    return (
        <Portlet name="Appointment Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('appointmentDetailPage.summary.appointmentId')}
                            name="identifier"
                            disabled
                        />
                    </div>
                    <FieldAppointmentDateTime name="date" />
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('appointmentDetailPage.summary.appointmentFor')}
                            name="purpose"
                            options={appointmentPurposeOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={t('appointmentDetailPage.summary.appointmentStatus')}
                            name="status"
                            options={appointmentStatusOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={formatDateTime(version.createdAt)}
                            label={t('applicationDetailsPage.label.dateCreated')}
                            name="__exclude.createdDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.dealer')}
                            name={formatPath('latestApplication.dealer.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={t('applicationDetailsPage.label.createdBy')}
                            name="version.createdBy"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserField
                            dealerIds={dealerIds}
                            label={t('applicationDetailsPage.label.assignedTo')}
                            name="assignee.id"
                            onChangeEvent={onAssigneeChange}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={getSourceLabel(latestApplication.channel, latestApplication.access, t)}
                            label={t('applicationDetailsPage.label.channel')}
                            name="__exclude.source"
                            disabled
                        />
                    </div>
                    {channel === Channel.EVENT && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={t('applicationDetailsPage.label.eventName')}
                                name="latestApplication.event.name"
                                disabled
                            />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <LinkField>
                            <div className="label">{t('appointmentDetailPage.summary.reference')}</div>
                            <div className="link-control">
                                <Link to={`${referralLinkPrefix}/${latestApplication?.version.id}`}>
                                    {latestApplication?.identifier}
                                </Link>
                            </div>
                        </LinkField>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={formatDateTime(latestApplication.version.updatedAt)}
                            label={t('applicationDetailsPage.label.lastModified')}
                            name="__exclude.lastModified"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default SectionSummary;
