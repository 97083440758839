import React from 'react';
import { PromoDataFragment } from '../../../components/data/useLoadPromo.graphql';
import { FlowStep } from '../../utils/flow';
import { ConfiguratorFlowState } from '../ConfiguratorFlow';
import CKDMetaTitle from '../components/Configurator/shared/CKDMetaTitle';
import Summary from '../components/Summary/Summary';
import { ConfigPageStepValues } from './ConfigPageStep';

export type SummaryStepValues = ConfigPageStepValues;

class SummaryStep extends FlowStep<ConfiguratorFlowState, SummaryStepValues> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'summaryPage';
    }

    // eslint-disable-next-line class-methods-use-this
    public get label() {
        return this.t('summaryPage.label.step');
    }

    public updatePromoCode = (promo?: PromoDataFragment, promoCode?: string) => {
        this.stateStore.update({ promo, promoCode });
    };

    public updateDealerSelection = (dealerId: string) => {
        this.stateStore.update({ dealerId });
    };

    public render(): React.ReactElement | null {
        const {
            interiorColor,
            exteriorColor,
            enhancedPackage,
            tequipment,
            timepiece,
            delivery,
            variantId,
            events,
            dealerId,
            defaultFinanceProduct,
            promoCode,
            promo,
        } = this.state;

        const initialData = {
            interiorColor,
            exteriorColor,
            enhancedPackage,
            tequipment,
            timepiece,
            delivery,
            variantId,
        };

        const initialValues = {
            dealerId,
            promoCode,
            promo,
        };

        if (!defaultFinanceProduct || !initialData) {
            throw new Error('Values are missing in state');
        }

        return (
            <>
                <CKDMetaTitle />
                <Summary
                    backStep={this.getBackContext()}
                    defaultFinanceProduct={defaultFinanceProduct}
                    events={events}
                    initialData={initialData}
                    initialValues={initialValues}
                    updateDealerSelection={this.updateDealerSelection}
                    updatePromoCode={this.updatePromoCode}
                />
            </>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected async execute() {
        return this.nextStep;
    }
}

export default SummaryStep;
