import React from 'react';
import Namirial from '../../DraftFlow/components/Namirial';
import { ReduxFormFlowStep } from '../../utils/flow';
import { EventFlowState } from '../EventSubmitFlow';

class EventNamirialStep extends ReduxFormFlowStep<EventFlowState> {
    // eslint-disable-next-line class-methods-use-this
    public get identifier(): string {
        return 'namirial';
    }

    public get isCompleted(): boolean {
        return this.state.application?.steps?.approval || false;
    }

    public render(): React.ReactElement | null {
        const { token, source } = this.state;

        if (!token) {
            throw new Error('token missing in state');
        }

        return <Namirial source={source} token={token} />;
    }
}

export default EventNamirialStep;
