import React from 'react';
import { FlowStep } from '../../utils/flow';
import { RemoteFlowState } from '../RemoteFlow';
import PreviewPage from '../components/PreviewPage';

class PreviewStep extends FlowStep<RemoteFlowState> {
    // eslint-disable-next-line class-methods-use-this
    get identifier(): string {
        return 'preview';
    }

    public render(): React.ReactElement | null {
        const { application, channel } = this.state;

        return <PreviewPage application={application} channel={channel} onSubmit={this.submit} />;
    }
}

export default PreviewStep;
