import { flow, get, map, uniqBy } from 'lodash/fp';
import { useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { VariantDataFragment, MakeDataFragment } from '../../../data/useLoadVariants.graphql';

const getMakersFromVariants = flow([map(get('model.make')), uniqBy(get('id'))]);

export type MakeFilter<TVariant extends VariantDataFragment> = {
    current: string | null;
    set: Dispatch<SetStateAction<string | null>>;
    list: MakeDataFragment[];
    filteredVariants: TVariant[];
};

const useMakers = <TVariant extends VariantDataFragment>(variants: TVariant[]): MakeFilter<TVariant> => {
    // first isolate our makers
    const makers = useMemo(() => getMakersFromVariants(variants) as MakeDataFragment[], [variants]);

    // then set a state to change it
    const [current, setCurrent] = useState<string | null>(null);

    // the following effect need to be ran every time the variants changed
    useEffect(() => {
        // by default if there is only one maker we are going to pre-select it
        // otherwise set it to null to select all
        setCurrent(!makers.length || makers.length > 1 ? null : makers[0].id);
    }, [variants, makers, setCurrent]);

    // filter variants with the current maker
    const filteredVariants = useMemo(() => {
        if (!current) {
            // no need to filter
            return variants;
        }

        // limit to the ones for this same maker
        return variants.filter(variant => variant.model.make.id === current);
    }, [variants, current]);

    return { current, set: setCurrent, list: makers, filteredVariants };
};

export default useMakers;
