import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { DraftFlow } from '../../../flows/DraftFlow';
import { useManagedFlow } from '../../../flows/utils/flow';
import useCompleteHandler from '../../../flows/utils/useCompleteHandler';
import { useCompany, useCountry, useZone } from '../../../hookSelectors';
import { ApplicationFlowSource, Channel } from '../../../schema';
import { getIsAuthenticated } from '../../../selectors';
import { getLocationCode, getUsedCarModelsUrl } from '../../../utilities/urls';
import useMustExistDealerIdFromTenantContext from '../../data/useMustExistDealerIdFromTenantContext';
import Chatbot from '../../shared/Chatbot';
import Footer from '../../shared/Footer';
import Header from '../../shared/Header';
import PublicSessionTimer from '../../shared/PublicSessionTimer';
import RedirectToCompanyHome from '../../utilities/RedirectToCompanyHome';
import { LocationState } from './NewRoute';

type UsedLocationState = LocationState;

const UsedRoute = () => {
    const zone = useZone();
    const country = useCountry();
    const company = useCompany();
    const dealerId = useMustExistDealerIdFromTenantContext();
    const { state } = useLocation<UsedLocationState>();
    const leadState = state?.lead;
    const isAuthenticated = useSelector(getIsAuthenticated);

    const url = useMemo(() => {
        const locationCode = getLocationCode(country.code, zone.code);

        return getUsedCarModelsUrl(company.code, locationCode);
    }, [company.code, country.code, zone.code]);

    const { step } = useManagedFlow(
        DraftFlow,
        {
            isAuthenticated,
            channel: Channel.USED,
            zone,
            country,
            company,
            dealerId,
            ...leadState,
            source: ApplicationFlowSource.DRAFT,
        },
        'usedSubmitFlow'
    );

    useCompleteHandler('usedSubmitFlow');

    const { isActive } = country.channelSetting.used;

    if (!isActive) {
        return <RedirectToCompanyHome />;
    }

    return (
        <>
            <Header channel={Channel.USED} />
            <Chatbot channel={Channel.USED} />
            <PublicSessionTimer url={url} />
            {step.render()}
            <Footer />
        </>
    );
};

export default UsedRoute;
